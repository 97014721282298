import { base } from '../connection';

export function setloanissue(siteId,data) {
	console.log(new Date());
	return (dispatch) => {
		getSite(siteId,data);
	}
}

function getSite(siteId,data) {
	 base.post('/delivery/Issue/statusbysite/'+siteId+'/financing/', {
		data: data
	    ,then(err){
	      if(!err){
	      	console.log("SUCCESS",siteId);
	      }else{
	      	console.log(err);
	      }
	    }
	  });
}
