import React from 'react';

export const SalesViewCheckBox = ({columnCheckBoxStatus,showDefault,handleColumnChange,index,label}) =>{
	if(label && {}.toString.call(label) === '[object Function]') {
		return "";
	}
	return (
		<div className="col-6 col-md-3" style={showDefault?{"display":"none"}:{"display":""}}>
			<div className="input-group mb-3">
				<div className="input-group-prepend">
					<div className="input-group-text">
						<input type="checkbox" defaultChecked={columnCheckBoxStatus===undefined?true:columnCheckBoxStatus} onClick={() => handleColumnChange(index)} />
					</div>
				</div>
				<input type="text" readOnly className="form-control" value={label} />
			</div>
		</div>
	)
}	