import React from 'react';
import Select from 'react-select';
import ReactTable from "react-table";

import '../styles/SalesAnalytics.css';

/* export const SalesAnalyticsView = ({defaultValues,buttonTypes}) => {
	return (
		<div className="mainBox">
			{
				defaultValues.map((value,index) => (
					<InputCustom 
						key = {index}
						label = {value.name}
						type = "text"
						name = {value.name}
						placeholder = "Enter you name"
						defaultValue = {value.defaultValue}
					/>
				))	
			}			
		</div>
	);
} */

export const SalesAnalyticsView = ({defaultValues, buttonTypes, stateStageColumns, cityStageColumns, cityMonthColumns, cityMonthData, employeeMonthColumns, employeeMonthData, stateStageData ,cityStageData, loading, handleOnButtonClick}) => {
	return (
		<div className="mainBox">
			<CustomTable id="state" columns={stateStageColumns} buttonTypes={buttonTypes} data={stateStageData} loading={loading} tableName="State-Stage" handleOnButtonClick={handleOnButtonClick}/>
			<CustomTable id="city" columns={cityStageColumns} buttonTypes={buttonTypes} data={cityStageData} loading={loading} tableName="City-Stage" handleOnButtonClick={handleOnButtonClick}/>
			<CustomTable id="cityMonth" columns={cityMonthColumns} buttonTypes={buttonTypes} data={cityMonthData} loading={loading} tableName="City-Month" handleOnButtonClick={handleOnButtonClick}/>
			<CustomTable id="employeeMonth" columns={employeeMonthColumns} buttonTypes={buttonTypes} data={employeeMonthData} loading={loading} tableName="Employee-Month" handleOnButtonClick={handleOnButtonClick}/>
		</div>
	);
}

const CustomTable = ({buttonTypes, columns, data, loading, handleOnButtonClick, id, tableName}) => {
	return (
		<div className="cardCustom shadow">
			<div className="card">
				<div className="card-body">
					<div className="card-title tableTitle">
						<div className="tableHead">
							<div className="tableName">
								<h5>{tableName}</h5>
							</div>
							<div className = "buttons">
								{
									buttonTypes.map((value,index) => (
										<ButtonCustom 
											key = {index}
											label = {value.label}
											type = {value.type}
											id = {id}
											handleOnButtonClick={handleOnButtonClick}
											className={"btn btn-success salesAdmin"}
										/>
									))
								}	
							</div>
						</div>
					</div>
					<div className = "col-12">
						<div className="buttonTables">
							<TableReact columns={columns} data={data} loading={loading}/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
const SelectCustom = ({options, name, placeholder}) => {
	return (
		<div className="drop_down1">
			<Select
				isMulti
				isSearchable
			    placeholder={placeholder}
			    name={name}
				options={options}
			/>
		</div>
	)
}

const InputCustom = ({label, type, name, placeholder, defaultValue}) => {
	return (
		<div className="input-group mb-3">
			<div className="input-group-prepend">
				<span className="input-group-text" id="basic-addon1">{label}</span>
			</div>
			<input className="form-control" type={type} name={name} placeholder={placeholder} defaultValue={defaultValue} />
		</div>
	)
}

const ButtonCustom = ({label, type, id, className, handleOnButtonClick}) => {
	return (
		<button type={type} className={className} onClick={() => {handleOnButtonClick(type,id)}}>{label}</button>
	);
}
const data = [];
/* const columns = [{
	Header: 'Name',
	accessor: 'name'
  },{
	Header: 'Age',
	accessor: 'age'
  }]; */
const TableReact = ({columns, data, loading}) => {
	return (
	<ReactTable
	    loading={loading}
	    data={data}
		columns={columns}
		filterable={true}
		style={{textAlign:'center'}}
		defaultPageSize = {5}
		defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase())>-1}/>
)
}