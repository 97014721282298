import { ADDING_COMMENT_STATUS } from './actiontypes';

import { base, db } from '../connection';
import { getCommentProcessRequest } from '../helper/addComments';
import { createDateObj } from '../helper/dateObj';
import axios from 'axios';
import moment from 'moment';
import { getNameById, sendEmail} from '../helper/helper';

export function setnextactiondate(siteId,date,user,saleDate,ref_Date,current_State,map, quoteId, commissioningDate, email) {
	console.log(new Date());
	return (dispatch) => {
		fetchStage(dispatch,siteId,date,user,saleDate,ref_Date,current_State,map, quoteId, commissioningDate, email);
	}
}

function convertDate(inputDate) {
	function pad(s) { return (s < 10) ? '0' + s : s; }
	var d = new Date(inputDate);
	return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('-');
}

function fetchStage(dispatch,siteId,next_date,user,saleDate,ref_Date,current_State,map, quoteId, commissioningDate, email) {
	let userphone = user;
	console.log(siteId);
	base.fetch('/delivery/sites/'+siteId, {})
	.then(data => {
		let stageArray=[];
		if(data.stage){
		stageArray = [...[],...data.stage];
		console.log(stageArray);
		let prevValue = JSON.parse(JSON.stringify(stageArray[stageArray.length-1]));
		let lastValue = JSON.parse(JSON.stringify(stageArray[stageArray.length-1]));
		console.log(lastValue);
		lastValue.change_By = ""+user;
		var oldSaleDate = prevValue.sales_Date?prevValue.sales_Date:"NA";
		var prevActionDate = prevValue.next_Date?prevValue.next_Date:"NA";
		var oldRefDate = prevValue.ref_Date?prevValue.ref_Date:"NA";
		var prevState = prevValue.current_State?prevValue.current_State:"NA";
		console.log(current_State);
		lastValue.next_Date = (!next_date)?prevActionDate:convertDate(next_date);
		lastValue.past_State = prevValue.current_State;
		lastValue.sales_Date = (!saleDate)?oldSaleDate:convertDate(saleDate);
		lastValue.ref_Date = (!ref_Date)?oldRefDate:convertDate(ref_Date);
		lastValue.current_State = (!current_State&&current_State!=0)?prevState:(""+current_State);
		lastValue.change_By = ""+user;
		stageArray.push(lastValue);
		console.log(lastValue);

		if(map[""+current_State] == "Delivered") {
			fetchOrderStatus(siteId);
			createDeliveredOrderTickets(quoteId, user);
			triggerWhatsapp(siteId).then();
		}
		if(!commissioningDate && current_State >= 6 && current_State <= 7){
			const emailSubject = 'Update:Commissioning of your solar rooftop project'
            const emailMessage = `
                <!DOCTYPE html>
                    <html lang="en">
                        <head>
                            <meta charset="utf-8">
                            <meta name="viewport" content="width=device-width, initial-scale=1">
                        </head>
                        <body>
                            <div>
                                Dear Sir,
                                <br>
                                Greetings from Team Zunroof!
                                <br><br>
								This is an update mail with regards to your solar rooftop project. We would like to inform you we have successfully commissioned your Solar Rooftop project. We will accordingly be moving ahead with net meter application and installation subject to adherence of payment terms agreed at the time of order confirmation.                                Warm Regards,
                                <br>
                                ZunRoof Team
                                <br>
                                www.zunroof.com,
                                <br>
                                www.facebook.com/zunroof
                                <br><br>
                                Support: +919319432702
                                <br>
                                Get your ZunRoof app: https://play.google.com/store/apps/details?id=com.zunroofprod.zunroofprod&hl=en
                            </div>
                        </body>
                    </html>
			`
			let emailList = [email,'delivery@zunroof.com']
            sendEmail(emailSubject, emailMessage, emailList).then((respose) => {
                if(respose){
                    console.log('Email Sent For Commissioning Date')
                }
            })
		}
		if(prevValue.sales_Date !== lastValue.sales_Date){
			updateSalesDateBitrix(quoteId, lastValue.sales_Date)
		}
		pushPastStage(siteId,current_State)
		pushNextActionDate(dispatch,siteId,userphone,stageArray,oldSaleDate,prevActionDate,oldRefDate,lastValue.next_Date,lastValue.sales_Date,lastValue.ref_Date,current_State,prevState,map);
		} else {
			var finalVal = {
				current_State:current_State!=-1?(""+current_State):"NA",
				sales_Date:saleDate?convertDate(saleDate):"NA",
				next_Date:next_date?convertDate(next_date):"NA",
				ref_Date:ref_Date?convertDate(ref_Date):"NA",
				past_State:"NA",
				change_By: ""+user
			}
			stageArray.push(finalVal);
			if(map[""+current_State] == "Delivered") {
				fetchOrderStatus(siteId);
				triggerWhatsapp(siteId).then();
			}
			updateSalesDateBitrix(quoteId, finalVal.sales_Date)
			pushPastStage(siteId,current_State)
			pushNextActionDate(dispatch,siteId,user,stageArray,"NA","NA","NA",finalVal.next_Date,finalVal.sales_Date,finalVal.ref_Date,finalVal.current_State,"NA",map)
		}
	})
	.catch(error=>{
        console.log(error);
	})
}

function pushNextActionDate(dispatch,siteId,user,stageArray,oldSaleDate,prevActionDate,oldRefDate,next_Date,sales_Date,ref_Date,current_State,prevState,map) {
	console.log("DATA : ",stageArray);
	var immediatelyAvailableReference  = base.post('/delivery/sites/'+siteId+'/stage', {
	    data: stageArray
	    ,then(err){
	      if(!err){
	      	console.log(current_State,prevState);
	      	if(current_State!=prevState) {
	      		let processCheck = {
		      		siteId:siteId,
	                user:user,
	                nameVar:"Stage",
	                newChangeValue:map[""+current_State],
	                oldChangeValue:map[""+prevState],
		      	}
		      	dispatch({type:ADDING_COMMENT_STATUS,payload:true})
		      	getCommentProcessRequest(processCheck,dispatch);
	      	}
	      }else{
	      	console.log(err);
	      }
	    }
	  });
}

//TO STORE THE PAST STAGE FOR APP

function pushPastStage(siteId,current_State) {
	base.post('/delivery/sites/'+siteId+'/paststage', {
		data:current_State,
		then(err){
			if(!err){
				console.log("SUCCESSFULLY ADDED STAGE");
			}
		}
	});
}

// TO CHANGE THE ORDER STATUS OF NET METERING
function pushOrderStatusNetMetring(siteId,data) {
	base.post('/delivery/sites/'+siteId+'/orderStatus', {
		data:data,
		then(err){
			if(!err){
				console.log("SUCCESSFULLY ADDED NET METERING STATUS");
			}
		}
	});
}




function fetchOrderStatus(siteId) {
	base.fetch('/delivery/sites/'+siteId+'/orderStatus', {}).
	then(data => {
		let orderStatus = {};
		console.log("DATA ADDING : ",data);
		console.log("DATA ADDING : ", Object.keys(data).length);
		console.log("DATA ADDING : ", Object.keys(data).length<5);
		if(!data || Object.keys(data).length<5){
			console.log("DATA");
			orderStatus ={
			  "invertor" : {
			    "name" : "Invertor",
			    "status" : 0
			  },
			  "netMetering" : {
			    "name" : "Net Metering",
			    "status" : 0
			  },
			  "orderConfirmationInternal" : {
			    "name" : "Order Confirmation",
			    "status" : 0
			  },
			  "panel" : {
			    "name" : "Panel",
			    "status" : 0
			  },
			  "structure" : {
			    "name" : "Structure",
			    "status" : 0
			  },
			  "subsidyApply" : {
			    "name" : "Subsidy Apply",
			    "status" : 0
			  }
			}
			orderStatus.netMetering = {
				name: "Net Metering",
				status: 1,
				expectedCompletionDate:createDateObj(new Date().getTime())
			}
		}else{
			orderStatus = data;
			orderStatus.netMetering = {
				name: "Net Metering",
				status: 1,
				expectedCompletionDate:createDateObj(new Date().getTime())
			}
		}
		pushOrderStatusNetMetring(siteId,orderStatus);
	});
}

async function createDeliveredOrderTickets(quoteId, user){
	try{
		let requestData = {
			quoteId,
			employeeId: user,
			key: "lhxXHlJAz4xwesf"
		};
		requestData = new URLSearchParams(requestData);
		await axios.post("https://operations.zunroof.com/api/createprojectcompletionticket", requestData, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
		});
		await axios.post("https://operations.zunroof.com/api/createinvoiceticket", requestData, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
		});
		return '';
	}
	catch(e){
		return '';
	}
}

async function updateSalesDateBitrix(quoteId, salesDate){
	try{
		console.log("Update Date")
		if(!moment(salesDate, "DD-MM-YYYY").isValid()){
			return;
		}
		let reqData = {
			quoteId: quoteId,
			salesDate: moment(salesDate, "DD-MM-YYYY").format("YYYYMMDD")
		};
		let resData = await axios.post("https://operations.zunroof.com/api/updatesalesdatebitrix", reqData);
		console.log("DONE", resData);
	}
	catch(e){
		console.log("error", e);
	}
}

const triggerWhatsapp = async (siteId) => {
	let {mSite_QuoteId : quoteId, mSite_OwnerId : customerId} = (await db.ref(`/sites/${siteId}`).once("value")).val();
	let customerName = await getNameById(customerId);
	let requestData = {
			key : "4VhfN1xiAD",
			phoneNumber : customerId,
			trigger : "orderDelivered",
			quoteId : quoteId,
			customerName : customerName
	}
	requestData = new URLSearchParams(requestData);
	let data = await axios.post("https://zuntalks.in:3000/whatsapp/trigger", requestData, {
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
	});
}
