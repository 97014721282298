import { FETCH_TICKETS_SUCCESS, FETCH_TICKETS_START, FETCH_LAST_COMMENT } from './actiontypes';

import { base, db } from '../connection';

import moment from 'moment';

export function fetchTickets() {
    return (dispatch) => {
        dispatch({ type: FETCH_TICKETS_START });
        fetchCompleteTicketsData()
            .then(completeData => {
                dispatch({ type: FETCH_TICKETS_SUCCESS, payload: completeData })
            })
            .catch(e => {
                console.log(e);
                return e;
            })
    }
}

let quoteIdToState = {};
let zunPrimeMap = {};

async function fetchCompleteTicketsData() {
    try {
        let ticketsData = (await db.ref('/website/ticketSystem/tickets').once('value')).val();
        // let ticketsData = {};
        let dataComplaints = Object.values(ticketsData ? ticketsData : {});
        let result = await Promise.all([fetchTeamToEmployeeMap(), fetchEmployeeList(), fetchEmployees(), fetchQuoteId(), fetchStateEmployeeMap(), fecthStageMap(), fecthStateMap(), fecthNotificationMap()]);
        let teamEmployeeMap = {};
        let employeeList = {};
        let members = [];
        let quoteList = [];
        let stageMap = [];
        if (result[0]) {
            teamEmployeeMap = result[0];
        }
        if (result[1]) {
            employeeList = result[1];
        }
        if (result[2]) {
            members = result[2];
        }
        if (result[3]) {
            quoteList = result[3];
        }
        if (result[5]) {
            stageMap = result[5];
        }
        console.log("stageMap", stageMap);
        let dataComplaintFormat = await Promise.all(dataComplaints.map(async (v, i) => {
            if (v.dueDate < new Date().getTime() && (v.status == 'Open' || v.status == 'In Progress')) {
                v.overdue = 'overdue';
                v.assignedToName = employeeList[v.assignedTo + ""]
            }
            else {
                v.overdue = '';
            }
            v.assignedToName = employeeList[v.assignedTo + ""];
            let [salesDate, current_stage, siteId] = await fetchSalesDate(v.quoteId);
            v.salesDate = salesDate;
            v.stage = stageMap[parseInt(current_stage)];
            v.state = quoteIdToState[v.quoteId];
            v.siteId = siteId;
            v.ticketDateView = moment(parseInt(v.epoch)).format("DD MMM, YYYY");
            v.dueDateView = moment(parseInt(v.dueDate)).format("DD MMM, YYYY");
            v.zunPrime = zunPrimeMap[v.quoteId] || "No"
            v.closeTime = v.status=='Close'?v.closeTime:'';
            v.closeTimeView = v.closeTime?moment(parseInt(v.closeTime)).format("DD MMM, YYYY"):"";
            v.ticketUpdatedOnView = v.ticketUpdatedOn?moment(parseInt(v.ticketUpdatedOn)).format("DD MMM, YYYY"):"";
            return v;
        }));
        let completeData = {
            complaintData: dataComplaintFormat,
            teamEmployeeMap: teamEmployeeMap,
            employeeList: employeeList,
            members: members,
            quoteList: quoteList,
            stateEmployeeMap: result[4],
            stageList: result[5],
            stateList: result[6],
            notificationMap: result[7]
        };
        return completeData;
    }
    catch (e) {
        return {};
    }
}

function fetchTeamToEmployeeMap() {
    return new Promise((resolve, reject) => {
        let teamToEmployeeMap = {};
        base.fetch('/website/ticketSystem/teamToEmployeeMap', {})
            .then(data => {
                for (var key in data) {
                    let teamId = key;
                    teamToEmployeeMap[data["" + teamId].teamName] = Object.keys(data["" + teamId].members);
                }
                resolve(teamToEmployeeMap);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            })
    });
}

function fetchEmployeeList() {
    return new Promise((resolve, reject) => {
        base.fetch('/employeelist', {})
            .then(data => {
                let employeeMap = {};
                for (var key in data) {
                    employeeMap["" + key] = data["" + key].fname + " " + data["" + key].lname;
                }
                resolve(employeeMap);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            })
    });
}

function fetchEmployees() {
    return new Promise((resolve, reject) => {
        let members = [];
        base.fetch('/website/ticketSystem/members', {})
            .then(data => {
                resolve(data);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            })
    });
}

async function fetchQuoteId() {
    let deliverySites = (await db.ref(`delivery/webPortal/deliverySiteMapping`).once('value')).val();
    let quoteList = await Promise.all(Object.keys(deliverySites ? deliverySites : {}).map(async siteId => {
        let quoteId = deliverySites[siteId];
        let userId = (await db.ref(`mapstore/quoteidtocustomerid/${quoteId}`).once('value')).val();
        let quoteData = {
            quoteId: quoteId,
            name: ''
        }
        if (userId) {
            let userData = (await db.ref(`/users/${userId}/mUser_Contact`).once('value')).val();
            let name = "";
            if (userData) {
                name = `${userData.mContact_FirstName || ""} ${userData.mContact_LastName || ""}`
            }
            // let webPortalObj = (await db.ref(`delivery/webPortal/sites/${siteId}`).once('value')).val() || {};
            let [ webPortalObj, stage ] = await Promise.all([
                db.ref(`delivery/webPortal/sites/${siteId}`).once('value'),
                db.ref(`delivery/sites/${siteId}/stage`).once('value')
            ])
            webPortalObj = webPortalObj.val() || {};
            let state = webPortalObj.state_D;
            let gridTied = webPortalObj.systemType_D;
            if (!state || !gridTied) {
                let siteObj = (await db.ref(`sites/${siteId}`).once('value')).val() || {};
                state = state ? state : siteObj.mSite_State;
                gridTied = gridTied ? gridTied : siteObj.mSite_SystemType;
            }
            stage = stage.val() || [];
            stage = stage.length?(stage[stage.length - 1].current_State):"";
            quoteIdToState[quoteId] = state;
            zunPrimeMap[quoteId] = webPortalObj.zunPrime?"Yes":"No"
            quoteData = {
                quoteId,
                name,
                state,
                gridTied,
                stage,
                userId
            }
        }
        return quoteData;
    }));
    return quoteList;
}

async function fecthStageMap() {
    try {
        let stageMap = (await db.ref(`/delivery/stagelist`).once('value')).val();
        return stageMap.map(stage => stage.name);
    }
    catch{
        return [];
    }
}

async function fecthStateMap() {
    try {
        let stateMap = (await db.ref(`/uploads/systemdesign/state_city_list_0`).once('value')).val();
        return Object.keys(stateMap);
    }
    catch{
        return [];
    }
}

async function fecthNotificationMap() {
    try {
        let notificationMap = (await db.ref(`/presetNotifications`).once('value')).val();
        return notificationMap;
    }
    catch{
        return [];
    }
}

export function fetchLastComment(ticketId) {
    return (dispatch) => {
        fetchComment(ticketId).then(comment => {
            if (comment) {
                dispatch({ type: FETCH_LAST_COMMENT, payload: Object.values(comment)[0] })
            }
            else {
                dispatch({ type: FETCH_LAST_COMMENT, payload: { comment: "No Comment", ticketId } })
            }
        })
    }
}

async function fetchComment(ticketId) {
    let lastComment = (await db.ref(`website/ticketSystem/comments/${ticketId}`).orderByKey().limitToLast(1).once('value')).val();
    return lastComment;
}

async function fetchStateEmployeeMap() {
    try {
        let stateEmployeeMap = (await db.ref(`website/ticketSystem/stateEmployeeMap`).once('value')).val();
        return stateEmployeeMap ? stateEmployeeMap : {};
    }
    catch (e) {
        return {};
    }
}

async function fetchSalesDate(quoteId) {
    try {
        let siteId = (await db.ref(`quotestaken/${quoteId}/siteId`).once('value')).val();
        let salesDate = '';
        let current_stage = '';
        if (siteId) {
            let stage = (await db.ref(`delivery/sites/${siteId}/stage`).once('value')).val();
            if (stage) {
                salesDate = stage[stage.length - 1].sales_Date;
                current_stage = stage[stage.length - 1].current_State;
                salesDate = new Date(`${salesDate.split('-')[2]}-${salesDate.split('-')[1]}-${salesDate.split('-')[0]}`).getTime();
            }
        }
        return [salesDate, current_stage, siteId];
    }
    catch (e) {
        return ['', '', ''];
    }
}