import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import Select from 'react-select';
import { changeToViewDateTime, changeToInputDate, changeToViewDate } from '../helper/dateChanger';
import { Restrict } from '../components/Restrict';
import { salesAnalyticsTable1, salesAnalyticsTable2, salesAnalyticsTable3 } from '../config/column_fields';
import Modal from 'react-responsive-modal';
import { fetchSalesAnalytics, setTarget } from '../actions/fetchSalesAnalytics';


class NewSalesAnalytics extends Component {


	constructor(props) {
		super(props);
		this.months = [
			{label:'Jan', value:1},
			{label:'Feb', value:2},
			{label:'Mar', value:3},
			{label:'Apr', value:4},
			{label:'May', value:5},
			{label:'Jun', value:6},
			{label:'Jul', value:7},
			{label:'Aug', value:8},
			{label:'Sep', value:9},
			{label:'Oct', value:10},
			{label:'Nov', value:11},
			{label:'Dec', value:12}
		];
		this.yearOptions = [];
		let currentYear = new Date().getFullYear();
		let currentMonth = new Date().getMonth();
		this.selectedMonth = this.months[currentMonth];
		this.selectedYear = {label:currentYear, value:currentYear};
		this.monthOptions = this.months.filter((v, i) => {
			if(i<=currentMonth){
				return true;
			}
		})
		for(let i=currentYear;i>=2017;i--){
			this.yearOptions.push({label:i,value:i});
		}
		this.state = {
			viewData1:[],
			viewData2:[],
			viewData3:[],
            table1Columms:salesAnalyticsTable1,
            table2Columms:salesAnalyticsTable2,
			table3Columms:salesAnalyticsTable3,
			loading:true,
			monthOptions:this.monthOptions,
			year:'',
			month:'',
			singleEditStatus:{},
			saTarget:{},
			roofTarget:{},
			monthSelected:0,
			currentMonthId:0,
			stateCityList:[],
			stateSelected:[]
        };
	}

	componentDidMount() {
		if (this.props.user) {
			if (this.props.user.level >= 2300) {
				let currentDate = new Date();
				let currentYear = currentDate.getFullYear();
				let currentMonth = currentDate.getMonth()+1;
				let selectedMonth = currentYear*100 + currentMonth;
				let currentMonthId = selectedMonth;
				let endYear = currentYear;
				let endMonth = currentMonth+1;
				if(endMonth>12){
					endMonth = 1;
					endYear+=1;
				}
				let startEpoch = new Date(`${currentYear}-${currentMonth}-1`).getTime();
				let endEpoch = new Date(`${endYear}-${endMonth}-1`).getTime();
				this.props.fetchSalesAnalytics(startEpoch, endEpoch, selectedMonth, []);
				let { table1Columms } = this.state;
				let stateColumns = table1Columms.map(val => {
					if(val.accessor == 'saTarget' || val.accessor == 'roofTarget'){
						return {
							...val, Cell: row => {
								return (this.state.singleEditStatus[row.index] && this.state.singleEditStatus[row.index][row.column.id] && this.state.monthSelected == this.state.currentMonthId) ?
									this.inputNumberDiv(row) : this.deafultViewDiv(row)
							}
						}
					}
					else{
						return val;
					}
				})
				this.setState({
					table1Columms:stateColumns,
					monthSelected:selectedMonth,
					currentMonthId:selectedMonth,
					year:currentYear,
					month:currentMonth
				})
			}
		}
	}


	deafultViewDiv = (row) => {
		let value = 0;
		let state = row.original.state;
		if (row.column.id === "saTarget") {
			value = this.state.saTarget?this.state.saTarget[state]:0;
		}
		if(row.column.id === "roofTarget"){
			value = this.state.roofTarget?this.state.roofTarget[state]:0;
		}
		return (<div onDoubleClick={() => { this.handleOnTableCellClick(row.index, row.column.id) }} >{value ? value : "Unavailable"}</div>);
	}

	inputNumberDiv = (row) => {
		let defaultValue = 0;
		let state = row.original.state;
		if (row.column.id === "saTarget") {
			defaultValue = this.state.saTarget?this.state.saTarget[state]:0;
		}
		if(row.column.id === "roofTarget"){
			defaultValue = this.state.roofTarget?this.state.roofTarget[state]:0;
		}
		return <input type="number" onKeyUp={(e) => { this.handleOnKeyPress(e, row, row.index, row.column.id) }} className="form-control" name={`${row.index}__${row.column.id}`} defaultValue={defaultValue} />
	}

	handleOnTableCellClick = (index, id) => {
		let indexUpdate = this.state.singleEditStatus[index] ? this.state.singleEditStatus[index] : {};
		indexUpdate[id] = true;
		this.setState({
			singleEditStatus: { ...this.state.singleEditStatus, [index]: indexUpdate }
		})
	}

	handleOnKeyPress = (e, row, index, columnId) => {
		let { value } = e.target;
		let { singleEditStatus } = this.state;
		if (e.keyCode === 13 && !e.shiftKey) {
			let indexUpdate = singleEditStatus[index] ? singleEditStatus[index] : {};
			indexUpdate[columnId] = false;
			let saTarget = {...this.state.saTarget};
			let roofTarget = {...this.state.roofTarget};
			let stateData = [...this.state.viewData1];
			if(columnId == 'saTarget'){
				saTarget[row.original.state] = value;
				this.props.setTarget(this.state.monthSelected, row.original.state, value, "saTarget");
				stateData[index].saTarget = value;
			}
			if(columnId == 'roofTarget'){
				roofTarget[row.original.state] = value;
				this.props.setTarget(this.state.monthSelected, row.original.state, value, "roofTarget");
				stateData[index].roofTarget = value;
			}
			this.setState({
				singleEditStatus: { ...this.state.singleEditStatus, [index]: indexUpdate },
				saTarget,
				roofTarget,
				viewData1:stateData
			})
		}
		if (e.keyCode === 27) {
			let indexUpdate = this.state.singleEditStatus[index] ? this.state.singleEditStatus[index] : {};
			indexUpdate[columnId] = false;
			this.setState({
				singleEditStatus: { ...this.state.singleEditStatus, [index]: indexUpdate },
			});
		}
	}

	componentWillReceiveProps(props) {
		if(props.salesData && props.salesData.data){
			let { stateData, stateCityData, employeeData, saTarget, roofTarget, stateCityList } = { ...props.salesData.data };
			let dataState = stateData.map(val => {
				val.saTarget = saTarget?saTarget[val.state]:'';
				val.roofTarget = roofTarget?roofTarget[val.state]:'';
				return val;
			});
			this.setState({
				viewData1:dataState,
				viewData2:stateCityData,
				viewData3:employeeData,
				loading:false,
				singleEditStatus:{},
				saTarget,
				roofTarget,
				stateCityList
			})
		}
	}

	onYearChange = (e) => {
		let year = e.value;
		if(year<new Date().getFullYear()){
			this.setState({
				monthOptions:this.months,
				year:e.value
			})
		}
		else{
			let monthOptions = this.months.filter((v, i) => {
				if(i<=new Date().getMonth()){
					return true;
				}
			})
			this.setState({
				monthOptions,
				year:e.value
			})
		}
	}

	onMonthChange = (e) => {
		this.setState({
			month:e.value
		})
	}

	onStateChange = (e) => {
		this.setState({
			stateSelected:e
		})
	}

	applyFilter = (e) => {
		let currentMonth = parseInt(new Date().getFullYear())*100 + (parseInt(new Date().getMonth())+1);
		let selectedMonth = parseInt(this.state.year)*100 + parseInt(this.state.month);
		if(selectedMonth <= currentMonth){
			let startEpoch = new Date(`${this.state.year}-${this.state.month}-1`).getTime();
			let endMonth = this.state.month + 1;
			let endYear = this.state.year;
			if(endMonth>12){
				endMonth = 1;
				endYear+=1;
			}
			let endEpoch = new Date(`${endYear}-${endMonth}-1`).getTime();
			let stateList = this.state.stateSelected.map(obj => {
				return obj.value;
			})
			this.props.fetchSalesAnalytics(startEpoch, endEpoch, selectedMonth, stateList);
			this.setState({
				loading: true,
				monthSelected:selectedMonth
			})
		}
		else{
			alert("Invalid Month");
		}
	}

	render() {
		const style = {
			hide: {
				display: 'none'
			},
			show: {
				display: ''
			},
			iconShow: {
				float: 'right',
				display: ''
			},
			iconHide: {
				float: 'right',
				display: 'none'
			}
		};
		if (this.props.user === null) {
			return <Redirect to={"/login?redirect_uri=newsa"} />;
		}

		if (this.props.user && this.props.user.level < 2300) {
			return (
				<div>
					<Restrict />
				</div>
			)
		}

		return (
            <div className="container-fluid" style={{marginTop:"50px"}}>
				<div className="row">
					<div className="col-md-2 col-6">
						<Select
							isSearchable
							onChange={ (e)=>this.onYearChange(e)  }
							placeholder={"Select Year"}
							name={"year"}
							options={this.yearOptions}
							defaultValue={this.selectedYear}
						/>
					</div>
					<div className="col-md-2 col-6">
						<Select
							isSearchable
							onChange={ (e)=>this.onMonthChange(e) }
							placeholder={"Select Month"}
							name={"month"}
							options={this.state.monthOptions}
							defaultValue={this.selectedMonth}
						/>
					</div>
					<div className="col-md-2 col-6">
						<Select
							isMulti
							isSearchable
							onChange={ (e)=>this.onStateChange(e) }
							placeholder={"Select State"}
							name={"selectedState"}
							options={this.state.stateCityList.map(val=>{return{label:val, value:val}})}
						/>
					</div>
					<div className="col-md-2 col-6">
						<button onClick={(e)=>this.applyFilter(e)} className="btn btn-success">Apply Filter</button>
					</div>
				</div>
                <h3 style={{marginTop:"30px"}}>State Wise</h3>
                <ReactTable
					filterable={true}
					showPagination={true}
                    sortable={true}
                    defaultPageSize={5}
					data={this.state.viewData1}
					columns={this.state.table1Columms}
					defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
					loading={this.state.loading}
					style={{ textAlign: 'center' }}
					getTdProps = {(state, rowInfo, column) => {
						let { currentMonthId, monthSelected } = this.state;
						if(rowInfo && rowInfo.original && ((column.id == 'saActual' && rowInfo.original.saTarget) || (column.id == 'roofActual' && rowInfo.original.roofTarget)) && (monthSelected < currentMonthId || new Date().getDate()>1)){
							let target;
							let actual;
							if(column.id == 'saActual'){
								actual = parseInt(rowInfo.original.saActual || 0);
								if(monthSelected == currentMonthId){
									let daysInCurrentMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
									let currentDate = new Date().getDate();
									target = parseInt(parseInt(rowInfo.original.saTarget)*(currentDate-1)/daysInCurrentMonth);
								}
								else{
									target = parseInt(rowInfo.original.saTarget);
								}
							}
							else{
								actual = parseInt(rowInfo.original.roofActual || 0);
								if(monthSelected == currentMonthId){
									let daysInCurrentMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
									let currentDate = new Date().getDate();
									target = parseInt(parseInt(rowInfo.original.roofTarget)*(currentDate-1)/daysInCurrentMonth);
								}
								else{
									target = parseInt(rowInfo.original.roofTarget);
								}
							}
							if(actual >= target){
								return {
									style: {
										backgroundColor: '#6ec5a2'
									}
								}
							}
							else if(actual/target >= 0.9){
								return {
									style: {
										backgroundColor: '#e4e89f'
									}
								}
							}
							else{
								return {
									style: {
										backgroundColor: '#f5adad'
									}
								}
							}
						}
						else{
							return {
								style: {
									backgroundColor: 'white'
								}
							}
						}
					}}
				/>
                <h3 style={{marginTop:"30px"}}>State City</h3>
                <ReactTable
					filterable={true}
					showPagination={true}
                    sortable={true}
                    defaultPageSize={5}
					data={this.state.viewData2}
					columns={this.state.table2Columms}
					defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
					loading={this.state.loading}
					style={{ textAlign: 'center' }}
				/>
                <h3 style={{marginTop:"30px"}}>Employee</h3>
                <ReactTable
					filterable={true}
					showPagination={true}
                    sortable={true}
                    defaultPageSize={5}
					data={this.state.viewData3}
					columns={this.state.table3Columms}
					defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
					loading={this.state.loading}
					style={{ textAlign: 'center' }}
				/>
            </div>
		);


	}
}



function mapStateToProps(state) {
	return {
		user: state.user,
		salesData:state.newsalesanalytics?state.newsalesanalytics.data:{}
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({fetchSalesAnalytics, setTarget }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(NewSalesAnalytics);