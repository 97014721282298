import { ADD_USER_START, ADD_USER_SUCCESS } from './actiontypes';

import { base } from '../connection';

import { fetch, set, push, fetchOrderByChild, fetchOrderByKey, fetchOrderByValue } from '../helper/firebaseFunctions';

import { db } from '../connection.js';

export function addNewUser(user) {
	return (dispatch) => {
		dispatch({type : ADD_USER_START});
    user.number = "+91" + user.number;
    addEmployeeRequest(user)
    .then(() => {
			db.ref("/addemployeerequest/" + user.number).on("child_changed", function(snapshot) {
			  let changedData = snapshot.val();
				console.log("Event Triggered", changedData);
				if (changedData == 1) {
					console.log("ADD_USER_SUCCESS");
					dispatch({type : ADD_USER_SUCCESS, payload : "SUCCESS"});
				}
			});
    })
	}
}

const addEmployeeRequest = async (user) => {
	try {
    let d = new Date();
    let name = user.name;
		let fname = " ";
		let lname = " ";
		if(user.name.indexOf(" ") >= 0){
			fname = user.name.slice(0,user.name.indexOf(" ")) || " ";
	    lname = user.name.slice(user.name.indexOf(" ") + 1) || " ";
		}
		else {
			fname = user.name;
		}
		console.log("fname: ", fname);
		console.log("lname: ", lname);
    let employeelistNode = await db.ref("/addemployeerequest/" + user.number).set({
      fname : fname,
      lastlogin : {
        date : d.getDate(),
        day : d.getDay(),
        hours : d.getHours(),
        minutes : d.getMinutes(),
        month : d.getMonth(),
        seconds : d.getSeconds(),
        time : d.getTime(),
        timezoneOffset : d.getTimezoneOffset(),
        year : d.getFullYear()
      },
      listing : user.number,
      lname : lname,
      phone : user.number,
			requestingrole : 900,
			responseadd : 0,
      role : 500,
			rolename : "ZunRoof Employee",
      userid : user.number
    });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

// export function addNewUser(user) {
// 	return (dispatch) => {
// 		dispatch({type : ADD_USER_START});
//     user.number = "+91" + user.number;
//     addNumber(user)
//     .then(() => {
//       return addEmpId(user)
//     })
//     .then(() => {
//       dispatch({type : ADD_USER_SUCCESS, payload : "SUCCESS"});
//     })
// 	}
// }

const addNumber = async (user) => {
  try {
    let userNode = await db.ref("/users/" + user.number).set({
      mUser_Contact : {
        mContact_Phone : user.number
      },
      mUser_Id : user.number,
      mUser_Role : {
        mRole_Name : "ZunRoof Employee",
        mRole_PermissionLevel : 500
      }
    });
    let d = new Date();
    let name = user.name;
		let fname = " ";
		let lname = " ";
		if(user.name.indexOf(" ") >= 0){
			fname = user.name.slice(0,user.name.indexOf(" ")) || " ";
	    lname = user.name.slice(user.name.indexOf(" ") + 1) || " ";
		}
		else {
			fname = user.name;
		}
		console.log("fname: ", fname);
		console.log("lname: ", lname);
    let employeelistNode = await db.ref("/employeelist/" + user.number).set({
      fname : fname,
      lastlogin : {
        date : d.getDate(),
        day : d.getDay(),
        hours : d.getHours(),
        minutes : d.getMinutes(),
        month : d.getMonth(),
        seconds : d.getSeconds(),
        time : d.getTime(),
        timezoneOffset : d.getTimezoneOffset(),
        year : d.getFullYear()
      },
      listing : user.number,
      lname : lname,
      phone : user.number,
      role : 500,
      userid : user.number
    });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

const addEmpId = async (user) => {
  try {
    let nameMapping = await db.ref("/delivery/webPortal/employeeIdToNameMapping/" + user.empId).set(user.name);
    let companyMapping = await db.ref("/delivery/webPortal/employeeToCompanyIdMapping/" + user.number + "/companyId").set(user.empId);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}
