import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import Select from 'react-select';
import { Restrict } from '../components/Restrict';
import { panelInverterStatusColumns } from '../config/column_fields';
import { fetchPanelInverterStatus } from '../actions/fetchPanelInverterStatus';
import { fetchmapping } from '../actions/fetchmapping';
import moment from 'moment';
import { CSVLink } from 'react-csv';

class PanelInverterStatus extends Component {

    constructor(props) {
        super(props);
        this.state = {
            viewData: [],
            filters: {
                type: "Panel"
            },
            loading: true
        };
    }

    componentDidMount() {
        if (this.props.user) {
            if (this.props.user.level >= 1900) {
                this.props.fetchPanelInverterStatus();
                this.props.fetchmapping();
            }
        }
    }


    componentWillReceiveProps(props) {
        console.log("props", props);
        let { panelData = [], inverterData = [], loading = true, stateMap = [] } = props;
        this.setState({
            panelData,
            inverterData,
            loading,
            stateMap,
            viewData: panelData
        })
    }

    handleSelectChangeFilters = (e, name) => {
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: e.value
            }
        })
    }

    handleMultiSelectChangeFilters = (e, name) => {
        let value = (e || []).map(val => val.value);
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value
            }
        })
    }

    handleInputChangeFilters = (e) => {
        let { name, value } = e.target;
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value
            }
        })
    }

    applyFilters = () => {
        let { filters = {}, panelData = [], inverterData = [] } = this.state;
        console.log("filters", filters);
        let { type, startDate, endDate, state = [], stage = [] } = filters;
        let data = (type == "Panel") ? panelData : inverterData;
        let selectedStart = startDate ? moment(startDate, "YYYY-MM-DD").valueOf() : 0;
        let selectedEnd = endDate ? moment(endDate, "YYYY-MM-DD").valueOf() : 9999999999999;
        let viewData = data.filter(row => {
            return (
                (!state.length || state.includes(row.state)) &&
                (!stage.length || stage.includes(row.stage)) &&
                (row.salesDateEpoch >= selectedStart && row.salesDateEpoch <= selectedEnd)
            )
        })
        this.setState({
            viewData
        })
    }

    render() {

        if (this.props.user === null) {
            return <Redirect to={"/login?redirect_uri=orderstatus"} />;
        }

        if (this.props.user && this.props.user.level < 1600) {
            return (
                <div>
                    <Restrict />
                </div>
            )
        }

        let { handleInputChangeFilters, handleSelectChangeFilters, applyFilters, handleMultiSelectChangeFilters } = this;
        let { stateMap = [], viewData } = this.state;
        let stateOptions = stateMap.map(val => ({ label: val, value: val }))
        return (

            <div style={{ "marginTop": 50 }} className="container-fluid">
                <div style={{ textAlign: "center" }}>
                    <h2 style={{ fontWeight: "bold" }}>Panel and Inverter In-Transit tracker</h2>
                </div>
                <div className="row filters v2-topbar-section">
                    <div className="col-12 col-md-2" style={{ width: '100%', paddingTop: '1%' }}>
                        <Select
                            isSearchable
                            placeholder={"Select"}
                            name={"type"}
                            options={["Panel", "Inverter"].map(val => ({ label: val, value: val }))}
                            onChange={(e) => handleSelectChangeFilters(e, "type")}
                            defaultValue={{ label: "Panel", value: "Panel" }}
                        />
                    </div>
                    <div className="col-12 col-md-2" style={{ width: '100%', paddingTop: '1%' }}>
                        <div className="form-group">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">Start Date</span>
                                </div>
                                <input className="form-control customInput" type="date" name={"startDate"} placeholder={"Start Date"} onChange={(e) => handleInputChangeFilters(e)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-2" style={{ width: '100%', paddingTop: '1%' }}>
                        <div className="form-group">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">End Date</span>
                                </div>
                                <input className="form-control customInput" type="date" name={"endDate"} placeholder={"End Date"} onChange={(e) => handleInputChangeFilters(e)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-2" style={{ width: '100%', paddingTop: '1%' }}>
                        <Select
                            isMulti
                            isSearchable
                            placeholder={"Select State"}
                            name={"state"}
                            options={stateOptions}
                            onChange={(e) => handleMultiSelectChangeFilters(e, "state")}
                        />
                    </div>
                    <div className="col-12 col-md-2" style={{ width: '100%', paddingTop: '1%' }}>
                        <Select
                            isMulti
                            isSearchable
                            placeholder={"Select Stage"}
                            name={"stage"}
                            options={["In Transit", "Not Delivered"].map(val => ({ label: val, value: val }))}
                            onChange={(e) => handleMultiSelectChangeFilters(e, "stage")}
                        />
                    </div>
                    <div className="col-6 col-md-1" style={{ width: '100%', paddingTop: '1%' }}>
                        <button className="btn btn-success" onClick={() => applyFilters()}>Apply Filter</button>
                    </div>
                    {(this.props.user.level == 1900 || this.props.user.level >= 2300) ? <div className="col-6 col-md-1" style={{ width: '100%', paddingTop: '1%' }}>
                        <CSVLink filename={"Order Status.csv"} data={viewData} headers={panelInverterStatusColumns.map((val, index) => { return { label: val.Header, key: val.csvAccessor ? val.csvAccessor : val.accessor } })} type="button" className="btn btn-info">
                            Download
						</CSVLink>
                    </div> : ""}
                </div>
                <ReactTable
                    filterable={true}
                    showPagination={true}
                    sortable={true}
                    data={viewData}
                    ref={this.reactTable}
                    columns={panelInverterStatusColumns}
                    defaultSorted={[{ id: "salesDateEpoch", desc: true }]}
                    style={{ height: "90vh", textAlign: 'center' }}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={this.state.loading}
                />
            </div>
        );


    }
}

const selectCustom = (options, name, placeholder, handleMultiSelectChange, defaultValue) => {
    const changeHandle = (value) => { handleMultiSelectChange(value, name) }
    return (
        <div className="drop_down1">
            <Select
                isMulti
                isSearchable
                onChange={(e) => { changeHandle(e) }}
                placeholder={placeholder}
                name={name}
                options={options}
                defaultValue={defaultValue}
            />
        </div>
    )
}


function mapStateToProps(state) {
    let { panelData, inverterData, loading } = state.panelinverterstatus || {};
    let { stateMap } = state.mapping || {};
    return {
        user: state.user,
        panelData,
        inverterData,
        loading,
        stateMap
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchPanelInverterStatus, fetchmapping }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelInverterStatus);