import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect, Link } from 'react-router-dom';
import ReactTable from "react-table";

import { styleSheet } from '../styles/styleSheet';

import { Restrict } from '../components/Restrict';
import { Loader } from "../components/Loader.jsx";
import { VendorAccessPageComponent } from "../components/VendorAccessPageComponent.jsx";

import { addNewVendor, updateVendor, fileDownloadVendor, uploadFilesEdit, deleteVendor } from '../actions/setvendormanagement';
import { fetchmapping } from '../actions/fetchmapping';

import { columnVendorManagement } from '../config/column_fields';
import Modal from 'react-responsive-modal';

import '../styles/all.css';

const companyTypeOptions = [
	{ label: "Public Limited", value: "companyTypeId00" },
	{ label: "Private Limited", value: "companyTypeId01" },
	{ label: "Proprietorship", value: "companyTypeId02" },
	{ label: "Partnership", value: "companyTypeId03" },
]

const msmeOptions = [
	{ label: "Yes", value: "Yes" },
	{ label: "No", value: "No" }
]

const tableFields = [
	{ type: 2, title: "Acc Number", key: "accountNumber" },
	{ type: 1, title: "Address", key: "address" },
	{ type: 1, title: "Bank", key: "bank" },
	{ type: 3, title: "Company Type", key: "companyType" },
	{ type: 1, title: "Director", key: "director" },
	{ type: 2, title: "GST", key: "gst" },
	{ type: 1, title: "IFSC", key: "ifsc" },
	{ type: 1, title: "Name", key: "name" },
	{ type: 1, title: "Email", key: "email" },
	{ type: 1, title: "Pan card", key: "pancard" },
	{ type: 1, title: "POC name", key: "pocname" },
	{ type: 2, title: "Poc Phone 1", key: "pocphone1" },
	{ type: 2, title: "Poc phone 2", key: "pocphone2" },
	{ type: 1, title: "Cashfree Id", key: "cashfreeId" },
	{ type: 3, title: "MSME", key: "msme" },
	{ type: 4, title: "GST Certificate", key: "type1" },
	{ type: 4, title: "Pan Card", key: "type2" },
	{ type: 4, title: "Cancelled Cheque", key: "type3" },
	{ type: 4, title: "V2 Agreement", key: "type4" },
];

let companyTypeOptionsMap = {};

class VendorAccessPage extends Component {
	constructor(props) {
		super(props);

		companyTypeOptions.forEach(val => {
			companyTypeOptionsMap[val.value] = val.label;
		});

		this.state = {
			v1Permission: false, v2Permission: false, panelPermission: false, inverterPermission: false, structurePermission: false,
			vendorMap: [],
			singleEditStatus: {},
			companyTypeOptionsMap: companyTypeOptionsMap,
			columnVendorManagement: columnVendorManagement,
			openModal: false,
			selectedId: '',
			editStatus: {},
			files:[null,null,null,null],
			blobs:[null,null,null,null],
			editFiles:{},
			editBlobs:{}
		};
	}

	componentWillMount() {
		let { user, fetchmapping } = this.props;
		if (user && user.level >= 1700) {
			fetchmapping();
		}
	}

	componentDidMount() {
		let { companyTypeOptionsMap } = this.state;
		let columnMap = columnVendorManagement.map(val => {
			switch (parseInt(val.type)) {
				case 1:
					return {
						...val, Cell: row => {
							return this.state.singleEditStatus[row.index] && this.state.singleEditStatus[row.index][row.column.id] ?
								this.inputDiv(row) : this.deafultViewDiv(row)
						}
					}
				case 2:
					return {
						...val,
						Cell: row => {
							return this.state.singleEditStatus[row.index]
								&& this.state.singleEditStatus[row.index][row.column.id] ?
								this.selectInputDiv(row, companyTypeOptionsMap) : this.deafultViewDiv(row);
						}
					}
				case 4:
					return {
						...val, Cell: row => { return (<button onClick={() => { this.handleOnApprovedClick(row.original, row.column.id, row.value) }} className={row.value ? "btn btn-warning" : "btn btn-success"}>{row.value ? "Disapprove" : "Approve"}</button>) }
					}
				case 5:
					return {
						...val, Cell: row => {
							return this.state.singleEditStatus[row.index] && this.state.singleEditStatus[row.index][row.column.id] ?
								this.inputNumberDiv(row) : this.deafultViewDiv(row)
						}
					}
				default:
					let newValue = { ...val };
					return { ...newValue }
			}
		});
		this.setState({
			columnVendorManagement: columnMap
		})
	}

	componentWillReceiveProps({ vendorMap, error, success }) {
		this.setState({
			vendorMap
		})
	}

	deafultViewDiv = (row) => {
		let { companyTypeOptionsMap } = this.state;
		let value = row.value;
		if (row.column.id === "companyType") {
			value = companyTypeOptionsMap[row.value];
		}
		return (<div onDoubleClick={() => { this.handleOnTableCellClick(row.index, row.column.id) }} >{value ? value : "Unavailable"}</div>);
	}

	inputDiv = (row) => {
		let defaultValue = row.value;
		return <input onKeyUp={(e) => { this.handleOnKeyPress(e, row, row.index, row.column.id) }} className="form-control" name={`${row.index}__${row.column.id}`} defaultValue={defaultValue} />
	}

	inputNumberDiv = (row) => {
		let defaultValue = row.value ? row.value : 0;
		return <input type="number" onKeyUp={(e) => { this.handleOnKeyPress(e, row, row.index, row.column.id) }} className="form-control" name={`${row.index}__${row.column.id}`} defaultValue={defaultValue} />
	}

	selectInputDiv = (row, options) => {
		return (
			<div>
				<select
					onKeyUp={(e) => { this.handleOnKeyPress(e, row, row.index, row.column.id) }}
					onChange={(e) => { this.handleOnSelectUpdateChange(e, row, row.index, row.column.id) }}
					className="form-control" name={`${row.index}__${row.column.id}`}
					defaultValue={row.value ? row.value : ""}>
					{Object.keys(options).map(key => <option key={key} value={key}>{options[key]}</option>)}
				</select>
			</div>
		)
	}

	// IN CELL UPDATE AND SUBMIT
	handleOnKeyPress = (e, row, index, columnId) => {
		let { user, updateVendor } = this.props;
		let { singleEditStatus, vendorMap } = this.state;
		let { value } = e.target;
		if (e.keyCode === 13 && !e.shiftKey) {
			let indexUpdate = singleEditStatus[index] ? singleEditStatus[index] : {};
			indexUpdate[columnId] = false;
			let { id } = row.original;
			let update = { ...vendorMap[id], [columnId]: value };
			updateVendor({ user, id, columnId, value });
			this.setState({
				vendorMap: { ...vendorMap, [id]: update }
			})
		}
		if (e.keyCode === 27) {
			let indexUpdate = this.state.singleEditStatus[index] ? this.state.singleEditStatus[index] : {};
			indexUpdate[columnId] = false;
			this.setState({
				singleEditStatus: { ...this.state.singleEditStatus, [index]: indexUpdate },
			});
		}
	}

	handleOnSelectUpdateChange = (e, row, index, columnId) => {
		let { user, updateVendor } = this.props;
		let { singleEditStatus, vendorMap } = this.state;
		let { value } = e.target;
		if (e.keyCode === 27) {
			let indexUpdate = this.state.singleEditStatus[index] ? this.state.singleEditStatus[index] : {};
			indexUpdate[columnId] = false;
			this.setState({
				singleEditStatus: { ...this.state.singleEditStatus, [index]: indexUpdate },
			});
		} else {
			let indexUpdate = singleEditStatus[index] ? singleEditStatus[index] : {};
			indexUpdate[columnId] = false;
			let { id } = row.original;
			let update = { ...vendorMap[id], [columnId]: value };
			updateVendor({ user, id, columnId, value });
			this.setState({
				vendorMap: { ...vendorMap, [id]: update }
			})
		}
	}

	handleOnApprovedClick = (data, columnId, value) => {
		let { user, updateVendor } = this.props;
		let { vendorMap } = this.state;
		let { id } = data;
		let update = { ...vendorMap[id], [columnId]: !value };
		updateVendor({ user, id, columnId, value: !value });
		this.setState({
			vendorMap: { ...vendorMap, [id]: update }
		});
	}

	handleOnMultiSelectInputChange = (dataChange, index, id) => {
		let indexUpdate = this.state.singleEditStatus[index] ? this.state.singleEditStatus[index] : {};
		indexUpdate[id] = false;
		let getData = [...this.state.data];
		let valueGet = dataChange.map(val => val.value).toString();
		getData[index][id] = valueGet;
		let siteId = this.state.data[index].siteId;
	}

	// CHANGING THE CELL TO INPUT
	handleOnTableCellClick = (index, id) => {
		let indexUpdate = this.state.singleEditStatus[index] ? this.state.singleEditStatus[index] : {};
		indexUpdate[id] = true;
		this.setState({
			singleEditStatus: { ...this.state.singleEditStatus, [index]: indexUpdate }
		})
	}

	// HANDLE BULK CLICK CHECK BOX CHANGE
	handleBulkSelectOnChange = (e, row) => {
		let { selectCheckBox } = this.state;
		Object.keys(selectCheckBox).forEach(number => {
			selectCheckBox[number] = false;
		});
		row.forEach(data => {
			selectCheckBox[data.quoteId] = e.target.checked;
		});
		this.setState({ selectCheckBox });
	}

	handleOnMultiSelectChange = (selectedValue, name) => {
		this.setState({ [name]: selectedValue });
	}

	handleDateInputChange = (e) => {
		let addValue = 0;
		if (e.target.name === "startDate") {
			addValue = e.target.value ? e.target.value : 0;
		} else {
			addValue = e.target.value ? e.target.value : 9999999999999;
		}
		this.setState({
			[e.target.name]: new Date(addValue).getTime()
		})
	}

	handleOnInputChange = (e) => {
		let { name, value } = e.target;
		this.setState({
			[name]: value
		})
	}

	handleOnSelectChange = (value, name) => {
		this.setState({
			[name]: value
		})
	}

	handleOnInputCheckBoxChange = (e) => {
		let { name } = e.target;
		this.setState({
			[name]: !this.state[name]
		})
	}

	handleFormSubmit = (e) => {
		e.preventDefault();
		let { user, addNewVendor } = this.props;
		let formObject = { ...this.state };
		addNewVendor({ user, formObject });
	}

	vendorDetails = (selectedId) => {
		console.log("show details");
		this.setState({
			openModal: true,
			selectedId
		})
	}

	handleOnViewChange = (e) => {
		this.setState({
			selectView: e.target.name
		})
	}

	onCloseModal = (e) => {
		console.log("close modal");
		this.setState({
			openModal: false,
			editStatus: {}
		})
	}

	handleEditClick = (id, field) => {
		console.log(id);
		let status = { ...this.state.editStatus, [field]: 1 };
		this.setState({
			editStatus: status
		})
	}

	handleEditKeyPress = (e, id, field) => {
		let { user, updateVendor } = this.props;
		let { singleEditStatus, vendorMap } = this.state;
		let { value } = e.target;
		if (e.keyCode === 13 && !e.shiftKey) {
			let vendorMap = { ...this.state.vendorMap };
			vendorMap[id][field] = e.target.value;
			console.log("Update", id, field, e.target.value);
			let columnId = field;
			let value = e.target.value;
			updateVendor({ user, id, columnId, value });
			this.setState({
				vendorMap,
				editStatus: { ...this.state.editStatus, [field]: false }
			})
		}
		if (e.keyCode === 27) {
			console.log("ESC");
			this.setState({
				editStatus: { ...this.state.editStatus, [field]: false }
			})
		}
	}

	handleEditSelectChange = (e, id, field) => {
		let { user, updateVendor } = this.props;
		let { singleEditStatus, vendorMap } = this.state;
		let { value } = e.target;
		if (e.keyCode === 27) {
			console.log("ESC");
			this.setState({
				editStatus: { ...this.state.editStatus, [field]: false }
			})
		} else {
			let vendorMap = { ...this.state.vendorMap };
			vendorMap[id][field] = e.target.value;
			console.log("Update", id, field, e.target.value);
			let columnId = field;
			let value = e.target.value;
			updateVendor({ user, id, columnId, value });
			this.setState({
				vendorMap,
				editStatus: { ...this.state.editStatus, [field]: false }
			})
		}
	}

	handleFileChange = (e, index) => {
		let file = e.target.files[0];
		console.log(file);
		const formData = new FormData();
		formData.append('data',file);
		console.log("FORM DATA : ",formData);
		var blob = new Blob([file], { type: file.type, name:new Date().getTime() });
		if (file.size > 25000000) {
			console.log("Size :", file.size);
		}
		else {
			let files = [...this.state.files];
			let blobs = [...this.state.blobs];
			files[index] = file;
			blobs[index] = blob;
			this.setState({
				blobs,
				files
			});
		}
	}

	handleFileChangeEdit = (e, type) => {
		let file = e.target.files[0];
		console.log(file);
		const formData = new FormData();
		formData.append('data',file);
		console.log("FORM DATA : ",formData);
		var blob = new Blob([file], { type: file.type, name:new Date().getTime() });
		if (file.size > 25000000) {
			console.log("Size :", file.size);
		}
		else {
			let editFiles = {...this.state.editFiles};
			let editBlobs = {...this.state.editBlobs};
			editFiles[type] = file;
			editBlobs[type] = blob;
			this.setState({
				editFiles,
				editBlobs
			});
		}
	}

	uploadFileEdit = (type, vendorId) => {
		console.log("Download click type", type);
		console.log(this.state.editBlobs[type]);
		console.log(this.state.editFiles[type]);
		let file = this.state.editFiles[type];
		let blob = this.state.editBlobs[type];
		let user = this.props.user;
		this.props.uploadFilesEdit(user, file, blob, type, vendorId);
	}

	downloadClick = (type, fileName) => {
		console.log("Download Clicked");
		let user = this.props.user.phoneNumber;
		let selectedId = this.state.selectedId;
		let downloadObj = {
			requestId:type,
			userId:user,
			quoteId:selectedId,
			fileName
		};
		console.log(downloadObj);
		this.props.fileDownloadVendor(downloadObj);
	}

	deleteVendor = (selectedId) => {
		if(selectedId){
			this.props.deleteVendor(selectedId);
			console.log("delete vendor called", selectedId);
		}
	}

	render() {
		let { state, props, handleOnInputChange, handleOnSelectChange, handleOnInputCheckBoxChange, handleFormSubmit, handleOnViewChange, handleFileChange } = this;
		let { user, error, success } = props
		let { vendorMap, selectView, columnVendorManagement } = state;
		if (this.props.user === null) {
			return <Redirect to="/login?redirect_uri=vendormanagement" />;
		}
		console.log("blobs",this.state.blobs);
		console.log("files", this.state.files);
		if (this.props.user.level < 1700) {
			return (
				<div>
					<Restrict />
				</div>
			)
		}

		
		return (
			<div className="container-fluid">
				{
					success ? <div className="alert alert-success" role="alert">
						{success}
					</div> : ""
				}
				{
					error ? <div className="alert alert-danger" role="alert">
						{error}
					</div> : ""
				}
				<div className="formButtons heading-margin">
					<button className={`btn btn-primary paymentViewButton btn-color ${selectView === "formView" ? "select" : ""}`} name="formView" onClick={handleOnViewChange}>Form</button>
					<button className={`btn btn-primary paymentViewButton btn-color ${selectView === "tableView" ? "select" : ""}`} name="tableView" onClick={handleOnViewChange}>Vendors</button>
				</div>
				{
					selectView === "formView" ? <VendorAccessPageComponent
						{...state}
						user={user}
						companyTypeOptions={companyTypeOptions}
						handleOnInputChange={handleOnInputChange}
						handleOnSelectChange={handleOnSelectChange}
						handleOnInputCheckBoxChange={handleOnInputCheckBoxChange}
						handleFormSubmit={handleFormSubmit}
						handleFileChange={handleFileChange}
						msmeOptions={msmeOptions}
					/> : ""
				}
				{
					selectView === "tableView" ? <div style={{ marginTop: 20 }}>
						<ReactTable
							data={Object.values(vendorMap)}
							columns={[
								{
									Header:'id',
									accessor:'id'
								},
								{
								Header: 'name',
								accessor: 'name',
								type: 1,
								Cell: row => <a href="javascript:void(0);"><div onClick={() => this.vendorDetails(row.original.id)}>{row.original.name}</div></a>
							}, ...columnVendorManagement]}
							filterable={true}
							style={{ textAlign: 'center' }}
							defaultPageSize={10}
							defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
						/>
						<div>
							<Modal open={this.state.openModal} onClose={this.onCloseModal.bind(this)} closeOnEsc={false}>
								<div className="vendorData" style={{padding:'10px'}}>
								<div className='vendorName' style={{fontWeight:'bold', textAlign:'center', fontSize:'20px'}}>
									{this.state.selectedId?vendorMap[this.state.selectedId]['name']:''}
								</div>
								<table className="vendorDetail" style={{ width: '80%', margin: '0 auto', marginTop:'20px' }}>
									{
										tableFields.map(val => {
											//console.log(val);
											switch (val.type) {
												case 1:
													return (
														<tr>
															<td style={{ fontWeight:'bold',border: '1px solid #dddddd', padding: '8px', width: '50%' }}>{val.title}</td>
															<td style={{ border: '1px solid #dddddd', padding: '8px', width: '50%' }}  onDoubleClick={() => { this.handleEditClick(this.state.selectedId, val.key) }}>
																{!this.state.editStatus[val.key] ? <div >{this.state.selectedId ? (vendorMap[this.state.selectedId][val.key] ? vendorMap[this.state.selectedId][val.key] : 'Unavailable') : ''}</div> : <input onKeyUp={(e) => { this.handleEditKeyPress(e, this.state.selectedId, val.key) }} className="form-control" defaultValue={this.state.selectedId ? (vendorMap[this.state.selectedId][val.key] ? vendorMap[this.state.selectedId][val.key] : '') : ''} />}</td>
														</tr>
													)
												case 2:
													return (
														<tr>
															<td style={{ fontWeight:'bold',border: '1px solid #dddddd', padding: '8px', width: '50%' }}>{val.title}</td>
															<td style={{ border: '1px solid #dddddd', padding: '8px', width: '50%' }} onDoubleClick={() => { this.handleEditClick(this.state.selectedId, val.key) }} >
																{!this.state.editStatus[val.key] ? <div>{this.state.selectedId ? (vendorMap[this.state.selectedId][val.key] ? vendorMap[this.state.selectedId][val.key] : 'Unavailable') : ''}</div> : <input onKeyUp={(e) => { this.handleEditKeyPress(e, this.state.selectedId, val.key) }} className="form-control" defaultValue={this.state.selectedId ? (vendorMap[this.state.selectedId][val.key] ? vendorMap[this.state.selectedId][val.key] : '') : ''} />}</td>
														</tr>
													)
												case 3:
													if(val.key == "msme") {
														return (
															<tr>
																<td style={{ fontWeight:'bold',border: '1px solid #dddddd', padding: '8px', width: '50%' }}>{val.title}</td>
																<td style={{ border: '1px solid #dddddd', padding: '8px', width: '50%' }} onDoubleClick={() => { this.handleEditClick(this.state.selectedId, val.key) }} >
																	{!this.state.editStatus[val.key] ? <div>{this.state.selectedId ? (vendorMap[this.state.selectedId][val.key] ? vendorMap[this.state.selectedId][val.key] : 'Unavailable') : ''}</div> : <div>
																	<select
																		onKeyUp={(e) => { this.handleEditKeyPress(e, this.state.selectedId, val.key) }}
																		onChange={(e) => { this.handleEditSelectChange(e, this.state.selectedId, val.key) }}
																		className="form-control" defaultValue={this.state.selectedId ? (vendorMap[this.state.selectedId][val.key] ? vendorMap[this.state.selectedId][val.key] : '') : ''}>
																		{msmeOptions.map(row => <option key={row.value} value={row.value}>{row.label}</option>)}
																	</select>
																</div>}</td>
															</tr>
														)
													}
													return (
														<tr>
															<td style={{ fontWeight:'bold',border: '1px solid #dddddd', padding: '8px', width: '50%' }}>{val.title}</td>
															<td style={{ border: '1px solid #dddddd', padding: '8px', width: '50%' }} onDoubleClick={() => { this.handleEditClick(this.state.selectedId, val.key) }} >
																{!this.state.editStatus[val.key] ? <div>{this.state.selectedId ? (vendorMap[this.state.selectedId][val.key] ? companyTypeOptionsMap[vendorMap[this.state.selectedId][val.key]] : 'Unavailable') : ''}</div> : <div>
																<select
																	onKeyUp={(e) => { this.handleEditKeyPress(e, this.state.selectedId, val.key) }}
																	onChange={(e) => { this.handleEditSelectChange(e, this.state.selectedId, val.key) }}
																	className="form-control" defaultValue={this.state.selectedId ? (vendorMap[this.state.selectedId][val.key] ? vendorMap[this.state.selectedId][val.key] : '') : ''}>
																	{Object.keys(companyTypeOptionsMap).map(key => <option key={key} value={key}>{companyTypeOptionsMap[key]}</option>)}
																</select>
															</div>}</td>
														</tr>
													)
												case 2:
													return (
														<tr>
															<td style={{ fontWeight:'bold',border: '1px solid #dddddd', padding: '8px', width: '50%' }}>{val.title}</td>
															<td style={{ border: '1px solid #dddddd', padding: '8px', width: '50%' }}>
																{!this.state.editStatus[val.key] ? <div onDoubleClick={() => { this.handleEditClick(this.state.selectedId, val.key) }} >{this.state.selectedId ? (vendorMap[this.state.selectedId][val.key] ? vendorMap[this.state.selectedId][val.key] : 'Unavailable') : ''}</div> : <input onKeyUp={(e) => { this.handleEditKeyPress(e, this.state.selectedId, val.key) }} className="form-control" defaultValue={this.state.selectedId ? (vendorMap[this.state.selectedId][val.key] ? vendorMap[this.state.selectedId][val.key] : '') : ''} />}</td>
														</tr>
													)
												case 3:
													return (
														<tr>
															<td style={{ fontWeight:'bold',border: '1px solid #dddddd', padding: '8px', width: '50%' }}>{val.title}</td>
															<td style={{ border: '1px solid #dddddd', padding: '8px', width: '50%' }}>
																{!this.state.editStatus[val.key] ? <div onDoubleClick={() => { this.handleEditClick(this.state.selectedId, val.key) }} >{this.state.selectedId ? (vendorMap[this.state.selectedId][val.key] ? companyTypeOptionsMap[vendorMap[this.state.selectedId][val.key]] : 'Unavailable') : ''}</div> : <div>
																<select
																	onKeyUp={(e) => { this.handleEditKeyPress(e, this.state.selectedId, val.key) }}
																	onChange={(e) => { this.handleEditSelectChange(e, this.state.selectedId, val.key) }}
																	className="form-control" defaultValue={this.state.selectedId ? (vendorMap[this.state.selectedId][val.key] ? vendorMap[this.state.selectedId][val.key] : '') : ''}>
																	{Object.keys(companyTypeOptionsMap).map(key => <option key={key} value={key}>{companyTypeOptionsMap[key]}</option>)}
																</select>
															</div>}</td>
														</tr>
													)
												case 4:
													return (
														<tr>
															<td style={{ fontWeight:'bold',border: '1px solid #dddddd', padding: '8px', width: '50%' }}>{val.title}</td>
															<td style={{ border: '1px solid #dddddd', padding: '8px', width: '50%' }}>
																{(this.state.selectedId && vendorMap[this.state.selectedId][val.key])?<a href="javascript:void(0);"><div onClick={() => this.downloadClick(val.key, vendorMap[this.state.selectedId][val.key])}>{"Download"}</div></a>:<div className="row" style={{maxWidth:'100%'}}><input type="file" className="col-md-8" onChange={(e) => this.handleFileChangeEdit(e, val.key)}/><button type="button" className="btn col-md-4" onClick={(e) => this.uploadFileEdit(val.key, this.state.selectedId)}>Upload</button></div>}</td>
														</tr>
													)
											}

										})
									
									}
									{
										(this.props.user && this.props.user.level>=2500)?<tr>
										<td style={{ fontWeight:'bold',border: '1px solid #dddddd', padding: '8px', width: '50%' }}>Delete Vendor</td>
										<td style={{ border: '1px solid #dddddd', padding: '8px', width: '50%' }}><button className="btn btn-danger" onClick={() => this.deleteVendor(this.state.selectedId)}>Delete</button></td>
										</tr>:''
									}
								</table>
								</div>
							</Modal>
						</div>
					</div> : ""

				}
			</div>
		);
	}
}

function mapStateToProps({ user, mapping, vendortickets }) {
	let { employeeMap, vendorMap } = mapping;
	let { error, success } = vendortickets;
	return {
		user,
		vendorMap,
		error, success
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ addNewVendor, fetchmapping, updateVendor, fileDownloadVendor, uploadFilesEdit, deleteVendor }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorAccessPage); 
