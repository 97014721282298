import { FETCHING_OPS_REPORT_START, FETCHING_OPS_REPORT_SUCCESS } from './actiontypes';

import axios from 'axios';
import { db, base } from '../connection';
import { dotToEmail, emailToDot } from '../helper/helper';

export function fetchOpsReportData(selectedStates) {
	return (dispatch) => {
		dispatch({type:FETCHING_OPS_REPORT_START,payload:[]})
		// // fetch('http://localhost:3002/api/getOpsReport')
		// fetch('https://operations.zunroof.com/api/getOpsReport')
		// .then(response => response.json())
		// .then(data => {
		// 	dispatch({type:FETCHING_OPS_REPORT_SUCCESS,payload:data})
		// })

		let formdata = new URLSearchParams();
		formdata.append('key', 'g6muc4cFoPVVZvs');
		formdata.append('selectedStates', selectedStates);
		// axios.post("http://localhost:3002/api/getOpsReport", formdata, {
		axios.post("https://operations.zunroof.com/api/getOpsReport", formdata, {
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
		})
		.then((data) => {
			// console.log(data.data.finalData);
				dispatch({type:FETCHING_OPS_REPORT_SUCCESS,payload:data.data})
		})
	}
}

async function setTarget(value, columnId, month){
	return (dispatch) => {
		if(value && columnId && month){
			db.ref(`website/salesAnalytics/monthWiseTarget/${month}/${columnId}`).set(value)
			.then(data => {
				console.log("Target Updated");
			})
		}
		else{
			alert("Some error occured");
		}
	}
}
