import { FETCH_CURRENT_DATA_SUCCESS, FETCH_CURRENT_DATA_START, FETCH_TICKETS_START } from './actiontypes';

import axios from 'axios';

import { base, db } from '../connection';

// export function fetchCurrentData() {
//     return async (dispatch) => {
//         dispatch({ type: FETCH_CURRENT_DATA_START });
//         let start = new Date().getTime();
//         fetchCurrentCallData().then(data => {
//             dispatch({ type: FETCH_CURRENT_DATA_SUCCESS, payload: data });
//             console.log("Time", new Date().getTime() - start);
//         })
//     }
// }
async function fetchCurrentCallData() {
    try {
        console.log("FETCH CURRENT DATA");
        let currentData = (await db.ref(`delivery/webPortal/knowlarity/stream`).once('value')).val();
        let agentList = {};
        let currentCallData = await Promise.all(currentData.map(async callData => {
            if (callData) {
                let dateTime = callData['Event_Date_Local'];
                let dateTimeArray = '';
                let date = '';
                let time = '';
                if (dateTime) {
                    dateTimeArray = dateTime.split(' ');
                    date = dateTimeArray[0];
                    time = dateTimeArray[1];
                }
                callData.date = new Date(dateTime).getTime();
                callData.time = time;
                let quoteIdPaymentList = await fetchQuoteId(callData.customer_number);
                let paymentList = quoteIdPaymentList.map(data => data.payment);
                let quoteList = quoteIdPaymentList.map(data => data.quoteId);
                callData.quoteId = quoteList;
                callData.payment = paymentList;
                let agentName = await fetchAgentName(callData.agent_number);
                if(agentName){
                    callData.agentName = agentName;
                }
                else{
                    callData.agentName = callData.agent_number;
                }
                agentList[callData.agentName] = 1;
                let ticketsList = await fetchQuoteTickets(quoteList);
                callData.tickets = ticketsList;
                let userDetails = (await db.ref(`users/${callData.customer_number}/mUser_Contact/`).once('value')).val();
                if(userDetails){
                    callData.city = userDetails.mContact_City?userDetails.mContact_City:'';
                    callData.name = (userDetails.mContact_FirstName?userDetails.mContact_FirstName:'') + " " + (userDetails.mContact_LastName?userDetails.mContact_LastName:'');
                }
            }
            return callData;
        }));
        let totalData = {
            callData : currentCallData,
            agentList : Object.keys(agentList ? agentList : {})
        }
        return totalData;
    }
    catch (e) {
        return e;
    }
}


async function fetchQuoteId(number) {
    try {
        let userData = (await db.ref(`users/${number}`).once('value')).val();
        let quoteList = [];
        if (userData && userData.mUser_SitesViewableIdList) {
            let sitesList = userData.mUser_SitesViewableIdList;
            quoteList = await Promise.all(sitesList.map(async (siteId) => {
                let [quoteId, payment] = await Promise.all([db.ref(`sites/${siteId}/mSite_QuoteId`).once('value'), db.ref(`delivery/sites/${siteId}/pctpaymentCompleted`).once('value')])
                quoteId = quoteId.val();
                payment = payment.val();
                // let quoteId = (await db.ref(`sites/${siteId}/mSite_QuoteId`).once('value')).val();
                // if(quoteId){
                return { quoteId, payment };
                // }
            }))
        }
        return quoteList;
    }
    catch (e) {
        return e;
    }
}


async function fetchAgentName(agentNumber) {
    try {
        let userData = (await db.ref(`users/${agentNumber}/mUser_Contact`).once('value')).val();
        if (userData) {
            return userData.mContact_FirstName + " " + userData.mContact_LastName;
        }
        else {
            return '';
        }
    }
    catch (e) {
        return e;
    }
}

async function fetchQuoteTickets(quoteList) {
    try {
        let tickets = [];
        let promiseList = await Promise.all((quoteList ? quoteList : []).map(async (quoteId) => {
            let ticketList = (await db.ref(`website/ticketSystem/quoteToTicketsMap/${quoteId}`).once('value')).val();
            if (ticketList) {
                tickets.concat(Object.keys(ticketList));
                return Object.keys(ticketList);
            }
            else {
                return [];
            }
        }));
        let merged = [];
        if (promiseList) {
            merged = promiseList.reduce(function (prev, next) {
                return prev.concat(next);
            }, []);
        }
        let openTickets = [];
        let openTicket = await Promise.all(merged.map(async ticketId => {
            let ticketData = (await db.ref(`website/ticketSystem/tickets/${ticketId}`).once('value')).val();
            if(ticketData && ticketData.status && ticketData.status != 'Close'){
                let currentTime = new Date().getTime();
                let status = 3;
                if(currentTime > ticketData.dueDate){
                    status = 1;
                }
                else if(ticketData.dueDate - currentTime < 86400000){
                    status = 2;
                }
                openTickets.push({
                    ticketId,
                    status,
                    type: ticketData.type
                });
            }
        }))
        return openTickets;
    }
    catch (e) {
        return e;
    }
}



export function fetchCurrentData() {
    return async (dispatch) => {
        console.log("fetchCurrentData start");
        dispatch({ type: FETCH_CURRENT_DATA_START });
        let start = new Date().getTime();
        fetch('https://konnectprodstream3.knowlarity.com:8200/update-stream/6b8b0678-2f95-429d-9e6c-3347c3cbc00a/konnect', {
            responseType: 'stream'
        }).then(response => {
            const stream = response.body.getReader();
            console.log("inside response", response, stream);
            let decoder = new TextDecoder('utf-8');
            stream.read().then(async function processData({ done, value }) {
                console.log("chunk", decoder.decode(value));
                let chunk = decoder.decode(value);
                let msg = chunk;
                if (msg.indexOf('{') != -1) {
                    msg = msg.slice(msg.indexOf('{'), msg.indexOf('}') + 1);
                    let data = JSON.parse(msg);
                    console.log("Msg- ", data);
                    if (data) {
                        let callData = data;
                        let dateTime = callData['Event_Date_Local'];
                        if(dateTime) {
                            callData.time = dateTime;
                            let quoteIdPaymentList = await fetchQuoteId(callData.customer_number);
                            let paymentList = quoteIdPaymentList.map(data => data.payment);
                            let quoteList = quoteIdPaymentList.map(data => data.quoteId);
                            callData.quoteId = quoteList;
                            callData.payment = paymentList;
                            let agentName = await fetchAgentName(callData.agent_number);
                            if (agentName) {
                                callData.agentName = agentName;
                            }
                            else {
                                callData.agentName = callData.agent_number;
                            }
                            let ticketsList = await fetchQuoteTickets(quoteList);
                            callData.tickets = ticketsList;
                            let userDetails = (await db.ref(`users/${callData.customer_number}/mUser_Contact/`).once('value')).val();
                            if (userDetails) {
                                callData.city = userDetails.mContact_City ? userDetails.mContact_City : '';
                                callData.name = (userDetails.mContact_FirstName ? userDetails.mContact_FirstName : '') + " " + (userDetails.mContact_LastName ? userDetails.mContact_LastName : '');
                            }
                            dispatch({ type: FETCH_CURRENT_DATA_SUCCESS, payload: [ data ] });
                        }
                    }
                }
                return stream.read().then(processData);
            })
        });
    }
}