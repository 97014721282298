import { FETCH_CONFIRMED_DATA_SUCCESS, FETCH_CONFIRMED_DATA_START } from '../actions/actiontypes'

const INIT_STATE = {
	data:{},
	completed:false,
}

export default function(state = INIT_STATE, action) {
	switch(action.type){
		case FETCH_CONFIRMED_DATA_START:{
			return {...state};
		}
		case FETCH_CONFIRMED_DATA_SUCCESS:{
			return {...state, data : action.payload};
		}
		default:
			return state;
	}
}