import { FETCHING_MAIL_START, FETCHING_MAIL_SUCCESS } from './actiontypes';
import { dotToEmail, emailToDot } from '../helper/helper';
import { base } from '../connection';


export function fetchmail(id) {
	console.log(new Date());
  	return (dispatch) => {
    	dispatch({type:FETCHING_MAIL_START});
  	  	let timeEmailURL = `/delivery/webPortal/email/timeData/${id}`
		base.fetch(timeEmailURL,{})
		.then(dataGet => {
			let emailData = {
				cc: dataGet.cc,
				date: dataGet.date,
				fromName: emailToDot(dataGet.fromName),
				mailBody: dataGet.mailBody,
				mailText: dataGet.mailText,
				subject: dataGet.subject,
				to: emailToDot(dataGet.to),
				mailId: id
			}
			dispatch({type:FETCHING_MAIL_SUCCESS,payload:emailData});
		})
		.catch(error => {
			console.log(error);
		})
 	}
}