import { FETCHING_OPS_REPORT_START, FETCHING_OPS_REPORT_SUCCESS } from '../actions/actiontypes';

export default function (state = {}, action) {
  switch (action.type) {
    case FETCHING_OPS_REPORT_START: {
      return {};
    }
    case FETCHING_OPS_REPORT_SUCCESS: {
      return { ...state, data: action.payload };
    }
    default:
      return state;
  }
}
