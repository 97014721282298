import { SEND_EMAIL_ON_EDIT } from './actiontypes';

import { base } from '../connection';

import { fetch, set, push, fetchOrderByChild, fetchOrderByKey, fetchOrderByValue } from '../helper/firebaseFunctions';


export function sendEmailOnEdit(text, subject) {
	console.log(new Date());
	return (dispatch) => {
		writeToNode(text, subject);
	}
}

function writeToNode(text, subject) {
	//let userphone = user;
	console.log("Infunction")
    let epochPlusRandomNo = parseInt(((new Date).getTime())/1000)*1000000 + Math.floor(100000 + Math.random() * 900000)
    //let subject = String(quoteId) + " edited by " + String(phoneNumber)
    let emailData = {
        from : 'communications@zunroof.in',
        subject : subject,
        text : JSON.stringify(text),
        to : 'shubham.arora@zunroof.com'
    };
	set(`delivery/webPortal/sendEmail/${epochPlusRandomNo}`,emailData)
	.then(data => {
		console.log("DONE");
	})
	.catch(error=>{
		console.log(error);
	})
}