import { base, db } from '../connection';


export function setPanelVendorData(siteId,vendor, quoteId) {
	console.log(new Date());
	return (dispatch) => {
        //pushVendor(siteId,vendor);
        console.log(siteId);
        console.log(vendor);
        let panelVendorPath = '/delivery/webPortal/sites/'+siteId+'/panelVendor';
        setData(panelVendorPath,vendor);
        let vendorUrl = '/delivery/vendorApp/assigned/'+vendor+'/'+siteId;
        let vendorData = {
            status : 0,
            date : '',
            quoteid:quoteId
        };
        setData(vendorUrl,vendorData);
	}
}


function pushVendor(siteId,data) {
	base.post('/delivery/sites/'+siteId+'/vendor', {
	    data: data
	    ,then(err){
	      if(!err){
	      	console.log("SUCCESS");
	      }else{
	      	console.log(err);
	      }
	    }
	  });
}


function setData(path, data){
    db.ref(path).set(data)
    .then((val) => {
        return
    })
    .catch(e => {
        return e;
    })
}