import { FETCH_TICKETID_SUCCESS, FETCH_TICKETID_START } from './actiontypes';

import { base, db } from '../connection';

export function fetchTicketId(ticketId) {
    return (dispatch) => {
        dispatch({ type: FETCH_TICKETID_START });
        db.ref('/website/ticketSystem/tickets/' + ticketId).once('value')
            .then(snapshot => {
                let data = snapshot.val();
                let phoneNumber = data.phoneNumber
                Promise.all([fetchTeamToEmployeeMap(), fetchEmployeeList(), fetchComments(ticketId), fetchRelatedTickets(phoneNumber), fetchQuoteIdState(data.quoteId), fetchStateEmployeeMap()])
                    .then(result => {
                        //console.log(result);
                        let teamEmployeeMap = {};
                        let employeeList = {};
                        let commentsData = [];
                        let relatedTickets = {};
                        if (result[0]) {
                            teamEmployeeMap = result[0];
                        }
                        if (result[1]) {
                            employeeList = result[1];
                        }
                        if (result[2]) {
                            commentsData = result[2];
                        }
                        if (result[3]) {
                            delete result[3][ticketId]
                            relatedTickets = result[3];
                        }
                        data.state = result[4];
                        let completeData = {
                            ticketDetail: data,
                            teamEmployeeMap: teamEmployeeMap,
                            employeeList: employeeList,
                            commentsData: commentsData,
                            stateEmployeeMap: result[5],
                            relatedTickets: relatedTickets
                        }
                        dispatch({ type: FETCH_TICKETID_SUCCESS, payload: completeData })
                    })
                    .catch(e => {
                        console.log(e);
                    })
                // dispatch({type:FETCH_TICKETID_SUCCESS,payload:data})
            })
            .catch(e => {
                console.log(e);
                return e;
            })
    }
}

function fetchTeamToEmployeeMap() {
    return new Promise((resolve, reject) => {
        let teamToEmployeeMap = {};
        base.fetch('/website/ticketSystem/teamToEmployeeMap', {})
            .then(data => {
                for (var key in data) {
                    let teamId = key;
                    teamToEmployeeMap[data["" + teamId].teamName] = Object.keys(data["" + teamId].members);
                }
                //console.log(teamToEmployeeMap);
                // teamToEmployeeMap = {
                //     CM: [ "+917827941240", "+919319295755", "+919319295762"],
                //     Procurement: ["+918448389958", "+918882922788", "+919205695692"],
                //     PM:[]
                // }
                resolve(teamToEmployeeMap);
                //fetchEmployeeList();
            })
            .catch(error => {
                console.log(error);
                reject(error);
            })
    });
}

function fetchEmployeeList() {
    return new Promise((resolve, reject) => {
        base.fetch('/employeelist', {})
            .then(data => {
                let employeeMap = {};
                for (var key in data) {
                    employeeMap["" + key] = data["" + key].fname + " " + data["" + key].lname;
                }
                //console.log(employeeMap);
                resolve(employeeMap);
                //fetchFolderType(dispatch,id);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            })
    });
}


function fetchComments(ticketId) {
    return new Promise((resolve, reject) => {
        let teamToEmployeeMap = {};
        base.fetch('/website/ticketSystem/comments/' + ticketId, {})
            .then(data => {
                let commentsData = Object.values(data ? data : {});
                resolve(commentsData);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            })
    });
}

async function fetchStateEmployeeMap() {
    try {
        let stateEmployeeMap = (await db.ref(`website/ticketSystem/stateEmployeeMap`).once('value')).val();
        return stateEmployeeMap ? stateEmployeeMap : {};
    }
    catch (e) {
        return {};
    }
}

async function fetchQuoteIdState(quoteId) {
    try {
        let siteId = (await db.ref(`quotestaken/${quoteId}/siteId`).once('value')).val();
        if (siteId) {
            let state = (await db.ref(`delivery/webPortal/sites/${siteId}/state_D`).once('value')).val();
            if (!state) {
                state = (await db.ref(`sites/${siteId}/mSite_State`).once('value')).val();
            }
            return state;
        }
        else {
            return '';
        }
    }
    catch (e) {
        return '';
    }
}

async function fetchRelatedTickets(phoneNumber) {
    try {
        let phone = (await db.ref('/website/ticketSystem/tickets/').orderByChild('phoneNumber').equalTo(phoneNumber).once('value')).val()
        return phone
    }
    catch (e) {
        return ''
    }
}