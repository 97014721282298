import { base } from '../connection';

export function addBulkNotification(data) {
	console.log(new Date());
	return (dispatch) => {
		pushBulkNotification(data);
	}
}

function pushBulkNotification(data) {
	console.log(data)
	base.push('bulkNotifications/', {
	    data: data
	    ,then(err){
	      if(!err){
	      	console.log("SUCCESS");
	      }else{
	      	console.log(err);
	      }
	    }
	  });
}
