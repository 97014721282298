import React, { Component } from 'react';
import ReactTable from "react-table";

import  { Filter } from './Filter';

import { dateRange, percentageRange } from '../helper/range';
import { checkExist, checkArrayExist } from '../helper/filterData';
import { styleSheet } from '../styles/styleSheet';

export class StageView extends Component {
	constructor(props) {
	  super(props);
	
	   this.state = {
  			stateList:[],
			stageList:[],
			ebissuesList:[],
			subsidiesList:[],
			vendorList:[],
			open:false,
			viewData:[],
			quoteCount:0,
			systemsize:0,
			column:[],
			startDate:"",
			endDate:"",
			startPercentage:0,
			notificationTitle:null,
			notificationText:null,
			notificationTitleText:null,
			errorData:[],
			showError:false
	  };
	}

	componentDidMount() {
		const { data, states, stages, subsidies, ebissues } = this.props;
		console.log(states, stages, ebissues, subsidies);
		this.setState({
			stateList: states.map(val => {return {value:val, label:val}}),
			stageList: stages.map(val => {return {value:val, label:val}}),
			ebissuesList: ebissues.map((val,index) => {return {value:index, label:val}}),
			subsidiesList: subsidies.map((val,index) => {return {value:index, label:val}}),
			vendorList: ["NA","Boond","Chemitech","SmartRoof","Aryav","Zivah","SDSL","Oakridge","ZunRoof","SunEnergy","Volks Energie","Sirius","Greenon","GeoPower","GM Trading","Alien","Adhunik"].map(val => {return {value:val, label:val}}),
		});
		if(data.length>0 && !this.state.dataGetOnce) {
			this.setState({
				viewData:data.map(val => stages[val.stage]==="Truncated"?false:val).filter(val => val?true:false),
				dataGetOnce:true
			})
		}
	}

	handleMultiSelectChange = (selectedOption, name) => {
		console.log(selectedOption, name);
		switch(name) {
			case "stateChange":
				return this.setState({stateChoosen:selectedOption});
			case "subsidyChange":
				return this.setState({subsidyChoosen:selectedOption});
			case "stageChange":
				return this.setState({stageChoosen:selectedOption});
			case "v1Change":
				return this.setState({vendorChoosen:selectedOption});
			case "ebIssueChange":
				return this.setState({ebissueChoosen:selectedOption});
			default:
				return;
		}
	}

	handleDateInputChange = (e) => {
		if(e.target.name==="startDate") {
			this.setState({
				[e.target.name]: e.target.value?new Date(e.target.value):0
			})
		}else{
			this.setState({
				[e.target.name]: e.target.value?new Date(e.target.value):9999999999999
			})
		}
	}

	handleInputChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	applyFilter = () => {
		let stateChoosenArray = []
		if(this.state.stateChoosen){
			stateChoosenArray = this.state.stateChoosen.map(val => val.value)
		}


		let subsidyChoosenArray = [];
		if(this.state.subsidyChoosen){
			subsidyChoosenArray = this.state.subsidyChoosen.map(val => val.label)
		}

		let stageChoosenArray = [];
		if(this.state.stageChoosen){
			stageChoosenArray = this.state.stageChoosen.map(val => val.value)
		}

		let vendorChoosenArray = [];
		if(this.state.vendorChoosen){
			vendorChoosenArray = this.state.vendorChoosen.map(val => val.value)
		}

		let ebissueChoosenArray = [];
		if(this.state.ebissueChoosen){
			ebissueChoosenArray = this.state.ebissueChoosen.map(val => val.value)
		}


		let startPercentage = this.state.startPercentage;
		let endPercentage 	= this.state.endPercentage;
		console.log(this.props.data);
		let viewData = this.props.data.map(value => {
			if(!percentageRange(value.paidPercentage,startPercentage,endPercentage)) {
				console.log(value);
			}

			if(checkExist(stateChoosenArray,value.stateD) && dateRange(value.salesdate,this.state.startDate,this.state.endDate) && percentageRange(value.paidPercentage,startPercentage,endPercentage) && checkArrayExist(ebissueChoosenArray,value.ebIssueAllList) && checkExist(vendorChoosenArray,value.vendor) && checkExist(subsidyChoosenArray,value.subsidyD) && (value.stage!=="Truncated" || (value.stage==="Truncated" && stageChoosenArray.indexOf("Truncated")>-1))  && checkExist(stageChoosenArray,value.stage)) {
				return value;
			}
			return false;
		}).filter(val => val?true:false);
		console.log(viewData);
		this.setState({
			viewData
		})
	}



	render() {
		let { handleDateInputChange, handleInputChange, handleMultiSelectChange, applyFilter } = this;
		let { stateList, stageList, vendorList, ebissuesList, subsidiesList, viewData } = this.state;
		return (
			<div className ="container-fluid">
				<div style={styleSheet.divStyle} className="filter_deals">
					<Filter
							stateList={stateList}
							stageList={stageList}
							vendorList={vendorList}
							ebissuesList={ebissuesList}
							subsidiesList={subsidiesList}
							handleDateInputChange={handleDateInputChange}
							handleInputChange={handleInputChange}
							handleMultiSelectChange={handleMultiSelectChange}
							applyFilter={applyFilter}
					/>
				</div>
				<div style={styleSheet.tableName}> <h5>{""}</h5></div>
				<ReactTable
					data={viewData}
					columns={this.props.columns}
				/>
			</div>
		);
	}
}