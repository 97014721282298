// import { FETCHING_DEALS_COMPLETED, FETCHING_DEALS_SUCCESS } from './actiontypes';
// import { columnsDeals } from '../config/column_fields';

// import { db } from '../connection';
// import { fetch } from '../helper/firebaseFunctions';
// import { dateChanger } from '../helper/dateChanger';
// import fetchRmsData from '../helper/fetchRmsData';

// var total = -1;
// var count = 0;
// let completeMap = {};
// let rmsData = [{ quoteId: "APR19001", status: 1 }]
// let siteQuoteMap = {};
// export function fetchmainview() {
// 	total = -1;
// 	return (dispatch) => {
// 		console.log("INIT", new Date());
// 		let allQuoteIdURL = `/delivery/webPortal`;
// 		fetchRmsData().then(data => {
// 			rmsData = data
// 			return fetch(allQuoteIdURL, 'deliverySiteMapping');
// 		})
// 			.then(siteIdMap => {
// 				console.log("AFTER GETTING ALL QUOTEID", new Date());
// 				let siteIdList = Object.keys(siteIdMap);
// 				siteQuoteMap = siteIdMap;
// 				total = siteIdList.length;
// 				fetchSalesDetails(dispatch, siteIdList);
// 			}).catch(error => {
// 				console.log(error);
// 				return;
// 			})
// 	}
// }

// function fetchSalesDetails(dispatch, siteIdList) {
// 	console.log("FETCHING FOR ALL DETAILS", new Date());
// 	siteIdList.forEach(siteId => {
// 		completeMap[siteId] = {
// 			siteVisited: false,
// 			deliveryVisited: false,
// 			webPortalVisited: false,
// 			issueVisited: false,
// 			siteVisitStatusVisited: false,
// 			delivery: {},
// 			sites: {},
// 			webportal: {},
// 			issue: {},
// 			siteVisitStatus: ''
// 		}
// 		fetchSiteDataAndUser(dispatch, siteId, completeMap, "DELIVERY")
// 		fetchData(dispatch, 'sites', siteId, completeMap, "SITES");
// 		fetchData(dispatch, 'delivery/webPortal/sites', siteId, completeMap, "WEBPORTAL");
// 		fetchData(dispatch, 'delivery/Issue/statusbysite/', siteId, completeMap, "ISSUE");
// 		fetchSiteVisitStatus(dispatch, siteId, completeMap, "SITEVISITSTATUS", siteQuoteMap[siteId]);
// 	})
// 	console.log("PROMISE CREATED", new Date());
// }

// function fetchData(dispatch, url, siteId, completeMap, type) {
// 	db.ref(url).child(siteId).on('value', function (snapshot) {
// 		let value = snapshot.val();
// 		combineSalesData(dispatch, completeMap, siteId, value, type);
// 	});
// }

// function fetchSiteDataAndUser(dispatch, siteId, completeMap, type) {
// 	db.ref('delivery/sites').child(siteId).on('value', function (snapshot) {
// 		let value = snapshot.val();
// 		let { siteOwnerId, responsilbleIdDelevery } = value;
// 		completeMap[siteId].delivery = { ...value };
// 		Promise.all([fetch('users', siteOwnerId), fetch('users', responsilbleIdDelevery)])
// 			.then(userObj => {
// 				let { mUser_Contact } = userObj[1];
// 				let { mContact_FirstName, mContact_LastName } = mUser_Contact;
// 				let responsibleName = `${mContact_FirstName} ${mContact_LastName}`;
// 				completeMap[siteId].delivery = { ...completeMap[siteId].delivery, ...userObj[0], responsibleName };
// 				combineSalesData(dispatch, completeMap, siteId, "", type)
// 			}).catch(error => {
// 				console.log(error);
// 			})
// 	});
// }

// function combineSalesData(dispatch, completeMap, siteId, value, type) {
// 	switch (type) {
// 		case "DELIVERY":
// 			completeMap[siteId].deliveryVisited = true;
// 			break;
// 		case "SITES":
// 			completeMap[siteId].sites = value;
// 			completeMap[siteId].siteVisited = true;
// 			break;
// 		case "WEBPORTAL":
// 			completeMap[siteId].webportal = value;
// 			completeMap[siteId].webPortalVisited = true;
// 			break;
// 		case "ISSUE":
// 			completeMap[siteId].issue = value;
// 			completeMap[siteId].issueVisited = true;
// 			break;
// 		case "SITEVISITSTATUS":
// 			completeMap[siteId].siteVisitStatus = value;
// 			completeMap[siteId].siteVisitStatusVisited = true;
// 			break;
// 		default:
// 			break;
// 	}
// 	let data = { ...completeMap[siteId] };
// 	let { deliveryVisited, siteVisited, webPortalVisited, issueVisited, siteVisitStatusVisited } = data;
// 	if (deliveryVisited && siteVisited && webPortalVisited && issueVisited && siteVisitStatusVisited) {
// 		getSalesData(dispatch, { ...data.delivery, ...data.sites, ...data.webportal, ...data.issue, ...data.siteVisitStatus });
// 	}
// }

// function getSalesData(dispatch, salesData) {
// 	let { stage, mSite_QuoteId, mSite_selectedSize, size_D, mSite_Id, mSite_finalPrice, mSite_State, state_D, mSite_SystemType, mSite_PropertyType, extraPriceLineItem, mSite_subsidy, mSite_subsidy_CashBack, mUser_Contact, siteOwnerId, subsidy_D, mSite_ElevationType, elevationType_D, systemType_D, paymentHistory, salesApprovedStatus_D, quoteId, employeeName, orderStatus, mUser_NotificationToken, responsibleName, vendor, inc, maintenanceRequired, financing, siteId, invoiceText, invoiceDate, promisedPanel, promisedInverter, subsidyStatus, netMeteringStage, connectionHolderName, siteissue, siteVisitedStatus, zunPrime } = salesData;

// 	let { mContact_FirstName, mContact_LastName, mContact_City, mContact_Email } = mUser_Contact ? mUser_Contact : {};

// 	let { change_By, current_State, next_Date, past_State, ref_Date, sales_Date } = stage ? stage[stage.length - 1] : {};

// 	let { invertor, netMetering, orderConfirmationInternal, panel, structure } = orderStatus ? orderStatus : {};

// 	let ottPriceArray = Object.values(extraPriceLineItem ? extraPriceLineItem : {}).map(val => !isNaN(parseInt(val.price)) ? parseInt(val.price) : 0);
// 	let paymentPaidArray = Object.values(paymentHistory ? paymentHistory : {}).map(val => !isNaN(parseInt(val.amountPaid)) ? parseInt(val.amountPaid) : 0);
// 	let ottPrice = ottPriceArray.reduce((val1, val2) => val1 + val2, 0);
// 	let paymentPaid = paymentPaidArray.reduce((val1, val2) => val1 + val2, 0);
// 	let finalSize = size_D ? size_D : mSite_selectedSize ? mSite_selectedSize : 0;
// 	let paidPercentage = Math.round((paymentPaid * 10000) / (mSite_finalPrice + ottPrice)) / 100;
// 	let isPrime = 'No';
// 	if(zunPrime){
// 		isPrime = 'Yes';
// 	}
// 	let rms = rmsData.find(rms => rms.quoteId.toUpperCase() == quoteId);
// 	let rmsInstalled = "No", rmsStatus = "";
// 	if (rms) {
// 		rmsInstalled = "Yes";
// 		rmsStatus = rms.status ? "Active" : "Inactive";
// 	}
// 	let createdData = {
// 		responsibleName: responsibleName,
// 		salesdate: getEpoch(sales_Date),
// 		quoteid: quoteId,
// 		vendor: vendor,
// 		inc: inc,
// 		email: mContact_Email,
// 		userNotificationId: mUser_NotificationToken ? mUser_NotificationToken : "",
// 		nextactiondate: next_Date,
// 		totalPayment: paymentPaid,
// 		totalPrice: mSite_finalPrice + ottPrice,
// 		invertorExpectedDate: invertor && invertor.status ? invertor.expectedCompletionDate.time : 0,
// 		panelExpectedDate: panel && panel.status ? panel.expectedCompletionDate.time : 0,
// 		structureExpectedDate: structure && structure.status ? structure.expectedCompletionDate.time : 0,
// 		elevationtype: elevationType_D ? elevationType_D : mSite_ElevationType,
// 		propertytype: mSite_PropertyType,
// 		state: mSite_State,
// 		type: systemType_D ? systemType_D : mSite_SystemType,
// 		size: size_D ? parseFloat(size_D) : parseFloat(mSite_selectedSize),
// 		subsidy: mSite_subsidy !== 0 && mSite_subsidy !== "" ? "Subsidy" : mSite_subsidy_CashBack !== 0 && mSite_subsidy_CashBack !== "" ? "Subsidy Cash Back" : "",
// 		maintenanceRequired: maintenanceRequired,
// 		paidPercentage: paidPercentage,
// 		financingIssue: financing,
// 		ebIssueName: siteissue ? siteissue.indexOf("1") > -1 ? 1 : siteissue.indexOf("4") > -1 ? 4 : "" : "",
// 		ebIssueLoad: siteissue ? siteissue.indexOf("2") > -1 ? 2 : siteissue.indexOf("5") > -1 ? 5 : "" : "",
// 		ebIssueType: siteissue ? siteissue.indexOf("7") > -1 ? 7 : siteissue.indexOf("8") > -1 ? 8 : "" : "",
// 		ebIssuePhase: siteissue ? siteissue.indexOf("3") > -1 ? 3 : siteissue.indexOf("6") > -1 ? 6 : "" : "",
// 		username: `${mContact_FirstName} ${mContact_LastName}`,
// 		city: mContact_City,
// 		stage: current_State ? current_State : "",
// 		refDate: getEpoch(ref_Date),
// 		subsidyD: subsidy_D,
// 		stateD: state_D ? state_D : mSite_State,
// 		siteId: siteId,
// 		ebIssueAllList: siteissue ? siteissue : [],
// 		panelPromised: promisedPanel,
// 		inverterPromised: promisedInverter,
// 		connectionHolderName: connectionHolderName,
// 		subsidyStatus: subsidyStatus ? subsidyStatus.status : "Not Applied",
// 		netMeteringStage: netMeteringStage || "Not Applied",
// 		invoiceText: invoiceText,
// 		invoiceDate: invoiceDate,
// 		phone: siteOwnerId,
// 		rmsInstalled: rmsInstalled,
// 		rmsStatus: rmsStatus,
// 		siteVisit: siteVisitedStatus,
// 		zunPrime : isPrime,
// 		netMeterDate : netMetering && netMetering.status ? netMetering.expectedCompletionDate.time : 0
// 	}
// 	count++;
// 	dispatch({ type: FETCHING_DEALS_SUCCESS, payload: { [siteId]: createdData } });
// 	if (count >= total) {
// 		console.log("Check");
// 		dispatch({ type: FETCHING_DEALS_COMPLETED, payload: true });
// 	}
// }

// function getEpoch(date) {
// 	return isNaN(dateChanger(date)) ? 0 : dateChanger(date);
// }


// function fetchSiteVisitStatus(dispatch, siteId, completeMap, type, quoteId) {

// 	db.ref(`delivery/webPortal/fileManagement/delivery/viewableFiles/${quoteId}`).once('value')
// 		.then(snapshot => {
// 			let filesData = snapshot.val();
// 			let layoutStatus = '';
// 			Object.keys(filesData).forEach(id => {
// 				let fileData = filesData[id];
// 				if (fileData.folderType == "Design" && fileData.type == "type1") {
// 					if (layoutStatus != 'Layout Approved') {
// 						if (fileData.verified == '1' || fileData.verified == '3') {
// 							layoutStatus = 'Layout Shared';
// 						}
// 						if (fileData.verified == '2') {
// 							layoutStatus = 'Layout Approved';
// 						}
// 					}
// 				}
// 			})
// 			if (!layoutStatus) {
// 				db.ref(`forms/formsresponse/4/${siteId}`).once('value')
// 					.then(snap => {
// 						let data = snap.val();
// 						console.log("SITEVISITDATA", data);
// 						if (data) {
// 							let response = {
// 								siteVisitedStatus: "Completed"
// 							}
// 							combineSalesData(dispatch, completeMap, siteId, response, type);
// 						} else {
// 							let response = {
// 								siteVisitedStatus: "Pending"
// 							}
// 							combineSalesData(dispatch, completeMap, siteId, response, type);
// 						}
// 					})
// 					.catch(e => {
// 						let response = {
// 							siteVisitedStatus: "Pending"
// 						}
// 						combineSalesData(dispatch, completeMap, siteId, response, type);
// 					})
// 			}
// 			else{
// 				let response = {
// 					siteVisitedStatus: layoutStatus
// 				}
// 				combineSalesData(dispatch, completeMap, siteId, response, type);
// 			}
// 		})
// 		.catch(e => {
// 			let response = {
// 				siteVisitedStatus: "Pending"
// 			}
// 			combineSalesData(dispatch, completeMap, siteId, response, type);
// 		})
// }








import { FETCHING_DEALS_COMPLETED, FETCHING_DEALS_SUCCESS } from './actiontypes';
export function fetchmainview(reloadData = "") {
  return async (dispatch) => {
  let data = await fetchDealsView(reloadData);
  // console.log(data);
  dispatch({ type: FETCHING_DEALS_SUCCESS, payload: data });
  dispatch({ type: FETCHING_DEALS_COMPLETED, payload: true });
  }
}
async function fetchDealsView(reloadData) {
  try {
    let formData = new URLSearchParams();
    formData.append("key", "oXPE4pCS0Xs7Q8JK2");
    formData.append("reloadData", reloadData);
    // let content = await fetch("http://localhost:3002/api/fetchDealsView", {
    let content = await fetch("https://operations.zunroof.com/api/fetchDealsView", {
      method : 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body : formData
    })
    content = await content.json();
    console.log(content);
    return content;
  }
  catch (e) {
    console.log(e);
    return {};
  }
}