import { FETCHING_QUOTEID_START, FETCHING_QUOTEID_SUCCESS } from './actiontypes';

import { db, base } from '../connection';
import { dotToEmail, emailToDot } from '../helper/helper';
import fetchRmsData from '../helper/fetchRmsData';

export function fetchquoteid(id) {
	return (dispatch) => {
		dispatch({type:FETCHING_QUOTEID_START,payload:[]})
		// fetch('http://localhost:3002/quoteId/details/' + id)
		fetch('https://operations.zunroof.com/quoteId/details/' + id)
		.then(response => response.json())
		.then(data => {
			dispatch({type:FETCHING_QUOTEID_SUCCESS,payload:data})
		})
	}
}
