import { PUSH_COMMENT_SUCCESS } from './actiontypes';

import { base } from '../connection';


export function setemailstatus(id,status) {
	console.log(new Date());
	return (dispatch) => {
		var immediatelyAvailableReference  = base.post(`delivery/webPortal/email/timeData/${id}/status`, {
	    data: status
	    ,then(err){
	      if(!err){
	      	console.log(`SUCCESSFULLY ADDED TO FIREBASE : ${id}, ${status}`);
	      }else{
	      	console.log(err);
	      }
	    }
	 })
	}
}