export function priceCalculator(type, state) {
	let { exactWatt_Structure, perWatt_Structure, payment_Structure, gst_Structure, costOfInverter_Structure, costOfMatrial_Structure, installerFee_Structure, zunroofFeeInput_Structure, paymentType_Structure, sizeP_Structure, systemPrice_Structure, freight_Structure, selectView, count_Structure, perUnitPrice_Structure } = state;
	let installerCharge_Structure = null;
	let zunroofFee_Structure = null;
	let price_Structure = null;
	let v1Charge_Structure = null;
	let value;
	let value_2;
	let count = (selectView == 'purchaseView' || selectView=='sellView')?count_Structure.actualValue:1;
	console.log("count", count);
	//debugger
	switch (type) {
		case "panels":
			value = (parseFloat(exactWatt_Structure.actualValue) * parseFloat(perWatt_Structure.actualValue) * parseFloat(payment_Structure.actualValue / 100) + parseFloat(freight_Structure.actualValue)) * (1 + parseFloat(gst_Structure.actualValue / 100));
			return { price_Structure: isNaN(value) ? 0 : value };
		case "inverter":
			value = (parseFloat(count) * parseFloat(costOfInverter_Structure.actualValue) * parseFloat(payment_Structure.actualValue / 100) + parseFloat(freight_Structure.actualValue)) * (1 + parseFloat(gst_Structure.actualValue / 100));
			return { price_Structure: isNaN(value) ? 0 : value };
		case "structure":
			value = (parseFloat(costOfMatrial_Structure.actualValue) * parseFloat(payment_Structure.actualValue / 100) + parseFloat(freight_Structure.actualValue)) * (1 + parseFloat(gst_Structure.actualValue / 100));
			return { price_Structure: isNaN(value) ? 0 : value };
		case "v2":
			value = parseFloat(installerFee_Structure.actualValue) * parseFloat(sizeP_Structure.actualValue) * parseFloat(payment_Structure.actualValue / 100) * (1 + (gst_Structure.actualValue / 100));
			value_2 = parseFloat(zunroofFeeInput_Structure.actualValue) * parseFloat(sizeP_Structure.actualValue);
			installerCharge_Structure = "" + (isNaN(value) ? "0" : value);
			zunroofFee_Structure = "" + ((paymentType_Structure.actualValue === "p1") ? (isNaN(value_2) ? "0" : value_2) : "0");
			price_Structure = "" + (parseFloat(installerCharge_Structure) - parseFloat(zunroofFee_Structure));
			return { installerCharge_Structure, zunroofFee_Structure, price_Structure };
		case "v1":
			value = parseFloat(systemPrice_Structure.actualValue) * parseFloat(sizeP_Structure.actualValue) * parseFloat(payment_Structure.actualValue / 100) * (1 + (gst_Structure.actualValue / 100));
			value_2 = (parseFloat(installerFee_Structure.actualValue) * parseFloat(sizeP_Structure.actualValue) * .5);
			console.log("paymentType_Structure", paymentType_Structure);
			v1Charge_Structure = isNaN(value) ? 0 : value;
			installerCharge_Structure = paymentType_Structure.actualValue === "p2" || paymentType_Structure.actualValue === "p3" ? (isNaN(value_2) ? 0 : value_2) : 0;
			zunroofFee_Structure = paymentType_Structure.actualValue === "p1" || paymentType_Structure.actualValue === "p2" || paymentType_Structure.actualValue === "p3" || paymentType_Structure.actualValue === "p4" ? isNaN(parseFloat(zunroofFeeInput_Structure.actualValue) * parseFloat(sizeP_Structure.actualValue)) ? 0 : parseFloat(zunroofFeeInput_Structure.actualValue) * parseFloat(sizeP_Structure.actualValue) : 0;
			price_Structure = v1Charge_Structure - installerCharge_Structure - zunroofFee_Structure;
			return { v1Charge_Structure, installerCharge_Structure, zunroofFee_Structure, price_Structure };
		case "accounts":
			return { price_Structure: state.duePayment }
		case "other":
			return;
		case "ACDB":
			value = (parseFloat(count) * parseFloat(perUnitPrice_Structure.actualValue) * parseFloat(payment_Structure.actualValue / 100) + parseFloat(freight_Structure.actualValue)) * (1 + parseFloat(gst_Structure.actualValue / 100));
			return { price_Structure: isNaN(value) ? 0 : value };
		case "DCDB":
			value = (parseFloat(count) * parseFloat(perUnitPrice_Structure.actualValue) * parseFloat(payment_Structure.actualValue / 100) + parseFloat(freight_Structure.actualValue)) * (1 + parseFloat(gst_Structure.actualValue / 100));
			return { price_Structure: isNaN(value) ? 0 : value };
		default:
			break;
	}
}

export const access = {
	"panels": {
		"quoteId_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"name_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"sizeP_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"epc_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"date_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":false,
"2300":false,
"2500": false,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"vendor_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"brand_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"make_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"exactWatt_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"perWatt_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"payment_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"freight_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"gst_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"price_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"reference_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"status_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"invoiceNumber_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"transactionType_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"businessType_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		}
	},
	"inverter": {
		"quoteId_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"name_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"sizeP_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"epc_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"date_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":false,
"2300":false,
"2500": false,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"vendor_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"brand_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"inverterSize_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"phase_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"costOfInverter_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"payment_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"freight_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"gst_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"price_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"reference_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"status_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"invoiceNumber_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"transactionType_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"businessType_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		}
	},
	"structure": {
		"quoteId_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"name_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"sizeP_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"epc_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"date_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":false,
"2300":false,
"2500": false,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"material_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"vendor_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"inverterSize_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"costOfMatrial_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"payment_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"freight_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"gst_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"price_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"reference_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"status_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"invoiceNumber_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"transactionType_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"businessType_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		}
	},
	"v2": {
		"quoteId_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"name_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"sizeP_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"epc_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"date_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":false,
"2300":false,
"2500": false,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"vendor_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"acdbDcdb_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"installerFee_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"paymentType_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"payment_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"zunroofFeeInput_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"gst_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"installerCharge_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"zunroofFee_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"price_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"reference_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"status_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"invoiceNumber_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"invoiceAmount_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"transactionType_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"businessType_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		}
	},
	"v1": {
		"quoteId_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"name_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"epc_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"sizeP_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"vendor_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"installerFee_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"zunroofFeeInput_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"date_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":false,
"2300":false,
"2500": false,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"paymentType_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"payment_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"gst_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"systemPrice_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"price_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"v1Charge_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"installerCharge_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"zunroofFee_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"status_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"reference_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"invoiceNumber_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"invoiceAmount_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"transactionType_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"businessType_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		}
	},
	"other": {
		"quoteId_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"name_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"sizeP_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"transactionType_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"comments_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"vendor_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"price_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"date_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":false,
"2300":false,
"2500": false,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"status_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"reference_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"businessType_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		}
	},
	"accounts": {
		"vendor_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":false,
"2300":false,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":false,
"2300":false,
"2500": true,
"2600": true
			}
		},
		"comments_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":false,
"2300":false,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"price_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":false,
"2300":false,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"date_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":false,
"2300":false,
"2500": false,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"reference_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":false,
"2300":false,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"bankName_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":false,
"2300":false,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		}
	}
}

export const purchaseFormAccess = {
	"panels": {
		"date_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":false,
"2300":false,
"2500": false,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"vendor_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"brand_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"make_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"exactWatt_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"perWatt_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"payment_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"freight_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"gst_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"count_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"price_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"reference_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"status_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"invoiceNumber_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"transactionType_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		}
	},
	"inverter": {
		"date_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":false,
"2300":false,
"2500": false,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"vendor_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"brand_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"inverterSize_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"phase_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"costOfInverter_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"payment_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"freight_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"gst_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"count_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"price_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"reference_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"status_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"invoiceNumber_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"transactionType_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		}
	},
	"ACDB":{
		"date_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":false,
"2300":false,
"2500": false,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"vendor_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"make_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"city_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"perUnitPrice_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"payment_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"freight_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"gst_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"count_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"price_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"reference_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"status_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"invoiceNumber_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"transactionType_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		}
	},
	"DCDB":{
		"date_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":false,
"2300":false,
"2500": false,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"vendor_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"make_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"city_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"perUnitPrice_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"payment_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"freight_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"gst_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"count_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"price_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"reference_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"status_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"invoiceNumber_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"transactionType_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		}
	}
};

export const sellFormAccess = {
	"panels": {
		"date_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":false,
"2300":false,
"2500": false,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"vendor_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"purchaseVendor_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"brand_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"make_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"exactWatt_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"perWatt_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"payment_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"freight_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"gst_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"count_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"price_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"reference_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"status_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"invoiceNumber_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"transactionType_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		}
	},
	"inverter": {
		"date_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":false,
"2300":false,
"2500": false,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"vendor_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"purchaseVendor_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"brand_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"inverterSize_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"phase_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"costOfInverter_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"payment_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"freight_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"gst_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"count_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"price_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"reference_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"status_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"invoiceNumber_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"transactionType_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		}
	},
	"ACDB":{
		"date_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":false,
"2300":false,
"2500": false,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"vendor_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"purchaseVendor_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"make_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"city_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"perUnitPrice_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"payment_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"freight_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"gst_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"count_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"price_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"reference_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"status_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"invoiceNumber_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"transactionType_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		}
	},
	"DCDB":{
		"date_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": true,
				"1900":false,
"2300":false,
"2500": false,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"vendor_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"purchaseVendor_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"make_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"city_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"perUnitPrice_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"payment_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"freight_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"gst_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"count_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"price_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"reference_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"status_Structure": {
			"read": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		},
		"invoiceNumber_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			}
		},
		"transactionType_Structure": {
			"read": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"write": {
				"1600": false,
"1700": false,
"1750": false,
				"1800": false,
				"1900":false,
"2300":false,
"2500": false,
"2600": false
			},
			"compulsary": {
				"1600": true,
"1700": true,
"1750": true,
				"1800": true,
				"1900":true,
"2300":true,
"2500": true,
"2600": true
			}
		}
	}
};