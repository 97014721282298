import { ADDING_COMMENT_STATUS } from '../actions/actiontypes'

const INITIAL_STATE = {
	comment:false
}

export default function(state = INITIAL_STATE, action) {
	switch(action.type){
		case ADDING_COMMENT_STATUS:{
			return {...state, comment:action.payload};
		}
		default: 
			return state;
	}
}