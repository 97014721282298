import { base } from '../../connection';

import { PERCENTAGE_GRAPH, PAYMENT_HISTORY_GRAPH, PAYMENT_RESPONSIBLE_WEEK_GRAPH } from '../actiontypes';

import { dateChanger } from '../../helper/dateChanger';
import { calculateSpecificDateAge, calculateDateAge } from '../../helper/helper';

const INITPERCENTAGEBUCKET 	= {};
const INITPAYMENTHISTORY 	= new Array(calculateDateAge(new Date("05/20/2018").getTime())).fill(0);
const INITPERCENTAGEWEEK	= {
	responsible:{},
	data:{}
};

let dispatcher = null;

let total = 0;
let visited = 0;
let paymentVisitedCount = 0;
let paymentWeek = 0;

export function percentagepaymentgraph() {
	console.log(new Date());
	total = 0
	visited = 0
	return (dispatch) => {
		base.fetch('/delivery/sites/',{
			context:{},
			asArray:false,
			then(data) {
				dispatcher = dispatch;
				total = Object.keys(data).length;
				Object.keys(data).forEach((value,index) => {
					let dataSet = {};
					let stage = data[""+value].stage?data[""+value].stage[data[""+value].stage.length-1].current_State:"NA";
					let salesDate = data[""+value].stage?data[""+value].stage[data[""+value].stage.length-1].sales_Date!=="NA"?dateChanger(data[""+value].stage[data[""+value].stage.length-1].sales_Date):new Date(0).getTime():new Date(0).getTime();
					
					//dataStructure
					dataSet[""+value] = {
						bill:{
							finalPrice:0,
							ott:[]
						},
						payment:[],
						stage:stage,
						salesDate:salesDate,
						responsibleMappingVisited:false,
						finalPriceVisited:false,
						ottPriceVisited:false,
						paymentVisited:false,
						stageVisited:false,
						paymentsHistory:{
							amount:[],
							date:[]
						},
						quoteid:data[""+value].quoteId,
						paymentsWeek: {},
						responsible: data[""+value].responsilbleIdDelevery,
					};
					fetchStage(dataSet,value);
					fetchFinalPrice(dataSet,value);
					fetchOttPrices(dataSet,value);
					fetchPrice(dataSet,value);
				})
			}
		})
	}
}

// FETCH STAGE
function fetchStage(dataSet,siteId) {
	base.listenTo('/delivery/sites/'+siteId+'/stage',{
		context:{},
		asArray:false,
		then(data) {
			if(data.length>0) {
				let stage = data[data.length-1].current_State;
				dataSet[""+siteId].stage = stage;
			}
			dataSet[""+siteId].stageVisited = true;
		}
	})
}

// FETCHING FINAL PRICE
function fetchFinalPrice(dataSet,siteId) {
	base.listenTo('/sites/'+siteId+'/mSite_finalPrice',{
		context:{},
		asArray:false,
		then(data) {
			if(data) {
				dataSet[""+siteId].bill.finalPrice = parseFloat(data)?parseFloat(data):0
			}
			dataSet[""+siteId].finalPriceVisited = true;
			calculatePercenatge(dataSet,siteId);
			percentageConditionCheck(dataSet,siteId);
		}
	})
}


// FETCHING OTT PRICES
function fetchOttPrices(dataSet,siteId) {
	base.listenTo('/delivery/sites/'+siteId+'/extraPriceLineItem',{
		context:{},
		asArray:true,
		then(data) {
			if(data) {
				data.forEach(val => {
					dataSet[""+siteId].bill.ott.push(isNaN(parseInt(val.price))?0:parseInt(val.price));
				})
			}
			dataSet[""+siteId].ottPriceVisited = true;
			calculatePercenatge(dataSet,siteId);
			percentageConditionCheck(dataSet,siteId);
		}
	})
}

// FETCHING PAYMENTS
function fetchPrice(dataSet,siteId) {
	base.fetch('/delivery/sites/'+siteId+'/paymentHistory',{
		context:{},
		asArray:true,
		then(data) {
			if(data) {
				data.forEach(val => {
					// CHECK WEEK AND ADD IF CONDITION FULLFILLED
					if(calculateWeek(val.paidDate.time)>0){
						let week = calculateWeek(val.paidDate.time);
						if(dataSet[""+siteId].paymentsWeek[""+week]) {
							dataSet[""+siteId].paymentsWeek[""+week].price += isNaN(parseInt(val.amountPaid))?0:parseInt(val.amountPaid);
							dataSet[""+siteId].paymentsWeek[""+week].date  = dataSet[""+siteId].paymentsWeek[""+week].date<val.paidDate.time?val.paidDate.time:dataSet[""+siteId].paymentsWeek[""+week].date;
						}else{
							dataSet[""+siteId].paymentsWeek[week] = {
								price: isNaN(parseInt(val.amountPaid))?0:parseInt(val.amountPaid),
								date : val.paidDate.time,
								responsible: dataSet[""+siteId].responsible,
								quoteid: dataSet[""+siteId].quoteid
							};
						}
					}

					dataSet[""+siteId].paymentsHistory.amount.push(isNaN(parseInt(val.amountPaid))?0:parseInt(val.amountPaid));
					dataSet[""+siteId].paymentsHistory.date.push(calculateSpecificDateAge(val.paidDate.time,"05/20/2018"));
					dataSet[""+siteId].payment.push(isNaN(parseInt(val.amountPaid))?0:parseInt(val.amountPaid));
				})
			}
			dataSet[""+siteId].paymentVisited = true;
			paymentDateMapping(dataSet,siteId);
			calculatePercenatge(dataSet,siteId);
			// CALL RESPONSIBLE WEEK MAPPING FUNCTION
			paymentWeekResponsible(dataSet,siteId)

		}
	})
}

function calculatePercenatge(dataSet,siteId) {
	if( dataSet[""+siteId].finalPriceVisited && dataSet[""+siteId].ottPriceVisited && dataSet[""+siteId].paymentVisited && dataSet[""+siteId].stageVisited ) {
		visited += 1;
		let totalBillValue = dataSet[""+siteId].bill.finalPrice;
		let totalPaymentValue = 0;
		dataSet[""+siteId].bill.ott.forEach(val => {
			totalBillValue+=val;
		})
		dataSet[""+siteId].payment.forEach(val => {
			totalPaymentValue+=val;
		})
		let percentage = (totalPaymentValue*100)/totalBillValue;
		if(percentage>150) {
		}

		if(!dataSet[""+siteId].stage||dataSet[""+siteId].stage==="NA") {
			dataSet[""+siteId].stage = 11;
		}

		INITPERCENTAGEBUCKET[""+siteId] = {
			stage:dataSet[""+siteId].stage,
			percentage:percentage,
			salesDate:dataSet[""+siteId].salesDate,
		}
	}
	if(Object.keys(INITPERCENTAGEBUCKET).length === total) {
		console.log(new Date());
		dispatcher({type:PERCENTAGE_GRAPH,payload:INITPERCENTAGEBUCKET})
	}
}

function paymentDateMapping(dataSet,siteId) {
	dataSet[""+siteId].paymentsHistory.date.forEach((val,index) => {
		if(val>=0) {
			INITPAYMENTHISTORY[val] += dataSet[""+siteId].paymentsHistory.amount[index];
		}
	})
	paymentVisitedCount++;
	if(paymentVisitedCount===total) {
		dispatcher({type:PAYMENT_HISTORY_GRAPH,payload:INITPAYMENTHISTORY});
	}
}


// WEEK RESPONSIBLE MAPPING
function paymentWeekResponsible(dataSet,siteId) {
	base.fetch('/delivery/responsiblehistory/sites/'+siteId,{
		context:{},
		asArray:false,
		then(data) {
			if(Object.keys(data).length > 0) {
				Object.keys(dataSet[""+siteId].paymentsWeek).forEach(val => {
					let value 	= dataSet[""+siteId].paymentsWeek[""+val].date;
					let keys 	= Object.keys(data);
					let values 	= Object.values(data)
					let responsibleIndex= responsibleSearch(keys,value);
					if(responsibleIndex>=0){
						let responsible = values[responsibleIndex].newResponsiblePhone;
						dataSet[""+siteId].paymentsWeek[""+val].responsible = responsible;
					}else{
						let responsible = values[0].oldResponsiblePhone;
						dataSet[""+siteId].paymentsWeek[""+val].responsible = responsible;
					}
				})
			}
			dataSet[""+siteId].responsibleMappingVisited = true;
			percentageConditionCheck(dataSet,siteId);
 		}
	})
}

// FIND THE PERCENTAGE IF GREATER THAN 20
function percentageConditionCheck(dataSet,siteId) {
	let { responsibleMappingVisited, finalPriceVisited, ottPriceVisited } = dataSet[""+siteId];
	if(responsibleMappingVisited&&finalPriceVisited&&ottPriceVisited) {
		paymentWeek+=1;
		let totalBillValue = dataSet[""+siteId].bill.finalPrice;
		dataSet[""+siteId].bill.ott.forEach(val => {
			totalBillValue+=val;
		})
		Object.keys(dataSet[""+siteId].paymentsWeek).forEach(val => {
			let responsible = dataSet[""+siteId].paymentsWeek[""+val].responsible;
			let totalPaymentValue = dataSet[""+siteId].paymentsWeek[""+val].price;
			let percentage = (totalPaymentValue*100)/totalBillValue;
			if(percentage>=20) {
				if(INITPERCENTAGEWEEK.data[""+val]) {
					if(INITPERCENTAGEWEEK.data[""+val][""+responsible]){
						INITPERCENTAGEWEEK.data[""+val][""+responsible] += 1;
					}else{
						INITPERCENTAGEWEEK.data[""+val][""+responsible] = 1;
						INITPERCENTAGEWEEK["responsible"][""+responsible] = Object.keys(INITPERCENTAGEWEEK["responsible"]).length;
					}
				}else{
					INITPERCENTAGEWEEK.data[""+val] = {};
					INITPERCENTAGEWEEK.data[""+val][""+responsible] = 1;
					INITPERCENTAGEWEEK["responsible"][""+responsible] = Object.keys(INITPERCENTAGEWEEK["responsible"]).length;
				}
			}
		})
		if(paymentWeek===total) {
			INITPERCENTAGEWEEK["responsibleMap"] = {};
			Object.keys(INITPERCENTAGEWEEK["responsible"]).forEach((val,index) => {
				INITPERCENTAGEWEEK["responsibleMap"][""+val] = index;
			});
			dispatcher({type:PAYMENT_RESPONSIBLE_WEEK_GRAPH,payload:INITPERCENTAGEWEEK});
		}
	}
}

function responsibleSearch(arr,value) {
	let low = 0;
	let high = arr.length-1;
	let found = false
	let mid = 0;
	while(!found&&low<=high) {
		mid = Math.round((low+high)/2);
		if(arr[mid]>value) {
			high=mid-1;
		} else if(arr[mid]<value) {
			low = mid+1;
		}else {
			found = true;
		}
	}
	if(arr[mid]>value) {
		return mid-1;
	}
	return mid;
}

//CHECK WEEK
function calculateWeek(date) {
	return Math.ceil(((new Date(date).getTime() - new Date("08/01/2018").getTime()))/(1000*3600*24*7));
}