import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import Select from 'react-select';
import { changeToViewDateTime, changeToInputDate, changeToViewDate } from '../helper/dateChanger';
import { Restrict } from '../components/Restrict';
import { salesAnalyticsTable1, salesAnalyticsTable2, salesAnalyticsTable3 } from '../config/column_fields';
import Modal from 'react-responsive-modal';
import { fetchOpsReportData, setTarget } from '../actions/fetchOpsReport';
import queryString from 'query-string';
import axios from 'axios';
import moment from 'moment';

class OpsReport extends Component {


	constructor(props) {
		super(props);
		this.months = [
			{label:'Jan', value:1},
			{label:'Feb', value:2},
			{label:'Mar', value:3},
			{label:'Apr', value:4},
			{label:'May', value:5},
			{label:'Jun', value:6},
			{label:'Jul', value:7},
			{label:'Aug', value:8},
			{label:'Sep', value:9},
			{label:'Oct', value:10},
			{label:'Nov', value:11},
			{label:'Dec', value:12}
		];
		this.yearOptions = [];
		let currentYear = new Date().getFullYear();
		let currentMonth = new Date().getMonth();
		this.selectedMonth = this.months[currentMonth];
		this.selectedYear = {label:currentYear, value:currentYear};
		this.monthOptions = this.months.filter((v, i) => {
			if(i<=currentMonth){
				return true;
			}
		})
		for(let i=currentYear;i>=2017;i--){
			this.yearOptions.push({label:i,value:i});
		}
		this.state = {
			viewData1:[],
			viewData2:[],
			viewData3:[],
            table1Columms:salesAnalyticsTable1,
            table2Columms:salesAnalyticsTable2,
			table3Columms:salesAnalyticsTable3,
			loading:true,
			targetEditStatus : {},
			monthOptions:[],
			// monthOptions:this.monthOption,
			year:'',
			month:'',
			singleEditStatus:{},
			saTarget:{},
			roofTarget:{},
			monthSelected:0,
			currentMonthId:0,
			stateCityList:[],
			stateSelected:[],
			stateOptions : [],
			stateList : [],
			opsData : {},
			tableData : {},
			existingFallRatio : []
        };
	}

	componentDidMount() {
		if (this.props.user) {
			if (this.props.user.level >= 2300) {
				let currentDate = new Date();
				let currentYear = currentDate.getFullYear();
				let currentMonth = currentDate.getMonth()+1;
				let selectedMonth = currentYear*100 + currentMonth;
				let currentMonthId = selectedMonth;
				let endYear = currentYear;
				let endMonth = currentMonth+1;
				if(endMonth>12){
					endMonth = 1;
					endYear+=1;
				}
				let startEpoch = new Date(`${currentYear}-${currentMonth}-1`).getTime();
				let endEpoch = new Date(`${endYear}-${endMonth}-1`).getTime();
				// this.props.fetchSalesAnalytics(startEpoch, endEpoch, selectedMonth, []);
				this.props.fetchOpsReportData();
				let opsData = this.state.opsData;
				// let opsData = { '202008' : {
				// 	month : '202008',
				// 	saTotalCount: 794,
				//   saPrimeCount: 6,
				//   saNonPrimeCount: 788,
				//   roofTotalCount: 67,
				//   roofPrimeCount: 4,
				//   roofNonPrimeCount: 63,
				//   capacityTotalCount: 247,
				//   capacityPrimeCount: 12,
				//   capacityNonPrimeCount: 235,
				//   netMetering: 128,
				//   commissioning: 153
				// }, '202009' : {
				// 	month : '202009',
				// 	saTotalCount: 926,
				//   saPrimeCount: 1,
				//   saNonPrimeCount: 925,
				//   roofTotalCount: 101,
				//   roofPrimeCount: 5,
				//   roofNonPrimeCount: 96,
				//   capacityTotalCount: 412,
				//   capacityPrimeCount: 33,
				//   capacityNonPrimeCount: 379,
				//   netMetering: 190,
				//   commissioning: 230,
				// 	bookedMargin : 2
				// }, '202010' : {
				// 	month : '202010',
				// 	saTotalCount: 151,
				//   saPrimeCount: 1,
				//   saNonPrimeCount: 150,
				//   roofTotalCount: 63,
				//   roofPrimeCount: 4,
				//   roofNonPrimeCount: 59,
				//   capacityTotalCount: 307,
				//   capacityPrimeCount: 21,
				//   capacityNonPrimeCount: 286,
				//   netMetering: 93,
				//   commissioning: 102
				// }}
				// let formdata = new URLSearchParams();
				// formdata.append('key', 'g6muc4cFoPVVZvs');
				// formdata.append('states', queryString.parse(this.props.location.search).state)
				// axios.post("http://localhost:3002/api/getOpsReport", formdata, {
				// // axios.post("https://operations.zunroof.com/api/getOpsReport", formdata, {
				// 		headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
				// })
				// .then((data) => {
				// 	console.log(data.data.finalData);
				// 		this.setState({ opsData: data.data.finalData, loading : false })
				// })
				// this.table0Columms = this.table0Columms.map(val => {
				// 	if(val.accessor == 'saTarget' || val.accessor == 'roofTarget' || val.accessor == 'bookedMargin'){
				// 		return {
				// 			...val, Cell: row => {
				// 				return (this.state.singleEditStatus[row.index] && this.state.singleEditStatus[row.index][row.column.id] && this.state.monthSelected == this.state.currentMonthId) ?
				// 					this.inputNumberDiv(row) : this.deafultViewDiv(row)
				// 			}
				// 		}
				// 	}
				// 	else{
				// 		return val;
				// 	}
				// })
				let monthOptions = Object.keys(opsData).map(month => {
					return {
						// label : moment(+month, "YYYYMM").format('MMM YYYY'),
						label : month,
						value : month
					}
				});
				this.setState({
					monthSelected:selectedMonth,
					currentMonthId:selectedMonth,
					year:currentYear,
					month:currentMonth,
					monthOptions : monthOptions,
					tableData : opsData,
					// opsData : opsData,
					// loading : false
				})
			}
		}
	}


	deafultViewDiv = (row) => {
		// console.log("naman", row);
		// let state = row.original.state;
		// if (row.column.id === "saTarget") {
		// 	value = this.state.saTarget?this.state.saTarget[state]:0;
		// }
		// if(row.column.id === "roofTarget"){
		// 	value = this.state.roofTarget?this.state.roofTarget[state]:0;
		// }
		return (<div onDoubleClick={() => { this.handleOnTableCellClick(row.original.month, row.column.id) }} >{row.value || "Unavailable"}</div>);
	}

	inputNumberDiv = (row) => {
		let defaultValue = row.value;
		// let state = row.original.state;
		// if (row.column.id === "saTarget") {
		// 	defaultValue = this.state.saTarget?this.state.saTarget[state]:0;
		// }
		// if(row.column.id === "roofTarget"){
		// 	defaultValue = this.state.roofTarget?this.state.roofTarget[state]:0;
		// }
		return <input type="number" onKeyUp={(e) => { this.handleOnKeyPress(e, row, row.original.month, row.column.id) }} className="form-control" name={`${row.original.month}__${row.column.id}`} defaultValue={defaultValue} />
	}

	handleOnTableCellClick = (month, id) => {
		let editStatus = this.state.targetEditStatus[month] || {};
		editStatus[id] = true;
		this.setState({
			targetEditStatus: { ...this.state.targetEditStatus, [month]: editStatus }
		})
	}

	handleOnKeyPress = (e, row, month, columnId) => {
		let { value } = e.target;
		let { targetEditStatus } = this.state;
		if (e.keyCode === 13 && !e.shiftKey) {
			let editStatus = targetEditStatus[month] || {};
			editStatus[columnId] = false;
			let opsData = this.state.opsData;
			opsData[month][columnId] = value;
			this.props.setTarget(value, columnId, month);
			this.setState({
				targetEditStatus: { ...this.state.targetEditStatus, [month]: editStatus },
				opsData : opsData
			})
		}
		if (e.keyCode === 27) {
			let editStatus = targetEditStatus[month] || {};
			editStatus[columnId] = false;
			this.setState({
				targetEditStatus: { ...this.state.targetEditStatus, [month]: editStatus }
			});
		}
	}

	componentWillReceiveProps(props) {
		if(props.opsReportData && props.opsReportData.data){
			let { stateList, stateCityData, employeeData, saTarget, roofTarget, stateCityList, existingFallRatio, finalData } = { ...props.opsReportData.data };
			// let dataState = stateData.map(val => {
			// 	val.saTarget = saTarget?saTarget[val.state]:'';
			// 	val.roofTarget = roofTarget?roofTarget[val.state]:'';
			// 	return val;
			// });
			let monthOptions = Object.keys(finalData).reverse().map(month => {
				return {
					// label : moment(+month, "YYYYMM").format('MMM YYYY'),
					label : month,
					value : month
				}
			});
			let stateOptions = stateList.map(state => {
				return {
					label : state,
					value : state
				}
			});
			this.setState({
				loading : false,
				stateList : stateList,
				monthOptions : monthOptions,
				stateOptions : stateOptions,
				tableData : finalData,
				existingFallRatio : existingFallRatio,
				opsData : finalData,
			})
		}
	}

	onYearChange = (e) => {
		let year = e.value;
		if(year<new Date().getFullYear()){
			this.setState({
				monthOptions:this.months,
				year:e.value
			})
		}
		else{
			let monthOptions = this.months.filter((v, i) => {
				if(i<=new Date().getMonth()){
					return true;
				}
			})
			this.setState({
				monthOptions,
				year:e.value
			})
		}
	}

	onMonthChange = (e) => {
		let tableData = {};
		let opsData = this.state.opsData;
		if (e.length > 0) {
			e.forEach(month => {
				month = month.value
				tableData[month] = opsData[month]
			})
		} else {
			tableData = opsData
		}
		this.setState({
			tableData : tableData
		})
	}

	onStateChange = (e) => {
		this.props.fetchOpsReportData(e.value);
		this.setState({
			loading : true,
			stateSelected : e.value
		})
	}

	applyFilter = (e) => {
		let currentMonth = parseInt(new Date().getFullYear())*100 + (parseInt(new Date().getMonth())+1);
		let selectedMonth = parseInt(this.state.year)*100 + parseInt(this.state.month);
		if(selectedMonth <= currentMonth){
			let startEpoch = new Date(`${this.state.year}-${this.state.month}-1`).getTime();
			let endMonth = this.state.month + 1;
			let endYear = this.state.year;
			if(endMonth>12){
				endMonth = 1;
				endYear+=1;
			}
			let endEpoch = new Date(`${endYear}-${endMonth}-1`).getTime();
			let stateList = this.state.stateSelected.map(obj => {
				return obj.value;
			})
			// this.props.fetchOpsReportData(startEpoch, endEpoch, selectedMonth, stateList);
			this.setState({
				loading: true,
				monthSelected:selectedMonth
			})
		}
		else{
			alert("Invalid Month");
		}
	}

	table0Columms = [
		{
			Header: 'Month',
			accessor: 'month'
		},
		{
			Header: 'No of Assessments',
			accessor: 'saTotalCount'
		},
		{
			Header: 'No of Roofs',
			accessor: 'roofTotalCount'
		},
		{
			Header: 'Capacity(in Kw)',
			accessor: 'capacityTotalCount',
			Cell: row => {
				return +(row.value || 0).toFixed(2)
			}
		},
		{
			Header: 'Booked Sales Value',
			accessor: 'bookedSalesTotalValue',
			Cell: row => {
				return +(row.value || 0).toFixed(2)
			}
		},
		{
			Header: 'Booked Margin',
			accessor: 'bookedMargin',
			Cell: row => {
				return (this.state.targetEditStatus[row.original.month] && this.state.targetEditStatus[row.original.month][row.column.id]) ?
					this.inputNumberDiv(row) : this.deafultViewDiv(row)
			}
		},
		{
			Header: 'Commissioning',
			accessor: 'commissioning'
		},
		{
			Header: 'Net Metering',
			accessor: 'netMetering'
		},
		{
			Header: 'Payment Collection',
			accessor: 'totalPaid'
		},
		{
			Header: 'Average Size',
			accessor: 'capacityTotalCount',
			Cell: row => {
				return (row.original.capacityTotalCount && row.original.roofTotalCount ? (row.original.capacityTotalCount/row.original.roofTotalCount).toFixed(2) : 0)
			}
		},
	]

	table1Columms = [
		{
			Header: 'Month',
			accessor: 'month'
		},
		{
			Header: 'No. of Roofs (Projected)',
			accessor: 'roofNonPrimeTarget',
			Cell: row => {
				return (this.state.targetEditStatus[row.original.month] && this.state.targetEditStatus[row.original.month][row.column.id]) ?
					this.inputNumberDiv(row) : this.deafultViewDiv(row)
			}
		},
		{
			Header: 'No of Roofs (Achieved)',
			accessor: 'roofNonPrimeCount'
		},
		{
			Header: 'Capacity (Projected)',
			accessor: 'capacityNonPrimeTarget',
			Cell: row => {
				return (this.state.targetEditStatus[row.original.month] && this.state.targetEditStatus[row.original.month][row.column.id]) ?
					this.inputNumberDiv(row) : this.deafultViewDiv(row)
			}
		},
		{
			Header: 'Capacity (Achieved)',
			accessor: 'capacityNonPrimeCount',
			Cell: row => {
				return +(row.value || 0).toFixed(2)
			}
		},
		{
			Header: 'Booked Sales Value (Projected)',
			accessor: 'bookedSalesNonPrimeTarget',
			Cell: row => {
				return (this.state.targetEditStatus[row.original.month] && this.state.targetEditStatus[row.original.month][row.column.id]) ?
					this.inputNumberDiv(row) : this.deafultViewDiv(row)
			}
		},
		{
			Header: 'Booked Sales Margin (Approved)',
			accessor: 'bookedSalesNonPrimeValue',
			Cell: row => {
				return +(row.value || 0).toFixed(2)
			}
		},
		{
			Header: 'Client to pay (Projected)',
			accessor: 'clientToPayNonPrimeTarget',
			Cell: row => {
				return (this.state.targetEditStatus[row.original.month] && this.state.targetEditStatus[row.original.month][row.column.id]) ?
					this.inputNumberDiv(row) : this.deafultViewDiv(row)
			}
		},
		{
			Header: 'Client to pay (Approved)',
			accessor: 'clientToPayNonPrime',
			Cell: row => {
				return +(row.value || 0).toFixed(2)
			}
		},
		{
			Header: 'P1 Token Projected',
			accessor: 'p1TokenNonPrimeTarget',
			Cell: row => {
				return (this.state.targetEditStatus[row.original.month] && this.state.targetEditStatus[row.original.month][row.column.id]) ?
					this.inputNumberDiv(row) : this.deafultViewDiv(row)
			}
		},
		{
			Header: 'P1 Token Achieved',
			accessor: 'totalPaid'
		}
	]

	table2Columms = [
		{
			Header: 'Month',
			accessor: 'month'
		},
		{
			Header: 'No. of Roofs (Projected)',
			accessor: 'roofPrimeTarget',
			Cell: row => {
				return (this.state.targetEditStatus[row.original.month] && this.state.targetEditStatus[row.original.month][row.column.id]) ?
					this.inputNumberDiv(row) : this.deafultViewDiv(row)
			}
		},
		{
			Header: 'No of Roofs (Achieved)',
			accessor: 'roofPrimeCount'
		},
		{
			Header: 'Capacity (Projected)',
			accessor: 'capacityPrimeTarget',
			Cell: row => {
				return (this.state.targetEditStatus[row.original.month] && this.state.targetEditStatus[row.original.month][row.column.id]) ?
					this.inputNumberDiv(row) : this.deafultViewDiv(row)
			}
		},
		{
			Header: 'Capacity (Achieved)',
			accessor: 'capacityPrimeCount'
		},
		{
			Header: 'Booked Sales Value (Projected)',
			accessor: 'bookedSalesPrimeTarget',
			Cell: row => {
				return (this.state.targetEditStatus[row.original.month] && this.state.targetEditStatus[row.original.month][row.column.id]) ?
					this.inputNumberDiv(row) : this.deafultViewDiv(row)
			}
		},
		{
			Header: 'Booked Sales Value (Approved)',
			accessor: 'bookedSalesPrimeValue'
		},
		{
			Header: 'Client to pay (Projected)',
			accessor: 'clientToPayPrimeTarget',
			Cell: row => {
				return (this.state.targetEditStatus[row.original.month] && this.state.targetEditStatus[row.original.month][row.column.id]) ?
					this.inputNumberDiv(row) : this.deafultViewDiv(row)
			}
		},
		{
			Header: 'Client to pay (Approved)',
			accessor: 'clientToPayPrime'
		}
	]

	table3Columms = [
		{
			Header: 'Month',
			accessor: 'month'
		},
		{
			Header: 'Payment Collection (Grand Total) Projected',
			accessor: 'paymentCollectionTotalTarget',
			Cell: row => {
				return (this.state.targetEditStatus[row.original.month] && this.state.targetEditStatus[row.original.month][row.column.id]) ?
					this.inputNumberDiv(row) : this.deafultViewDiv(row)
			}
		},
		{
			Header: 'Payment Collection (Grand Total) Achieved',
			accessor: 'totalPaid'
		},
		{
			Header: 'Payment Collection (ZunPrime) Projected',
			accessor: 'totalPaidPrimeTarget',
			Cell: row => {
				return (this.state.targetEditStatus[row.original.month] && this.state.targetEditStatus[row.original.month][row.column.id]) ?
					this.inputNumberDiv(row) : this.deafultViewDiv(row)
			}
		},
		{
			Header: 'Payment Collection (ZunPrime) Achieved',
			accessor: 'totalPaidPrime'
		},
		{
			Header: 'P1 Token (in terms of % of Total Client to Pay) Projected',
			accessor: 'p1TokenNewTarget',
			Cell: row => {
				return (this.state.targetEditStatus[row.original.month] && this.state.targetEditStatus[row.original.month][row.column.id]) ?
					this.inputNumberDiv(row) : this.deafultViewDiv(row)
			}
		},
		{
			Header: 'P1 Token (in terms of % of Total Client to Pay) Achieved',
			accessor: 'p1TokenNew'
		},
		{
			Header: 'P1 Remaining ( from New projects ) Projected',
			accessor: 'p1RemainingNewTarget',
			Cell: row => {
				return (this.state.targetEditStatus[row.original.month] && this.state.targetEditStatus[row.original.month][row.column.id]) ?
					this.inputNumberDiv(row) : this.deafultViewDiv(row)
			}
		},
		{
			Header: 'P1 Remaining ( from New projects ) Achieved',
			accessor: 'p1RemainingNew'
		},
		{
			Header: 'P2 ( from New projects ) Projected',
			accessor: 'p2NewTarget',
			Cell: row => {
				return (this.state.targetEditStatus[row.original.month] && this.state.targetEditStatus[row.original.month][row.column.id]) ?
					this.inputNumberDiv(row) : this.deafultViewDiv(row)
			}
		},
		{
			Header: 'P2 ( from New projects ) Achieved',
			accessor: 'p2New'
		},
		{
			Header: 'P1 Remaining ( Existing Projects ) Projected',
			accessor: 'p1RemainingExistingTarget',
			Cell: row => {
				return (this.state.targetEditStatus[row.original.month] && this.state.targetEditStatus[row.original.month][row.column.id]) ?
					this.inputNumberDiv(row) : this.deafultViewDiv(row)
			}
		},
		{
			Header: 'P1 Remaining ( Existing Projects ) Achieved',
			accessor: 'p1RemainingExisting'
		},
		{
			Header: 'P2 ( Existing Projects ) Projected',
			accessor: 'p2ExistingTarget',
			Cell: row => {
				return (this.state.targetEditStatus[row.original.month] && this.state.targetEditStatus[row.original.month][row.column.id]) ?
					this.inputNumberDiv(row) : this.deafultViewDiv(row)
			}
		},
		{
			Header: 'P2 ( Existing Projects ) Achieved',
			accessor: 'p2Existing'
		}
	]

	table4Columms = [
		{
			Header: 'Month',
			accessor: 'month'
		},
		{
			Header: 'Existing Projects Projected',
			accessor: 'comissioningExistingTarget',
			Cell: row => {
				return (this.state.targetEditStatus[row.original.month] && this.state.targetEditStatus[row.original.month][row.column.id]) ?
					this.inputNumberDiv(row) : this.deafultViewDiv(row)
			}
		},
		{
			Header: 'Existing Projects Achieved',
			accessor: 'comissioningExisting'
		},
		{
			Header: 'New Projects (ZunPrime) Projected',
			accessor: 'comissioningNewPrimeTarget',
			Cell: row => {
				return (this.state.targetEditStatus[row.original.month] && this.state.targetEditStatus[row.original.month][row.column.id]) ?
					this.inputNumberDiv(row) : this.deafultViewDiv(row)
			}
		},
		{
			Header: 'New Projects (ZunPrime) Achieved',
			accessor: 'comissioningNewPrime'
		},
		{
			Header: 'New Projects (Non ZunPrime) Projected',
			accessor: 'comissioningNewNonPrimeTarget',
			Cell: row => {
				return (this.state.targetEditStatus[row.original.month] && this.state.targetEditStatus[row.original.month][row.column.id]) ?
					this.inputNumberDiv(row) : this.deafultViewDiv(row)
			}
		},
		{
			Header: 'New Projects (Non ZunPrime) Achieved',
			accessor: 'comissioningNewNonPrime'
		}
	]

	table5Columms = [
		{
			Header: 'Month',
			accessor: 'month'
		},
		{
			Header: 'Existing Projects Projected',
			accessor: 'netMeteringExistingTarget',
			Cell: row => {
				return (this.state.targetEditStatus[row.original.month] && this.state.targetEditStatus[row.original.month][row.column.id]) ?
					this.inputNumberDiv(row) : this.deafultViewDiv(row)
			}
		},
		{
			Header: 'Existing Projects Achieved',
			accessor: 'netMeteringExisting'
		},
		{
			Header: 'New Projects (ZunPrime) Projected',
			accessor: 'netMeteringNewPrimeTarget',
			Cell: row => {
				return (this.state.targetEditStatus[row.original.month] && this.state.targetEditStatus[row.original.month][row.column.id]) ?
					this.inputNumberDiv(row) : this.deafultViewDiv(row)
			}
		},
		{
			Header: 'New Projects (ZunPrime) Achieved',
			accessor: 'netMeteringNewPrime'
		},
		{
			Header: 'New Projects (Non ZunPrime) Projected',
			accessor: 'netMeteringNewNonPrimeTarget',
			Cell: row => {
				return (this.state.targetEditStatus[row.original.month] && this.state.targetEditStatus[row.original.month][row.column.id]) ?
					this.inputNumberDiv(row) : this.deafultViewDiv(row)
			}
		},
		{
			Header: 'New Projects (Non ZunPrime) Achieved',
			accessor: 'netMeteringNewNonPrime'
		}
	]

	table6Columms = [
		{
			Header: 'Month',
			accessor: 'month'
		},
		{
			Header: 'Panel (in KW) Projected',
			accessor: 'panelKWTarget',
			Cell: row => {
				return (this.state.targetEditStatus[row.original.month] && this.state.targetEditStatus[row.original.month][row.column.id]) ?
					this.inputNumberDiv(row) : this.deafultViewDiv(row)
			}
		},
		{
			Header: 'Panel (in KW) Achieved',
			accessor: 'panelKW'
		},
		{
			Header: 'Panel (in INR) Projected',
			accessor: 'panelINRTarget',
			Cell: row => {
				return (this.state.targetEditStatus[row.original.month] && this.state.targetEditStatus[row.original.month][row.column.id]) ?
					this.inputNumberDiv(row) : this.deafultViewDiv(row)
			}
		},
		{
			Header: 'Panel (in INR) Achieved',
			accessor: 'panelINR',
			Cell: row => {
				return +(row.value || 0).toFixed(2)
			}
		},
		{
			Header: 'Inverter (in KW) Projected',
			accessor: 'inverterKWTarget',
			Cell: row => {
				return (this.state.targetEditStatus[row.original.month] && this.state.targetEditStatus[row.original.month][row.column.id]) ?
					this.inputNumberDiv(row) : this.deafultViewDiv(row)
			}
		},
		{
			Header: 'Inverter (in KW) Achieved',
			accessor: 'inverterKW',
			Cell: row => {
				return +(row.value || 0).toFixed(2)
			}
		},
		{
			Header: 'Inverter (in INR) Projected',
			accessor: 'inverterINRTarget',
			Cell: row => {
				return (this.state.targetEditStatus[row.original.month] && this.state.targetEditStatus[row.original.month][row.column.id]) ?
					this.inputNumberDiv(row) : this.deafultViewDiv(row)
			}
		},
		{
			Header: 'Inverter (in INR) Achieved',
			accessor: 'inverterINR',
			Cell: row => {
				return +(row.value || 0).toFixed(2)
			}
		}
	]

	table7Columms = [
		{
			Header: 'Month',
			accessor: 'month'
		},
		{
			Header: 'M0',
			accessor: 'fallNewM0'
		},
		{
			Header: 'M1',
			accessor: 'fallNewM1'
		},
		{
			Header: 'M2',
			accessor: 'fallNewM2'
		},
		{
			Header: 'M3',
			accessor: 'fallNewM3'
		}
	]

	table8Columms = [
		{
			Header: 'Parameter',
			accessor: 'keyName'
		},
		{
			Header: 'M0(Jul 20)',
			accessor: 'M0'
		},
		{
			Header: 'M1(AUG 20)',
			accessor: 'M1'
		},
		{
			Header: 'M2(SEP 20)',
			accessor: 'M2'
		},
		{
			Header: 'M3(OCT 20)',
			accessor: 'M3'
		}
	]

	render() {
		const style = {
			hide: {
				display: 'none'
			},
			show: {
				display: ''
			},
			iconShow: {
				float: 'right',
				display: ''
			},
			iconHide: {
				float: 'right',
				display: 'none'
			}
		};
		if (this.props.user === null) {
			return <Redirect to={"/login?redirect_uri=newopsreport"} />;
		}

		if (this.props.user && this.props.user.level < 2300) {
			return (
				<div>
					<Restrict />
				</div>
			)
		}

		const tableData = Object.values(this.state.tableData).reverse();

		return (
            <div className="container-fluid" style={{marginTop:"50px"}}>
				<div className="row">
					<div className="col-md-2 col-6">
						<Select
							isMulti
							isSearchable
							onChange={ (e)=>this.onMonthChange(e) }
							placeholder={"Select Month"}
							name={"month"}
							options={this.state.monthOptions}
						/>
					</div>
					<div className="col-md-2 col-6">
						<Select
							isSearchable
							onChange={ (e)=>this.onStateChange(e) }
							placeholder={"Select State"}
							name={"state"}
							options={this.state.stateOptions}
						/>
					</div>
					{/*<div className="col-md-2 col-6">
						<Select
							isSearchable
							onChange={ (e)=>this.onYearChange(e)  }
							placeholder={"Select Year"}
							name={"year"}
							options={this.yearOptions}
							defaultValue={this.selectedYear}
						/>
					</div>
					<div className="col-md-2 col-6">
						<Select
							isMulti
							isSearchable
							onChange={ (e)=>this.onStateChange(e) }
							placeholder={"Select State"}
							name={"selectedState"}
							options={this.state.stateCityList.map(val=>{return{label:val, value:val}})}
						/>
					</div>
					<div className="col-md-2 col-6">
						<button onClick={(e)=>this.applyFilter(e)} className="btn btn-success">Apply Filter</button>
					</div>*/}
				</div>
                {/*<h3 style={{marginTop:"30px"}}>State Wise</h3>
                <ReactTable
					filterable={true}
					showPagination={true}
                    sortable={true}
                    defaultPageSize={5}
					data={this.state.viewData1}
					columns={this.state.table1Columms}
					defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
					loading={this.state.loading}
					style={{ textAlign: 'center' }}
				/>*/}
			<h3 style={{marginTop:"30px"}}>Grand Summary Table 0</h3>
			<ReactTable
				filterable={true}
				showPagination={true}
				sortable={true}
				defaultPageSize={5}
				data={tableData}
				columns={this.table0Columms}
				defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
				loading={this.state.loading}
				style={{ textAlign: 'center' }}
			/>
		<h3 style={{marginTop:"30px"}}>Table 1 - Sales Table (Non Zunprime orders)</h3>
			<ReactTable
				filterable={true}
				showPagination={true}
				sortable={true}
				defaultPageSize={5}
				data={tableData}
				columns={this.table1Columms}
				defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
				loading={this.state.loading}
				style={{ textAlign: 'center' }}
			/>
		<h3 style={{marginTop:"30px"}}>Table 2 - Sales Table (Zunprime orders)</h3>
			<ReactTable
				filterable={true}
				showPagination={true}
				sortable={true}
				defaultPageSize={5}
				data={tableData}
				columns={this.table2Columms}
				defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
				loading={this.state.loading}
				style={{ textAlign: 'center' }}
			/>
		<h3 style={{marginTop:"30px"}}>Table 3 - Payment Collection</h3>
			<ReactTable
				filterable={true}
				showPagination={true}
				sortable={true}
				defaultPageSize={5}
				data={tableData}
				columns={this.table3Columms}
				defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
				loading={this.state.loading}
				style={{ textAlign: 'center' }}
			/>
		<h3 style={{marginTop:"30px"}}>Table 4 - Comissioning</h3>
			<ReactTable
				filterable={true}
				showPagination={true}
				sortable={true}
				defaultPageSize={5}
				data={tableData}
				columns={this.table4Columms}
				defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
				loading={this.state.loading}
				style={{ textAlign: 'center' }}
			/>
		<h3 style={{marginTop:"30px"}}>Table 5 - Net Metering</h3>
			<ReactTable
				filterable={true}
				showPagination={true}
				sortable={true}
				defaultPageSize={5}
				data={tableData}
				columns={this.table5Columms}
				defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
				loading={this.state.loading}
				style={{ textAlign: 'center' }}
			/>
		<h3 style={{marginTop:"30px"}}>Table 6 - Procurement</h3>
			<ReactTable
				filterable={true}
				showPagination={true}
				sortable={true}
				defaultPageSize={5}
				data={tableData}
				columns={this.table6Columms}
				defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
				loading={this.state.loading}
				style={{ textAlign: 'center' }}
			/>
		<h3 style={{marginTop:"30px"}}>Table 7 - Fall Table (New Projects)</h3>
			<ReactTable
				filterable={true}
				showPagination={true}
				sortable={true}
				defaultPageSize={5}
				data={tableData}
				columns={this.table7Columms}
				defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
				loading={this.state.loading}
				style={{ textAlign: 'center' }}
			/>
		<h3 style={{marginTop:"30px"}}>Table 8 - Fall Table (Old Projects)</h3>
			<ReactTable
				filterable={true}
				showPagination={true}
				sortable={true}
				defaultPageSize={4}
				data={this.state.existingFallRatio}
				columns={this.table8Columms}
				defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
				loading={this.state.loading}
				style={{ textAlign: 'center' }}
			/>
    </div>
		);


	}
}

function mapStateToProps(state) {
	return {
		user: state.user,
		opsReportData : state.opsReportData
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({fetchOpsReportData }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(OpsReport);
