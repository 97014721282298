import { base, db } from '../connection';

export function changeEscalationStatus(quoteId, type, status) {
    console.log(quoteId, type);
    return async (dispatch) => {
        changeStatus(quoteId, type, status).then(() => {
            console.log("Done");
        })
    }
}

async function changeStatus(quoteId, type, status){
    try{
        if(quoteId && type){
            await db.ref(`delivery/webPortal/escalations/${quoteId}/${type}/status`).set(status);
            console.log("Done");
            alert("Status Changed");
        }
        else{
            alert("Some Error Occurred");            
        }
    }
    catch(e){
        console.log(e);
        alert("Some Error Occurred");
    }
}