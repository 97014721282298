import { ADDING_COMMENT_STATUS } from '../actions/actiontypes';

import { base } from '../connection';

let arrayCheck = [];
let lock = 0;

function createDateObj() {
	let dateVal = new Date();
	let dateObj = {
		date:dateVal.getDate(),
		day:dateVal.getDay(),
		hours:dateVal.getHours(),
		minutes:dateVal.getMinutes(),
		month:dateVal.getMonth()+1,
		seconds:dateVal.getSeconds(),
		time:dateVal.getTime(),
		timezoneOffset:dateVal.getTimezoneOffset(),
		year:dateVal.getFullYear()
	}
	return dateObj;
}


function getUserObject(user,cb){
   base.fetch('/users/'+user+'/mUser_Contact',{})
   .then(data=>{
	   return cb(null,data);
   })
   .catch(error=>{
	   return cb(error);
   })
}

function pushCommentChange(dispatch,siteId,data) {
	base.post('/delivery/sites/'+siteId+'/comments', {
	    data: data	
	    ,then(err){
	      if(!err){
	      	console.log("SUCCESS")
	      	lock=0;
	      	activateProcess(dispatch)
	      }else{
	      	console.log(err);
	      }
	    }
	  });
}

function addComments(dispatch,siteId,user,nameVar,newChangeValue,oldChangeValue){
	getUserObject(user, (err,userObj) => {
	 	if(err){
	 		console.log(err);
	 	}else{
			base.fetch('/delivery/sites/'+siteId, {})
			.then(data => {
				let commentsArray =[];
				if(data.comments){
				commentsArray = [...[],...data.comments];
				let lastValue = JSON.parse(JSON.stringify(commentsArray[commentsArray.length-1]));
				lastValue.commentId=""+commentsArray.length;
				lastValue.comment='New '+nameVar+': ' +(newChangeValue)+'\nOld '+nameVar+': '+oldChangeValue;
				lastValue.commentDate=createDateObj();
				lastValue.employeeId = ""+user;
				lastValue.employeeName=userObj.mContact_FirstName+' '+userObj.mContact_LastName;
				commentsArray.push(lastValue);
				pushCommentChange(dispatch,siteId,commentsArray);
				}
				else{
					var finalCommentArray = {
						comment:'New '+nameVar+': ' +(newChangeValue)+'\nOld '+nameVar+': NA',
						commentDate:createDateObj(),
						commentId:""+0,
						employeeId:""+user,
						employeeName:userObj.mContact_FirstName+' '+userObj.mContact_LastName
					}
					commentsArray.push(finalCommentArray);
					pushCommentChange(dispatch,siteId,commentsArray);
				}
			})
	 	}
	 });
}


export function getCommentProcessRequest(processCheck,dispatch) {
	arrayCheck.push(processCheck);
	console.log(arrayCheck.length);
	activateProcess(dispatch);

}

function activateProcess(dispatch) {
	console.log( lock, arrayCheck.length );
	if(lock===0&&arrayCheck.length>0) {
		lock=1;
		var remove = arrayCheck.splice(0,1);
		console.log(remove,lock,new Date())
		addComments(dispatch,remove[0].siteId,remove[0].user,remove[0].nameVar,remove[0].newChangeValue,remove[0].oldChangeValue);
	}else{
		dispatch({type:ADDING_COMMENT_STATUS,payload:false})
	}
}