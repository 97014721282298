import React from 'react';
import Button from '@material-ui/core/Button';

import '../styles/auth.css';

export const LoginComponent = ({ phoneNumber, code, getcode, requestCode, buttontext, handleOnInputChange, onSubmitForm }) => {
	return (
		<div className="formBody">
			<div className="authFormInside">
				<div className="formHeading"><div className="formHeader">
					<img src="https://firebasestorage.googleapis.com/v0/b/zunpulse4development.appspot.com/o/images%2FZunWindow%2FZunPulse_Logo.jpg?alt=media&token=4e89bbe3-bd75-4b92-9c85-e913c49ac8ca" style={{'paddingBottom': '10px'}}width='50px' alt="ZunWindow Logo"/>
					<div>Sign in</div>
				</div></div>
				<form onSubmit = {onSubmitForm}>
				  <div className="form-group formTop">
				    <label htmlFor="exampleInputEmail1">Mobile Number :</label>
				    <div className="input-group">
					    <div className="input-group-prepend">
							<span className="input-group-text" id="inputGroup-sizing-default">+91</span>
						</div>
						<input type="text" className="form-control" name = "phoneNumber" placeholder="Eg. 9988XXXXX" autoComplete="off" onChange={handleOnInputChange}/>
					</div>
				    <small id="emailHelp" className="form-text text-muted">{requestCode&&!getcode?"Sending OTP...":""}</small>
				  </div>
				  { 
				  	getcode?OTPComponent(handleOnInputChange,phoneNumber):""
				  }
				  <div className="formButton"><Button type="submit" variant="contained" color="primary" className="btn btn-primary">{buttontext}</Button></div>
				</form>
			</div>
		</div>
	);
}

const OTPComponent = (handleOnInputChange,phoneNumber) => {
	return (
		<div>
			<div className="form-group formTop">
				<label htmlFor="exampleInputPassword1">Enter OTP sent to {phoneNumber} :</label>
				<input type="text" className="form-control" placeholder="Enter OTP" name = "code" autoComplete="off" onChange={handleOnInputChange}/>
			</div>
			{	// FUTURE WORK WILL USE TO REMEMBER PASSWORD 
				/*<div className="form-group form-check formTop">
					<input type="checkbox" className="form-check-input" id="exampleCheck1" />
					<label className="form-check-label" htmlFor="exampleCheck1">Remember Me</label>
				</div>*/
			}
		</div>
	)
}