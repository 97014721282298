import { CREATE_COMPLAINT_FAILED, CREATE_COMPLAINT_SUCCESS } from './actiontypes';

import { base, db } from '../connection';

export function createComplaint(dataObj, employeeId) {
	console.log(new Date());
  	return (dispatch) => {
        getNumberAndCityFromQuoteId(dataObj.quoteId)
        .then(quoteData => {
            let createTime = (new Date()).getTime();
            let dueDate = new Date(createTime);
            dueDate.setDate(dueDate.getDate()+2);
            dueDate.setHours(23);
            dueDate.setMinutes(59);
            dueDate.setSeconds(59);
            let userEmail = '';
            if(quoteData.email && quoteData.email!=''){
                userEmail = quoteData.email;
            }
            let ticketId = parseInt(createTime/1000)+dataObj.quoteId;
            let actionObj = {
                [createTime] : {
                    status : 'Initiated',
                    text : 'Your Complaint is submitted',
                    updatedBy : '',
                    updateTime : createTime,
                    dueDate:dueDate.getTime(),
                    removed:0,
                    responsibleTeam:dataObj.responsibleTeam,
                    assignedTo:dataObj.assignedTo
                }
            };
            let complaintObj = {
                quoteId:dataObj.quoteId,
                phoneNumber : quoteData.phoneNumber,
                email: userEmail,
                type:dataObj.type,
                subType:dataObj.subType,
                status:'Initiated',
                text : 'Your Complaint is submitted',
                city : quoteData.city,
                ticketId : ticketId,
                updatedBy : '',
                updateTime : createTime,
                createdBy:employeeId,
                comment:dataObj.description,
                epoch:createTime,
                actions:actionObj,
                source:dataObj.source,
                assignedTo:dataObj.assignedTo,
                dueDate:dueDate.getTime(),
                removed:0,
                responsibleTeam:dataObj.responsibleTeam
            };
            let ticketPath = `website/complaint/tickets/${ticketId}`;
            setData(ticketPath,complaintObj);
            let quoteTicketMapPath = `website/complaint/quoteToTicketsMap/${dataObj.quoteId}/${ticketId}`;
            setData(quoteTicketMapPath, 1);
            dispatch({type:CREATE_COMPLAINT_SUCCESS,payload:complaintObj});
            sendEmailCM(ticketId, dataObj.quoteId, quoteData.city);
            if(userEmail && userEmail!=''){
                sendEmailCustomer(userEmail, ticketId);
            }
        })
        .catch(e => {
            console.log("INERROR");
            console.log(e);
            alert("Something went wrong");
            dispatch({type:CREATE_COMPLAINT_FAILED,payload:0});
        })    	
 	}
}

function setData(path, data){
    db.ref(path).set(data)
    .then((val) => {
        return
    })
    .catch(e => {
        return e;
    })
}

function fetchNameFromId(userId){
    return new Promise((resolve,reject) => {
		db.ref('users/'+userId+'/mUser_Contact').once('value')
        .then(snapshot => {
            let userObj = snapshot.val();
            let fullname = userObj.mContact_FirstName + " " + userObj.mContact_LastName;
            resolve(fullname);
        })
        .catch(e => {
            reject(e);
        })
	});
}

function getNumberAndCityFromQuoteId(quoteId){
    
        return new Promise((resolve,reject) => {
            if(quoteId){
            db.ref(`mapstore/quoteidtocustomerid/${quoteId}`).once('value')
            .then(snapshot => {
                if(snapshot.val()){
                    let userId = snapshot.val();
                    db.ref(`users/${userId}/mUser_Contact`).once('value')
                    .then(citySnapshot => {
                        let data = {};
                        if(citySnapshot.val()){
                        let userData = citySnapshot.val();
                        let city = userData.mContact_City;
                        let userEmail = userData.mContact_Email;
                        data = {
                            phoneNumber : userId,
                            city : city,
                            email:userEmail
                        };
                        }
                        else{
                        data = {
                                phoneNumber : userId,
                                city : '',
                                email:''
                            };
                        }
                        resolve(data);
                    })
                    .catch(e => {
                        console.log(e);
                        let data = {
                            phoneNumber : userId,
                            city : '',
                            email:''
                        };
                        resolve(data);
                    })
                }
                else{
                    reject("NOT PRESENT");
                    alert("INVALID QUOTE ID");
                }
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
        }
        else{
            reject("NULL QUOTE ID");
            alert("INVALID QUOTE ID");
        }
        });
    
    
}

// $database->getReference('website/complaint/tickets/'.$ticketId)
//    ->set([
//        'quoteId' => $quoteId,
//        'phoneNumber' => $phoneNumber,
//        'email' => $email,
//        'type' => $type,
//        'comment' => $comment,
//        'ticketId' => $ticketId,
//        'epoch' => $epochTime,
//        'subType' => $subType,
//        'status' => 'Initiated',
//        'text' => 'Your Complaint is submitted',
//        'updatedBy' => '',
//         'updateTime' => $epochTime,
//         'city' => $city
//       ]);
// $database->getReference('website/complaint/tickets/'.$ticketId.'/actions'.'/'.$epochTime)
// ->set([
//     'status' => 'Initiated',
//     'text' => 'Your Complaint is submitted',
//     'updatedBy' => '',
//     'updateTime' => $epochTime
//     ]);
// $database->getReference('website/complaint/quoteToTicketsMap/'.$quoteId.'/'.$ticketId)
// ->set(1);

function sendEmailCM(ticketId, quoteId, city){
    let cityArray1 = ["Noida","Greater Noida","Ghaziabad","Lucknow","Kanpur","Meerut","Agra","Varanasi","Allahabad","Ayodhya","Jhansi","Faizabad","Mathura","Saharanpur","Bareli","Aligarh","Moradabad"];
    let cityArray2 = ["Chandigarh","Dera Bassi","Jalandhar","Ludhiana","Zirakpur","Mohali","Kharar","Amritsar","Patiala","Hoshiarpur","Bathinda","Barnala","Sangrur","Sonipat","Panchkula","Ambala","Panipat","Hisar","Sirsa","Rohtak","Bahadurgarh","Yamuna Nagar","Gurgaon","Faridabad","Rewari","","Indore","Jaipur","Jodhpur","Kota","Alwar","Udaipur","Bhiwadi","Ajmer","Ahmedabad","Gandhinagar","Vadodara","Surat"];
    let cityArray3 = ["Chennai","Vellore","Coimbatore","Hyderabad","Kozhikode","Cochin","Bangalore","Mysore"];
    let cityArray4 = ["Delhi"];
    cityArray1 = cityArray1.map(function(x){ return x.toLowerCase() });
    cityArray2 = cityArray2.map(function(x){ return x.toLowerCase() });
    cityArray3 = cityArray3.map(function(x){ return x.toLowerCase() });
    cityArray4 = cityArray4.map(function(x){ return x.toLowerCase() });
    let complaintCity = city.toLowerCase();
    let responsibleId= '';
    let responsibleTeam = '';
    let responsibleEmail = '';
    if(cityArray1.includes(complaintCity)){
        responsibleId = '+918448389958';
        responsibleTeam = 'UP';
        responsibleEmail = 'aayush.gupta@zunroof.com';
    }
    else if(cityArray2.includes(complaintCity)){
        responsibleId = '+918448389957';
        responsibleTeam = 'Punjab';
        responsibleEmail = 'saurabh.chauhan@zunroof.com';
    }
    else if(cityArray3.includes(complaintCity)){
        responsibleId = '+917827944128';
        responsibleTeam = 'Bangalore';
        responsibleEmail = 'aayush.gupta@zunroof.com';
    }
    else if(cityArray4.includes(complaintCity)){
        responsibleId = '+919205695692';
        responsibleTeam = 'Delhi';
        responsibleTeam = 'aayush.gupta@zunroof.com';
        //responsibleEmail = 'shubham.arora@zunroof.com';
    }
    else{
        responsibleId = '+918448389957';
        responsibleTeam = 'Punjab';
        responsibleEmail = 'saurabh.chauhan@zunroof.com';
    }
    let url = 'https://www.zunroof.com/server/sendEmail.php';
    //let emailCM = 'devteam@zunroof.com';
    let emailCM = 'delivery@zunroof.com';
    let subject = ticketId + " has been generated";
    let emailBody = `This is to bring to your attention that Quote Id ${quoteId} has registered a new complaint on the complaints portal. Please update the progress of Ticked Id on the complaints portal within the next 48 hours. Failure to do so, will lead to further escalation as per the escalation matrix shared below:<br><br>`;
    emailBody +=`
        <table>
            <tr>
                <td><24 Hours</td>
                <td>Responsible and Captain</td>
            </tr>
            <tr>
                <td>24-72 Hours</td>
                <td>Rishabh</td>
            </tr>
            <tr>
                <td>72 - 120 Hours</td>
                <td>Kamlendra</td>
            </tr>
            <tr>
                <td>120 Hours +</td>
                <td>Pranesh</td>
            </tr>
        </table>`;
        let jsonRequest = {
            "senderEmail": "communications@zunroof.in",
            "emailList": [emailCM, responsibleEmail],
            "subject": subject,
            "body": emailBody,
            "replyTo": "devteam@zunroof.com"
        };
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(jsonRequest), // data can be `string` or {object}!
                mode: 'cors',
                // headers: {
                //     'Content-Type': 'application/json'
                // }
            }).then(res => res.json())
                .then(response => {
                    console.log('Success:', JSON.stringify(response))
                    resolve(JSON.stringify(response))
            })
                .catch(error => {console.error('Error:', error)
                reject(error);
                });
        })
}


function sendEmailCustomer(userEmail, ticketId){
    let url = 'https://www.zunroof.com/server/sendEmail.php';
    let subject = "Ticket Id:" + ticketId + " has been generated";
    let emailBody = "Dear Customer,<br><br>";
    emailBody+="Greetings from ZunRoof !<br>";
    emailBody += `Thank you for reaching out to us. Resolving your complaint is of utmost importance for us. Our Customer support team will attempt to reach you within the next 48 hours and share an update on the issue highlighted by you as soon as possible.<br>You can view the status of your complaint at <a href="www.zunroof.com/support">www.zunroof.com/support</a>.<br><br>`;
    emailBody += `Please install ZunRoof APP for latest updates <a href="https://vcc7b.app.goo.gl/zunroof">https://vcc7b.app.goo.gl/zunroof</a>.<br><br>`;
    emailBody += "Regards<br>ZunRoof";
    let jsonRequest = {
        "senderEmail": "communications@zunroof.in",
        "emailList": [userEmail],
        "subject": subject,
        "body": emailBody,
        "replyTo": "devteam@zunroof.com"
    };
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(jsonRequest), // data can be `string` or {object}!
            mode: 'cors',
            // headers: {
            //     'Content-Type': 'application/json'
            // }
        }).then(res => res.json())
            .then(response => {
                console.log('Success:', JSON.stringify(response))
                resolve(JSON.stringify(response))
        })
            .catch(error => {console.error('Error:', error)
            reject(error);
            });
    })
}