import { SET_VENDOR_FORM_INIT_SUCCESS, SET_VENDOR_FORM_INIT_ERROR, SET_TRANSACTION_FORM_SUCCESS, SET_TRANSACTION_FORM_ERROR, FETCH_REFERENCE_LIST_SUCCESS, FETCH_INVOICE_DATA_SUCCESS } from './actiontypes';
import { push, set } from '../helper/firebaseFunctions';
import axios from 'axios';
import { db } from '../connection';
import { access, purchaseFormAccess, sellFormAccess } from '../helper/vendorHelper';
let inventoryFields = {
	"panels":["brand", "make"],
	"inverter": ["brand", "inverterSize", "phase"],
	"ACDB": ["city", "make"],
	"DCDB": ["city", "make"]
};

export function requestNewPayment({ user, formObject, currentSelected }) {
	return (dispatch) => {
		console.log(formObject);
		if(panelFormCheck({user, currentSelected,formObject})) {
			formObject["responsibleAccount"] = user.phoneNumber;
			push('paymentsModule/transactionCompleteModule', formObject)
			.then(value => {
				dispatch({type:SET_VENDOR_FORM_INIT_SUCCESS, payload:"SuccessFully Submitted"});
				setTimeout(function(){
					dispatch({type:SET_VENDOR_FORM_INIT_SUCCESS, payload:null})
				},2000)
			})
			.catch(error => {
				dispatch({type:SET_VENDOR_FORM_INIT_ERROR, payload:"Oops! Something went wrong"});
				setTimeout(function(){
					dispatch({type:SET_VENDOR_FORM_INIT_ERROR, payload:null})
				},2000)
			});
		}else{
			console.log("CHECK");
			dispatch({type:SET_VENDOR_FORM_INIT_ERROR, payload:"Please check all the fields"});
			setTimeout(function(){
				dispatch({type:SET_VENDOR_FORM_INIT_ERROR, payload:null})
			},2000)
		}
	}
}

export function requestNewTransaction({ user, formObject, currentSelected, type, referenceData }) {
	return (dispatch) => {
		createNewTransaction({ user, formObject, currentSelected, type, referenceData }).then(data => {
			dispatch(data[0]);
			setTimeout(function(){
				dispatch(data[1]);
			},2000);
		})
	}
}

async function createNewTransaction({ user, formObject, currentSelected, type, referenceData }){
	try{
		if(panelFormCheck({user, currentSelected, formObject, type})) {
			if(currentSelected == "v2" && formObject["paymentType"] == "p3" && !formObject["acdbDcdb"]) {
				return([{type:SET_VENDOR_FORM_INIT_ERROR, payload:"Please update ACDB/DCDB variable in Quote id view."}, {type:SET_VENDOR_FORM_INIT_ERROR, payload:null}]);
			}
			switch(currentSelected) {
				case "panels":
					formObject.reference = `${formObject.quoteId}__Panels`;
					break;
				case "inverter":
					formObject.reference = `${formObject.quoteId}__Inverter`;
					break;
				case "structure":
					formObject.reference = `${formObject.quoteId}__${formObject.material}`;
					break;
				case "v2":
					formObject.reference = `${formObject.quoteId}__${formObject.vendor}__${formObject.payment}`;
					break;
				case "v1":
					formObject.reference = `${formObject.quoteId}__${formObject.epc}__${formObject.payment}`;
					break;
				case "other":
					formObject.reference = `${formObject.quoteId}__${formObject.transactionType}`;
					break;
				default:
					break;
			}
			if(type){
				formObject.type = type;
			}
			if(type == 'Sell'){
				console.log("Sell");
				let stockPresent = await checkInventory(formObject, currentSelected, referenceData);
				if(stockPresent){
					formObject.price = -1 * parseFloat(formObject.price);
					await push('paymentsModule/requestModule', formObject);
					return([{type:SET_VENDOR_FORM_INIT_SUCCESS, payload:"SuccessFully Submitted"}, {type:SET_VENDOR_FORM_INIT_SUCCESS, payload:null}]);
				}
				else{
					return([{type:SET_VENDOR_FORM_INIT_ERROR, payload:"Not Enough Stock !!!"}, {type:SET_VENDOR_FORM_INIT_ERROR, payload:null}]);
				}
			}
			else{
				await push('paymentsModule/requestModule', formObject);
				return([{type:SET_VENDOR_FORM_INIT_SUCCESS, payload:"SuccessFully Submitted"}, {type:SET_VENDOR_FORM_INIT_SUCCESS, payload:null}]);
			}
		} else {
			return([{type:SET_VENDOR_FORM_INIT_ERROR, payload:"Please check all the fields"}, {type:SET_VENDOR_FORM_INIT_ERROR, payload:null}]);
		}
	}
	catch(e){
		return([{type:SET_VENDOR_FORM_INIT_ERROR, payload:"Oops! Something went wrong"}, {type:SET_VENDOR_FORM_INIT_ERROR, payload:null}]);
	}
}

export function approvePayment({ user, id, value, dataReference, data }) {
	return (dispatch) => {
		set(`paymentsModule/requestList/${id}/status`,value)
		.then(val => {
			console.log(val);
			if((data.type == 'Purchase' || data.type=='Sell') && value == 2){
				updateInventory(data, data.transactionType, data.type);
			}
			if (value == 2) {
				if (data.transactionType == "panels") {
					triggerWhatsapp(data.quoteId, data.name, "panelInTransit").then();
				} else if (data.transactionType == "inverter") {
					triggerWhatsapp(data.quoteId, data.name, "inverterInTransit").then();
				}
			}
			let newData = {...dataReference, [id]:data};
			dispatch({ type: FETCH_REFERENCE_LIST_SUCCESS, payload: newData });
		})
		.catch(error => {console.log(error)});
	}
}

export function approveRejectSeletedPayments({ selectedTransactions, dataReference, value }) {
	return async (dispatch) => {
		try {
			dispatch({type:SET_VENDOR_FORM_INIT_SUCCESS, payload:"Processing. Please wait..."});
			let newData = JSON.parse(JSON.stringify(dataReference));
			await Promise.all(Object.keys(selectedTransactions).map(async requestKey => {
				let paymentData = dataReference[requestKey];
				await db.ref(`paymentsModule/requestList/${requestKey}/status`).set(value);
				// console.log(`paymentsModule/requestList/${requestKey}/status`, value);
				if((paymentData.type == 'Purchase' || paymentData.type=='Sell') && value == 2){
					updateInventory(paymentData, paymentData.transactionType, paymentData.type);
				}
				if (value == 2) {
					if (paymentData.transactionType == "panels") {
						triggerWhatsapp(paymentData.quoteId, paymentData.name, "panelInTransit").then();
					} else if (paymentData.transactionType == "inverter") {
						triggerWhatsapp(paymentData.quoteId, paymentData.name, "inverterInTransit").then();
					}
				}
				newData[requestKey].status = value;
			}))
			dispatch({ type: FETCH_REFERENCE_LIST_SUCCESS, payload: newData });
			dispatch({type:SET_VENDOR_FORM_INIT_SUCCESS, payload:"Success!!"});
			setTimeout(function(){
				dispatch({type:SET_VENDOR_FORM_INIT_SUCCESS, payload:null})
			},2000);
		}
		catch(e) {
			console.log("Error occured", e);
			dispatch({type:SET_VENDOR_FORM_INIT_SUCCESS, payload:null});
			dispatch({type:SET_VENDOR_FORM_INIT_ERROR, payload:"Oops! Something went wrong"});
			setTimeout(function(){
				dispatch({type:SET_VENDOR_FORM_INIT_ERROR, payload:null})
			},2000)
			return;
		}
	}
}

export function updateInvoiceData({ id, value, invoiceData }){
	return (dispatch) => {
		set(`paymentsModule/invoices/${id}/invoiceStatus`,value)
		.then(val => {
			console.log(val);
			let invoicesData = { ...invoiceData };
			invoicesData[id] = {
				invoiceStatus :value
			}
			dispatch({ type:FETCH_INVOICE_DATA_SUCCESS, payload:invoicesData })
		})
		.catch(error => {console.log(error)});
	}
}

export function updateInvoiceApprovalData({ id, value, invoiceData }){
	return (dispatch) => {
		set(`paymentsModule/invoices/${id}/approvalStatus`,value)
		.then(val => {
			console.log(val);
			let invoicesData = { ...invoiceData };
			invoicesData[id].approvalStatus = value;
			dispatch({ type:FETCH_INVOICE_DATA_SUCCESS, payload:invoicesData })
		})
		.catch(error => {console.log(error)});
	}
}

function panelFormCheck({user, formObject, currentSelected, type}) {
	let status = true;
	let accessMap = {};
	switch(type){
		case 'Purchase':
			accessMap = purchaseFormAccess[currentSelected];
			break;
		case 'Sell':
			accessMap = sellFormAccess[currentSelected];
			break;
		default:
			accessMap = access[currentSelected];
			break;
	}
	console.log(accessMap);
	console.log(formObject);
	Object.keys(accessMap).forEach(key => {
		if(accessMap[key].compulsary[user.level] && formObject[key.split('_')[0]] === undefined){
			status = false;
		}
	});
	return status;
}

export function uploadInvoiceFile({ requestKey, file, userId, invoiceData }){
	return (dispatch) => {
		const formData = new FormData();
        formData.append("file", file);
        formData.append("requestKey", requestKey);
        formData.append("userId", userId);
        axios.post("https://operations.zunroof.com/api/vendorportalinvoice/upload", formData)
		.then(content => {
			console.log("response: ",content);
			let invoicesData = { ...invoiceData };
			invoicesData[requestKey] = {
				invoiceStatus: 2
			}
			dispatch({ type:FETCH_INVOICE_DATA_SUCCESS, payload:invoicesData })
		})
	}
}

export function downloadInvoice({ requestKey }){
	return (dispatch) => {
		const formData = new FormData();
        formData.append("requestKey", requestKey);
        axios.post("https://operations.zunroof.com/api/vendorportalinvoice/getdownloadurl", formData)
		.then(content => {
			console.log("response: ",content);
			let response = content.data;
			if(response){
				downloadFile(response.url, response.name)
			}
		})
	}
}


async function downloadFile(url, name){
	try{
		let fileResponse = await axios({ url: url, method: 'GET', responseType: 'blob' })
		console.log(fileResponse);
		const downloadUrl = window.URL.createObjectURL(new Blob([fileResponse.data]));
		const link = document.createElement('a');
		link.href = downloadUrl;

		link.setAttribute('download', name);
		document.body.appendChild(link);
		link.click();
	}
	catch(e){
		console.log(e);
	}
}

async function updateInventory(data, currentSelected, type){
	try{
		let vendor = (type == 'Purchase')?data.vendor:data.purchaseVendor;
		let vendorProducts = (await db.ref(`paymentsModule/inventory/${currentSelected}/${vendor}`).once('value')).val();
		let productId = '';
		let value = (type == 'Purchase')?1:-1;
		Object.keys(vendorProducts?vendorProducts:{}).map(id => {
			let product = vendorProducts[id];
			if(inventoryFields[currentSelected].reduce((boolVal, field) => boolVal&&(product[field]==data[field]), true)){
				console.log("present");
				productId = id;
			}
			else{
				console.log("Not present");
			}
			// if(product.brand == data.brand && product.make == data.make){
			// 	productId = id;
			// }
		})
		if(!productId){
			productId = getRandomDateGetKey();
			let productData = {
				productId:productId,
				count:0,
				vendor:vendor
			};
			inventoryFields[currentSelected].map(field => {
				productData[field] = data[field];
			})
			await db.ref(`paymentsModule/inventory/${currentSelected}/${vendor}/${productId}`).set(productData);
		}
		let updateData;
		let count = parseInt(data.count);
		if(type == 'Purchase'){
			updateData = await db.ref(`paymentsModule/inventory/${currentSelected}/${vendor}/${productId}/count`).transaction(function(currentCount) {
				return currentCount+=count;
			});
		}
		else{
			updateData = await db.ref(`paymentsModule/inventory/${currentSelected}/${vendor}/${productId}/count`).transaction(function(currentCount) {
				return currentCount-=count;
			});
		}
		console.log("Inventory Update", updateData);
		return 'Inventory Updated';
	}
	catch(e){
		console.log("Inventory Update Error", e)
		return e;
	}
}

async function checkInventory(data, currentSelected, referenceData){
	try{
		let pendingCount = 0, totalCount = 0;
		let status = false;
		let inventoryData = (await db.ref(`paymentsModule/inventory/${currentSelected}/${data.purchaseVendor}`).once('value')).val();
		if(inventoryData){
			Object.values(inventoryData?inventoryData:{}).map(val => {
				if(inventoryFields[currentSelected].reduce((boolVal, field) => boolVal&&(val[field]==data[field]), true)){
					totalCount = val.count;
				}
			})
		}
		Object.values(referenceData?referenceData:{}).map(val => {
			if(val.type == 'Sell' && [0, 1, 6].includes(val.status) && val.purchaseVendor == data.purchaseVendor && val.transactionType == currentSelected ){
				if(inventoryFields[currentSelected].reduce((boolVal, field) => boolVal&&(val[field]==data[field]), true)){
					pendingCount += parseInt(val.count?val.count:1);
				}
			}
		})
		let count = totalCount - pendingCount;
		console.log("counts", count, pendingCount, totalCount);
		if(count >= data.count){
			return true;
		}
		else{
			return false;
		}
	}
	catch(e){
		console.log(e);
		return false;
	}
}

function getRandomDateGetKey() {
	let currentEpoch = new Date().getTime();
	let randomDigit = Math.floor(100000 + Math.random() * 900000);
	return `${currentEpoch}${randomDigit}`;
}

const triggerWhatsapp = async (quoteId, customerName, trigger) => {
	try {
		let siteId = (await db.ref(`/quotestaken/${quoteId}/siteId`).once("value")).val();
		let {mSite_OwnerId : customerId} = (await db.ref(`/sites/${siteId}`).once("value")).val();
		let stageVal = (await db.ref(`/delivery/sites/${siteId}/stage`).once("value")).val() || [];
		stageVal = stageVal.pop();
		let stage = stageVal.current_State;
		if (["7", "9", "10"].includes(stage)) {
			return;
		}
		let requestData = {
				key : "4VhfN1xiAD",
				phoneNumber : customerId,
				trigger : trigger,
				quoteId : quoteId,
				customerName : customerName
		}
		requestData = new URLSearchParams(requestData);
		let data = await axios.post("https://zuntalks.in:3000/whatsapp/trigger", requestData, {
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
		});
		return;
	}
	catch(e) {
		console.log("triggerWhatsapp error", e);
		return;
	}
}
