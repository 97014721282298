export function dateChanger(dateString){
	let dateValue = dateString.split("-");
	let date = new Date(dateValue[2]+"-"+dateValue[1]+"-"+dateValue[0]).getTime();
	return date;
}

export function changeToViewDate(dateep) {
	let dateobj = new Date(dateep);
	function pad(n) {return n < 10 ? "0"+n : n;}
    let month = dateobj.toLocaleString("en-us", { month: "short" });
	let changeDate = pad(dateobj.getDate())+" "+month+", "+dateobj.getFullYear();
	return changeDate;
}


export function changeToInputDate(dateep) {
	let date = new Date(dateep);
	let changeDate = date.getFullYear()+ "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + (date.getDate())).slice(-2);
	return changeDate;
}

export function changeToViewDateTime(dateep) {
	function pad(n) {return n < 10 ? "0"+n : n;}
	let dateobj = new Date(dateep);
    let month 		= dateobj.toLocaleString("en-us", { month: "short" });
	let changeDate 	= pad(dateobj.getDate())+" "+month+" "+dateobj.getFullYear();
	let time 		= new Date(dateobj).toLocaleTimeString();
	let timeView	= `${time.split(":")[0]}:${time.split(":")[1]}:${time.split(":")[2]}`
	return `${changeDate} ${timeView}`;
}


export function dateToMonth(dateep) {
	let date = new Date(dateep);
	let changeDate = date.getFullYear()*100 + date.getMonth() + 1;
	return changeDate;
}

export function inputDateConverter(dateep) {
	let date = new Date(dateep);
	let changeDate = (date.getMonth()+1) + "/" + date.getDate() + "/" + date.getFullYear();
	return changeDate;
}

export function inputDateFormat(dateep) {
	let date = new Date(dateep?dateep:new Date());
	let changeDate = date.getFullYear() + "-" + ("0"+(date.getMonth()+1)).slice(-2) + "-" + ("0"+date.getDate()).slice(-2);
	return changeDate;
}

export function inputDateFormatChange(dateep) {
	if(dateep){
		let date = new Date(dateep?dateep:new Date());
		let changeDate = date.getFullYear() + "-" + ("0"+(date.getMonth()+1)).slice(-2) + "-" + ("0"+date.getDate()).slice(-2);
		return changeDate;
	}
	else{
		return dateep;
	}
}

export function changeTimeDifferenceToDays(dateep){
	if(dateep){
		let currTime = new Date().getTime();
		let timeSincePaymentCompleted = currTime - dateep;
		let days = Math.round(parseFloat(timeSincePaymentCompleted / (1000 * 3600 * 24)));
		return days;
	}else {
		return dateep
	}
}

export function numberConverter(number) {
	if(number) {
		let  x=number.toString();
		let afterPoint = '';
		if(x.indexOf('.') > 0)
		   afterPoint = x.substring(x.indexOf('.'),x.length);
		x = Math.floor(x);
		x=x.toString();
		let lastThree = x.substring(x.length-3);
		let otherNumbers = x.substring(0,x.length-3);
		if(otherNumbers !== '')
		    lastThree = ',' + lastThree;
		let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;

		return res;
	}
}