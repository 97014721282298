import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { Restrict } from '../components/Restrict';
import { generateInvoice, downloadInvoice, uploadCsvFile } from '../actions/generateInvoiceActions';

class GenerateInvoice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            invoiceData: [{
                products: [{}],
                invoiceFile: ''
            }]
        };
    }

    componentWillReceiveProps(props) {
        let { invoiceData = [{products: [{}]}], updateInvoiceData = false } = props;
        console.log(updateInvoiceData);
        if(updateInvoiceData) {
            this.setState({ invoiceData });
        }
    }

    InvoiceInput = ({ label, name, type, index }) => {
        let { invoiceData = [] } = this.state;
        let rowData = invoiceData[index] || {};
        let value = rowData[name] || "";
        return (
            <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '1%' }}>
                <div className="form-group">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">{label}</span>
                        </div>
                        <input className="form-control customInput" type={type} name={name} placeholder={label} value={value} onChange={(e) => this.handleInvoiceInputChange(e, index)} required={name != "subsidyDescription"} />
                    </div>
                </div>
            </div>
        )
    }

    InvoiceAddressInput = ({ label, name, type, index, addressType }) => {
        let { invoiceData = [] } = this.state;
        let rowData = invoiceData[index] || {};
        let addressData = rowData[addressType] || {}
        let value = addressData[name] || "";
        return (
            <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '1%' }}>
                <div className="form-group">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">{label}</span>
                        </div>
                        <input className="form-control customInput" type={type} name={name} placeholder={label} value={value} onChange={(e) => this.handleInvoiceAddressInputChange(e, addressType, index)} required />
                    </div>
                </div>
            </div>
        )
    }

    InvoiceProductInput = ({ label, name, type, index, productIndex }) => {
        let { invoiceData = [] } = this.state;
        let rowData = invoiceData[index] || {};
        let products = rowData["products"] || [];
        let value = (products[productIndex] || {})[name] || "";
        return (
            <div className="col" style={{ width: '100%', padding: '0%' }}>
                <div className="form-group">
                    <div className="input-group mb-3">
                        <input className="form-control customInput" type={type} name={name} placeholder={label} value={value} onChange={(e) => this.handleInvoiceProductInputChange(e, productIndex, index)} required={name != "description"} />
                    </div>
                </div>
            </div>
        )
    }

    handleAddInvoice = () => {
        let { invoiceData = [] } = this.state;
        invoiceData = JSON.parse(JSON.stringify(invoiceData));
        invoiceData.push({
            products: [{}]
        });
        this.setState({
            invoiceData
        })
    }

    handleAddProduct = (index) => {
        let { invoiceData = [] } = this.state;
        invoiceData = JSON.parse(JSON.stringify(invoiceData));
        invoiceData[index] = invoiceData[index] || {};
        invoiceData[index].products = invoiceData[index].products || [];
        invoiceData[index].products.push({});
        this.setState({
            invoiceData
        })
    }

    handleInvoiceInputChange = (e, index) => {
        let { name, value } = e.target;
        let { invoiceData = [] } = this.state;
        invoiceData = JSON.parse(JSON.stringify(invoiceData));
        invoiceData[index][name] = value;
        this.setState({
            invoiceData
        })
    }

    handleInvoiceAddressInputChange = (e, addressType, index) => {
        let { name, value } = e.target;
        let { invoiceData = [] } = this.state;
        invoiceData = JSON.parse(JSON.stringify(invoiceData));
        invoiceData[index][addressType] = invoiceData[index][addressType] || {}
        invoiceData[index][addressType][name] = value;
        this.setState({
            invoiceData
        })
    }

    handleInvoiceProductInputChange = (e, productIndex, index) => {
        let { name, value } = e.target;
        let { invoiceData = [] } = this.state;
        invoiceData = JSON.parse(JSON.stringify(invoiceData));
        invoiceData[index]["products"][productIndex][name] = value;
        this.setState({
            invoiceData
        })
    }

    handleSubmitInvoiceForm = (e) => {
        e.preventDefault();
        let { invoiceData = [] } = this.state;
        this.props.generateInvoice(invoiceData);
    }

    handleDownloadAll = (e) => {
        this.props.downloadInvoice(this.props.result);
    }

    handleDownloadClick = (index) => {
        this.props.downloadInvoice([this.props.result[index]]);
    }

    handleInvoiceFileUpload = (e) => {
        let file = e.target.files[0];
        this.setState({
            invoiceFile: file
        })
    }

    handleInvoiceFileSubmit = () => {
        let { invoiceFile } = this.state;
        if(!invoiceFile) {
            return;
        }
        // if(invoiceFile.type != 'text/csv') {
        //     alert("Invalid file type. Please upload a csv file.");
        //     return;
        // }
        this.props.uploadCsvFile(invoiceFile);
    }

    handleDeleteClick = (index) => {
        let { invoiceData = [] } = this.state;
        invoiceData = JSON.parse(JSON.stringify(invoiceData));
        invoiceData.splice(index, 1);
        this.setState({ invoiceData });
    }

    handleDeleteProduct = (index) => {
        let { invoiceData } = this.state;
        if(invoiceData[index]["products"].length <= 1) {
            return;
        }
        invoiceData = JSON.parse(JSON.stringify(invoiceData));
        invoiceData[index].products.splice(invoiceData[index].products.length - 1, 1);
        this.setState({ invoiceData });
    }

    render() {

        if (this.props.user === null) {
            return <Redirect to={"/login?redirect_uri=generate-invoice"} />;
        }

        if (this.props.user && this.props.user.level < 2500 && this.props.user.level != 1800) {
            return (
                <div>
                    <Restrict />
                </div>
            )
        }

        let { InvoiceInput, InvoiceAddressInput, InvoiceProductInput, handleAddInvoice, handleAddProduct, state, handleSubmitInvoiceForm, handleDownloadAll, handleDownloadClick, handleInvoiceFileUpload, handleInvoiceFileSubmit, handleDeleteClick, handleDeleteProduct } = this;
        let { invoiceData = [] } = state;

        return (
            <div style={{ "marginTop": 50 }} className="container-fluid">
                <div style={{ textAlign: "center" }}>
                    <h2 style={{ fontWeight: "bold" }}>Generate Invoice</h2>
                </div>
                <form onSubmit={handleSubmitInvoiceForm}>
                    <div style={{ width: '90%', margin: '0 auto' }}>
                        {this.props.status != 2 ? <button type="button" className="btn btn-outline-info btn-lg" style={{ float: 'left' }} onClick={handleAddInvoice}>Add More</button> : ""}
                        {this.props.status != 2 ? <button type="submit" className="btn btn-outline-success btn-lg" style={{ float: 'right' }} disabled={this.props.status != 0}>Submit</button> : ""}
                        {this.props.status == 2 ? <button type="button" className="btn btn-outline-success btn-lg" style={{ float: 'right' }} onClick={handleDownloadAll}>Download All</button> : ""}
                    </div>
                    <div style={{ clear: 'both' }}></div>
                    { this.props.status != 2 ? <div className="mt-3" style={{ width: '90%', margin: '0 auto' }}><div className="input-group mb-3" style={{width: '25%'}}>
                        <div className="input-group-prepend">
                            <span className="input-group-text">Upload file</span>
                        </div>
                        <input type="file" accept=".csv" className="form-control" name="invoiceFile" onChange={handleInvoiceFileUpload} />
                        <div className="input-group-append">
                            <button type="button" className="btn btn-outline-success btn-lg" onClick={handleInvoiceFileSubmit}>Submit</button>
                        </div>
                    </div></div> : "" }
                    {
                        invoiceData.map((value, index) => <div key={`Invoice_${index}`} style={{ border: '1px solid #8ACB95', width: '90%', margin: '40px auto 0 auto', padding: '2% 3%' }}>
                            <div className="row">
                                <InvoiceInput
                                    label="Quote Id"
                                    name="quoteId"
                                    type="text"
                                    index={index}
                                />
                                <InvoiceInput
                                    label="Date"
                                    name="date"
                                    type="date"
                                    index={index}
                                />
                                <InvoiceInput
                                    label="Invoice Number"
                                    name="invoiceNumber"
                                    type="text"
                                    index={index}
                                />
                                <InvoiceInput
                                    label="Place of supply"
                                    name="placeOfSupply"
                                    type="text"
                                    index={index}
                                />
                            </div>
                            <div style={{ fontSize: '20px' }}>Customer Details</div>
                            <div className="row">
                                <InvoiceAddressInput
                                    label="Name"
                                    name="name"
                                    type="text"
                                    addressType={"customerDetails"}
                                    index={index}
                                />
                                <InvoiceAddressInput
                                    label="Address"
                                    name="address"
                                    type="text"
                                    addressType={"customerDetails"}
                                    index={index}
                                />
                                <InvoiceAddressInput
                                    label="State Code"
                                    name="stateCode"
                                    type="number"
                                    addressType={"customerDetails"}
                                    index={index}
                                />
                            </div>
                            <div style={{ fontSize: '20px' }}>Shipping Details</div>
                            <div className="row">
                                <InvoiceAddressInput
                                    label="Name"
                                    name="name"
                                    type="text"
                                    addressType={"shippingDetails"}
                                    index={index}
                                />
                                <InvoiceAddressInput
                                    label="Address"
                                    name="address"
                                    type="text"
                                    addressType={"shippingDetails"}
                                    index={index}
                                />
                                <InvoiceAddressInput
                                    label="State Code"
                                    name="stateCode"
                                    type="number"
                                    addressType={"shippingDetails"}
                                    index={index}
                                />
                            </div>
                            <div>
                                <span style={{ fontSize: '20px' }}>Products &nbsp;</span>
                                {this.props.status != 2 ? <span><button type="button" className="btn btn-outline-info" onClick={() => handleAddProduct(index)}>
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                </button></span>:""} &nbsp;
                                {this.props.status != 2 ? <span><button type="button" className="btn btn-outline-danger" onClick={() => handleDeleteProduct(index)}>
                                    <i className="fa fa-minus" aria-hidden="true"></i>
                                </button></span>:""}
                            </div>
                            <div>
                                <div className="row" style={{ fontSize: '15px', textAlign: 'center' }}>
                                    <div className="col" style={{ paddingTop: '1%', border: '1px solid #ccc' }}>
                                        HSN
                            </div>
                                    <div className="col" style={{ paddingTop: '1%', border: '1px solid #ccc' }}>
                                        Product
                            </div>
                                    <div className="col" style={{ paddingTop: '1%', border: '1px solid #ccc' }}>
                                        Description
                            </div>
                                    <div className="col" style={{ paddingTop: '1%', border: '1px solid #ccc' }}>
                                        Quantity
                            </div>
                                    <div className="col" style={{ paddingTop: '1%', border: '1px solid #ccc' }}>
                                        Rate
                            </div>
                                    <div className="col" style={{ paddingTop: '1%', border: '1px solid #ccc' }}>
                                        Tax
                            </div>
                                </div>

                                {
                                    (value.products || []).map((productValue, productIndex) => <div key={`Product_${index}_${productIndex}`} className="row" style={{ fontSize: '15px', textAlign: 'center' }}>
                                        <InvoiceProductInput
                                            label="HSN/SAC"
                                            name="hsn"
                                            type="text"
                                            index={index}
                                            productIndex={productIndex}
                                        />

                                        <InvoiceProductInput
                                            label="Product"
                                            name="product"
                                            type="text"
                                            index={index}
                                            productIndex={productIndex}
                                        />
                                        <InvoiceProductInput
                                            label="Description"
                                            name="description"
                                            type="text"
                                            index={index}
                                            productIndex={productIndex}
                                        />
                                        <InvoiceProductInput
                                            label="Qty"
                                            name="quantity"
                                            type="number"
                                            index={index}
                                            productIndex={productIndex}
                                        />

                                        <InvoiceProductInput
                                            label="Rate"
                                            name="rate"
                                            type="number"
                                            index={index}
                                            productIndex={productIndex}
                                        />

                                        <InvoiceProductInput
                                            label="Tax"
                                            name="tax"
                                            type="number"
                                            index={index}
                                            productIndex={productIndex}
                                        />
                                    </div>)
                                }

                            </div>

                            <div className="row">
                                <InvoiceInput
                                    label="Subsidy"
                                    name="subsidy"
                                    type="number"
                                    index={index}
                                />
                                <InvoiceInput
                                    label="Discount"
                                    name="discount"
                                    type="number"
                                    index={index}
                                />
                                <InvoiceInput
                                    label="Shipping"
                                    name="shipping"
                                    type="number"
                                    index={index}
                                />
                                <InvoiceInput
                                    label="Subsidy Description"
                                    name="subsidyDescription"
                                    type="text"
                                    index={index}
                                />
                            </div>

                            {this.props.result[index] ? <div style={{ marginBottom: '15px' }}><button type="button" className="btn btn-outline-success btn-lg" style={{ float: 'right' }} onClick={() => handleDownloadClick(index)}>Download</button></div> : index > 0 ? <div style={{ marginBottom: '15px' }}><button type="button" className="btn btn-outline-danger btn-lg" style={{ float: 'right' }} onClick={() => handleDeleteClick(index)}>Delete</button></div>:""}

                        </div>)
                    }
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    let { status = 0, result = [], invoiceData = [{products:{}}], updateInvoiceData = false } = state.generateInvoiceStatus || {};
    return {
        user: state.user,
        status,
        result,
        invoiceData,
        updateInvoiceData
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ generateInvoice, downloadInvoice, uploadCsvFile }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateInvoice);