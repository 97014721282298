import { base } from '../connection';

export function setuserinfo(siteId,currentUser,user,lastEmail,newEmail) {
	console.log(new Date());
	return (dispatch) => {
		pushUserInfo(siteId,currentUser,user,lastEmail,newEmail);
	}
}


function pushUserInfo(siteId,currentUser,user,lastEmail,newEmail) {
	base.post('users/'+user+'/mUser_Contact/mContact_Email', {
	    data: newEmail
	    ,then(err){
	      if(!err){
	      	console.log("SUCCESS");
	      	addComment(siteId,currentUser,user,lastEmail,newEmail);
	      }else{
	      	console.log(err);
	      }
	    }
	  });
}

function addComment(siteId,currentUser,user,lastEmail,newEmail) {
	getUserObject(currentUser, (err,userObj) => {
	 	if(err){
	 		console.log(err);
	 	}else{
	 		base.fetch('/delivery/sites/'+siteId, {})
			.then(data => {
				let commentsArray =[];
				if(data.comments){
				 commentsArray = [...[],...data.comments];
				let lastValue = JSON.parse(JSON.stringify(commentsArray[commentsArray.length-1]));
				lastValue.commentId=""+commentsArray.length;
				lastValue.comment='New Email:' +newEmail+'\n\nOld Email:'+lastEmail;
				lastValue.commentDate=createDateObj();
				lastValue.employeeId = user;
				lastValue.employeeName=userObj.mContact_FirstName+' '+userObj.mContact_LastName;
				commentsArray.push(lastValue);
				pushCommentUponStageChange(siteId,commentsArray);
				} else {
					var finalCommentArray = {
						comment: 'New Email:' +newEmail+'\nOld Email:'+lastEmail,
						commentDate:createDateObj(),
						commentId:""+0,
						employeeId:""+user,
						employeeName:userObj.mContact_FirstName+' '+userObj.mContact_LastName
					}
					commentsArray.push(finalCommentArray);
					pushCommentUponStageChange(siteId,commentsArray);
				}
			})
	 	}
	 });
}

function pushCommentUponStageChange(siteId,data) {
	base.post('/delivery/sites/'+siteId+'/comments', {
	    data: data	
	    ,then(err){
	      if(!err){
	      	console.log("SUCCESS")
	      }else{
	      	console.log(err);
	      }
	    }
	  });
}


function getUserObject(user,cb){
   base.fetch('/users/'+user+'/mUser_Contact',{})
   .then(data=>{
	   return cb(null,data);
   })
   .catch(error=>{
	   return cb(error);
   })
}

function createDateObj() {
	let dateVal = new Date();
	let dateObj = {
		date:dateVal.getDate(),
		day:dateVal.getDay(),
		hours:dateVal.getHours(),
		minutes:dateVal.getMinutes(),
		month:dateVal.getMonth()+1,
		seconds:dateVal.getSeconds(),
		time:dateVal.getTime(),
		timezoneOffset:dateVal.getTimezoneOffset(),
		year:dateVal.getFullYear()
	}
	return dateObj;
}