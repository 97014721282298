import {  ADDING_COMMENT_STATUS } from './actiontypes';

import { base, db } from '../connection';
import axios from 'axios';

import { createDateObj } from '../helper/dateObj';
import { getNameById, sendEmail} from '../helper/helper';
import { getCommentProcessRequest } from '../helper/addComments';

export function addOrUpdate(siteId,elevationDetails,deliveredPanel,deliveredInverter,phase_D, sanctioned_Load_D,state_D,subsidy_D,subsidyStatus,netMeteringStage,subsidyAppliedDate,promisedPanel,promisedInverter,address_D,latlon_D,connectionHolderName,user,invoiceText,invoiceDate,size,discom_D,specialRequirement_D,idMovedBy_D,maintenanceRequired,GST_B,BillingAddress_B,City_B,State_B,connectionHolderName_B, oldSubsidyStatus, oldNetMeteringStage, email, quoteId, tenderYear, size_del, ebIssueResolutionDate, loanResolutionDate, subsidyApprovalDate, netMeteringAppliedDate, ebIssueAppliedDate, inverterCourierPartner, trackingId) {
	return (dispatch) => {
        console.log("RECIVED");
				if (subsidyStatus == "Applied" && subsidyStatus != oldSubsidyStatus) {
                    triggerWhatsapp(siteId, "subsidyApplied").then();
                    const emailSubject = 'Update: Subsidy application'
                    const emailMessage = `
                    <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="utf-8">
                                <meta name="viewport" content="width=device-width, initial-scale=1">
                            </head>
                            <body>
                                <div>
                                    Dear Sir,
                                    <br>
                                    Greetings from Team Zunroof!
                                    <br><br>
                                    This is an update mail with regards to your solar rooftop project. We would like to inform you that we have successfully applied subsidy for your Solar Rooftop project. We will accordingly be moving ahead with delivery of materials and installations subject to clearance of your payments
                                    <br><br>
                                    Warm Regards,
                                    <br>
                                    ZunRoof Team
                                    <br>
                                    www.zunroof.com,
                                    <br>
                                    www.facebook.com/zunroof
                                    <br><br>
                                    Support: +919319432702
                                    <br>
                                    Get your ZunRoof app: https://play.google.com/store/apps/details?id=com.zunroofprod.zunroofprod&hl=en
                                </div>
                            </body>
                        </html>
                    `
                    let emailList = [email,'delivery@zunroof.com']
                    sendEmail(emailSubject, emailMessage, emailList).then((response) => {
                        if(response){
                            console.log('Email Sent For Subsidy Applied')
                        }
                    })

                }
				if (netMeteringStage == "Applied" && netMeteringStage != oldNetMeteringStage) {
                    triggerWhatsapp(siteId, "netMeterApplied").then();
                    const emailSubject = 'Update: Net Meter application'
                    const emailMessage = `
                    <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="utf-8">
                                <meta name="viewport" content="width=device-width, initial-scale=1">
                            </head>
                            <body>
                                <div>
                                    Dear Sir,
                                    <br>
                                    Greetings from Team Zunroof!
                                    <br><br>
                                    This is an update mail with regards to your solar rooftop project. We would like to inform you that we have     successfully applied net metering for your Solar Rooftop project. We will accordingly be moving ahead with delivery of materials and installations subject to clearance of your payments
                                    <br><br>
                                    Warm Regards,
                                    <br>
                                    ZunRoof Team
                                    <br>
                                    www.zunroof.com,
                                    <br>
                                    www.facebook.com/zunroof
                                    <br><br>
                                    Support: +919319432702
                                    <br>
                                    Get your ZunRoof app: https://play.google.com/store/apps/details?id=com.zunroofprod.zunroofprod&hl=en
                                </div>
                            </body>
                        </html>
                    `
                    let emailList = [email,'delivery@zunroof.com']
                    sendEmail(emailSubject, emailMessage, emailList).then((respose) => {
                        if(respose){
                            console.log('Email Sent For Net Meter Applied')
                        }
                    })
				}
		addElevationAndDeliveredDetailsComments(dispatch,siteId,elevationDetails,deliveredPanel,deliveredInverter,phase_D, sanctioned_Load_D,state_D,subsidy_D,subsidyStatus,netMeteringStage,subsidyAppliedDate,promisedPanel,promisedInverter,address_D,latlon_D,connectionHolderName,user,invoiceText,invoiceDate,size,discom_D,specialRequirement_D,idMovedBy_D,maintenanceRequired,GST_B,BillingAddress_B,City_B,State_B,connectionHolderName_B, tenderYear, size_del, ebIssueResolutionDate, loanResolutionDate, subsidyApprovalDate, netMeteringAppliedDate, ebIssueAppliedDate, inverterCourierPartner, trackingId);
	}
}


function addElevationAndDeliveredDetails(dispatch,siteId,elevationDetails,deliveredPanel,deliveredInverter,phase_D, sanctioned_Load_D,state_D,subsidy_D,subsidyStatus,netMeteringStage,subsidyAppliedDate,promisedPanel,promisedInverter,address_D,latlon_D,connectionHolderName,user,invoiceText,invoiceDate,size,discom_D,specialRequirement_D,idMovedBy_D,maintenanceRequired,GST_B,BillingAddress_B,City_B,State_B,connectionHolderName_B, tenderYear, size_del, ebIssueResolutionDate, loanResolutionDate, subsidyApprovalDate, netMeteringAppliedDate, ebIssueAppliedDate, inverterCourierPartner, trackingId){
    base.update('/delivery/webPortal/sites/'+siteId, {
      data: {elevationDetails: elevationDetails?elevationDetails:'',netMeteringStage:netMeteringStage || "Not Applied", deliveredPanel: deliveredPanel?deliveredPanel:'',deliveredInverter:deliveredInverter?deliveredInverter:'',phase_D:phase_D?phase_D:'',sanctioned_Load_D:sanctioned_Load_D?sanctioned_Load_D:'',state_D:state_D?state_D:'',subsidy_D:subsidy_D?subsidy_D:'',subsidyStatus:{status:subsidyStatus?subsidyStatus:'',appliedDate:subsidyAppliedDate?createDateObj(subsidyAppliedDate):''},promisedPanel:promisedPanel,promisedInverter:promisedInverter,address_D:address_D,latlon_D:latlon_D,connectionHolderName: connectionHolderName?connectionHolderName:'',invoiceText,invoiceDate,size_D:size,discom_D,specialRequirement_D,idMovedBy_D,maintenanceRequired,GST_B,BillingAddress_B,City_B,State_B,connectionHolderName_B, tenderYear, size_del, ebIssueResolutionDate: ebIssueResolutionDate || "", loanResolutionDate: loanResolutionDate || "", subsidyApprovalDate: subsidyApprovalDate || "", netMeteringAppliedDate: netMeteringAppliedDate || "", ebIssueAppliedDate: ebIssueAppliedDate || "", inverterCourierPartner: inverterCourierPartner || "", trackingId: trackingId || ""},
      then(err){
        if(!err){
        }else{
            console.log(err);
        }
      }
    });
}

function addElevationAndDeliveredDetailsComments(dispatch,siteId,elevationDetails,deliveredPanel,deliveredInverter,phase_D, sanctioned_Load_D,state_D,subsidy_D,subsidyStatus,netMeteringStage,subsidyAppliedDate,promisedPanel,promisedInverter,address_D,latlon_D,connectionHolderName,user,invoiceText,invoiceDate,size,discom_D,specialRequirement_D,idMovedBy_D,maintenanceRequired,GST_B,BillingAddress_B,City_B,State_B,connectionHolderName_B, tenderYear, size_del, ebIssueResolutionDate, loanResolutionDate, subsidyApprovalDate, netMeteringAppliedDate, ebIssueAppliedDate, inverterCourierPartner, trackingId){
    console.log("ADDING");
    base.fetch('/delivery/webPortal/sites/'+siteId, {})
    .then(data => {
        if(data.address_D !== address_D) {
            let processCheck = {
                siteId:siteId,
                user:user,
                nameVar:"Address",
                newChangeValue:address_D,
                oldChangeValue:data.address_D,
            }
            dispatch({type:ADDING_COMMENT_STATUS,payload:true})
            getCommentProcessRequest(processCheck,dispatch);
        }
        if(data.latlon_D !== latlon_D) {
            let processCheck = {
                siteId:siteId,
                user:user,
                nameVar:"Lat-Lon",
                newChangeValue:latlon_D,
                oldChangeValue:data.latlon_D,
            }
            dispatch({type:ADDING_COMMENT_STATUS,payload:true})
            getCommentProcessRequest(processCheck,dispatch);
        }
        if((data.subsidyStatus || {}).status !== subsidyStatus) {
            let processCheck = {
                siteId:siteId,
                user:user,
                nameVar:"Subsidy Status",
                newChangeValue:subsidyStatus,
                oldChangeValue:(data.subsidyStatus || {}).status,
            }
            dispatch({type:ADDING_COMMENT_STATUS,payload:true})
            getCommentProcessRequest(processCheck,dispatch);
        }
        addElevationAndDeliveredDetails(dispatch,siteId,elevationDetails,deliveredPanel,deliveredInverter,phase_D, sanctioned_Load_D,state_D,subsidy_D,subsidyStatus,netMeteringStage,subsidyAppliedDate,promisedPanel,promisedInverter,address_D,latlon_D,connectionHolderName,user,invoiceText,invoiceDate,size,discom_D,specialRequirement_D,idMovedBy_D,maintenanceRequired,GST_B,BillingAddress_B,City_B,State_B,connectionHolderName_B, tenderYear, size_del, ebIssueResolutionDate, loanResolutionDate, subsidyApprovalDate, netMeteringAppliedDate, ebIssueAppliedDate, inverterCourierPartner, trackingId);
    })
    .catch(error => {

    })
}


export function updateStructureVendor(siteId, vendorId){
    return (dispatch) => {
        console.log("structure action", siteId, vendorId);
        if(siteId && vendorId){
            db.ref(`delivery/webPortal/sites/${siteId}/structureVendor`).set(vendorId)
            .then(val => {
                console.log("Structure Vendor Added");
            })
            .catch(e => {
                console.log(e);
            })
        }
    }
}

export function updateMppVerificationStatus(siteId, status, oldStatus, user){
    return async (dispatch) => {
        if(!siteId){
            return;
        }
        await db.ref(`delivery/webPortal/sites/${siteId}/mppVerificationStatus`).set(status);
        addComment(siteId, status, oldStatus, user)
    }
}


async function addComment(siteId, status, oldStatus, user){
	try{
		let comments = (await db.ref(`delivery/sites/${siteId}/comments`).once('value')).val()||[];
		let userObj = (await db.ref(`users/${user}/mUser_Contact`).once('value')).val() || {};
		let { mContact_FirstName, mContact_LastName } = userObj;
		let userName = `${mContact_FirstName||""} ${mContact_LastName||""}`;
		let commentObj = createDateObj(new Date().getTime());
		let newComment = {
			commentId : comments.length + "",
			comment : 'New Mpp Verification Status:' +status+'\nOld Mpp Verification Status:'+oldStatus,
			commentDate: commentObj,
			employeeId: user,
			employeeName: userName
		}
		let updatedComments = [ ...comments, newComment ];
		await db.ref(`delivery/sites/${siteId}/comments`).set(updatedComments);
	}
	catch(e){
		console.log("error", e)
	}
}

export function updateFeasiblityStatus(siteId, status, email, oldFeasibility, quoteId){
    return async (dispatch) => {
        if(!siteId || !status || !email)  return;
        await db.ref(`delivery/webPortal/sites/${siteId}/feasibility`).set(status)
        if(status == 'Applied' && status != oldFeasibility){
            console.log('sending Mail')
            const emailSubject = 'Update: Feasibility application'
            const emailMessage = `
                <!DOCTYPE html>
                    <html lang="en">
                        <head>
                            <meta charset="utf-8">
                            <meta name="viewport" content="width=device-width, initial-scale=1">
                        </head>
                        <body>
                            <div>
                                Dear Sir,
                                <br>
                                Greetings from Team Zunroof!
                                <br><br>
                                This is an update mail with regards to your solar rooftop project. We would like to inform you that we have successfully applied feasibility for your Solar Rooftop project. We will accordingly be moving ahead with delivery of materials and installations subject to clearance of your payments                            <br><br>
                                Warm Regards,
                                <br>
                                ZunRoof Team
                                <br>
                                www.zunroof.com,
                                <br>
                                www.facebook.com/zunroof
                                <br><br>
                                Support: +919319432702
                                <br>
                                Get your ZunRoof app: https://play.google.com/store/apps/details?id=com.zunroofprod.zunroofprod&hl=en
                            </div>
                        </body>
                    </html>
            `
            let emailList = [email,'delivery@zunroof.com']
            sendEmail(emailSubject, emailMessage, emailList).then((respose) => {
                if(respose){
                    console.log('Email Sent For Feasibility Applied')
                }
            })
        }
        return;
    }
}

const triggerWhatsapp = async (siteId, trigger) => {
	let {mSite_QuoteId : quoteId, mSite_OwnerId : customerId} = (await db.ref(`/sites/${siteId}`).once("value")).val();
	let customerName = await getNameById(customerId);
	let requestData = {
			key : "4VhfN1xiAD",
			phoneNumber : customerId,
			trigger : trigger,
			quoteId : quoteId,
			customerName : customerName
	}
	requestData = new URLSearchParams(requestData);
	let data = await axios.post("https://zuntalks.in:3000/whatsapp/trigger", requestData, {
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
	});
}

export function updateAcdbDcdbVariable(siteId, value){
    return (dispatch) => {
        console.log("updateAcdbDcdbVariable action", siteId, value);
        if(siteId){
            db.ref(`delivery/webPortal/sites/${siteId}/acdbDcdb`).set(value)
            .then(val => {
                console.log("Structure Vendor Added");
            })
            .catch(e => {
                console.log(e);
            })
        }
    }
}

export function updateCustomerVideoUrlVariable(siteId, value){
    return (dispatch) => {
        console.log("updateCustomerVideoUrlVariable action", siteId, value);
        if(siteId){
            db.ref(`delivery/webPortal/sites/${siteId}/customerVideoUrl`).set(value)
            .then(val => {
                console.log("customerVideoUrl updated");
            })
            .catch(e => {
                console.log(e);
            })
        }
    }
}

export function updateQualityVisitStatus(siteId, value){
    return (dispatch) => {
        console.log("updateQualityVisitStatus action", siteId, value);
        if(siteId){
            db.ref(`delivery/webPortal/sites/${siteId}/qualityVisit`).set(value)
            .then(val => {
                console.log("Structure Vendor Added");
            })
            .catch(e => {
                console.log(e);
            })
        }
    }
}

export function updatePPAVariable(siteId, value){
    return (dispatch) => {
        console.log("updatePPAVariable action", siteId, value);
        if(siteId){
            db.ref(`delivery/webPortal/sites/${siteId}/ppa`).set(value)
            .then(val => {
                console.log("PPA Variable Added");
            })
            .catch(e => {
                console.log(e);
            })
        }
    }
}