import React, { Component } from 'react';
import ReactTable from "react-table";
import Modal from 'react-responsive-modal';
import { Link } from 'react-router-dom';
import { columnsFMS } from '../config/column_fields';
import { styleSheet } from '../styles/styleSheet';

import { changeToViewDate } from '../helper/dateChanger';

export class FrameSix extends Component {

	constructor(props) {
	  super(props);

	  this.state = {
		clientinfoindex:{},
		openfileviewer:false,
		log:[],
		rejectreason:"",
	  	show:true,
	  	data:[],
	  	columns:columnsFMS,
	  	text:"",
	  	user:"",
	  	show:true,
	  	open:false,
	  	file:null,
		blob:null,
		logopen:false,
	  	column:columnsFMS,
	  	folderTypeName:"",
	  	fileTypeName:"",
	  	fileArray: {
	  		"Client Info":[],
	  		"Design":[],
	  		"Payment & Warranty":[],
	  		"Sales Info":[],
				"Client Feedback":[],
				"Net Metering":[],
				"Subsidy Status":[],
				"FlexUpload": []
	  	},
		clientInfoDocs:[],
	  	uploadFile: {
	  		uploadStarted:[],
			uploadSuccess:[],
			uploadError:[],
			},
			sendEmailLoading:0,
			openSendEmail:0,
			openQualityRejectModal : false
	  };
	}

	componentDidMount() {
		console.log(this.props.fmsData);
		console.log("QUOTE DETAILS", this.props.quoteDetails);
		let columns 	= [...[],...columnsFMS];
		let dataFMS = this.props.fmsData;
		let data 	= [];
		let typeVersionMap = {};
		console.log(this.props.fileType);
		console.log("File", this.props.fileType);
		console.log("datFMS", this.props.fmsData);
		columns.push(
			{
				Header : 'Download',
				accessor : 'id',
				Cell: row => (<div><button onClick={() => {this.handleOnDownloadClick(row.value)}}>Download</button></div>)
			}
		);

		let fileArray = {
			"Client Info":[],
	  		"Design":[],
	  		"Payment & Warranty":[],
	  		"Sales Info":[],
				"Client Feedback":[],
				"Net Metering":[],
				"Subsidy Status":[],
				"FlexUpload": []
		};
		let clientInfoDocs=this.props.docs;
		Object.keys(dataFMS).forEach(id => {
			if (true) {

			}
			if (this.props.fileType[""+dataFMS[""+id].folderType]){
				let  {  createdOn, versionName } = dataFMS[""+id].metaData;
				let type = this.props.fileType[""+dataFMS[""+id].folderType][""+dataFMS[""+id].type].name;
				let folderType = dataFMS[""+id].folderType;

				if(typeVersionMap.hasOwnProperty(dataFMS[""+id].type)) {
					typeVersionMap[""+dataFMS[""+id].type] += 1;
				}else{
					typeVersionMap[""+dataFMS[""+id].type] = 0;
				}
				let folderTyepName = dataFMS[""+id].folderType;
				// if (dataFMS[""+id].folderType == "Net Metering" || dataFMS[""+id].folderType == "Subsidy Status") {
				// 	folderTyepName = "Payment & Warranty";
				// }
				if (fileArray.hasOwnProperty(folderTyepName)) {
					fileArray[folderTyepName].push({
						type: dataFMS[""+id].type,
						date: dataFMS[""+id].metaData.createdOn,
						name: dataFMS[""+id].name,
						verified: dataFMS[""+id].verified,
						actionTime:dataFMS[""+id].actionTime,
						id:   id
					})
				}

				data.push({
					id,
					createdOn,
					name: dataFMS[""+id].name,
					type,
					folderType,
					versionName
				})
			}
		});

		this.setState({
			columns,
			data,
			typeVersionMap,
			fileArray,
			clientInfoDocs
		},() => {
			console.log(this.state,'12345')
		})
		let userid=this.props.quoteDetails.quoteid;
		this.props.openlog(userid);
	}

	componentWillReceiveProps(props) {
		let { uploadFile, emailLayout ,log} = props;
		if(emailLayout){
			console.log('Email Layout Received');
			console.log(emailLayout);
			this.setState({
				openSendEmail:0
			})
		}
		if(uploadFile) {
			console.log(`UPLOAD FILE : ${uploadFile}`);
			this.setState({
				uploadFile:uploadFile
			})
		}
		if(log){
			this.setState({
				log
			})
		}
	}

	handleOnClickNotifications = () => {
		this.setState({
			show: !this.state.show
		})
	}

	handleOnChange = (e) => {
		this.setState({
			[e.target.name] : e.target.value
		})
	}
	setindex=(name,index)=>{
		this.setState({clientinfoindex:{name,index}})
	}
	openlog=()=>{
		let userid=this.props.quoteDetails.quoteid;
		console.log(userid)
		this.props.openlog(userid);
		this.setState({
			logopen:true,

		})

	}
	verify=(quoteid ,reqid,userid,name)=>{
		this.props.verify(quoteid,reqid,userid,name,this.state.rejectreason);
		let clientInfoDocs= this.state.clientInfoDocs.map((doc)=>{
			if(doc.name!=name)return doc;
			else return {name:name, status:2, key:doc.key }
		})
		this.setState({
			clientInfoDocs,
			openfileviewer:false,
			rejectreason:""
		})
	}
	reject=(quoteid ,reqid,userid,name)=>{
		if(this.state.rejectreason==="")
		alert("please fill the reject reason")
		else{
			this.props.reject(quoteid,reqid,userid,name, this.state.rejectreason);
			let clientInfoDocs= this.state.clientInfoDocs.map((doc)=>{
				if(doc.name!=name)return doc;
				else return {name:name, status:0, key:doc.key }
			})
			this.setState({
				clientInfoDocs,
				openfileviewer:false,
				rejectreason:""
			})
		}
	}

	handleOnUpload = (e) => {
		let file = e.target.files[0];
		console.log(file);
		const formData = new FormData();
		formData.append('data',file);
		console.log("FORM DATA : ",formData);
		var blob = new Blob([file], { type: file.type, name:new Date().getTime() });
		if (file.size > 25000000) {
			console.log("Size :", file.size);
			this.setState({
				uploadFile: {...this.state.uploadFile, uploadError:["File Size can not exceed 25 MB"]}
			})
		}
		else {
			console.log(blob,file);
			// this.props.fileUpload(blob,file.name);
			// this.props.fileDownload();
			this.setState({
				blob,
				file
			});
		}
	}

	handleOnDownloadClick = (requestId) => {
		console.log(this.props);
		console.log(this.state);
		let userId 	= this.props.user.phoneNumber;
		let quoteId = this.props.quoteDetails.quoteid;
		let fileName= this.props.fmsData[""+requestId].name;
		let downloadObj = {userId, quoteId, requestId, fileName };
		console.log(downloadObj);
		this.props.fileDownload(downloadObj);
	}

	handleUploadClick = () => {
		let userId 		= this.props.user.phoneNumber;
		let quoteId 	= this.props.quoteDetails.quoteid;
		let name 		= this.state.file?this.state.file.name:"";
		let indexFound  = {...{},...this.state.typeVersionMap};
		let indexPresent= 0;
		let folderType 	= this.state.folderTypeName;
		let type 	    = this.state.fileTypeName;
		let file    	= this.state.blob;
		indexFound[""+type] = (indexFound[""+type]||indexFound[""+type]==0?indexFound[""+type]:-1) + 1;
		console.log(this.state.typeVersionMap,indexFound);
		let uploadObj = { userId, quoteId, name, version:indexFound[""+type], type, folderType, file };
		console.log(uploadObj);
		if(file && file.size >= 1024 && file.size <= 1024*1024*20 && folderType && folderType!="Choose..." && type && type!="Choose..."){
			this.props.fileUpload(uploadObj);
			this.setState({
				typeVersionMap:indexFound
			})
		}else{
			let error = [];
			if(!folderType || folderType=="Choose..." ) {
				error.push("Please select a type");
			}else if(!type || type=="Choose..." ) {
				error.push("Please select a sub-type");
			}else if(!file || file.size < 1024 || file.size > 1024*1024*20) {
				error.push("File Size is not in range. It should not be more than 20MB");
			}
			this.setState({
				uploadFile: {...this.state.uploadFile, uploadError:error}
			})
			console.log("SIZE NOT IN RANGE");
		}
	}

	handleSendEmailClick = (key, requestId) => {
		console.log('clicked');
		console.log(this.props);
		console.log(this.state);
		let userId 	= this.props.user.phoneNumber;
		let quoteId = this.props.quoteDetails.quoteid;
		let fileName= this.props.fmsData[""+requestId].name;
		let fileObj = {userId, quoteId, requestId, fileName };
		console.log(fileObj);
		console.log(key);
		console.log(this.state.fileArray["Design"][key]);
		let fileArrayNew = Object.assign({}, this.state.fileArray);
		fileArrayNew["Design"][key].verified = 1;
		//this.props.fileDownload(downloadObj);
		this.props.sendEmailLayout(fileObj,this.props.quoteDetails.name,this.props.quoteDetails.email,this.props.quoteDetails.responsibleEmail, this.props.quoteDetails.responsibleDeliveryNumber);
		this.setState({
			fileArray :fileArrayNew,
			openSendEmail:1
		});
	}

	handleQualityReportApprove = (requestId) => {
		let {fileArray} = this.state;
		fileArray["Design"].forEach(val => {
			if (val.id == requestId) {
				val.verified = 2;
			}
		})
		this.setState({
			fileArray : fileArray
		})
		let requestData = {
			quoteId : this.props.quoteDetails.quoteid,
			requestId : requestId,
			status : 2,
			userId : this.props.user.phoneNumber
		}
		this.props.setInvoiceVerificationStatus(requestData);
	}

	handleQualityReportReject = (requestId) => {
		console.log(this.props);
		console.log(this.state);
		let userId 	= this.props.user.phoneNumber;
		let quoteId = this.props.quoteDetails.quoteid;
		let fileName= this.props.fmsData[""+requestId].name;
		let downloadObj = {userId, quoteId, requestId, fileName };
		console.log(downloadObj);
		this.setState({
			openQualityRejectModal : true,
			clientinfoindex : downloadObj
		})
	}

	handleQualityReportRejectReason = (requestId) => {
		let {fileArray, rejectreason} = this.state;
		if (rejectreason) {
			fileArray["Design"].forEach(val => {
				if (val.id == requestId) {
					val.verified = 3;
				}
			})
			this.setState({
				fileArray : fileArray
			})
			let userId = this.props.user.phoneNumber;
			let requestData = {
				quoteId : this.props.quoteDetails.quoteid,
				requestId : requestId,
				status : 3,
				userId : userId
			}
			this.props.setInvoiceVerificationStatus(requestData);
			this.props.setquotedata(this.props.siteid, rejectreason, userId);
		}
		this.onCloseQualityRejectModal();
	}

	onOpenModal = () => {
		this.setState({ open: true });
	}

	onCloseModal = () => {
		this.setState({ open: false });
	}

	onCloseQualityRejectModal = () => {
		this.setState({ openQualityRejectModal: false, rejectreason : "" });
	}

	hoverOn = () => {
      this.setState({ hover: true });
    }

    hoverOff = () => {
      this.setState({ hover: false });
	}
	onclickverify=(quoteid ,reqid, phoneNumber, name)=>{
		// this.handleOnDownloadClick(reqid);
		this.setState({
			openfileviewer:true,
			clientinfoindex:{name,reqid}
		})
	}
	onCloseModal2=()=>{
		this.setState({logopen:false})
	}
	closefileviewer=()=>{
		this.setState({openfileviewer:false})
	}

	handleSendFile = (id, type, fileName) => {
		console.log("SEND EMAIL CLICKED", id);
		let { quoteid, email, responsibleDeliveryNumber, name } = this.props.quoteDetails;
		this.props.sendUploadedFileEmail(quoteid, id, type, email, fileName, responsibleDeliveryNumber, name);
		alert("Sending Email");
	}

	handleInvoiceStatusChange = (e, index, key) => {
		let { checked } = e.target;
		let { fileArray } = this.state;
		fileArray = JSON.parse(JSON.stringify(fileArray));
		fileArray["Payment & Warranty"][index].verified = checked?1:0;
		this.setState({
			fileArray
		})
		let quoteId = this.props.quoteDetails.quoteid;
		let userId 	= this.props.user.phoneNumber;
		let requestData = {
			quoteId,
			requestId: key,
			status: (checked?1:0),
			userId
		}
		this.props.setInvoiceVerificationStatus(requestData);
	}

	render() {
		console.log(this.state.folderTypeName);
		if(this.state.sendEmailLoading){
		return (
			<div>
        <i className="fa fa-spinner fa-spin" /> Loading...
      </div>
		);}
		else{
		return(
			<div className="row row_data" style={{marginBottom:2}}>
				<div className="col-12">
					<div className="page-title">
					<Modal open={this.state.logopen} onClose={this.onCloseModal2} center>
								<div className="container">
								<table className="table table-striped table-bordered" style={{textAlign:"center"}}>
									<thead className="text-primary" style={{fontSize:"24px"}}>
										<tr>
											<th>Docs</th>
											<th colSpan="2"  >Uploaded</th>
											<th colSpan="2"> Verified</th>
											<th>Comments</th>
										</tr>
									</thead>
									<tbody>
								{
									this.state.log.map((log1)=>{
										return (

											<tr className= {log1["rejected"]===0?"table-success":"table-danger"}>
												<th>{this.props.fileType["Client Info"][log1["typename"]].name}</th>
												<th>{log1["uploadtime"]}</th>
												<th>{log1["uploadedby"]}</th>
												<th>{log1["verifiedat"]}</th>
												<th>{log1["verifiedby"]}</th>
												<th>{log1["comments"]}</th>
											</tr>
										)
									})
								}
								</tbody>
								</table>
								</div>
						</Modal>
						<Modal open={this.state.openfileviewer} onClose={this.closefileviewer}>
							<div className="container">
							<button className="btn btn-outline-success btn-lg" onClick={()=>this.verify(this.props.quoteDetails.quoteid ,this.state.clientinfoindex.reqid, this.props.user.phoneNumber, this.state.clientinfoindex.name)}>Verify </button>
							<button className="btn btn-outline-danger btn-lg" onClick={()=>this.reject(this.props.quoteDetails.quoteid ,this.state.clientinfoindex.reqid, this.props.user.phoneNumber, this.state.clientinfoindex.name)}>Reject</button>

						<br/><br/>
							<div className="input-group">
								<div className="input-group-prepend">
								<div className="input-group-text" id="btnGroupAddon">Comments</div>
								</div>
								<input type="text" name="rejectreason" onChange={this.handleOnChange} value={this.state.rejectreason} height="100px" className="form-control" placeholder="Comments" aria-label="Input group example" aria-describedby="btnGroupAddon"/>
							</div>
							</div>

						</Modal>
						<Modal open={this.state.open} onClose={this.onCloseModal} center>
							<div className="container">
								{this.state.uploadFile.uploadStarted.map(val => <div class="alert alert-primary" role="alert">Upload Started for {val}</div>)}
								{this.state.uploadFile.uploadSuccess.map(val => <div class="alert alert-success" role="alert">{val} Successfully Uploaded</div>)}
								{this.state.uploadFile.uploadError.map(val => <div class="alert alert-danger" role="alert">Error : {val}</div>)}
							</div>
							<div style={styleSheet.commentForm} className="form-group">
								<div className="container" style={{marginTop:'40px'}}>
									<div style={{textAlign:'center','marginBottom':'10px'}}><h3>Upload</h3></div>
									<div class="input-group mb-3">
										<div class="input-group-prepend">
											<span class="input-group-text" id="basic-addon1">Type</span>
										</div>
										<select class="custom-select" id="inputGroupSelect01" name="folderTypeName" onChange={this.handleOnChange}>
											<option selected>Choose...</option>
											{
												Object.keys(this.props.fileType).map((type) => <option value={type}>{type}</option>)
											}
										</select>
									</div>
									<div class="input-group mb-3">
										<div class="input-group-prepend">
											<span class="input-group-text" id="basic-addon1">Sub Type</span>
										</div>
										<select class="custom-select" id="inputGroupSelect01" name="fileTypeName" onChange={this.handleOnChange}>
											<option selected>Choose...</option>
											{
												this.state.folderTypeName && this.state.folderTypeName!="Choose..."?Object.keys(this.props.fileType[""+this.state.folderTypeName]).map((type) => <option value={type}>{this.props.fileType[""+this.state.folderTypeName][""+type].name}</option>):""
											}
										</select>
									</div>
									<div class="input-group mb-3">
										<input type="file"
											name="upload"
		                        			onChange={this.handleOnUpload} />
									</div>
									<div class="input-group mb-3" style={{textAlign:"center"}}>
										<button className="btn btn-success" onClick={this.handleUploadClick}>Upload</button>
									</div>
								</div>
							</div>
				        </Modal>
								<Modal open={this.state.openSendEmail} showCloseIcon={false} center>
							<div className="container">
								Sending Email...<br />
								Please do not refresh the page.
							</div>
				        </Modal>
								<Modal open={this.state.openQualityRejectModal} onClose={this.onCloseQualityRejectModal}>
									<div className="container">
										<div className="container">
											Reason for rejection is mandatory to reject the report
											<div className="input-group">
												<div className="input-group-prepend">
												<div className="input-group-text" id="btnGroupAddon">Reason for rejection</div>
												</div>
												<input type="text" name="rejectreason" onChange={this.handleOnChange} value={this.state.rejectreason} height="100px" className="form-control" placeholder="Comments" aria-label="Input group example" aria-describedby="btnGroupAddon"/><button className="btn btn-success" onClick={()=>{this.handleQualityReportRejectReason(this.state.clientinfoindex.requestId)}}>Save</button>
											</div>
										</div>
									</div>
								</Modal>
						<div style={styleSheet.titlePage}>
							<div style={styleSheet.titlePage.quote}>
								<span style={styleSheet.dropRightArrow} onClick={this.handleOnClickNotifications}><i className={this.state.show?"fas fa-arrow-down open_arrow":"fas fa-arrow-right open_arrow"}></i> Files</span>
							</div>
							<div style={styleSheet.titlePage.name}>
							</div>
							<div style={styleSheet.addComment}
								 onMouseEnter={this.hoverOn}
	            				 onMouseLeave={this.hoverOff}>
								<i style={this.state.hover?styleSheet.plusCircleOnHover:styleSheet.plusCircle} onClick={this.onOpenModal} className="fas fa-plus-circle"></i>
							</div>
						</div>
					</div>
				</div>
				<div style={this.state.show?styleSheet.show:styleSheet.hide} className="col-12">
					<div className="page_data page_data__background">
						<div className="row row_data">
							<div className="col-md page_data__3">
								<div className="row_head">
									<div className="row row_data">Sales Info</div>
								</div>
								{
									this.state.fileArray["Sales Info"].map(val => {
										return (<div className="row row_data" key={val.id}>
											<span className="row_data__key col-6">{this.props.fileType["Sales Info"][val.type].name}</span>
											<span className="row_data__key col-6"><Link to="#" onClick={() => {this.handleOnDownloadClick(val.id)}} >{changeToViewDate(isNaN(val.date)?0:+val.date)}</Link></span>
										</div>)
									})
								}
							</div>
							<div className="col-md page_data__2">
								<div className="row_head">







									<div className="row row_data" ><div style={{padding: "0 10px" }}>Client Info </div>  <button className="btn btn-primary btn-sm"  style={{display:"flex", float:"right"}}onClick={()=>this.openlog()} >Log</button> </div>

								</div>
								{console.log("Naman",this.state.clientInfoDocs)}
								<div className="row row_data" >
									<span className ="row_data__key col-4"> <strong>Doc name</strong></span>
									<span className="row_data__key col-4"> <strong>Uploaded </strong></span>
									<span className="row_data__key col-4"> <strong>Verified </strong></span>
									</div>
									{
										this.state.clientInfoDocs.map(doc=>{
											let status=doc.status;let name =doc.name;
											return (<div className="row row_data" >
											<span className="row_data__key col-4">
												{
													(status===0)?name:<Link to="#" onClick={() => this.handleOnDownloadClick(doc.reqid,name)} >{name}</Link>
												}
											</span>
											<span className="row_data__key col-4">
											{
												(status===0)?"...":<i style={styleSheet.plusCircle} className="fas fa-check"></i>
											}
											</span>
											<span className="row_data__key col-4">
													{
														(status<2)?((status==0)?"...":<button className="btn btn-success" onClick={()=>this.onclickverify(this.props.quoteDetails.quoteid ,doc.reqid, this.props.user.phoneNumber, name)}>Verify</button>):<i style={styleSheet.plusCircle} className="fas fa-check"></i>
													}
											</span>


											</div>
											)
										})
									}
							</div>
							<div className="col-md page_data__2">
								<div className="row_head" style={{"display":"flex","justifyContent": "space-between"}}>
									<div className="row row_data" style={{paddingLeft:"50%"}}>Design</div>
									<div style={styleSheet.titlePage.actions}>

									</div>
								</div>
								{
									this.state.fileArray["Design"].map((val,key) => {
										console.log(val.type);
										console.log('verified',val.verified);
										if(val.type=='type1'){
											if(!val.verified){
											return (<div className="row row_data" key={val.id}>
											<span className="row_data__key col-4">{this.props.fileType["Design"][val.type].name}</span>
											<span className="row_data__key col-4"><Link to="#" onClick={() => {this.handleOnDownloadClick(val.id)}} >{changeToViewDate(val.date)}</Link></span>
											<span className="row_data__key col-4"><button class="btn" onClick={()=>{this.handleSendEmailClick(key,val.id)}}>Send Email</button></span>
										</div>)
											}
											else{
											let status;
											if(val.verified == 1){
												status = 'Email Sent';
											}
											if(val.verified == 2){
												status = "Approved";
												if(val.actionTime){
													status += "\n("+changeToViewDate(val.actionTime)+")";
												}
											}
											if(val.verified == 3){
												status = 'Rejected';
											}
											return (<div className="row row_data" key={val.id}>
											<span className="row_data__key col-4">{this.props.fileType["Design"][val.type].name}</span>
											<span className="row_data__key col-4"><Link to="#" onClick={() => {this.handleOnDownloadClick(val.id)}} >{changeToViewDate(val.date)}</Link></span>
											<span className="row_data__key col-4" style={{whiteSpace:"pre-wrap"}}>{status}</span>
										</div>)
											}
										} else if (val.type == "type2" || val.type == "type3") {
											if(!val.verified){
												return (<div className="row row_data" key={val.id}>
												<span className="row_data__key col-4">{this.props.fileType["Design"][val.type].name}</span>
												<span className="row_data__key col-4"><Link to="#" onClick={() => {this.handleOnDownloadClick(val.id)}} >{changeToViewDate(val.date)}</Link></span>
												<span className="row_data__key col-4"><button class="btn btn-success" onClick={()=>{this.handleQualityReportApprove(val.id)}}>Approve</button><button class="btn btn-danger" onClick={()=>{this.handleQualityReportReject(val.id)}}>Reject</button></span>
												</div>)
											}
											else{
											let status;
											// if(val.verified == 1){
											// 	status = 'Email Sent';
											// }
											if(val.verified == 2){
												status = "Approved";
												if(val.actionTime){
													status += "\n("+changeToViewDate(val.actionTime)+")";
												}
											}
											if(val.verified == 3){
												status = 'Rejected';
											}
											return (<div className="row row_data" key={val.id}>
											<span className="row_data__key col-4">{this.props.fileType["Design"][val.type].name}</span>
											<span className="row_data__key col-4"><Link to="#" onClick={() => {this.handleOnDownloadClick(val.id)}} >{changeToViewDate(val.date)}</Link></span>
											<span className="row_data__key col-4" style={{whiteSpace:"pre-wrap"}}>{status}</span>
										</div>)
											}
										} else{
											return (<div className="row row_data" key={val.id}>
											<span className="row_data__key col-6">{this.props.fileType["Design"][val.type].name}</span>
											<span className="row_data__key col-6"><Link to="#" onClick={() => {this.handleOnDownloadClick(val.id)}} >{changeToViewDate(val.date)}</Link></span>
										</div>)
										}
									})
								}
							</div>
							<div className="col-md page_data__1">
								<div className="row_head" style={{"display":"flex","justifyContent": "space-between"}}>
									<div className="" style={{textAlign:'center',width:"100%"}}>Payment & Warranty</div>
								</div>
								{
									this.state.fileArray["Payment & Warranty"].map((val, index) => {
										console.log(val);
										return (<div className="row row_data" key={val.id}>
											<span className="row_data__key col-3">{this.props.fileType["Payment & Warranty"][val.type].name}</span>
											{val.type=="type1"?<span className="row_data__key col-1"><input type="checkbox" checked={val.verified == 1?true:false} onChange={(e) => this.handleInvoiceStatusChange(e, index, val.id)}/></span>:""}
											<span className="row_data__key col-4"><Link to="#" onClick={() => {this.handleOnDownloadClick(val.id)}} >{changeToViewDate(val.date)}</Link></span>
											{(val.type=='type6' || val.type=='type1')?(val.verified?"Email Sent":(this.props.user.level>=1700?<Link to="#" onClick={() => {this.handleSendFile(val.id, val.type, val.name)}} >{"Send Email"}</Link>:'')):''}
										</div>)
									})
								}<br/>
								<div className="row_head" style={{"display":"flex","justifyContent": "space-between"}}>
									<div className="" style={{textAlign:'center',width:"100%"}}>Subsidy Status</div>
								</div>
								{
									this.state.fileArray["Subsidy Status"].map(val => {
										return (<div className="row row_data" key={val.id}>
											<span className="row_data__key col-6">{this.props.fileType["Subsidy Status"][val.type].name}</span>
											<span className="row_data__key col-6"><Link to="#" onClick={() => {this.handleOnDownloadClick(val.id)}} >{changeToViewDate(val.date)}</Link></span>
										</div>)
									})
								}<br/>
							<div className="row_head" style={{"display":"flex","justifyContent": "space-between"}}>
								<div className="" style={{textAlign:'center',width:"100%"}}>Net Metering</div>
							</div>
							{
								this.state.fileArray["Net Metering"].map(val => {
									return (<div className="row row_data" key={val.id}>
										<span className="row_data__key col-6">{this.props.fileType["Net Metering"][val.type].name}</span>
										<span className="row_data__key col-6"><Link to="#" onClick={() => {this.handleOnDownloadClick(val.id)}} >{changeToViewDate(val.date)}</Link></span>
									</div>)
								})
							}<br/>
							</div>
							<div className="col-md page_data__2">
								<div className="row_head">
									<div className="row row_data">Client Feedback</div>
								</div>
								{
									this.state.fileArray["Client Feedback"].map(val => {
										return (<div className="row row_data" key={val.id}>
											<span className="row_data__key col-6">{this.props.fileType["Client Feedback"][val.type].name}</span>
											<span className="row_data__key col-6"><Link to="#" onClick={() => {this.handleOnDownloadClick(val.id)}} >{changeToViewDate(val.date)}</Link></span>
										</div>)
									})
								}
							</div>
							<div className="col-md page_data__2">
								<div className="row_head">
									<div className="row row_data">Flex</div>
								</div>
								{
									this.state.fileArray["FlexUpload"].map(val => {
										return (<div className="row row_data" key={val.id}>
											<span className="row_data__key col-6">{this.props.fileType["FlexUpload"][val.type].name}</span>
											<span className="row_data__key col-6"><Link to="#" onClick={() => {this.handleOnDownloadClick(val.id)}} >{changeToViewDate(val.date)}</Link></span>
										</div>)
									})
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		);}
	}
}
