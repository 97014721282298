import { LOGIN, LOGIN_CHECK, LOGOUT } from '../actions/actiontypes'

export default function(state = null, action) {
	switch(action.type){
		case LOGIN:
			return action.payload;
		case LOGIN_CHECK:
			return action.payload;
		case LOGOUT:
			return null;
		default:
			return state;
	}
}