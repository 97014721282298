import React, { Component } from 'react';
import ReactTable from "react-table";
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

import { styleSheet } from '../styles/styleSheet';

import { fetchsnap } from '../actions/fetchsnapdata';
// import { setTempAction } from '../actions/setTempAction';
import { logout} from '../actions/auth';
import { columnsComplete } from '../config/column_fields';
import { csvComplete } from '../config/csv_fields';

import { Restrict } from '../components/Restrict';

class AllData extends Component {

	constructor(props) {
	  super(props);
	
	  this.state = {
	  	loading:true,
	  	pageSize: 1,
		showPagination: false
	  };
	}

	componentWillMount() {
		if(this.props.user){
			console.log("USER PRESENT")
			this.props.fetchsnap();
		}
		// if(checkIfAllowed){
		// 	localStorage.removeItem("user_f");
		// 	this.props.logout();
		// 	this.props.user=null;
		// }
	}

	componentWillReceiveProps(props) {
		let { data, user } = props;
		if(data) {
			this.setState({loading:false})
		}
		if(user && user.level>=1900) {
			this.setState({
				pageSize: 20,
				showPagination: true
			})
		}
	}

	render() {
		if(this.props.user===null){
			return <Redirect to="/login?redirect_uri=all" />;
		}

		if(this.props.user.level<1600) {
			return ( 
				<div>
					<Restrict />
				</div>
			)
		}

		return (
			<div>
				<div>
					<div style={styleSheet.divStyle} className="input-group mb-3">
						<div>
							{
								this.props.user.level>=2500?<CSVLink filename={"Operations Data.csv"} data={this.props.data} headers={csvComplete} type="button" className="btn btn-info">
									Download
								</CSVLink>:""
							}
						</div>
					</div>
				</div>
				<div>
				<div style={styleSheet.tableName}> <h5>All Data</h5></div>
		  		<ReactTable
			  		loading={this.state.loading}
			  		pageSize={this.state.pageSize}
			  		filterable={true}
			  		showPagination={this.state.showPagination}
			  		sortable={true}
			  		data={this.props.data}
			  		columns={columnsComplete}
			  		defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase())>-1}/>
			  	</div>
			</div>
		)
	}
}


function mapStateToProps(state) {
	return {
		tableinfo : state.tableinfo,
		data: state.snapdata,
		user : state.user
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({fetchsnap, logout}, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(AllData); 
