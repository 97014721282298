import React from 'react';
import Select from 'react-select';
import ReactTable from "react-table";
import Modal from 'react-responsive-modal';
import { Link } from 'react-router-dom';
import { styleSheet } from '../styles/styleSheet';
import { changeToViewDate } from '../helper/dateChanger';


export const VendorAccessPageComponent = ({ user, handleFileChange, handleOnInputChange, name, email, address, pancard, gst, ifsc, accountNumber, bank, director, pocname, pocphone1, pocphone2, cashfreeId, companyType, companyTypeOptions, handleOnSelectChange, handleOnInputCheckBoxChange, handleFormSubmit, msmeOptions, msme }) => {
	return (
		<div className="container-fluid">
			<div className="vendorAddForm">
				<div style={{textAlign:"center"}}>
					<h3>Vendor Form</h3>
				</div>
				<form onSubmit={handleFormSubmit}>
					<div className="form-row">
						<InputCustom 
							label = {"Name*"}
							type = {"text"}
							name = {"name"}
							placeholder = {"Enter Name"}
							value = {name?name:""}
							handleOnChange = {handleOnInputChange}
						/>
						<InputCustom 
							label = {"Email"}
							type = {"text"}
							name = {"email"}
							placeholder = {"Enter Email"}
							value = {email?email:""}
							handleOnChange = {handleOnInputChange}
						/>
						<InputCustom 
							label = {"Address*"}
							type = {"text"}
							name = {"address"}
							placeholder = {"Enter Address"}
							value = {address?address:""}
							handleOnChange = {handleOnInputChange}
						/>
						<InputCustom 
							label = {"PAN*"}
							type = {"text"}
							name = {"pancard"}
							placeholder = {"Enter PAN"}
							value = {pancard?pancard:""}
							handleOnChange = {handleOnInputChange}
						/>
						<InputCustom 
							label = {"GST*"}
							type = {"text"}
							name = {"gst"}
							placeholder = {"Enter GST"}
							value = {gst?gst:""}
							handleOnChange = {handleOnInputChange}
						/>
						<SelectCustom 
							options = {companyTypeOptions}
							name = {"companyType"}
							placeholder = {"Select Company Type*"}
							handleOnChange = {handleOnSelectChange}
							value = {companyType}
						/>
						<InputCustom 
							label = {"IFSC*"}
							type = {"text"}
							name = {"ifsc"}
							placeholder = {"Enter IFSC"}
							value = {ifsc?ifsc:""}
							handleOnChange = {handleOnInputChange}
						/>
						<InputCustom 
							label = {"Acc. No.*"}
							type = {"text"}
							name = {"accountNumber"}
							placeholder = {"Enter Account Number"}
							value = {accountNumber?accountNumber:""}
							handleOnChange = {handleOnInputChange}
						/>
						<InputCustom 
							label = {"Bank Name*"}
							type = {"text"}
							name = {"bank"}
							placeholder = {"Enter Bank Name"}
							value = {bank?bank:""}
							handleOnChange = {handleOnInputChange}
						/>
						<InputCustom 
							label = {"Director*"}
							type = {"text"}
							name = {"director"}
							placeholder = {"Enter Director"}
							value = {director?director:""}
							handleOnChange = {handleOnInputChange}
						/>
						<InputCustom 
							label = {"POC Name*"}
							type = {"text"}
							name = {"pocname"}
							placeholder = {"Enter POC Name"}
							value = {pocname?pocname:""}
							handleOnChange = {handleOnInputChange}
						/>
						<InputCustom 
							label = {"POC Phone1*"}
							type = {"number"}
							name = {"pocphone1"}
							placeholder = {"Enter POC Phone1"}
							value = {pocphone1?pocphone1:""}
							handleOnChange = {handleOnInputChange}
						/>
						<InputCustom 
							label = {"POC Phone2*"}
							type = {"number"}
							name = {"pocphone2"}
							placeholder = {"Enter POC Phone2"}
							value = {pocphone2?pocphone2:""}
							handleOnChange = {handleOnInputChange}
						/>
						<InputCustom 
							label = {"Cashfree Id"}
							type = {"text"}
							name = {"cashfreeId"}
							placeholder = {"To be updated by accounts"}
							value = {cashfreeId?cashfreeId:""}
							handleOnChange = {handleOnInputChange}
						/>
						<SelectCustom 
							options = {msmeOptions}
							name = {"msme"}
							placeholder = {"Select MSME"}
							handleOnChange = {handleOnSelectChange}
							value = {msme}
						/>
						<div style={{width:"100%", padding:"10px 5px"}}>
							<h5>Permissions:</h5>
						</div>
						<InputCheckBoxCustom 
							label = {"V1"}
							type = {"number"}
							name = {"v1Permission"}
							placeholder = {"Enter V1"}
							handleOnChange = {handleOnInputCheckBoxChange}
						/>
						<InputCheckBoxCustom 
							label = {"V2"}
							type = {"number"}
							name = {"v2Permission"}
							placeholder = {"Enter V2"}
							handleOnChange = {handleOnInputCheckBoxChange}
						/>
						<InputCheckBoxCustom 
							label = {"Panel"}
							type = {"number"}
							name = {"panelPermission"}
							placeholder = {"Enter Panel"}
							handleOnChange = {handleOnInputCheckBoxChange}
						/>
						<InputCheckBoxCustom 
							label = {"Inverter"}
							type = {"number"}
							name = {"inverterPermission"}
							placeholder = {"Enter V2"}
							handleOnChange = {handleOnInputCheckBoxChange}
						/>
						<InputCheckBoxCustom 
							label = {"Structure"}
							type = {"number"}
							name = {"structurePermission"}
							placeholder = {"Enter Structure"}
							handleOnChange = {handleOnInputCheckBoxChange}
						/>
						<div className="files" style={{width:"100%", padding:"10px 5px"}}>
							<h5>Files:</h5>
						</div>
						<InputCustom 
							label = {"GST Certificate"}
							type = {"file"}
							name = {"gst"}
							placeholder = {"Upload gst"}
							handleOnChange = {(e) => handleFileChange(e, 0)}
						/>
						<InputCustom 
							label = {"PAN Card"}
							type = {"file"}
							name = {"panCard"}
							placeholder = {"Upload Pan Card"}
							handleOnChange = {(e) => handleFileChange(e, 1)}
						/>
						<InputCustom 
							label = {"Cancelled Cheque"}
							type = {"file"}
							name = {"cheque"}
							placeholder = {"Upload Canc. cheque"}
							handleOnChange = {(e) => handleFileChange(e, 2)}
						/>
						<InputCustom 
							label = {"V2 Agreement"}
							type = {"file"}
							name = {"v2Agg"}
							placeholder = {"Upload V2 Agreement"}
							handleOnChange = {(e) => handleFileChange(e, 3)}
						/>
						<div style={{width:"100%", padding:"10px 5px"}}>
							<h5>(*) - Mandatory Fields</h5>
						</div>
					</div>
					<div style={{}}>
						<button className="btn btn-success">
							Submit
						</button>
					</div>
				</form>
			</div>
		</div>
	)
}

const SelectCustom = ({options, name, placeholder, handleOnChange, value}) => {
	const changeHandle = (value) => { handleOnChange(value,name) }
	return (
		<div className="col-12 col-md-3">
			<div className="form-group">
				<Select
					isSearchable
					placeholder={placeholder}
					name={name}
					value={value}
					options={options}
					onChange= {changeHandle}
				/>
			</div>
		</div>
	)
}

const InputCustom = ({label, type, name, placeholder, value, handleOnChange}) => {
	return (
		<div className="col-12 col-md-3">
			<div className="form-group">
				<div className="input-group mb-3">
					<div className="input-group-prepend">
						<span className="input-group-text labelDefaultSize" id="basic-addon1">{label}</span>
					</div>
					<input className="form-control customInput" autoComplete="falseDone" type={type} name={name} placeholder={placeholder} value={value} onChange={handleOnChange}/>
				</div>
			</div>
		</div>
	)
}

const InputCheckBoxCustom = ({label, type, name, placeholder, handleOnChange}) => {
	return (
		<div className="col-12 col-md-1">
			<div className="form-group">
				<div className="input-group mb-3">
					<div className="input-group-prepend">
						<div className="input-group-text">
							<input type="checkbox" name={name} aria-label="Checkbox for following text input" onChange={handleOnChange} />
						</div>
					</div>
					<input type="text" className="form-control" value={label} aria-label="Text input with checkbox" readOnly={true} />
				</div>
			</div>
		</div>
	)
}

