import { CREATE_TICKET_FAILED, CREATE_TICKET_SUCCESS, FETCH_TICKETS_SUCCESS } from './actiontypes';

import { base, db } from '../connection';
import { validateEmail } from '../helper/validate';
import { createDateObj } from '../helper/dateObj';
import axios from 'axios';

export function createTicket(dataObj, employeeId, props) {
    console.log(new Date());
    return (dispatch) => {
        createNewTicket(dataObj, employeeId, props).then(data => {
            if(data){
                dispatch(data);
            }
        })
    }
}

async function createNewTicket(dataObj, employeeId, props){
    try{
        let siteId, customerId, userDetails, webPortalObj, deliveryObj, userContact, address, city, pincode, latlon, procurementFields = {};
        siteId = (await db.ref(`quotestaken/${dataObj.quoteId}/siteId`).once('value')).val();
        customerId = (await db.ref(`mapstore/quoteidtocustomerid/${dataObj.quoteId}`).once('value')).val();
        userDetails = await Promise.all([
            db.ref(`delivery/webPortal/sites/${siteId}`).once('value'),
            db.ref(`delivery/sites/${siteId}`).once('value'),
            db.ref(`users/${customerId}/mUser_Contact`).once('value')
        ]);
        webPortalObj = userDetails[0].val() || {};
        deliveryObj = userDetails[1].val() || {};
        userContact = userDetails[2].val() || {};
        address = (webPortalObj.address_D || userContact.mContact_AddressLine1 || "");
        city = userContact.mContact_City;
        pincode  = userContact.mContact_Pincode;
        latlon = (deliveryObj.latitude && deliveryObj.longitude?(deliveryObj.latitude + ", " + deliveryObj.longitude):webPortalObj.latlon_D);
        if(dataObj.type == 'Procurement' && (dataObj.subType == 'Panel' || dataObj.subType == 'Inverter')){
            let missingFields = [];
            if(!address){
                missingFields.push("Address");
            }
            if(!city || city=="Select City"){
                missingFields.push("City");
            }
            if(!pincode){
                missingFields.push("Pincode");
            }
            if(!latlon){
                missingFields.push("Lat Lon");
            }
            console.log("Missing Fields", missingFields);
            if(missingFields.length > 0){
                alert(`Please fill ${missingFields.join(", ")} in dashboard`);
                return;
            }
            if(dataObj.subType == 'Panel'){
                procurementFields = {
                    panelType: dataObj.panelType,
                    panelBrand: dataObj.panelBrand,
                    brandConfirm: dataObj.brandConfirm
                };
            }
            if(dataObj.subType == 'Inverter'){
                procurementFields = {
                    inverterPhase: dataObj.inverterPhase,
                    inverterBrand: dataObj.inverterBrand,
                    brandConfirm: dataObj.brandConfirm,
                    gridTied: dataObj.gridTied
                };
            }
        }
        let ticketType = dataObj.type + "__" + dataObj.subType;
        let ticketStatus = await isExist(ticketType, dataObj.quoteId);
        if (ticketStatus.status) {
            let createTime = (new Date()).getTime();
            let dueDate = dataObj.dueDate;
            dueDate = dueDate.split("-");
            dueDate = new Date(dueDate[1] + "-" + dueDate[2] + "-" + dueDate[0]).getTime() + 86399000;
            let isComplaint = 0;
            if(dataObj.type.includes("Complaint")){
                isComplaint = 1;
            }
            let ticketId = parseInt(createTime / 1000) + dataObj.quoteId;
            let actionObj = {
                [createTime]: {
                    status: 'Open',
                    text: 'Open Ticket',
                    updatedBy: '',
                    updateTime: createTime,
                    dueDate: dueDate,
                    removed: 0,
                    responsibleTeam: dataObj.responsibleTeam,
                    assignedTo: dataObj.assignedTo,
                    priority:dataObj.priority
                }
            };
            let complaintObj = {
                quoteId: dataObj.quoteId,
                phoneNumber: customerId,
                email: userContact.mContact_Email,
                type: dataObj.type,
                subType: dataObj.subType,
                status: 'Open',
                text: 'Thank for reaching out to us. Our customer support team has initiated the process of resolving your order issue.',
                city: city,
                ticketId: ticketId,
                updatedBy: '',
                updateTime: createTime,
                createdBy: employeeId,
                comment: dataObj.description,
                epoch: createTime,
                actions: actionObj,
                source: dataObj.source,
                assignedTo: dataObj.assignedTo,
                dueDate: dueDate,
                removed: 0,
                responsibleTeam: dataObj.responsibleTeam,
                custName:userContact.mContact_FirstName + " " + userContact.mContact_LastName,
                priority:dataObj.priority,
                isComplaint:isComplaint
            };
            complaintObj = { ...complaintObj, ...procurementFields};
            if(complaintObj.type == 'Payment'){
                complaintObj = { ...complaintObj, amount:dataObj.amount }
                sendWhatsappMessage(complaintObj);
            }
            let ticketPath = `website/ticketSystem/tickets/${ticketId}`;
            setData(ticketPath, complaintObj);
            let quoteTicketMapPath = `website/ticketSystem/quoteToTicketsMap/${dataObj.quoteId}/${ticketId}`;
            setData(quoteTicketMapPath, 1);
            setData(`website/ticketSystem/quoteToTypeMap/${dataObj.quoteId}/${ticketType}`,ticketId);
            setData(`website/ticketSystem/runningTickets/${ticketId}`,1);
            let newData = [...props.complaintData];
            complaintObj.assignedToName = props.employeeList[complaintObj.assignedTo+""];
            complaintObj.state = dataObj.selectedQuoteIdState || '';
            complaintObj.stage = dataObj.stage || '';
            newData.push(complaintObj);
            let allData = {...props, complaintData:newData};
            return({ type: FETCH_TICKETS_SUCCESS, payload: allData });
        }
        else{
            let message = {
                error : `Ticket already exists with Ticket Id - ${ticketStatus.existingTicket}`
            }
            alert(message.error);
            return({ type: CREATE_TICKET_FAILED, payload: 0 });
        }
    }
    catch(e){
        console.log("create ticket error", e);
        alert("Something went wrong");
        return({ type: CREATE_TICKET_FAILED, payload: 0 });
    }
}

function setData(path, data) {
    db.ref(path).set(data)
        .then((val) => {
            return
        })
        .catch(e => {
            return e;
        })
}


function isExist(ticketType, quoteId) {
    return new Promise((resolve, reject) => {
        let returnVal = {
            existingTicket: '',
            status: false
        }
        db.ref(`website/ticketSystem/quoteToTypeMap/${quoteId}/${ticketType}`).once('value')
            .then(snapshot => {
                let ticketId = snapshot.val();
                if (ticketId) {
                    db.ref(`website/ticketSystem/runningTickets/${ticketId}`).once('value')
                        .then(snapshot2 => {
                            let val = snapshot2.val();
                            if (val && val == 1) {
                                returnVal.existingTicket = ticketId;
                                returnVal.status = false;
                                resolve(returnVal);
                            }
                            else {
                                returnVal.existingTicket = '';
                                returnVal.status = true;
                                resolve(returnVal);
                            }
                        })
                }
                else {
                    returnVal.existingTicket = '';
                    returnVal.status = true;
                    resolve(returnVal);
                }
            })
            .catch(e => {
                console.log(e);
                reject(e);
            })
    });
}


export function sendBulkNotification(data, text, title, requestedBy){
    return async (dispatch) => {
        let emailArray 			= [];
		let notificationArray	= [];
		let nameArray 			= [];
		let sitesArray 			= [];
		let phoneNumberArray 	= [];
		let allDataArray		= [];
		let sendData 			= {};
		let sendDate	= createDateObj(new Date());
        await Promise.all(data.map(async row => {
            let quoteId = row.quoteId;
            let [ siteId, userId ] = await Promise.all([firebaseGet(`quotestaken/${quoteId}/siteId`), firebaseGet(`mapstore/quoteidtocustomerid/${quoteId}`)]);
            if(siteId && userId){
                let userObject = await firebaseGet(`users/${userId}`);
                let notificationToken = userObject.mUser_NotificationToken;
                let contact = userObject.mUser_Contact||{};
                let name = `${contact.mContact_FirstName} ${contact.mContact_LastName}`;
                let email = contact.mContact_Email;
                if(validateEmail(email)){
					let data = {};
					data = {
						email: email,
						notificationId: notificationToken,
						name: name,
						siteId: siteId,
						phoneNumber: userId
					}
					allDataArray.push(data);
					emailArray.push(email);
					notificationArray.push(notificationToken);
					nameArray.push(name);
					sitesArray.push(siteId);
					phoneNumberArray.push(userId);
				}
            }
        }))
        sendData = {
            emailList : emailArray,
            notificationList : notificationArray,
            nameList: nameArray,
            siteIdList : sitesArray,
            phoneNumberList: phoneNumberArray,
            text,
            title,
            sendDate,
            requestedBy,
            combineDataList:allDataArray
        }
        let notificationData = JSON.parse(JSON.stringify(sendData));
        console.log("data", notificationData);
        await db.ref(`bulkNotifications`).push(notificationData);
    }
}

async function firebaseGet(path){
    try{
        let data = (await db.ref(path).once('value')).val();
        return data;
    }
    catch(e){
        return;
    }
}

async function sendWhatsappMessage(ticket){
    try{
        let { phoneNumber, quoteId, custName, amount } = ticket;
        // phoneNumber = "+917840058645";
        let requestData = {
            key: "4VhfN1xiAD",
            phoneNumber,
            quoteId,
            customerName: custName,
            amount
        }
        requestData = new URLSearchParams(requestData);
        let data = await axios.post("https://zuntalks.in:3000/paymentticket", requestData, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });
        console.log("resp", data);
        return '';
    }
    catch(e){
        return '';
    }
}