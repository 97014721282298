import { combineReducers } from 'redux';

import Login from './reducer_login';
import TableInfo from './reducer_table_info';
import SnapData from './reducer_fetch_snapdata';
import NotificationsData from './reducer_fetch_notifications';
import CommentsData from './reducer_fetch_comments';
import BillsData from './reducer_fetch_bills';
import PaymentsData from './reducer_fetch_payments';
import StagesData from './reducer_fetch_stages';
import QuoteIdData from './reducer_fetch_quoteid';
import SetStatusData from './reducer_set_status';
import GraphData from './reducer_graph';
import Deals from './reducer_fetch_deals';
import Sales from './reducer_fetch_sales';
import SalesAnalytics from './reducer_fetch_sales_analytics';
import VendorTickets from './reducer_fetch_vendor_tickets';
import MappingData from './reducer_mapping';
import IncentiveData from './reducer_fetch_incentives';
import MailData from './reducer_fetch_mail';
import EmailLatestData from './reducer_fetch_email_latest';
import UploadFile from './reducer_file_upload';
import FilesData from './reducer_fetch_fileview';

import Complaint from './reducer_fetch_complaint';
import updateComplaint from './reducer_update_complaint';
import EmailAccountData from './reducer_fetch_email_accounts';
import UserRole from "./reducer_fetch_userRole";
import SetUserRole from "./reducer_set_userRole";
import NewUser from "./reducer_add_user";
import DeliveryTeam from "./reducer_add_deliveryTeams";
import ClientCall from "./reducer_fetch_call_data";
import SendEmailLayout from "./reducer_send_email_layout";
import openLog from "./reducer_open_log.js";
import GenerateVerifyOtpPanelVendor from './reducer_generate_verify_otp_panel_vendor';
import CreateNewComplaint from './reducer_create_complaint';
import CreateNewTicket from './reducer_create_ticket';
import Tickets from './reducer_fetch_tickets';
import TicketData from './reducer_fetch_ticketid';
import UpdateTicket from './reducer_update_ticket';
import UpdateComment from './reducer_update_comment';
import ReOpenTicket from './reducer_reopen_ticket';
import InboundCall from './reducer_fetch_inboundCall';
import CurrentCall from './reducer_fetch_currentcall';
import OutboundCall from './reducer_fetch_outboundcall';
import FetchConfirmed from './reducer_fetch_confirmed_orders';
import FetchEscalations from './reducer_fetch_escalations';
import FetchSalesAnalytics from './reducer_sales_analytics';
import FetchTicketingReport from './reducer_fetch_ticketing_report';
import FetchDpp from './reducer_fetch_dpp';
import PeData from './reducer_fetch_petasks';
import PmData from './reducer_fetch_pmtasks';
import allPayments from './reducer_fetch_all_payment';
import paymentPortalMapping from './reducer_payment_portal_mapping';
import paymentHistory from './reducer_fetch_payment_portal_history';
import createNewPayment from './reducer_create_new_payment';
import v2AllData from './reducer_fetch_v2_data';
import v2PocList from './reducer_fetch_v2_poclist';
import paymentComments from './reducer_fetch_payment_comments';
import panelinverterstatus from './reducer_fetch_panel_inverter_status';
import OpsReportData from './reducer_fetch_opsReport';
import peCentersData from './reducer_pe_centers'
import salesTarget from './reducer_sales_target';
import generateInvoiceStatus from './reducer_generate_invoice';

const rootReducer = combineReducers({
	snapdata: SnapData,
	notificationsdata: NotificationsData,
	commentsdata: CommentsData,
	billsdata: BillsData,
	paymentsdata: PaymentsData,
	stagesdata: StagesData,
	dealsdata: Deals,
	salesdata: Sales,
	salesanalyticsdata: SalesAnalytics,
	mapping: MappingData,
	status: SetStatusData,
	user: Login,
	data: QuoteIdData,
	tableinfo: TableInfo,
	graphdata: GraphData,
	incentivedata: IncentiveData,
	email: MailData,
	latestemail: EmailLatestData,
	uploadfile: UploadFile,
	filesdata: FilesData,
	vendortickets: VendorTickets,
	complaint: Complaint,
	updatecomplaint: updateComplaint,
	emailaccountData: EmailAccountData,
	userRole: UserRole,
	setUserRole: SetUserRole,
	newUser: NewUser,
	deliveryTeam: DeliveryTeam,
	clientcall: ClientCall,
	sendemaillayout: SendEmailLayout,
	openlog: openLog,
	generateverifyotppanel: GenerateVerifyOtpPanelVendor,
	createnewcomplaint: CreateNewComplaint,
	tickets: Tickets,
	createnewticket: CreateNewTicket,
	ticketdata: TicketData,
	updateticket: UpdateTicket,
	updatecomment: UpdateComment,
	reopen: ReOpenTicket,
	inboundcall: InboundCall,
	currentcall: CurrentCall,
	outboundcall: OutboundCall,
	confirmedorders: FetchConfirmed,
	fetchescalations: FetchEscalations,
	newsalesanalytics: FetchSalesAnalytics,
	ticketreport: FetchTicketingReport,
	fetchdpp: FetchDpp,
	pedata: PeData,
	PmData: PmData,
	opsReportData: OpsReportData,
	allPayments,
	paymentPortalMapping,
	paymentHistory,
	createNewPayment,
	v2AllData,
	v2PocList,
	paymentComments,
	panelinverterstatus,
	peCentersData,
	salesTarget,
	generateInvoiceStatus
});

export default rootReducer;
