import { PUSH_COMMENT_SUCCESS } from './actiontypes';

import { base } from '../connection';


export function setemailstatusaccounts(id,status) {
	console.log(new Date());
	return (dispatch) => {
        if(id){
		var immediatelyAvailableReference  = base.post(`delivery/webPortal/email/account/timeData/${id}/status`, {
	    data: status
	    ,then(err){
	      if(!err){
	      	console.log(`SUCCESSFULLY ADDED TO FIREBASE : ${id}, ${status}`);
	      }else{
	      	console.log(err);
	      }
	    }
     })
    }
    else{
        console.log("Invalid Id");
    }
	}
}