import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

import { styleSheet } from '../styles/styleSheet';

import { fetchfileviews } from '../actions/fileUploadActions/fetchfileviews';
import { fileDownload } from '../actions/fileUploadActions/fileUpload';
import { fetchmapping } from '../actions/fetchmapping';
import { Restrict } from '../components/Restrict';
import { changeToViewDate } from '../helper/dateChanger';


import { FilesView } from '../components/FilesView'

class FilesViewMain extends Component {

	constructor(props) {
		super(props);

		this.state = {
			recieved: false
		};
		this.vendor1 = ["NA", "Boond", "Chemitech", "SmartRoof", "Aryav", "Zivah", "SDSL", "Oakridge", "ZunRoof", "SunEnergy", "Volks Energie", "Sirius", "Greenon", "GeoPower", "GM Trading", "Alien", "Adhunik"]
		this.choosenColumn = new Array(23).fill(true);
	}

	componentDidMount() {
		if (this.props.user) {
			if (this.props.user.level >= 1600) {
				this.props.fetchfileviews();
				this.props.fetchmapping();
				this.choosenColumn = localStorage.getItem("column_operations") ? JSON.parse(localStorage.getItem("column_operations")) : new Array(23).fill(true);
			}
		}
	}

	componentWillReceiveProps(props) {

		const { filesdata, states, stages, subsidies, ebissues } = props;
		console.log("--->>", filesdata)
		if (filesdata.data.length > 0 && states && stages && subsidies && ebissues) {
			let columnData = ["360", "Electricity Bill", "Aadhaar Card", "Photo", "Property Papers", "Rooftop", "Other1", "Other2", "Panel Layout", "Quality Visit 1", "Quality Visit 2", "Commissioning Form", "Invoice", "Panel Warranty", "Inverter Warranty", "Payment Receipt", "V2 Invoice", "OC", "3D", "Shadow Profile", "Feedback Photo", "Feedback Video", "Google Review","flex1","flex2","flex3","flex4","flex5","cust1","flex7"];
			let columnDataView = [];
			columnData.forEach(val => {
				filesdata.columns.push({
					// Header: val,
					Header: row => {
						let total = (row.data||[]).filter(files => (files||{})[val]).length;
						return(<div>{val} ({total})</div>)
					},
					accessor: val, // String-based value accessors!
					Cell: row => (<div>{row.value ? <Link to="#" onClick={() => { this.props.fileDownload({ userId: this.props.user.phoneNumber, quoteId: row.value.split("__")[0], requestId: row.value.split("__")[1], fileName: row.value.split("__")[2] }) }}>{changeToViewDate(parseInt(row.value.split("__")[3]))}</Link> : ""}</div>),
					sortMethod: (a1, b1) => {
						let a = a1 ? a1.split("__")[3] : 0;
						let b = b1 ? b1.split("__")[3] : 0;
						if (a < b) {
							return 1;
						} else if (b < a) {
							return -1;
						} else {
							return -1;
						}
					}
				});
			});
			this.setState({
				recieved: true
			})
		}
	}

	render() {
		if (this.props.user === null) {
			return <Redirect to="/login?redirect_uri=files" />;
		}

		if (this.props.user.level < 1600) {
			return (
				<div>
					<Restrict />
				</div>
			)
		}

		if (this.state.recieved) {
			return (
				<div>
					<div className="container-fluid">
						<FilesView column={this.props.filesdata.columns}
							data={this.props.filesdata.data}
							user={this.props.user}
							states={this.props.states}
							stateCityMap = {this.props.stateCityMap}
							stages={this.props.stages}
							subsidies={this.props.subsidies}
							ebissues={this.props.ebissues}
							vendor1={this.vendor1} />
					</div>
				</div>
			)
		}

		return (
			<div style={styleSheet.frameOne} className="container-fluid">
				Loading...
			</div>
		);
	}
}


function mapStateToProps(state) {
	return {
		user: state.user,
		filesdata: state.filesdata,
		states: state.mapping.stateMap,
		stages: state.mapping.stageMap,
		subsidies: state.mapping.subsidyMap,
		ebissues: state.mapping.ebissueMap,
		stateCityMap : state.mapping.stateCityMap
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ fetchfileviews, fetchmapping, fileDownload }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(FilesViewMain); 
