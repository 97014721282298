import { SEND_EMAIL_LAYOUT_START, SEND_EMAIL_LAYOUT_SUCCESS } from './actiontypes';


import { base, db, storage } from '../connection';
import axios from 'axios';

export function sendEmailLayout(fileObj,name,email,responsibleEmail, responsibleDeliveryNumber) {
    console.log(new Date());
    return (dispatch) => {
        console.log("SEND EMAIL LAYPUT START");
        dispatch({ type: SEND_EMAIL_LAYOUT_START });
        console.log(responsibleDeliveryNumber);
        db.ref(`delivery/webPortal/employeeDetails/${responsibleDeliveryNumber}`).once('value')
        .then(snapshot => {
            console.log(snapshot.val());
            let employeeDetailsData = snapshot.val();
            let captainEmail = "";
            if(employeeDetailsData){
            if(employeeDetailsData.captain){
                if(employeeDetailsData.captain.email){
                    captainEmail = employeeDetailsData.captain.email;
                }
            }
        }
            fileDownload(dispatch, fileObj,name,email,responsibleEmail, captainEmail);
        })
    }
}


// function sendEmail(dispatch, fileObj) {
//     getDownloadLink(fileObj)
//     .then(downloadUrl => {
//         console.log(downloadUrl);
//         let url = 'https://www.zunroof.com/server/sendEmail.php';
//         let customerEmail = 'shubham.arora@zunroof.com';
//         let subject = "TEST EMAIL FOR LAYOUT VERIFICATION";
//         let {quoteId, requestId,filename} = fileObj;
//         let approvedLink = `https://www.zunroof.com/server/setLayoutStatus.php?quoteId=${quoteId}&requestId=${requestId}&value=2`;
//         let rejectLink = `https://www.zunroof.com/server/setLayoutStatus.php?quoteId=${quoteId}&requestId=${requestId}&value=3`;
//         let emailBody = `<!DOCTYPE html>
//     <html lang="en">
    
//     <head>
//         <meta charset="utf-8">
//         <meta name="viewport" content="width=device-width, initial-scale=1">
//         <style>
//             button {
//                 color: inherit;
//                 font: inherit;
//                 margin: 0;
//             }
    
//             button {
//                 overflow: visible;
//             }
    
//             button {
//                 text-transform: none;
//             }
    
//             button {
//                 -webkit-appearance: button;
//                 cursor: pointer;
//             }
    
//             button::-moz-focus-inner {
//                 border: 0;
//                 padding: 0;
//             }
    
//             @media print {
    
//                 *,
//                 :after,
//                 :before {
//                     color: #000 !important;
//                     text-shadow: none !important;
//                     background: 0 0 !important;
//                     -webkit-box-shadow: none !important;
//                     box-shadow: none !important;
//                 }
    
//                 h2 {
//                     orphans: 3;
//                     widows: 3;
//                 }
    
//                 h2 {
//                     page-break-after: avoid;
//                 }
//             }
    
//             * {
//                 -webkit-box-sizing: border-box;
//                 -moz-box-sizing: border-box;
//                 box-sizing: border-box;
//             }
    
//             :after,
//             :before {
//                 -webkit-box-sizing: border-box;
//                 -moz-box-sizing: border-box;
//                 box-sizing: border-box;
//             }
    
//             button {
//                 font-family: inherit;
//                 font-size: inherit;
//                 line-height: inherit;
//             }
    
//             h2 {
//                 font-family: inherit;
//                 font-weight: 500;
//                 line-height: 1.1;
//                 color: inherit;
//             }
    
//             h2 {
//                 margin-top: 20px;
//                 margin-bottom: 10px;
//             }
    
//             h2 {
//                 font-size: 30px;
//             }
    
//             .container {
//                 padding-right: 15px;
//                 padding-left: 15px;
//                 margin-right: auto;
//                 margin-left: auto;
//             }
    
//             @media (min-width:768px) {
//                 .container {
//                     width: 750px;
//                 }
//             }
    
//             @media (min-width:992px) {
//                 .container {
//                     width: 970px;
//                 }
//             }
    
//             @media (min-width:1200px) {
//                 .container {
//                     width: 1170px;
//                 }
//             }
    
//             .btn {
//                 display: inline-block;
//                 margin-bottom: 0;
//                 font-weight: 400;
//                 text-align: center;
//                 white-space: nowrap;
//                 vertical-align: middle;
//                 -ms-touch-action: manipulation;
//                 touch-action: manipulation;
//                 cursor: pointer;
//                 background-image: none;
//                 border: 1px solid transparent;
//                 padding: 6px 12px;
//                 font-size: 14px;
//                 line-height: 1.42857143;
//                 border-radius: 4px;
//                 -webkit-user-select: none;
//                 -moz-user-select: none;
//                 -ms-user-select: none;
//                 user-select: none;
//             }
    
//             .btn:active:focus,
//             .btn:focus {
//                 outline: 5px auto -webkit-focus-ring-color;
//                 outline-offset: -2px;
//             }
    
//             .btn:focus,
//             .btn:hover {
//                 color: #333;
//                 text-decoration: none;
//             }
    
//             .btn:active {
//                 background-image: none;
//                 outline: 0;
//                 -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
//                 box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
//             }
    
//             .btn-default {
//                 color: #333;
//                 background-color: #fff;
//                 border-color: #ccc;
//             }
    
//             .btn-default:focus {
//                 color: #333;
//                 background-color: #07aa07;
//                 border-color: #8c8c8c;
//             }
    
//             .btn-default:hover {
//                 color: #333;
//                 background-color: #07aa07;
//                 border-color: #adadad;
//             }
    
//             .btn-default:active {
//                 color: #333;
//                 background-color: #07aa07;
//                 background-image: none;
//                 border-color: #adadad;
//             }
    
//             .btn-default:active:focus,
//             .btn-default:active:hover {
//                 color: #333;
//                 background-color: #d4d4d4;
//                 border-color: #8c8c8c;
//             }
    
//             .container:after,
//             .container:before {
//                 display: table;
//                 content: " ";
//             }
    
//             .container:after {
//                 clear: both;
//             }
//         </style>
//     </head>
    
//     <body>
    
//         <div class="container">
//             <h2>Verify</h2>
//             <p>Download Url - ${downloadUrl}</p>
//             <a href="${approvedLink}" target="_blank"><button type="button" class="btn btn-default" id="b1" onclick="testIt()">YES</button></a>
//             <a href="${rejectLink}" target="_blank"><button type="button" class="btn btn-default">NO</button></a>
//         </div>
    
//     </body>
//     <script>
//         function testIt(){
//             document.getElementById('b1').innerHTML = 'B1';
//         }
//         </script>
//     </html>`;
//         let jsonRequest = {
//             "senderEmail": "communications@zunroof.in",
//             "emailList": [customerEmail],
//             "subject": subject,
//             "body": emailBody,
//             "replyTo": "devteam@zunroof.com"
//         };
//         fetch(url, {
//             method: 'POST', // or 'PUT'
//             body: JSON.stringify(jsonRequest), // data can be `string` or {object}!
//             mode: 'cors',
//             // headers: {
//             //     'Content-Type': 'application/json'
//             // }
//         }).then(res => res.json())
//             .then(response => {
//                 console.log('Success:', JSON.stringify(response))
//                 updateStatus(fileObj);
//         })
//             .catch(error => console.error('Error:', error));
//     })
//         .catch(e => {
//             console.log(e);
//         })
// }



// function getDownloadUrl(urlObj) {
//     console.log("ATTCHING LISTENER");
//     let { userId, quoteId, requestId, fileName } = urlObj;
//     let listenerCreated = base.listenTo(`delivery/webPortal/fileManagement/delivery/downloadRequest/${userId}/${quoteId}/${requestId}/url`, {
//         context: {},
//         asArray: false,
//         then(caseVal) {
//             switch (caseVal) {
//                 case 1:
//                     // ALL DONWLOAD
//                     let userFolder = "" + parseInt(userId);
//                     console.log("Found : ", `delivery/tempDownload/${userId}/${quoteId}/${requestId}`);
//                     storage.ref(`delivery/tempDownload/${userFolder}/${quoteId}/${requestId}`).getDownloadURL().then(function (url) {
//                         console.log(url);
//                         axios({
//                             url,
//                             method: 'GET',
//                             responseType: 'blob', // important
//                         }).then((response) => {
//                             console.log(response);
//                             base.post(`delivery/webPortal/fileManagement/delivery/downloadRequest/${userId}/${quoteId}/${requestId}`, {
//                                 data: { url: "REMOVED", status: 2 },
//                                 then(err) {
//                                     if (!err) {
//                                         // REMOVE CALL INITIATED
//                                         console.log("REMOVED")
//                                     } else {
//                                         console.log(err);
//                                     }
//                                 }
//                             });
//                             const url = window.URL.createObjectURL(new Blob([response.data]));
//                             const link = document.createElement('a');
//                             link.href = url;

//                             link.setAttribute('download', fileName);
//                             document.body.appendChild(link);
//                             link.click();
//                         });

//                     }).catch(error => { console.log(error) });
//                     console.log("REMOVING LISTENER");
//                     base.removeBinding(listenerCreated);
//                     break;
//                 case 2:
//                     // FORBIDDEN
//                     break;
//                 case 3:
//                     // NO FILE PRESENT
//                     break;
//                 default:
//                     break;
//             }
//         }
//     })
// }


// function getDownloadLink(urlObj) {
//     return new Promise((resolve, reject) => {
//         console.log("Get Download Url");
//         let { userId, quoteId, requestId, fileName } = urlObj;
//         let userFolder = "" + parseInt(userId);
//         storage.ref(`delivery/tempDownload/${userFolder}/${quoteId}/${requestId}`).getDownloadURL().then(downloadUrl => {
//             resolve(downloadUrl);
//         })
//             .catch(e => {
//                 reject(e);
//             })
//     });
// }

function updateStatus(dispatch, fileObj){
    let { userId, quoteId, requestId, fileName } = fileObj;
    if(quoteId && requestId){
        let fileObjPath = `/delivery/webPortal/fileManagement/delivery/viewableFiles/${quoteId}/${requestId}/verified`;
        console.log(fileObjPath);
        setData(fileObjPath,1);
        let sendEmailTimePath = `/delivery/webPortal/fileManagement/delivery/viewableFiles/${quoteId}/${requestId}/emailSendTime`;
        let currentTime = (new Date).getTime();
        setData(sendEmailTimePath,currentTime);
        dispatch({type:SEND_EMAIL_LAYOUT_SUCCESS,payload:1});
    }
}

function setData(path, data){
    db.ref(path).set(data)
    .then((val) => {
        return
    })
    .catch(e => {
        return e;
    })
}


function fileDownload(dispatch, downloadObj,name,email,responsibleEmail, captainEmail) {
	console.log(new Date());
        let { userId, quoteId, requestId } = downloadObj;
        if(userId && quoteId && requestId){
		base.post(`delivery/webPortal/fileManagement/delivery/downloadRequest/${userId}/${quoteId}/${requestId}/`,{
				data:{url:"",status:1},
				then(err){
		      	if(!err){
		      		// DOWNLOAD CALL INITIATED
		      		downloadURLListener(dispatch, downloadObj,name,email,responsibleEmail, captainEmail);
		      	}else{
		      		console.log(err);
		      	}
		    }
        });
    }
}

function downloadURLListener(dispatch, downloadObj,name,email,responsibleEmail, captainEmail) {
	console.log("ATTCHING LISTENER");
	let { userId, quoteId, requestId, fileName } = downloadObj;
	let listenerCreated = base.listenTo(`delivery/webPortal/fileManagement/delivery/downloadRequest/${userId}/${quoteId}/${requestId}/url`, {
		context: {},
		asArray: false,
		then(caseVal){
			switch(caseVal) {
				case 1: 
					// ALL DONWLOAD
					let userFolder = ""+parseInt(userId);
					console.log("Found : ",`delivery/tempDownload/${userId}/${quoteId}/${requestId}`);
					console.log(`delivery/tempDownload/${userFolder}/${quoteId}/${requestId}`);
					storage.ref(`delivery/tempDownload/${userFolder}/${quoteId}/${requestId}`).getDownloadURL().then(function (url) {
						console.log(url);
                        sendEmailClient(dispatch, downloadObj,url,name,email,responsibleEmail, captainEmail)
                        .then((response) => {
							console.log(response);
							base.post(`delivery/webPortal/fileManagement/delivery/downloadRequest/${userId}/${quoteId}/${requestId}`, {
								data:{url:"REMOVED",status:2},
								then(err){
							      	if(!err){
							      		// REMOVE CALL INITIATED
							      		console.log("REMOVED")
							      	}else{
							      		console.log(err);
							      	}
							    }
							});
							// const url = window.URL.createObjectURL(new Blob([response.data]));
							// const link = document.createElement('a');
							// link.href = url;
							
							// link.setAttribute('download', fileName);
							// document.body.appendChild(link);
							// link.click();
						});
				        
				    }).catch(error => {console.log(error)});
					console.log("REMOVING LISTENER");
					base.removeBinding(listenerCreated);
					break;
				case 2: 
					// FORBIDDEN
					break;
				case 3: 
					// NO FILE PRESENT
					break;
				default:
					break;
			}
		}
	})
}


function sendEmailClient(dispatch, fileObj,downloadUrl,name,email,responsibleEmail, captainEmail){
    console.log(downloadUrl);
        let url = 'https://www.zunroof.com/server/sendEmailWithAtt.php';
        let customerEmail = email;
        let subject = "Layout approval request, ZunRoof";
        let {quoteId, requestId,fileName} = fileObj;
        let approvedLink = `https://www.zunroof.com/server/setLayoutStatus.php?quoteId=${quoteId}&requestId=${requestId}&value=2&name=${name}&responsibleEmail=${responsibleEmail}&captainEmail=${captainEmail}`;
        let rejectLink = `https://www.zunroof.com/server/setLayoutStatus.php?quoteId=${quoteId}&requestId=${requestId}&value=3&name=${name}&responsibleEmail=${responsibleEmail}&captainEmail=${captainEmail}`;
        let emailBody = `<!DOCTYPE html>
        <html lang="en">
        
        <head>
            <meta charset="utf-8">
            <meta name="viewport" content="width=device-width, initial-scale=1">
            <style>
                .button {
                    background-color: #4CAF50;
                    /* Green */
                    border: none;
                    color: white;
                    padding: 8px 16px;
                    text-align: center;
                    text-decoration: none;
                    display: inline-block;
                    font-size: 16px;
                    margin: 4px 2px;
                    -webkit-transition-duration: 0.4s;
                    /* Safari */
                    transition-duration: 0.4s;
                    cursor: pointer;
                }
        
                .button1 {
                    background-color: white;
                    color: black;
                    border: 2px solid #4CAF50;
                }
        
                .button1:hover {
                    background-color: #4CAF50;
                    color: white;
                }
            </style>
        </head>
        
        <body>
            Hello,<br><br>
        
            Greetings from ZunRoof !! Please find below the 2D layout of your proposed solar power system. Press yes if you find design good to go. Press no if there is something for us to change.<br><br>
        
            <a href="${approvedLink}" target="_blank"><button type="button" class="button button1" id="b1"
                    onclick="testIt()">YES</button></a>
            <a href="${rejectLink}" target="_blank"><button type="button" class="button button1">NO</button></a>
            <br><br>
            Warm Regards,
            <br>
            ZunRoof Team
            <br>
            <a href="www.zunroof.com" target="_blank">www.zunroof.com,</a><br>
            <a href="www.facebook.com/zunroof" target="_blank">www.facebook.com/zunroof</a><br>
            <br>
            Support: +919319432702<br>
            Sales: +91 9205695690<br>
            <br>
            <br>
            Get your ZunRoof app:<br>
            <a href="https://play.google.com/store/apps/details?id=com.zunroofprod.zunroofprod" target="_blank"><img src="https://ci5.googleusercontent.com/proxy/4KMkvPyRLycxS2qcLEIYuA25TSHNFc95V9uHOsmdENm5yCFO_Eem-EmyWs6JYZrxwRdXL0TbRvXRJ_2cnNmBbpDorkAhDbDvDCHrUuTcBvFps28vtWe1b8UsJuuQ4rBPyVN7F8U9WuVjsaY=s0-d-e1-ft#https://www.saveonfoods.com/wp-content/uploads/2015/10/app-store-android-download.png" height="45" width="120"></a>
            <br>
            <a href="https://itunes.apple.com/us/app/zunroof-solar-app/id1394408235?ls=1&mt=8" target="_blank"><img src="https://ci5.googleusercontent.com/proxy/VqwD6sXk-Zf3DYYm682VWKa4PG6zSp2qGuia3NXwVbsU_WLFGs3oneeguQIidfUiLH4F1w9ncVpnljZIUDhLrrPxlOItaa8oRZsxD84kpAaD7YGTHMVDq4Nhnw=s0-d-e1-ft#http://blog.creativelive.com/wp-content/uploads/2016/10/app-store.png" height="45" width="120"></a>
            <br>
        </body>
        
        </html>`;
        let jsonRequest = {
            "email": customerEmail,
            "subject": subject,
            "body": emailBody,
            "fileName":fileName,
            "quoteId":quoteId,
            "att":downloadUrl
        };
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(jsonRequest), // data can be `string` or {object}!
                mode: 'cors',
                // headers: {
                //     'Content-Type': 'application/json'
                // }
            }).then(res => res.json())
                .then(response => {
                    console.log('Success:', JSON.stringify(response))
                    //updateStatus(fileObj);
                    sendEmailCM(dispatch, fileObj,name,email,responsibleEmail);
                    createLayoutTicket(quoteId, fileObj.userId);
                    resolve(JSON.stringify(response))
            })
                .catch(error => {console.error('Error:', error)
                reject(error);
                });
        })
        
}

function sendEmailCM(dispatch, fileObj,name,email,responsibleEmail){
    let url = 'https://www.zunroof.com/server/sendEmail.php';
    let emailCM = 'delivery@zunroof.com';
    //let emailCM = 'devteam@zunroof.com';
    let {quoteId, requestId} = fileObj;
    let subject = "Get approval for Layout Quote ID: "+quoteId;
    let emailBody = `<!DOCTYPE html>
    <html lang="en">
    
    <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
    </head>
    
    <body>
    
        <div class="container">
        Hi,<br><br>
        Kindly contact the following Client to get layout approved. <br><br>        
        Quote ID:${quoteId}<br> 
        Name:${name}<br><br>
        
        Thanks<br>
        
        </div>
    
    </body>
    </html>`;
        let jsonRequest = {
            "senderEmail": "communications@zunroof.in",
            "emailList": [emailCM, responsibleEmail],
            "subject": subject,
            "body": emailBody,
            "replyTo": "devteam@zunroof.com"
        };
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(jsonRequest), // data can be `string` or {object}!
                mode: 'cors',
                // headers: {
                //     'Content-Type': 'application/json'
                // }
            }).then(res => res.json())
                .then(response => {
                    console.log('Success:', JSON.stringify(response))
                    updateStatus(dispatch, fileObj);
                    resolve(JSON.stringify(response))
            })
                .catch(error => {console.error('Error:', error)
                reject(error);
                });
        })
}


async function createLayoutTicket(quoteId, employeeId) {
    try {
        let request = {
            quoteId: quoteId,
            employeeId: employeeId,
            team: "CM",
            type: "Project update",
            subType: "Layout Approval",
            responsibleId: "+919311343855",
            key: "lhxXHlJAz4xwesf"
        };
        let createTicketResponse = await axios.post('https://operations.zunroof.com/api/createticket', request);
        console.log("createTicketResponse", createTicketResponse);
    }
    catch(e) {
        console.log("Error in createLayoutTicket", e);
        return;
    }
}