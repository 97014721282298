import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { Restrict } from '../components/Restrict';
import renderHTML from 'react-render-html';
import axios from 'axios';
import moment from 'moment';
class BulkInvoiceUpload extends Component {


  constructor(props) {
    super(props);
    this.state = {
      uploading : false,
      files : []
    };
  }

  componentDidMount() {
    console.log(this.props.user);
    if (this.props.user) {
      if (this.props.user.level == 1800 || this.props.user.level > 2500) {
        console.log("Component Did Mount");
      }
      else{
        this.setState({ html: "You don't have access to view this. Please Contact Admin." })
      }
    }
  }

  handleOnUpload = (e) => {
		let files = Array.from(e.target.files);
    let newFiles = files.map(file => {
      let fileName = file.name;
      let data = fileName.split('_');
      let [quoteId, invoiceText, invoiceDate] = data;
      if (!quoteId || !invoiceText || !invoiceDate) {
        file.error = "Data Missing or Invalid format";
      } else if (!(/^([a-zA-Z]{3,4}[0-9]{5,7})$/.test(quoteId))) {
        file.error = "Invalid QuoteId";
      } else {
        if (invoiceDate.includes(".")) {
          invoiceDate = invoiceDate.slice(0, invoiceDate.indexOf("."))
        }
        if (!(/^([0-9]{2}[\-/]{1}[0-9]{2}[\-/]{1}[0-9]{4})$/.test(invoiceDate))) {
          file.error = "Invalid Invoice Date Format";
        } else {
          file.quoteId = quoteId.toUpperCase();
          file.invoiceText = invoiceText.replace(/\$/g, '/');
          file.invoiceDate = moment(invoiceDate, 'DD-MM-YYYY').format('x');
        }
      }
      return file;
    })
    this.setState({
      files : newFiles
    })
	}

  handleUploadClick = async () => {
    this.setState({
      uploading : true
    })
    let files = await Promise.all(this.state.files.map(async file => {
      if (file.quoteId && file.invoiceText && file.invoiceDate) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("quoteId", file.quoteId);
        formData.append("invoiceText", file.invoiceText);
        formData.append("invoiceDate", file.invoiceDate);
        formData.append("userId", this.props.user.phoneNumber);
        // let content = await axios.post("http://localhost:3002/bulkInvoiceUpload", formData);
        let content = await axios.post("https://operations.zunroof.com/bulkInvoiceUpload", formData);
        console.log("response: ",content);
        if (content.data) {
          content = content.data;
          if (content.error) {
            file.error = content.error;
          } else {
            file.success = content.success;
          }
        }
        return file;
      } else {
        return file;
      }
    }))
    console.log("files", files);
    this.setState({
      files : files,
      uploading : false
    })
	}

  render() {

    if (this.props.user === null) {
      return <Redirect to={"/login?redirect_uri=bulkInvoiceUpload"} />;
    }

    if (this.props.user && !(this.props.user.level == 1800 || this.props.user.level > 2500)) {
      return (
        <div>
        <Restrict />
        </div>
      )
    }

    return (
      <div style={{ "marginTop": 50 }} className="container-fluid">
        <div style={{textAlign:"center"}}>
          <h1>Bulk Invoice Upload</h1>
          <h4>Note: File name should be of the format QuoteId_InvoiceNumber_InvoiceDate and then any other string after an underscore('_'). Also, Every slash('/') in Invoice Number should be replaced by a Dollar Sign('$') and Invoice Date should be of the format 'DD-MM-YYYY'. Eg: ABC19000_Zun$19-20$12345_31-12-2019_Example.pdf</h4>
        </div>
        <div style={{margin:"50px 100px"}}>
          <form>
            <input type="file" disabled={this.state.uploading} onChange={this.handleOnUpload} required multiple/>
          </form>
          {this.state.uploading ? <div style={{margin:"50px 0px"}}>Uploading Files...</div> :
            <div style={{margin:"50px 0px"}}>
              {this.state.files.map((file, index) => {
                return <div style={{margin:"5px"}} key={index}><b>{file.name} :</b> {file.error || file.success || (<span>QuoteId - {file.quoteId}, Invoice No - {file.invoiceText}, Date - {moment(+file.invoiceDate).format('DD-MM-YYYY')} <i className={"fas fa-check"} style={{color:"green"}}></i></span>)} <br/></div>;
              })}
            </div>
          }
          <div>
            <button className="btn btn-success" disabled={this.state.uploading} onClick={this.handleUploadClick}>Upload Files</button><br/>
            <p>Only Correct Files will be uploaded</p>
          </div>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    user: state.user
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkInvoiceUpload);
