import { SEND_EMAIL_LAYOUT_START, SEND_EMAIL_LAYOUT_SUCCESS } from '../actions/actiontypes';

const INIT_STATE = {
	data:{},
	completed:false,
}

export default function(state = INIT_STATE, action) {
	switch(action.type){
		case SEND_EMAIL_LAYOUT_START:{
			return {...state};
		}
		case SEND_EMAIL_LAYOUT_SUCCESS:{
			return {...state, data : action.payload};
		}
		default:
			return state;
	}
}