import { FETCH_V2_POCS_START, FETCH_V2_POCS_SUCCESS } from '../actions/actiontypes';

export default function (state = {}, action) {
  switch (action.type) {
    case FETCH_V2_POCS_START: {
      return {};
    }
    case FETCH_V2_POCS_SUCCESS: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}