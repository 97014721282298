import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { Restrict } from '../components/Restrict';
import { fetchSalesTarget, updateSalesTarget, updateAchievedCounter, fetchAccessLevel } from '../actions/salesTargetActions';
import moment from 'moment';
import { Loader } from "../components/Loader.jsx";

const adminLevel = 2;
const editLevel = 1;

class SalesTarget extends Component {


    constructor(props) {
        super(props);
        this.state = {
            editStatus: {
                roof: false,
                delivered: false,
                zunpulse: false,
                zunsolar: false
            },
            salesTargetData: {},
            updatedData: {},
            accessLevel: 0
        };
    }

    componentDidMount() {
        if (this.props.user) {
            if (this.props.user.level >= 1600) {
                this.props.fetchSalesTarget();
                this.props.fetchAccessLevel(this.props.user.phoneNumber);
            }
        }
    }


    componentWillReceiveProps(props) {
        let { data, received } = props;
        let accessLevel = this.props.user.level >= 2600?2:props.accessLevel;
        this.setState({
            salesTargetData: data,
            received,
            accessLevel
        })
    }

    onEditClick = (type) => {
        this.setState(prevState => ({
            editStatus: {
                ...prevState.editStatus,
                [type]: true
            },
            updatedData: {
                ...prevState.updatedData,
                [type]: prevState.salesTargetData[type]
            }
        }))
    }

    handleOnChange = (type, e) => {
        let { name, value } = e.target;
        let { updatedData = {} } = this.state;
        updatedData = JSON.parse(JSON.stringify(updatedData));
        updatedData[type] = updatedData[type] || {};
        updatedData[type][name] = value;
        this.setState({
            updatedData
        })
    }

    onSaveClick = (type) => {
        let { salesTargetData = {}, updatedData = {} } = this.state;
        console.log(type, updatedData[type], salesTargetData);
        this.props.updateSalesTarget(type, updatedData[type], salesTargetData, this.props.user.phoneNumber);
        this.setState(prevState => ({
            editStatus: {
                ...prevState.editStatus,
                [type]: false
            }
        }))
    }

    onCounterClick = (type, value) => {
        let { salesTargetData = {} } = this.state;
        this.props.updateAchievedCounter(type, value, salesTargetData, this.props.user.phoneNumber);
        this.setState({
            received: false
        })
    }


    CardComponent = ({ type, heading, imageLink }) => {
        let { onEditClick, onSaveClick, state, handleOnChange, onCounterClick } = this;
        let { editStatus, salesTargetData, updatedData, received, accessLevel } = state;
        let edit = editStatus[type];
        let currentData = edit ? updatedData : salesTargetData;
        let data = currentData[type] || {};
        let completedPercent = data.target && data.target > 0 ? parseInt(data.achieved / data.target * 100) : 0;
        // let canEdit = this.props.user.level >= 2600 ? true : false;
        // console.log(completedPercent);
        return (
            <div className="card" style={{ backgroundColor: "#ffffff", borderRadius: "1rem" }}>
                <div className="card-header" style={{ background: `-webkit-linear-gradient(left, #CFF7D2 ${completedPercent}%,#ffffff ${completedPercent}%)`, borderRadius: "calc(1rem - 1px) calc(1rem - 1px) 0 0", padding: "1.5rem 2rem", boxShadow: "0 0.125rem 0.8rem rgba(0,0,0,0.1)" }}>
                    <h2 className="h4" style={{ float: 'left' }}>{heading} (Last Updated - {data.updatedOn ? moment(+data.updatedOn).format("DD MMM, YYYY HH:mm:ss") : ""})</h2>
                    {accessLevel >= 1 ? (edit ? <button className="btn" onClick={() => onSaveClick(type)} style={{ float: 'right' }}><i className="fa fa-check" aria-hidden="true"></i></button> : <button className="btn" onClick={() => onEditClick(type)} style={{ float: 'right' }} disabled={!received}><i className="fa fa-edit" aria-hidden="true"></i></button>):""}
                </div>
                <div className="card-body">
                    <div className="h5" style={{ textAlign: "center" }}><img width="15%" src={`${imageLink}`} /></div>
                    <div className="row" style={{ textAlign: "center", padding: "30px 10px" }}>
                        <div className="col-3" style={{ margin: "auto" }}>
                            {accessLevel >= 1 ? (edit ? "" : <button className="btn btn-light" onClick={() => onCounterClick(type, -1)} disabled={!received}><i className="fa fa-minus fa-lg" aria-hidden="true"></i></button>) : ""}
                        </div>
                        <div className="col-6" style={{ fontSize: "30px" }}>
                            {edit ? <input type="number" className="form-control mt-3" style={{ padding: "10px 2px", fontSize: "20px" }} name="achieved" value={data.achieved} onChange={(e) => handleOnChange(type, e)}></input> : data.achieved}
                        </div>
                        <div className="col-3" style={{ margin: "auto" }}>
                            {accessLevel >= 1 ? (edit ? "" : <button className="btn btn-light" onClick={() => onCounterClick(type, 1)} disabled={!received}><i className="fa fa-plus fa-lg" aria-hidden="true"></i></button>) : ""}
                        </div>
                    </div>
                    <hr />
                    <div className="row" style={{ textAlign: "center", padding: "30px 10px" }}>
                        <div className="col-6 border-right" style={{ fontSize: "22px" }}>
                            <div className="h5 center">Target Date</div>
                            {edit && accessLevel >= 2 ? <input type="date" className="form-control mt-3" style={{ padding: "10px 2px" }} value={data.targetDate} name="targetDate" onChange={(e) => handleOnChange(type, e)}></input> : ( data.targetDate ? moment(data.targetDate, "YYYY-MM-DD").format("DD MMM, YYYY") : "" ) }
                        </div>
                        <div className="col-6" style={{ fontSize: "22px" }}>
                            <div className="h5 center">Target</div>
                            {edit && accessLevel >= 2 ? <input type="number" className="form-control mt-3" style={{ padding: "10px 2px" }} value={data.target} name="target" onChange={(e) => handleOnChange(type, e)}></input> : data.target}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    HomesImpactedCardComponent = ({ type, heading }) => {
        let { onEditClick, onSaveClick, state, handleOnChange } = this;
        let { editStatus, salesTargetData, updatedData, received, accessLevel } = state;
        let edit = editStatus[type];
        let currentData = edit ? updatedData : salesTargetData;
        let data = currentData[type] || {};
        let achieved = 99586 + +(data.supply || 0) + +(data.consumption || 0) + +(data.b2b || 0);
        let completedPercent = data.target && data.target > 0 ? parseInt(achieved / data.target * 100) : 0;
        return (
            <div className="card" style={{ backgroundColor: "#ffffff", borderRadius: "1rem" }}>
                <div className="card-header" style={{ background: `-webkit-linear-gradient(left, #CFF7D2 ${completedPercent}%,#ffffff ${completedPercent}%)`, borderRadius: "calc(1rem - 1px) calc(1rem - 1px) 0 0", padding: "1.5rem 2rem", boxShadow: "0 0.125rem 0.8rem rgba(0,0,0,0.1)" }}>
                    <h2 className="h4" style={{ float: 'left' }}>{heading}</h2>
                    {accessLevel >= 1 ? (edit ? <button className="btn" onClick={() => onSaveClick(type)} style={{ float: 'right' }}><i className="fa fa-check" aria-hidden="true"></i></button> : <button className="btn" onClick={() => onEditClick(type)} style={{ float: 'right' }} disabled={!received}><i className="fa fa-edit" aria-hidden="true"></i></button>):""}
                </div>
                <div className="card-body">
                    <div className="h5" style={{ textAlign: "center" }}><i class="fas fa-home fa-3x" style={{ color: "#2ab67e" }}></i></div>
                    <div className="row" style={{ textAlign: "center", padding: "30px 10px" }}>
                        <div className="col-3" style={{ margin: "auto" }}></div>
                        <div className="col-6" style={{ fontSize: "30px" }}>
                            <b>{achieved}</b>
                        </div>
                        <div className="col-3" style={{ margin: "auto" }}></div>
                    </div>
                    <hr />
                    <div className="row" style={{ textAlign: "center", padding: "45px 10px" }}>
                        <div className="col border-right" style={{ fontSize: "22px" }}>
                            <div className="h5 center">Party Target</div>
                            {edit && accessLevel >= 2 ? <input type="number" className="form-control mt-3" style={{ padding: "10px 2px" }} value={data.target} name="target" onChange={(e) => handleOnChange(type, e)}></input> : data.target}
                        </div>
                        <div className="col" style={{ fontSize: "22px" }}>
                            <div className="h5 center">Target Date</div>
                            {edit && accessLevel >= 2 ? <input type="date" className="form-control mt-3" style={{ padding: "10px 2px" }} value={data.targetDate} name="targetDate" onChange={(e) => handleOnChange(type, e)}></input> : ( data.targetDate ? moment(data.targetDate, "YYYY-MM-DD").format("DD MMM, YYYY") : "" ) }
                        </div>
                        { edit && accessLevel >= 2 ? <div className="col border-left" style={{ fontSize: "22px" }}>
                            <div className="h5 center">B2B</div>
                            {edit && accessLevel >= 2 ? <input type="number" className="form-control mt-3" style={{ padding: "10px 2px" }} value={data.b2b} name="b2b" onChange={(e) => handleOnChange(type, e)}></input> : data.b2b}
                        </div> : "" }
                    </div>
                </div>
            </div>
        )
    }

    render() {
        let { CardComponent, HomesImpactedCardComponent, state } = this;
        let { received = false } = state;
        if (this.props.user === null) {
            return <Redirect to={"/login?redirect_uri=target"} />;
        }

        if (this.props.user && this.props.user.level < 1600) {
            return (
                <div>
                    <Restrict />
                </div>
            )
        }
        
        if(received) {
            return (
                <div className="container-fluid" style={{ margin: '50px auto' }}>
                    <div className="row mb-3">
                        <div className="col-md-7 col-12" style={{ margin: '0 auto' }}>
                            <HomesImpactedCardComponent
                                type="homesImpacted"
                                heading={"ZunHomes"}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <CardComponent
                                type="roof"
                                heading={"Roofs"}
                                imageLink="https://www.zunroof.com/assets/logo.png"
                            />
                        </div>
                        <div className="col-md-6 col-12">
                            <CardComponent
                                type="delivered"
                                heading={"Delivered"}
                                imageLink="https://www.zunroof.com/assets/logo.png"
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-6 col-12">
                            <CardComponent
                                type="zunsolar"
                                heading={"ZunSolar"}
                                imageLink="https://zunsolar.com/assets/header/logo.png"
                            />
                        </div>
                        <div className="col-md-6 col-12">
                            <CardComponent
                                type="zunpulse"
                                heading={"ZunPulse"}
                                imageLink="https://www.zunpulse.com/assets/header/desktop/logo.png"
                            />
                        </div>
                    </div>

                </div>
            );
        }

        return (
			<div className="container-fluid">
				<Loader />
			</div>
		);
    }
}
function mapStateToProps(state) {
    let { data = {}, received = false, accessLevel = 0 } = state.salesTarget || {};
    // console.log("accessLevel", accessLevel);
    return {
        user: state.user,
        data,
        received,
        accessLevel
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchSalesTarget, updateSalesTarget, updateAchievedCounter, fetchAccessLevel }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesTarget);