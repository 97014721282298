import { checkExist } from './filterData';
import { calculateDateAge } from './helper';

// PERCENTAGE BAR GRAPH
export function barGraphPercentageStage(percentagedata,stagesSelected,stages) {
	let labelsBackgroundColor = ["#b2dfdb","#4db6ac","#009688","#b2ebf2","#4dd0e1","#00bcd4","#0097a7","#00796b","#004d40","#006064","#0288d1","#01579b"];
	var arrayNew = Array.from({length: 11}, e =>{return{
		label: 'NA',
	    backgroundColor: '#1a237e',
	    borderWidth: 1,
	    stack: '1',
	    hoverBackgroundColor: '#1a237e',
	    data: new Array(11).fill(0)
	}})
	Object.values(percentagedata).forEach((value,index) => {
		if(stages[value.stage]!=="Truncated" && checkExist(stagesSelected.map(val=> val.value),value.stage)) {
			let index = -1;
			if(isFinite(value.percentage)){
				if(value.percentage>=100)
					value.percentage = 99.99;
				index = Math.floor(parseFloat(value.percentage)/10);
			}
			else{
				index = 10;
			}
			let { stage } = value;
			if(arrayNew[stage]) {
				arrayNew[stage].label = stages[stage]?stages[stage]:"NA";
			    arrayNew[stage].backgroundColor = labelsBackgroundColor[stage]?labelsBackgroundColor[stage]:"#000";
			    arrayNew[stage].borderWidth = 1;
			    arrayNew[stage].stack = '1';
			    arrayNew[stage].hoverBackgroundColor = labelsBackgroundColor[stage]?labelsBackgroundColor[stage]:"#000";
			    arrayNew[stage].data[index] += 1
			} 
		}
	});
	return arrayNew;
}


// SALES ORDER COUNT BAR GRAPH
export function barGraphSalesOrder(percentagedata,stagesSelected,stages) {
	let labelsBackgroundColor = ["#b2dfdb","#4db6ac","#009688","#b2ebf2","#4dd0e1","#00bcd4","#0097a7","#00796b","#004d40","#006064","#0288d1","#01579b"];
	var arrayNew = Array.from({length: 11}, e =>{return{
		label: 'NA',
	    backgroundColor: '#1a237e',
	    borderWidth: 1,
	    stack: '1',
	    hoverBackgroundColor: '#1a237e',
	    data: new Array(7).fill(0)
	}})
	Object.values(percentagedata).forEach((value,index) => {
		if(stages[value.stage]!=="Truncated" && checkExist(stagesSelected.map(val=> val.value),value.stage)) {
			let { salesDate, stage } = value;
			let indexFound = Math.floor(calculateDateAge(new Date(salesDate).getTime())/45);
			if(indexFound>=6) {
				indexFound = 6;
			}
			if(arrayNew[stage]) {
				arrayNew[stage].label = stages[stage]?stages[stage]:"NA";
			    arrayNew[stage].backgroundColor = labelsBackgroundColor[stage]?labelsBackgroundColor[stage]:"#000";
			    arrayNew[stage].borderWidth = 1;
			    arrayNew[stage].stack = '1';
			    arrayNew[stage].hoverBackgroundColor = labelsBackgroundColor[stage]?labelsBackgroundColor[stage]:"#000";
			    arrayNew[stage].data[indexFound] += 1
			}
		}
	});
	return arrayNew;
}



// PERCENTAGE RESPONSIBLE GRAPH
export function barGraphPercentageResponsible(percentagedata,responsibleMap,employeemap) {
	let labelsBackgroundColor = ["#b2dfdb","#4db6ac","#009688","#00bcd4","#0097a7","#00796b","#004d40","#006064","#0288d1","#01579b","#b2ebf2","#4dd0e1"]
		
	var arrayNew = Array.from({length: Object.keys(responsibleMap).length}, e =>{return{
		label: 'NA',
	    backgroundColor: '#1a237e',
	    borderWidth: 1,
	    stack: '1',
	    hoverBackgroundColor: '#1a237e',
	    data: new Array(Object.keys(percentagedata).length).fill(0)
	}})
	Object.keys(percentagedata).forEach((week,index1) => {
		let value = percentagedata[""+week];
		console.log(value);
		Object.keys(value).forEach((number,index) => {
			if(employeemap[""+number]) {
				arrayNew[responsibleMap[""+number]].label = employeemap[""+number].fname+" "+employeemap[""+number].lname;
			    arrayNew[responsibleMap[""+number]].backgroundColor = labelsBackgroundColor[responsibleMap[""+number]]?labelsBackgroundColor[responsibleMap[""+number]]:"#000";
			    arrayNew[responsibleMap[""+number]].borderWidth = 1;
			    arrayNew[responsibleMap[""+number]].stack = '1';
			    arrayNew[responsibleMap[""+number]].hoverBackgroundColor = labelsBackgroundColor[responsibleMap[""+number]]?labelsBackgroundColor[responsibleMap[""+number]]:"#000";
			    arrayNew[responsibleMap[""+number]].data[index1] += value[""+number];
			}
		})
	});
	console.log(arrayNew)
	return arrayNew;
}
