import { FETCH_PANEL_INVERTER_STATUS_SUCCESS } from '../actions/actiontypes'
const INIT_STATE = {
	loading: true
}

export default function (state = INIT_STATE, action) {
	switch (action.type) {
		case FETCH_PANEL_INVERTER_STATUS_SUCCESS:
            let { panelData, inverterData } = action.payload || {};
			return { ...state, panelData, inverterData, loading:false };
        default:
			return state;
	}
}
