import { FETCH_CLIENT_CALL_LOG_START, FETCH_CLIENT_CALL_LOG_SUCCESS } from '../actions/actiontypes'

const INIT_STATE = {
	totalCallData:{},
	completed:false,
}

export default function(state = INIT_STATE, action) {
	switch(action.type){
		case FETCH_CLIENT_CALL_LOG_START:{
			return {...state};
		}
		case FETCH_CLIENT_CALL_LOG_SUCCESS:{
			return {...state, totalCallData : action.payload};
		}
		default:
			return state;
	}
}