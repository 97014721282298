import { FETCHING_SALES_START, FETCHING_SALES_ERROR, FETCHING_SALES_SUCCESS, FETCHING_SALES_ANALYTICS_SUCCESS } from './actiontypes';

import { db } from '../connection';
import { fetch, set } from '../helper/firebaseFunctions';
import { dateChanger } from '../helper/dateChanger';


export function fetchsalesdata(viewName) {
	return (dispatch) => {
		console.log("INIT",new Date());
		let allQuoteIdURL = `/delivery/webPortal`;
		fetch(allQuoteIdURL,'deliverySiteMapping')
		.then(siteIdMap => {
			console.log("AFTER GETTING ALL QUOTEID",new Date());
			let siteIdList = Object.keys(siteIdMap);
			fetchSalesDetails(siteIdList)
			.then(values => {
				console.log("FINAL DATA",new Date());
				switch(viewName) {
					case "sales" :
						let salesTableData = values.map(val => {
							let siteData 		= val[0]?val[0]:{};
							let deliveryData 	= val[1]?val[1]:{};
							let webPortalData 	= val[2]?val[2]:{};
							let otherData 		= val[3]?val[3]:{};
							let data = {...siteData, ...deliveryData, ...webPortalData, ...otherData};
							return getSalesData(data);
						});
						dispatch({type:FETCHING_SALES_SUCCESS,payload:salesTableData})
						break;
					case "salesAnalytics":
						let salesAnalyticsTableData = values.map(val => {
							let siteData 		= val[0]?val[0]:{};
							let deliveryData 	= val[1]?val[1]:{};
							let webPortalData 	= val[2]?val[2]:{};
							let otherData 		= val[3]?val[3]:{};
							let data = {...siteData, ...deliveryData, ...webPortalData, ...otherData};
							return getSalesAnalyticsData(data);
						});
						dispatch({type:FETCHING_SALES_ANALYTICS_SUCCESS,payload:salesAnalyticsTableData})
						break;
					 default:
					 	break;
				}
			}).catch(error => {
				console.log(error);
			})
		}).catch(error => {
			console.log(error);
		})
	}
}

function fetchSalesDetails(siteIdList) {
	console.log("FETCHING FOR ALL DETAILS",new Date());
	let promiseList = siteIdList.map((siteId,index) => {
		return new Promise((resolve, reject) => {
			Promise.all([
				fetchSiteDataAndUser(siteId),
				fetch('sites',siteId),
				fetch('delivery/webPortal/sites',siteId),
				fetch('delivery/Issue/statusbysite/',siteId)
			])
			.then(values => {resolve(values);return;})
			.catch(values => {reject(values);return;});
		})
	});
	console.log("PROMISE CREATED",new Date());
	return Promise.all(promiseList);
}

function fetchSiteDataAndUser(siteId) {
	return new Promise((resolve,reject) => {
		fetch('delivery/sites',siteId)
		.then(value => {
			let { siteOwnerId, responsilbleIdDelevery } = value;
			Promise.all([fetch('users',siteOwnerId),fetch('users',responsilbleIdDelevery)])
			.then(userObj => {
				let { mUser_Contact } = userObj[1];
				let { mContact_FirstName, mContact_LastName } = mUser_Contact;
				let responsibleName = `${mContact_FirstName} ${mContact_LastName}`;
				resolve({...value,...userObj[0], responsibleName})
			}).catch(error => {
				console.log(error);
			})
		}).catch(error => {
			reject(error);
			return;
		});
	});
}

function getSalesData(salesData) {
	let { stage, mSite_QuoteId, mSite_selectedSize, size_D, mSite_Id, vendor, inc, employeeId_D, revenueType_D, mSite_finalPrice, mSite_State, state_D, mSite_SystemType, extraPriceLineItem, mSite_subsidy, mSite_subsidy_CashBack, mUser_Contact, siteOwnerId, subsidy_D, mSite_ElevationType, elevationType_D, systemType_D, paymentHistory, salesApprovedStatus_D, employeeName, revenueRate_D, responsibleName, siteissue } = salesData;

	let { mContact_FirstName, mContact_LastName, mContact_City } = mUser_Contact;

	let { change_By, current_State, next_Date, past_State, ref_Date, sales_Date } = stage?stage[stage.length-1]:{};

	let ottPriceArray = Object.values(extraPriceLineItem?extraPriceLineItem:{}).map(val => !isNaN(parseInt(val.price))?parseInt(val.price):0);
	let paymentPaidArray = Object.values(paymentHistory?paymentHistory:{}).map(val => !isNaN(parseInt(val.amountPaid))?parseInt(val.amountPaid):0);
	let ottPrice = ottPriceArray.reduce((val1, val2) => val1+val2, 0);
	let paymentPaid = paymentPaidArray.reduce((val1, val2) => val1+val2, 0);
	let finalSize = size_D?size_D:mSite_selectedSize?mSite_selectedSize:0;
	let createdData = {
		siteId: mSite_Id,
		ownerId: siteOwnerId,
		salesDate: dateChanger(sales_Date),
		quoteId: mSite_QuoteId,
		elevationType: elevationType_D?elevationType_D:mSite_ElevationType?mSite_ElevationType:"",
		size: parseFloat(finalSize),
		finalPrice: +mSite_finalPrice+ottPrice,
		state: state_D?state_D:mSite_State,
		stage: current_State||current_State===0?(!isNaN(parseInt(current_State))?parseInt(current_State):-1):-1,
		systemType: systemType_D?systemType_D:mSite_SystemType?mSite_SystemType:"",
		subsidyValue: mSite_subsidy?+mSite_subsidy:0,
		subsidy: subsidy_D?subsidy_D:mSite_subsidy?"Upfront":mSite_subsidy_CashBack?"Cashback":"No Subsidy",
		totalSale: (+mSite_finalPrice)+ottPrice+(+mSite_subsidy),
		city: mContact_City,
		revenueRate: isNaN(parseFloat(revenueRate_D))?0:Math.round(parseFloat(revenueRate_D)*100)/100,
		employeeId: employeeId_D,
		revenueType: revenueType_D,
		revenueTotal: isNaN(parseInt(revenueRate_D))?0:Math.round(parseFloat(revenueRate_D)*parseFloat(finalSize)*100)/100,
		salesApprovedStatus_D: salesApprovedStatus_D?salesApprovedStatus_D:0,
		percentage: (paymentPaid*100)/(ottPrice+(+mSite_finalPrice)),
		name: `${mContact_FirstName} ${mContact_LastName}`,
		employeeName: employeeName,
		v1: vendor,
		v2: inc
	}
	return createdData;
}

// SALES ANALYTICS
function getSalesAnalyticsData(salesData) {
	let { stage, mSite_QuoteId, mSite_selectedSize, size_D, mSite_Id, vendor, inc, employeeId_D, revenueType_D, mSite_finalPrice, mSite_State, state_D, mSite_SystemType, extraPriceLineItem, mSite_subsidy, mSite_subsidy_CashBack, mUser_Contact, siteOwnerId, subsidy_D, mSite_ElevationType, elevationType_D, systemType_D, paymentHistory, salesApprovedStatus_D, employeeName, revenueRate_D, responsibleName, siteissue } = salesData;

	let { mContact_FirstName, mContact_LastName, mContact_City } = mUser_Contact;

	let { change_By, current_State, next_Date, past_State, ref_Date, sales_Date } = stage?stage[stage.length-1]:{};

	let ottPriceArray = Object.values(extraPriceLineItem?extraPriceLineItem:{}).map(val => !isNaN(parseInt(val.price))?parseInt(val.price):0);
	let paymentPaidArray = Object.values(paymentHistory?paymentHistory:{}).map(val => !isNaN(parseInt(val.amountPaid))?parseInt(val.amountPaid):0);
	let ottPrice = ottPriceArray.reduce((val1, val2) => val1+val2, 0);
	let paymentPaid = paymentPaidArray.reduce((val1, val2) => val1+val2, 0);
	let finalSize = size_D?size_D:mSite_selectedSize?mSite_selectedSize:0;
	let createdData = {
		size: finalSize,
		state: state_D?state_D:mSite_State,
		stage: current_State||current_State===0?(!isNaN(parseInt(current_State))?parseInt(current_State):-1):-1,
		city: mContact_City,
		salesDate: dateChanger(sales_Date),
		employeeName: employeeId_D,
		ebIssueAllList: siteissue?siteissue:[],
		percentage: (paymentPaid*100)/(ottPrice+(+mSite_finalPrice)),
		vendor: vendor,
		subsidy : +mSite_subsidy!==0 && mSite_subsidy!==""?"UpFront":+mSite_subsidy_CashBack!==0 && mSite_subsidy_CashBack!==""?"Cashback":"No Subsidy",
	}
	return createdData;
}