import { PERCENTAGE_GRAPH, PAYMENT_HISTORY_GRAPH, PAYMENT_RESPONSIBLE_WEEK_GRAPH, OUTSTANDING_ORDERS_GRAPH } from '../actions/actiontypes'

const INITIAL_STATE = {
	percentageGraph: null,
	paymentHistory:null,
	paymentWeekHistory:null,
	outstandingOrdersGraph:null
}

export default function(state = INITIAL_STATE, action) {
	switch(action.type){
		case PERCENTAGE_GRAPH:{
			return {...state, percentageGraph:action.payload};
		}
		case PAYMENT_HISTORY_GRAPH:{
			return {...state, paymentHistory:action.payload}
		}
		case PAYMENT_RESPONSIBLE_WEEK_GRAPH:{
			return {...state, paymentWeekHistory:action.payload}
		}
		case OUTSTANDING_ORDERS_GRAPH:{
			return {...state, outstandingOrdersGraph:action.payload}
		}
		default:
			return state;
	}
}