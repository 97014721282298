import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage'
import Rebase from 're-base';

import { FirebaseConfig } from "./dbconfig/key";

const app 		= firebase.initializeApp(FirebaseConfig);
var db 			= firebase.database(app);
var base 		= Rebase.createClass(db);
const provider 	= new firebase.auth.GoogleAuthProvider();
const auth 		= firebase.auth();
const storage 	= firebase.storage(app);

export { base, provider, auth, firebase, storage, db };