import { base } from '../connection';
import { FETCHING_STAGE_MAP_SUCCESS,
		 FETCHING_EBISSUE_MAP_SUCCESS,
		 FETCHING_FINANCING_MAP_SUCCESS,
		 FETCHING_STATE_MAP_SUCCESS,
		 FETCHING_SUBSIDY_MAP_SUCCESS,
		 FETCHING_NOTIFICATION_MAP_SUCCESS,
		 FETCHING_EMPLOYEE_MAP_SUCCESS,
		 FETCHING_CITY_MAP_SUCCESS,
		 FETCHING_EMPLOYEE_ID_MAP_SUCCESS,
		 FETCHING_VENDOR_ID_MAP_SUCCESS,
		 FETCHING_SUBSIDY_STATUS_MAP_SUCCESS,
		  FETCHING_NET_METERING_MAP_SUCCESS,
		  FETCHING_STATE_CITY_MAP_SUCCESS, 
		  FETCHING_NEW_STAGE_MAPPING_SUCCESS } from './actiontypes';

let statusMap 	= {};
let EBIssueMap 	= [];
let FinancingMap= [];
let StateD 		= [];
let SubsidyD 	= [];
let stageMap 	= []
let notificationsMap = [];
let subsidyStatusMap = [];
let netMeteringStagesMap = [];


export function fetchmapping() {
	console.log(new Date());
	return (dispatch) => {
		statusMap 	= {};
		EBIssueMap 	= [];
		FinancingMap= [];
		StateD 		= [];
		SubsidyD 	= [];
		stageMap 	= [];
		notificationsMap = [];
		fetchStatusMaps(dispatch);
		fetchEBIssues(dispatch);
		fetchFinancingIssues(dispatch);
 		fetchStatesD(dispatch);
		fetchSubsidyD(dispatch);
		fetchNotificationMaps(dispatch);
		fetchEmployeeList(dispatch);
		fetchStateToCity(dispatch);
		fetchEmployeeId(dispatch);
		fetchVendorId(dispatch);
		fetchSubsidyStatus(dispatch);
		fetchNetMeteringStages(dispatch);
		fetchNewStageMap(dispatch);
	}
}


function fetchStatusMaps(dispatch) {
 	base.fetch('/delivery/stagelist',{})
 	.then(data=>{
 		data.map((val,index)=>{
 			statusMap[val.id] = val.name;
 			stageMap[index] = val.name;
 		});
 		dispatch({type:FETCHING_STAGE_MAP_SUCCESS,payload:stageMap});
 	})
 	.catch(error=>{

 		console.log(error);
 	})
 }

 function fetchSubsidyStatus(dispatch) {
  	base.fetch('/delivery/webPortal/subsidyStatus',{})
  	.then(data=>{
			data.forEach((val,index)=>{
	 			subsidyStatusMap[index] = val;
	 		});
  		dispatch({type:FETCHING_SUBSIDY_STATUS_MAP_SUCCESS,payload:subsidyStatusMap});
  	})
  	.catch(error=>{

  		console.log(error);
  	})
  }

	function fetchNetMeteringStages(dispatch) {
	 	base.fetch('/delivery/webPortal/netMeteringStages',{})
	 	.then(data=>{
			data.forEach((val,index)=>{
	 			netMeteringStagesMap[index] = val;
	 		});
	 		dispatch({type:FETCHING_NET_METERING_MAP_SUCCESS,payload:netMeteringStagesMap});
	 	})
	 	.catch(error=>{

	 		console.log(error);
	 	})
	 }



function fetchEBIssues(dispatch) {
	base.fetch('/delivery/Issue/EB_issue', {})
	.then(data => {
		let index = 0;
		data.map(val=>{
 			EBIssueMap[index++] = val.name;
 		});
 		dispatch({type:FETCHING_EBISSUE_MAP_SUCCESS,payload:EBIssueMap})
	})
	.catch(error => {

	})
}

function fetchEmployeeList(dispatch) {
	base.fetch('/employeelist', {})
	.then(data => {
 		dispatch({type:FETCHING_EMPLOYEE_MAP_SUCCESS,payload:data})
	})
	.catch(error => {

	})
}

function fetchFinancingIssues(dispatch) {
	base.fetch('/delivery/Issue/Financing', {})
	.then(data => {
		let index = 0;
		data.map(val=>{
 			FinancingMap[index++] = val;
 		});
 		dispatch({type:FETCHING_FINANCING_MAP_SUCCESS,payload:FinancingMap})
	})
	.catch(error => {

	})
}


function fetchStatesD(dispatch) {
	base.fetch('/delivery/webPortal/states', {})
	.then(data => {
		for(var index in data){
			StateD.push(index);
		}
 		dispatch({type:FETCHING_STATE_MAP_SUCCESS,payload:StateD})
	})
	.catch(error => {

	})
}

function fetchSubsidyD(dispatch) {
	base.fetch('/delivery/webPortal/subsidyType', {})
	.then(data => {
		data.map((val,index)=>{
 			SubsidyD[index] = val;
 		});
 		dispatch({type:FETCHING_SUBSIDY_MAP_SUCCESS,payload:SubsidyD})
	})
	.catch(error => {

	})
}

// PRESET NOTIFICATIONS
function fetchNotificationMaps(dispatch) {
 	base.fetch('/presetNotifications',{})
 	.then(data=>{
 		notificationsMap = data;
 		dispatch({type:FETCHING_NOTIFICATION_MAP_SUCCESS,payload:notificationsMap})
 	})
 	.catch(error=>{
 		console.log(error);
 	})
}


// CITY MAP
function fetchStateToCity(dispatch) {
	base.fetch('/uploads/systemdesign/state_city_list_0',{})
	.then(data=>{
		let stateToCityMap = {};
		Object.values(data).forEach(val => {
			val.forEach(value => {
				stateToCityMap[value] = value;
			});
		});
		dispatch({type : FETCHING_STATE_CITY_MAP_SUCCESS, payload : data})
		dispatch({type:FETCHING_CITY_MAP_SUCCESS,payload:stateToCityMap})
	})
	.catch(error=>{
		console.log(error);
	})
}

// EMPLOYEE ID MAPPING
function fetchEmployeeId(dispatch) {
	base.fetch('delivery/webPortal/employeeIdToNameMapping',{})
	.then(data=>{
		dispatch({type:FETCHING_EMPLOYEE_ID_MAP_SUCCESS,payload:data})
	})
	.catch(error=>{
		console.log(error);
	})
}

// VENDOR ID MAPPING
function fetchVendorId(dispatch) {
	base.fetch('delivery/webPortal/vendorMapping',{})
	.then(data=>{
		dispatch({type:FETCHING_VENDOR_ID_MAP_SUCCESS,payload:data})
	})
	.catch(error=>{
		console.log(error);
	})
}

function fetchNewStageMap(dispatch) {
	base.fetch('delivery/webPortal/newStageMapping',{})
	.then(data=>{
		dispatch({type:FETCHING_NEW_STAGE_MAPPING_SUCCESS,payload:data})
	})
	.catch(error=>{
		console.log(error);
	})
}
