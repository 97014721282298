import { UPDATE_COMPLAINTS_SUCCESS, UPDATE_COMPLAINTS_START } from '../actions/actiontypes'

const INIT_STATE = {
	completed:false,
}

export default function(state = INIT_STATE, action) {
	switch(action.type){
		case UPDATE_COMPLAINTS_START:{
			return {...state};
		}
		case UPDATE_COMPLAINTS_SUCCESS:{
			return {...state, completed:true};
		}
		default:
			return state;
	}
}