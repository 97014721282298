import { FETCHING_USERROLE_START,
  FETCHING_USERROLE_COMPLETED,
  FETCHING_USERROLE_SUCCESS} from '../actions/actiontypes'

const INIT_STATE = {
	data:{},
  teams: [],
	completed:false,
}

export default function(state = INIT_STATE, action) {
	switch(action.type){
		case FETCHING_USERROLE_START:{
			return state;
		}
		case FETCHING_USERROLE_SUCCESS:{
			return {...state, data:action.payload.userRoles, teams : action.payload.teams ,completed:true };
		}
		default: {
			return state;
		}
	}
}
