import { FETCH_CONFIRMED_DATA_SUCCESS, FETCH_CONFIRMED_DATA_START } from './actiontypes';

import { base, db } from '../connection';

let fetched = {};

export function fetchConfirmedOrders() {
    return async (dispatch) => {
        dispatch({ type: FETCH_CONFIRMED_DATA_START });

        fetchOrders().then(data => {
            console.log(data);
            dispatch({ type: FETCH_CONFIRMED_DATA_SUCCESS, payload: data });
        })
    }
}
async function fetchOrders() {
    try {
        let formData = new URLSearchParams();
        formData.append("key", "JA]T8WFx5M25bew");
        let content = await fetch("https://operations.zunroof.com/api/fetchconfirmed", {
          method : 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body : formData
        })
        content = await content.json();
        console.log(content);
        return content.data;
      } catch (e) {
        console.log(e);
        return {};
      }
}
