import { FETCH_COMPLAINTS_SUCCESS, FETCH_COMPLAINTS_START } from './actiontypes';

import { base, db } from '../connection';

export function fetchComplaints() {
	console.log(new Date());
  	return (dispatch) => {        
    	dispatch({type:FETCH_COMPLAINTS_START});
        db.ref('/website/complaint/tickets').once('value')
        .then(snapshot => {
            console.log(Object.values(snapshot.val()));
            let dataComplaints = Object.values(snapshot.val());
            Promise.all([fetchTeamToEmployeeMap(), fetchEmployeeList(),fetchEmployees()])
            .then(result => {
                console.log(result);
                let teamEmployeeMap = {};
                let employeeList = {};
                let members = [];
                if(result[0]){
                    teamEmployeeMap = result[0];
                }
                if(result[1]){
                    employeeList = result[1];
                }
                if(result[2]){
                    members = result[2];
                }
                let completeData = {
                    complaintData : dataComplaints,
                    teamEmployeeMap : teamEmployeeMap,
                    employeeList : employeeList,
                    members:members
                }
                dispatch({type:FETCH_COMPLAINTS_SUCCESS,payload:completeData})
            })
            .catch(e => {
                console.log(e);
            })
            
            //fetchTeamToEmployeeMap();
        })
        .catch(e => {
            return e;
        })
 	}
}

function fetchTeamToEmployeeMap() {
    return new Promise((resolve,reject) => {
    let teamToEmployeeMap = {};
    base.fetch('/website/complaint/teamToEmployeeMap',{})
    .then(data=>{
        for(var key in data) {
            let teamId = key;
            teamToEmployeeMap[data[""+teamId].teamName] = Object.keys(data[""+teamId].members);
        }
        console.log(teamToEmployeeMap);
        resolve(teamToEmployeeMap);
        //fetchEmployeeList();
    })
    .catch(error=>{
        console.log(error);
        reject(error);
    })
});
}

function fetchEmployeeList() {
    return new Promise((resolve,reject) => {
    base.fetch('/employeelist',{})
    .then(data=>{
        let employeeMap = {};
        for(var key in data){
            employeeMap[""+key] = data[""+key].fname + " " + data[""+key].lname;
        }
        console.log(employeeMap);
        resolve(employeeMap);
        //fetchFolderType(dispatch,id);
    })
    .catch(error=>{
        console.log(error);
        reject(error);
    })
});
}

function fetchEmployees() {
    return new Promise((resolve,reject) => {
    let members = [];
    base.fetch('/website/complaint/members',{})
    .then(data=>{
        //console.log(teamToEmployeeMap);
        resolve(data);
        //fetchEmployeeList();
    })
    .catch(error=>{
        console.log(error);
        reject(error);
    })
});
}