import { base, db } from '../connection';


export function setincdata(siteId,inc, user, oldInc) {
	console.log(new Date());
	return (dispatch) => {
		pushVendor(siteId,inc, user, oldInc);
	}
}


function pushVendor(siteId,data,user,oldInc) {
	base.post('/delivery/sites/'+siteId+'/inc', {
	    data: data
	    ,then(err){
	      if(!err){
			  addComment(siteId,data,user,oldInc);
	      }else{
	      	console.log(err);
	      }
	    }
	  });
}

async function addComment(siteId,data,user,oldInc){
	try{
		if(!siteId){
			return;
		}
		let comments = (await db.ref(`delivery/sites/${siteId}/comments`).once('value')).val()||[];
		let userObj = (await db.ref(`users/${user}/mUser_Contact`).once('value')).val() || {};
		let { mContact_FirstName, mContact_LastName } = userObj;
		let userName = `${mContact_FirstName||""} ${mContact_LastName||""}`;
		let commentObj = createDateObj();
		let newComment = {
			commentId : comments.length + "",
			comment : 'New V2:' +data+'\nOld V2:'+oldInc,
			commentDate: commentObj,
			employeeId: user,
			employeeName: userName
		}
		let updatedComments = [ ...comments, newComment ];
		await db.ref(`delivery/sites/${siteId}/comments`).set(updatedComments);
	}
	catch(e){
		console.log("error", e)
	}
}

function createDateObj() {
	let dateVal = new Date();
	let dateObj = {
		date:dateVal.getDate(),
		day:dateVal.getDay(),
		hours:dateVal.getHours(),
		minutes:dateVal.getMinutes(),
		month:dateVal.getMonth()+1,
		seconds:dateVal.getSeconds(),
		time:dateVal.getTime(),
		timezoneOffset:dateVal.getTimezoneOffset(),
		year:dateVal.getFullYear()
	}
	return dateObj;
}