import { GENERATE_INVOICE_STATUS, GENERATE_INVOICE_SUCCESS, UPDATE_INVOICE_DATA } from './actiontypes';
import axios from 'axios';

export function generateInvoice(data) {
    return async (dispatch) => {
        dispatch({ type: GENERATE_INVOICE_STATUS, payload: 1 });
        let response = await axios.post("https://operations.zunroof.com/api/generateinvoice", { key: "bSFx2wcJ2MUnPVf", invoiceData: data })
        response = response.data;
        if(response.error) {
            alert("Some error occured");
            return;
        }
        dispatch({ type: GENERATE_INVOICE_SUCCESS, payload: response.result })
        dispatch({ type: GENERATE_INVOICE_STATUS, payload: 2 })
    }
}


export function downloadInvoice(files = []) {
    return async (dispatch) => {
        let response = await axios.post("https://operations.zunroof.com/api/donwloadinvoice", { key: "bSFx2wcJ2MUnPVf", files })
        response = response.data;
        if(response.error) {
            alert("Some error occured.");
            return;
        }
        let { fileData, fileName, type } = response;
        const blob = new Blob([new Uint8Array(fileData.data)], {type: type});
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    }
}

export function uploadCsvFile(file) {
    return async (dispatch) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("key", "bSFx2wcJ2MUnPVf");
        let response = await axios.post("https://operations.zunroof.com/api/uploadcsvfile", formData)
        response = response.data;
        if(response.error) {
            alert("Some error occured.");
            return;
        }
        console.log("response", response);
        let { invoiceData = [{products:{}}] } = response;
        dispatch({ type: UPDATE_INVOICE_DATA, payload: invoiceData });
    }
}