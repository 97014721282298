export const csvComplete = [
	{
		label: 'name',
		key: 'name' // String-based value keys!
	},
	{
		label: 'phone',
		key: 'phone' // String-based value keys!
	},
	{
		label: 'email',
		key: 'email' // String-based value keys!
	},
	{
		label: 'responsible',
		key: 'responsible' // String-based value keys!
	},
	// {
	// 	label: 'siteid',
	// 	key: 'siteid' // String-based value keys!
	// },
	{
		label: 'quoteid',
		key: 'quoteid', // String-based value keys!,

	},
	{
		label: 'state',
		key: 'state' // String-based value keys!
	},
	{
		label: 'city',
		key: 'city' // String-based value keys!
	},
	{
		label: 'address',
		key: 'address' // String-based value keys!
	},
	{
		label: 'size',
		key: 'selectedsize' // String-based value keys!
	},
	{
		label: 'lat',
		key: 'latitude' // String-based value accessors!
	},
	{
		label: 'lon',
		key: 'longitude' // String-based value accessors!
	},
	{
		label: 'inc',
		key: 'inc' // String-based value keys!
	},
	{
		label: 'vendor',
		key: 'vendor' // String-based value keys!
	}]

export const csvComment = [
	{
		label: 'name',
		key: 'name'
	},
	{
		label: 'quoteid',
		key: 'quoteid',
	},
	{
		label: 'responsible',
		key: 'responsible'
	},
	{
		label: 'comment',
		key: 'comment'
	},
	{
		label: 'date',
		key: 'date'
	},
	{
		label: 'state',
		key: 'state'
	}
]

export const csvPaymentDue = [
	{
		label: 'Name',
		key: 'name'
	},
	{
		label: 'Quote Id',
		key: 'quoteid',
	},
	{
		label: 'Size',
		key: 'size'
	},
	{
		label: 'Payment Type',
		key: 'paymenttype',
		width: 207
	},
	{
		label: 'Amount',
		key: 'paymentamount'
	},
	{
		label: 'Property Type',
		key: 'propertytype'
	},
	{
		label: 'State',
		key: 'state'
	}
]


export const csvNotification = [
	{
		label: 'name',
		key: 'name'
	},
	{
		label: 'quoteid',
		key: 'quoteid',
	},
	{
		label: 'responsible',
		key: 'responsible'
	},
	{
		label: 'notification',
		key: 'notification'
	},
	{
		label: 'date',
		key: 'date'
	},
	{
		label: 'state',
		key: 'state'
	}
]

export const csvorderstatus = [
	{
		label: 'name',
		key: 'name'
	},
	{
		label: 'quoteid',
		key: 'quoteid',
	},
	{
		label: 'expectedCompletionDate',
		key: 'expectedCompletionDate'
	},
	{
		label: 'invertor',
		key: 'invertor'
	},
	{
		label: 'netMetering',
		key: 'netMetering'
	},
	{
		label: 'orderConfirmationInternal',
		key: 'orderConfirmationInternal'
	},
	{
		label: 'panel',
		key: 'panel'
	},
	{
		label: 'structure',
		key: 'structure'
	},
	{
		label: 'subsidyApply',
		key: 'subsidyApply'
	},
	{
		label: 'address',
		key: 'address'
	},
	{
		label: 'city',
		key: 'city'
	}
]

export const csvpaymentstatus = [
	{
		label: 'quoteid',
		key: 'quoteid',
	},
	{
		label: 'Payment Date',
		key: 'paymentdate'
	},
	{
		label: 'Payment Type',
		key: 'paymenttype'
	},
	{
		label: 'payment',
		key: 'paymentvalue'
	},
	{
		label: 'city',
		key: 'city'
	},
	{
		label: 'name',
		key: 'name'
	}
]

export const columnsDealsCSV = [
	{
		label: 'Sales Date',
		key: 'salesdate'
	},
	{
		label: 'Quote Id',
		key: 'quoteid'
	},
	{
		label: 'Name',
		key: 'username' // String-based value keys!
	},
	{
		label: 'Size',
		key: 'size'
	},
	{
		label: 'Stage',
		key: 'stage'
	},
	{
		label: 'City',
		key: 'city' // String-based value keys!
	},
	{
		label: 'State',
		key: 'stateD'
	},
	{
		label: 'Subsidy',
		key: 'subsidyD'
	},
	{
		label: 'Paid %',
		key: 'paidPercentage'
	},
	{
		label: 'Invoice No.',
		key: 'invoiceText'
	},
	{
		label: 'Invoice Date',
		key: 'invoiceDate',
	},
	{
		label: 'V1',
		key: 'vendor'
	},
	{
		label: 'V2',
		key: 'inc'
	},
	{
		label: 'Responsible',
		key: 'responsibleName'
	},
	{
		label: 'Action Date',
		key: 'nextactiondate'
	},
	{
		label: 'Ref Date',
		key: 'refDate'
	},
	{
		label: 'Elevation Type',
		key: 'elevationtype' // String-based value keys!
	},
	{
		label: 'System Type',
		key: 'type' // String-based value keys!
	},
	{
		label: 'Loan Issue',
		key: 'financingIssue'
	},
	{
		label: 'Structure',
		key: 'structureExpectedDate'
	},
	{
		label: 'Panel',
		key: 'panelExpectedDate'
	},
	{
		label: 'Invertor',
		key: 'invertorExpectedDate'
	},
	{
		label: 'EB_Name',
		key: 'ebIssueName'
	},
	{
		label: 'EB_Load',
		key: 'ebIssueLoad'
	},
	{
		label: 'EB_Type',
		key: 'ebIssueType'
	},
	{
		label: 'EB_Phase',
		key: 'ebIssuePhase'
	},
	{
		label: 'Property Type',
		key: 'propertytype'
	},
]

export const csvEmailComplete = [
	{
		label: 'name',
		key: 'name' // String-based value keys!
	},
	{
		label: 'phone',
		key: 'phone' // String-based value keys!
	},
	{
		label: 'email',
		key: 'email' // String-based value keys!
	},
	{
		label: 'responsible',
		key: 'responsible' // String-based value keys!
	},
	// {
	// 	label: 'siteid',
	// 	key: 'siteid' // String-based value keys!
	// },
	{
		label: 'quoteid',
		key: 'quoteid', // String-based value keys!,

	}]

export const csvErrorCSV = [
	{
		label: 'QuoteId',
		key: 'quoteid' // String-based value keys!
	}
]

export const csvTickets = [
	{
		label: 'Date',
		key: 'ticketDateView'
	},
	{
		label: 'Quote Id',
		key: 'quoteId'
	},
	{
		label: 'Name',
		key: 'custName',
	},
	{
		label: 'City',
		key: 'city',
	},
	{
		label: 'Ticket Id',
		key: 'ticketId'
	},
	{
		label: 'Category',
		key: 'type',
	},
	{
		label: 'Sub-Category',
		key: 'subType',
	},
	{
		label: 'Due Date',
		key: 'dueDateView'
	},
	{
		label: 'Status',
		key: 'status',
	},
	{
		label: 'Source',
		key: 'source'
	},
	{
		label: 'Team',
		key: 'responsibleTeam',
	},
	{
		label: 'Assigned To',
		key: 'assignedToName'
	},
	{
		label: 'State',
		key: 'state'
	},
	{
		label: 'Stage',
		key: 'stage'
	},
	{
		label: 'Closing Date',
		key: 'closeTimeView'
	},
	{
		label: 'Last Updated',
		key: 'ticketUpdatedOnView'
	},
	{
		label: 'Overdue',
		key: 'overdue'
	}
];

export const csvDPP = [
	{
		label: 'Quote ID',
		key: 'quoteId'
	},
	{
		label: 'Date',
		key: 'viewDate'
	},
	{
		label: 'Sales Person',
		key: 'salesPerson'
	},
	{
		label: 'Request Number',
		key: 'requestNumber',
	},
	{
		label: 'First Name',
		key: 'firstName'
	},
	{
		label: 'Last Name',
		key: 'surName',
	},
	{
		label: 'Gender',
		key: 'gender'
	},
	{
		label: 'DOB',
		key: 'dob'
	},
	{
		label: 'Email',
		key: 'email'
	},
	{
		label: 'PAN',
		key: 'pan',
	},
	{
		label: 'Monthly Salary',
		key: 'monthly_salary_income'
	},
	{
		label: 'State',
		key: 'state',
	},
	{
		label: 'City',
		key: 'city',
	},
	{
		label: 'Pin Code',
		key: 'pincode',
	},
	{
		label: 'Address',
		key: 'address',
	},
	{
		label: 'Flat No',
		key: 'flatNo',
	},
	{
		label: 'OTP Entered',
		key: 'otpEntered'
	},
	{
		label: 'Age',
		key: 'age',
	},
	{
		label: 'Credit Score',
		key: 'creditScore',
	},
	{
		label: 'App Status',
		key: 'appStatus',
	}
];

export const csvPaymentData = [
	{
		label: 'Date',
		key: 'viewDate'
	},
	{
		label: 'Business Category',
		key: 'category'
	},
	{
		label: 'Sub Category',
		key: 'subCategory'
	},
	{
		label: 'Customer Name',
		key: 'name'
	},
	{
		label: 'Quote Id',
		key: 'id'
	},
	{
		label: 'Amount',
		key: 'amount'
	},
	{
		label: 'Mode',
		key: 'mode'
	},
	{
		label: 'Status',
		key: 'status'
	},
	{
		label: 'Bank',
		key: 'bank'
	},
	{
		label: 'Employee Name',
		key: 'employeeName'
	},
	{
		label: 'Ref No',
		key: 'referenceNumberView'
	},
	{
		label: 'Dealer Code',
		key: 'dealerCode'
	},
	{
		label: 'Key',
		key: 'key'
	},
]