import { FETCH_ALL_PAYMENTS_START, FETCH_ALL_PAYMENT_SUCCESS, FETCH_ALL_PAYMENT_ERROR, FETCH_PAYMENT_PORTAL_MAPPING_START, FETCH_PAYMENT_PORTAL_MAPPING_SUCCESS, FETCH_PAYMENT_HISTORY_SUCCESS, FETCH_PAYMENT_HISTORY_START, CREATE_PAYMENT_START, CREATE_PAYMENT_SUCCESS, CREATE_PAYMENT_ERROR, FETCH_PAYMENT_COMMENTS_START, FETCH_PAYMENT_COMMENTS_SUCCESS } from './actiontypes';
import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment';

// let  API_BASE_URL = "http://localhost:3004/api/"
let API_BASE_URL = "https://operations.zunroof.com/api/"

export function fetchAllPayment(page=0,limit=500, oldData=[], filters={}, user) {
  return async (dispatch) => {
    dispatch({ type: FETCH_ALL_PAYMENTS_START, payload: [] })
    let key = "bSFx2wcJ2MUnPVf"
    user = user ? user.phoneNumber : ''
    let apiData = {
      page:page,
      limit:limit,
      filters:filters,
      key:key,
      user:user
    }
    let allPayment = await axios.post(`${API_BASE_URL}payment/allV2`, apiData);
    
    if (allPayment.data.data) {
      // console.log('data',allPayment.data);
      // console.log('page', page);
      if(limit=="all"){
        dispatch({ type: FETCH_ALL_PAYMENT_SUCCESS, payload: allPayment.data || {} })
        
      }
      else{
          let data = allPayment.data.data;
          if(page!=0){
            allPayment.data.data = data.concat(oldData);
          }
          dispatch({ type: FETCH_ALL_PAYMENT_SUCCESS, payload: allPayment.data|| {} })

        }
    }
    else  {
      dispatch({ type: FETCH_ALL_PAYMENT_ERROR, payload: allPayment.data.error })
      return;
    }
  } 
}

export function paymentPortalMapping(user) {
  return async (dispatch) => {
    console.log('call paymentPortalMapping');
    dispatch({ type: FETCH_PAYMENT_PORTAL_MAPPING_START, payload: {} });
    let key = "bSFx2wcJ2MUnPVf";
    user = user ? user.phoneNumber : '';
    console.log('data', user,key);
    let allMapping = await axios.post(`${API_BASE_URL}payment/mapping`, { user, key });
    console.log("allMapping", allMapping,allMapping.data.mapping);
    dispatch({ type: FETCH_PAYMENT_PORTAL_MAPPING_SUCCESS, payload: allMapping.data.mapping })
  }
}

export function newPayment(formData, user, allPayments, access) {
  return async (dispatch) => {
    try {
      console.log("called")
      let obj = {
        user: user,
        data: formData,
        key: "bSFx2wcJ2MUnPVf"
      }
      allPayments = JSON.parse(JSON.stringify(allPayments));
      let response = await axios.post(`${API_BASE_URL}payment/create`, obj);
      // console.log("1000", response);
      console.log('response',response);
      if (response.data) {
        if (response.data.missingComment) {
          swal({
            text: 'Duplicate Reference Number! Enter comment to continue',
            content: "input",
            button: {
              text: "Submit!",
              closeModal: false,
            },
          }).then(comment => {
            if (!comment) {
              swal({
                text: "No comment Found, Edit Request declines",
                icon: "warning",
                buttons: {
                  cancel: 'close'
                }
              });
              return;
            };
            obj.comment = comment
            axios.post(`${API_BASE_URL}payment/create`, obj).then((duplicateResponse) => {
              if (duplicateResponse.data.data) {
                // console.log("success")
                swal({
                  text: 'Payment Created',
                  icon: 'success',
                  buttons: {
                    cancel: 'close',
                  },
                });
                formData.createdOn = moment().valueOf();
                formData.paymentKey = duplicateResponse.data.key;
                formData.paymentDate = moment(formData.paymentDate, "YYYY-MM-DD").valueOf();
                allPayments.push(formData)
                dispatch({ type: FETCH_ALL_PAYMENT_SUCCESS, payload: { data: allPayments, access: access } })
              }
            })
          })
        } else if (response.data.error) {
          swal(response.data.error);
          dispatch({ type: FETCH_ALL_PAYMENT_SUCCESS, payload: { data: allPayments, access: access } })
        } else {
          swal({
            text: 'Payment Created',
            icon: 'success',
            buttons: {
              cancel: 'close',
            },
          });
          formData.createdOn = moment().valueOf();
          formData.paymentKey = response.data.key;
          formData.paymentDate = moment(formData.paymentDate, "YYYY-MM-DD").valueOf();
          allPayments.push(formData)
          dispatch({ type: FETCH_ALL_PAYMENT_SUCCESS, payload: { data: allPayments, access: access } })
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
}

export function updatePayment(oldData, newData, allPayments, access, user) {
  return async (dispatch) => {
    try {
      let id = oldData.paymentKey
      let key = "bSFx2wcJ2MUnPVf"
      let data = {}

      Object.keys(newData).map((val) => {
        if (newData[val] != oldData[val]) {
          if (val == "paymentDate") {
            data[val] = new Date(newData[val]).getTime();
          } else {
            data[val] = newData[val];
          }
        }
      })
      console.log("modifiedObj", data);
      let response = await axios.post(`${API_BASE_URL}payment/updateV2/` + id, { data, user, key, quoteId: oldData.id })
      if (response && response.data) {
        if (response.data.missingComment) {
          swal({
            text: 'Duplicate Reference Number! Enter comment to continue',
            content: "input",
            button: {
              text: "Submit!",
              closeModal: false,
            },
          }).then(comment => {
            if (!comment) {
              swal({
                text: "No comment Found, Edit Request declines",
                icon: "warning",
                buttons: {
                  cancel: 'close'
                }
              })
              return
            }
            let quoteId = oldData.id
            axios.post(`${API_BASE_URL}payment/updateV2/` + id, { data, user, key, comment, quoteId }).then((duplicateResponse) => {
              if (duplicateResponse.data.data) {
                // console.log("success")
                swal({
                  text: 'Payment Updated',
                  icon: 'success',
                  buttons: {
                    cancel: 'close',
                  },
                });
                let modifiedPaymentData = [...allPayments];
                allPayments.map((val, index) => {
                  if (val.paymentKey == id) {
                    modifiedPaymentData[index] = newData;
                  }
                })
                dispatch({ type: FETCH_ALL_PAYMENT_SUCCESS, payload: { data: modifiedPaymentData, access: access } })
              }
            })
          })
        }
        else if (response.data.error) {
          swal(response.data.error)
          // return allPayments;
        } else {
          swal({
            text: 'Payment Updated',
            icon: 'success',
            buttons: {
              cancel: 'close',
            },
          })
          let modifiedPaymentData = [...allPayments]
          allPayments.map((val, index) => {
            // console.log("inside map")
            if (val.paymentKey == id) {
              modifiedPaymentData[index] = newData;
            }
          })
          dispatch({ type: FETCH_ALL_PAYMENT_SUCCESS, payload: { data: modifiedPaymentData, access: access } })
          // return modifiedPaymentData
        }
      }
      // return allPayments
    } catch (error) {
      console.log(error);
      return allPayments
    }
    // updatePaymentHelper(oldData, newData, allPayments, access, user).then(payments => {
    //   // console.log("before dispatch", payments)
    //   dispatch({ type: FETCH_ALL_PAYMENT_SUCCESS, payload: { data: payments, access: access } })
    // })
  }
}

export function fetchHistory(id, user) {
  return async (dispatch) => {
    let key = "bSFx2wcJ2MUnPVf"
    dispatch({ type: FETCH_PAYMENT_HISTORY_START, payload: {} });
    let response = await axios.post(`${API_BASE_URL}payment/historyV2/` + id, { user, key })
    console.log(response)
    dispatch({ type: FETCH_PAYMENT_HISTORY_SUCCESS, payload: response.data.history })
  }
}

export function getComments(user, id) {
  return async (dispatch) => {
    dispatch({ type: FETCH_PAYMENT_COMMENTS_START, payload: [] })
    let key = 'bSFx2wcJ2MUnPVf';
    let response = await axios.post(`${API_BASE_URL}payment/commentsV2/` + id, { key, user });
    dispatch({ type: FETCH_PAYMENT_COMMENTS_SUCCESS, payload: response.data.comments });
  }
}

export function createNewComment(user, id, newComment, previousComments) {
  return async (dispatch) => {
    console.log("100", previousComments)
    let allCommentsArr = []
    let comment = newComment
    let key = 'bSFx2wcJ2MUnPVf';
    let response = await axios.post(`${API_BASE_URL}payment/newCommentV2/` + id, { key, user, comment })
    console.log(response);
    if (response.data.data == "Comment Inserted") {
      let obj = {
        comment: comment,
        time: moment().valueOf(),
        user: user,
        employeeName: response.data.comment.employeeName
      }
      previousComments.unshift(obj);
      dispatch({ type: FETCH_PAYMENT_COMMENTS_SUCCESS, payload: previousComments })
    }
  }
}

export const editPaymentsInBulk = (allPayments, selectedIds, newValues, user, access) => {
  try {
    return async (dispatch) => {
      let key = "bSFx2wcJ2MUnPVf";
      let selectedPaymentIds = [];
      console.log(allPayments, selectedPaymentIds, newValues);
      Object.keys(selectedIds).map((val) => {
        if (selectedIds[val] == 1) {
          selectedPaymentIds.push(val)
        }
      });
      let response = await axios.post(`${API_BASE_URL}payment/bulkUpdateV2`, { newValues, selectedPaymentIds, key, user });
      console.log(response);
      if (response.data.success) {
        Object.keys(selectedIds).map((paymentId) => {
          let index = allPayments.findIndex((val) => (val.paymentKey) == paymentId);
          // console.log("index", index)
          Object.keys(newValues).map((field) => {
            allPayments[index][field] = newValues[field];
          })
        });
        swal({
          text: "Bulk Edit Successfull!!",
          buttons: {
            cancel: "Close"
          },
          icon: "success"
        });
        dispatch({ type: FETCH_ALL_PAYMENT_SUCCESS, payload: { data: allPayments, access: access } })
      }
    }
  } catch (e) {
    console.log(e)
  }
}
export function bulkUploadPayments(file, user) {
  return async (dispatch) => {
    try {
      // console.log('bulkUploadPayments');
      user = user ? user.phoneNumber : ''
      let formData = new FormData();
      formData.append("file", file);
      formData.append("user", user);
      formData.append("key", "bSFx2wcJ2MUnPVf");
      let response = await axios.post(`${API_BASE_URL}payment/bulkuploadV2`, formData);
      response = response.data || {};
      console.log(response);
      alert(response.message);
      return;
    }
    catch (error) {
      console.log(error);
    }
  }
}