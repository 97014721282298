import { FETCHING_FILE_START, FETCHING_FILE_SUCCESS } from '../actiontypes';
import { filesAllColumn } from '../../config/column_fields';

import { base } from '../../connection';
import { dateChanger } from '../../helper/dateChanger';


//////////////////////
/// FETCHING STEPS ///
//////////////////////

/*
	# Fetch all sites ids from mapping
	# Get quoteId and size from 'delivery/sites'
	# Get name from users
	# Get file data from fileManagement
	# Get state, size and subsidy
	# Send all reponse to single function and check if all visited
	# Create Object by combining all the data
*/

let collectiveData = {
	loading: true,
	tablename: 'Loading...',
	column: [],
	data: [],
	pagesize: 20,
	pagination: true
}

let subTypeMap = {};
let stageList = {};
let total = -1;
let dispatchValue = null;

const INITIAL_STRUCTURE = {
	stage: null,
	paymentPercentage: 0,
	paymentLastDate: 0,
	state: null,
	city : null,
	subsidy: null,
	vendor: null,
	ebissueList: [],
	totalPayment: 0,
	totalBill: 0,
	finalPricePresent: false,
	userVisited: false,
	ebissuePresent: false,
	quoteid: null,
	user: null,
	size: null,
	ebissue: null,
	deliverySiteVisited: false,
	siteVisited: false,
	deliveryIssueVisited: false,
	deliveryWebPortalVisited: false,
	deliveryFilesVisited: false,

}


export function fetchfileviews() {
	console.log(new Date());
	return (dispatch) => {
		collectiveData.loading = true;
		collectiveData.tablename = "Loading...";
		dispatch({ type: FETCHING_FILE_START, payload: collectiveData });
		dispatchValue = dispatch;
		fetchStageList();
	}
}


//FETCH STAGELIST
function fetchStageList() {
	base.fetch('/delivery/stagelist', {})
		.then(data => {
			data.forEach(val => {
				stageList["" + val.id] = val.name;
			})
			fetchFileTypeList();
		})
		.catch(error => {
			console.log("ERROR: ", error);
		})
}

//FETCH STAGELIST
function fetchFileTypeList() {
	base.fetch(`delivery/webPortal/fileManagement/delivery/config/typesList`, {})
		.then(data => {
			Object.keys(data).forEach(val => {
				subTypeMap["" + val] = {};
				let subData = data["" + val];
				Object.keys(subData).forEach(type => {
					subTypeMap["" + val]["" + type] = {};
					subTypeMap["" + val]["" + type] = subData["" + type].name;
					INITIAL_STRUCTURE["" + subData["" + type].name] = 0;
				});
			})
			fetchSiteIdList();
		})
		.catch(error => {
			console.log("ERROR: ", error);
		})
}


//FETCH SITE ID LIST
function fetchSiteIdList() {
	base.fetch('/delivery/webPortal/deliverySiteMapping', {})
		.then(data => {
			total = Object.keys(data).length;
			Object.keys(data).forEach(key => {
				let initData = { ...{}, ...INITIAL_STRUCTURE };
				fetchDeliverySiteId(key, initData);
				fetchSiteId(key, initData);
				// fetchNotificationIdList(key,initData);
				fetchWebPortalId(key, initData);
				fetchEbIssueId(key, initData);
				fetchFiles(data["" + key], initData);
			})
		})
		.catch(error => {
			console.log("ERROR: ", error);
		})
}

// FETCHING DELIVERY SITE DATA
function fetchDeliverySiteId(id, initData) {
	base.listenTo('delivery/sites/' + id, {
		context: {},
		asArray: false,
		then(data) {
			let { extraPriceLineItem, paymentHistory, stage, vendor, siteOwnerId, quoteId } = data; //DESTRUCTURING DATA
			fetchUserInformation(siteOwnerId, initData);
			// OTT PRICE ADDING IN TOTAL DATA
			if (extraPriceLineItem) {
				Object.values(extraPriceLineItem).forEach(val => {
					initData.totalBill += val.price ? parseInt(val.price) : 0;
				})
			}

			// TOTAL PAYMENT
			if (paymentHistory) {
				Object.values(paymentHistory).forEach((val, index) => {
					initData.totalPayment += parseInt(val.amountPaid);
					if (index === Object.values(paymentHistory).length - 1) {
						initData.paymentLastDate = val.paidDate.time;
					}
				})
			}

			// STAGE VARIABLES
			if (stage) {
				initData.stage = stage[stage.length - 1].current_State;
				initData.refDate = stage[stage.length - 1].ref_Date ? stage[stage.length - 1].ref_Date === "NA" ? 0 : dateChanger(stage[stage.length - 1].ref_Date) : 0;
				initData.salesDate = stage[stage.length - 1].sales_Date ? stage[stage.length - 1].sales_Date === "NA" ? 0 : dateChanger(stage[stage.length - 1].sales_Date) : 0;
			}

			// VENDOR
			initData.vendor = vendor ? vendor : "";
			//QUOTE ID
			initData.quoteid = quoteId ? quoteId : "";

			//VISITED DELIVERY
			initData.deliverySiteVisited = true;
			checkAndDispatch(initData);
		}
	})
}


// FETCHING USER INFORMATION
function fetchUserInformation(id, initData) {
	base.fetch('/users/' + id + '/mUser_Contact', {})
		.then(data => {
			if (data) {
				if (data.mContact_FirstName) {
					initData.user = data.mContact_FirstName + " " + data.mContact_LastName;
				}
				initData.city = data.mContact_City || ""
			}
			// VISITED USER
			initData.userVisited = true

			checkAndDispatch(initData);
		})
}

// FETCHING FILES INFORMATION
function fetchFiles(id, initData) {
	base.fetch(`delivery/webPortal/fileManagement/delivery/viewableFiles/${id}`, {})
		.then(data => {
			//debugger
			Object.keys(data).forEach(key => {
				let { folderType, type, name, metaData } = data["" + key];
				let { createdOn } = metaData;
				let typeName = subTypeMap["" + folderType]["" + type];
				if (folderType == 'Client Feedback' && typeName != 'Google Review') {
					typeName = "Feedback " + typeName;
				}
				initData["" + typeName] = `${id}__${key}__${name}__${createdOn}`;
			});
			// VISITED FILES
			initData.deliveryFilesVisited = true;
			checkAndDispatch(initData);
		})
}

// FETCHING SITES DATA
function fetchSiteId(id, initData) {
	base.listenTo('sites/' + id, {
		context: {},
		asArray: false,
		then(data) {
			let { mSite_finalPrice, mSite_State, mSite_selectedSize, mSite_totalSize } = data;

			// FINAL PRICE ADDING IN TOTAL DATA
			if (mSite_finalPrice) {
				initData.finalPricePresent = true;
				initData.totalBill += parseFloat(mSite_finalPrice);
			}
			// STATE
			initData.state = initData.state ? initData.state : mSite_State;

			//SIZE
			initData.size = mSite_totalSize?parseFloat(mSite_totalSize):parseFloat(mSite_selectedSize);

			//VISITED SITE
			initData.siteVisited = true;
			checkAndDispatch(initData);
		}
	})
}


// FETCHING NOTIFICATIONS LIST
// function fetchNotificationIdList(id,initData) {
// 	base.listenTo('sites/'+id+'/mSite_notificationIds', {
// 		context:{},
// 		asArray:true,
// 		then(data) {
// 			if(data.length>0) {
// 				//FETCHING LAST NOTICATION
// 				let latestNotification = data[data.length-1];
// 				// fetchNotificationId(latestNotification,initData)
// 			}else{
// 				initData.deliveryNotificationVisited = true;
// 				checkAndDispatch(initData);
// 			}
// 		}
// 	})
// }


//FETCIHNG NOTIFICATION DATA
// function fetchNotificationId(id,initData) {
// 	base.fetch('/notifications/'+id, {}).
// 	then(data => {
// 		if(data) {
// 			if(data.sendDate) {
// 				if(data.sendDate.time) {
// 					//LAST NOTIFICATION SEND DATE
// 					initData.notificationLastDate = data.sendDate.time;
// 				}
// 			}
// 		}
// 		//VISITED SITE
// 		initData.deliveryNotificationVisited = true;
// 		checkAndDispatch(initData);
// 	}).
// 	catch(error => {
// 		console.log("ERROR: ",error);
// 	})
// }


//FETCHING WEB-PORTAL DATA
function fetchWebPortalId(id, initData) {
	base.listenTo('delivery/webPortal/sites/' + id, {
		context: {},
		asArray: false,
		then(data) {
			let { state_D, subsidy_D } = data;
			initData.state = state_D ? state_D : initData.state;	// STATE
			initData.subsidy = subsidy_D ? subsidy_D : "";			// SUBSIDY

			//VISITED SITE
			initData.deliveryWebPortalVisited = true;
			checkAndDispatch(initData);
		}
	})
}


//FETCHING EBISSUE DATA
function fetchEbIssueId(id, initData) {
	base.listenTo('delivery/Issue/statusbysite/' + id + '/siteissue', {
		context: {},
		asArray: true,
		then(data) {
			if (data.length > 0) {
				initData.ebissueList = data ? data : [];
				data.forEach(val => {
					if (val === 1 || val === 2 || val === 3 || val === 7)
						initData.ebissuePresent = true;
				})
			}

			//VISITED WEB PORTAL
			initData.deliveryIssueVisited = true;
			checkAndDispatch(initData);
		}
	})
}

// COLLECT ALL DATA AND DISPATCH AFTER COMPLETION CHECK
function checkAndDispatch(initData) {
	let { totalPayment, totalBill, finalPricePresent, userVisited, deliverySiteVisited, siteVisited, deliveryIssueVisited, deliveryWebPortalVisited, deliveryFilesVisited } = initData;

	if (deliveryFilesVisited && deliverySiteVisited && deliveryIssueVisited && deliveryWebPortalVisited && siteVisited && userVisited) {
		if (finalPricePresent) {
			initData.paymentPercentage = ((totalPayment * 100) / totalBill);
		}

		collectiveData.data.push(initData);

		if (Object.keys(collectiveData.data).length === total) {
			// let updatedData = Object.values(tableData);
			collectiveData.loading = false;
			collectiveData.tablename = 'Files View';
			dispatchValue({ type: FETCHING_FILE_SUCCESS, payload: { filesData: collectiveData.data, columns: filesAllColumn } });
		}
	}
}