import { PUSH_COMMENT_SUCCESS } from './actiontypes';

import { base } from '../connection';

import { fetch, set, push, fetchOrderByChild, fetchOrderByKey, fetchOrderByValue } from '../helper/firebaseFunctions';


export function setcitydata(user,city) {
	console.log(new Date());
	return (dispatch) => {
		fetchUserComment(user,city);
	}
}

function fetchUserComment(user,city) {
	let userphone = user;

	set(`users/${user}/mUser_Contact/mContact_City`,city)
	.then(data => {
		console.log("DONE");
	})
	.catch(error=>{
		console.log(error);
	})
}