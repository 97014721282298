import { FETCHING_MAIL_START, FETCHING_MAIL_SUCCESS } from '../actions/actiontypes'

let INIT_STATE = {
	cc: null,
	date: null,
	fromName: null,
	mailBody: null,
	mailText: null,
	subject: null,
	to: null,
	mailId: null
}

export default function(state = [], action) {
	switch(action.type){
		case FETCHING_MAIL_START:{
			return INIT_STATE;
		};

		case FETCHING_MAIL_SUCCESS:{
			return {...INIT_STATE, ...action.payload};
		}

		default:
			return state;
	}
}