import { base } from '../../connection';

import { OUTSTANDING_ORDERS_GRAPH } from '../actiontypes';

const INITSTAGEHISTORY = {};

let total = 0;

export function outstandingordersgraph() {
	return (dispatch) => {
		base.fetch('/delivery/webPortal/salesHistoryDate',{})
		.then(data => {
			total = Object.keys(data).length;
			Object.keys(data).forEach(val => {
				INITSTAGEHISTORY[""+val] = {};
				fetchDateHistory(dispatch,val)
			})
		})
		.catch(err => {
			console.log(err);
		})
	}
}

function fetchDateHistory(dispatch,date) {
	base.fetch('/delivery/stagePerDayHistory/'+date+'/stageCount',{})
	.then(data => {
		let dataSet = INITSTAGEHISTORY[""+date];
		Object.keys(data).forEach(val => {
			let { siteListsLessThanConditionCount, siteListsMoreThanConditionCount, count } = data[""+val];
			dataSet[""+val] = {
				actualCount:count,
				expectedCount:parseInt(siteListsLessThanConditionCount)+(parseInt(siteListsMoreThanConditionCount)*0.2)
			}
		});
		dispatcher(dispatch);
	})
	.catch(err => {
		console.log(err);
	})
}

function dispatcher(dispatch) {
	if(Object.keys(INITSTAGEHISTORY).length === total){
		dispatch({type:OUTSTANDING_ORDERS_GRAPH,payload:INITSTAGEHISTORY});
	}
}