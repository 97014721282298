import { FETCH_TICKETREPORT_SUCCESS, FETCH_HISTORY_SUCCESS } from './actiontypes';
import moment from 'moment';
import axios from 'axios';

import { base, db } from '../connection';

export function fetchTicketReport() {
    console.log("start");
    return (dispatch) => {
        let formdata = new URLSearchParams();
		formdata.append('key', 'g6muc4cFoPVVZvs');
		// axios.post("http://localhost:3002/api/ticketingReport/fetch", formdata, {
		axios.post("https://operations.zunroof.com/api/ticketingReport/fetch", formdata, {
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
		})
        .then(data => {
            console.log("naman", data.data);
            dispatch({ type: FETCH_TICKETREPORT_SUCCESS, payload: data.data })
        })
    }
}

export function fetchTicketHistory(id){
    return (dispatch) => {
        let formdata = new URLSearchParams();
		formdata.append('key', 'g6muc4cFoPVVZvs');
		formdata.append('id', id);
		// axios.post("http://localhost:3002/api/ticketingReport/fetch/history", formdata, {
		axios.post("https://operations.zunroof.com/api/ticketingReport/fetch/history", formdata, {
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
		})
        .then(data => {
            console.log(data);
            dispatch({type:FETCH_HISTORY_SUCCESS, payload:data.data.data})
        })
    }
}