import axios from "axios"
import { actions } from "react-table";
import swal from "sweetalert";
import { FETCH_CITIES, FETCH_CENTERS_SUCCESS } from './actiontypes'

export function addNewCenter(centerData, user, oldTableData) {
    return async (dispatch) => {
        centerData.user = user;
        let key = "IG3BX8aCz5R";
        // console.log("user from action", user)
        try {
            let response = await axios.post(`https://operations.zunroof.com/api/peCenters/addnewcenter`, { centerData, key, user });
            console.log(response);
            if (response.data.error) {
                swal(response.data.error);
            } else {
                swal({
                    text: 'Center Added',
                    icon: 'success',
                    buttons: {
                        cancel: 'close',
                    },
                });
                oldTableData.push(centerData)
                let modified = [...oldTableData]
                dispatch({ type: FETCH_CENTERS_SUCCESS, payload: { tableData: modified } })
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export function fetchAllCenters(user) {
    return async (dispatch) => {
        try {
            let key = "IG3BX8aCz5R";
            console.log("claee")
            let response = await axios.post(`https://operations.zunroof.com/api/peCenters/all`, { user, key });
            console.log(response);
            if (response.data.error) {
                swal(response.data.error)
            } else {
                dispatch({ type: FETCH_CENTERS_SUCCESS, payload: { tableData: response.data.data } })

            }
        } catch (e) {
            console.log(e)
        }
    }
}

export function fetchCities(user) {
    return async (dispatch) => {
        try {
            let key = "IG3BX8aCz5R";
            user = user.phoneNumber || ""
            let response = await axios.post(`https://operations.zunroof.com/api/peCenters/cities`, { user, key });
            // console.log(response);
            if (response.data.error) {
                swal(response.data.error)
            } else {
                dispatch({ type: FETCH_CITIES, payload: { stateToCityMap: response.data.stateToCityMap } });

            }
        } catch (e) {
            console.log(e)
        }
    }
}

export function updateCenter(user, oldData, newData, tableData) {
    return async (dispatch) => {
        try {
            let id = oldData.key;
            let data = {};
            let key = "IG3BX8aCz5R";
            Object.keys(newData).map((val) => {
                if (newData[val] != oldData[val]) {
                    data[val] = newData[val];
                }
            })
            let response = await axios.post(`https://operations.zunroof.com/api/peCenters/update/` + id, { data, key, user });
            console.log(response);
            if (response.data.error) {
                swal(response.data.error);
            } else {
                swal({
                    text: 'Center Updated',
                    icon: 'success',
                    buttons: {
                        cancel: 'close',
                    },
                });
                let modifiedCenters = [...tableData];
                tableData.map((val, index) => {
                    if (val.key == id) {
                        modifiedCenters[index] = newData;
                    }
                });
                dispatch({ type: FETCH_CENTERS_SUCCESS, payload: { tableData: modifiedCenters } })
            }
        } catch (e) {
            console.log(e)
        }
    }
};

export function deleteCenter(id, user, tableData) {
    return async (dispatch) => {
        try {
            let key = "IG3BX8aCz5R";
            // console.log("key", key)
            let response = await axios.post(`https://operations.zunroof.com/api/peCenters/delete/${id}`, { key, user });
            console.log(response);
            if (response.data.error) {
                swal(response.data.error)
            } else {
                swal({
                    text: 'Center Deleted',
                    icon: 'success',
                    buttons: {
                        cancel: 'close',
                    },
                });
                let modifiedData = [...tableData];
                tableData.map((obj, index) => {
                    if (obj.key == id) {
                        modifiedData.splice(index, 1)
                    }
                });
                dispatch({ type: FETCH_CENTERS_SUCCESS, payload: { tableData: modifiedData } })
            }
        } catch (e) {
            console.log(e)
        }
    }
}