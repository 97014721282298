import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';
import { changeToViewDateTime, changeToInputDate, changeToViewDate } from '../helper/dateChanger';
import { Restrict } from '../components/Restrict';
import { columnsEscalationView } from '../config/column_fields';
import Modal from 'react-responsive-modal';
import { fetchEscalations } from '../actions/fetchEscalations';
import { changeEscalationStatus } from '../actions/changeEscalationStatus';
class EscalationView extends Component {


    constructor(props) {
        super(props);
        this.state = {
            showColumns:[...columnsEscalationView,{
                Header: 'Status',
                accessor: 'status',
                Cell:row => {
                    if(row.original.status){
                        return(<button className="btn" onClick={() => this.changeStatus(row.index, row.original.quoteId, row.original.type, 0)}>Active</button>);
                    }
                    else{
                        return(<button className="btn" onClick={() => this.changeStatus(row.index, row.original.quoteId, row.original.type, 1)}>Inactive</button>);
                    }
                }
            }],
            data:[],
            viewData:[]
        };
    }

    componentDidMount() {
        console.log(this.props.user);
        if (this.props.user) {
            if (this.props.user.level >= 2300) {
                console.log("Component Did Mount");
                this.props.fetchEscalations();
            }
        }
    }



    componentWillReceiveProps(props) {
        //console.log("Component will receive props");
        console.log(props.escalation);
        this.setState({
            viewData:props.escalation
        })
        
    }

    changeStatus = (index, quoteId, type, status) => {
        console.log(index, status);
        this.props.changeEscalationStatus(quoteId, type, status);
        let data = [...this.state.viewData];
        data[index].status = status;
        this.setState({
            viewData: data
        })
    }

    render() {
       
        if (this.props.user === null) {
            return <Redirect to={"/login?redirect_uri=escalations"} />;
        }

        if (this.props.user && this.props.user.level < 2300) {
            return (
                <div>
                    <Restrict />
                </div>
            )
        }

        return (
            <div style={{ "marginTop": 50 }} className="container-fluid">
                <ReactTable
                    filterable={true}
                    showPagination={true}
                    sortable={true}
                    data={this.state.viewData}
                    columns={this.state.showColumns}
                    defaultSorted={[{ id: "date", desc: true }]}
                    style={{ height: "90vh", textAlign: 'center' }}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={this.state.loading}
                />
            </div>
        );


    }
}


function mapStateToProps(state) {
    return {
        user: state.user,
        escalation: state.fetchescalations.data 
        //currentCallData: state.currentcall.data
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchEscalations, changeEscalationStatus }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(EscalationView);