import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import TextField from '@material-ui/core/TextField';
import { fetchEmailsAnalytics, fetchClientAnalytics, searchData } from '../actions/fetchEmailsAnalytics';
import { setemailstatus } from '../actions/setemailstatus';

import Select from 'react-select';
import { changeToViewDateTime, changeToInputDate, changeToViewDate } from '../helper/dateChanger';
import { Restrict } from '../components/Restrict';
import { columnsComplaint } from '../config/column_fields';
import { fetchComplaints } from '../actions/fetchComplaints';
import Modal from 'react-responsive-modal';
import { updateComplaint } from '../actions/updateComplaint';
import { createComplaint } from '../actions/createComplaint';


class Complaint extends Component {


	constructor(props) {
		super(props);

		this.state = {
			data: [],
			columns: [],
			phoneData: [],
			phonecolumns: [],
			openModal: {},
			completed: false,
			newForm: {
				status: '',
				text: '',
				internalComment:'',
				dueDate:'',
				removed:'',
				assignedTo:'',
				responsibleTeam:'',
				actions:{}
			},
			currentTicket: '',
			showColumns: [...columnsComplaint, {
				Header:'Assigned to',
				accessor:'assignedTo',
				Cell:row => {
						if(row.original.assignedTo){
							return(<div>{this.state.data.employeeList[row.original.assignedTo]}</div>)
						}
						else{
							if(row.original.city){
								let complaintCity = row.original.city.toLowerCase();
								let responsibleId = '';
								let responsibleTeam = '';
								if(this.cityArray1.includes(complaintCity)){
									responsibleId = '+918448389958';
    								responsibleTeam = 'UP';
								}
								else if(this.cityArray2.includes(complaintCity)){
									responsibleId = '+918448389957';
    								responsibleTeam = 'Punjab';
								}
								else if(this.cityArray3.includes(complaintCity)){
									responsibleId = '+917827944128';
									responsibleTeam = 'Bangalore';
								}
								else if(this.cityArray4.includes(complaintCity)){
									responsibleId = '+919205695692';
									responsibleTeam = 'Delhi';
								}
								else{
									responsibleId = '+918448389957';
    								responsibleTeam = 'Punjab';
								}
								row.original.assignedTo = responsibleId;
								row.original.responsibleTeam = responsibleTeam;
								return(<div>{this.state.data.employeeList[responsibleId]}</div>)
							}
							else{
								return(<div></div>)
							}
						}
					}	
				},
				{
					Header:'Overdue',
					accessor:'overdue',
					Cell:row => (
							<div>{(row.original.dueDate<new Date().getTime()&&row.original.status!='Resolved')?'Overdue':' '}</div>
						)
				},
				{
				Header: 'Edit',
				accessor:'editColumn',
				Cell: row => {
					const options = [
						{ value: 'Initiated', label: 'Initiated' },
						{ value: 'In Progress', label: 'In Progress' },
						{ value: 'Resolved', label:'Resolved'}
					  ];
					const statusObj = {
						value : row.original.status,
						label : row.original.status
					}
					let { status, text, internalComment, dueDate, assignedTo, removed, responsibleTeam, actions } = this.state.newForm;
					const style = {
						hide : {
							display:'none'
						},
						show : {
							display:''
						}
					}
					let displayActions = {};
					Object.values(actions).forEach(obj => {
						if(obj.internalComment){
							displayActions[obj.internalComment] = {
								updatedBy:this.state.data.employeeList[obj.updatedBy+""],
								time:changeToViewDate(obj.updateTime)
							};
						}
					})
					console.log(displayActions);
					// let comments = '';
					// Object.keys(displayActions).forEach(key=>{
					// 	comments = comments + key + "-" + displayActions[key] + "<br/>";
					// })
					//dueDate = changeToInputDate(dueDate);
					return (
						<div>
							<button className="btn btn-success" onClick={this.onOpenModal.bind(this, row.original.ticketId, statusObj, row.original.text, row.original.internalComment, row.original.dueDate, row.original.assignedTo, row.original.removed, row.original.responsibleTeam, row.original.actions)}>Edit <i className="fa fa-edit"></i></button>
							<Modal open={this.state.openModal[row.original.ticketId]} onClose={this.onCloseModal.bind(this, row.original.ticketId)}>
								<h2>Ticket Id - {row.original.ticketId}</h2>
								<p></p>
								<h5>Issue - {row.original.type} - {row.original.subType}</h5>
								<p></p>
								<h6>Comment - {row.original.comment}</h6>
								<p></p>
								<form onSubmit={this.handleUpdateClick} className="form-control">
									<Select
										value={status}
										onChange={this.handleStatusChange}
										options={options}
									/>
									<div className="form-group">
										<label>User Comment</label>
										<textarea rows="2" className="form-control" type="text" value={text} onChange={this.handleTextChange} required/>
									</div>
									<div className="form-group">
									<label>INTERNAL COMMENTS:</label>
									<div className="comments" style={{width: '100%',height: '180px',overflow: 'auto'}}>
									{
										Object.keys(displayActions).reverse().map((v,i)=>{
												//console.log(key+" - "+displayActions[key]);
												 return(<div className="commentSection1111">{displayActions[v].time+" - "+v+" - "+displayActions[v].updatedBy}<br/></div>)
										})
									}
									</div>
									</div>
									<div className="form-group">
										<label>Internal Comment</label>
										<textarea rows="5" className="form-control" type="text" value={internalComment} onChange={this.handleCommentChange} required />
									</div>
									<div className="form-group">
										<label>Due Date</label>
										<input type="date" name="dueDate" onChange={this.handleEditFormChange} value={dueDate}/>
									</div>
									<div className="form-group">
										<label>Removed</label>
										<select className="form-control" onChange={this.handleEditFormChange} name="removed" value={removed}>
											<option key="0" value="0">No</option>
											<option key="1" value="1">Yes</option>
											<option key="2" value="2">NA</option>
										</select>
									</div>
									<div className="form-group" style={this.captainList.includes(this.props.user.phoneNumber)?style.show:style.hide}>
										<label>Assigned To</label>
										<select className="form-control" onChange={this.handleEditFormChange} name="responsibleTeam" value={responsibleTeam}>
										<option key="-1" value="-1">---Select Team---</option>
											{	
												Object.keys(this.state.data.teamEmployeeMap?this.state.data.teamEmployeeMap:{}).map((v,i) => {
													//console.log(i,v);
												return(<option key={i} value={v}>{v}</option>)
												})
											}
										</select>
										<select className="form-control" onChange={this.handleEditFormChange} name="assignedTo" value={assignedTo}>
										<option key="-1" value="-1">--Select Responsible---</option>
											{	
												(this.state.responsibleOptionsEdit?this.state.responsibleOptionsEdit:[]).map((v,i) => {
													//console.log(i,v);
												return(<option key={i} value={v}>{this.state.data.employeeList[v+""]}</option>)
												})
											}
										</select>
									</div>
									<div className="form-group">
										<button type="submit" className="btn btn-success">Update</button>
									</div>
								</form>

							</Modal>
						</div>
					)
				}
			}
			],
			showData: [],
			startDate: "",
			endDate: "",
			statusChoose: -1,
			choice: 0,
			edit: {},
			loading: true,
			lastDate: "Fetching...",
			clicked: false,
			openCreateModal:false,
			createComplaintForm : {
				quoteId : '',
				type:'',
				subType:'',
				description:'',
				assignedTo:'',
				source:'',
				responsibleTeam:''
			},
			subTypeOptions:[],
			responsibleOptions:[],
			responsibleOptionsEdit:[],
			statusChoosen:[],
			sourceChoosen:[],
			assignedChoosen:[],
			cityList:[],
			cityChoosen:[]
		}

		this.typeOptions = {
			"Site Assessment":['Visit Not Scheduled', 'Engineer didn\'t show up', 'Other' ],
			"App Related":['App Not Working', 'Other'],
			"Remote Monitoring":['Not Installed', 'Not Working','Other' ],
			"Installation":['Material Not Arrived', 'Installation incomplete', 'Quality Related', 'Invoice and payment', 'Other' ],
			"Maintainance":['System not working', 'Need Maintenance Visit', 'Other' ],
			"Net Metering":['Other'],
			"Project Delay":['Other']
		}

		this.sourceOptions = ["Facebook","Twitter","Youtube","Google","Call","Other"];
		this.sourceOptionsFilter = ["Facebook","Twitter","Youtube","Google","Call","website","Other"];
		this.captainList = ['+919205695692','+918448389958','+917011352085','+919650073462','+918448389957','+917827944128'];
		this.statusTextMap = {
			"Initiated":"Thank for reaching out to us. Our customer support team has initiated the process of resolving your order issue.",
			"In Progress":"Your complaint has moved to 'In-progress' Stage. We'll soon share an update on the same.",
			"Resolved":"We are glad to inform that your complaint has been resolved successfully. Your Ticket is now closed."
		};
		this.cityArray1 = ["Noida","Greater Noida","Ghaziabad","Lucknow","Kanpur","Meerut","Agra","Varanasi","Allahabad","Ayodhya","Jhansi","Faizabad","Mathura","Saharanpur","Bareli","Aligarh","Moradabad"];
		this.cityArray2 = ["Chandigarh","Dera Bassi","Jalandhar","Ludhiana","Zirakpur","Mohali","Kharar","Amritsar","Patiala","Hoshiarpur","Bathinda","Barnala","Sangrur","Sonipat","Panchkula","Ambala","Panipat","Hisar","Sirsa","Rohtak","Bahadurgarh","Yamuna Nagar","Gurgaon","Faridabad","Rewari","","Indore","Jaipur","Jodhpur","Kota","Alwar","Udaipur","Bhiwadi","Ajmer","Ahmedabad","Gandhinagar","Vadodara","Surat"];
		this.cityArray3 = ["Chennai","Vellore","Coimbatore","Hyderabad","Kozhikode","Cochin","Bangalore","Mysore"];
		this.cityArray4 = ["Delhi"];
		this.cityArray1 = this.cityArray1.map(function(x){ return x.toLowerCase() });
		this.cityArray2 = this.cityArray2.map(function(x){ return x.toLowerCase() });
		this.cityArray3 = this.cityArray3.map(function(x){ return x.toLowerCase() });
		this.cityArray4 = this.cityArray4.map(function(x){ return x.toLowerCase() });
	}

	componentDidMount() {
		// console.log("DID MOUNT")
		if (this.props.user) {
			if (this.props.user.level >= 1600) {
				this.props.fetchComplaints();
				// this.props.fetchClientAnalytics();
			}
		}
	}

	onOpenModal(ticketId, status, text, internalComment, dueDate, assignedTo, removed, responsibleTeam, actions) {
		console.log( dueDate, assignedTo, removed, responsibleTeam, actions);
		let responsibleOptionsEdit = this.state.data.teamEmployeeMap[responsibleTeam];
		removed = removed?removed:0;
		dueDate = changeToInputDate(dueDate);
		//text = this.statusTextMap[status.value+""];
		this.setState({
			openModal: {
				[ticketId]: true
			},
			currentTicket: ticketId,
			responsibleOptionsEdit:responsibleOptionsEdit,
			newForm: {
				status,
				text,
				internalComment,
				dueDate,
				assignedTo,
				removed,
				responsibleTeam,
				actions
			}
		});
	};

	onCloseModal(ticketId) {
		this.setState({
			openModal: {
				[ticketId]: false
			},
			currentTicket: ''
		});
	};


	componentWillReceiveProps(props) {
		const { data, completed, newComplaintData } = props;
		console.log(data);
		let viewData = data['complaintData'];
		if(data && data!=this.props.data){
			console.log("componentWillReceiveProps Data");
			let cityList ={};
			viewData.forEach(obj => {
				cityList[obj.city] = 1;
			});
			this.setState({
				data: data,
				viewData:viewData,
				loading : false,
				cityList:cityList
			})
		}
		if(newComplaintData && newComplaintData!=this.props.newComplaintData){
			console.log("componentWillReceiveProps New complaint");
			console.log(newComplaintData);
			this.setState({
				openCreateModal : false
			})
			//this.props.fetchComplaints();
			let newData = {...this.state.data,...{}};
			console.log("New Data",newData);
			//newData.complaintData.push(newComplaintData);
			//newData['complaintData'] =  [...newData['complaintData'], newComplaintData];
			console.log("New Data",newData);
			this.setState(oldState => ({
				data : {
					...oldState.data,
					complaintData:[...newData['complaintData'], newComplaintData]
				},
				viewData:[...viewData, newComplaintData]
			}));
		}
		if(updateComplaint){
			console.log("UPDATE COMPLAINT PROPS");
		}
	}

	handleStatusChange = (e) => {
		console.log("status-e",e);
		let statusChanged = e;
		let text = this.statusTextMap[statusChanged.value+""];
		this.setState(prevState => ({
			newForm: {
				...prevState.newForm,
				status: statusChanged,
				text:text
			}
		}));
		console.log(this.state.data)
		console.log(e);
	};

	handleTextChange = (e) => {
		console.log("text-e",e);
		let text = e.target.value;
		this.setState(prevState => ({
			newForm: {
				...prevState.newForm,
				text: text
			}
		}))
	};

	handleCommentChange = (e) => {
		console.log("comment-e",e);
		let comment = e.target.value;
		this.setState(prevState => ({
			newForm: {
				...prevState.newForm,
				internalComment: comment
			}
		}))
	};

	handleUpdateClick = (e) => {
		console.log("update-e",e);
		e.preventDefault();
		console.log(this.state.currentTicket);
		console.log(this.state.newForm.status);
		console.log(this.state.newForm.text);
		console.log(this.state.newForm.dueDate);
		console.log(this.state.newForm.assignedTo);
		console.log(this.state.newForm.removed);
		console.log(this.state.newForm.responsibleTeam);
		let editedTicket = this.state.currentTicket;
		let newData = {...this.state.data,...{}};
		// let ticketIndex = data.find(function (item, i) {
		// 	if (item.ticketId === editedTicket) {
		// 		index = i;
		// 		return i;
		// 	}
		// });
		let ticketIndex = newData['complaintData'].findIndex(obj => obj.ticketId == editedTicket);
		console.log(ticketIndex);
		console.log(this.state.data);
		newData['complaintData'][ticketIndex].status = this.state.newForm.status.value;
		newData['complaintData'][ticketIndex].text = this.state.newForm.text;
		console.log("NewData", newData[ticketIndex]);
		this.props.updateComplaint(editedTicket, this.state.newForm.status.value, this.state.newForm.text, this.state.newForm.internalComment, this.props.user.phoneNumber, this.state.newForm.dueDate, this.state.newForm.assignedTo, this.state.newForm.removed, this.state.newForm.responsibleTeam, this.state.data['complaintData'][ticketIndex]);
		this.props.fetchComplaints();
		console.log(this.state.data);
		this.setState({
			openModal: {
				[editedTicket]: false
			},
			currentTicket: '',
			data: newData
		});
		console.log(this.state.data);
		//this.props.data = newData;
	}

	createComplaint = (e) => {
		console.log("Create complaint clicked");
		this.setState({
			openCreateModal:true
		});
	}

	onCloseCreateModal = (e) => {
		console.log("Close clicked");
		this.setState({
			openCreateModal:false
		});
	}


	handleSubmitCreateComplaint = (e) => {
		e.preventDefault();
		console.log("Submit clicked");
		console.log("FORMDATA", this.state.createComplaintForm);
		if(this.state.createComplaintForm.quoteId.length>=8 && this.state.createComplaintForm.type && this.state.createComplaintForm.type!=-1 && this.state.createComplaintForm.subType && this.state.createComplaintForm.subType!=-1 && this.state.createComplaintForm.description && this.state.createComplaintForm.assignedTo && this.state.createComplaintForm.assignedTo!=-1 && this.state.createComplaintForm.source && this.state.createComplaintForm.source!=-1 && this.state.createComplaintForm.responsibleTeam && this.state.createComplaintForm.responsibleTeam!=-1){
			this.props.createComplaint(this.state.createComplaintForm, this.props.user.phoneNumber);
		}
		else{
			alert('Incomplete Data');
		}
	}

	handleComplaintFormChange = (e) =>{
		console.log("Form chnage");
		console.log(e.target.name);
		console.log(e.target.value);
		let name = e.target.name;
		let value = e.target.value;
		if(e.target.name == 'type'){
			if(value!=-1){
			this.setState({
				subTypeOptions:this.typeOptions[e.target.value]
			})
			}
			else{
				this.setState({
					subTypeOptions:[]
				})
			}
		}
		if(e.target.name=='responsibleTeam'){
			if(value!=-1){
				this.setState({
					responsibleOptions : this.state.data.teamEmployeeMap[value]
				})
			}
			else{
				this.setState({
					responsibleOptions : []
				})
			}
		}
		this.setState(prevState => ({
			createComplaintForm: {
				...prevState.createComplaintForm,
				[name] : value
			}
		}))
	}

	handleEditFormChange = (e) =>{
		console.log(e.target.name,e.target.value);
		let name = e.target.name;
		let value = e.target.value;
		if(e.target.name=='responsibleTeam'){
			this.setState({
				responsibleOptionsEdit : this.state.data.teamEmployeeMap[value]
			})
		}
		this.setState(prevState => ({
			newForm: {
				...prevState.newForm,
				[name] : value
			}
		}));
	}

	applyFilter = (e) =>{
		let complaintData = this.state.data.complaintData;
		let statusArray = this.state.statusChoosen.map(val => val.value);
		let sourceArray = this.state.sourceChoosen.map(val => val.value);
		let assignedArray = this.state.assignedChoosen.map(val => val.value);
		let cityArray = this.state.cityChoosen.map(val => val.value);
		let startEpoch = 0;
		let endEpoch = 9999999999999;
		if(this.state.startDate && this.state.startDate!=''){
			let startDateArray = this.state.startDate.split("-");
			startEpoch = new Date(startDateArray[1]+"-"+startDateArray[2]+"-"+startDateArray[0]).getTime();
		}
		if(this.state.endDate && this.state.endDate!=''){
			let endDateArray = this.state.endDate.split("-");
			endEpoch = new Date(endDateArray[1]+"-"+endDateArray[2]+"-"+endDateArray[0]).getTime();
		}
		console.log(statusArray);
		let newData = complaintData.filter(obj => {
			console.log(obj.dueDate);
			let curDate = obj.dueDate?obj.dueDate:1;
			return((statusArray.length>0?statusArray.includes(obj.status):true)&&(sourceArray.length>0?sourceArray.includes(obj.source):true)&&(assignedArray.length>0?assignedArray.includes(obj.assignedTo):true)&&(cityArray.length>0?cityArray.includes(obj.city):true));
		});
		//let newData = complaintData.filter(obj => obj.ticketId=='1562335459APR19001');
		this.setState(oldState => ({
			viewData:newData
		}))
	}
	handleMultiSelectChange = (selectedOption, name) => {
		console.log(selectedOption, name);
		this.setState({
			[name] : selectedOption
		});
		
		// switch(name) {
		// 	case "stateChange":
		// 		return this.setState({stateChoosen:selectedOption});
		// 	case "subsidyChange":
		// 		return this.setState({subsidyChoosen:selectedOption});
		// 	case "stageChange":
		// 		return this.setState({stageChoosen:selectedOption});
		// 	case "v1Change":
		// 		return this.setState({vendorChoosen:selectedOption});
		// 	case "ebIssueChange":
		// 		return this.setState({ebissueChoosen:selectedOption});
		// 	default:
		// 		return;
		// }
	}
	handleDateChange = (e) =>{
		console.log(e.target.name, e.target.value);
		let name = e.target.name;
		let value = e.target.value;
		this.setState({
			[name] : value
		});
	}

	render() {
		console.log(this.props.user);
		const style = {
			hide: {
				display: 'none'
			},
			show: {
				display: ''
			}
		}

		if (this.props.user === null) {
			return <Redirect to={"/login?redirect_uri=complaint"} />;
		}

		if (this.props.user && this.props.user.level < 1600) {
			return (
				<div>
					<Restrict />
				</div>
			)
		}
		const options = [
			{ value: 'Initiated', label: 'Initiated' },
			{ value: 'In Progress', label: 'In Progress' },
			{ value: 'Resolved', label:'Resolved'}
		];
		const sourceSelectOptions = this.sourceOptionsFilter.map(val=>{
			//console.log(val);
			return(
				{
					value:val,
					label:val
				}
			)
		});
		const assignedToOptions = (this.state.data.members?this.state.data.members:[]).map(val=>{
			return(
				{
					value:val,
					label:this.state.data.employeeList[""+val]
				}
			)
		});
		const cityOptions = (this.state.cityList?Object.keys(this.state.cityList):[]).map(val=>{
			return(
				{
					value:val,
					label:val
				}
			)
		});
		return (
			<div style={{ "marginTop": 50 }} className="container-fluid">
				<button onClick={this.createComplaint} className="btn btn-success" style={{ "marginBottom": 10 }}>Create Complaint</button>
				<div className="row">
				<div className="col-md-2 col-6 filter_box ">
							{selectCustom(options,"statusChoosen","Choose Status",this.handleMultiSelectChange)}
				</div>
				<div className="col-md-2 col-6 filter_box ">
							{selectCustom(sourceSelectOptions,"sourceChoosen","Choose Source",this.handleMultiSelectChange)}
				</div>
				<div className="col-md-2 col-6 filter_box ">
							{selectCustom(assignedToOptions,"assignedChoosen","Choose Responsible",this.handleMultiSelectChange)}
				</div>
				<div className="col-md-2 col-6 filter_box ">
							{selectCustom(cityOptions,"cityChoosen","Choose City",this.handleMultiSelectChange)}
				</div>
				{/* <div className="col-lg-2 col-6" style={{padding:'0px 15px'}}>
					Start Date:
					<input
						style={{width:"100%",borderRadius:5,padding:'8px 8px'}}
						onChange={this.handleDateChange}
						name="startDate"
						type='date'
					/>
				</div>
				<div className="col-lg-2 col-6" style={{padding:'0px 10px'}}>
					End Date:
					<input
						style={{width:"100%",borderRadius:5,padding:'8px 8px'}}
						onChange={this.handleDateChange}
						name="endDate"
						type='date'
					/>
				</div> */}
				<div className="col-md-2 col-6">
					<button onClick={this.applyFilter} className="btn btn-success" style={{ "marginBottom": 10 }}>Apply Filter</button>
				</div>
				
				</div>
				<ReactTable
					filterable={true}
					showPagination={true}
					sortable={true}
					data={this.state.viewData}
					columns={this.state.showColumns}
					defaultSorted={[{ id: "epoch", desc: true }]}
					style={{ height: "90vh", textAlign: 'center' }}
					defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
					loading={this.state.loading}
					/>
					<Modal open={this.state.openCreateModal} onClose={this.onCloseCreateModal}>
								<h2 style={{textAlign:"center",marginTop:10,marginBottom:10}}>Create New Complaint</h2>
								<form onSubmit={this.handleSubmitCreateComplaint} className="form-control">
									<div className="form-group">
										<label>Quote Id</label>
										<input className="form-control" type="text" name="quoteId" value={this.state.createComplaintForm.quoteId} onChange={this.handleComplaintFormChange} required />
									</div>
									<div className="form-group">
										<label>Type</label>
										<select className="form-control" onChange={this.handleComplaintFormChange} name="type" value={this.state.createComplaintForm.type}>
										<option key="-1" value="-1">--Select Type---</option>
											{	
												Object.keys(this.typeOptions).map((v,i) => {
													//console.log(i,v);
												return(<option key={i} value={v}>{v}</option>)
												})
											}
										</select>
									</div>
									<div className="form-group">
										<label>Sub Type</label>
										<select className="form-control" onChange={this.handleComplaintFormChange} name="subType" value={this.state.createComplaintForm.subType}>
										<option key="-1" value="-1">--Select Type---</option>
											{	
												(this.state.subTypeOptions).map((v,i) => {
													//console.log(i,v);
												return(<option key={i} value={v}>{v}</option>)
												})
											}
										</select>
									</div>
									<div className="form-group">
										<label>Description</label>
										<textarea rows="5" className="form-control" type="text" name="description" value={this.state.createComplaintForm.description} onChange={this.handleComplaintFormChange} required />
									</div>
									<div className="form-group">
										<label>Assigned To</label>
										{/* <input className="form-control" type="text" name="assignedTo" value={this.state.createComplaintForm.assignedTo} onChange={this.handleComplaintFormChange} required /> */}
										<select className="form-control" onChange={this.handleComplaintFormChange} name="responsibleTeam" value={this.state.createComplaintForm.responsibleTeam}>
										<option key="-1" value="-1">---Select Team---</option>
											{	
												Object.keys(this.state.data.teamEmployeeMap?this.state.data.teamEmployeeMap:{}).map((v,i) => {
													//console.log(i,v);
												return(<option key={i} value={v}>{v}</option>)
												})
											}
										</select>
										<select className="form-control" onChange={this.handleComplaintFormChange} name="assignedTo" value={this.state.createComplaintForm.assignedTo}>
										<option key="-1" value="-1">--Select Responsible---</option>
											{	
												(this.state.responsibleOptions).map((v,i) => {
													//console.log(i,v);
												return(<option key={i} value={v}>{this.state.data.employeeList[v+""]}</option>)
												})
											}
										</select>
									</div>
									<div className="form-group">
										<label>Source</label>
										<select className="form-control" onChange={this.handleComplaintFormChange} name="source" value={this.state.createComplaintForm.source}>
										<option key="-1" value="-1">--Select source---</option>
											{	
												(this.sourceOptions).map((v,i) => {
													//console.log(i,v);
												return(<option key={i} value={v}>{v}</option>)
												})
											}
										</select>
									</div>
									<div className="form-group">
										<button type="submit" className="btn btn-success">Submit</button>
									</div>
								</form>

					</Modal>
			</div>
		);


	}
}

const selectCustom = (options, name, placeholder, handleMultiSelectChange) => {
	const changeHandle = (value) => { handleMultiSelectChange(value,name) }
	return (
		<div className="drop_down1">
			<Select
				isMulti
				isSearchable
			    onChange = {(e) => {changeHandle(e)}}
			    placeholder={placeholder}
			    name={name}
				options={options}
			/>
		</div>
	)
}


function mapStateToProps(state) {
	return {
		user: state.user,
		data: state.complaint.data,
		completed: state.updatecomplaint.completed,
		newComplaintData:state.createnewcomplaint.data
	};
};

// function mapStateToProps({ user, complaint, updatecomplaint, createnewcomplaint }) {
// 	let { data } = complaint;
// 	let { completed } = updatecomplaint;
// 	let newComplaintData = createnewcomplaint.data;
// 	return {
// 		user: user,
// 		data: data,
// 		completed: completed,
// 		newComplaintData:newComplaintData
// 	};
// };

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ fetchEmailsAnalytics, fetchClientAnalytics, setemailstatus, updateComplaint, createComplaint, searchData, fetchComplaints }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Complaint);