import { FETCH_PETASKS_SUCCESS, FETCH_QUOTEID_PE_SUCCESS, FETCH_PE_MAPPING_SUCCESS, FETCH_NAME_MAPPING_SUCCESS, FETCH_CITY_MAPPING_SUCCESS, FETCH_STATE_SUBSIDY_MAPPING, FETCH_CITY_DISCOM_MAPPING, UPDATE_AUTO_ASSIGN_STATUS } from '../actions/actiontypes'
const INIT_STATE = {
	loading: true
}


export default function (state = INIT_STATE, action) {
	switch (action.type) {
		case FETCH_PETASKS_SUCCESS:
			return { ...state, tableData: action.payload, loading:false };
        case FETCH_QUOTEID_PE_SUCCESS:
            return { ...state, quoteIdList: action.payload, fetchQuoteIdError: (action.payload && action.payload.length)?false:true };
        case FETCH_PE_MAPPING_SUCCESS:
            return { ...state, peMapping: action.payload };
        case FETCH_NAME_MAPPING_SUCCESS:
			return { ...state, employeeList: action.payload };
		case FETCH_CITY_MAPPING_SUCCESS:
			return { ...state, stateCityMap: action.payload };
		case FETCH_STATE_SUBSIDY_MAPPING:
			return { ...state, stateSubsidyMap: action.payload };
		case FETCH_CITY_DISCOM_MAPPING:
			return { ...state, cityDiscomMap: action.payload };
		case UPDATE_AUTO_ASSIGN_STATUS:
			return { ...state, autoAssignStatus: action.payload }
		default:
			return state;
	}
}
