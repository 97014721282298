import { PUSH_COMMENT_SUCCESS } from './actiontypes';

import { base } from '../connection';
import { createDateObj } from '../helper/dateObj'
import { sendEmail } from '../helper/helper';


export function setorderstatus(siteId,inverter,panel,structure,email,quoteId) {
	console.log(new Date());
	if(inverter){
		console.log("Inverter date not null");
	}
	else{
		console.log("Inverter date null");
	}
	return (dispatch) => {
		fetchOrderStatus(siteId,inverter,panel,structure,email,quoteId);
	}
}

function fetchOrderStatus(siteId,invertor,panel,structure,email,quoteId) {
	base.fetch('/delivery/sites/'+siteId+'/orderStatus', {})
	.then(data=>{
        sendEmailForDateChange(data.invertor,invertor,email,quoteId)
        sendEmailForDateChange(data.panel,panel,email,quoteId)
        sendEmailForDateChange(data.structure,structure,email,quoteId)

		changeStructure(data.invertor,invertor);
		changeStructure(data.panel,panel);
        changeStructure(data.structure,structure);
        
        setOrderStatusValue(siteId,data)
	})
	.catch(error=>{
        console.log(error)
	})
}

function setOrderStatusValue(siteId,data) {
	base.post('/delivery/sites/'+siteId+'/orderStatus', {
    data: data	
    ,then(err){
      if(!err){
      	console.log("SUCCESS",'parduman')
      }else{
      	console.log(err);
      }
    }
  });
}

function changeStructure(data,date) {
	if(date){
		data.status = 1;
		data.expectedCompletionDate = createDateObj(date);
	}
	else{
		data.status = 0;
		data.expectedCompletionDate = null;
	}
	return data;
}

function sendEmailForDateChange(data,date,email,quoteId){
    if(data.status == 0 && date){
        let emailSubject , emailMessage, emailList
        switch(true){
            case data.name == 'Invertor':
                console.log('sending Invertor email')
                emailSubject = 'Update:Inverter delivery'
                emailMessage = `
                    <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="utf-8">
                                <meta name="viewport" content="width=device-width, initial-scale=1">
                            </head>
                            <body>
                                <div>
                                    Dear Sir,
                                    <br>
                                    Greetings from Team Zunroof!
                                    <br><br>
                                    This is an update mail with regards to your solar rooftop project. We would like to inform you that Solar Inverter for your Rooftop project has been sucessfully delivered at your residence. We will accordingly be moving ahead with delivery of panels and inverter and commissioning of the system subject to adherence of payment terms agreed at the time of order confirmation                                    <br><br>
                                    Warm Regards,
                                    <br>
                                    ZunRoof Team
                                    <br>
                                    www.zunroof.com,
                                    <br>
                                    www.facebook.com/zunroof
                                    <br><br>
                                    Support: +919319432702
                                    <br>
                                    Get your ZunRoof app: https://play.google.com/store/apps/details?id=com.zunroofprod.zunroofprod&hl=en
                                </div>
                            </body>
                        </html>
                    `
                emailList = [email,'delivery@zunroof.com']
                sendEmail(emailSubject, emailMessage, emailList).then((response) => {
                    console.log('Email Sent For Inverter Date')
                })
                break;
            case data.name == 'Panel':
                emailSubject = 'Update:Solar Panels delivery'
                emailMessage = `
                    <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="utf-8">
                                <meta name="viewport" content="width=device-width, initial-scale=1">
                            </head>
                            <body>
                                <div>
                                    Dear Sir,
                                    <br>
                                    Greetings from Team Zunroof!
                                    <br><br>
                                    This is an update mail with regards to your solar rooftop project. We would like to inform you that Solar Panels for your Rooftop project have been sucessfully delivered at your residence. We will accordingly be moving ahead with delivery of other components and commissioning of the system subject to adherence of payment terms agreed at the time of order confirmation
                                    <br><br>
                                    Warm Regards,
                                    <br>
                                    ZunRoof Team
                                    <br>
                                    www.zunroof.com,
                                    <br>
                                    www.facebook.com/zunroof
                                    <br><br>
                                    Support: +919319432702
                                    <br>
                                    Get your ZunRoof app: https://play.google.com/store/apps/details?id=com.zunroofprod.zunroofprod&hl=en
                                </div>
                            </body>
                        </html>
                    `
                emailList = [email,'delivery@zunroof.com']
                sendEmail(emailSubject, emailMessage, emailList).then((response) => {
                    console.log('Email Sent For Panels Date')
                })
                break;
            case data.name == 'Structure':
                emailSubject = 'Update:Panel mounting structure delivery'
                emailMessage = `
                    <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="utf-8">
                                <meta name="viewport" content="width=device-width, initial-scale=1">
                            </head>
                            <body>
                                <div>
                                    Dear Sir,
                                    <br>
                                    Greetings from Team Zunroof!
                                    <br><br>
                                    This is an update mail with regards to your solar rooftop project. We would like to inform you that Panel mounting structure for your Solar Rooftop project has been sucessfully delivered at your residence. We will accordingly be moving ahead with delivery of other components and commissioning of the system subject to adherence of payment terms agreed at the time of order confirmation                                    <br><br>
                                    Warm Regards,
                                    <br>
                                    ZunRoof Team
                                    <br>
                                    www.zunroof.com,
                                    <br>
                                    www.facebook.com/zunroof
                                    <br><br>
                                    Support: +919319432702
                                    <br>
                                    Get your ZunRoof app: https://play.google.com/store/apps/details?id=com.zunroofprod.zunroofprod&hl=en
                                </div>
                            </body>
                        </html>
                    `
                emailList = [email,'delivery@zunroof.com']
                sendEmail(emailSubject, emailMessage, emailList).then((response) => {
                    console.log('Email Sent For Structure Date')
                })
                break;
        }
    }
}