import React from 'react';
import { NavLink } from 'react-router-dom';
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	NavbarText
} from 'reactstrap';
export const NavBarComponent = ({ user, logout, redirect }) => {
	return (
		<nav className="navbar container-fluid navbar-expand-md fixed-top navbar-dark bg-dark">
			<div className="container-fluid">
				<NavLink className="navbar-brand" strict to="#"> Operations</NavLink>
				<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
					<span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse" id="collapsibleNavbar">
					{user.phoneNumber && user.level >= 1600 ? logoutComponent(user, logout) : loginComponent()}
				</div>
			</div>
		</nav>
	);
}

const loginComponent = () => {
	return (
		<ul className="navbar-nav my-lg-0 ml-auto">
			<div className="input-group-append">
				<NavLink className="nav-link" strict to="/login" >Login</NavLink>
			</div>
		</ul>
	)
}


const logoutComponent = (user, logout) => {
	return (
		<ul className="navbar-nav my-lg-0 ml-auto">
			<NavLinkCustom
				to="all"
				name="Search"
			/>
			<NavLinkCustom
				to="deals"
				name="Deals"
			/>
			{/* {user.level>=1900?<NavLink className="nav-link" strict to="/sales" onClick={(event) => {event.preventDefault(); window.open(window.location.origin+"/sales")}}>Sales</NavLink>:""}
			{user.level>=1900?<NavLink className="nav-link" strict to="/salesanalytics" onClick={(event) => {event.preventDefault(); window.open(window.location.origin+"/salesanalytics")}}>Sales Analytics</NavLink>:""} */}
			{/* {user.level>=1900?<NavLink className="nav-link" strict to="/stages" onClick={(event) => {event.preventDefault(); window.open(window.location.origin+"/stages")}}>Stage Status</NavLink>:""} */}
			{/* {user.level>=2500?<NavLink className="nav-link" strict to="/graphs" onClick={(event) => {event.preventDefault(); window.open(window.location.origin+"/graphs")}}>Analytics</NavLink>:""} */}
			
			
			{user.level >= 1900 ? <UncontrolledDropdown nav inNavbar>
				<DropdownToggle nav caret>
					Sales
              </DropdownToggle>
				<DropdownMenu style={{ backgroundColor: '#343a40' }} left>
					<DropdownItem style={{ backgroundColor: '#343a40' }}>
						<NavLink className="nav-link" strict to="/sales" onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/sales") }}>Sales</NavLink>
					</DropdownItem>
					<DropdownItem style={{ backgroundColor: '#343a40' }}>
						<NavLink className="nav-link" strict to="/salesanalytics" onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/salesanalytics") }}>Sales Analytics</NavLink>
					</DropdownItem>
					{user.level >= 2300 ? <DropdownItem style={{ backgroundColor: '#343a40' }}>
						<NavLink className="nav-link" strict to="/newsa" onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/newsa") }}>Sales Analytics New</NavLink>
					</DropdownItem> : ""}
				</DropdownMenu>
			</UncontrolledDropdown> : ''}


			{user.level >= 1900 ? <NavLink className="nav-link" strict to="/userroles" onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/userroles") }}>User Roles</NavLink> : ""}
			<NavLink className="nav-link" strict to="/incentivepage" onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/incentivepage") }}>Incentives</NavLink>
			<NavLink className="nav-link" strict to="/files" onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/files") }}>Files</NavLink>
			<NavLink className="nav-link" strict to="/emailanalytics" onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/emailanalytics") }}>CM Team</NavLink>
			{(user.level == 1800 || user.level > 2500) ? <NavLink className="nav-link" strict to="/emailaccounts" onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/emailaccounts") }}>Accounts</NavLink> : ""}
			<NavLink className="nav-link" strict to="/ticketSystem" onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/ticketSystem") }}>Tickets</NavLink>
			{/* {(user.level==2300 || user.level>=2500)?<NavLink className="nav-link" strict to="/opsreport" onClick={(event) => {event.preventDefault(); window.open(window.location.origin+"/opsreport")}}>Ops Report</NavLink>:""} */}


			{user.level >= 1700 ? <UncontrolledDropdown nav inNavbar>
				<DropdownToggle nav caret>
					Report
              </DropdownToggle>
				<DropdownMenu style={{ backgroundColor: '#343a40' }} left>
					{(user.level == 2300 || user.level >= 2500) ? <DropdownItem style={{ backgroundColor: '#343a40' }}>
						<NavLink className="nav-link" strict to="/opsreport" onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/opsreport") }}>Ops Report</NavLink>
					</DropdownItem> : ""}
					<DropdownItem style={{ backgroundColor: '#343a40' }}>
						<NavLink className="nav-link" strict to="/ticketReport" onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/ticketReport") }}>Ticket Report</NavLink>
					</DropdownItem>
				</DropdownMenu>
			</UncontrolledDropdown> : ''}

			
			<UncontrolledDropdown nav inNavbar>
				<DropdownToggle nav caret>
					Vendor
              </DropdownToggle>
				<DropdownMenu style={{ backgroundColor: '#343a40' }} left>
					<DropdownItem style={{ backgroundColor: '#343a40' }}>
						<NavLink className="nav-link" strict to="/vendor" onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/vendor") }}>Vendor Payment</NavLink>
					</DropdownItem>
					{(user.level >= 1700) ? <DropdownItem style={{ backgroundColor: '#343a40' }}>
						<NavLink className="nav-link" strict to="/vendormanagement" onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/vendormanagement") }}>Vendor Management</NavLink>
					</DropdownItem> : ""}
					<DropdownItem style={{ backgroundColor: '#343a40' }}>
						<NavLink className="nav-link" strict to="/v2dashboard" onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/v2dashboard") }}>V2 Dashboard</NavLink>
					</DropdownItem>
				</DropdownMenu>
			</UncontrolledDropdown>
			{(user.level == 1800 || user.level > 2500) ? <NavLink className="nav-link" strict to="/bulkInvoiceUpload" onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/bulkInvoiceUpload") }}>Invoice upload</NavLink> : ""}
			<NavLink className="nav-link" strict to="/petasks" onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/petasks") }}>PE Tasks</NavLink>
			<NavLink className="nav-link" strict to="#" onClick={logout} >Logout</NavLink>
		</ul>
	)
}

const NavLinkCustom = ({ to, name }) => {
	return (
		<li className="nav-item">
			<NavLink className="nav-link" strict to={`/${to}`} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/" + to) }}>{name}</NavLink>
		</li>
	)
}
