import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { Loader } from "../components/Loader.jsx";
import { PaymentView } from "../components/PaymentView.jsx";
import { Restrict } from '../components/Restrict';


export class PaymentsProcess extends Component {
	constructor(props) {
	  super(props);
	
	  this.state = {
		
	  };
	}

	componentWillMount() {
	}

	componentWillReceiveProps({ user }) {
		
	}

	render() {
		
		if(this.props.user===null){
			return <Redirect to="/login?redirect_uri=payments" />;
		}

		if(this.props.user.level<1600) {
			return ( 
				<div>
					<Restrict />
				</div>
			)
		}

		if(this.props.user.level>=1900) {
			return ( 
				<div style={{marginTop:60}}>
					<PaymentView />
				</div>
			)
		}

		return (
			<div className="navMargin">
				<Loader />
			</div>
		);
	}
}

function mapStateToProps({user, salesdata, mapping}) {
	return { user }
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps,mapDispatchToProps)(PaymentsProcess);