import { FETCH_TICKET_QUOTEIDS_START, FETCH_TICKET_QUOTEIDS_ERROR, FETCH_TICKET_QUOTEIDS_SUCCESS, FETCH_QUOTEID_DATA_START, FETCH_QUOTEID_DATA_SUCCESS, FETCH_QUOTEID_DATA_ERROR, FETCH_REFERENCE_LIST_START, FETCH_REFERENCE_LIST_SUCCESS, FETCH_PROCESS_LIST_START, FETCH_PROCESS_LIST_SUCCESS, FETCH_VENDOR_DUE_START, FETCH_VENDOR_DUE_SUCCESS, SET_VENDOR_FORM_INIT_SUCCESS, SET_VENDOR_FORM_INIT_ERROR, SET_VENDOR_FORM_APPROVAL_SUCCESS, SET_VENDOR_FORM_APPROVAL_ERROR, SET_NEW_VENDOR_FORM_SUCCESS, SET_NEW_VENDOR_FORM_ERROR, SET_TRANSACTION_FORM_SUCCESS, SET_TRANSACTION_FORM_ERROR, FETCH_VENDOR_DUE_PAYMENT_SUCCESS, FETCH_VENDOR_DUE_PAYMENT_START, FETCH_BANK_DATA_SUCCESS, FETCH_BANK_DATA_START, FETCH_VENDOR_SUMMARY_START, FETCH_VENDOR_SUMMARY_SUCCESS, FETCH_INVENTORY_SUCCESS, FETCH_INVOICE_DATA_SUCCESS, FETCH_REFERENCE_LIST_QUOTEID_SUCCESS } from '../actions/actiontypes'
const INIT_STATE = {
	loading: true,
	quoteIdList: [],
	error: null,
	quoteIdDetails: {},
	dataReference: {},
	dueAmount: 0,
	processLedgerData: [],
	error: null,
	success: null,
	bankData:[],
	referenceListQuoteId: []
}


export default function (state = INIT_STATE, action) {
	switch (action.type) {
		case FETCH_TICKET_QUOTEIDS_START:
			return { ...state, loading: true };

		case FETCH_TICKET_QUOTEIDS_ERROR:
			return { ...state, error: action.payload };

		case FETCH_TICKET_QUOTEIDS_SUCCESS:
			return { ...state, quoteIdList: action.payload, loading: false };

		case FETCH_QUOTEID_DATA_START:
			return { ...state, loading: true };

		case FETCH_QUOTEID_DATA_SUCCESS:
			return { ...state, quoteIdDetails: action.payload };

		case FETCH_QUOTEID_DATA_ERROR:
			return { ...state, error: action.payload };

		case FETCH_REFERENCE_LIST_START:
			return { ...state, dataReference: {} };

		case FETCH_REFERENCE_LIST_SUCCESS:
			return { ...state, dataReference: action.payload };

		case FETCH_BANK_DATA_START:
			return { ...state, bankData: [] };

		case FETCH_BANK_DATA_SUCCESS:
			return { ...state, bankData: action.payload };

		case FETCH_VENDOR_SUMMARY_START:
			return { ...state, vendorSummary: [] };

		case FETCH_VENDOR_SUMMARY_SUCCESS:
			return { ...state, vendorSummary: action.payload };

		case FETCH_PROCESS_LIST_START:
			return { ...state, processLedgerData: [] };

		case FETCH_PROCESS_LIST_SUCCESS:
			return { ...state, processLedgerData: action.payload };

		case FETCH_VENDOR_DUE_START:
			return { ...state, dueAmount: 0 };

		case FETCH_VENDOR_DUE_SUCCESS:
			return { ...state, dueAmount: action.payload };

			//debugger
		case FETCH_VENDOR_DUE_PAYMENT_START:
			return { ...state, duePayment: 0 };

		case FETCH_VENDOR_DUE_PAYMENT_SUCCESS:
			return { ...state, duePayment: action.duePayment };

		case SET_VENDOR_FORM_INIT_SUCCESS:
			return { ...state, success: action.payload };

		case SET_VENDOR_FORM_INIT_ERROR:
			return { ...state, error: action.payload };

		case SET_VENDOR_FORM_APPROVAL_SUCCESS:
			return { ...state, success: action.payload };

		case SET_VENDOR_FORM_APPROVAL_ERROR:
			return { ...state, error: action.payload };

		case SET_NEW_VENDOR_FORM_SUCCESS:
			return { ...state, success: action.payload };

		case SET_NEW_VENDOR_FORM_ERROR:
			return { ...state, error: action.payload };

		case SET_TRANSACTION_FORM_SUCCESS:
			return { ...state, success: action.payload };

		case SET_TRANSACTION_FORM_ERROR:
			return { ...state, error: action.payload };

		case FETCH_INVOICE_DATA_SUCCESS:
			return { ...state, invoiceData: action.payload };
		case FETCH_INVENTORY_SUCCESS:
			return { ...state, inventoryData: action.payload }

		case FETCH_REFERENCE_LIST_QUOTEID_SUCCESS:
			return { ...state, referenceListQuoteId: action.payload }
		default:
			return state;
	}
}
