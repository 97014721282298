export function createDateObj(dateValue) {
	let dateVal = new Date(dateValue);
	let dateObj = {
		date:dateVal.getDate(),
		day:dateVal.getDay(),
		hours:dateVal.getHours(),
		minutes:dateVal.getMinutes(),
		month:dateVal.getMonth()+1,
		seconds:dateVal.getSeconds(),
		time:dateVal.getTime(),
		timezoneOffset:dateVal.getTimezoneOffset(),
		year:dateVal.getFullYear()-1900
	}
	return dateObj;
}