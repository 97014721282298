export function checkExist(array,value){
	if(array.length<=0) {
		return true;
	}
	return value?array.indexOf(value)>-1:false;
}

export function checkArrayExist(array,value){
	if(array.length<=0) {
		return true;
	}
	let found = false;
	value.forEach(val=>{
		// console.log(val);
		if(isNaN(parseInt(val))) {
			if(array.indexOf(val)>-1){
				found = true;
			}
		}else{
			if(array.indexOf(parseInt(val))>-1){
				found = true;
			}
		}
	});
	return found;
}

export function checkChoosenArrayExist(array,value){
	if(array.length<=0) {
		return true;
	}
	let found = false;
	// console.log("ARRAY : ",array," VALUE : ",value);
	value.forEach(val=>{
		if(isNaN(parseInt(val))) {
			if(array.indexOf(val)>-1){
				found = true;
			}
		}else{
			if(array.indexOf(parseInt(val))>-1){
				found = true;
			}
		}
	});
	return found;
}

export function filterClone(key,value,data) {
	let keyValue = value[key];
	let updatedData = data.map(val => {
		if(val[key]===keyValue) {
			return value;
		}
		return val;
	});
	return updatedData;
}