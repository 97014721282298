import { base } from '../connection';
import { sendEmail} from '../helper/helper';


import { createDateObj } from '../helper/dateObj';

export function setNetMeteringDate(siteId,date,email,quoteId) {
	console.log(new Date());
	console.log("Net Meter Date", date);
	if(date){
		console.log("Net meter Date Not Null");
	}
	else{
		console.log("Net meter date null");
	}
	return (dispatch) => {
		fetchOrderStatus(siteId,date,email,quoteId)
	}
}

function fetchOrderStatus(siteId,date,email,quoteId) {
	base.fetch('/delivery/sites/'+siteId+'/orderStatus', {})
	.then(data => {
		let orderStatus = {};
		if(!data || Object.keys(data).length<5){
			orderStatus ={
			  "invertor" : {
			    "name" : "Invertor",
			    "status" : 0
			  },
			  "netMetering" : {
			    "name" : "Net Metering",
			    "status" : 0
			  },
			  "orderConfirmationInternal" : {
			    "name" : "Order Confirmation",
			    "status" : 0
			  },
			  "panel" : {
			    "name" : "Panel",
			    "status" : 0
			  },
			  "structure" : {
			    "name" : "Structure",
			    "status" : 0
			  },
			  "subsidyApply" : {
			    "name" : "Subsidy Apply",
			    "status" : 0
			  }
			}
			if(date){
			orderStatus.netMetering = {
				name: "Net Metering",
				status: 1,
				expectedCompletionDate:createDateObj(date)
			}
			}
		}else{
			orderStatus = {...data};
			if(date){
			orderStatus.netMetering = {
				name: "Net Metering",
				status: 1,
				expectedCompletionDate:createDateObj(date)
			}
			}
			else{
				orderStatus.netMetering = {
					name: "Net Metering",
					status: 0
				}
			}
		}
		if(date && email && data.netMetering.status != 1 && orderStatus.netMetering.status == 1){
			const emailSubject = 'Update:Net Meter installation'
			const emailMessage = `
				<!DOCTYPE html>
					<html lang="en">
						<head>
							<meta charset="utf-8">
							<meta name="viewport" content="width=device-width, initial-scale=1">
						</head>
						<body>
							<div>
								Dear Sir,
								<br>
								Greetings from Team Zunroof!
								<br><br>
								This is an update mail with regards to your solar rooftop project. We would like to inform you that net meter for your project has been installed
								<br><br>
								Warm Regards,
								<br>
								ZunRoof Team
								<br>
								www.zunroof.com,
								<br>
								www.facebook.com/zunroof
								<br><br>
								Support: +919319432702
								<br>
								Get your ZunRoof app: https://play.google.com/store/apps/details?id=com.zunroofprod.zunroofprod&hl=en
							</div>
						</body>
					</html>
			`
			let emailList = [email,'delivery@zunroof.com']
			sendEmail(emailSubject, emailMessage, emailList).then((response) => {
				console.log('Email Sent For NET METERING DATE')
			})
		}
		pushOrderStatusNetMetring(siteId,orderStatus,quoteId);
	});
}

function pushOrderStatusNetMetring(siteId,data,quoteId) {
	base.post('/delivery/sites/'+siteId+'/orderStatus/', {
		data:data,
		then(err){
			if(!err){
				console.log("SUCCESSFULLY ADDED NET METERING DATE");
			}
		}
	});
}