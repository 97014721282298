import { FETCHING_SALES_START, FETCHING_SALES_ERROR, FETCHING_SALES_SUCCESS } from '../actions/actiontypes'

import { columnsEmailAnalytics, columnsClientAnalytics } from '../config/column_fields';

const INIT_STATE = {
	loading:true,
	data:[]
}
	

export default function(state = INIT_STATE, action) {
	switch(action.type){
		case FETCHING_SALES_START:{
			return {...state,loading:true};
		};

		case FETCHING_SALES_ERROR:{
			return {...state};
		}

		case FETCHING_SALES_SUCCESS:{
			return {...state,data:action.payload,loading:false};
		}
	}	
	
	return state;
}