import { GENERATE_INVOICE_STATUS, GENERATE_INVOICE_SUCCESS, UPDATE_INVOICE_DATA } from '../actions/actiontypes'
const INIT_STATE = {
    status: 0,
    result: [],
    invoiceData: [{products:{}}],
    updateInvoiceData: false
}

export default function (state = INIT_STATE, action) {
	switch (action.type) {
		case GENERATE_INVOICE_STATUS:
            return { ...state, status: action.payload };
        case GENERATE_INVOICE_SUCCESS:
            return { ...state, result: action.payload };
        case UPDATE_INVOICE_DATA:
            return { ...state, invoiceData: action.payload, updateInvoiceData: true };
        default:
			return state;
	}
}
