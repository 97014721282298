import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import renderHTML from 'react-render-html';

import { fetchmailaccounts } from '../actions/fetchmailaccounts';

import { emailToDot } from '../helper/helper';
import { changeToViewDate } from '../helper/dateChanger';
import { Restrict } from '../components/Restrict';

class EmailViewerAccounts extends Component {
	constructor(props) {
	  super(props);
	
	  this.state = {
	  	to: null,
		fromName: null,
		subject: null,
		emailData: null
	  };
	}

	componentWillMount() {
		console.log("WILL MOUNT");
		if(this.props.match.params.id) {
			if(this.props.user){
				if(this.props.user.level>=1900){
					this.props.fetchmailaccounts(this.props.match.params.id);
				}
			}
		}
	}

	componentWillReceiveProps({email}) {
		if(email.mailBody){
			let { to, subject, fromName, date, mailBody } = email;
			this.setState({
				to: emailToDot(to),
				fromName: emailToDot(fromName),
				subject: subject,
				date: changeToViewDate(date),
				emailData:mailBody
			})
		}
	}

	render() {
		if(this.props.user===null){
			return <Redirect to={"/login?redirect_uri=emailviewaccounts/"+this.props.match.params.id} />;
		}

		if(this.props.user && this.props.user.level<1600) {
			return ( 
				<div>
					<Restrict />
				</div>
			)
		}
		
		if(this.state.emailData) {
			let { to, fromName, subject, date, emailData } = this.state;
			return(
				<div style={{"marginTop":100}}>
					<div className="container">
						<div className="row" style={{"border":"1px solid black"}}>
							<div className="col-md-3" style={{"border":"1px solid black","padding":"10px 5px","textAlign":"center"}}><h5><strong>To:</strong> {to}</h5></div>
							<div className="col-md-3" style={{"border":"1px solid black","padding":"10px 5px","textAlign":"center"}}><h5><strong>From:</strong> {fromName}</h5></div>
							<div className="col-md-2" style={{"border":"1px solid black","padding":"10px 5px","textAlign":"center"}}><h5><strong>Date:</strong> {date}</h5></div>
							<div className="col-md-4" style={{"border":"1px solid black","padding":"10px 5px","textAlign":"center"}}><h5><strong>Subject:</strong> {subject}</h5></div>
						</div>
						<div className="row" style={{"border":"1px solid black","padding":"10px"}}>
							<div className="col-md-12 col-12">
								{ renderHTML(emailData) }
							</div>
						</div>
					</div>
				</div>
			)
		}

		return (
			<div style={{"marginTop":100}}>
				Loading...
			</div>
		);
	}
}

function mapStateToProps({ user, email }) {
	console.log(email);
	return {
		user: user,
		email: email
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ fetchmailaccounts }, dispatch);
};

export default connect( mapStateToProps, mapDispatchToProps )(EmailViewerAccounts); 