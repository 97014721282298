import React from 'react';

import {Bar,Line} from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

export const PercentageGraph = ({ dataGraph, labels, XLabel, YLabel, title }) => {
	return (
			<div className="containerFluid" >
				<div style={{marginBottom:20}}>
					<Bar 
						data={{
							  labels: labels,
							  datasets: dataGraph
							}}
						height={250}
						width={50}
						options={{
			            	maintainAspectRatio: false,
			            	plugins: {
								datalabels: {
									display: true,
									color: '#2a4242'
								}
			            	},
			            	title: {
					            display: true,
					            text: title
					        },
							scales: {
					            xAxes: [{
					                stacked: true,
					                scaleLabel: {
					                	display:true,
						                labelString: XLabel
					                }
					            }],
					            yAxes: [{
					                stacked: true,
					                scaleLabel: {
					                	display:true,
						                labelString: YLabel
					                }
					            }]
					        }
			          	}} />
				</div>
			</div>
		);
}

export const LineGraph = ({ dataGraph, labels, XLabel, YLabel, title, dataLabels }) => {
	const dataSet = [];
	console.log(dataGraph.length);
	for(let i=0; i<dataGraph.length; i++ ) {
		dataSet.push({
			label: dataLabels[i],
			fill: true,
			lineTension: 0.1,
			backgroundColor: 'rgba(75,192,192,0.4)',
			borderColor: 'rgba(75,192,192,1)',
			borderCapStyle: 'butt',
			borderDash: [],
			borderDashOffset: 0.0,
			borderJoinStyle: 'miter',
			pointBorderColor: 'rgba(75,192,192,1)',
			pointBackgroundColor: '#fff',
			pointBorderWidth: 1,
			pointHoverRadius: 5,
			pointHoverBackgroundColor: 'rgba(75,192,192,1)',
			pointHoverBorderColor: 'rgba(220,220,220,1)',
			pointHoverBorderWidth: 2,
			pointRadius: 1,
			pointHitRadius: 10,
			data: dataGraph[i]
		})
	}

	return (
			<div className="containerFluid" >
				<div style={{marginBottom:20}}>
					<Line 
						data={{
							labels: labels,
							datasets: [...[],...dataSet]
						}}
						height={250}
						width={50}
						options={{
			            	maintainAspectRatio: false,
			            	plugins: {},
			            	title: {
					            display: true,
					            text: title
					        },
							scales: {
					            xAxes: [{
					                stacked: false,
					                scaleLabel: {
					                	display:true,
						                labelString: XLabel
					                },
					                ticks: {
									    autoSkip: true,
										maxTicksLimit: 20
									}
					            }],
					            yAxes: [{
					                stacked: false,
					                scaleLabel: {
					                	display:true,
						                labelString: YLabel
					                }
					            }]
					        }
			          	}} />
				</div>
			</div>
		);
}