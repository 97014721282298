import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

import { styleSheet } from '../styles/styleSheet';

import { fetchmainview } from '../actions/fetchmainview';
import { fetchmapping } from '../actions/fetchmapping';
import { addBulkNotification } from '../actions/addBulkNotification';
import { logout} from '../actions/auth';

import { DealsView } from '../components/DealsView';
import { Restrict } from '../components/Restrict';
import { Loader } from "../components/Loader.jsx";

import { columnsDeals } from '../config/column_fields';
import { sendEmailOnEdit } from '../actions/sendEmailOnEdit';

import moment from 'moment';
import '../styles/all.css'
import queryString from 'query-string';

class Deals extends Component {

	constructor(props) {
		super(props);

		this.state = {
			recieved:false,
			loading:true,
			data:[],
			reloadDisabled: false
		};
		this.vendor1 = ["NA","Boond","Chemitech","SmartRoof","Aryav","Zivah","SDSL","Oakridge","ZunRoof","SunEnergy","Volks Energie","Sirius","Greenon","GeoPower","GM Trading","Alien","Adhunik"]
		this.choosenColumn = {};
	}

	componentDidMount() {
		if(this.props.user){
			if(this.props.user.level>=1600){
				this.props.fetchmainview();
				this.props.fetchmapping();
				this.choosenColumn = localStorage.getItem("column_operations")?JSON.parse(localStorage.getItem("column_operations")):{ maintenanceRequired: false, vendor: false, nextactiondate: false, responsibleName: false, refDate: false, mppVerificationStatus: false, feasibility: false, rmsInstalled: false, rmsStatus: false, rmsDeviceType: false };
				let reloadButtonData = JSON.parse(localStorage.getItem('reloadButtonData') || '{}');
				let reloadCountToday = reloadButtonData[moment().format('YYYY-MM-DD')] || 0;
				if(reloadCountToday >= 2) {
					this.setState({
						reloadDisabled: true
					});
				}
			}
		}
	}

	componentWillReceiveProps(props) {
		const { data, states, stages, subsidies, ebissues, notifications, completed, newStages = {} } = props;
		console.log("newStages",newStages)
		if(Object.keys(data).length>0 && stages && ebissues && completed) {
			let newData = Object.values({...data}).filter(val => val.stage!==8).map(value => {
				let val = {...value};
				val.stage = stages[val.stage];
				val.ebIssueName = ebissues[val.ebIssueName];
				val.ebIssueLoad = ebissues[val.ebIssueLoad];
				val.ebIssueType = ebissues[val.ebIssueType];
				val.ebIssuePhase = ebissues[val.ebIssuePhase];
				return val;
			});
			this.setState({
				loading:false,
				data: newData,
				recieved: true
			})
		}
	}

	reloadDealsData = (reloadData) => {
		console.log("here", this.props);
		let todayDate = moment().format('YYYY-MM-DD');
		let reloadButtonData = JSON.parse(localStorage.getItem('reloadButtonData') || '{}');
		let reloadCountToday = (reloadButtonData[todayDate] || 0) + 1;
		localStorage.setItem('reloadButtonData', JSON.stringify({
			[todayDate]: reloadCountToday
		}))
		this.props.fetchmainview(true);
		this.setState({
			loading:true,
			recieved: false,
			reloadDisabled: reloadCountToday >= 2 ? true : false
		})
	}

	render() {
		let { loading, data, reloadDisabled } = this.state;

		let parsed = queryString.parse(this.props.location.search);

		let stateList = "";
		let cityList = "";

		try {
			if(parsed.selectedStage || parsed.selectedCity || parsed.selectedVariable) {
				stateList = JSON.parse(localStorage.getItem("selectedStatesST") || "[]").join(",");
				cityList = JSON.parse(localStorage.getItem("selectedCitiesST") || "[]").join(",");
			}
		}
		catch(err) {
			console.log("err", err);
		}

		if(this.props.user===null){
			if(parsed.selectedStage) return <Redirect to={`/login?redirect_uri=deals?selectedStage=${parsed.selectedStage}`} />;
			if(parsed.selectedCity) return <Redirect to={`/login?redirect_uri=deals?selectedVariable=${parsed.selectedVariable}&selectedCity=${parsed.selectedCity}`} />;
			if(parsed.selectedVariable) return <Redirect to={`/login?redirect_uri=deals?selectedVariable=${parsed.selectedVariable}`} />;
			return <Redirect to="/login?redirect_uri=deals" />;
		}

		if(this.props.user.level<1600) {
			return (
				<div>
					<Restrict />
				</div>
			)
		}

		if(this.state.recieved) {
			return (
				<div>
					<div className="container-fluid">
						<DealsView choosenColumn = {this.choosenColumn} loading={loading} addBulkNotification = {this.props.addBulkNotification} notificationMap={this.props.notifications} vendor1 = {this.vendor1} data={data} stages={this.props.stages} ebissues = {this.props.ebissues} states={this.props.states} subsidyStatusMap={this.props.subsidyStatusMap} netMeteringMap={this.props.netMeteringMap} subsidies={this.props.subsidies} columns={columnsDeals} user={this.props.user} sendEmailOnEdit = {this.props.sendEmailOnEdit} stateCityMap={this.props.stateCityMap} reloadDealsData={this.reloadDealsData} lastUpdatedTime={this.props.lastUpdatedTime} reloadDisabled={reloadDisabled} newStages={this.props.newStages} selectedStage={parsed.selectedStage || ""} selectedVariable={parsed.selectedVariable || ""} selectedStateList={stateList || ""} selectedCityList={cityList || ""}/>
					</div>
				</div>
			)
		}

		return (
			<div style = {styleSheet.frameOne} className="container-fluid">
				<Loader />
			</div>
		);
	}
}


function mapStateToProps({ user, mapping, dealsdata }) {
	let { stateMap, stageMap, subsidyMap, ebissueMap, notificationMap, subsidyStatusMap, netMeteringMap,cityMap,stateCityMap, newStages } = mapping;
	let { data, lastUpdatedTime, completed } = dealsdata;
	completed = !!(completed && (Object.keys(mapping).length == 15))
	console.log(Object.keys(mapping), "naman", completed);
	return {
		data: 			data,
		completed: 		completed,
		lastUpdatedTime : lastUpdatedTime,
		states: 		stateMap,
		stages: 		stageMap,
		subsidies: 		subsidyMap,
		ebissues: 		ebissueMap,
		subsidyStatusMap : subsidyStatusMap,
		netMeteringMap : netMeteringMap,
		notifications: 	notificationMap,
		user : 			user,
		cityMap : cityMap,
		stateCityMap : stateCityMap,
		newStages: newStages
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({fetchmainview, fetchmapping, addBulkNotification, sendEmailOnEdit, logout}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Deals);
