import { FETCH_PETASKS_SUCCESS, FETCH_QUOTEID_PE_SUCCESS, FETCH_PE_MAPPING_SUCCESS, FETCH_NAME_MAPPING_SUCCESS, FETCH_CITY_MAPPING_SUCCESS, FETCH_STATE_SUBSIDY_MAPPING, FETCH_CITY_DISCOM_MAPPING, UPDATE_AUTO_ASSIGN_STATUS } from './actiontypes';
import axios from 'axios';
import moment from 'moment';
import { base, db } from '../connection';
import swal from 'sweetalert';

// const taskFields = ['date', 'quoteId', 'state', 'type', 'startTime', 'employee', 'comment', 'priority'];
const taskFields = ['date', 'state', 'type', 'priority', 'city'];

export function fetchPeTask(filters) {
    return (dispatch) => {
        getPeTasks(filters)
            .then(data => {
                dispatch({ type: FETCH_PETASKS_SUCCESS, payload: data })
            })
            .catch(e => {
                console.log(e);
                return e;
            })
    }
}

async function getPeTasks(filters) {
    try {
        let { startDate, endDate, state, employee } = filters;
        let formdata = new URLSearchParams();
        if (!startDate || !endDate) {
            startDate = moment().format('YYYYMMDD');
            endDate = moment().add(7, 'days').format('YYYYMMDD');
        }
        else {
            startDate = moment(startDate, "YYYY-MM-DD").format("YYYYMMDD");
            endDate = moment(endDate, "YYYY-MM-DD").format("YYYYMMDD");
        }
        formdata.append('startDate', startDate);
        formdata.append('endDate', endDate);
        let data = await axios.post("https://operations.zunroof.com/api/getPeTasks", formdata, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });
        let peData = [];
        if (data && data.data && data.data.Data) {
            let apiData = data.data.Data;
            Object.keys(apiData).map(date => {
                apiData[date].map(taskObj => {
                    taskObj.date = date;
                    taskObj.statusVal = taskObj.status == "1"?"Completed":"Pending";
                    peData.push(taskObj);
                })
            })
        }
        return peData;
    }
    catch (e) {
        return [];
    }
}

export function fetchQuoteId() {
    return (dispatch) => {
        getQuoteId()
            .then(data => {
                dispatch({ type: FETCH_QUOTEID_PE_SUCCESS, payload: data })
            })
            .catch(e => {
                console.log(e);
                return e;
            })
    }
}

async function getQuoteId() {
    try {
        let deliverySites = (await db.ref(`delivery/webPortal/deliverySiteMapping`).once('value')).val();
        let quoteList = await Promise.all(Object.keys(deliverySites ? deliverySites : {}).map(async siteId => {
            let quoteId = deliverySites[siteId];
            let userId = (await db.ref(`mapstore/quoteidtocustomerid/${quoteId}`).once('value')).val();
            let quoteData = {
                quoteId: quoteId,
                name: ''
            }
            if (userId) {
                let userData = (await db.ref(`/users/${userId}/mUser_Contact`).once('value')).val();
                let name = "";
                if (userData) {
                    name = `${userData.mContact_FirstName || ""} ${userData.mContact_FirstName || ""}`
                }
                let webPortalObj = (await db.ref(`delivery/webPortal/sites/${siteId}`).once('value')).val() || {};
                let state = webPortalObj.state_D;
                let gridTied = webPortalObj.systemType_D;
                if (!state || !gridTied) {
                    let siteObj = (await db.ref(`sites/${siteId}`).once('value')).val() || {};
                    state = state ? state : siteObj.mSite_State;
                    gridTied = gridTied ? gridTied : siteObj.mSite_SystemType;
                }
                quoteData = {
                    quoteId,
                    name,
                    state,
                    gridTied
                }
            }
            return quoteData;
        }));
        return quoteList;
    }
    catch (e) {
        return [];
    }
}

export function fetchPeMapping() {
    return (dispatch) => {
        fetchMapping()
            .then(data => {
                dispatch({ type: FETCH_PE_MAPPING_SUCCESS, payload: data.peMapping })
                dispatch({ type: FETCH_NAME_MAPPING_SUCCESS, payload: data.employeeList })
                dispatch({ type: FETCH_CITY_MAPPING_SUCCESS, payload: data.stateCityMap })
                dispatch({ type: FETCH_STATE_SUBSIDY_MAPPING, payload: data.stateSubsidyMapping })
                dispatch({ type: FETCH_CITY_DISCOM_MAPPING, payload: data.cityDiscomMapping })
            })
            .catch(e => {
                console.log(e);
                return e;
            })
    }
}

async function fetchMapping() {
    try {
        // let mapping = (await db.ref(`/website/peMapping`).once('value')).val();
        let [ mapping, stateCityList, centersList ] = await Promise.all([
            db.ref(`/website/peMapping`).once('value'),
            db.ref(`uploads/systemdesign/state_city_list_0`).once('value'),
            db.ref(`website/peTasksCenters`).once('value')
        ]);
        mapping = mapping.val() || {};
        stateCityList = stateCityList.val() || {};
        centersList = centersList.val() || {};
        let stateCityMap = {};
        let employeeList = {};
        let stateSubsidyMapping = {}, cityDiscomMapping = {};
        if (mapping.ALL) {
            await Promise.all(mapping.ALL.map(async employeeId => {
                let userObj = (await db.ref(`users/${employeeId}/mUser_Contact`).once('value')).val();
                let name = userObj ? `${userObj.mContact_FirstName} ${userObj.mContact_LastName}` : "";
                employeeList[employeeId] = name;
            }))
        }
        delete mapping["ALL"];
        Object.keys(mapping).map(state => {
            stateCityMap[state] = stateCityList[state] || [];
        })
        Object.values(centersList).map(row => {
            if(row.centerType == "Subsidy") {
                if(!stateSubsidyMapping[row.state]) {
                    stateSubsidyMapping[row.state] = [];
                }
                stateSubsidyMapping[row.state].push({label: row.centerName, value: row.key});
            }
            else if(row.centerType == "Discom") {
                if(!cityDiscomMapping[row.city]) {
                    cityDiscomMapping[row.city] = [];
                }
                cityDiscomMapping[row.city].push({label: row.centerName, value: row.key});
            }
        })
        return {
            peMapping: mapping,
            employeeList,
            stateCityMap,
            stateSubsidyMapping,
            cityDiscomMapping
        }
    }
    catch (e) {
        return {
            peMapping: {},
            employeeList: {},
            stateCityMap: {},
            stateSubsidyMapping: {},
            cityDiscomMapping: {}
        }
    }
}


export function createNewTask(taskObj, peTasks) {
    return (dispatch) => {
        createTask(taskObj, peTasks).then(tasks => {
            dispatch({ type: FETCH_PETASKS_SUCCESS, payload: tasks })
        })
    }
}

async function createTask(taskObj, peTasks) {
    try {
        if (checkFields(taskObj)) {
            if(!taskObj.size_del && taskObj.type.includes("Layout")) {
                swal('Please fill Size_del variable in the Quote Id View.');
                return peTasks;
            }
            let { date, comment="", pmComment="", employee="", type, startTime="", quoteId="", state, priority, city, centerKey = "" } = taskObj;
            let taskDate = moment(date, "YYYY-MM-DD").format('YYYYMMDD');
            let taskStartTime = startTime?moment(`${date} ${startTime}:00`, 'YYYY-MM-DD HH:mm').valueOf():"";
            let formdata = new URLSearchParams();
            formdata.append("date", taskDate);
            formdata.append("startTime", taskStartTime);
            formdata.append("state", state);
            formdata.append("quoteId", quoteId);
            formdata.append("comment", comment);
            formdata.append("pmComment", pmComment);
            formdata.append("type", type);
            formdata.append("employeeId", employee);
            formdata.append("priority", priority);
            formdata.append("city", city);
            formdata.append("centerKey", centerKey);
            let data = await axios.post("https://operations.zunroof.com/api/createPeTasks", formdata, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            });
            if (data && data.data) {
                let addTaskStatus = data.data;
                if (addTaskStatus.Error) {
                    swal(addTaskStatus.Error);
                    return peTasks;
                }
                else {
                    swal('Task Added');
                    let addedTask = {
                        date: taskDate,
                        startTime: taskStartTime,
                        state,
                        comment,
                        pmComment,
                        type,
                        employeeId: employee,
                        taskId: addTaskStatus.Data,
                        quoteId,
                        priority:priority,
                        status: "0",
                        statusVal: "Pending",
                        city: city
                    }
                    return [addedTask, ...peTasks];
                }
            }
            return peTasks;
        }
        else {
            swal('Please Check all the fields');
            return peTasks;
        }
    }
    catch (e) {
        return peTasks;
    }
}

function checkFields(taskObj) {
    try {
        if(taskObj.type != "Subsidy" && !taskObj.quoteId) {
            return false;
        }
        let status = true;
        taskFields.map(field => {
            if (!taskObj[field]) {
                status = false;
            }
        })
        return status;
    }
    catch (e) {
        return false;
    }
}


export function updateTask(task, peTasks) {
    return (dispatch) => {
        updatePeTask(task, peTasks).then(data => {
            dispatch({ type: FETCH_PETASKS_SUCCESS, payload: data })
        })
    }
}

async function updatePeTask(task, peTasks) {
    try {
        if (checkFields(task) && task.id) {
            let { date, comment="", pmComment="", employee="", type, startTime="", quoteId="", state, priority, status, statusVal, city, centerKey = "" } = task;
            let taskDate = moment(date, "YYYY-MM-DD").format('YYYYMMDD');
            let taskStartTime = startTime?moment(`${date} ${startTime}:00`, 'YYYY-MM-DD HH:mm').valueOf():"";
            let formdata = new URLSearchParams();
            formdata.append("taskId", task.id);
            formdata.append("date", taskDate);
            formdata.append("startTime", taskStartTime);
            formdata.append("state", state);
            formdata.append("quoteId", quoteId);
            formdata.append("comment", comment);
            formdata.append("pmComment", pmComment);
            formdata.append("type", type);
            formdata.append("employeeId", employee);
            formdata.append("priority", priority);
            formdata.append("status", status||"0");
            formdata.append("city", city);
            formdata.append("centerKey", centerKey);
            let data = await axios.post("https://operations.zunroof.com/api/updatePeTasks", formdata, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            });
            if (data && data.data) {
                let addTaskStatus = data.data;
                if (addTaskStatus.Error) {
                    swal(addTaskStatus.Error);
                    return peTasks;
                }
                else {
                    swal('Task Updated');
                    let tasks = [...peTasks];
                    let index = tasks.findIndex(val => val.taskId == task.id);
                    tasks[index] = {
                        date: taskDate,
                        startTime: taskStartTime,
                        state,
                        comment,
                        pmComment,
                        type,
                        employeeId: employee,
                        taskId: task.id,
                        quoteId,
                        priority,
                        status,
                        statusVal,
                        city
                    };
                    return tasks;
                }
            }
            return peTasks;
        }
        else {
            swal('Please Check all the fields');
            return peTasks;
        }
    }
    catch (e) {
        return peTasks;
    }
}

export function deleteTask(taskId, peData) {
    return (dispatch) => {
        let taskData = (peData || []).find(task => taskId == task.taskId);
        let date = taskData.date;
        let formdata = new URLSearchParams();
        formdata.append("taskId", taskId);
        formdata.append("date", date);
        axios.post("https://operations.zunroof.com/api/removePeTasks", formdata, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then(data => {
            if (data && data.data) {
                let res = data.data;
                if (res.Error) {
                    swal(res.Error);
                }
                else {
                    swal("Task Deleted");
                    let finalData = (peData || []).filter(task => task.taskId != taskId);
                    dispatch({ type: FETCH_PETASKS_SUCCESS, payload: finalData });
                }
            }
        })
    }
}

export function searchSites(searchString = '', type) {
    return async (dispatch) => {
        try {
            let response = await axios.post("https://operations.zunroof.com/api/searchsites", { searchString, type, key: "4KdOQUVM8eJyEmN" });
            let data = response.data || [];
            dispatch({ type: FETCH_QUOTEID_PE_SUCCESS, payload: data || [] })
        }
        catch (e) {
            console.log(e);
            dispatch({ type: FETCH_QUOTEID_PE_SUCCESS, payload: [] })
        }
    }
}

export function resetAutoAssign() {
    return (dispatch) => {
        dispatch({ type: UPDATE_AUTO_ASSIGN_STATUS, payload: { value: 0 } });
        return;
    }
}


export function autoAssignTasks(data) {
    return async (dispatch) => {
        if (!data.city || !data.date) {
            swal('Please Check all the fields');
            return;
        }
        dispatch({ type: UPDATE_AUTO_ASSIGN_STATUS, payload: { value: 1, text: "Assigning tasks. Please wait..." } });
        data.date = moment(data.date, "YYYY-MM-DD").format("YYYYMMDD");
        let response = await axios.post("https://operations.zunroof.com/api/assignpetasks", data);
        response = response.data || {};
        if (response.error) {
            dispatch({ type: UPDATE_AUTO_ASSIGN_STATUS, payload: { value: 3, text: response.error } });
            return;
        }
        dispatch({ type: UPDATE_AUTO_ASSIGN_STATUS, payload: { value: 3, text: response.data } });
        return;
    }
}