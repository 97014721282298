import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

import { incentive, getTeamAndCity } from '../actions/fetchincentive';
import { setincentiveupdate } from '../actions/setincentiveupdate';

import { IncentiveComponent } from '../components/IncentiveComponent.jsx';

import { incentiveColumn } from '../config/column_fields';
import { CSVLink } from 'react-csv';

export class IncentivePage extends Component {
	constructor(props) {
	  super(props);
	
	  this.state = {
	  	loading: true,
	  	data:[],
	  	columns:[],
	  	teamMapping:[],
	  	cityMapping:[],
	  	teamSelected:[],
		citySelected:[],
		quoteIdOption:[],
		quoteIdSelected:{},
		cityFound:"",
		teamFound:"",
		startDate:0,
		endDate:9999999999999,
		mainType:null,
		openUpdate:false,
		updateStatus:null,
		updated: {
			team:"",
			city:"",
			quoteId:""
		}
	  };

	 	this.subTypeOptions = {
			Adjustment: ["Payment 1","Payment 2","Net Metering", "Other"],
			Quality: ["Manual","Compliant"],
			Payments: ["P2","P3","P4 Late"],
			"Net Metering": ["Achieved"]
		}
	}

	componentDidMount() {
		// console.log("DID MOUNT")
		if(this.props.user){
			if(this.props.user.level>=1600){
				this.props.incentive();
			}
		}
	}

	componentWillReceiveProps({user, loading, data, quoteIdList, cityFound, teamFound, quoteIdFound, updateStatus}) {
		if(!loading&& data.length>0 && this.state.data.length===0) {
			console.log("SEE")
			let teamListPresent = {};
			let cityListPresent = {};
			data.forEach(val => {
				teamListPresent[val.team] = 1;
				cityListPresent[val.city] = 1;
			});
			let teamMapping = Object.keys(teamListPresent).map(val => {
				return {label:val,value:val};
			})
			let cityMapping = Object.keys(cityListPresent).map(val => {
				return {label:val,value:val};
			})
			let quoteIdOption = Object.keys(quoteIdList).map(siteId => {
				return { label:quoteIdList[siteId], value:siteId }
			})
			this.setState({
				loading,
				data,
				teamMapping,
				cityMapping,
				quoteIdOption
			})
		}
	}

	handleMultiSelectChange = (e,name) => {
		if(name==="quoteIdSelected") {
			console.log(e);
			this.props.getTeamAndCity(e.value,e.label)
			let valArray = [];
			valArray.push(e)
			e = [];
			e = valArray;
		}
		this.setState({
			[name]: e.map(val => val.value)
		})
	}

	handleInputChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	onOpenModal = (name) => {
		this.setState({
			[name]: true,
		})
	}

	onCloseModal = (name) => {
		this.setState({
			[name]: false
		})
	}

	applyFilter = () => {
		let filteredData = [...this.props.data];
		let { startDate, endDate, teamSelected, citySelected } = this.state;
		startDate 	= startDate?new Date(startDate).setHours(0,0,0,0):0;
		endDate 	= endDate?new Date(endDate).setHours(23,59,59,59):999999999999999;
		let filter = filteredData.filter(val => {
			if((!teamSelected.length||teamSelected.indexOf(val.team)>-1)&&(!citySelected.length||citySelected.indexOf(val.city)>-1)&&(val.date>=startDate&&val.date<=endDate)) {
				return true;
			}else{
				return false;
			}
		})
		this.setState({
			data: filter
		})
	}

	handleFormSubmit = (e) => {
		e.preventDefault();
		let { quoteIdFound, teamFound, currentResponsible, setincentiveupdate, user } = this.props;
		let { dateUpdate, mainType, subType, mainValue } = e.target;
		let data = {
			date:dateUpdate.value,
			value: mainValue.value,
			team: teamFound,
			quoteId: quoteIdFound,
			responsible: currentResponsible,
			subtype: subType.value,
			type: mainType.value,
		}
		setincentiveupdate(user,data);
	}

	render() {
		let { loading, data, teamMapping, openUpdate, cityMapping, updated, mainType, quoteIdOption } = this.state;
		let { teamMap, cityFound, teamFound, quoteIdFound, updateStatus, user } = this.props;
		let { handleMultiSelectChange, handleInputChange, applyFilter, onModalOpen, subTypeOptions, onCloseModal, onOpenModal, handleFormSubmit } = this;
		if(this.props.user===null){
			return <Redirect to={"/login?redirect_uri=incentivepage"} />;
		}

		if(this.props.user && this.props.user.level<1600) {
			return ( 
				<div>
					Contact Admin
				</div>
			)
		}

		return (
			<div style={{"marginTop":50}} className="container-fluid">
				{this.props.user.level>=2600?<CSVLink filename={"Incentive_Data.csv"} data={data} headers={incentiveColumn.map((val, index) => { return { label: val.csvHeader || val.Header, key: val.csvAccessor ? val.csvAccessor : val.accessor } })} type="button" className="btn btn-info">
					Download
				</CSVLink>:""}
				<IncentiveComponent 
					user= {user}
					teamMapping= {teamMapping}
					handleMultiSelectChange= {handleMultiSelectChange}
					cityMapping= {cityMapping}
					handleInputChange= {handleInputChange}
					applyFilter= {applyFilter}
					onModalOpen= {onModalOpen}
					loading= {loading}
					data= {data}
					incentiveColumn= {incentiveColumn}
					subTypeOptions= {subTypeOptions}
					quoteIdOption= {quoteIdOption}
					openUpdate= {openUpdate}
					onOpenModal= {onOpenModal}
					teamMap= {teamMap}
					onCloseModal= {onCloseModal}
					updateStatus= {updateStatus}
					cityFound= {cityFound}
					teamFound= {teamFound}
					quoteIdFound= {quoteIdFound}
					updated= {updated}
					mainType= {mainType}
					handleFormSubmit= {handleFormSubmit}
				/>
			</div>
		);
	}
}

function mapStateToProps({user, incentivedata, mapping}) {
	let { loading, data, quoteIdList, quoteIdSelected, cityFound, teamFound, updateStatus,currentResponsible } = incentivedata;
	let { teamMap } = mapping;
	return {
		user : user,
		loading, data, quoteIdList, quoteIdFound:quoteIdSelected, cityFound, teamFound, teamMap, updateStatus, currentResponsible
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ incentive, getTeamAndCity, setincentiveupdate }, dispatch);
};

export default connect(mapStateToProps,mapDispatchToProps)(IncentivePage);