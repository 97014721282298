import React, { Component } from 'react';
import ReactTable from "react-table";
import { qualityReportBasicConfig, qualityReportChecksConfig, qualityReportCommentsConfig } from '../config/column_fields';
import { styleSheet } from '../styles/styleSheet'

export class QualityReport extends Component {
	constructor(props) {
	  super(props);
	
	  this.state = {
	  	data: [],
		show: true
	  };
	  this.handleOnClickComment = this.handleOnClickComment.bind(this);
	}

	componentWillMount() {
		this.setState({
			data : this.props.data,
			open : false
		})
	}

	componentWillRecieveProps({data}) {
		this.setState({
			data
		})
	}

	handleOnClickComment() {
		this.setState({
			show: !this.state.show
		})
	}

    render() {
		return (
			<div className="row row_data">
				<div className="col-12">
					<div className="page-title">
						<div style={styleSheet.titlePage}>
							<div style={styleSheet.titlePage.quote}>
								<span style={styleSheet.dropRightArrow} onClick={this.handleOnClickComment}><i className={this.state.show?"fas fa-arrow-down open_arrow":"fas fa-arrow-right open_arrow"}></i>Quality Report</span>
							</div>
						</div>
					</div>
				</div>
				<div style={this.state.show?styleSheet.show:styleSheet.hide} className="col-12">
					<div className="page_data">
                        <div class="card shadow">
                            <div class="card-body">
                                <div class="card-title">
                                    <h5>Basic</h5>
                                </div>
                                <ReactTable
                                    columns={qualityReportBasicConfig}
                                    defaultPageSize={this.state.data.length > 5 ? 5 : this.state.data.length}
									data={this.state.data}
									defaultSorted={[{id: "time",desc: true}]}
                                />
                            </div>
                        </div>
                        <div class="card shadow" style={{marginTop:"10px"}}>
                            <div class="card-body">
                                <div class="card-title">
                                    <h5>Checks</h5>
                                </div>
                                <ReactTable
                                    columns={qualityReportChecksConfig}
                                    defaultPageSize={this.state.data.length > 5 ? 5 : this.state.data.length}
									data={this.state.data}
									defaultSorted={[{id: "time",desc: true}]}
                                />
                            </div>
                        </div>
                        <div class="card shadow" style={{marginTop:"10px"}}>
                            <div class="card-body">
                                <div class="card-title">
                                    <h5>Comments</h5>
                                </div>
                                <ReactTable
                                    columns={qualityReportCommentsConfig}
                                    defaultPageSize={this.state.data.length > 5 ? 5 : this.state.data.length}
									data={this.state.data}
									defaultSorted={[{id: "time",desc: true}]}
                                />
                            </div>
                        </div>
					</div>
				</div>
			</div>
		);
	}
}
