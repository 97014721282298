import { db } from '../connection';
import axios from 'axios';

export function calculateDateDiff(date,limit) {
	return Math.ceil((Math.abs(new Date().getTime() - date))/(1000*3600*24))>limit;
}

export function calculateDateAge(date) {
	return Math.ceil((Math.abs(new Date().getTime() - date))/(1000*3600*24))
}

export function calculateSpecificDateAge(date,fromDate) {
	return Math.ceil(((date - new Date(fromDate).getTime()))/(1000*3600*24))
}

export function calculateWeek() {
	return Math.ceil((Math.abs(new Date().getTime() - new Date("06/01/2018").getTime()))/(1000*3600*24*7));
}

export function sortKeyData(val) {
	return function(a, b) {
		if (new Date(a[val]).getTime() > new Date(b[val]).getTime()) {
			return 1;
		} else if (new Date(a[val]).getTime() < new Date(b[val]).getTime()) {
			return -1;
		}
		return 0;
	}
}

export async function getNameById(userId) {
  try {
    let userObj = (await db.ref(`users/${userId}/mUser_Contact`).once('value')).val();
    let name = userObj ? `${userObj.mContact_FirstName || ""} ${userObj.mContact_LastName || ""}` : "";
    return name.trim();
  } catch (e) {
    console.log(e);
    return "";
  }
}

export function emailToDot(value) {
	return value.replace(/\__dot__/g,'.');
}

export function dotToEmail(value) {
	return value.replace(/\./g,'__dot__');
}

export async function sendEmail(subject, message, emailList) {
	console.log('sending email',subject,message,emailList)
    try {
		  let response = await axios.post(`https://www.zunroof.com/server/sendEmail.php`, {
		  	senderEmail: "communications@zunroof.in",
		  	emailList: emailList,
		  	subject: subject,
		  	body: message,
		  	replyTo: "devteam@zunroof.com"
		  })
		  console.log(response)
      return response;
    }
    catch(e) {
        console.log("Error in sending Email", e);
        return;
    }
}