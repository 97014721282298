import { base, db } from '../connection';
import axios from 'axios';


export function setquotedata(siteId,text,user) {
	console.log(new Date());
	return (dispatch) => {
		console.log(siteId,text,user);
		fetchUserComment(siteId,text,user);
	}
}

function getUserObject(user,cb){
   base.fetch('/users/'+user+'/mUser_Contact',{})
   .then(data=>{
	   return cb(null,data);
   })
   .catch(error=>{
	   return cb(error);
   })
}

function createDateObj() {
	let dateVal = new Date();
	let dateObj = {
		date:dateVal .getDate(),
		day:dateVal.getDay(),
		hours:dateVal.getHours(),
		minutes:dateVal.getMinutes(),
		month:dateVal.getMonth()+1,
		seconds:dateVal.getSeconds(),
		time:dateVal.getTime(),
		timezoneOffset:dateVal.getTimezoneOffset(),
		year:dateVal.getFullYear()
	}
	return dateObj;
}


function fetchUserComment(siteId,text,user){
	 getUserObject(user, (err,userObj) => {
	 	if(err){
	 		console.log(err);
	 	}else{
	 		let userphone = user;
			base.fetch('/delivery/sites/'+siteId, {})
			.then(data => {
				let commentsArray =[];
				if(data.comments){
				 commentsArray = [...[],...data.comments];
				let lastValue = JSON.parse(JSON.stringify(commentsArray[commentsArray.length-1]));
				lastValue.commentId=""+commentsArray.length;
				lastValue.comment=text
				lastValue.commentDate=createDateObj();
				lastValue.employeeId = ""+user;
				lastValue.employeeName=userObj.mContact_FirstName+' '+userObj.mContact_LastName;
				commentsArray.push(lastValue);
				pushCommentUponStageChange(siteId,commentsArray);
				} else {
					var finalCommentArray = {
						comment: text ,
						commentDate:createDateObj(),
						commentId:"0",
						employeeId:""+user,
						employeeName:userObj.mContact_FirstName+' '+userObj.mContact_LastName
					}
					commentsArray.push(finalCommentArray);
					pushCommentUponStageChange(siteId,commentsArray);
				}
			})
	 	}
	 });
}

function pushCommentUponStageChange(siteId,data) {
	var immediatelyAvailableReference  = base.post('/delivery/sites/'+siteId+'/comments', {
	    data: data	
	    ,then(err){
	      if(!err){
	      	console.log("SUCCESS")
	      }else{
	      	console.log(err);
	      }
	    }
	  });
}

export function setCertificateData(siteId,data, amcPeriod, warrantyYears80, warrantyYears90) {
	console.log(new Date());
	return (dispatch) => {
		updateCertificateData(siteId, data, amcPeriod, warrantyYears80, warrantyYears90).then(data => {
			console.log(data);
		})
	}
}

async function updateCertificateData(siteId, data, amcPeriod, warrantyYears80, warrantyYears90){
	try{
		if(!siteId) return;
		await db.ref(`delivery/webPortal/sites/${siteId}`).update({
			certificateData: data,
			amcPeriod,
			warrantyYears80,
			warrantyYears90
		})
		// await db.ref(`delivery/webPortal/sites/${siteId}/certificateData`).set(data);
		// await db.ref(`delivery/webPortal/sites/${siteId}/amcPeriod`).set(amcPeriod);
		return 'Certificate Data updated.'
	}
	catch(e){
		return 'Error updating certificate data';
	}
}

export function generateCertificate(quoteid, name, address_D, size, certificateData, phoneNumber, amcPeriod, warrantyYears80, warrantyYears90, netMetringDate) {
	console.log(new Date());
	return (dispatch) => {
		createCertificate(quoteid, name, address_D, size, certificateData, phoneNumber, amcPeriod, warrantyYears80, warrantyYears90, netMetringDate).then(data => {
			console.log(data);
			alert(data);
		})
	}
}

async function createCertificate(quoteid, name, address_D, size, certificateData, phoneNumber, amcPeriod, warrantyYears80, warrantyYears90, netMetringDate){
	try{
		let formData = new URLSearchParams();
		formData.append("key", "5ju76h5sc0qiilu");
		formData.append("quoteId", quoteid);
		formData.append("name", name);
		formData.append("address", address_D);
		formData.append("size", size);
		formData.append("certificateData", JSON.stringify(certificateData));
		formData.append("userId", phoneNumber);
		formData.append("amcPeriod", amcPeriod);
		formData.append("warrantyYears80", warrantyYears80);
		formData.append("warrantyYears90", warrantyYears90);
		formData.append("netMeteringDate", netMetringDate);
		let data = await axios.post("https://operations.zunroof.com/api/uploadCertificateFile", formData, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
		});
        console.log(data?data.data:{});
        return 'Certificate Generated.'
	}
	catch(e){
		return 'Error uploading certificate file';
	}
}