import { FETCHING_INCENTIVE_START,
		FETCHING_INCENTIVE_SUCCESS,
		INCENTIVE_UPADTE_START,
		INCENTIVE_UPADTE_SUCCESS,
		INCENTIVE_UPADTE_FAILED,
		FETCHED_QUOTEIDLIST,
		FETCHING_INCENTIVE_DETAILS,
		FETCHING_TEAM_MAP_SUCCESS,
		 } from './actiontypes';

import { db } from '../connection';
import { fetch } from '../helper/firebaseFunctions';
import moment from 'moment';

export function incentive() {
  	return (dispatch) => {
		dispatch({type:FETCHING_INCENTIVE_START})
  		Promise.all([fetch('employeelist','/'),fetch('delivery','teamtoEmployeeMapping'),fetchIncentiveData(),fetch('delivery/webPortal','deliverySiteMapping')])
  		.then(values => {
  			let dataArray = values[2].map(val => {
				// if(val.team === "" || val.team === "No Team"){
				// 	val.team = "team1";
				// }
  				val.team = values[1][val.team]?values[1][val.team].teamName:'';
  				val.responsible = `${values[0][val.responsible]?values[0][val.responsible].fname:""} ${values[0][val.responsible]?values[0][val.responsible].lname:""}`;
				  val.subtype = val.subtype.toUpperCase()==='Achived'.toUpperCase()?'Achieved':val.subtype;
				  val.viewDate = val.date?moment(val.date).format("DD MMM, YYYY"):"";
  				return val;
  			});
  			let teamMap = {};
  			Object.keys(values[1]).forEach(teamId => {
  				teamMap[teamId] = values[1][teamId].teamName;
  			})
  			dispatch({type:FETCHING_TEAM_MAP_SUCCESS,payload: teamMap})
  			dispatch({type:FETCHED_QUOTEIDLIST,payload: values[3]})
  			dispatch({type:FETCHING_INCENTIVE_SUCCESS,payload: dataArray.filter(val => val.stage!=11)})
  		}).catch(error => {
  			console.log(error);
  		})
 	}
}

function fetchIncentiveData() {
	return new Promise((resolve,reject) => {
		db.ref('/delivery/webPortal/operationsIncentives').child('timeWiseHistory')
  		.orderByChild('date')
  		.startAt(1535740200000)
  		.once('value')
  		.then(snapshot => {
			  let incentiveData = Object.values(snapshot.val());

  			let PromiseList = incentiveData.map(val => {
  				let quoteId = val.quoteId;
  				return getCity(quoteId)
  			})
  			Promise.all(PromiseList)
  			.then(values => {
  				let finalData = incentiveData.map((val,index) => {
  					val.value = Math.round(parseFloat(val.value)*100)/100;
  					return {...val,...values[index]}
				  })
  				resolve(finalData);
  			})
  		}).catch(error => {
  			reject(error);
  		})
	})
}

function getCity(quoteId) {
	return new Promise((resolve,reject) => {
		fetch('quotestaken',quoteId).then(siteIdObj => {
			let { siteId } = siteIdObj;
			Promise.all([fetch('delivery/sites',`${siteId}/teamId`),getUserId(siteId),fetch('delivery/sites',`${siteId}/stage`),fetch('delivery/sites',`${siteId}/responsilbleIdDelevery`)])
			.then(values => {
				let stageValues = values[2];
				resolve({teamCurrent:values[0],city:values[1],stage:stageValues?stageValues[stageValues.length-1].current_State:0,currentResponsible:values[3]})
			})
		}).catch(error => {
			console.log(error);
		});
	})
}

function getUserId(siteId) {
	return new Promise((resolve,reject) => {
		fetch('delivery/sites',`${siteId}/siteOwnerId`)
		.then(userId => {
			fetch(`users/${userId}/mUser_Contact`,'mContact_City')
			.then(city => {
				resolve(city)
			}).catch(error => {
				reject(error)
			})
		}).catch(error => {
				reject(error)
			})
	})
}

export function getTeamAndCity(siteId, quoteId) {
	return (dispatch) => {
		dispatch({type:INCENTIVE_UPADTE_START,payload:{status:"alert alert-primary",text:"Please wait..."}});
		getCity(quoteId)
		.then(values => {
			let data = {
				quoteIdSelected: quoteId,
				cityFound: values.city,
				teamFound: values.teamCurrent,
				currentResponsible: values.currentResponsible
			}
			dispatch({type:INCENTIVE_UPADTE_SUCCESS,payload:{status:"",text:""}});
			dispatch({type:FETCHING_INCENTIVE_DETAILS,payload:data});
		}).catch(error => {
			dispatch({type:INCENTIVE_UPADTE_FAILED,payload:{status:"alert alert-warning",text:"Oops! Error"}});
		})
	}
}

/*
	date: 1492713000000
	quoteId: "APR17023"
	responsible: "+918448380949"
	subtype: "P3"
	team: "team5"
	type: "Payments"
	value: 1
*/
