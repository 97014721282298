import { FETCH_TICKETREPORT_SUCCESS, FETCH_HISTORY_SUCCESS } from '../actions/actiontypes'
const INIT_STATE = {
	
};


export default function (state = INIT_STATE, action) {
	switch (action.type) {
		case FETCH_TICKETREPORT_SUCCESS:
			return { ...state, data: {...action.payload} };
		case FETCH_HISTORY_SUCCESS:
			return { ...state, history: [...action.payload] };
		default:
			return state;
	}
}
