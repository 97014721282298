import { FETCH_TICKETS_START, FETCH_TICKETS_SUCCESS, FETCH_LAST_COMMENT } from '../actions/actiontypes'

const INIT_STATE = {
	data:{},
	completed:false,
}

export default function(state = INIT_STATE, action) {
	switch(action.type){
		case FETCH_TICKETS_START:{
			return {...state};
		}
		case FETCH_TICKETS_SUCCESS:{
			return {...state, data : action.payload};
		}
		case FETCH_LAST_COMMENT:{
			return {...state, lastComment : action.payload};
		}
		default:
			return state;
	}
}