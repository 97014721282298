import axios from 'axios';

export function sendFeedbackMsg(quoteId, mobileNumber, type, name) {
    console.log(new Date());
    return (dispatch) => {
        sendMessage(quoteId, mobileNumber, type, name).then(data => {
            console.log(data);
            // alert(data);
        })
    }
}


async function sendMessage(quoteId, mobileNumber, type, name) {
    try {
        let content = getContent(quoteId, name, type);
        mobileNumber = mobileNumber.substring(3, 14);
        if(content && mobileNumber){
            console.log("Mobile NUmber", mobileNumber);
            // mobileNumber = '7011352085';
            await axios.get(`https://www.zunroof.com/websiteapi/amazonSns.php?key=bBXbYtAHZu2rwVB3lzJ9&message=${content}&phone=${mobileNumber}`);
        }
    }
    catch (e) {
        console.log(e);
        return 'Some Error Occurred While Sending message';
    }
}


function getContent(quoteId, name, type) {
    try {
        let content = '';
        switch (type) {
            case 'complaint':
                content = `Hi ${name}%0aThank you for your trust in us!%0aIn order to provide best services to out clients, can you please provide us your feedback%0ahttps://forms.gle/iiT4g2zTPZMZo1YL6`;
                break;
            case 'panel':
                content = `Hi ${name}%0aThank you for your trust in us!%0aIn order to provide best services to out clients, can you please provide us your feedback%0ahttps://bit.ly/3hCGBSp`;
                break;
        }
        return content;
    }
    catch (e) {
        return '';
    }
}