import { LOGIN, LOGIN_CHECK, LOGOUT } from './actiontypes';
import { firebase, base } from '../connection';

export function logincachecheck() {
	return (dispatch) => {
		if(localStorage.getItem('user_f')){
			checkPermission(dispatch,JSON.parse(localStorage.getItem('user_f')).phoneNumber);
		}else{
			dispatch({type:LOGIN,payload:null})
		}
	}
}

export function login(valueCode,confirmationResult) {
	if(valueCode.length==6){
		return (dispatch) => {
		 	confirmationResult.confirm(valueCode).then(result => {
				// User signed in successfully.
				console.log(result.user.uid);
				localStorage.setItem("user_f", JSON.stringify(result.user));
				checkPermission(dispatch,result.user.phoneNumber);
			}).catch(function (error) {
			  
			});
		}
	}
}

function checkPermission(dispatch,user) {
	console.log(user);
	if(user){
		base.fetch('/webDelivery/userrolemapping/'+user, {})
		.then(data => {
			let userInfo = {
				phoneNumber: user,
				level: data
			}
			console.log(userInfo)
			dispatch({type:LOGIN,payload:userInfo});
		})
		.catch(error=>{
			
		})
	}else{
		dispatch({type:LOGIN,payload:null})
	}
}

// export function checkIfAllowed(){
// 	base.fetch('/webDelivery/userrolemapping/',{})
// 	 	.then(data=>{
// 	 		console.log(data);	
// 	        // return data[""+JSON.parse(localStorage.getItem("user_f")).phoneNumber]==null?true:false;
// 		 })
// 		 .catch(error=>{
// 			console.log(error);
// 		}) 
// }

export function logout() {
	return (dispatch)=> {
		firebase.auth().signOut()
		.then(function() {
			localStorage.removeItem("user_f");
			dispatch({type:LOGOUT,payload:null});
		})
		.catch(function(error) {
		
		});
	}
}