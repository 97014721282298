import { base } from '../connection';


export function setvendordata(siteId,vendor) {
	console.log(new Date());
	return (dispatch) => {
		pushVendor(siteId,vendor);
	}
}


function pushVendor(siteId,data) {
	base.post('/delivery/sites/'+siteId+'/vendor', {
	    data: data
	    ,then(err){
	      if(!err){
	      	console.log("SUCCESS");
	      }else{
	      	console.log(err);
	      }
	    }
	  });
}