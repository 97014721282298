import { base } from '../connection';

export function setresponsible(siteId,currentUser,fromTeamId,fromUser,ownerId,quoteId,newTeamID,newResponsible,oldResponsibleName,newResponsibleName) {
	console.log(new Date());
	return (dispatch) => {
		let data = {
			"datetransfer" : createDateObj(),
		    "employeetransferring" : currentUser,
		    "fromTeamId" : fromTeamId,
		    "fromUser" : fromUser,
		    "ownerId" : ownerId,
		    "quoteId" : quoteId,
		    "siteId" : siteId,
		    "toTeamId" : newTeamID,
		    "toUser" : newResponsible
		}
		pushUserInfo(siteId,data,currentUser,oldResponsibleName,newResponsibleName);
	}
}

function pushUserInfo(siteId,data,currentUser,oldResponsibleName,newResponsibleName) {
	console.log(data)
	var immediatelyAvailableReference  = base.push('delivery/changeResponsibleEmployee/'+siteId, {
	    data: data
	    ,then(err){
	      if(!err){
	      	console.log("SUCCESS");
	      	addComment(siteId,data,currentUser,oldResponsibleName,newResponsibleName);
	      }else{
	      	console.log(err);
	      }
	    }
	  });
}

function addComment(siteId,data1,currentUser,oldResponsibleName,newResponsibleName) {
	getUserObject(currentUser, (err,userObj) => {
	 	if(err){
	 		console.log(err);
	 	}else{
			base.fetch('/delivery/sites/'+siteId, {})
			.then(data => {
				let commentsArray =[];
				if(data.comments){
				 commentsArray = [...[],...data.comments];
				let lastValue = JSON.parse(JSON.stringify(commentsArray[commentsArray.length-1]));
				lastValue.commentId=""+commentsArray.length;
				lastValue.comment=new Date()+':\n\Responsible changed by:'+userObj.mContact_FirstName+'' +userObj.mContact_LastName+'\n\nNew Responsible:' +newResponsibleName+'\nOld Responsible:'+oldResponsibleName;
				lastValue.commentDate=createDateObj();
				lastValue.employeeId = currentUser;
				lastValue.employeeName=userObj.mContact_FirstName+' '+userObj.mContact_LastName;
				commentsArray.push(lastValue);
				pushCommentUponStageChange(siteId,commentsArray);
				} else {
					var finalCommentArray = {
						comment: new Date()+':\n\Responsible changed by:'+userObj.mContact_FirstName+'' +userObj.mContact_LastName+'\n\nNew Responsible:' +newResponsibleName+'\n\nOld Responsible:'+oldResponsibleName,
						commentDate:createDateObj(),
						commentId:""+0,
						employeeId:""+currentUser,
						employeeName:userObj.mContact_FirstName+' '+userObj.mContact_LastName
					}
					commentsArray.push(finalCommentArray);
					pushCommentUponStageChange(siteId,commentsArray);
				}
			})
	 	}
	 });
}

function pushCommentUponStageChange(siteId,data) {
	var immediatelyAvailableReference  = base.post('/delivery/sites/'+siteId+'/comments', {
	    data: data	
	    ,then(err){
	      if(!err){
	      	console.log("SUCCESS")
	      }else{
	      	console.log(err);
	      }
	    }
	  });
}


function getUserObject(user,cb){
   base.fetch('/users/'+user+'/mUser_Contact',{})
   .then(data=>{
	   return cb(null,data);
   })
   .catch(error=>{
	   return cb(error);
   })
}

function createDateObj() {
	let dateVal = new Date();
	let dateObj = {
		date:dateVal.getDate(),
		day:dateVal.getDay(),
		hours:dateVal.getHours(),
		minutes:dateVal.getMinutes(),
		month:dateVal.getMonth()+1,
		seconds:dateVal.getSeconds(),
		time:dateVal.getTime(),
		timezoneOffset:dateVal.getTimezoneOffset(),
		year:dateVal.getFullYear()
	}
	return dateObj;
}