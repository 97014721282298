import { CREATE_NEW_CENTER_START, CREATE_NEW_CENTER_SUCCESS, FETCH_CENTERS_SUCCESS, FETCH_CITIES } from '../actions/actiontypes';

export default function (state = {}, action) {
    switch (action.type) {
        case CREATE_NEW_CENTER_START: {
            return { ...state, ...action.payload };
        }
        case CREATE_NEW_CENTER_SUCCESS: {
            return { ...state, ...action.payload };
        }
        case FETCH_CITIES: {
            return { ...state, ...action.payload }
        }
        case FETCH_CENTERS_SUCCESS: {
            return { ...state, ...action.payload }
        }
        default:
            return state;
    }
}  