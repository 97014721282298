import { GENERATE_OTP_FAILED, GENERATE_OTP_SUCCESS, VERIFY_OTP_FAILED, VERIFY_OTP_SUCCESS } from '../actions/actiontypes'

const INIT_STATE = {
	data:{},
	completed:false,
}

export default function(state = INIT_STATE, action) {
	switch(action.type){
		case GENERATE_OTP_SUCCESS:{
            console.log("INSUCCESS");
			return {...state, data : action.payload};
		}
		case GENERATE_OTP_FAILED:{
			return {...state, data : action.payload};
        }
        case VERIFY_OTP_SUCCESS:{
			return {...state, data : action.payload};
		}
		case VERIFY_OTP_FAILED:{
			return {...state, data : action.payload};
		}
		default:
			return state;
	}
}