import React from 'react';
import Select from 'react-select';
import ReactTable from "react-table";
import Modal from 'react-responsive-modal';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import { changeToViewDate } from '../helper/dateChanger';

import '../styles/vendor.css';

const data = [];

const columns = [{
	Header: 'Name',
	accessor: 'name'
}, {
	Header: 'Age',
	accessor: 'age'
}];


var loading = true;
export const VendorButtonComponent = ({ handleOnViewChange, user }) => {
	return (
		<div className="formButtons">
			{user.level >= 1600 ? <button className="btn btn-primary paymentViewButton" onClick={handleOnViewChange} value='panels'>Panel</button> : ""}
			{user.level >= 1600 ? <button className="btn btn-primary paymentViewButton" onClick={handleOnViewChange} value='inverter'>Inverter</button> : ""}
			{user.level >= 1600 ? <button className="btn btn-primary paymentViewButton" onClick={handleOnViewChange} value='structure'>Structure</button> : ""}
			{user.level >= 1600 ? <button className="btn btn-primary paymentViewButton" onClick={handleOnViewChange} value='v2'>V2</button> : ""}
			{user.level >= 1600 ? <button className="btn btn-primary paymentViewButton" onClick={handleOnViewChange} value='v1'>V1</button> : ""}
			{user.level >= 1600 ? <button className="btn btn-primary paymentViewButton" onClick={handleOnViewChange} value='other'>Other</button> : ""}
			{user.level === 1800 || user.level === 2600 ? <button className="btn btn-primary paymentViewButton" onClick={handleOnViewChange} value='accounts'>Accounts</button> : ""}
		</div>
	)
}

export const PurchaseSellFields = ({ handleOnViewChange, user }) => {
	return (
		<div className="formButtons">
			{user.level >= 1600 ? <button className="btn btn-primary paymentViewButton" onClick={handleOnViewChange} value='panels'>Panel</button> : ""}
			{user.level >= 1600 ? <button className="btn btn-primary paymentViewButton" onClick={handleOnViewChange} value='inverter'>Inverter</button> : ""}
			{user.level >= 1600 ? <button className="btn btn-primary paymentViewButton" onClick={handleOnViewChange} value='ACDB'>ACDB</button> : ""}
			{user.level >= 1600 ? <button className="btn btn-primary paymentViewButton" onClick={handleOnViewChange} value='DCDB'>DCDB</button> : ""}
		</div>
	)
}

export const ModalReceiptView = ({ recieptView, onCloseModal }) => {
	return (
		<div className="container">
			<Modal open={recieptView} onClose={() => { onCloseModal("recieptView") }} center>
				Test
			</Modal>
		</div>
	)
}

const History = () => {
	return (
		<div className="card historyCard shadow">
			<div className="card-title">
				<h4> History </h4>
			</div>
			<div className="card-body">
				<TableReact columns={columns} data={data} loading={loading} />
			</div>
		</div>
	)
}

export const InputDynamicType = ({ type, name, defaultValue, value, divType, editable, label, placeholder, options, handleOnChange }) => {
	switch (divType) {
		case "inputDiv":
			return editable ? <InputCustom type={type} name={name} defaultValue={defaultValue} value={value} editable={editable} label={label} placeholder={placeholder} handleOnChange={handleOnChange} /> : <InputCustomWithoutLabel label={label} type={type} name={name} placeholder={placeholder} value={value} defaultValue={defaultValue} handleOnChange={handleOnChange} />
		case "selectDiv":
			return editable ? <SelectCustom type={type} name={name} defaultValue={defaultValue} value={value} editable={editable} label={label} placeholder={placeholder} options={options} handleOnChange={handleOnChange} /> : <InputCustomWithoutLabel label={label} type={type} name={name} placeholder={placeholder} value={value} defaultValue={defaultValue} handleOnChange={handleOnChange} />
		default:
			return "";
	}
}

export const ProcessedTable = ({ data, columns, quoteIdProcess, dueAmount }) => {
	return (
		<div>
			<div style={{ textAlign: 'center' }}>
				{
					dueAmount !== null ? (
						<div style={{ textAlign:'center' }}>
							<div>
								<h3>Process Table</h3>
								<h3> {`${quoteIdProcess.label ? quoteIdProcess.label : ""} \u20b9${parseFloat(dueAmount).toFixed(0)}`}</h3>
							</div>
						</div>
					) : ""
				}
			</div>
			{/* <CSVLink filename={"Ledger_View_Data.csv"} data={data} headers={columns.map((val,index) => {return {label:val.Header,key:val.accessor}})} type="button" className="btn btn-info">
									Download
								</CSVLink> */}
			<ReactTable
				data={data}
				columns={columns}
				filterable={true}
				style={{ textAlign: 'center' }}
				defaultPageSize={10}
				defaultSorted={[{ id: "date", desc: false }]}
				defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
				
			/>
		</div>
	)
}

export const FilterView = ({ placeholder, name, value, options, handleOnChange, handleOnFilterChange, filterTypeOptions, selectView, transactionOptions, transactionTypeFilter, statusFilter, statusOptions, data, columns, applyFilter, downloadTransactionData, downloadRef, transactionViewData, user, transactionTypeFilterInvoice, paymentTypeFilter, paymentTypeOptions }) => {
	return (
		<div style={{ marginTop: 40 }}>
			<div className="filterPaymentMain">
				<div className="filterPaymentChild"  >
					<SelectCustomFilter
						isSearchable
						placeholder={placeholder}
						name={name}
						value={value}
						options={options}
						handleOnChange={handleOnChange}
						isMulti={selectView=='transactionView'||selectView=="invoiceView"}
					/>
				</div>
				<div className="filterPaymentChild">
					<div className="form-group">
						<div className="input-group mb-3">
							<div className="input-group-prepend">
								<span className="input-group-text" id="basic-addon1">Start</span>
							</div>
							<input className="form-control customInput" type="date" name="filterStartDate" placeholder="Start Date" onChange={handleOnFilterChange} />
						</div>
					</div>
				</div>
				<div className="filterPaymentChild">
					<div className="form-group">
						<div className="input-group mb-3">
							<div className="input-group-prepend">
								<span className="input-group-text" id="basic-addon1">End</span>
							</div>
							<input className="form-control customInput" type="date" name="filterEndDate" placeholder="End Date" onChange={handleOnFilterChange} />
						</div>
					</div>
				</div>
				{
					selectView === "transactionView" ? (
						<div className="filterPaymentChild">
							<SelectCustomFilter
								isSearchable
								placeholder={"Select Transaction Type"}
								name={"transactionTypeFilter"}
								value={transactionTypeFilter}
								options={transactionOptions}
								handleOnChange={handleOnChange}
								isMulti={true}
							/>
						</div>
					) : ""
				}
				{
					selectView === "invoiceView" ? (
						<div className="filterPaymentChild">
							<SelectCustomFilter
								isSearchable
								placeholder={"Select Transaction Type"}
								name={"transactionTypeFilterInvoice"}
								value={transactionTypeFilterInvoice}
								options={transactionOptions}
								handleOnChange={handleOnChange}
								isMulti={true}
							/>
						</div>
					) : ""
				}
				{
					selectView === "transactionView" ? (
						<div className="filterPaymentChild">
							<SelectCustomFilter
								isSearchable
								placeholder={"Select Status"}
								name={"statusFilter"}
								value={statusFilter}
								options={statusOptions}
								handleOnChange={handleOnChange}
								isMulti={true}
							/>
						</div>
					) : ""
				}
				{
					selectView === "transactionView" ? (
						<div className="filterPaymentChild">
							<SelectCustomFilter
								isSearchable
								placeholder={"Select Payment Type"}
								name={"paymentTypeFilter"}
								value={paymentTypeFilter}
								options={paymentTypeOptions}
								handleOnChange={handleOnChange}
								isMulti={true}
							/>
						</div>
					) : ""
				}
				{	
					selectView === "ledgerView" ? (
						<div>
						<CSVLink filename={"Ledger_View_Data.csv"} data={data} headers={columns.map((val,index) => {return {label:val.Header,key:val.csvAccessor?val.csvAccessor:val.accessor}})} type="button" className="btn btn-info">
								Download
							</CSVLink></div>
					) : ""
				}
				{	
					selectView === "transactionView" || selectView === "invoiceView"? (
						<div>
						<button type="button" onClick={applyFilter} className="btn btn-info">
								Apply Filter
							</button></div>
					) : ""
				}
				{	
					selectView === "transactionView" ? (
						<div style={{marginLeft:'20px'}}>
							<button onClick={downloadTransactionData} className="btn btn-info">Download</button>
							<CSVLink filename={"Transaction_View_Data.csv"} className="hidden" ref={downloadRef} data={transactionViewData} headers={columns.map((val,index) => {return {label:typeof val.Header!='function'?val.Header:val.accessor,key:val.accessor}})} />
						</div>
					) : ""
				}
				{	
					selectView === "invoiceView" ? (
						<div style={{marginLeft:'20px'}}>
							<button onClick={downloadTransactionData} className="btn btn-info">Download</button>
							<CSVLink filename={"Invoice_View_Data.csv"} className="hidden" ref={downloadRef} data={transactionViewData} headers={columns.map((val,index) => {return {label:typeof val.Header!='function'?val.Header:val.accessor,key:val.accessor}})} />
						</div>
					) : ""
				}
				{	
					selectView === "bankView" && ( user.level==2600 || user.level == 1800 ) ? (
						<div>
							<CSVLink filename={"Bank_View_Data.csv"} data={data} headers={columns.map((val, index) => { return { label: val.Header, key: val.csvAccessor ? val.csvAccessor : val.accessor } })} type="button" className="btn btn-info">
								Download
							</CSVLink></div>
					) : ""
				}				
			</div>
		</div>
	)
}

export const FilterViewInventory = ({currentSelected, inventoryVendorSelected, vendorOptions, inventoryBrandSelected, brandOptions, inventoryMakeSelected ,makeOptions, inventoryPhaseSelected, phaseOptions, inventoryCitySelected, cityOptions, handleOnChange, applyFilter, data, headers}) => {
	return(
		<div style={{ marginTop: 30, marginBottom: 30 }}>
			<div className="filterPaymentMain">
				<div className="filterPaymentChild"  >
					<SelectCustomFilter
						isSearchable
						placeholder={"Vendor"}
						name={"inventoryVendorSelected"}
						value={inventoryVendorSelected}
						options={vendorOptions}
						handleOnChange={handleOnChange}
						isMulti
					/>
				</div>
				{
					currentSelected == "panels" || currentSelected == "inverter" ? <div className="filterPaymentChild"  >
						<SelectCustomFilter
							isSearchable
							placeholder={"Brand"}
							name={"inventoryBrandSelected"}
							value={inventoryBrandSelected}
							options={brandOptions}
							handleOnChange={handleOnChange}
							isMulti
						/>
					</div> : ""
				}
				{
					currentSelected == "panels" || currentSelected == "ACDB" || currentSelected == "DCDB" ? <div className="filterPaymentChild"  >
						<SelectCustomFilter
							isSearchable
							placeholder={"Make"}
							name={"inventoryMakeSelected"}
							value={inventoryMakeSelected}
							options={makeOptions}
							handleOnChange={handleOnChange}
							isMulti
						/>
					</div> : ""
				}
				{
					currentSelected == "inverter"? <div className="filterPaymentChild"  >
						<SelectCustomFilter
							isSearchable
							placeholder={"Phase"}
							name={"inventoryPhaseSelected"}
							value={inventoryPhaseSelected}
							options={phaseOptions}
							handleOnChange={handleOnChange}
							isMulti
						/>
					</div> : ""
				}
				{
					currentSelected == "ACDB" || currentSelected == "DCDB"? <div className="filterPaymentChild"  >
						<SelectCustomFilter
							isSearchable
							placeholder={"City"}
							name={"inventoryCitySelected"}
							value={inventoryCitySelected}
							options={cityOptions}
							handleOnChange={handleOnChange}
							isMulti
						/>
					</div> : ""
				}
				<div>
					<button type="button" onClick={applyFilter} className="btn btn-info">
						Apply Filter
					</button>
				</div>
				<div style={{marginLeft:'10px'}}>
					<CSVLink filename={`${currentSelected}.csv`} data={data} headers={headers} type="button" className="btn btn-info">
						Download
					</CSVLink>
				</div>
			</div>
		</div>
	)
}

const SelectCustom = ({ options, name, placeholder, handleOnChange, value }) => {
	const changeHandle = (value) => { handleOnChange(value, name) }
	return (
		<div className="col-12 col-md-2">
			<div className="form-group">
				<Select
					isSearchable
					placeholder={placeholder}
					name={name}
					value={value}
					options={options}
					onChange={changeHandle}
				/>
			</div>
		</div>
	)
}

const InputCustom = ({ label, type, name, placeholder, value, handleOnChange }) => {
	return (
		<div className="col-12 col-md-2">
			<div className="form-group">
				<div className="input-group mb-3">
					<div className="input-group-prepend">
						<span className="input-group-text" id="basic-addon1">{label}</span>
					</div>
					<input className="form-control customInput" type={type} name={name} placeholder={placeholder} value={value} onChange={handleOnChange} />
				</div>
			</div>
		</div>
	)
}

const InputCustomWithoutLabel = ({ label, type, name, placeholder, defaultValue, handleOnChange }) => {
	return (
		<div className="col-12 col-md-2">
			<div className="form-group">
				<div className="input-group mb-3">
					<div className="input-group-prepend">
						<span className="input-group-text" id="basic-addon1">{label}</span>
					</div>
					<input className="form-control customInput" type={type} readOnly={true} name={name} placeholder={placeholder} onChange={handleOnChange} defaultValue={defaultValue} />
				</div>
			</div>
		</div>
	)
}

const SelectCustomFilter = ({ options, name, placeholder, handleOnChange, value, style, isMulti }) => {
	const changeHandle = (value) => { handleOnChange(value, name) }
	return (
		<div style={{ width: '100%' }}>
			<Select
				isSearchable
				placeholder={placeholder}
				name={name}
				value={value}
				options={options}
				onChange={changeHandle}
				isMulti={isMulti}
			/>
		</div>

	)
}

const TableReact = ({ columns, data, loading }) => {
	return (
		<ReactTable
			data={data}
			columns={columns}
			filterable={true}
			style={{ textAlign: 'center' }}
			defaultPageSize={10}
			defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1} />
	)
}
