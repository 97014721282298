import axios from 'axios';
import { db } from '../connection';

export function changeZunprimeStatus(siteId, todo) {
  return async (dispatch) => {
    if(todo=="markZunprime"){
      console.log("inside marking")
       db.ref('/delivery/webPortal/sites/' + siteId + '/zunPrime').set(1);
    }
    if (todo =="removeZunprime"){
      console.log("remove zunprime");
      db.ref('/delivery/webPortal/sites/' + siteId + '/zunPrime').set(null);
    }
  }
}