import { FETCH_ALL_PAYMENTS_START, FETCH_ALL_PAYMENT_SUCCESS, FETCH_ALL_PAYMENT_ERROR } from '../actions/actiontypes';

export default function (state = {}, action) {
  switch (action.type) {
    case FETCH_ALL_PAYMENTS_START: {
      return {};
    }
    case FETCH_ALL_PAYMENT_ERROR: {
      return { error: action.payload }
    }
    case FETCH_ALL_PAYMENT_SUCCESS: {
      return { ...state, tableData: action.payload };
    }
    default:
      return state;
  }
}