import { base } from '../connection';
import { createDateObj } from '../helper/dateObj';
import { db } from '../connection';
import { getNameById} from '../helper/helper';
import axios from 'axios';

const slackIds = {
	"Aayush Gupta" : "U58M97BGX",
	"Akansha Srivastava" : "UG4AFTN3H",
	"Archana Prasad" : "UAPRS6GL8",
	"Himanshu Khurana" : "U4W1WEL68",
	"Ivpreet Kaur" : "UG35CCZ6F",
	"Vaibhav Tyagi" : "UJC62BKGW",
	"Naman Goyal" : "UHS5N63HD"
}

export function setPayments(siteId,quoteId,user,oldPayment,newPayment,pctComplete,team,state,inverterDate, city) {
	console.log(new Date());
	return (dispatch) => {
		if (pctComplete >= 94 && !inverterDate) {
			sendInverterMessage(quoteId, state, city).then();
		}
		let oldPaymentConvert 	= convertToNewArray(oldPayment);
		// CONVERT THE NEW PAYMENT DATA
		let newPaymentConvert 	= convertToNewArray(newPayment);
		// CONVERT THE NEW PAYMENT DATA TO OBJECT
		let newPaymentObject 	= convertToObject(newPaymentConvert);
		let changedby = user;
		console.log("oldPaymentConvert", oldPaymentConvert, "newPaymentConvert", newPaymentConvert, "newPaymentObject", newPaymentObject, "changedby", changedby);
		let paymentDifferenceArray = newPaymentConvert.slice(oldPaymentConvert.length);
		if (paymentDifferenceArray.length) {
			paymentDifferenceArray.map(payment => {
				triggerWhatsapp(siteId, payment.amountPaid).then();
			})
		}
		// CHECK IF USER HAS THE PERMISSION
		checkPermission(user,team,function(error,check) {
			if(error) {
				console.log(error);
			}else if(check) {
				console.log("VALIDATED");
				fetchPaymentRecord(siteId,changedby,oldPaymentConvert,newPaymentConvert);
				setpctpaymentCompleted(siteId,pctComplete);
				setPaymentHistory(siteId,newPaymentObject);
			}else{
				console.log("NOT A VALID USER");
			}
		});
	}
}


function checkPermission(user,team,cb) {
	base.fetch(`/delivery/teamtoEmployeeMapping/${team}/members/${user}/paymentupdatepermision`, {})
	.then(data => {
		console.log(data);
		if(data) {
			return cb(null,true);
		}else{
			return cb(null,false);
		}
	})
	.catch(error=>{
		console.log(error);
		return cb(error);
	})
}

function fetchPaymentRecord(siteId,changedby,oldPaymentConvert,newPaymentConvert) {
	base.fetch(`/delivery/sites/${siteId}/PaymentRecord`, {})
	.then(data => {
		if(data.length>0) {
			data.push({
				changedby,
				changedon:createDateObj(new Date().getTime()),
				currentPayment:oldPaymentConvert,
				newPayment:newPaymentConvert
			});
			setPaymentsRecord(siteId,data)
		}else{
			data = [];
			data.push({
				changedby,
				changedon:createDateObj(new Date().getTime()),
				currentPayment:[],
				newPayment:newPaymentConvert
			});
			setPaymentsRecord(siteId,data)
		}
	})
	.catch(error=>{
		console.log(error);
	})
}

// SET PERCENTAGE COMPLETE

function setpctpaymentCompleted(siteId,data) {
	base.post('/delivery/sites/'+siteId+'/pctpaymentCompleted', {
    data: data
    ,then(err){
      if(!err){
      	console.log("SUCCESS PAYMENT COMPLETE")
      }else{
      	console.log(err);
      }
    }
  });
}

// SEND SLACK Message

const sendInverterMessage = async (quoteId, state, city) => {
	try {
		let [ captainStateMap, cityResponsibleMap ] = await Promise.all([
			db.ref('/delivery/captainStateMapping').once("value"),
			db.ref('/website/cityResponsibleMap').once("value")
		])
		captainStateMap = captainStateMap.val() || {};
		cityResponsibleMap = cityResponsibleMap.val() || {};
		let captains = [];
		if(cityResponsibleMap[`${state}__${city}`]) {
			captains = cityResponsibleMap[`${state}__${city}`];
		}
		else {
			Object.keys(captainStateMap || []).forEach(captain => {
				if (captainStateMap[captain].includes(state)) {
					captains.push(slackIds[captain] ? "<@" + slackIds[captain] + ">" : captain)
				}
			})
		}
		let slackMessage = captains.join(' ') + " : Please order Inverter for " + quoteId;
		let payload = JSON.stringify({text : slackMessage});
		let result = await fetch("https://hooks.slack.com/services/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",
		{
	    method: "POST",
	    body: payload
		})
	} catch (e) {
		console.log(e);
	}
}

//SET PAYMENT HISTORY

function setPaymentHistory(siteId,data) {
	base.post('/delivery/sites/'+siteId+'/paymentHistory', {
    data: data
    ,then(err){
      if(!err){
      	console.log("SUCCESS PAYMENT HISTORY")
      }else{
      	console.log(err);
      }
    }
  });
}

//SET PAYMENT RECORD

function setPaymentsRecord(siteId,data) {
	base.post('/delivery/sites/'+siteId+'/PaymentRecord', {
    data: data
    ,then(err){
      if(!err){
      	console.log("SUCCESS PAYMENT DATA UPDATE")
      }else{
      	console.log(err);
      }
    }
  });
}

// FUNCTION TO CONVERT ARRAY INTO OBJECT
function convertToNewArray(payments) {
	let createdData = []
	payments.forEach((val,index) => {
		createdData.push({
			amountPaid: val.amount,
			paidDate: createDateObj(val.date),
			paymentId: "payment"+(index)
		});
	});
	return createdData;
}

function convertToObject(payments) {
	let createdData = {};
	payments.forEach((val,index) => {
		createdData[""+val.paymentId] = val;
	});
	return createdData;
}

const triggerWhatsapp = async (siteId, amount) => {
	let {mSite_QuoteId : quoteId, mSite_OwnerId : customerId} = (await db.ref(`/sites/${siteId}`).once("value")).val();
	let customerName = await getNameById(customerId);
	let requestData = {
			key : "4VhfN1xiAD",
			phoneNumber : customerId,
			trigger : "paymentConfirmation",
			quoteId : quoteId,
			customerName : customerName,
			amount : amount
	}
	requestData = new URLSearchParams(requestData);
	let data = await axios.post("https://zuntalks.in:3000/whatsapp/trigger", requestData, {
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
	});
}
