import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import './App.css';

import NavBar from './containers/NavBar';
import Login from './containers/Login';
import Deals from './containers/Deals';
import AllData from './containers/AllData';
import Stages from './containers/Stages';
import QuoteId from './containers/QuoteId';
import Graph from './containers/Graph';
import IncentivePage from './containers/IncentivePage';
import EmailViewer from './components/EmailViewer';
import EmailAnalytics from './containers/EmailAnalytics';
import FilesViewMain from './containers/FilesView';
import SalesAdmin from './containers/SalesAdmin';
import PaymentsProcess from './containers/PaymentsProcess';
import Vendor from './containers/Vendor';
import VendorAccessPage from './containers/VendorAccessPage';
import SalesAnalytics from './containers/SalesAnalytics';
import Complaint from './containers/Complaint';
import EmailAccounts from './containers/EmailAccounts';
import EmailViewerAccounts from './components/EmailViewerAccounts';
import UserRole from './containers/UserRole';
import TicketSystem from './containers/TicketSystem';
import Tickets from './containers/Tickets';
import InboundCall from './containers/inboundCall';
import CurrentCall from './containers/CurrentCall';
import OutboundCall from './containers/OutboundCall';
import Confirmed from './containers/Confirmed';
import EscalationView from './containers/EscalationView';
import OpsReport from './containers/OpsReport';
import NewOpsReport from './containers/OpsReport1';
import BulkInvoiceUpload from './containers/BulkInvoiceUpload';
import NewSalesAnalytics from './containers/NewSalesAnalytics';
import TicketingReport from './containers/TicketingReport';
import Dpp from './containers/Dpp';
import PeTask from './containers/PeTask';
import PmTask from './containers/PmTask';
import PaymentPortal from './containers/PaymentPortal';
import v2Dashboard from './containers/v2Dashboard';
import PanelInverterStatus from './containers/PanelInverterStatus';
import PeTaskCenters from './containers/PeTaskCentres';
import SalesTarget from './containers/SalesTarget';
import GenerateInvoice from './containers/GenerateInvoice';
import QuickBooksIntegration from './containers/QuickBooksIntegration';
import OrderStageTracker from './containers/OrderStageTracker';

class App extends Component {
  render() {
    return (
      <div>
        <Router>
          <div>
            <NavBar />
            <Switch>
              <Route path='/' exact render={() => (
                <Redirect to="/deals" />
                // <Table />
              )
              } />
              <Route path='/all' exact component={AllData} />
              <Route path='/deals' exact component={Deals} />
              {/* <Route path='/graphs' exact component={Graph}/> */}
              <Route path='/payments' exact component={PaymentsProcess} />
              {/* <Route path='/stages' exact component={Stages}/> */}
              <Route path='/incentivepage' exact component={IncentivePage} />
              <Route path='/quoteid/:id' exact component={QuoteId} />
              <Route path='/emailview/:id' exact component={EmailViewer} />
              <Route path='/emailanalytics' exact component={EmailAnalytics} />
              <Route path='/files' exact component={FilesViewMain} />
              <Route path='/sales' exact component={SalesAdmin} />
              <Route path='/vendor' exact component={Vendor} />
              <Route path='/vendormanagement' exact component={VendorAccessPage} />
              <Route path='/SalesAnalytics' exact component={SalesAnalytics} />
              <Route path='/userroles' exact component={UserRole} />
              <Route path='/inbound' exact component={InboundCall} />
              <Route path='/login' exact component={Login} />
              {/* <Route path='/complaint' exact component={Complaint}/> */}
              <Route path='/emailaccounts' exact component={EmailAccounts} />
              <Route path='/emailviewaccounts/:id' exact component={EmailViewerAccounts} />
              <Route path='/ticketSystem' exact component={TicketSystem} />
              <Route path='/tickets/:id' exact component={Tickets} />
              <Route path='/current' exact component={CurrentCall} />
              <Route path='/outbound' exact component={OutboundCall} />
              <Route path='/confirmed' exact component={Confirmed} />
              <Route path='/escalations' exact component={EscalationView} />
              <Route path='/opsreport' exact component={OpsReport} />
              <Route path='/newopsreport' exact component={NewOpsReport} />
              <Route path='/bulkInvoiceUpload' exact component={BulkInvoiceUpload} />
              <Route path='/newsa' exact component={NewSalesAnalytics} />
              <Route path='/ticketReport' exact component={TicketingReport} />
              <Route path='/dpp' exact component={Dpp} />
              <Route path='/petasks' exact component={PeTask} />
              <Route path='/pmtask' exact component={PmTask} />
              <Route path='/paymentview' exact component={PaymentPortal} />
              <Route path='/v2dashboard' exact component={v2Dashboard} />
              <Route path='/orderstatus' exact component={PanelInverterStatus} />
              <Route path='/petaskcenters' exact component={PeTaskCenters} />
              <Route path='/target' exact component={SalesTarget} />
              <Route path='/generate-invoice' exact component={GenerateInvoice} />
              <Route path='/quickbooks' exact component={QuickBooksIntegration} />
              <Route path='/stage-tracker' exact component={OrderStageTracker} />
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
