import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import Select from 'react-select';
import { changeToViewDateTime, changeToInputDate, changeToViewDate } from '../helper/dateChanger';
import { Restrict } from '../components/Restrict';
import { salesAnalyticsTable1, salesAnalyticsTable2, salesAnalyticsTable3 } from '../config/column_fields';
import Modal from 'react-responsive-modal';
import { fetchPmTasks, editTask, addComment, createTask} from '../actions/fetchPmTasks';
import queryString from 'query-string';
import renderHTML from 'react-render-html';
import axios from 'axios';
import moment from 'moment';

class PmTask extends Component {


	constructor(props) {
		super(props);
		this.state = {
			loading:true,
			tableData : {},
			filteredData : {},
			cellEditStatus : {},
			filter : {
				active : [1]
			},
			stateOptions : [],
			pmOptions : [],
			stageOptions : [],
			categoryOptions : [],
			openCreateModal : false,
			createTask : {
				quoteId: '',
				stage: '',
				category: '',
				task: ''
			},
			createListValues:{
				"Layout Approval":{
					"PE Visit":{"Schedule Layout Site visit":{"prime":2,"nonPrime":1},"Upload Flex ":{"prime":3,"nonPrime":3}},
					"communication":{"Share layout with client":{"prime":2,"nonPrime":1},"Layout Approved":{"prime":2,"nonPrime":1},"Share layout with client ( Layout Rejection )":{"prime":2,"nonPrime":1}}
				},
				"P1":{
					"payment":{"P1 collection":{"prime":2,"nonPrime":null}},
					"Bill Issues":{"EB Issue":{"prime":null,"nonPrime":null}},
					"Documentation":{"Apply feasibility/subsidy":{"prime":3,"nonPrime":3}},
					"Material Delivery":{"Inverter Delivery":{"prime":10,"nonPrime":7}}
				},
				"P2":{
					"payment":{"P2 Collection":{"prime":2,"nonPrime":null}},
					"v2":{"V2 allocation":{"prime":null,"nonPrime":null}}
				},
				"Inverter Delivery":{
					"Material Delivery":{"Panel Delivery":{"prime":null,"nonPrime":null}}
				},
				"Structure Delivery":{
					"Material Delivery":{"Structure Delivery":{"prime":15,"nonPrime":10}},
					"v2":{"V2 P1":{"prime":5,"nonPrime":5}}
				},  
				"Commissioning":{
					"Installation":{"Commission site":{"prime":15,"nonPrime":7}},
					"PE Visit":{"Schedule Quality Visit":{"prime":5,"nonPrime":2},
					"Quality review":{"prime":2,"nonPrime":2},
					"Schedule Quality Visit":{"prime":5,"nonPrime":2}},
					"v2":{"V2 P2":{"prime":5,"nonPrime":5},
					"V2 P3":{"prime":5,"nonPrime":5}}
				}, 
				"Net Meter Installation":{
					"Installation":{"Net Meter Installation":{"prime":5,"nonPrime":5}},
					"v2":{"V2 P4":{"prime":null,"nonPrime":null}},
					"PE Visit":{"Upload Site Ceremony":{"prime":10,"nonPrime":10}}
				}, 
			}
    };
	}

	componentDidMount() {
		if (this.props.user) {
			if (this.props.user.level >= 1600) {
				this.props.fetchPmTasks();
			}
		}
	}

	componentWillReceiveProps(props) {
		if(props.PmData && props.PmData.data){
			let { data, stateList, pmList, stages, categories, tasks } = { ...props.PmData.data };
			const arrayToSelectOptions = (arr) => {
				let options = (arr || []).map(val => {
					return ({
						value: val,
						label: val
					})
				})
				return options;
			}
			this.setState({
				loading : false,
				tableData : data,
				stateOptions : arrayToSelectOptions(stateList),
				// pmOptions : arrayToSelectOptions(pmList),
				pmOptions : pmList,
				stageOptions : arrayToSelectOptions(stages),
				categoryOptions : arrayToSelectOptions(categories),
				taskOptions : arrayToSelectOptions(tasks),
			}, () => {
				this.applyFilter();
			})
		}
	}

	arrayToSelectOptions = (arr) => {
		let options = (arr || []).map(val => {
			return ({
				value: val,
				label: val
			})
		})
		return options;
	}

	deafultViewDiv = (row, data) => {
		let taskId = `${row.original.creationDate}_${row.original.quoteId}`
		return (<div onDoubleClick={() => { this.handleOnTableCellClick(taskId, row.column.id) }} >{data && data == "<div></div>" ? "-" : renderHTML(data)}</div>);
	}

	onCloseCreateModal = (e) => {
		this.setState({
			openCreateModal: false
		});
	}

	onOpenCreateModal = (e) => {
		this.setState({
			openCreateModal: true
		});
	}

	handleSubmitCreateTicket = (e) => {
		e.preventDefault();
		console.log('parduman')
		if(this.state.createTask.quoteId && this.state.createTask.stage && this.state.createTask.category && this.state.createTask.task){
			let dueDates = (((this.state.createListValues[(this.state.createTask.stage || '')] || {})[(this.state.createTask.category || '')] || {})[this.state.createTask.task || '']) || {}
			console.log(dueDates,'parduman')
			this.props.createTask(this.state.createTask,dueDates,this.props.PmData.data)
		}
		else{
			alert('Please fill all the details!!!!');
		}
		this.setState({
			openCreateModal: false
		});
	}

	inputTextDiv = (row) => {
		let defaultValue = row.value;
		let taskId = `${row.original.creationDate}_${row.original.quoteId}`
		return <input type="text" onKeyUp={(e) => { this.handleOnKeyPress(e, row, taskId, row.column.id) }} className="form-control" name={`${taskId}__${row.column.id}`}/>
	}

	inputSelectDiv = (row) => {
		let taskId = `${row.original.creationDate}_${row.original.quoteId}`;
		return (<select className="form-control" value={row.value} onBlur={(e) => { this.handleOnKeyPress(e, row, taskId, row.column.id) }} onChange={(e) => {this.handleStatusChange(e, row)}}>
			<option value="">Select Status</option>
			<option value="Closed">Closed</option>
			<option value="Hold">Hold</option>
			<option value="Cancelled">Cancelled</option>
			<option value="Delivered">Delivered</option>
		</select>)
	}

	handleOnTableCellClick = (taskId, id) => {
		let editStatus = this.state.cellEditStatus[taskId] || {};
		editStatus[id] = true;
		this.setState({
			cellEditStatus: { ...this.state.cellEditStatus, [taskId]: editStatus }
		})
	}

	handleOnKeyPress = (e, row, taskId, columnId) => {
		let { value } = e.target;
		let { cellEditStatus } = this.state;
		if (e.keyCode === 13 && !e.shiftKey) {
			let editStatus = cellEditStatus[taskId] || {};
			editStatus[columnId] = false;
			let tableData = this.state.tableData;
			let data = {
				timestamp : Date.now(),
        commentData : value,
        commentedByName : "You",
        commentedByUser : this.props.user.phoneNumber
			}
			tableData[taskId][columnId] = (tableData[taskId][columnId] || []).concat(data);
			this.props.addComment(taskId, value, this.props.user.phoneNumber);
			this.setState({
				cellEditStatus: { ...this.state.cellEditStatus, [taskId]: editStatus },
				tableData : tableData
			}, () => {
				this.applyFilter();
			})
		}
		if (!e.keyCode || e.keyCode === 27) {
			let editStatus = cellEditStatus[taskId] || {};
			editStatus[columnId] = false;
			this.setState({
				cellEditStatus: { ...this.state.cellEditStatus, [taskId]: editStatus }
			});
		}
	}

	handleStatusChange = (e, row) => {
		let { value } = e.target;
		let { cellEditStatus, tableData } = this.state;
		let taskId = `${row.original.creationDate}_${row.original.quoteId}`;
		let editStatus = cellEditStatus[taskId] || {};
		editStatus[row.column.id] = false;
		tableData[taskId][row.column.id] = value;
		this.props.editTask(taskId, row.column.id, value);
		this.setState({
			cellEditStatus: { ...this.state.cellEditStatus, [taskId]: editStatus },
			tableData : tableData
		}, () => {
			this.applyFilter();
		})
	}

	handleCloseTask = (e, row) => {
		let tableData = this.state.tableData;
		let taskId = `${row.original.creationDate}_${row.original.quoteId}`
		tableData[taskId].active = 0;
		tableData[taskId].closedOn = Date.now();
		this.props.editTask(taskId, "active", 0);
		this.props.editTask(taskId, "closedOn", Date.now());
		this.setState({
			tableData : tableData
		}, () => {
			this.applyFilter();
		})
	}

	handleDateChange = (e) => {
		let { name, value } = e.target;
		let filter = this.state.filter;
		filter[name] = value;
		this.setState({
			filter : filter
		})
	}

	handleCreateFormChange = (e) => {
		let name = e.target.name;
		let value = e.target.value;
		this.setState(prevState => ({
			createTask: {
				...prevState.createTask,
				[name]: value
			}
		}))
	}

	handleFilterSelectChange = (selectedOption, name) => {
		let filter = this.state.filter;
		filter[name] = selectedOption.map(val => val.value)
		if (filter[name] && filter[name].length == 0) {
			delete filter[name];
		}
		this.setState({
			filter : filter
		});
	}

	applyFilter = (e) => {
		let {tableData, filter} = this.state;
		let filteredData = {};
		Object.values(tableData).forEach(task => {
			for (let key in filter ) {
				if (key.includes("Date")) {
					if ((key.includes("Start") && task[key.slice(0, key.indexOf("Start"))] < moment(filter[key]).format('x')) || (key.includes("End") && task[key.slice(0,key.indexOf("End"))] > moment(filter[key]).endOf("day").format('x'))) {
						return;
					}
				} else {
					if (!filter[key].includes(task[key])) {
						return;
					}
				}
			}
			filteredData[`${task.creationDate}_${task.quoteId}`] = task
		})
		this.setState({
			filteredData : filteredData
		})
	}

	tableColumms = [
		{
			Header: 'Quote Id',
			accessor: 'quoteId'
		},
		{
			Header: 'Client Name',
			accessor: 'clientName'
		},
		{
			Header: 'State',
			accessor: 'state'
		},
		{
			Header: 'Responsible Person',
			accessor: 'responsibleName'
		},
		{
			Header: 'Stage',
			accessor: 'stage'
		},
		{
			Header: 'Category',
			accessor: 'category'
		},
		{
			Header: 'Task',
			accessor: 'task',
			width : 300
		},
		{
			Header: 'Task Creation Date',
			accessor: 'creationDate',
			Cell : row => {
				return moment(+row.value).format('ll')
			}
		},
		{
			Header: 'Task Due Date',
			accessor: 'dueDate',
			Cell : row => {
				return moment(+row.value).format('ll')
			}
		},
		{
			Header: 'DPD',
			accessor: 'DPD',
			Cell : row => {
				return (Date.now() > row.original.dueDate ? 1 : 0);
			}
		},
		{
			Header: 'Status',
			accessor: 'status',
			// Cell: row => {
			// 	let taskId = `${row.original.creationDate}_${row.original.quoteId}`;
			// 	return (this.state.cellEditStatus[taskId] && this.state.cellEditStatus[taskId][row.column.id]) ?
			// 		this.inputSelectDiv(row) : (<div onDoubleClick={() => { this.handleOnTableCellClick(taskId, row.column.id) }} >{row.value || "-"}</div>)
			// }
		},
		{
			Header: 'Comments',
			accessor: 'comments',
			Cell: row => {
				// let data = (row.value || []).map(cmt => {
				// 	return `${moment(+cmt.timestamp).format('ll hh:mm a')} - ${cmt.commentData} - By ${cmt.commentedByName}`
				// }).join("\n")
				let data = "<div>" + (row.value || []).map(cmt => {
					return `${moment(+cmt.timestamp).format('ll hh:mm a')} - ${cmt.commentData} - By ${cmt.commentedByName || cmt.commentedByUser}`
				}).join("<br/>") + "</div>";
				let taskId = `${row.original.creationDate}_${row.original.quoteId}`
				return (this.state.cellEditStatus[taskId] && this.state.cellEditStatus[taskId][row.column.id]) ?
					this.inputTextDiv(row) : this.deafultViewDiv(row, data)
			},
			width : 500
		},
		{
			Header: 'Closed On',
			accessor: 'closedOn',
			Cell : row => {
				return (row.value ? moment(+row.value).format('ll') : '')
			}
		},
		{
			Header: 'Close Task',
			accessor: 'close',
			Cell:row=>{
				return <div><button className="btn btn-success" onClick = {(e) => {this.handleCloseTask(e, row)}}>Close Task</button></div>
			}
		},
	]

	render() {
		console.log(this.state,'parduman')
		const style = {
			hide: {
				display: 'none'
			},
			show: {
				display: ''
			},
			iconShow: {
				float: 'right',
				display: ''
			},
			iconHide: {
				float: 'right',
				display: 'none'
			}
		};
		if (this.props.user === null) {
			return <Redirect to={"/login?redirect_uri=pmtask"} />;
		}

		if (this.props.user && this.props.user.level < 1600) {
			return (
				<div>
					<Restrict />
				</div>
			)
		}

		const tableData = Object.values(this.state.filteredData).reverse()

		const modalStyles = {
			modal: {
				width: '80%',
				maxWidth: '80%',
				padding: '40px'
			}
		}

		return (
      <div className="container-fluid" style={{marginTop:"50px"}}>
				<div className="row">
					<div className="col-md-2 col-6 mb-3">
						<label>State</label>
						{selectCustom(this.state.stateOptions, "state", "Choose State", this.handleFilterSelectChange)}
					</div>
					<div className="col-md-2 col-6 mb-3">
						<label>PM</label>
						{selectCustom(this.state.pmOptions, "responsibleId", "Choose PM", this.handleFilterSelectChange)}
					</div>
					<div className="col-md-2 col-6 mb-3">
						<label>Creation Date Start</label>
						<input className="form-control customInput" type='date' name='creationDateStart' onChange={(e) => this.handleDateChange(e)} />
					</div>
					<div className="col-md-2 col-6 mb-3">
						<label>Creation Date End</label>
						<input className="form-control customInput" type='date' name='creationDateEnd' onChange={(e) => this.handleDateChange(e)} />
					</div>
					<div className="col-md-2 col-6 mb-3">
						<label>Stage</label>
						{selectCustom(this.arrayToSelectOptions(Object.keys(this.state.createListValues)), "stage", "Choose Stage", this.handleFilterSelectChange)}
					</div>
					<div className="col-md-2 col-6 mb-3">
						<label>Category</label>
						{selectCustom(this.arrayToSelectOptions((this.state.filter.stage || []).map(stage => Object.keys(this.state.createListValues[(stage || '')] || {})).flat()), "category", "Choose Category", this.handleFilterSelectChange)}
					</div>
					<div className="col-md-2 col-6 mb-3">
						<label>Task</label>
						{selectCustom(this.arrayToSelectOptions((this.state.filter.stage || []).map(stage => (this.state.filter.category || []).map(category => Object.keys(this.state.createListValues[(stage || '')][category || ''] || {})).flat()).flat()), "task", "Choose Task", this.handleFilterSelectChange)}
					</div>
					{/* <div className="col-md-2 col-6 mb-3">
						<label>Stage</label>
						{selectCustom(this.state.stageOptions, "stage", "Choose Stage", this.handleFilterSelectChange)}
					</div>
					<div className="col-md-2 col-6 mb-3">
						<label>Category</label>
						{selectCustom(this.state.categoryOptions, "category", "Choose Category", this.handleFilterSelectChange)}
					</div>
					<div className="col-md-2 col-6 mb-3">
						<label>Task</label>
						{selectCustom(this.state.taskOptions, "task", "Choose Task", this.handleFilterSelectChange)}
					</div> */}
					<div className="col-md-2 col-6 mb-3">
						<label>Active</label>
						{selectCustom([{label : "Inactive", value : 0}, {label : "Active", value : 1}], "active", "Choose Active", this.handleFilterSelectChange, [{label : "Active", value : 1}])}
					</div>
					<div className="col-md-2 col-6">
						<label></label><br></br>
						<button onClick={(e)=>this.applyFilter(e)} className="btn btn-success">Apply Filter</button>
					</div>
					<div className="col-md-2 col-6">
						<label></label><br></br>
						<button onClick={this.onOpenCreateModal} className="btn btn-success">Create Task</button>
					</div>
				</div>
			{/*<h3 style={{marginTop:"30px"}}>PM Tasks</h3>*/}
			<ReactTable
				filterable={true}
				showPagination={true}
				sortable={true}
				defaultPageSize={50}
				data={tableData}
				columns={this.tableColumms}
				defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
				loading={this.state.loading}
				style={{ textAlign: 'center' }}
				getTrProps={(state, rowInfo, column, instance) => {
					if (rowInfo && Date.now() > rowInfo.original.dueDate) {
						return {
							style: {
								backgroundColor: '#FF9595'
							}
						}
					}
					else {
						return {
							style: {
								backgroundColor: '#FFFFFF'
							}
						}
					}
				}
				}
			/>
		    <Modal styles={modalStyles} open={this.state.openCreateModal} onClose={this.onCloseCreateModal}>
				<form onSubmit={this.handleSubmitCreateTicket}  className="form-control" style={{ border: 'none' }}>
					<div className="row">
						<div className="form-group col-4">
							<label>Quote Id</label>
							<input className="form-control" type="text" name="quoteId" onChange={this.handleCreateFormChange} value={this.state.createTask.quoteId} required />
						</div>
						<div className="form-group col-4">
							<label>Stage</label>
							<select className="form-control" name="stage" onChange={this.handleCreateFormChange} value={this.state.createTask.stage} required>
								<option key="-1" value="">--Select Type---</option>
								{
									Object.keys(this.state.createListValues).map((v, i) => {
										return (<option key={i} value={v}>{v}</option>)
									})
								}
							</select>
					    </div>
						<div className="form-group col-4">
							<label>Category</label>
							<select className="form-control" name="category" onChange={this.handleCreateFormChange} value={this.state.createTask.category} required>
								<option key="-1" value="">--Select Type---</option>
								{
									Object.keys(this.state.createListValues[(this.state.createTask.stage || '')] || {}).map((v, i) => {
										return (<option key={i} value={v}>{v}</option>)
									})
								}
							</select>
					    </div>
					</div>
					<div className="row">
						<div className="form-group col-4">
							<label>Task</label>
							<select className="form-control" name="task" onChange={this.handleCreateFormChange} value={this.state.createTask.task} required>
								<option key="-1" value="">--Select Type---</option>
								{
									Object.keys((this.state.createListValues[(this.state.createTask.stage || '')]|| {})[(this.state.createTask.category || '')] || {}).map((v, i) => {
										return (<option key={i} value={v}>{v}</option>)
									})
								}
							</select>
						</div>
					</div>
					<div className="form-group col-4" style={{ "marginTop": "22px", "textAlign": "center" }}>
						<button type="submit" className="btn btn-success">Submit</button>
					</div>
				</form>
			</Modal>
    </div>
		);
	}
}

const selectCustom = (options, name, placeholder, handleFilterSelectChange, defaultValue) => {
	const changeHandle = (value) => { handleFilterSelectChange(value, name) }
	return (
		<div>
			<Select
				isMulti
				isSearchable
				onChange={(e) => { changeHandle(e) }}
				placeholder={placeholder}
				name={name}
				options={options}
				defaultValue={defaultValue}
			/>
		</div>
	)
}

function mapStateToProps(state) {
	return {
		user: state.user,
		PmData : state.PmData
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({fetchPmTasks, editTask, addComment, createTask}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PmTask);
