import { base } from '../connection';
import { createDateObj } from '../helper/dateObj';	

export function setottdata(siteId,user,ottNewData,ottOldData,pctComplete) {
	console.log(new Date());
	return (dispatch) => {
		//FOR OTT HISTORY
		fetchOttHistory(siteId,user,ottNewData);
		//FOR PAYMENT PERCENTAGE
		setpctpaymentCompleted(siteId,pctComplete);
		// FOR NEW OTT DATA
		setOttArray(siteId,ottNewData);
	}
}


// FETCH OTT HISTORY

function fetchOttHistory(siteId,user,ottNewData) {
	base.fetch('/delivery/sites/'+siteId+'/OTTRECORD', {})
	.then(data => {
		if(Object.keys(data).length === 0 && data.constructor === Object){
			data = [];
			data.push({
				changedby : user,
				changedon : createDateObj(new Date()),
				currentott : null,
				newott : ottNewData
			});
			setOttHistory(siteId,data);
		}else{
			data.push({
				changedby : user,
				changedon : createDateObj(new Date()),
				currentott : data[data.length-1].newott?data[data.length-1].newott:null,
				newott : ottNewData
			});
			setOttHistory(siteId,data);
		}
	})
	.catch(error=>{
		console.log(error);
	})
}


// SET PERCENTAGE COMPLETE

function setpctpaymentCompleted(siteId,data) {
	base.post('/delivery/sites/'+siteId+'/pctpaymentCompleted', {
    data: data	
    ,then(err){
      if(!err){
      	console.log("SUCCESS PAYMENT COMPLETE")
      }else{
      	console.log(err);
      }
    }
  });
}

//SET OTT HISTORY

function setOttHistory(siteId,data) {
	base.post('/delivery/sites/'+siteId+'/OTTRECORD', {
    data: data	
    ,then(err){
      if(!err){
      	console.log("SUCCESS OTT HISTORY")
      }else{
      	console.log(err);
      }
    }
  });
}

//SET OTT DATA

function setOttArray(siteId,data) {
	base.post('/delivery/sites/'+siteId+'/extraPriceLineItem', {
    data: data	
    ,then(err){
      if(!err){
      	console.log("SUCCESS OTT DATA UPDATE")
      }else{
      	console.log(err);
      }
    }
  });
}