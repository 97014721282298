import { FETCH_OUTBOUND_DATA_SUCCESS, FETCH_OUTBOUND_DATA_START } from './actiontypes';

import { base, db } from '../connection';


export function fetchOutboundCalldata() {
    return async (dispatch) => {
        dispatch({ type: FETCH_OUTBOUND_DATA_START });
        fetchTicketsData().then(data => {
            console.log(data);
            dispatch({ type: FETCH_OUTBOUND_DATA_SUCCESS, payload: data });
            console.log(data);
        })
    }
}

async function fetchData() {
    try {
        let userTicketsMap = (await db.ref(`delivery/webPortal/knowlarity/currentCampaign`).once('value')).val();
        let allData = [];
        Object.keys(userTicketsMap ? userTicketsMap : {}).forEach(userId => {
            let data = userTicketsMap[userId];
            allData.push({
                caller: userId,
                quoteId: data.quoteId,
                dueDate: data.dueDate,
                type: data.type,
                responsible: data.responsible,
                priority: data.priority,
                tickets: data.tickets,
                val: data.val
            });
        });
        allData = allData.sort(compare);
        return allData;
    }
    catch (e) {
        return e;
    }
}

async function fetchTicketsData() {
    try {
        let inboundData = (await db.ref(`delivery/webPortal/knowlarity/inbound`).once('value')).val();
        let missedList = {};
        let currentCampaignList = (await db.ref(`delivery/webPortal/knowlarity/currentCampaign`).once('value')).val();
        currentCampaignList = Object.keys(currentCampaignList ? currentCampaignList : {});
        let epoch = new Date();
        let date = epoch.getFullYear() + "-" + (epoch.getMonth() + 1) + "-" + epoch.getDate();
        let calledList = (await db.ref(`delivery/webPortal/knowlarity/dailyCallData/${date}`).once('value')).val();
        calledList = Object.keys(calledList ? calledList : {});
        //console.log("calledlist",calledList);
        Object.keys(inboundData ? inboundData : {}).forEach(number => {
            if(number){
            let callsList = inboundData[number];
            let lastCallKey = (Object.keys(callsList))[Object.keys(callsList).length - 1];
            if (lastCallKey) {
                if (callsList[lastCallKey].call_status == 'offhours' || callsList[lastCallKey].call_status == 'Missed') {
                    missedList[number] = 1;
                }
            }
        }
        });
        console.log("Missed list", missedList);
        let ticketsData = (await db.ref(`website/ticketSystem/tickets`).once('value')).val();
        let userTicketsMap = {};
        let tickets = await Promise.all(Object.values(ticketsData ? ticketsData : {}).map(async ticketData => {
            let userId = ticketData.phoneNumber;
            if (!ticketData.phoneNumber) {
                userId = (await db.ref(`mapstore/quoteidtocustomerid/${ticketData.quoteId}`).once('value')).val();
            }
            if ((((ticketData.status == 'Open' || ticketData.status == 'In Progress') && (ticketData.dueDate - new Date().getTime() < 86400000) && (ticketData.responsibleTeam=='CM')) || Object.keys(missedList ? missedList : {}).includes(userId)) && (!calledList.includes(userId) && currentCampaignList.includes(userId))) {
                //console.log(userId);
                let isMissed = 0;
                if (missedList[userId]) {
                    delete missedList[userId];
                    isMissed = 1;
                }
                if (userTicketsMap[userId]) {
                    ticketData.priority = ticketData.priority ? ticketData.priority : 'Normal';
                    let sortValue = 0;
                    if (ticketData.type == 'Payment') {
                        sortValue = 5;
                    }
                    else if (ticketData.priority == 'High') {
                        sortValue = 4;
                    }
                    else if (isMissed) {
                        sortValue = 3;
                    }
                    else if (ticketData.priority == 'Normal') {
                        sortValue = 2;
                    }
                    else if (ticketData.priority == 'Low') {
                        sortValue = 1;
                    }
                    userTicketsMap[userId] = {
                        quoteId: [...userTicketsMap[userId].quoteId, ticketData.quoteId],
                        dueDate: [...userTicketsMap[userId].dueDate, ticketData.dueDate],
                        responsible: [...userTicketsMap[userId].responsible, ticketData.assignedTo],
                        type: [...userTicketsMap[userId].type, ticketData.type],
                        tickets: [...userTicketsMap[userId].tickets, ticketData.ticketId],
                        priority: [...userTicketsMap[userId].priority, ticketData.priority ? ticketData.priority : 'Normal'],
                        val: sortValue > userTicketsMap[userId].val ? sortValue : userTicketsMap[userId].val
                    }
                }
                else {
                    let sortValue = 0;
                    if (ticketData.type == 'Payment') {
                        sortValue = 5;
                    }
                    else if (ticketData.priority == 'High') {
                        sortValue = 4;
                    }
                    else if (isMissed) {
                        sortValue = 3;
                    }
                    else if (ticketData.priority == 'Normal') {
                        sortValue = 2;
                    }
                    else if (ticketData.priority == 'Low') {
                        sortValue = 1;
                    }
                    userTicketsMap[userId] = {
                        quoteId: [ticketData.quoteId],
                        dueDate: [ticketData.dueDate],
                        responsible: [ticketData.assignedTo],
                        type: [ticketData.type],
                        tickets: [ticketData.ticketId],
                        priority: [ticketData.priority ? ticketData.priority : 'Normal'],
                        val: sortValue
                    }
                }
            }
            return ticketData;
        }));
        let allData = [];
        Object.keys(userTicketsMap ? userTicketsMap : {}).forEach(userId => {
            let data = userTicketsMap[userId];
            allData.push({
                caller: userId,
                quoteId: data.quoteId,
                dueDate: data.dueDate,
                type: data.type,
                responsible: data.responsible,
                priority: data.priority,
                tickets: data.tickets,
                val: data.val
            });
        });
        let remainingData = await Promise.all(Object.keys(missedList ? missedList : {}).map(async number => {
            let quoteId = await fetchQuoteId(number);
            allData.push({
                caller: number,
                quoteId: quoteId,
                val: 3
            });
            return quoteId;
        }));
        allData = allData.sort(compare);
        return allData;
    }
    catch (e) {
        return e;
    }
}

function compare(a, b) {
    if (a.val < b.val) {
        return 1;
    }
    else {
        return -1;
    }
}

async function fetchQuoteId(number) {
    try {
        let userData = (await db.ref(`users/${number}`).once('value')).val();
        let quoteList = [];
        if (userData && userData.mUser_SitesViewableIdList) {
            let sitesList = userData.mUser_SitesViewableIdList;
            quoteList = await Promise.all(sitesList.map(async (siteId) => {
                let quoteId = (await db.ref(`sites/${siteId}/mSite_QuoteId`).once('value')).val();
                // if(quoteId){
                return quoteId;
                // }
            }))
        }
        return quoteList;
    }
    catch (e) {
        return e;
    }
}


// async function fetchCurrentCallData() {
//     try {
//         console.log("FETCH CURRENT DATA");
//         let currentData = (await db.ref(`delivery/webPortal/knowlarity/stream`).once('value')).val();
//         console.log(currentData);
//         //currentData.shift();
//         let agentList = {};
//         let currentCallData = await Promise.all(currentData.map(async callData => {
//             if (callData) {
//                 let dateTime = callData['Event_Date_Local'];
//                 let dateTimeArray = '';
//                 let date = '';
//                 let time = '';
//                 if (dateTime) {
//                     dateTimeArray = dateTime.split(' ');
//                     date = dateTimeArray[0];
//                     time = dateTimeArray[1];
//                 }
//                 callData.date = new Date(dateTime).getTime();
//                 callData.time = time;
//                 let quoteList = await fetchQuoteId(callData.customer_number);
//                 // let quoteList = await fetchQuoteId('+917989144281');
//                 let quoteStr = '';
//                 quoteList.forEach(quoteId => {
//                     //eturn ('<a href="/quoteId/' + quote + '" target="_blank">' + quote + '</a>');
//                     quoteStr = quoteStr + '<a href="/quoteId/' + quoteId + '" target="_blank">' + ', ';
//                 })
//                 callData.quoteId = quoteList;
//                 let agentName = await fetchAgentName(callData.agent_number);
//                 if (agentName) {
//                     callData.agentName = agentName;
//                 }
//                 else {
//                     callData.agentName = callData.agent_number;
//                 }
//                 agentList[callData.agentName] = 1;
//                 let ticketsList = await fetchQuoteTickets(quoteList);
//                 let ticketStr = '';
//                 console.log("LISTTICKET", ticketsList);
//                 (ticketsList ? ticketsList : []).forEach(ticketId => {
//                     ticketStr = ticketStr + ticketId + ", ";
//                 })
//                 callData.tickets = ticketsList;
//             }
//             return callData;
//         }));
//         console.log(currentCallData);
//         let totalData = {
//             callData: currentCallData,
//             agentList: Object.keys(agentList ? agentList : {})
//         }
//         return totalData;
//     }
//     catch (e) {
//         return e;
//     }
// }


// async function fetchQuoteId(number) {
//     try {
//         let userData = (await db.ref(`users/${number}`).once('value')).val();
//         let quoteList = [];
//         if (userData && userData.mUser_SitesViewableIdList) {
//             let sitesList = userData.mUser_SitesViewableIdList;
//             quoteList = await Promise.all(sitesList.map(async (siteId) => {
//                 let quoteId = (await db.ref(`sites/${siteId}/mSite_QuoteId`).once('value')).val();
//                 // if(quoteId){
//                 return quoteId;
//                 // }
//             }))
//         }
//         return quoteList;
//     }
//     catch (e) {
//         return e;
//     }
// }


// async function fetchAgentName(agentNumber) {
//     try {
//         let userData = (await db.ref(`users/${agentNumber}/mUser_Contact`).once('value')).val();
//         if (userData) {
//             return userData.mContact_FirstName + " " + userData.mContact_LastName;
//         }
//         else {
//             return '';
//         }
//     }
//     catch (e) {
//         return e;
//     }
// }

// async function fetchQuoteTickets(quoteList) {
//     try {
//         let tickets = [];
//         let promiseList = await Promise.all((quoteList ? quoteList : []).map(async (quoteId) => {
//             let ticketList = (await db.ref(`website/ticketSystem/quoteToTicketsMap/${quoteId}`).once('value')).val();
//             if (ticketList) {
//                 console.log("list", Object.keys(ticketList));
//                 tickets.concat(Object.keys(ticketList));
//                 return Object.keys(ticketList);
//             }
//             else {
//                 return [];
//             }
//         }));
//         console.log("Total tickets", promiseList);
//         let merged = [];
//         if (promiseList) {
//             merged = promiseList.reduce(function (prev, next) {
//                 return prev.concat(next);
//             }, []);
//         }
//         console.log("Total tickets", merged);
//         return merged;
//     }
//     catch (e) {
//         return e;
//     }
// }