import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { Restrict } from '../components/Restrict';
import axios from 'axios';
import { CSVLink } from 'react-csv';

class GenerateQuickBooksToken extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clientId: '',
            clientSecret: '',
            loginLink: '',
            authUrl: '',
            file: '',
            type: '',
            fileData: [],
            uploadButtonDisabled: false
        };
        this.csvLink = React.createRef()
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }

    handleSubmitClick = async () => {
        console.log("Submit clicked");
        let { clientId, clientSecret } = this.state;
        if (!clientSecret || !clientId) {
            return;
        }
        let response = await axios.post('https://operations.zunroof.com/api/quickbooks/auth/generatelink', { clientId, clientSecret, key: "44ahe8YJzzVutK9" });
        response = response.data;
        if (response.error) {
            alert(response.error);
            return;
        }
        this.setState({
            loginLink: response.link
        })
    }

    generateToken = async () => {
        let { clientId, clientSecret, authUrl } = this.state;
        if (!clientSecret || !clientId || !authUrl) {
            return;
        }
        let response = await axios.post('https://operations.zunroof.com/api/quickbooks/auth/generatetoken', { clientId, clientSecret, key: "44ahe8YJzzVutK9", authUrl });
        response = response.data;
        if (response.error) {
            alert(response.error);
            return;
        }
        this.setState({
            authToken: response.token
        })
        alert("Token Generated.");
    }

    handleFileInputChange = (e) => {
        let file = e.target.files[0];
        this.setState({
            file: file
        })
    }

    handleFileSubmit = async () => {
        let { file, authToken, type } = this.state;
        if (!file || !authToken || !type) {
            return;
        }
        let formData = new FormData();
        formData.append("file", file);
        formData.append("key", "44ahe8YJzzVutK9");
        formData.append("authToken", authToken);
        formData.append("type", type);
        this.setState({ uploadButtonDisabled: true })
        let response = await axios.post('https://operations.zunroof.com/api/quickbooks/auth/createjournalentry', formData);
        response = response.data;
        this.setState({ uploadButtonDisabled: false });
        if (response.error) {
            alert(response.error);
            return;
        }
        if(response.fileData) {
            this.setState({
                fileData: response.fileData
            }, () => this.csvLink.current.link.click());
            return;
        }
        alert(response.message);
    }

    render() {

        if (this.props.user === null) {
            return <Redirect to={"/login?redirect_uri=quickbooks"} />;
        }

        if (this.props.user && this.props.user.level < 1600) {
            return (
                <div>
                    <Restrict />
                </div>
            )
        }

        return (

            <div style={{ "marginTop": 50, textAlign: "center" }} className="container">
                <div>
                    <h2 style={{ fontWeight: "bold" }}>Upload QuickBooks Data</h2>
                </div>
                <div className="row mt-4">
                    <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                        Client Id
                    </label>
                    <div className=" col-md-8">
                        <input type="text" className="form-control" name="clientId" onChange={(e) => this.handleInputChange(e)}></input>
                    </div>
                    <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                        Client Secret
                    </label>
                    <div className=" col-md-8">
                        <input type="text" className="form-control" name="clientSecret" onChange={(e) => this.handleInputChange(e)}></input>
                    </div>
                </div>
                {this.state.loginLink ? <a href={this.state.loginLink} target="_blank"><button type="button" className="btn btn-success">Open Link</button></a> : <button type="button" className="btn btn-success" onClick={this.handleSubmitClick}>Generate Link</button>}
                {
                    this.state.loginLink ? <div className="row mt-4">
                        <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                            Enter Auth Url
                    </label>
                        <div className=" col-md-8">
                            <input type="text" className="form-control" name="authUrl" onChange={(e) => this.handleInputChange(e)}></input>
                        </div>
                    </div> : ""
                }
                {this.state.loginLink && this.state.authUrl ? <button type="button" className="btn btn-success" onClick={this.generateToken}>Generate Token</button> : ""}
                {
                    this.state.authToken ? <div className="row mt-4">
                        <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                            Upload File
                        </label>
                        <div className=" col-md-8">
                            <input type="file" className="form-control" onChange={(e) => this.handleFileInputChange(e)} accept=".csv"></input>
                        </div>
                        <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                            Type
                        </label>
                        <div className="col-md-8">
                            <select className="form-control" onChange={(e) => this.handleInputChange(e)} name="type">
                                <option value="">Select</option>
                                <option value="Employee">Employee</option>
                                <option value="Customer">Customer</option>
                                <option value="Vendor">Vendor</option>
                            </select>
                        </div>
                    </div> : ""
                }
                {this.state.authToken ? <button type="button" className="btn btn-success" onClick={this.handleFileSubmit} disabled={this.state.uploadButtonDisabled} >Upload</button> : ""}
                <CSVLink data={this.state.fileData || []} filename={"Invalid Entries.csv"} style={{ display: "none" }} ref={this.csvLink} >Download</CSVLink>
            </div>
        );


    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateQuickBooksToken);