import React, { Component } from 'react';
import ReactTable from "react-table";
import Select from 'react-select';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom';
import Modal from 'react-responsive-modal';

import { fileUpload, fileDownload } from '../actions/fileUploadActions/fileUpload';
import { fetchInboundCall, fetchInboundCallData } from "../actions/fetchInboundCall";
import { addToDelivery } from "../actions/addToDelivery";
import { setUserRole } from "../actions/setUserRole";
import { addNewUser } from "../actions/addNewUser";
import { Restrict } from '../components/Restrict';
import { logincachecheck } from "../actions/auth";
import { Loader } from "../components/Loader.jsx";
import { styleSheet } from '../styles/styleSheet';
import { changeToViewDateTime, changeToInputDate, changeToViewDate } from '../helper/dateChanger';

import { fetchmainview } from '../actions/fetchmainview';
import { fetchmapping } from '../actions/fetchmapping';
import { addBulkNotification } from '../actions/addBulkNotification';
import { logout} from '../actions/auth';
import { fetchEmailsAnalytics, fetchClientAnalytics, searchData } from '../actions/fetchEmailsAnalytics';
import { setemailstatus } from '../actions/setemailstatus';
import moment from 'moment'

class InboundCall extends Component {
	constructor(props) {
	  super(props);
		this.state = {
			columnInboundCall : [
				{
					Header: 'Date',
					accessor: 'callDate',
					Cell: row => <div>{moment(+row.value).format("DD MMM, YYYY")}</div>
				},
				{
					Header: 'Time',
					accessor: 'callDate',
					Cell: row => <div>{moment(+row.value).format("HH:mm:ss")}</div>
				},
				// {
				// 	Header: 'Status',
				// 	accessor: 'business_call_type'
				// },
				{
					Header: 'Call Duration',
					accessor: 'call_duration'
				},
				{
					Header: 'Call Type',
					accessor: 'Call_Type',
					Cell: row => {
						let callTypes = ["Inbound", "Outbound"];
						return (callTypes[row.value] || "")
					}
				},
				{
					Header: 'Caller Number',
					accessor: 'caller_number'
				},
				{
					Header: 'Agent/Status',
					accessor: 'agent_number'
				},
				// {
				// 	Header: 'Conversation Duration',
				// 	accessor: 'conversation_duration'
				// },
				{
					Header: 'Quote Ids',
					accessor: 'quoteId',
					Cell : row => {
						let quotes = row.original.quoteId;
						return (
							quotes && quotes.length != 0 ? <div>
							{quotes.map(quote => {
								return (<a href={"/quoteId/" + quote} target="_blank">{quote}, </a>)
							})}
							</div> : <div></div>
						)
						// if () {
						// 	return (
						// 		<div>
						// 		{quotes.map(quote => {
						// 			return (<a href={"/quoteId/" + quote} target="_blank">{quote}</a>)
						// 		})}
						// 		</div>
						// 	)
						// } else {
						// 	return "-";
						// }
					}
				},
				// {
				// 	Header: 'Category',
				// 	accessor: 'menu_extension',
				// 	Cell : row => {
				// 		let menu = row.original.menu_extension && row.original.menu_extension != "None" ? row.original.menu_extension.split(",") : ["None"];
				// 		menu = row.original.menu_extension == "outbound" ? ["outbound"] : menu
				// 		let type = {
				// 			1 : "New Order",
				// 			2 : "Work In Progress",
				// 			3 : "Maintenance",
				// 			"None" : "None",
				// 			"outbound" : "Outbound"
				// 		}
				// 		return type[menu[0]]
				// 	}
				// },
				// {
				// 	Header: 'Sub-Category',
				// 	accessor: 'menu_extension',
				// 	Cell : row => {
				// 		let menu = row.original.menu_extension && row.original.menu_extension != "None" ? row.original.menu_extension.split(",") : ["None"];
				// 		let category = {
				// 			1 : "Payment",
				// 			2 : "Delivery",
				// 			3 : "Structure installation",
				// 			4 : "Net metering",
				// 			5 : "Complaint",
				// 			6 : "Other",
				// 			"None" : "None"
				// 		}
				// 		menu.shift();
				// 		while(menu[0] == '7'){
				// 			menu.shift();
				// 		}
				// 		let final = menu.length > 0 ? category[menu[0]] : "-"
				// 		return final;
				// 	}
				// },
			],
			received : false,
			data : [],
			page: 1,
			limit: 100,
			startTime: "2017-01-01 00:00:00",
			endTime: moment().format("YYYY-MM-DD HH:mm:ss")
		}
	}

	componentDidMount() {
		if(this.props.user){
			if(this.props.user.level>=1600){
				this.props.logincachecheck();
				let { startTime, endTime, limit, page, data } = this.state;
				this.props.fetchInboundCallData(startTime, endTime, limit, page, data);
			}
		}
	}

	componentWillReceiveProps(props) {
		const {data, user} = props;
		if(data.length>0){
			this.setState({
				data : data,
				user : user,
				received : true,
				loading: false
			})
		}
	}

	fetchNextPage = () => {
		let { startTime, endTime, limit, page, data } = this.state;
		this.props.fetchInboundCallData(startTime, endTime, limit, page+1, data);
		this.setState({
			page: page + 1,
			loading: true
		})
	}

	renderDeviceTable() {
		let { fetchNextPage } = this;
		return (
			<div className="container-fluid">
				<button className="btn btn-light btn-lg mb-3" onClick={fetchNextPage}>More calls <i className="fas fa-angle-double-right" aria-hidden="true"></i></button>
        <ReactTable
		defaultPageSize={100}
          className = "Rtable1"
          filterable
          data={this.state.data}
		  columns={this.state.columnInboundCall}
		  loading={this.state.loading}
          defaultSorted={[{id: "callDate",desc: true}]}
					style={
		  			{
		  				textAlign:'center',
							overflowX: 'scroll'
		  			}
		  		}
					defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase())>-1}
        />
      </div>
		)
	}

	render() {
    console.log(this.state.data);
		if(this.props.user===null){
			return <Redirect to="/login?redirect_uri=inbound" />;
		}

		if(this.props.user && this.props.user.level<1750) {
			return (
				<div>
					<Restrict />
				</div>
			)
		}

		if(this.state.received) {
			return (
				<div>
				<br/>
					<div>
						{this.renderDeviceTable()}
					</div>
				</div>
			)
		}

		return (
			<div style = {styleSheet.frameOne} className="container-fluid">
				<Loader />
			</div>
		);
	}

}

function mapStateToProps({user, inboundcall}) {
	let {data} = inboundcall;
	return {
		user : user,
		data : data
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ fetchInboundCall, logincachecheck, fetchInboundCallData}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(InboundCall);
