import { CREATE_TICKET_FAILED, CREATE_TICKET_SUCCESS } from '../actions/actiontypes'

const INIT_STATE = {
	completed:false,
}

export default function(state = INIT_STATE, action) {
	switch(action.type){
		case CREATE_TICKET_FAILED:{
			return {...state, data:action.payload};
		}
		case CREATE_TICKET_SUCCESS:{
			return {...state, data:action.payload};
		}
		default:
			return state;
	}
}