import React, { Component } from 'react';
import ReactTable from "react-table";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import Select from 'react-select';
import { Restrict } from '../components/Restrict';
import { fetchV2Data, fetchPocs } from '../actions/fetchV2Data';
import { v2DashboardColumns, pocListColumns } from '../config/column_fields';
import '../styles/v2Dashboard.css';

class v2Dashboard extends Component {
  constructor(props) {
    super(props)
    this.reactTable = React.createRef();
    this.state = {
      tableColumns: v2DashboardColumns,
      pocListColumns: pocListColumns,
      tableData: [],
      vendorNameArr: [],
      loading: true,
      statusChoosen: [],
      stateChoosen: [],
      openPocModal: false,
      vendorChoosen: [],
      startDate: 0,
      endDate: 0,
      states: [],
      status: [
        { value: "scheduled", label: "Scheduled" },
        { value: "completed", label: "Completed" },
        { value: "past due", label: "Past Due" },
        { value: "deleted", label: "Deleted" },
      ],
    }
  }

  componentDidMount() {
    if (this.props.user) {
      if (this.props.user.level >= 1600) {
        let user = this.props.user;
        this.props.fetchV2Data(user);
        v2DashboardColumns.map((val) => {
          if (val.accessor == "pocs") {
            val.Cell = row => {
              return (
                <div>
                  <button className="btn btn-info" onClick={(e) => this.fetchPocsList(e, row.original)}>Fetch POCs</button>
                </div>
              )
            }
          }
        })
      }
    }
  }

  componentWillReceiveProps(props) {
    let allData = props.allData ? props.allData : [];


    if (allData.length > 0) {
      let vendorName = props.vendorMap ? Object.keys(props.vendorMap).map((val) => ({ value: val, label: props.vendorMap[val] })) : []
      let statesArr = props.stateList ? props.stateList.map((val) => ({ value: val, label: val })) : []
      // console.log("states", props.stateList)
      this.setState({
        tableData: allData,
        tableDataCopy: allData,
        vendorNameArr: vendorName,
        loading: false,
        states: statesArr
      }, () => {
        // console.log(this.state.states)
      })
    }
  }

  checkSelectString = (str) => {
    if (str == "not selected") {
      return str = ""
    }
    else {
      return str
    }
  }
  removingNullKeys = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName].length < 1 || obj[propName] == "" || obj[propName] == {}) {
        delete obj[propName];
      }
    }
    return obj
  }

  handleInputChange = (e) => {
    let { name, value } = e.target;
    value = this.checkSelectString(value);
    value = this.removingNullKeys(value);
    this.setState({
      [name]: value
    }
    )
  }

  handleMultiSelectChange = (selectedOption, name) => {
    // console.log(selectedOption, name)
    this.setState({
      [name]: selectedOption
    });
  }

  inputType = (name, value, type, placeholder) => {
    return <input type={type} placeholder={placeholder} style={{ padding: "8px 8px" }} className="form-control" name={name} value={value} onChange={(e) => this.handleInputChange(e)} />
  }

  fetchPocsList = (e, row) => {
    let user = this.props.user;
    let vendorId = row.vendorId;
    // console.log(vendorId);
    this.props.fetchPocs(user, vendorId);
    this.setState({
      openPocModal: true
    })
  }

  openPocModal = (e) => {
    this.setState({
      openPocModal: true,
    })
  }

  closePocModal = (e) => {
    this.setState({
      openPocModal: false,
    })
  }

  onApplyFilter = (e) => {
    let startDate = this.state.startDate ? new Date(this.state.startDate).getTime() : 0;
    let endDate = this.state.endDate ? new Date(this.state.endDate).getTime() : 9999999999999;
    let statusChoosen = this.state.statusChoosen.map(val => val.value);
    let vendorChoosen = this.state.vendorChoosen.map(val => val.value);
    let stateChoosen = this.state.stateChoosen.map(val => val.value);
    let tableData = JSON.parse(JSON.stringify(this.state.tableData));
    // console.log(startDate, endDate, statusChoosen, vendorChoosen)

    let filteredTable = tableData.filter(obj => {
      let dateOfVisit = obj.date ? new Date(obj.date).getTime() : 0;

      return ((statusChoosen.length > 0 ? statusChoosen.includes(obj.status) : true) && (vendorChoosen.length > 0 ? vendorChoosen.includes(obj.vendorId) : true)) && (dateOfVisit >= startDate && dateOfVisit <= endDate) && (stateChoosen.length > 0 ? stateChoosen.includes(obj.state) : true)
    })

    // console.log("after filtered", filteredTable);
    this.setState({
      tableDataCopy: filteredTable
    })
  }

  render() {
    const style = {
      hide: {
        display: 'none'
      },
      show: {
        display: ''
      },
      iconShow: {
        float: 'right',
        display: ''
      },
      iconHide: {
        float: 'right',
        display: 'none'
      },
      showError: {
        display: '',
        color: 'red',
      },
      hideError: {
        display: 'none',
      }
    };

    if (this.props.user === null) {
      return <Redirect to={"/login?redirect_uri=v2dashboard"} />;
    }

    if (this.props.user && this.props.user.level < 1600) {
      return (
        <div>
          <Restrict />
        </div>
      )
    }
    return (
      <div className="container-fluid" style={{ marginTop: '50px', backgroundColor: "#f7f7f7" }}>
        <div style={{ textAlign: "center" }}>
          <h2 style={{ fontWeight: "bold" }}>V2 Dashboard</h2>
        </div>
        <div className="row v2-topbar-section">
          <div className="col-md-2">
            <label>Start Date</label>
            {this.inputType("startDate", this.state.startDate, "date")}
          </div>
          <div className="col-md-2">
            <label>End Date</label>
            {this.inputType("endDate", this.state.endDate, "date")}
          </div>
          <div className="col-md-2">
            <label>State </label>
            {selectCustom(this.state.states, "stateChoosen", "States", this.handleMultiSelectChange)}
          </div>
          <div className="col-md-2">
            <label>Status </label>
            {selectCustom(this.state.status, "statusChoosen", "Status", this.handleMultiSelectChange)}
          </div>
          <div className="col-md-2">
            <label>V2</label>
            {selectCustom(this.state.vendorNameArr, "vendorChoosen", "Vendor", this.handleMultiSelectChange)}
          </div>
          <div className="col-md-2">
            <button className="btn btn-success v2SubmitButton" onClick={(e) => { this.onApplyFilter() }}>Submit</button>
          </div>
        </div>
        <div className="v2-table-section">
          <ReactTable
            loading={this.state.loading}
            data={this.state.tableDataCopy}
            columns={this.state.tableColumns}
            filterable={true}
            showPagination={true}
            sortable={true}
            defaultPageSize={25}
            style={{ textAlign: "center", backgroundColor: "#ffffff" }}
            defaultSorted={[{ id: "date", desc: true }]}
          >

          </ReactTable>

          <Modal open={this.state.openPocModal} onClose={this.closePocModal}>
            <div style={{ textAlign: "center" }}>
              <h3 style={{ fontWeight: "bold", marginBottom: "15px" }}>{this.props.vendorName}</h3>
              <hr></hr>
              <ReactTable
                data={this.props.pocList}
                columns={this.state.pocListColumns}
                filterable={true}
                showPagination={true}
                sortable={true}
                defaultPageSize={5}
                style={{ textAlign: "center", backgroundColor: "#ffffff" }}
              >
              </ReactTable>
            </div>
          </Modal>
        </div>
      </div>
    )
  }
}

const selectCustom = (options, name, placeholder, handleMultiSelectChange, defaultValue) => {
  const changeHandle = (value) => { handleMultiSelectChange(value, name) }
  return (

    <Select
      isMulti
      isSearchable
      onChange={(e) => { changeHandle(e) }}
      placeholder={placeholder}
      name={name}
      options={options}
      defaultValue={defaultValue}
    />
  )
}

const mapStateToProps = (state) => {
  // console.log(state)
  return {
    user: state.user,
    allData: state.v2AllData.tableData ? state.v2AllData.tableData : [],
    pocList: state.v2PocList.pocData ? state.v2PocList.pocData : [],
    vendorName: state.v2PocList.vendorName ? state.v2PocList.vendorName : "",
    vendorMap: state.v2AllData.vendorMap ? state.v2AllData.vendorMap : {},
    stateList: state.v2AllData.states ? state.v2AllData.states : [],
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchV2Data, fetchPocs }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(v2Dashboard)


