import { UPLOADING_STARTED, UPLOADING_SUCCESS, UPLOADING_ERROR } from '../actions/actiontypes';

let INIT_STATUS = {
	uploadStarted:[],
	uploadSuccess:[],
	uploadError:[]
}

export default function(state = INIT_STATUS, action) {
	switch(action.type){
		case UPLOADING_STARTED:{
			return {...state,uploadStarted:[...state.uploadStarted,action.payload]};
		}

		case UPLOADING_SUCCESS:{
			console.log(state.uploadStarted);
			let index = state.uploadStarted.indexOf(action.payload);
			if (index > -1) {
			  state.uploadStarted.splice(index, 1);
			}
			return {...state,uploadStarted:[...state.uploadStarted],uploadSuccess:[...state.uploadSuccess,action.payload]};
		}

		case UPLOADING_ERROR:{
			let index = state.uploadStarted.indexOf(action.payload);
			if (index > -1) {
			  state.splice(index, 1);
			}
			return {...state,uploadStarted:[...state.uploadStarted],uploadError:[...state.uploadError,action.payload]};
		}

		default:
			return state;
	}
}