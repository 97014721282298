import React from 'react';
import Select from 'react-select';

export const FilterView = ({ stageMap, stateMap, subsidyMap, ebissueMap, financingMap, employeeMap, onChangeHandle, applyFilter, handleDateInputChange, handleInputChange, handleViewChange, currentView, currentViewButton, onClickModal }) => {
	return (
		<div>
			<div className="filter_deals__subpart">
				<div className="filter_selector">
					<div className="row" style={{width:'100%'}}>
						<SelectView 
							placeholder = {"Choose State"}
							options = {stateMap?stateMap.map(val => {return {label:val,value:val}}):[]}
							name = "stateFilter"
							onChangeHandle = {onChangeHandle}
						/>
						<SelectView 
							placeholder = {"Choose Subsidy"}
							options = {subsidyMap?subsidyMap.map(val => {return {label:val,value:val}}):[]}
							name = "subsidyFilter"
							onChangeHandle = {onChangeHandle}
						/>
						<SelectView 
							placeholder = {"Choose Stage"}
							options = {stageMap?stageMap.map((val,index) => {return {label:val,value:index}}):[]}
							name = "stageFilter"
							onChangeHandle = {onChangeHandle}
						/>
						<div className="col-md-2 col-6 filter_box filter_box_button">
							<div className="filter_selector__drop_down_label1" style={{marginBottom:'6%'}}></div>
							<button className="btn btn-success applyButton" onClick={applyFilter}>Apply Filter</button>
						</div>
					</div>
				</div>
			</div>
			<div className="row" style = {{marginBottom:10}}>
				<div className="col-lg-2 col-6" style={{padding:'0px 15px'}}>
					Sales Start Date:
					<input
					  style={{width:"100%",borderRadius:5,padding:'8px 8px'}}
					  onChange={handleDateInputChange}
					  name="startDate"
					  type='date'
					/>
				</div>
				<div className="col-lg-2 col-6" style={{padding:'0px 10px'}}>
					Sales End Date:
					<input
					  style={{width:"100%",borderRadius:5,padding:'8px 8px'}}
					  onChange={handleDateInputChange}
					  name="endDate"
					  type='date'
					/>
				</div>
				<div className="col-lg-1 col-6" style={{padding:'0px 10px'}}>
					% Start Value:
					<input
					  style={{width:85,borderRadius:5,padding:'8px 8px'}}
					  name="startPercentage"
					  type='number'
					  onChange={handleInputChange}
					  placeholder='Start Value'
					/>
				</div>
				<div className="col-lg-1 col-6" style={{padding:'0px 10px'}}>
					% End Value:
					<input
					  style={{width:85,borderRadius:5,padding:'8px 8px'}}
					  name="endPercentage"
					  type='number'
					  onChange={handleInputChange}
					  placeholder='Last Value'
					/>
				</div>
				<div className="col-lg-6 col-6">
					<div style={{margin: '14px auto',display:'flex','flexDirection':'row-reverse'}}>
						<div style={{padding:'0 5px'}}><button className="btn btn-success" onClick={() => {handleViewChange(currentView?0:1)}}>{currentViewButton}</button></div>
						<div style={{padding:'0 5px'}}><i className="fas fa-cog font-icon" onClick={() => {onClickModal("openModal")}} ></i></div>
					</div>					
				</div>
			</div>
		</div>
	);
}


const SelectView = ({ placeholder, options, onChangeHandle, name }) => {
	const changeValue = (value) => {onChangeHandle(value,name)}
	return (
		<div className="col-md-2 col-6 filter_box ">
			<div className="drop_down1">
				<Select
					isMulti
					isSearchable
					name={name}
					placeholder={placeholder}
					options={options}
					onChange={(value) => changeValue(value)}
				/>
			</div>
		</div>
	)
}