import { FETCH_PAYMENT_COMMENTS_START, FETCH_PAYMENT_COMMENTS_SUCCESS } from '../actions/actiontypes';

export default function (state = {}, action) {
  switch (action.type) {
    case FETCH_PAYMENT_COMMENTS_START: {
      return {};
    }
    case FETCH_PAYMENT_COMMENTS_SUCCESS: {
      return { ...state, commentData: action.payload }
    }
    default:
      return state;
  }
}