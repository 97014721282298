import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment';
import { base, db } from '../connection';
import { FETCH_V2_DATA_START, FETCH_V2_DATA_SUCCESS, FETCH_V2_DATA_ERROR, FETCH_V2_POCS_START, FETCH_V2_POCS_SUCCESS } from './actiontypes';

export function fetchV2Data(user) {
  return async (dispatch) => {
    dispatch({ type: FETCH_V2_DATA_START, payload: [] });
    let key = "CLFx4DtJ2MGKw7";
    let stateCityMap = (await db.ref('/uploads/systemdesign/state_city_list_0').once('value')).val();
    delete stateCityMap["-Not Selected-"];
    console.log(stateCityMap)
    let response = await axios.post('https://operations.zunroof.com/api/v2/all', { key, user });
    console.log(response);
    if (response.data.error) {
      dispatch({ type: FETCH_V2_DATA_ERROR, payload: response.data.error })
    }
    if (response.data.data) {
      dispatch({ type: FETCH_V2_DATA_SUCCESS, payload: { tableData: response.data.data, vendorMap: response.data.vendorMap, states: Object.keys(stateCityMap) } })
    }
  }
}

export function fetchPocs(user, vendorId) {
  return async (dispatch) => {
    dispatch({ type: FETCH_V2_POCS_START, payload: {} });
    let key = "CLFx4DtJ2MGKw7";
    let response = await axios.post('https://operations.zunroof.com/api/v2/pocList', { key, user, vendorId });
    console.log("response from api", response);
    if (response.data.error) {
      swal("some error occurred")
    }
    if (response.data.pocData) {
      dispatch({ type: FETCH_V2_POCS_SUCCESS, payload: response.data });
    }
  }
}