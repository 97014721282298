import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import Select from 'react-select';
import { Restrict } from '../components/Restrict';
import { peTaskColumns } from '../config/column_fields';
import Modal from 'react-responsive-modal';
import { fetchPeTask, fetchQuoteId, fetchPeMapping, createNewTask, updateTask, deleteTask, searchSites, resetAutoAssign, autoAssignTasks } from '../actions/fetchPeTasks';
import moment from 'moment';
import debounce from 'lodash/debounce'
import { Link } from 'react-router-dom';

class PeTask extends Component {


    constructor(props) {
        super(props);
        this.state = {
            showColumns: peTaskColumns,
            allData:[],
            viewData: [],
            openUpdateModal: false,
            openCreateModal: false,
            openAutoAssignModal: false,
            quoteList: [],
            peMapping: {},
            employeeList: {},
            newTask: {
                date: '',
                quoteId: '',
                state: '',
                type: '',
                startTime: '',
                employee: '',
                comment: '',
                pmComment: '',
                priority:'',
                size_del: ''
            },
            updateTask: {
                date: '',
                quoteId: '',
                state: '',
                type: '',
                startTime: '',
                employee: '',
                comment: '',
                pmComment: '',
                id:'',
                priority:'',
                status:'',
                statusVal:''
            },
            filters: {
                startDate: '',
                endDate: '',
                state: '',
                employee: ''
            },
            appliedFilters:{},
            loading: true,
            editButton:true,
            selectView:"ALL",
            searchBy: {label: "Quote Id", value: "quoteId"},
            fetchQuoteIdError: false,
            cityList: [],
            autoAssignData: {
                city: '',
                date: ''
            }
        };

        this.hours = [
            { label: '09:00', value: 9 },
            { label: '10:00', value: 10 },
            { label: '11:00', value: 11 },
            { label: '12:00', value: 12 },
            { label: '13:00', value: 13 },
            { label: '14:00', value: 14 },
            { label: '15:00', value: 15 },
            { label: '16:00', value: 16 },
            { label: '17:00', value: 17 },
            { label: '18:00', value: 18 }
            // { label: '19:00', value: 19 },
            // { label: '20:00', value: 20 }
        ];
        this.taskTypes = ["Maintenance", "Quality", "Layout", "Documentation", "Discoms", "Others", "Rms Scan", "Cheque Collection", "Site Ceremony", "Subsidy", "Flex Upload"];
        this.priorityTypes = ["High", "Medium", "Low"];
    }

    componentDidMount() {
        if (this.props.user) {
            if (this.props.user.level >= 1600) {
                this.props.fetchPeTask({});
                // this.props.fetchQuoteId();
                this.props.fetchPeMapping();
            }
        }
    }


    componentWillReceiveProps(props) {
        let { peData, quoteIdList, peMapping, employeeList, loading, fetchQuoteIdError, stateCityMap, stateSubsidyMap, cityDiscomMap } = props;
        let { appliedFilters, selectView } = this.state;
        let { state, employee } = appliedFilters;
        let viewData = (peData||[]).filter(task => {
            if((!state || state == task.state) && (!employee || employee == task.employeeId) && (selectView=='ALL' || selectView.toUpperCase() == task.state.toUpperCase())){
                return true;
            }
            else{
                return false;
            }
        }) 
        let cityList = Object.values(stateCityMap || {}).reduce((cityList, current) => [...cityList, ...current], []);
        this.setState({
            viewData,
            allData: peData,
            quoteList: quoteIdList || [],
            peMapping: peMapping || {},
            employeeList: employeeList || {},
            loading,
            editButton: peMapping&&Object.keys(peMapping).length?false:true,
            fetchQuoteIdError,
            stateCityMap,
            stateSubsidyMap,
            cityDiscomMap,
            cityList
        })
        if(employeeList&&Object.keys(employeeList).length){
            let showColumns = [...this.state.showColumns];
            let columns = showColumns.map(val => {
                let column = {...val};
                if(column.accessor == 'employeeId'){
                    column.Cell = row => <div>{employeeList[row.value]}</div>
                }
                return column;
            })
            this.setState({
                showColumns:columns
            })
        }
    }


    openModal = () => {
        let defaultDate = moment().add(1, 'days').format('YYYY-MM-DD');
        this.setState({
            openCreateModal: true,
            newTask:{
                date:defaultDate
            }
        })
    }

    onCloseCreateModal = () => {
        this.setState({
            openCreateModal: false
        })
    }

    onCloseUpdateModal = () => {
        this.setState({
            openUpdateModal: false
        })
    }

    handleSelectChange = (e, name) => {
        if(name == 'quoteId'){
            this.setState({
                newTask: {
                    ...this.state.newTask,
                    [name]: e.value,
                    state: e.state,
                    size_del: e.size_del
                }
            })
        }
        else if(name == 'type') {
            let typeList = (e||[]).map(row => row.value);
            let taskType = [];
            if((typeList.includes('Discoms') || typeList.includes('Subsidy')) && typeList.length > 1) {
                taskType = [ typeList[typeList.length - 1] ];
            }
            else {
                taskType = typeList;
            }
            this.setState({
                newTask: {
                    ...this.state.newTask,
                    [name]: taskType.join(', ')
                }
            })
        }
        else{
            this.setState({
                newTask: {
                    ...this.state.newTask,
                    [name]: e.value
                }
            })
        }
    }

    handleSelectChangeFilters = (e, name) => {
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: e.value
            }
        })
    }

    handleSelectChangeUpdate = (e, name) => {
        if(name == 'type') {
            let typeList = (e||[]).map(row => row.value);
            let taskType = [];
            if((typeList.includes('Discoms') || typeList.includes('Subsidy')) && typeList.length > 1) {
                taskType = [ typeList[typeList.length - 1] ];
            }
            else {
                taskType = typeList;
            }
            this.setState({
                updateTask: {
                    ...this.state.updateTask,
                    [name]: taskType.join(', ')
                }
            });
            return;
        }
        this.setState({
            updateTask: {
                ...this.state.updateTask,
                [name]: e.value
            }
        })
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            newTask: {
                ...this.state.newTask,
                [name]: value
            }
        })
    }

    handleInputChangeFilters = (e) => {
        let { name, value } = e.target;
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value
            }
        })
    }

    handleInputChangeUpdate = (e) => {
        let { name, value } = e.target;
        this.setState({
            updateTask: {
                ...this.state.updateTask,
                [name]: value
            }
        })
    }


    createTaskSubmit = () => {
        let { newTask, allData } = this.state;
        this.props.createNewTask(newTask, allData);
        // this.setState({
        //     openCreateModal:false
        // })
    }

    updateTaskSubmit = () => {
        let { updateTask, allData } = this.state;
        this.props.updateTask(updateTask, allData);
        // this.setState({
        //     openUpdateModal:false
        // })
    }

    applyFilters = () => {
        console.log("apply");
        let { filters } = this.state;
        this.setState({
            loading:true,
            appliedFilters:filters
        })
        this.props.fetchPeTask(filters);
    }

    editTask = (taskId) => {
        let { allData } = this.state;
        let taskData = allData.find(task => task.taskId == taskId);
        let taskDefaultData = {
            date: moment(taskData.date, 'YYYYMMDD').format('YYYY-MM-DD'),
            quoteId: taskData.quoteId,
            state: taskData.state,
            type: taskData.type,
            startTime: moment(parseInt(taskData.startTime)).format('HH'),
            employee: taskData.employeeId,
            pmComment: taskData.pmComment,
            comment: taskData.comment,
            id:taskData.taskId,
            priority:taskData.priority,
            status: taskData.status,
            statusVal: taskData.statusVal,
            city: taskData.city
        };
        this.setState({
            openUpdateModal:true,
            updateTask:taskDefaultData
        })
    }

    handleOnViewChange = (value) => {
        let { allData } = this.state;
        let viewData = [];
        if(value != 'ALL'){
            viewData = allData.filter(task => task.state.toUpperCase() == value.toUpperCase());
        }
        else{
            viewData = [...allData];
        }
        this.setState({
            selectView: value,
            viewData
        })
    }

    deleteTask = (taskId) => {
        let { allData } = this.state;
        this.props.deleteTask(taskId, allData);
    }

    inputQuoteIdChange = (e = "") => {
        if(!e) {
            return;
        }
        this.setState({
            quoteList: [],
            fetchQuoteIdError: false
        })
        let { searchBy } = this.state;
        this.props.searchSites(e, searchBy.value);
    }

    onCloseAutoAssignModal = () => {
        console.log("close");
        this.props.resetAutoAssign();
        // this.applyFilters();
        this.setState({
            openAutoAssignModal: false
        })
    }

    onClickAutoAssign = () => {
        this.props.resetAutoAssign();
        this.setState({
            openAutoAssignModal: true
        })
    }

    handleSelectChangeAutoAssign = (e, name) => {
        this.setState({
            autoAssignData: {
                ...this.state.autoAssignData,
                [name]: e.value
            }
        })
    }

    handleInputChangeAutoAssign = (e) => {
        let { name, value } = e.target;
        this.setState({
            autoAssignData: {
                ...this.state.autoAssignData,
                [name]: value
            }
        })
    }

    autoAssignTaskSubmit = () => {
        let { autoAssignData = {} } = this.state;
        this.props.autoAssignTasks(autoAssignData);
        console.log("data", autoAssignData);
    }

    render() {
        if (this.props.user === null) {
            return <Redirect to={"/login?redirect_uri=petasks"} />;
        }
        if (this.props.user && this.props.user.level < 1600) {
            return (
                <div>
                    <Restrict />
                </div>
            )
        }
        const modalStyles = {
            modal: {
                width: '80%',
                maxWidth: '80%',
                padding: '40px'
            }
        }
        let { handleSelectChange, hours, taskTypes, priorityTypes, createTaskSubmit, handleInputChange, handleInputChangeFilters, handleSelectChangeFilters, applyFilters, onCloseCreateModal, onCloseUpdateModal, editTask, handleInputChangeUpdate, handleSelectChangeUpdate, updateTaskSubmit, handleOnViewChange, deleteTask, onCloseAutoAssignModal, onClickAutoAssign, handleSelectChangeAutoAssign, handleInputChangeAutoAssign, autoAssignTaskSubmit } = this;
        let { newTask, quoteList, peMapping, employeeList, filters, updateTask, openCreateModal, openUpdateModal, editButton, selectView, searchBy, stateCityMap, stateSubsidyMap, cityDiscomMap, openAutoAssignModal, cityList, autoAssignData } = this.state;
        let { autoAssignStatus = {} } = this.props;
        return (

            <div style={{ "marginTop": 50 }} className="container-fluid">
                <div className="formButtons" style={{float: 'left'}}>
                    <button className={`btn btn-primary paymentViewButton btn-color ${selectView === "ALL" ? "select" : ""}`} name={"ALL"} onClick={(e) => handleOnViewChange("ALL")} value={"ALL"}>{"ALL"}</button>
                    {
                        Object.keys(peMapping?peMapping:{}).map(state => {
                            return (
                                <button className={`btn btn-primary paymentViewButton btn-color ${selectView === state ? "select" : ""}`} name={state} onClick={(e) => handleOnViewChange(state)} value={state}>{state}</button>
                            )
                        })
                    }
				</div>
                <div style={{float: 'right'}}>
                    <Link to={"/petaskcenters"} className="btn btn-info" onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/petaskcenters/"); }}>Subsidy/Discom Centers <i className="fa fa-external-link-alt" aria-hidden="true"></i></Link>
                </div>
                <div style={{clear: 'both'}}></div>
                <button className="btn btn-success" style={{marginTop:'1%'}} onClick={() => this.openModal()}>Create Task</button>
                <div className="row filters">
                    <div className="col-12 col-md-2" style={{ width: '100%', paddingTop: '1%' }}>
                        <div className="form-group">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">Start Date</span>
                                </div>
                                <input className="form-control customInput" type="date" name={"startDate"} placeholder={"Start Date"} onChange={(e) => handleInputChangeFilters(e)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-2" style={{ width: '100%', paddingTop: '1%' }}>
                        <div className="form-group">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">End Date</span>
                                </div>
                                <input className="form-control customInput" type="date" name={"endDate"} placeholder={"End Date"} onChange={(e) => handleInputChangeFilters(e)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-2" style={{ width: '100%', paddingTop: '1%' }}>
                        <Select
                            isSearchable
                            placeholder={"Select State"}
                            name={"state"}
                            options={[{label:'Select', value:''}, ...Object.keys(peMapping?peMapping:{}).map(val => { return ({ label: val, value: val }) })]}
                            onChange={(e) => handleSelectChangeFilters(e, "state")}
                        />
                    </div>
                    <div className="col-12 col-md-2" style={{ width: '100%', paddingTop: '1%' }}>
                        <Select
                            isSearchable
                            placeholder={"Select Employee"}
                            name={"employee"}
                            options={[{label:'Select', value:''}, ...(filters.state ? peMapping[filters.state].map(val => { return ({ label: employeeList[val], value: val }) }) : [])]}
                            onChange={(e) => handleSelectChangeFilters(e, "employee")}
                        />
                    </div>
                    <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '1%' }}>
                        <button className="btn btn-success" onClick={() => applyFilters()} style={{float: 'left'}}>Apply Filter</button>
                        <button className="btn btn-outline-primary" onClick={() => onClickAutoAssign()} style={{float: 'right'}}>Auto Assign Tasks</button>
                    </div>
                </div>
                <Modal styles={modalStyles} open={openCreateModal} onClose={() => onCloseCreateModal()}>
                    <div style={{textAlign:'center', fontSize:'22px'}}>Create Task</div>
                    <div className="row">
                        <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '2%' }}>
                            <div className="row">
                                <Select
                                    placeholder={"Search"}
                                    name={"searchBy"}
                                    options={[{label: "Quote Id", value: "quoteId"}, {label: "Name", value: "name"}]}
                                    onChange={(e) => this.setState({ searchBy: e })}
                                    className={"col-md-4"}
                                    value={searchBy}
                                />
                                <Select
                                    isSearchable
                                    placeholder={"Select Quote Id"}
                                    name={"quoteId"}
                                    options={quoteList.map(val => { return ({ label: `${val.quoteId} ${val.name}`, value: val.quoteId, state: val.state, size_del: val.size_del }) })}
                                    onChange={(e) => handleSelectChange(e, "quoteId")}
                                    onInputChange={debounce((e) => this.inputQuoteIdChange(e), 3000)}
                                    noOptionsMessage={() => this.state.fetchQuoteIdError?"No results found":"Loading..."}
                                    className={"col-md-8"}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '2%' }}>
                            <Select
                                isSearchable
                                placeholder={"Select State"}
                                name={"state"}
                                value={Object.keys(peMapping).map(val => { return ({ label: val, value: val }) }).find(val => val.value == newTask.state)}
                                options={Object.keys(peMapping).map(val => { return ({ label: val, value: val }) })}
                                onChange={(e) => handleSelectChange(e, "state")}
                            />
                        </div>
                        <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '2%' }}>
                            <Select
                                isSearchable
                                placeholder={"Select Employee"}
                                name={"employee"}
                                options={newTask.state ? (peMapping[newTask.state]||[]).map(val => { return ({ label: employeeList[val], value: val }) }) : []}
                                onChange={(e) => handleSelectChange(e, "employee")}
                            />
                        </div>
                        <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '2%' }}>
                            <Select
                                isSearchable
                                placeholder={"Select City"}
                                name={"city"}
                                options={newTask.state ? (stateCityMap[newTask.state]||[]).map(val => { return ({ label: val, value: val }) }) : []}
                                onChange={(e) => handleSelectChange(e, "city")}
                            />
                        </div>
                        <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '2%' }}>
                            <div className="form-group">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">Date</span>
                                    </div>
                                    <input className="form-control customInput" type="date" name={"date"} placeholder={"date"} value={newTask.date} onChange={(e) => handleInputChange(e)} />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '2%' }}>
                            <Select
                                isSearchable
                                placeholder={"Select Start Time"}
                                name={"startTime"}
                                options={hours}
                                onChange={(e) => handleSelectChange(e, "startTime")}
                            />
                        </div>
                        <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '2%' }}>
                            <Select
                                isMulti
                                isSearchable
                                placeholder={"Select Task Type"}
                                name={"type"}
                                options={taskTypes.map(val => { return ({ label: val, value: val }) })}
                                onChange={(e) => handleSelectChange(e, "type")}
                                value={newTask.type?newTask.type.split(', ').map(val => { return ({ label: val, value: val }) }):[]}
                            />
                        </div>
                        {
                            newTask.type == "Subsidy" ? <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '2%' }}>
                                <Select
                                    isSearchable
                                    placeholder={"Select Center"}
                                    name={"centerKey"}
                                    options={newTask.state ? (stateSubsidyMap[newTask.state]||[]): []}
                                    onChange={(e) => handleSelectChange(e, "centerKey")}
                                />
                            </div> : ""
                        }
                        {
                            newTask.type == "Discoms" ? <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '2%' }}>
                                <Select
                                    isSearchable
                                    placeholder={"Select Center"}
                                    name={"centerKey"}
                                    options={newTask.city ? (cityDiscomMap[newTask.city]||[]): []}
                                    onChange={(e) => handleSelectChange(e, "centerKey")}
                                />
                            </div> : ""
                        }
                        <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '2%' }}>
                            <div className="form-group">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">PM Comment</span>
                                    </div>
                                    <input className="form-control customInput" type="comment" name={"pmComment"} placeholder={"PM Comment"} onChange={(e) => handleInputChange(e)} />
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '2%' }}>
                            <div className="form-group">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">Comment</span>
                                    </div>
                                    <input className="form-control customInput" type="comment" name={"comment"} placeholder={"Comment"} onChange={(e) => handleInputChange(e)} />
                                </div>
                            </div>
                        </div> */}
                        <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '2%' }}>
                            <Select
                                isSearchable
                                placeholder={"Select Priority"}
                                name={"priority"}
                                options={priorityTypes.map(val => { return ({ label: val, value: val }) })}
                                onChange={(e) => handleSelectChange(e, "priority")}
                            />
                        </div>
                        <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '2%' }}>
                            <button className="btn btn-success" onClick={() => createTaskSubmit()}>Create</button>
                        </div>
                    </div>
                </Modal>
                <Modal styles={modalStyles} open={openUpdateModal} onClose={() => onCloseUpdateModal()}>
                    <div style={{textAlign:'center', fontSize:'22px'}}>Update Task</div>
                    <div className="row">
                        <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '2%' }}>
                            <div className="row">
                                <Select
                                    placeholder={"Search"}
                                    name={"searchBy"}
                                    options={[{label: "Quote Id", value: "quoteId"}, {label: "Name", value: "name"}]}
                                    onChange={(e) => this.setState({ searchBy: e })}
                                    className={"col-md-4"}
                                    value={searchBy}
                                />
                                <Select
                                    isSearchable
                                    placeholder={"Select Quote Id"}
                                    name={"quoteId"}
                                    options={quoteList.map(val => { return ({ label: `${val.quoteId} ${val.name}`, value: val.quoteId, state: val.state, size_del: val.size_del }) })}
                                    onChange={(e) => handleSelectChangeUpdate(e, "quoteId")}
                                    // defaultValue={quoteList.map(val => { return ({ label: `${val.quoteId} ${val.name}`, value: val.quoteId, state: val.state }) }).find(val=>val.value==updateTask.quoteId)}
                                    defaultValue={[{label: updateTask.quoteId, value: updateTask.quoteId}]}
                                    onInputChange={debounce((e) => this.inputQuoteIdChange(e), 3000)}
                                    noOptionsMessage={() => this.state.fetchQuoteIdError?"No results found":"Loading..."}
                                    className={"col-md-8"}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '2%' }}>
                            <Select
                                isSearchable
                                placeholder={"Select State"}
                                name={"state"}
                                options={Object.keys(peMapping).map(val => { return ({ label: val, value: val }) })}
                                onChange={(e) => handleSelectChangeUpdate(e, "state")}
                                defaultValue={Object.keys(peMapping).map(val => { return ({ label: val, value: val }) }).find(val => val.value == updateTask.state)}
                            />
                        </div>
                        <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '2%' }}>
                            <Select
                                isSearchable
                                placeholder={"Select Employee"}
                                name={"employee"}
                                options={updateTask.state&&Object.keys(peMapping).length ? peMapping[updateTask.state].map(val => { return ({ label: employeeList[val], value: val }) }) : []}
                                onChange={(e) => handleSelectChangeUpdate(e, "employee")}
                                defaultValue={(updateTask.state&&Object.keys(peMapping).length ? peMapping[updateTask.state].map(val => { return ({ label: employeeList[val], value: val }) }) : []).find(val => val.value==updateTask.employee)}
                            />
                        </div>
                        <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '2%' }}>
                            <Select
                                isSearchable
                                placeholder={"Select City"}
                                name={"city"}
                                options={updateTask.state&&Object.keys(stateCityMap).length ? stateCityMap[updateTask.state].map(val => { return ({ label: val, value: val }) }) : []}
                                onChange={(e) => handleSelectChangeUpdate(e, "city")}
                                defaultValue={(updateTask.state&&Object.keys(stateCityMap).length ? stateCityMap[updateTask.state].map(val => { return ({ label: val, value: val }) }) : []).find(val => val.value==updateTask.city)}
                            />
                        </div>
                        <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '2%' }}>
                            <div className="form-group">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">Date</span>
                                    </div>
                                    <input className="form-control customInput" type="date" name={'date'} placeholder={"date"} defaultValue={updateTask.date} onChange={(e) => handleInputChangeUpdate(e)} readOnly/>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '2%' }}>
                            <Select
                                isSearchable
                                placeholder={"Select Start Time"}
                                name={"startTime"}
                                options={hours}
                                onChange={(e) => handleSelectChangeUpdate(e, "startTime")}
                                defaultValue={hours.find(val => val.value == updateTask.startTime)}
                            />
                        </div>
                        <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '2%' }}>
                            <Select
                                isMulti
                                isSearchable
                                placeholder={"Select Task Type"}
                                name={"type"}
                                options={taskTypes.map(val => { return ({ label: val, value: val }) })}
                                onChange={(e) => handleSelectChangeUpdate(e, "type")}
                                // defaultValue={taskTypes.map(val => { return ({ label: val, value: val }) }).find(val => val.value==updateTask.type)}
                                value={updateTask.type?updateTask.type.split(', ').map(val => { return ({ label: val, value: val }) }):[]}
                            />
                        </div>
                        {
                            updateTask.type == "Subsidy" ? <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '2%' }}>
                                <Select
                                    isSearchable
                                    placeholder={"Select Center"}
                                    name={"centerKey"}
                                    options={updateTask.state ? (stateSubsidyMap[updateTask.state]||[]): []}
                                    onChange={(e) => handleSelectChange(e, "centerKey")}
                                    defaultValue={(updateTask.state&&Object.keys(stateSubsidyMap).length ? (stateSubsidyMap[updateTask.state] || []) : []).find(val => val.value==updateTask.centerKey)}
                                />
                            </div> : ""
                        }
                        {
                            updateTask.type == "Discoms" ? <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '2%' }}>
                                <Select
                                    isSearchable
                                    placeholder={"Select Center"}
                                    name={"centerKey"}
                                    options={updateTask.city ? (cityDiscomMap[updateTask.city]||[]): []}
                                    onChange={(e) => handleSelectChange(e, "centerKey")}
                                    defaultValue={(updateTask.city&&Object.keys(cityDiscomMap).length ? (cityDiscomMap[updateTask.city] || []) : []).find(val => val.value==updateTask.centerKey)}
                                />
                            </div> : ""
                        }
                        <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '2%' }}>
                            <div className="form-group">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">PM Comment</span>
                                    </div>
                                    <input className="form-control customInput" type="comment" name={"pmComment"} defaultValue={updateTask.pmComment} placeholder={" PM Comment"} onChange={(e) => handleInputChangeUpdate(e)} />
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '2%' }}>
                            <div className="form-group">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">Comment</span>
                                    </div>
                                    <input className="form-control customInput" type="comment" name={"comment"} defaultValue={updateTask.comment} placeholder={"Comment"} onChange={(e) => handleInputChangeUpdate(e)} />
                                </div>
                            </div>
                        </div> */}
                        <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '2%' }}>
                            <Select
                                isSearchable
                                placeholder={"Select Priority"}
                                name={"priority"}
                                options={priorityTypes.map(val => { return ({ label: val, value: val }) })}
                                onChange={(e) => handleSelectChangeUpdate(e, "priority")}
                                defaultValue={priorityTypes.map(val => { return ({ label: val, value: val }) }).find(val => val.value==updateTask.priority)}
                            />
                        </div>
                        <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '2%' }}>
                            <button className="btn btn-success" onClick={() => updateTaskSubmit()}>Update Task</button>
                        </div>
                    </div>
                </Modal>
                <Modal styles={modalStyles} open={openAutoAssignModal} onClose={() => onCloseAutoAssignModal()}>
                    <div style={{ textAlign: 'center', fontSize: '22px' }}>Auto assign tasks</div>
                    {
                        !autoAssignStatus.value ? <div className="row">
                            <div className="col-12 col-md-4" style={{ paddingTop: '2%' }}>
                                <Select
                                    isSearchable
                                    placeholder={"Select City"}
                                    name={"city"}
                                    value={cityList.map(val => { return ({ label: val, value: val }) }).find(val => val.value == autoAssignData.city)}
                                    options={cityList.map(val => { return ({ label: val, value: val }) })}
                                    onChange={(e) => handleSelectChangeAutoAssign(e, "city")}
                                />
                            </div>
                            <div className="col-12 col-md-4" style={{ width: '100%', paddingTop: '2%' }}>
                                <div className="form-group">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">Date</span>
                                        </div>
                                        <input className="form-control customInput" type="date" name={"date"} placeholder={"date"} value={autoAssignData.date} onChange={(e) => handleInputChangeAutoAssign(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4" style={{ paddingTop: '2%' }}>
                                <button className="btn btn-light btn-lg" onClick={() => autoAssignTaskSubmit()}>Assign</button>
                            </div>
                        </div> : <div style={{textAlign: 'center', paddingTop: '2%', fontSize: '18px'}}>{autoAssignStatus.text}</div>
                    }
                </Modal>
                <ReactTable
                    filterable={true}
                    showPagination={true}
                    sortable={true}
                    data={this.state.viewData}
                    ref={this.reactTable}
                    columns={[...this.state.showColumns,{
                        Header:'Edit',
                        Cell:row=>{
                            return <div><button className="btn btn-success" onClick={()=>editTask(row.original.taskId)} disabled={editButton}>Edit</button> <button className="btn btn-danger" onClick={()=>deleteTask(row.original.taskId)} disabled={editButton}>Delete</button></div>
                        }
                    }]}
                    defaultSorted={[{ id: "date", desc: true }]}
                    style={{ height: "90vh", textAlign: 'center' }}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={this.state.loading}
                />
            </div>
        );


    }
}

const selectCustom = (options, name, placeholder, handleMultiSelectChange, defaultValue) => {
    const changeHandle = (value) => { handleMultiSelectChange(value, name) }
    return (
        <div className="drop_down1">
            <Select
                isMulti
                isSearchable
                onChange={(e) => { changeHandle(e) }}
                placeholder={placeholder}
                name={name}
                options={options}
                defaultValue={defaultValue}
            />
        </div>
    )
}


function mapStateToProps(state) {
    let { tableData, quoteIdList, peMapping, employeeList, loading, fetchQuoteIdError = false, stateCityMap = {}, stateSubsidyMap = {}, cityDiscomMap = {}, autoAssignStatus = {} } = state.pedata || {};
    return {
        user: state.user,
        peData: tableData,
        quoteIdList,
        peMapping,
        employeeList,
        loading,
        fetchQuoteIdError,
        stateCityMap,
        stateSubsidyMap,
        cityDiscomMap,
        autoAssignStatus
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchPeTask, fetchQuoteId, fetchPeMapping, createNewTask, updateTask, deleteTask, searchSites, resetAutoAssign, autoAssignTasks }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PeTask);