import { SET_USERROLE_START, SET_USERROLE_SUCCESS } from './actiontypes';

import { base } from '../connection';

import { fetch, set, push, fetchOrderByChild, fetchOrderByKey, fetchOrderByValue } from '../helper/firebaseFunctions';

import { db } from '../connection.js';

export function setUserRole(destination, userid,role, team) {
	return (dispatch) => {
		dispatch({type : SET_USERROLE_START});
		if (destination == "WEB") {
			addUserRole("webDelivery/userrolemapping/" + userid,parseInt(role));
			dispatch({type : SET_USERROLE_SUCCESS, payload : "SUCCESS"});
		}
		else if (destination == "APP") {
			addUserRole("employeelist/" + userid + "/role",role.code);
			addUserRole("users/" + userid + "/mUser_Role",{mRole_Name : role.role, mRole_PermissionLevel : role.code});
			dispatch({type : SET_USERROLE_SUCCESS, payload : "SUCCESS"});
		}
	}
}

function  addUserRole(path,role) {
	db.ref(path).set(role)
		.then(() => console.log("Successfully Set ", role))
		.catch(err => err)
	return true;
}
