import { db } from '../connection';
import { OPENING_LOG_START,VERIFY_SUCCESS } from './actiontypes';

import { base } from '../connection';

export function reject(quoteid,reqid,userid,name,reason){
    return (dispatch) => {
        dispatch({type : OPENING_LOG_START});
        rejectq(quoteid, reqid,userid,name,reason)
        .then(() => {
        dispatch({type : VERIFY_SUCCESS, payload : "Success"});
        })
    }
}

export function verify(quoteid,reqid,userid,name,reason) {
    return (dispatch) => {
        dispatch({type : OPENING_LOG_START});
        veriq(quoteid, reqid,userid,name,reason)
        .then(() => {
        dispatch({type : VERIFY_SUCCESS, payload : "Success"});
        })
    }
}
async function veriq(quoteid ,reqid, userid,name,reason){
    let verifyid = `${new Date().getTime()}`;
    console.log("Function Working.")
    console.log(`delivery/webPortal/fileManagement/delivery/docs/${quoteid}/${name}`)
    base.post(`delivery/webPortal/fileManagement/delivery/docs/${quoteid}/${name}`,{
        data:{key:reqid,status:2},
        then(err){ 
            if(!err){
                base.post(`delivery/webPortal/fileManagement/delivery/viewableFiles/${quoteid}/${reqid}/Verifiedby`, {
                    data: {userid},
                    then(err){
                        if(!err){
                            base.post(`delivery/webPortal/fileManagement/delivery/viewableFiles/${quoteid}/${reqid}/Verifiedat`, {
                                data :{"epochtime":verifyid},then(err){ 
                                    if(!err) {
                                        base.post(`delivery/webPortal/fileManagement/delivery/viewableFiles/${quoteid}/${reqid}/Comments`, {
                                        data :{"comments":reason},then(err){ 
                                            if(!err) return "success"
                                        }
                                    })}
                                }
                            })
                        }
                    }
                })
            }
        }
    });
}
async function rejectq(quoteid ,reqid, userid,name,reason){
    let verifyid = `${new Date().getTime()}`;
    console.log("Function Working.")
    console.log(`delivery/webPortal/fileManagement/delivery/docs/${quoteid}/${name}`)
    base.post(`delivery/webPortal/fileManagement/delivery/docs/${quoteid}/${name}`,{
        data:null,
        then(err){ 
            if(!err){
                base.post(`delivery/webPortal/fileManagement/delivery/viewableFiles/${quoteid}/${reqid}/Verifiedby`, {
                    data: {userid},
                    then(err){
                        if(!err){
                            base.post(`delivery/webPortal/fileManagement/delivery/viewableFiles/${quoteid}/${reqid}/Verifiedat`, {
                                data :{"epochtime":verifyid},then(err){ 
                                    if(!err) {
                                        base.post(`delivery/webPortal/fileManagement/delivery/viewableFiles/${quoteid}/${reqid}/Comments`, {
                                        data :{"comments":reason},then(err){ 
                                            if(!err) {
                                                base.post(`delivery/webPortal/fileManagement/delivery/viewableFiles/${quoteid}/${reqid}/Rejected`, {
                                                data :{"rejected":1},then(err){ 
                                                    if(!err) return "success"
                                                }
                                            })}
                                        }
                                    })}
                                }
                            })
                        }
                    }
                })
            }
        }
    });
}
