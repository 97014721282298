import { FETCHING_STAGES_SUCCESS, FETCHING_STAGES_START, FETCHING_STAGES_PROGRESS } from '../actions/actiontypes'

const INIT_STAGE = {
	data: [],
	count:null,
}

export default function(state = INIT_STAGE, action) {
	switch(action.type){
		case FETCHING_STAGES_START:{
			return INIT_STAGE;
		}
		case FETCHING_STAGES_PROGRESS:{
			return {...state,count:action.payload};
		}
		case FETCHING_STAGES_SUCCESS:{
			return {...state, data:[...state.data,action.payload]};
		}
		default:
			return state;
	}
}