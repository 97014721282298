import { FETCHING_FILE_START, FETCHING_FILE_SUCCESS, FETCHING_FILE_ERROR } from '../actions/actiontypes'

import { columnsFilesView } from '../config/column_fields';

const INIT_STATE = {
	loading:true,
	columns:[],
	data:[],
	fetchError:null
}
	

export default function(state = INIT_STATE, action) {
	switch(action.type){
		case FETCHING_FILE_START:{
		return {...state,...INIT_STATE};
		};

		case FETCHING_FILE_SUCCESS:{
			return {...state,data:action.payload.filesData,loading:false,columns:action.payload.columns};
		}

		case FETCHING_FILE_ERROR:{
			return {...state,fetchError:action.payload};
		}
	}	
	
	return state;
}