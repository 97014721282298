import { FETCH_PAYMENT_HISTORY_SUCCESS, FETCH_PAYMENT_HISTORY_START } from '../actions/actiontypes';

export default function (state = [], action) {
  switch (action.type) {
    case FETCH_PAYMENT_HISTORY_START: {
      return [];
    }
    case FETCH_PAYMENT_HISTORY_SUCCESS: {
      return [...state, ...action.payload];
    }
    default:
      return state;
  }
}