import { FETCH_DPP_SUCCESS, FETCH_ELIGIBILITY_DPP_SUCCESS } from './actiontypes';
import axios from 'axios';

export function fetchDpp() {
    console.log("start");
    return (dispatch) => {
        fetchData().then(data => {
            dispatch({ type: FETCH_DPP_SUCCESS, payload: data })
        })
    }
}


async function fetchData() {
    try {
        let formdata = new URLSearchParams();
        formdata.append('key', 'o793xxmmAsidmj5');
        let data = await axios.post("https://operations.zunroof.com/api/fetchdppdata", formdata, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });
        console.log(data);
        return (data&&data.data&&data.data.data)?data.data.data:[];
    }
    catch (e) {
        console.log("error", e);
        return [];
    }
}

export function fetchEligibility() {
    console.log("start");
    return (dispatch) => {
        fetchEligibilityData().then(data => {
            dispatch({ type: FETCH_ELIGIBILITY_DPP_SUCCESS, payload: data })
        })
    }
}


async function fetchEligibilityData() {
    try {
        let formdata = new URLSearchParams();
        formdata.append('key', 'o793xxmmAsidmj5');
        let data = await axios.post("https://operations.zunroof.com/api/fetcheligibilitydata", formdata, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });
        console.log(data);
        return (data&&data.data&&data.data.data)?data.data:[];
    }
    catch (e) {
        console.log("error", e);
        return [];
    }
}
