import { FETCHING_CITY_MAP_SUCCESS,FETCHING_STATE_MAP_SUCCESS,FETCHING_EBISSUE_MAP_SUCCESS,FETCHING_FINANCING_MAP_SUCCESS,FETCHING_STAGE_MAP_SUCCESS,FETCHING_SUBSIDY_MAP_SUCCESS,FETCHING_NOTIFICATION_MAP_SUCCESS,FETCHING_EMPLOYEE_MAP_SUCCESS,FETCHING_TEAM_MAP_SUCCESS, FETCHING_EMPLOYEE_ID_MAP_SUCCESS, FETCHING_VENDOR_ID_MAP_SUCCESS, FETCHING_SUBSIDY_STATUS_MAP_SUCCESS, FETCHING_NET_METERING_MAP_SUCCESS ,FETCHING_STATE_CITY_MAP_SUCCESS, FETCHING_NEW_STAGE_MAPPING_SUCCESS} from '../actions/actiontypes'

const INTIAL_STATE  = {
	stageMap:null,
	stateMap: null,
	subsidyMap:null,
	ebissueMap:null,
	financingMap:null,
	notificationMap:null,
	employeeMap:null,
	teamMap:null,
	cityMap:null,
	employeeIdMap: null,
	vendorMap: null,
	subsidyStatusMap : null,
	netMeteringMap : null
}


export default function(state = INTIAL_STATE, action) {
	switch(action.type){
		case FETCHING_STAGE_MAP_SUCCESS:{
			return {...state, stageMap:action.payload};
		}
		case FETCHING_EBISSUE_MAP_SUCCESS:{
			return {...state, ebissueMap:action.payload};
		}
		case FETCHING_FINANCING_MAP_SUCCESS:{
			return {...state, financingMap:action.payload};
		}
		case FETCHING_STATE_MAP_SUCCESS:{
			return {...state, stateMap:action.payload};
		}
		case FETCHING_SUBSIDY_MAP_SUCCESS:{
			return {...state, subsidyMap:action.payload};
		}
		case FETCHING_NOTIFICATION_MAP_SUCCESS:{
			return {...state, notificationMap:action.payload};
		}
		case FETCHING_EMPLOYEE_MAP_SUCCESS:{
			return {...state, employeeMap:action.payload};
		}
		case FETCHING_TEAM_MAP_SUCCESS:{
			return {...state, teamMap:action.payload};
		}
		case FETCHING_EMPLOYEE_ID_MAP_SUCCESS:{
			return {...state, employeeIdMap:action.payload};
		}
		case FETCHING_CITY_MAP_SUCCESS:{
			return {...state, cityMap:action.payload};
		}
		case FETCHING_VENDOR_ID_MAP_SUCCESS:{
			return {...state, vendorMap:action.payload};
		}
		case FETCHING_SUBSIDY_STATUS_MAP_SUCCESS:{
			return {...state, subsidyStatusMap:action.payload};
		}
		case FETCHING_NET_METERING_MAP_SUCCESS:{
			return {...state, netMeteringMap:action.payload};
		}
		case FETCHING_STATE_CITY_MAP_SUCCESS : {
			return {...state, stateCityMap : action.payload}
		}
		case FETCHING_NEW_STAGE_MAPPING_SUCCESS: {
			return {...state, newStages : action.payload}
		}
	}

	return state;
}
