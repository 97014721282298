export const FirebaseConfig = {

    // apiKey: "AIzaSyBYWRQ2LSjaTwBvi984CFxsAGveKGCadWs",
    // authDomain: "zunroofdev2.firebaseapp.com",
    // databaseURL: "https://zunroofdev2.firebaseio.com",
    // projectId: "zunroofdev2",
    // storageBucket: "zunroofdev2.appspot.com",
    // messagingSenderId: "81614558469"

    apiKey: "AIzaSyAsOxeUSLzGqKa5YirGr4LpXpzgvwj0GqU",
    authDomain: "zunroofproductionapp.firebaseapp.com",
    databaseURL: "https://zunroofproductionapp.firebaseio.com",
    projectId: "zunroofproductionapp",
    storageBucket: "zunroofproductionapp.appspot.com",
    messagingSenderId: "502255916855"
}
