import React from 'react';
import Modal from 'react-responsive-modal';
import ReactTable from "react-table";
import Select from 'react-select';
import '../styles/modal.css';

export const IncentiveComponent = ({user, teamMapping,handleMultiSelectChange,cityMapping,handleInputChange,applyFilter,onModalOpen,loading,data,incentiveColumn, openUpdate, onCloseModal, onOpenModal, updateStatus, updated, cityFound, teamFound, quoteIdFound, teamMap, mainType, quoteIdOption, subTypeOptions, handleFormSubmit}) => {
	return (
		<div>
			<IncentiveModal
				user={user}
				openUpdate={openUpdate}
				onCloseModal={onCloseModal}
				updateStatus={updateStatus}
				updated={updated}
				mainType={mainType}
				cityFound={cityFound}
				teamMap={teamMap}
				teamFound={teamFound}
				quoteIdFound={quoteIdFound}
				subTypeOptions={subTypeOptions}
				quoteIdOption={quoteIdOption}
				handleFormSubmit={handleFormSubmit}
				handleInputChange={handleInputChange}
				handleMultiSelectChange={handleMultiSelectChange}
			/>
			<div className="row">
				<div className="col-md-2 col-6 filter_box" style={{"paddingTop":"10px"}}>
					<div className="drop_down1">
						<Select
							isMulti
							isSearchable
							placeholder="Choose Team"
							name="team"
							onChange={(e) => {handleMultiSelectChange(e,"teamSelected")}}
							options={teamMapping}
						/>
					</div>
				</div>
				<div className="col-md-2 col-6 filter_box" style={{"paddingTop":"10px"}}>
					<div className="drop_down1">
						<Select
							isMulti
							isSearchable
							placeholder="Choose City"
							name="city"
							onChange={(e) => {handleMultiSelectChange(e,"citySelected")}}
							options={cityMapping}
						/>
					</div>
				</div>
				<div className="col-2" style={{padding:'0px 15px'}}>
					Start Date:
					<input
					  style={{width:203,borderRadius:5,padding:'8px 8px'}}
					  type='date'
					  name='startDate'
					  onChange={handleInputChange}
					/>
				</div>
				<div className="col-2" style={{padding:'0px 10px'}}>
					End Date:
					<input
					  style={{width:203,borderRadius:5,padding:'8px 8px'}}
					  type='date'
					  name='endDate'
					  onChange={handleInputChange}
					/>
				</div>
				<div className="col-md-4 col-6 filter_box filter_box_button" style={{"paddingTop": 10,"display": "flex","flexDirection": "row-reverse"}}>
					<div style={{"marginTop": "12px"}}>
						<button className="btn btn-success applyButton" onClick={applyFilter}>Apply Filter</button>
					</div>
					{
						user.level>=1900?(<div>
									<i className="fas fa-plus-circle addIncentive" onClick={() => {onOpenModal('openUpdate')}}></i>
								</div>):""
					}
				</div>
			</div>
			<ReactTable
				loading				= {loading}
				data				= {data}
			  	columns				= {incentiveColumn}
			  	filterable			= {true}
				sortable			= {true}
				defaultSorted		= {[{ id: "date", desc: true }]}
				defaultFilterMethod	= {(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase())>-1}
			/>
		</div>
	)
}

const IncentiveModal = ({openUpdate, onCloseModal, updateStatus, updated, mainType, subTypeOptions, cityFound, teamMap, teamFound, quoteIdFound, quoteIdOption, handleFormSubmit, handleInputChange, handleMultiSelectChange}) => {
	return (
		<Modal open={openUpdate} onClose={() => {onCloseModal('openUpdate')}} center>
			<div className="form-group">
				<div className="container">
					{updateStatus&&updateStatus.status?
						<div className={updateStatus.status} role="alert">
					  		{updateStatus.text}
						</div>:""}
				</div>
				<div className="container" style={{"paddingTop": "15px"}}>
			   		<form onSubmit = {handleFormSubmit}>
			   		  <div style={{"textAlign": "center"}}><h3>Quote Id: {quoteIdFound}</h3></div>
					  <div className="form-group row">
					    <label htmlFor="mainType" className="col-sm-2 col-form-label">Quote Id:</label>
					    <div className="col-sm-10">
					      <Select
								isSearchable
								placeholder="Choose Quote Id"
								name="quoteIdSelected"
								onChange={(e) => {handleMultiSelectChange(e,"quoteIdSelected")}}
								options={quoteIdOption}
							/>
					    </div>
					  </div>
					  <div className="form-group row">
					    <label htmlFor="mainType" className="col-sm-2 col-form-label">City :</label>
					    <div className="col-sm-10">
					      <input type="text" className="form-control" placeholder={cityFound} readOnly />
					    </div>
					  </div>
					  <div className="form-group row">
					    <label htmlFor="mainType" className="col-sm-2 col-form-label">Team :</label>
					    <div className="col-sm-10">
					      <input type="text" className="form-control" placeholder={teamFound?teamMap[teamFound]:teamFound} readOnly />
					    </div>
					  </div>
					  <div className="form-group row">
					    <label htmlFor="mainType" className="col-sm-2 col-form-label">Date :</label>
					    <div className="col-sm-10">
					      <input type="date" className="form-control" name="dateUpdate" onChange={handleInputChange} />
					    </div>
					  </div>
					  <div className="form-group row">
					    <label htmlFor="mainType" className="col-sm-2 col-form-label">Type :</label>
					    <div className="col-sm-10">
					      <select className="form-control" name="mainType" onChange={handleInputChange}>
					      		<option value={-2}>Choose Type</option>
								<option>Adjustment</option>
								<option>Quality</option>
								<option>Payments</option>
								<option>Net Metering</option>
							</select>
					    </div>
					  </div>
					  <div className="form-group row">
					    <label htmlFor="subType" className="col-sm-2 col-form-label">Sub-Type : </label>
					    <div className="col-sm-10">
					      	<select className="form-control" name="subType" onChange={handleInputChange}>
					      		<option value={-2}>Choose Sub-Type</option>
								{mainType?subTypeOptions[""+mainType].map(val => <option key={val}>{val}</option>):""}
							</select>
					    </div>
					  </div>
					  <div className="form-group row">
					    <label htmlFor="mainValue" className="col-sm-2 col-form-label">Value : </label>
					    <div className="col-sm-10">
						<input type="number" className="form-control" name="mainValue" step="0.001" onChange={handleInputChange} />
					    </div>
					  </div>
					  <div style={{"textAlign": "center"}}><button type="submit" className="btn btn-primary">Submit</button></div>
					</form>
			   	</div>
			</div>
    	</Modal>
	)
}
