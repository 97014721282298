import React, { Component } from 'react';

export class Restrict extends Component {
	render() {
		return (
			<div className = "restrict" style={{marginTop:100}}>
				<div className="alert alert-danger" role="alert">
					You don't have access to view this. Please Contact Admin.
				</div>
			</div>
		);
	}
}
