import { ADDING_COMMENT_STATUS } from './actiontypes';

import { base } from '../connection';
import { set } from '../helper/firebaseFunctions';

import { createDateObj } from '../helper/dateObj';
import { getCommentProcessRequest } from '../helper/addComments';

export function updateDVariable(siteId,type,value) {
	return (dispatch) => {
        set(`delivery/webPortal/sites/${siteId}/${type}`,value)
        .then(() => {console.log("DONE");}).catch(error => {console.log(error)});
	}
}

// elevationDetails
// deliveredPanel
// deliveredInverter
// phase_D
// sanctioned_Load_D
// state_D
// subsidy_D
// subsidyStatus
// appliedDate
// promisedPanel
// promisedInverter
// address_D
// latlon_D
// connectionHolderName
// invoiceText
// invoiceDate
// size_D
// discom_D
// specialRequirement_D
// idMovedBy_D
// maintenanceRequired