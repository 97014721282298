import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import Select from 'react-select';
import { changeToViewDateTime, changeToInputDate, changeToViewDate } from '../helper/dateChanger';
import { Restrict } from '../components/Restrict';
import { ticketingReportTable, resolvedTickets, complaintsTracker } from '../config/column_fields';
import { fetchTicketReport, fetchTicketHistory } from '../actions/fetchTicketingReport';
import moment from 'moment';

class TicketingReport extends Component {


	constructor(props) {
		super(props);
		this.months = [
			{ label: 'Select', value: -1 },
			{ label: 'Jan', value: 1 },
			{ label: 'Feb', value: 2 },
			{ label: 'Mar', value: 3 },
			{ label: 'Apr', value: 4 },
			{ label: 'May', value: 5 },
			{ label: 'Jun', value: 6 },
			{ label: 'Jul', value: 7 },
			{ label: 'Aug', value: 8 },
			{ label: 'Sep', value: 9 },
			{ label: 'Oct', value: 10 },
			{ label: 'Nov', value: 11 },
			{ label: 'Dec', value: 12 }
		];
		this.yearOptions = [{ label: 'Select', value: -1 }];
		let currentYear = new Date().getFullYear();
		for (let i = currentYear; i >= 2017; i--) {
			this.yearOptions.push({ label: i, value: i });
		}
		this.state = {
			viewData1: [],
			viewData2: [],
			viewData3: [],
			ticketingReportTable: ticketingReportTable,
			resolvedTickets:resolvedTickets,
			complaintsTracker: complaintsTracker,
			loading: true,
			loadingOutstanding:true,
			year: '',
			month: '',
			ticketsData: {},
			versions:[],
			version:''
		};
	}

	componentDidMount() {
		if (this.props.user) {
			if (this.props.user.level >= 1700) {
				this.props.fetchTicketReport();
			}
		}
	}


	componentWillReceiveProps(props) {
		console.log(props);
		if (props.reportData) {
			console.log("props", props);
			let { ticketsData, outstandingTickets, resolvedTickets, complaintsData, versions } = { ...props.reportData };
			console.log(props.history);
			let historyData = [ ...props.history];
			console.log(historyData.length);
			this.setState({
				viewData1: historyData.length?historyData:outstandingTickets,
				viewData2: resolvedTickets,
				viewData3: complaintsData,
				ticketsData: ticketsData,
				loadingOutstanding:false,
				loading: false,
				versions: versions
			}, () => {
				this.applyFilter();
			})
		}
	}

	onFilterChange = (name, e) => {
		console.log(e);
		this.setState({
			[name]: e.value
		})
	}

	applyFilter = (e) => {
		let year = this.state.year || -1;
		let month = this.state.month || -1;
		console.log(year, month);
		let ticketsData = { ...this.state.ticketsData };
		let resolvedTickets = {
            "Complaints":{
                rowName:"Complaints",
                d0:0,
                d1:0,
                d2:0,
                d3:0
            },
            "Non-Complaints":{
                rowName:"Non-Complaints(Total)",
                d0:0,
                d1:0,
                d2:0,
                d3:0
            }
        };
        let resolvedTicketsByType = {};
		Object.values(ticketsData).map(ticketData => {
			let createTime = new Date(ticketData.epoch);
			if ((year == -1 || year == createTime.getFullYear()) && (month == -1 || (month == createTime.getMonth() + 1))) {
				if (ticketData.status == 'Close') {
					if (ticketData.closeTime) {
						let rowType = ticketData.isComplaint ? 'Complaints' : 'Non-Complaints';
						let duration;
						if((ticketData.closeTime - ticketData.epoch) < 86400000){
							duration = 'd0';
						}
						else if ((ticketData.closeTime - ticketData.epoch) < 259200000) {
							duration = 'd1';
						}
						else if ((ticketData.closeTime - ticketData.epoch) >= 259200000 && (ticketData.closeTime - ticketData.epoch) <= 604800000) {
							duration = 'd2';
						}
						else {
							duration = 'd3';
						}
						if(!resolvedTickets[rowType]){
							resolvedTickets[rowType] = {
								rowName: rowType,
								d0:0,
								d1:0,
								d2:0,
								d3:0
							}
						}
						resolvedTickets[rowType][duration] = resolvedTickets[rowType][duration] + 1;
						if(!ticketData.isComplaint){
							if(!resolvedTicketsByType[ticketData.type]){
								resolvedTicketsByType[ticketData.type] = {
									rowName:ticketData.type,
									d0:0,
									d1:0,
									d2:0,
									d3:0
								}
							}
							resolvedTicketsByType[ticketData.type][duration] += 1;
						}
					}
				}
			}
		})
		this.setState({
			viewData2: [resolvedTickets['Complaints'], resolvedTickets['Non-Complaints'], ...Object.values(resolvedTicketsByType)]
		})
	}

	fetchOutstandingTicketsHistory = (e) => {
		console.log(this.state.version);
		this.props.fetchTicketHistory(this.state.version);
		this.setState({
			loadingOutstanding: true
		})
	}

	render() {
		console.log(this.state);
		const style = {
			hide: {
				display: 'none'
			},
			show: {
				display: ''
			},
			iconShow: {
				float: 'right',
				display: ''
			},
			iconHide: {
				float: 'right',
				display: 'none'
			}
		};
		if (this.props.user === null) {
			return <Redirect to={"/login?redirect_uri=ticketReport"} />;
		}

		if (this.props.user && this.props.user.level < 1700) {
			return (
				<div>
					<Restrict />
				</div>
			)
		}

		return (
			<div className="container-fluid" style={{ marginTop: "50px" }}>
				<div className="card shadow">
					<div className="card-body">
						<div className="card-title">
							<h5>Outstanding Tickets</h5>
							<div className="row">
								<div className="col-md-2 col-6">
									<Select
										isSearchable
										onChange={(e) => this.onFilterChange('version', e)}
										placeholder={"Choose Version"}
										name={"version"}
										options={[{label:'Current Version', value:''}, ...this.state.versions.map((id) => ({label:moment(parseInt(id)).format('DD MMM YYYY'), value:id}))]}
										defaultValue={{label:'Current Version', value:''}}
									/>
								</div>
								<div className="col-md-2 col-6">
						<button onClick={(e) => this.fetchOutstandingTicketsHistory(e)} className="btn btn-success">Apply Filter</button>
					</div>
							</div>
						</div>
						<ReactTable
							filterable={true}
							sortable={true}
							showPagination={false}
							defaultPageSize={3}
							data={this.state.viewData1}
							columns={this.state.ticketingReportTable}
							defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
							loading={this.state.loadingOutstanding}
							style={{ textAlign: 'center' }}
						/>
					</div>

				</div>
				{/* <h3 style={{ marginTop: "30px" }}>Outstanding Tickets</h3> */}
				<div className="card shadow" style={{marginTop:'30px'}}>
					<div className="card-body">
						<div className="card-title">
							<h5>Resolved Tickets</h5>
							Resolve Date is available after 1st March, 2020.
							<div className="row">
								<div className="col-md-2 col-6">
									<Select
										isSearchable
										onChange={(e) => this.onFilterChange('year', e)}
										placeholder={"Select Year"}
										name={"year"}
										options={this.yearOptions}
										defaultValue={""}
									/>
								</div>
								<div className="col-md-2 col-6">
									<Select
										isSearchable
										onChange={(e) => this.onFilterChange('month', e)}
										placeholder={"Select Month"}
										name={"month"}
										options={this.months}
										defaultValue={""}
									/>
								</div>
								<div className="col-md-2 col-6">
						<button onClick={(e) => this.applyFilter(e)} className="btn btn-success">Apply Filter</button>
					</div>
							</div>
						</div>
						<ReactTable
					filterable={true}
					showPagination={false}
					sortable={true}
					defaultPageSize={10}
					data={this.state.viewData2}
					columns={this.state.resolvedTickets}
					defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
					loading={this.state.loading}
					style={{ textAlign: 'center' }}
					getTrProps={(state, rowInfo, column, instance) => {
						if (rowInfo && (rowInfo.original.rowName=='Complaints'||rowInfo.original.rowName=='Non-Complaints(Total)')) {
							return {
								style: {
									backgroundColor: '#DCDCDC'
								}
							}
						}
						else {
							return {
								style: {
									backgroundColor: '#FFFFFF'
								}
							}
						}
					}
					}
				/>
					</div>

				</div>
				<div className="card shadow" style={{marginTop:'30px'}}>
					<div className="card-body">
						<div className="card-title">
							<h5>Complaints Tracker</h5>
						</div>
						<ReactTable
					filterable={true}
					showPagination={false}
					sortable={true}
					defaultPageSize={8}
					data={this.state.viewData3}
					columns={this.state.complaintsTracker}
					defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
					loading={this.state.loading}
					style={{ textAlign: 'center' }}
				/>
					</div>

				</div>
			</div>
		);


	}
}



function mapStateToProps(state) {
	console.log(state);
	return {
		user: state.user,
		reportData: state.ticketreport ? state.ticketreport.data : {},
		history: state.ticketreport && state.ticketreport.history ? state.ticketreport.history: []
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ fetchTicketReport, fetchTicketHistory }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketingReport);