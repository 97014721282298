import { ADD_DELIVERY_TEAM_START,
  ADD_DELIVERY_TEAM_SUCCESS} from '../actions/actiontypes'

const INIT_STATE = {
	data : {}
}
export default function(state = INIT_STATE, action) {
	switch(action.type){
		case ADD_DELIVERY_TEAM_START:{
			return state;
		}
		case ADD_DELIVERY_TEAM_SUCCESS:{
			return {...state, data : action.payload };
		}
		default: {
			return state;
		}
	}
}
