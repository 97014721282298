import { FETCHING_EMAIL_ACCOUNTS_START, FETCHING_EMAIL_ACCOUNTS_SUCCESS, FETCHING_EMAIL_ANALYTICS_START, FETCHING_EMAIL_ANALYTICS_DATE, FETCHING_CLIENT_CALL_ANALYTICS_DATE, FETCHING_EMAIL_ANALYTICS_SUCCESS, FETCHING_EMAIL_STATUS_SUCCESS, FETCHING_CLIENT_CALL_ANALYTICS_SUCCESS } from './actiontypes';
import { dotToEmail, emailToDot } from '../helper/helper';
import { fetch, fetchOrderByChild } from '../helper/firebaseFunctions';
import { base, db } from '../connection';

let total = 0;
let teamMapping = {};
let foundEmail = {};
let foundEmail2 = {};

export function fetchEmailsAnalytics() {
	console.log(new Date());
    console.log(new Date());
    return (dispatch) => {        
	  dispatch({type:FETCHING_EMAIL_ACCOUNTS_START});
	  fetchStatus(dispatch);
      db.ref('/delivery/webPortal/email/account/timeData').once('value')
      .then(snapshot => {
		  //console.log(Object.values(snapshot.val()));
		  let totalData = [];
          console.log('Fetch Email Accounts');
          let result = snapshot.val();
          for(let k in result) {
			//console.log(k, result[k]);
			if(!foundEmail[""+k]){
			foundEmail[""+k] = 1;
			// result[k].key = k;
			// result[k].status = result[k].status?`${k}___${result[k].status}`:`${k}___${1}`;
			let emailData = {
				cc: result[k].cc,
				date: result[k].date,
				fromName: emailToDot(result[k].fromName),
				mailBody: result[k].mailBody,
				mailText: result[k].mailText,
				subject: result[k].subject,
				to: emailToDot(result[k].to),
				status: result[k].status?`${k}___${result[k].status}`:`${k}___${1}`,
				key:k
			};
			totalData.push(emailData);
		}
        }
            //console.log(Object.values(result))
          dispatch({type:FETCHING_EMAIL_ACCOUNTS_SUCCESS,payload:totalData})
		  fetchLastFetchDateEmail(dispatch);
		  //fetchEmailsList(dispatch);
      })
      .catch(e => {
          return e;
      })
   }
}



export function fetchEmailsAccounts(dispatch) {
	console.log(new Date());
	console.log(new Date());
	return(dispatch)  => {
	dispatch({type:FETCHING_EMAIL_ACCOUNTS_START});
	fetchStatus(dispatch);
	  let emailAccountsUrl = `/delivery/webPortal/email/account/emailIdList`;
	  db.ref(emailAccountsUrl).once('value')
	  .then(snapshot =>{
		let emailData = snapshot.val();
		let emailAllData = [];
		Object.keys(emailData).forEach(emailAddress => {
			let lastKey =  Object.keys(emailData[emailAddress]).sort().pop();
			console.log(lastKey);
			let emailPath = `/delivery/webPortal/email/account/timeData/${lastKey}`;
			db.ref(emailPath).once('value')
			.then(email =>{
				let emailData = email.val();
				if(emailData){
				if(emailAddress == emailData.fromName && !foundEmail2[""+lastKey] && emailData.to == 'accounts@zunroof__dot__com' &&emailData.status!=4){
					foundEmail2[""+lastKey] = 1;
					let dataEmail = {
						cc: emailData.cc,
						date: emailData.date,
						fromName: emailToDot(emailData.fromName),
						mailBody: emailData.mailBody,
						mailText: emailData.mailText,
						subject: emailData.subject,
						to: emailToDot(emailData.to),
						status: emailData.status?`${lastKey}___${emailData.status}`:`${lastKey}___${1}`,
						key:lastKey
					};
					emailAllData.push(dataEmail);
				} 
			}
			})
		})
		console.log(emailAllData);
	  console.log("EMAIL FETCHING DONE");
	  dispatch({type:FETCHING_EMAIL_ACCOUNTS_SUCCESS,payload:emailAllData})
	  })
	  .catch(e => {
		return e;
	})
	}
}

// function fetchEmailDataId(key){
// 	let lastKey = `/delivery/webPortal/email/account/emailIdList/${key}`;
// 	db.ref(lastKey).limitToLast(1).once('value')
// 	.then(result =>{
// 		let value = Object.keys(result.val())[0];
// 		//console.log(value);
// 		fetchAllData(key, value);
// 	})
// }

// function fetchAllData(key, value){
// 	let emailPath = `/delivery/webPortal/email/account/timeData/${value}`;
// 	db.ref(emailPath).once('value')
// 	.then(email =>{
// 		let emailData = email.val();
// 		if(key == emailData.fromName && !foundEmail2[""+value]){
// 			foundEmail2[""+value] = 1;
// 			let dataEmail = {
// 				cc: emailData.cc,
// 				date: emailData.date,
// 				fromName: emailToDot(emailData.fromName),
// 				mailBody: emailData.mailBody,
// 				mailText: emailData.mailText,
// 				subject: emailData.subject,
// 				to: emailToDot(emailData.to),
// 				status: emailData.status?`${value}___${emailData.status}`:`${value}___${1}`,
// 				key:value
// 			};
// 			emailAllData.push(dataEmail);
// 		} 
// 	})
// }


function fetchLastFetchDateEmail(dispatch) {
	return new Promise((resolve,reject) => {
		db.ref('delivery/webPortal/email/account/timeData')
		.orderByChild('date')
		.limitToLast(1)
		.once("value")
		.then(snapshot => {
			console.log(snapshot.val(),Object.values(snapshot.val())[0].date);
			resolve({type:FETCHING_EMAIL_ANALYTICS_DATE, payload:{Email: Object.values(snapshot.val())[0].date}})
		}).catch(err => {reject(err)})
	})
}

function fetchStatus(dispatch) {
	let statusFetchURL = `delivery/webPortal/email/statusFlag`;
	base.fetch(statusFetchURL,{})
	.then(dataGet => {
		dispatch({type:FETCHING_EMAIL_STATUS_SUCCESS, payload:dataGet});
		//fecthSiteIds(dispatch,dataGet);
	})
	.catch(error => {
		console.log(error);
	})
}


export function fetchLastFetchTime() {
	console.log(new Date());
  	return (dispatch) => {
    	Promise.all([fetchLastFetchDateEmail(dispatch),fetchLastFetchDateCall(dispatch)])
    	.then(values => {
    		dispatch(values[0]);
    	})//fetchClientData(dispatch);
 	}
}

function fetchLastFetchDateCall(dispatch) {
	return new Promise((resolve,reject) => {
		db.ref('utilities/webConfig/clientManagementLastFetch')
		.once("value")
		.then(snapshot => {
			resolve({type:FETCHING_CLIENT_CALL_ANALYTICS_DATE, payload:snapshot.val()})
		}).catch(err => {reject(err)})
	})
}


function dispatcher(dispatch,callRecordDataTable,count,total) {
	count.status = count.status+1;
	console.log(count.status,total)
	if(count.status===total) {
		dispatch({type:FETCHING_CLIENT_CALL_ANALYTICS_SUCCESS,payload:[...[],...Object.values(callRecordDataTable).filter(val => val)]});
	}
}



function destructuring(values) {
	console.log(values);
	let data1=values[0]?Object.values(values[0]):[];
	let data2=values[1]?Object.values(values[1]):[];
	let data3=values[2]?Object.values(values[2]):[];
	let data = [...data1,...data2,...data3].map(val => {
		return {
			date: dateToEpoch(val.date),
			quoteId: values[3].quoteId,
			name: values[3].name,
			mobile: val.mobileNumber.substring(val.mobileNumber.length-10,val.mobileNumber.length),
			on: val.phoneNumber,
			duration: val.duration,
			type: val.type
		}
	});
	return data;
}


function dateToEpoch(value) {
	return new Date(`${value.substring(3,5)}-${value.substring(0,2)}-${value.substring(6,8)} ${value.split(" ")[1]}`).getTime();
}