import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import TextField from '@material-ui/core/TextField';
import { fetchEmailsAnalytics, fetchLastFetchTime, fetchEmailsAccounts } from '../actions/fetchEmailsAccounts';
import { setemailstatusaccounts } from '../actions/setemailstatusaccounts';

import { changeToViewDateTime } from '../helper/dateChanger';
import { Restrict } from '../components/Restrict';
import { columnsEmailAccounts } from '../config/column_fields';

class EmailAccounts extends Component {

	constructor(props) {
	  	super(props);
	
	  	this.state = {
	  		data:[],
	  		columns:[],
	  		phoneData: [],
			phonecolumns: [],
			showColumns: columnsEmailAccounts,
			showData: [],
	  		startDate: "",
			endDate: "",
			statusChoose:-1,
			choice:0,
			edit:{},
			loading: true,
			lastDate:"Fetching...",
			clicked:false
		}
	}

	componentDidMount() {
		// console.log("DID MOUNT")
		if(this.props.user){
			if(this.props.user.level==1800 || this.props.user.level>2500){
				this.props.fetchEmailsAccounts();
				this.props.fetchLastFetchTime();
			}
		}
	}

	componentWillReceiveProps({ emailaccountData }) {
		if(emailaccountData && emailaccountData.statusMap && !this.state.clicked) {
			let statusMap = emailaccountData.statusMap;
			console.log(statusMap);
			let emailColumn = [...[],...emailaccountData.columns];
			console.log("1");
			emailColumn.splice(5, 0, {
				Header: 'Status',
				accessor: 'status',
				Cell: row => (
			        <div>
			        	{!this.state.edit[row.value.split("___")[0]]?<div onClick={() => {this.handleEditClick(row.value.split("___")[0])}}>{statusMap[row.value.split("___")[1]]}</div>:
			        	<select className="custom-select" value={row.value.split("___")[1]} name={row.value.split("___")[0]} onChange={this.handleInputStatusChange}>
			        		{statusMap.map((val,index) => <option key={index} value={index}>{val}</option>)}
			        	</select>}
			        </div>
			      ),
				style: { 'whiteSpace': 'unset'}
			})
			this.setState({
				data:[...emailaccountData.data],
				columns: [...emailColumn],
				showColumns: [...emailColumn],
				showData: [...emailaccountData.data],
			})
		}
		if(Object.keys(emailaccountData.lastDate).length > 0) {
			console.log("Received");
			let lastDate = "";
			Object.keys(emailaccountData.lastDate).forEach(val => {
				lastDate += `${val}: ${changeToViewDateTime(emailaccountData.lastDate[val])}\n`
			});
			this.setState({
				lastDate,
				loading: emailaccountData.loading?emailaccountData.loading:false
			})
		}
		// console.log(emailaccountData)
		if(emailaccountData.clientData) {
			if(this.state.clicked){
				this.setState({
					showData:[...emailaccountData.clientData],
					showColumns: [...emailaccountData.clientColumn]
				})
			}
			this.setState({
				phoneData:[...emailaccountData.clientData],
				phonecolumns: [...emailaccountData.clientColumn]
			})
		}
	}

	handleEditClick = (id) => {
		let edit = this.state.edit;
		edit[""+id] = true;
		this.setState({
			edit: {...{},...edit}
		})
	}

	handleInputChange = (e) =>{
		console.log(e);
		console.log(e.target.name);
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	handleInputStatusChange = (e) =>{
		console.log(e.target.name,e.target.value);
		let emailData = this.state.data;
		let newData   = [];
		emailData.forEach(val => {
			if(val.key===e.target.name) {
				val.status = `${e.target.name}___${e.target.value}`;
				console.log(val);
				newData.push(val);
			}else{
				newData.push(val);
			}
		});
		this.props.setemailstatusaccounts(e.target.name,e.target.value);
		console.log(newData);
		let edit = this.state.edit;
		edit[""+e.target.name] = false;
		this.setState({
			data:[...[],...newData],
			showData:[...[],...newData],
			edit: {...{},...edit}
		})
	}

	applyFilter = () => {
		let filteredDataCurrent = [];
		let type = null;
		let { phoneNumber, quoteId } = this.state;
		filteredDataCurrent = this.props.emailaccountData.data;
		type = "data";
		let startDate 	= this.state.startDate?new Date(this.state.startDate).getTime():0;
		let endDate 	= this.state.endDate?new Date(this.state.endDate).getTime():9999999999999;
		startDate  = startDate - 19800000;
		endDate = endDate - 19800000;
		if(startDate != endDate){
		console.log(startDate);
		console.log(endDate);
		let statusChoose= ((this.state.statusChoose&&this.state.statusChoose!==-1)||this.state.statusChoose===0)?this.state.statusChoose:-1;
		console.log(statusChoose,this.state.statusChoose);
		// let filteredDate = filteredDataCurrent.filter(val => {return val.date>=startDate && val.date<=endDate });
		// this.setState({[type]:[...[],...filteredDate],showData:[...[],...filteredDate]});
		let filteredDate = filteredDataCurrent.filter(val => {console.log(val.status.split("___")[1]===statusChoose,statusChoose,statusChoose!==0); return val.date>=startDate && val.date<=endDate && (val.status.split("___")[1]===statusChoose||statusChoose==-1)});
		this.setState({[type]:[...[],...filteredDate],showData:[...[],...filteredDate]});
		}
		else{
			alert('Please select different dates');
		}
	}

	viewToggle = (value) => {
		if(!this.state.loading){
			this.setState({clicked:true})
		}
		if(value==="Email") {
			this.setState({
				choice:0,
				showData: [...this.state.data],
				showColumns: [...this.state.columns]
			})
		}else{
			let filteredDataCurrent = this.props.emailaccountData.clientData;
			let startDate 			= this.state.startDate?new Date(this.state.startDate).getTime():0;
			let endDate 			= this.state.endDate?new Date(this.state.endDate).getTime():9999999999999;
			let filteredDate 		= filteredDataCurrent.filter(val => val.date>startDate && val.date<endDate);
			this.setState({
				choice:1,
				phoneData: [...filteredDate],
				showData: [...filteredDate],
				showColumns: [...this.state.phonecolumns]
			})
		}
	}

	render() {

		const style = {
			hide : {
				display:'none'
			},
			show : {
				display:''
			}
		}

		if(this.props.user===null){
			return <Redirect to={"/login?redirect_uri=emailaccounts"} />;
		}

		if(this.props.user && this.props.user.level<=2500) {
			if(this.props.user.level!=1800){
				return ( 
					<div>
						<Restrict />
					</div>
				)
			}
		}

		if(this.props.emailaccountData) {
			return (
				<div style={{"marginTop":50}} className="container-fluid">
					<div className="row" style={{padding:'10px 15px'}}>
						<div className="col-2" style={{padding:'0px 15px'}}>
							Start Date:
							<input
							  style={{width:203,borderRadius:5,padding:'8px 8px'}}
							  type='date'
							  name='startDate'	
							  onChange={this.handleInputChange}
							  value={this.state.startDate}
							/>
						</div>
						<div className="col-2" style={{padding:'0px 10px'}}>
							End Date:
							<input
							  style={{width:203,borderRadius:5,padding:'8px 8px'}}
							  type='date'
							  name='endDate'	
							  onChange={this.handleInputChange}
							  value={this.state.endDate}
							/>
						</div>
						<div className="col-2" style={this.state.choice==0?{padding:'0px 10px'}:{display:'none'}}>
							Status:
							<select className="custom-select" style={{'height': '37.5px'}} name="statusChoose" onChange={this.handleInputChange} >
								<option value="-1">Not Selected</option>
			        			{this.props.emailaccountData.statusMap.map((val,index) => <option key={index} value={index}>{val}</option>)}
			        		</select>
						</div>
						<div className="col-1" style={this.state.choice==1?{padding:'0px 10px'}:{display:'none'}}>
							<TextField
								label="Phone Number"
								type="search"
								margin="normal"
								name="phoneNumber"
								onChange={this.handleInputChange}
					        />
						</div>
						<div className="col-1" style={this.state.choice==1?{padding:'0px 10px'}:{display:'none'}}>
							<TextField
								label="Quote Id"
								type="search"
								margin="normal"
								name="quoteId"
								onChange={this.handleInputChange}
					        />
						</div>
						<div className="col-md-2 filter_box filter_box_button"  style={{"paddingTop":"10px"}}>
							<div className="filter_selector__drop_down_label1" style={{marginBottom:'6%'}}></div>
							<button className="btn btn-success applyButton" onClick={this.applyFilter}>Apply Filter</button>
						</div>
						<div className={this.state.choice==0?"col-4":"col-4"}>
							<div style={{display:"flex",justifyContent: "flex-end",paddingTop: 18}}>
								{/* <button className="btn btn-primary applyButton" style= {{margin:4}} onClick={() => {this.viewToggle("Email")}}>Email</button>
								<button className="btn btn-primary applyButton" style= {{margin:4}} onClick={() => {this.viewToggle("Client")}}>Call</button> */}
								<i className="fas fa-question-circle" style={{
								    padding: '9px 0 0 0',
								    fontSize: 20,
								    color: '#49b8bd'
								}}
								data-toggle="tooltip" data-placement="left" title={this.state.lastDate}
								></i>
							</div>
						</div>
					</div>
					<ReactTable
						loading={this.state.loading}
						filterable={true}
						showPagination={true}
						sortable={true}
						data={this.state.showData}
						columns={this.state.showColumns}
						defaultSorted={[{ id: "date", desc: true }]}
						style={{height: "90vh",textAlign:'center'}}
						defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase())>-1}/>
				</div>
			);
		}

		return (
			<div style={{"marginTop":100}}>
				Loading...
			</div>
		);
	}
}

function mapStateToProps({ user, emailaccountData }) {
	return {
		user : user,
		emailaccountData: emailaccountData
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ fetchEmailsAccounts, fetchLastFetchTime, setemailstatusaccounts  }, dispatch);
};

export default connect(mapStateToProps,mapDispatchToProps)(EmailAccounts);