import { CREATE_PAYMENT_START, CREATE_PAYMENT_SUCCESS, CREATE_PAYMENT_ERROR } from '../actions/actiontypes';

export default function (state = {}, action) {
  switch (action.type) {
    case CREATE_PAYMENT_START: {
      return { ...state, data: action.payload };
    }
    case CREATE_PAYMENT_SUCCESS: {
      return { ...state, data: action.payload };
    }
    default:
      return state;
  }
}