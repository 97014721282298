import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { changeToViewDateTime, changeToInputDate, changeToViewDate } from '../helper/dateChanger';
import { Restrict } from '../components/Restrict';
import Modal from 'react-responsive-modal';
import ReactTable from "react-table";
import { fetchTicketId } from '../actions/fetchTicketId';
import { sendFeedbackMsg } from '../actions/sendFeedbackMsg';
import { updateTicket } from '../actions/updateTicketId';
import { updateComment } from '../actions/updateComment';
import { reOpenTicket } from '../actions/updateTicketId';
import { relatedTicketsColumn } from '../config/column_fields';
import moment from 'moment';

class Tickets extends Component {

    constructor(props) {
        super(props);
        // this._isMounted = false;
        this.reactTable = React.createRef();
        this.state = {
            ticketData: {},
            relatedTicketsColumns: relatedTicketsColumn,
            editForm: {
                responsibleTeam: '',
                assignedTo: '',
                priority: 'Normal',
                dueDate: '',
                responsibleOptions: [],
                status: '',
                text: '',
                removed: '',
                issueType: '',
                source: '',
                deliveryStatus: ''
            },
            teamEmployeeMap: {},
            stateEmployeeMap: {},
            employeeList: {},
            responsibleOptions: [],
            newComment: '',
            comments: [],
            relatedTickets: {},
            initState: true,
            edit: false,
            reOpenState: false,
            openCommentModal: false,
            dueDateChangeComment: ''
        };

        this.statusTextMap = {
            "Open": "Thank for reaching out to us. Our customer support team has initiated the process of resolving your order issue.",
            "In Progress": "Your complaint has moved to 'In-progress' Stage. We'll soon share an update on the same.",
            "Hold": "Your complaint has moved to 'Hold' Stage. We'll soon share an update on the same.",
            "Close": "We are glad to inform that your complaint has been resolved successfully. Your Ticket is now closed."
        };

        this.removedArray = ["No", "Yes", "NA"];
        this.issueTypes = ["", "Manageable", "Non-Manageable"];
        this.stateEmployeeMap = {
            "CHANDIGARH": ["+918448389958", "+918448389952"],
            "DELHI": ["+918448389958", "+918448389952", "+917827941242"],
            "HARYANA": ["+918448389958", "+918448389952", "+917827941242"],
            "PUNJAB": ["+918448389958", "+918448389952", "+917827941242"],
            "UTTARAKHAND": ["+918448389958", "+918448389952"],
            "GUJARAT": ["+918448389958", "+917827038133", "+918287929840", "+918882922788", "+918448380949"],
            "RAJASTHAN": ["+918448389958", "+918448389952"],
            "KERALA": ["+917827944128", "+919205893367", "+919318345697"],
            "TAMIL NADU": ["+917827944128", "+919205893367", "+919318345697"],
            "ANDHRA PRADESH": ["+917827944128"],
            "KARNATAKA": ["+917827944128", "+919205893367", "+919318345697"],
            "UTTAR PRADESH": ["+919205695692", "+918448380949", "+918287102187"],
            "TELANGANA": ["+917827944128", "+918287929833", "+918287102178"],
            "ALL": ["+918448389958", "+918448389952", "+917827941242", "+917827038133", "+918287929840", "+918882922788", "+918448380949", "+917827944128", "+919205893367", "+919318345697", "+919205695692", "+918287102187", "+918287929833", "+918287102178"]
        };
        // this.sourceOptions = ["Internal", "System", "Facebook", "Twitter", "Youtube", "Google", "Call", "Email", "website", "Other"];
        this.sourceOptions = ["Email", "Call", "Whatsapp", "Social Media", "website"];
    }

    componentWillMount() {
        // this._isMounted = false
        if (this.props.user) {
            if (this.props.user.level >= 1600) {
                this.props.fetchTicketId(this.props.match.params.id);
            }
        }
    }

    componentDidMount() {
        // this._isMounted = true
        if (this.props.user) {
            if (this.props.user.level >= 1600) {
                setInterval(() => this.setState({ curTime: parseInt((new Date().getTime()) / 1000) }), 1000);
                let tableColumns = relatedTicketsColumn.map(val => {
                    if (val.accessor == "createdBy") {
                        val.Cell = row => {
                            return ((row.value && this.state.employeeList[row.value + ""]) ? this.state.employeeList[row.value + ""] : "")
                        }
                    }
                    if (val.accessor == "assignedTo") {
                        val.Cell = row => {
                            return ((row.value && this.state.employeeList[row.value + ""]) ? this.state.employeeList[row.value + ""] : "")
                        }
                    }
                    if (val.accessor == "issueType") {
                        val.Cell = row => {
                            return ((row.value && this.issueTypes[row.value]) ? this.issueTypes[row.value] : "")
                        }
                    }
                    return val;
                })
                this.setState({
                    relatedTicketsColumns: tableColumns
                })
            }
        }
    }

    componentWillReceiveProps(props) {
        if (props.ticketData.data.ticketDetail && this.state.initState) {
            let responsibleOptions = [];
            if (props.ticketData.data.ticketDetail.responsibleTeam == 'PM') {
                if (props.ticketData.data.ticketDetail.state && props.ticketData.data.stateEmployeeMap[props.ticketData.data.ticketDetail.state.toUpperCase()]) {
                    responsibleOptions = props.ticketData.data.stateEmployeeMap[props.ticketData.data.ticketDetail.state.toUpperCase()];
                }
                else {
                    responsibleOptions = props.ticketData.data.stateEmployeeMap["ALL"];
                }
            }
            else {
                responsibleOptions = props.ticketData.data.teamEmployeeMap[props.ticketData.data.ticketDetail.responsibleTeam];
            }
            let { CM = [], Procurement = [] } = props.ticketData.data.teamEmployeeMap;
            let { phoneNumber, level } = this.props.user;
            // let canClose = [ ...CM, ...Procurement ].includes(phoneNumber) || level>=2300 || phoneNumber == "+918448389958"
            let minDueDate = moment().format("YYYY-MM-DD");
            let maxDays = props.ticketData.data.ticketDetail.type == 'Complaint'?3:7;
            let maxDueDate = moment().add(maxDays, 'days').format("YYYY-MM-DD")
            let canClose = [ ...CM, ...Procurement ].includes(phoneNumber) || level>=2300 || phoneNumber == "+918448389958" || phoneNumber == "+919871163924"
            this.setState({
                ticketData: props.ticketData.data.ticketDetail,
                editForm: {
                    responsibleTeam: props.ticketData.data.ticketDetail.responsibleTeam,
                    assignedTo: props.ticketData.data.ticketDetail.assignedTo,
                    priority: props.ticketData.data.ticketDetail.priority ? props.ticketData.data.ticketDetail.priority : 'NA',
                    dueDate: changeToInputDate(props.ticketData.data.ticketDetail.dueDate),
                    status: props.ticketData.data.ticketDetail.status,
                    text: props.ticketData.data.ticketDetail.text,
                    removed: props.ticketData.data.ticketDetail.removed ? props.ticketData.data.ticketDetail.removed : 0,
                    issueType: props.ticketData.data.ticketDetail.issueType ? props.ticketData.data.ticketDetail.issueType : 0,
                    source: props.ticketData.data.ticketDetail.source ? props.ticketData.data.ticketDetail.source : "",
                    deliveryStatus: props.ticketData.data.ticketDetail.deliveryStatus ? props.ticketData.data.ticketDetail.deliveryStatus : "Not Ordered"
                },
                teamEmployeeMap: props.ticketData.data.teamEmployeeMap,
                employeeList: props.ticketData.data.employeeList,
                responsibleOptions: responsibleOptions,
                comments: props.ticketData.data.commentsData.reverse(),
                initState: false,
                stateEmployeeMap: props.ticketData.data.stateEmployeeMap,
                relatedTickets: props.ticketData.data.relatedTickets,
                canClose,
                minDueDate,
                maxDueDate
            },() => {console.log(this.state,this.state.ticketData,'here')});
        }
        if (this.state.reOpenState) {
            if (props.openTicket.data && props.openTicket.data.error) {
                alert(props.openTicket.data.error);
            }
            else {
                this.setState(prevState => ({
                    ticketData: {
                        ...prevState.ticketData,
                        status: 'Open'
                    },
                    editForm: {
                        ...prevState.editForm,
                        status: 'Open'
                    },
                    reOpenState: false
                }))
            }
        }
    }

    handleEditFormChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (e.target.name == 'responsibleTeam') {
            if (value != -1) {
                if (value == 'PM') {
                    if (this.state.ticketData.state && this.state.stateEmployeeMap[this.state.ticketData.state.toUpperCase()]) {
                        this.setState({
                            responsibleOptions: this.state.stateEmployeeMap[this.state.ticketData.state.toUpperCase()]
                        })
                    } else {
                        this.setState({
                            responsibleOptions: this.state.stateEmployeeMap["ALL"]
                        })
                    }
                }
                else {
                    this.setState({
                        responsibleOptions: this.state.teamEmployeeMap[value]
                    })
                }
            }
            else {
                this.setState({
                    responsibleOptions: []
                })
            }
        }
        if (e.target.name == 'status') {
            if (value != -1) {
                this.setState(prevState => ({
                    editForm: {
                        ...prevState.editForm,
                        text: this.statusTextMap[value]
                    }
                }))
            }
        }
        this.setState(prevState => ({
            editForm: {
                ...prevState.editForm,
                [name]: value
            }
        }))
    }

    handleCommentChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value
        })
    }
    handleCommentSubmit = (e) => {
        if (this.state.newComment && this.state.newComment.trim() != "") {
            let epoch = new Date().getTime();
            this.props.updateComment(this.state.newComment, this.props.match.params.id, this.props.user.phoneNumber, epoch);
            let newComment = {
                comment: this.state.newComment,
                commentBy: this.props.user.phoneNumber,
                time: epoch
            };
            this.setState({
                newComment: '',
                comments: [...[newComment], ...this.state.comments]
            }, function () {
                console.log(this.state.comments);
            });
        }
    }


    secondsToHms = () => {
        if (this.state.ticketData.status == 'Open' || this.state.ticketData.status == 'In Progress') {
            let sec = parseInt(this.state.ticketData.dueDate / 1000 - this.state.curTime);
            let d = sec;
            if (sec < 0) {
                d = sec * -1;
            }
            d = Number(d);
            let text = '';
            let days = Math.floor(d / (24 * 3600));
            d = d - days * 24 * 3600;
            let h = Math.floor(d / 3600);
            d = d - h * 3600;
            let m = Math.floor(d / 60);
            d = d - m * 60;
            d = Math.floor(d);
            if (sec < 0) {
                text = "Overdue since : ";
            }
            else {
                text = "Overdue in : ";
            }
            return text + (days + "d") + " " + ('0' + h).slice(-2) + ":" + ('0' + m).slice(-2) + ":" + ('0' + d).slice(-2);
        }
        else {
            return '';
        }
    }


    handleEditClick = (e) => {
        this.setState({
            edit: !this.state.edit
        })
    }

    editTicketData = (e) => {
        e.preventDefault();
        if (this.state.editForm.responsibleTeam && this.state.editForm.responsibleTeam != -1 && this.state.editForm.assignedTo && this.state.editForm.assignedTo != -1 && this.state.editForm.priority && this.state.editForm.priority != -1 && this.state.editForm.dueDate && this.state.editForm.status && this.state.editForm.status != -1 && (this.state.editForm.text || !this.state.ticketData.isComplaint)) {
            if(!(this.state.editForm.dueDate >= this.state.minDueDate && this.state.editForm.dueDate <= this.state.maxDueDate)) {
                alert("Invalid due date");
                return;
            }
            if (this.state.ticketData.isComplaint && this.state.editForm.status == 'Close' && (!this.state.editForm.issueType || this.state.editForm.issueType == "0")) {
                alert("Please fill issue type");
                this.setState({
                    edit: !this.state.edit
                })
            }
            else {
                if (changeToInputDate(this.state.ticketData.dueDate) != changeToInputDate(this.state.editForm.dueDate)) {
                    this.setState({
                        openCommentModal: true
                    })
                }
                else {
                    this.handleSaveClick();
                }
            }
        }
        else {
            alert('Please fill all the details!!!!');
            this.setState({
                edit: !this.state.edit
            })
        }
    }

    submitDueDateComment = (e) => {
        let dueDateChangeComment = (this.state.dueDateChangeComment || "").trim();
        if (dueDateChangeComment && dueDateChangeComment.length>20) {
            let epoch = new Date().getTime();
            this.props.updateComment(this.state.dueDateChangeComment, this.props.match.params.id, this.props.user.phoneNumber, epoch);
            let newComment = {
                comment: this.state.dueDateChangeComment,
                commentBy: this.props.user.phoneNumber,
                time: epoch
            };
            this.setState({
                dueDateChangeComment: '',
                comments: [...[newComment], ...this.state.comments],
                openCommentModal: false
            }, function () {
                console.log(this.state.comments);
                this.handleSaveClick();
            });
            // this.handleSaveClick();
        }
        else{
            alert("Please enter Min 20 Characters!!");
        }
    }

    handleSaveClick = (e) => {
        if(this.state.editForm.status == "Close" && !this.state.editForm.reasonForClose){
            alert("Reason Not Mentioned");
            return;
        };
       if (this.state.editForm.responsibleTeam && this.state.editForm.responsibleTeam != -1 && this.state.editForm.assignedTo && this.state.editForm.assignedTo != -1 && this.state.editForm.priority && this.state.editForm.priority != -1 && this.state.editForm.dueDate && this.state.editForm.status && this.state.editForm.status != -1 && (this.state.editForm.text || !this.state.ticketData.isComplaint)) {
            let closeTime = this.state.editForm.status == 'Close' ? new Date().getTime() : '';
            this.props.updateTicket(this.state.editForm, this.props.match.params.id, this.state.ticketData.type + "__" + this.state.ticketData.subType, this.state.ticketData.quoteId, closeTime, this.state.ticketData.type, this.state.ticketData);
            let changedDueDate = this.state.editForm.dueDate;
            changedDueDate = changedDueDate.split("-");
            changedDueDate = new Date(changedDueDate[1] + "-" + changedDueDate[2] + "-" + changedDueDate[0]).getTime() + 86399000;
            let comment = "";
            if (!(this.state.ticketData.responsibleTeam == this.state.editForm.responsibleTeam && this.state.ticketData.assignedTo == this.state.editForm.assignedTo)) {
                // let comment += (changeToViewDateTime(new Date().getTime()) + "\nNew Responsible : " + this.state.employeeList[this.state.editForm.assignedTo] + "\n" + "Old Responsible : " + this.state.employeeList[this.state.ticketData.assignedTo]);
                comment += `Responsible changed from ${this.state.employeeList[this.state.ticketData.assignedTo]} to ${this.state.employeeList[this.state.editForm.assignedTo]}\n`
                // this.insertResponsibleChangeComment(comment);
            }
            if(this.state.ticketData.status != this.state.editForm.status ){
                comment += `Status changed from ${this.state.ticketData.status} to ${this.state.editForm.status}\n`
            }
            if(this.state.ticketData.dueDate != changedDueDate ){
                let oldDate = moment(this.state.ticketData.dueDate).format("DD MMM, YYYY");
                let newDate = moment(changedDueDate).format("DD MMM, YYYY");
                comment += `Due Date changed from ${oldDate} to ${newDate}`
            }
            if(comment){
                this.insertResponsibleChangeComment(comment);
            }
            if (this.state.ticketData.isComplaint && this.state.ticketData.status != 'Close' && this.state.editForm.status == 'Close') {
                this.props.sendFeedbackMsg(this.state.ticketData.quoteId, this.state.ticketData.phoneNumber, 'complaint', this.state.ticketData.custName);
            }
            this.setState(prevState => ({
                ticketData: {
                    ...prevState.ticketData,
                    dueDate: changedDueDate,
                    priority: this.state.editForm.priority,
                    status: this.state.editForm.status,
                    responsibleTeam: this.state.editForm.responsibleTeam,
                    assignedTo: this.state.editForm.assignedTo,
                    text: this.state.editForm.text,
                    removed: this.state.editForm.removed,
                    issueType: this.state.editForm.issueType,
                    source: this.state.editForm.source,
                    deliveryStatus: this.state.editForm.deliveryStatus,
                    closeTime,
                    reasonForClose : this.state.editForm.reasonForClose
                }
            }))
        }
        else {
            alert('Please fill all the details!!!!');
        }
        this.setState({
            edit: !this.state.edit
        })
    }

    insertResponsibleChangeComment = (comment) => {
        if (comment) {
            let epoch = new Date().getTime();
            this.props.updateComment(comment, this.props.match.params.id, this.props.user.phoneNumber, epoch);
            let newComment = {
                comment: comment,
                commentBy: this.props.user.phoneNumber,
                time: epoch
            };
            this.setState({
                comments: [...[newComment], ...this.state.comments]
            }, function () {
                console.log(this.state.comments);
            });
        }
    }

    onCloseCommentModal = (e) => {
        this.setState({
            openCommentModal: false
        })
    }

    handleReopenClick = (e) => {
        this.setState({
            reOpenState: true
        });
        this.props.reOpenTicket(this.state.ticketData.quoteId, this.state.ticketData.type + "__" + this.state.ticketData.subType, this.state.ticketData.ticketId);
    }

    render() {
        if (this.props.user === null) {
            return <Redirect to={"/login?redirect_uri=tickets/" + this.props.match.params.id} />;
        }

        if (this.props.user && this.props.user.level < 1600) {
            return (
                <div>
                    <Restrict />
                </div>
            )
        }
        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            },
            iconShow: {
                float: 'right',
                display: ''
            },
            iconHide: {
                float: 'right',
                display: 'none'
            }
        }

        return (
            <div style={{ "marginTop": 50 }} className="container-fluid">
                <Modal open={this.state.openCommentModal} onClose={this.onCloseCommentModal}>
                    <div className="form-group col-11">
                        <label style={{ fontSize: "16px" }}>Comment to change due date:</label>
                        <textarea rows="5" className="form-control" type="text" name="dueDateChangeComment" value={this.state.dueDateChangeComment} onChange={this.handleCommentChange} required />
                    </div>
                    <div className="col-1">
                        <button onClick={this.submitDueDateComment} className="btn btn-success">Submit</button>
                    </div>
                </Modal>
                <div className="row" style={{ backgroundColor: "#cceffb", padding: "15px", margin: "0px" }}>
                    <div className="col-md-7">
                        <h2>Ticket Id - {this.state.ticketData.ticketId}</h2>
                    </div>
                    <div className="col-md-3" style={{ float: "right", fontSize: "20px" }}>{this.secondsToHms()}</div>
                    <div className="col-md-2" style={this.state.ticketData.status == 'Close' ? style.hide : style.show}>
                        <div style={this.state.edit ? style.hide : style.show}><i className="fa fa-pen" style={{ float: "right", fontSize: "20px" }} onClick={this.handleEditClick}></i></div>
                        <div style={this.state.edit ? style.show : style.hide}><button className="btn btn btn-outline-secondary" style={{ float: "right", fontSize: "20px" }} onClick={this.editTicketData}>Save</button></div>
                    </div>
                    <div className="col-md-2" style={this.state.ticketData.status == 'Close' ? style.show : style.hide}>
                        <button style={{ display: 'inline' }} className="btn btn btn-outline-secondary" onClick={this.handleReopenClick}>ReOpen</button>
                    </div>
                </div>
                <div style={{ backgroundColor: "#e2f5fc", padding: "15px" }}>
                    <div className="row">
                        <div className="col-6" style={{ "fontSize": "15px" }}>
                            Quote Id : {this.state.ticketData.quoteId}
                        </div>
                        <div className="col-6" style={{ "fontSize": "15px" }}>
                            Client Name : {this.state.ticketData.custName}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6" style={{ "fontSize": "15px" }}>
                            Category : {this.state.ticketData.type}
                        </div>
                        <div className="col-6" style={{ "fontSize": "15px" }}>
                            Subcategory : {this.state.ticketData.subType}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6" style={{ "fontSize": "15px" }}>
                            Created by : {this.state.employeeList[this.state.ticketData.createdBy + ""]}
                        </div>
                        <div className="col-6" style={{ "fontSize": "15px" }}>
                            <div style={this.state.edit ? style.hide : style.show}>Due Date : {changeToViewDate(this.state.ticketData.dueDate)}</div>
                            <div style={this.state.edit ? style.show : style.hide} className="form-group">
                                <div className="date">
                                    Due Date : <input type="date" name="dueDate" value={this.state.editForm.dueDate} onChange={this.handleEditFormChange} min={this.state.minDueDate} max={this.state.maxDueDate} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6" style={{ "fontSize": "15px" }}>
                            <div style={this.state.ticketData.isComplaint ? style.hide : style.show}>
                                <div style={this.state.edit ? style.hide : style.show}>Priority : {this.state.ticketData.priority}</div>
                                <div style={this.state.edit ? style.show : style.hide} className="form-group">
                                    Priority : <select style={{ width: "20%", display: "inline" }} className="form-control" name="priority" value={this.state.editForm.priority} onChange={this.handleEditFormChange}>
                                        <option key="1" value="Normal">Normal</option>
                                        <option key="2" value="High">High</option>
                                        <option key="3" value="Low">Low</option>
                                    </select>
                                </div>
                            </div>
                            <div style={this.state.ticketData.isComplaint ? style.show : style.hide}>
                                <div style={this.state.edit ? style.hide : style.show}>Removed : {this.removedArray[this.state.ticketData.removed]}</div>
                                <div style={this.state.edit ? style.show : style.hide} className="form-group">
                                    Removed : <select style={{ width: "20%", display: "inline" }} className="form-control" name="removed" value={this.state.editForm.removed} onChange={this.handleEditFormChange}>
                                        <option key="0" value="0">No</option>
                                        <option key="1" value="1">Yes</option>
                                        <option key="2" value="2">NA</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                        <div className="col-3" style={{ "fontSize": "15px" }}>
                            <div style={this.state.edit && this.state.ticketData.status != 'Close' ? style.hide : style.show}>Status : {this.state.ticketData.status} {(this.state.ticketData.status == 'Close' && this.state.ticketData.closeTime) ? changeToViewDate(this.state.ticketData.closeTime) : ''}</div>
                            <div style={this.state.edit && this.state.ticketData.status != 'Close' ? style.show : style.hide} className="form-group">
                                Status : <select style={{ width: "40%", display: "inline" }} className="form-control" name="status" value={this.state.editForm.status} onChange={this.handleEditFormChange}>
                                    <option key="1" value="Open">Open</option>
                                    {this.state.canClose?<option key="2" value="Close">Close</option>:""}
                                    <option key="3" value="In Progress">In Progress</option>
                                    <option key="4" value="Hold">Hold</option>
                                </select>
                            </div>
                            
                        </div>
                        <div className="col-3" style={{ "fontSize": "15px" }}>
                            <div style={this.state.ticketData.status=="Close" ? style.show : style.hide}>
                                Reason : {this.state.ticketData.reasonForClose}
                            </div>
                            <div style={ this.state.edit && this.state.editForm.status == "Close" ? style.show : style.hide} className="form-group">
                                Reason : <select style={{width:"40%", display:"inline"}} className="form-control" name="reasonForClose" value={this.state.editForm.reasonForClose} onChange = {this.handleEditFormChange}>
                                    <option key="-1" value="">--Not Selected--</option>
                                    <option key="1" value="Irrelevant ticket">Irrelevant ticket</option>
                                    <option key="2" value="Ticket Resolved">Ticket Resolved</option>
                                    <option key="3" value="Client not responding">Client not responding</option>
                                    <option key="4" value="Wrong type/sub type selected">Wrong type/sub type selected</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6" style={{ "fontSize": "15px" }}>
                            <div style={this.state.edit ? style.hide : style.show}>Responsible : {this.state.employeeList[this.state.ticketData.assignedTo + ""]}</div>
                            <div style={this.state.edit ? style.show : style.hide} className="form-group">
                                Responsible : <select style={{ width: "20%", display: "inline" }} className="form-control" name="responsibleTeam" value={this.state.editForm.responsibleTeam} onChange={this.handleEditFormChange}>
                                    <option key="-1" value="-1">---Select Team---</option>
                                    {
                                        Object.keys(this.state.teamEmployeeMap ? this.state.teamEmployeeMap : {}).map((v, i) => {
                                            return (<option key={i} value={v}>{v}</option>)
                                        })
                                    }
                                </select>
                                <select style={{ width: "30%", display: "inline" }} className="form-control" name="assignedTo" value={this.state.editForm.assignedTo} onChange={this.handleEditFormChange}>
                                    <option key="-1" value="-1">--Select Responsible---</option>
                                    {
                                        (this.state.responsibleOptions ? this.state.responsibleOptions : []).map((v, i) => {
                                            return (<option key={i} value={v}>{this.state.employeeList[v + ""]}</option>)
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-6" style={{ "fontSize": "15px" }}>
                            <div style={this.state.edit ? style.hide : style.show}>
                                Source : {this.state.ticketData.source}
                            </div>
                            <div style={this.state.edit ? style.show : style.hide} className="form-group">
                                Source : <select style={{ width: "20%", display: "inline" }} className="form-control" name="source" value={this.state.editForm.source} onChange={this.handleEditFormChange}>
                                    {
                                        (this.sourceOptions ? this.sourceOptions : []).map((v, i) => {
                                            return (<option key={i} value={v}>{v}</option>)
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        {/* <div className="col-6" style={{ "fontSize": "15px" }}>
                            Source : {this.state.ticketData.source}
                        </div> */}
                        <div className="col-6" style={{ "fontSize": "15px" }}>
                            <div style={this.state.ticketData.isComplaint ? style.show : style.hide}>
                                <div style={this.state.edit ? style.hide : style.show}>Issue Type : {this.state.ticketData.issueType ? this.issueTypes[this.state.ticketData.issueType] : ""}</div>
                                <div style={this.state.edit ? style.show : style.hide} className="form-group">
                                    Issue Type : <select style={{ width: "20%", display: "inline" }} className="form-control" name="issueType" value={this.state.editForm.issueType} onChange={this.handleEditFormChange}>
                                        <option key="0" value="0">--Select Issue Type--</option>
                                        <option key="1" value="1">Manageable</option>
                                        <option key="2" value="2">Non-Manageable</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-6" style={{ "fontSize": "15px" }}>
                            <div style={this.state.ticketData.isComplaint ? style.show : style.hide}>
                                <div style={this.state.edit ? style.hide : style.show}> Comment:{this.state.ticketData.text} </div>
                                <div style={this.state.edit ? style.show : style.hide}>Comment: <textarea style={{ width: '60%', display: 'inline' }} rows="2" className="form-control" type="text" name="text" value={this.state.editForm.text} onChange={this.handleEditFormChange} /> </div>
                            </div>
                        </div>
                        {(this.state.ticketData.type == 'Payment') ? <div className="col-6" style={{ "fontSize": "15px" }}>
                            Amount : {this.state.ticketData.amount}
                        </div> : ""}
                        {(this.state.ticketData.type == 'Procurement') ? <div className="col-6" style={{ "fontSize": "15px" }}>
                            <div style={this.state.edit ? style.hide : style.show}>Delivery Status : {this.state.ticketData.deliveryStatus ? this.state.ticketData.deliveryStatus : "Not Ordered"}</div>
                            <div style={this.state.edit ? style.show : style.hide} className="form-group">
                                Delivery Status : <select style={{ width: "20%", display: "inline" }} className="form-control" name="deliveryStatus" value={this.state.editForm.deliveryStatus} onChange={this.handleEditFormChange}>
                                    <option key="1" value="Not Ordered">Not Ordered</option>
                                    <option key="2" value="Ordered">Ordered</option>
                                    <option key="3" value="Ordered">Delivered</option>
                                </select>
                            </div>
                        </div> : ""}
                        {(this.state.ticketData.type == 'Procurement' && this.state.ticketData.subType == 'Panel') ? <div className="col-6" style={{ "fontSize": "15px" }}>
                            Type Of Panel : {this.state.ticketData.panelType}
                        </div> : ""}
                        {(this.state.ticketData.type == 'Procurement' && this.state.ticketData.subType == 'Panel') ? <div className="col-6" style={{ "fontSize": "15px" }}>
                            Brand Of Panel : {this.state.ticketData.panelBrand}
                        </div> : ""}
                        {(this.state.ticketData.type == 'Procurement' && this.state.ticketData.subType == 'Panel') ? <div className="col-6" style={{ "fontSize": "15px" }}>
                            Brand Confirmation : {this.state.ticketData.brandConfirm}
                        </div> : ""}
                        {(this.state.ticketData.type == 'Procurement' && this.state.ticketData.subType == 'Inverter') ? <div className="col-6" style={{ "fontSize": "15px" }}>
                            Phase : {this.state.ticketData.inverterPhase}
                        </div> : ""}
                        {(this.state.ticketData.type == 'Procurement' && this.state.ticketData.subType == 'Inverter') ? <div className="col-6" style={{ "fontSize": "15px" }}>
                            Brand Of Inverter : {this.state.ticketData.inverterBrand}
                        </div> : ""}
                        {(this.state.ticketData.type == 'Procurement' && this.state.ticketData.subType == 'Inverter') ? <div className="col-6" style={{ "fontSize": "15px" }}>
                            Brand Confirmation : {this.state.ticketData.brandConfirm}
                        </div> : ""}
                        {(this.state.ticketData.type == 'Procurement' && this.state.ticketData.subType == 'Inverter') ? <div className="col-6" style={{ "fontSize": "15px" }}>
                            Grid Tied : {this.state.ticketData.gridTied}
                        </div> : ""}
                    </div>
                    <div style={{ "fontSize": "15px" }}>Description - {this.state.ticketData.comment}</div>
                </div>
                <div className="comments" style={{ "overflowY": "scroll", "maxHeight": "300px" }}>
                    {
                        this.state.comments.map((v, i) => {
                            return (
                                <div className="comment" style={{ backgroundColor: "#edf1f3", margin: "15px", width: "60%", borderRadius: "20px" }}>
                                    <div className="commentBy" style={{ padding: "3px", marginLeft: "10px" }}>
                                        <b>{this.state.employeeList[v.commentBy]}</b><span className="commentTime" style={{ marginLeft: "5px" }}>{changeToViewDate(v.time)}</span>
                                    </div>

                                    <div className="commentText" style={{ padding: "3px", marginLeft: "10px", whiteSpace: "pre-wrap" }}>
                                        {v.comment}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="newComment row" style={{ "position": "relative" }}>
                    <div className="form-group col-11">
                        <label>Comment</label>
                        <textarea rows="5" className="form-control" type="text" name="newComment" value={this.state.newComment} onChange={this.handleCommentChange} required />
                    </div>
                    <div className="col-1" style={{ bottom: "20px", right: "0", "position": "absolute" }}>
                        <button onClick={this.handleCommentSubmit} className="btn btn-success">Submit</button>
                    </div>
                </div>
                <div style={{ marginTop: "40px" }}>
                    <h3>Related Tickets</h3>
                    <br></br>
                    <ReactTable
                        columns={this.state.relatedTicketsColumns}
                        data={Object.values(this.state.relatedTickets)}
                        filterable={true}
                        showPagination={true}
                        sortable={true}
                        defaultPageSize={5}
                        style={{ textAlign: "center" }}
                    >
                    </ReactTable>
                </div>
            </div>
        );


    }
}


function mapStateToProps(state) {
    return {
        user: state.user,
        ticketData: state.ticketdata,
        updatedTicket: state.updateticket,
        commentupdate: state.updatecomment,
        openTicket: state.reopen,
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchTicketId, updateTicket, updateComment, reOpenTicket, sendFeedbackMsg }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Tickets);