import { FETCH_SALES_TARGET_SUCCESS, FETCH_TARGET_ACCESS_SUCCESS } from './actiontypes';
import { db } from '../connection';
import axios from 'axios';

export function fetchSalesTarget() {
    return async (dispatch) => {
        try {
            let [ salesTarget, homesImpactedData ] = await Promise.all([
                db.ref(`website/salesTarget`).once('value'),
                axios.post(`https://zrm.zunroof.com:3002/api/fetchtargetportaldata`, { key: "8ALS!Fcm#iWX_IgN" })
            ])
            salesTarget = salesTarget.val();
            let { supply = 0, consumption = 0 } = homesImpactedData.data || {};
            salesTarget.homesImpacted = salesTarget.homesImpacted || {};
            salesTarget["homesImpacted"]["supply"] = supply;
            salesTarget["homesImpacted"]["consumption"] = consumption;
            dispatch({ type: FETCH_SALES_TARGET_SUCCESS, payload: salesTarget });
        }
        catch (e) {
            console.log("Error in fetch sales target", e);
            return;
        }
    }
}

export function updateSalesTarget(type, newData = {}, allData = {}, user) {
    return async (dispatch) => {
        try {
            if(!type) {
                dispatch({ type: FETCH_SALES_TARGET_SUCCESS, payload: JSON.parse(JSON.stringify(allData)) });
                return;
            }
            let typeData = allData[type] || {};
            let updatedData = {};
            Object.keys(newData).map(key => {
                if(typeData[key] != newData[key]) {
                    updatedData[key] = newData[key];
                }
            })
            if(!Object.keys(updatedData).length) {
                dispatch({ type: FETCH_SALES_TARGET_SUCCESS, payload: JSON.parse(JSON.stringify(allData)) });
                return;
            }
            let updatedOn = new Date().getTime();
            let historyData = {
                updatedOn,
                updatedBy: user,
                oldData: typeData,
                type: type,
                updatedData: JSON.parse(JSON.stringify(updatedData))
            }
            updatedData.updatedBy = user;
            updatedData.updatedOn = updatedOn;
            await Promise.all([
                db.ref(`website/salesTarget/${type}`).update(updatedData),
                saveHistory(historyData)
            ]);
            let updatedAllData = JSON.parse(JSON.stringify(allData));
            updatedAllData[type] = newData;
            updatedAllData[type].updatedOn = updatedOn;
            dispatch({ type: FETCH_SALES_TARGET_SUCCESS, payload: updatedAllData });
        }
        catch(e) {
            console.log("Error in update sales target", e);
            return;
        }
    }
}

export function updateAchievedCounter(type, value, allData = {}, user) {
    return async (dispatch) => {
        try {
            if(!type || !value) {
                dispatch({ type: FETCH_SALES_TARGET_SUCCESS, payload: JSON.parse(JSON.stringify(allData)) });
                return;
            }
            let typeData = allData[type] || {};
            let { achieved = 0 } = typeData;
            let updatedValue = (+achieved) + (+value);
            if(updatedValue < 0) {
                dispatch({ type: FETCH_SALES_TARGET_SUCCESS, payload: JSON.parse(JSON.stringify(allData)) });
                return;
            }
            let updatedData = {
                achieved: updatedValue
            };
            let updatedOn = new Date().getTime();
            let historyData = {
                updatedOn,
                updatedBy: user,
                oldData: typeData,
                type: type,
                updatedData: JSON.parse(JSON.stringify(updatedData))
            }
            updatedData.updatedBy = user;
            updatedData.updatedOn = updatedOn;
            await Promise.all([
                db.ref(`website/salesTarget/${type}`).update(updatedData),
                saveHistory(historyData)
            ]);
            // await db.ref(`website/salesTarget/${type}/achieved`).set(updatedValue);
            let updatedAllData = JSON.parse(JSON.stringify(allData));
            if(!updatedAllData[type]) {
                updatedAllData[type] = {};
            }
            updatedAllData[type].achieved = updatedValue;
            updatedAllData[type].updatedOn = updatedOn;
            dispatch({ type: FETCH_SALES_TARGET_SUCCESS, payload: updatedAllData });
        }
        catch(e) {
            console.log("Error in updateAchievedCounter", e);
            return;
        }
    }
}

export function fetchAccessLevel(phoneNumber) {
    return async (dispatch) => {
        try {
            if(!phoneNumber) {
                return;
            }
            let accessLevel = (await db.ref(`website/salesTargetAccess/${phoneNumber}`).once('value')).val();
            // dispatch({type: FETCH_TARGET_ACCESS_SUCCESS, payload: 0});
            dispatch({type: FETCH_TARGET_ACCESS_SUCCESS, payload: accessLevel || 0});
            return;
        }
        catch(e) {
            console.log("Some error occurred", e);
            return;
        }
    }
}

async function saveHistory(data) {
    try {
        await db.ref(`website/salesTargetHistory/${data.updatedOn}`).set(data);
        return;
    }
    catch(e) {
        console.log("Error in save history", e);
        return;
    }
}