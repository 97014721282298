import { FETCHED_ALL, FETCHING_MAIN_SUCCESS } from './actiontypes';

import { columnsComplete } from '../config/column_fields';
import { csvComplete } from '../config/csv_fields';
import { base } from '../connection';

let collectiveData = {
	loading:true,
	tablename:'Loading...',
	column:columnsComplete,
	csvheader:csvComplete,
	pagesize:1,
	pagination:false
}

let completeData = [];
let total = 0

export function fetchsnap() {
	completeData = [];
	return (dispatch) => {
		collectiveData.loading = true;
  		collectiveData.tablename = "Loading...";
		dispatch({type:FETCHED_ALL,payload:collectiveData});
		fetchDeliverySites(dispatch);
	}
}

function fetchDeliverySites(dispatch) {
	console.log(new Date());
	base.fetch('/delivery/sites', {})
	.then(data => {
		let keys = Object.keys(data);
		total = keys.length;
		keys.map(key => {
			let value 					= data[""+key];
			let quoteId 				= value.quoteId;
			let ottArray 				= value.extraPriceLineItem;
			let latitude 				= value.latitude;
			let longitude 				= value.longitude;
			let responsible		 		= value.responsilbleIdDelevery;
			let vendor 					= value.vendor;
			let inc 					= value.inc;
			let paymentHistory 			= value.paymentHistory;
			let user 					= value.siteOwnerId;
			fetchSites(dispatch,user,responsible,key,quoteId,ottArray,paymentHistory,latitude,longitude,vendor,inc);
		});
	})
	.catch(error=>{
		console.log(error);
	})
}

function fetchSites(dispatch,user,responsible,data,quoteId,ottArray,paymentHistory,latitude,longitude,vendor,inc) {
	base.fetch('/sites/'+data,{})
	.then(dataGet => {
		// GET DATA FROM SITES ABOUT QUOTEID AND PAYMENT NEED TO PAY
		// let finalPrice = dataGet.mSite_finalPrice;
		let selectedSize = -1;
		let state = "";
		if(dataGet.mSite_State)
			state = dataGet.mSite_State;
		
		if(dataGet.mSite_selectedSize){
			selectedSize = dataGet.mSite_totalSize ? parseFloat(dataGet.mSite_totalSize):parseFloat(dataGet.mSite_selectedSize);
		}

		// for(let key in ottArray) {
		// 	if(ottArray[key].price){
		// 		finalPrice += parseInt(ottArray[key].price);
		// 	}
		// }
		// let paymentPaid = 0;
		// let paymentLastDate = 0;
		// for(let key in paymentHistory){
		// 	if(paymentHistory[key].amountPaid){
		// 		paymentPaid += parseInt(paymentHistory[key].amountPaid);
		// 		paymentLastDate = paymentHistory[key].paidDate.time;
		// 	}
		// }

		// let paymentDue = Math.floor(finalPrice- paymentPaid);

		// let paidPercentage = 0;
		// if(paymentPaid){
		// 	paidPercentage = Math.ceil(Math.floor((paymentPaid/finalPrice)*10000)/100);
		// }
		fetchWebPortal(dispatch,user,responsible,quoteId,latitude,longitude,vendor,inc,selectedSize,state,data)
	})
	.catch(error => {
		console.log(error);
	})
}


function fetchWebPortal(dispatch,user,responsible,quoteId,latitude,longitude,vendor,inc,selectedSize,state,id) {
	base.fetch('/delivery/webPortal/sites/'+id, {})
	.then(dataGet => {
		let elevationDetails = "";
		let deliveredPanel = "";
		let deliveredInverter = "";
		let phase_D = "";
		let sanctioned_Load_D = "";
		let state_D = "";
		let subsidy_D = "";
		let status = "";
		let appliedDate = "";
		let promisedPanel = "";
		let promisedInverter = "";
		let address_D = "";
		let latlon_D = "";

		console.log("DATA");
		if(dataGet){
			elevationDetails 	= dataGet.elevationDetails?dataGet.elevationDetails:"";
			deliveredPanel 		= dataGet.deliveredPanel?dataGet.deliveredPanel:"";
			deliveredInverter 	= dataGet.deliveredInverter?dataGet.deliveredInverter:"";
			promisedPanel 		= dataGet.promisedPanel?dataGet.promisedPanel:"";
			promisedInverter 	= dataGet.promisedInverter?dataGet.promisedInverter:"";
			phase_D 			= dataGet.phase_D?dataGet.phase_D:"";
			sanctioned_Load_D 	= dataGet.sanctioned_Load_D?dataGet.sanctioned_Load_D:"";
			state_D 			= dataGet.state_D?dataGet.state_D:state;
			address_D 			= dataGet.address_D?dataGet.address_D:"";
			latlon_D 			= dataGet.latlon_D?dataGet.latlon_D:"";
			subsidy_D 			= dataGet.subsidy_D?dataGet.subsidy_D:"";
			if(dataGet.subsidyStatus){
				status = dataGet.subsidyStatus.status;
				if(dataGet.subsidyStatus.appliedDate)
				appliedDate = dataGet.subsidyStatus.appliedDate.time;
			}
		}

		if(latlon_D) {
			let dataSplit = latlon_D.split(",");
			latitude 	= dataSplit[0];
			longitude 	= dataSplit[1];
		}
		console.log(latitude);
		fetchUser(dispatch,user,responsible,quoteId,latitude,longitude,vendor,inc,selectedSize,state_D,address_D)
	})
	.catch(error=>{

	})
}


function fetchUser(dispatch,user,responsible,quoteId,latitude,longitude,vendor,inc,selectedSize,state,address_D) {
	base.fetch('/users/'+user,{})
	.then(dataGet => {
		let email = "";
		let name = "";
		let phone = "";
		let city = "";
		let address = "";
		console.log("DATA USER")
		if(dataGet.mUser_Contact){
			email 	= dataGet.mUser_Contact.mContact_Email;
			name 	= dataGet.mUser_Contact.mContact_FirstName+" "+dataGet.mUser_Contact.mContact_LastName;
			phone 	= user;
			city 	= dataGet.mUser_Contact.mContact_City;
			address = address_D?address_D:dataGet.mUser_Contact.mContact_AddressLine1;
		}
		fetchEmployee(dispatch,responsible,quoteId,latitude,longitude,vendor,inc,selectedSize,state,email,name,phone,city,address)
	})
	.catch(error => {

	})
}


function fetchEmployee(dispatch,responsible,quoteId,latitude,longitude,vendor,inc,selectedSize,state,email,name,phone,city,address_D) {
	base.fetch('/users/'+responsible,{})
	.then(dataGet => {
		let responsible = "";
		if(dataGet.mUser_Contact)
			responsible = dataGet.mUser_Contact.mContact_FirstName+" "+dataGet.mUser_Contact.mContact_LastName;
		let values = {
			responsible: responsible,
			quoteid: quoteId,
			latitude: latitude,
			longitude: longitude,
			vendor: vendor,
			inc: inc,
			selectedsize: selectedSize,
			state: state,
			email: email,
			name: name,
			phone: phone,
			city: city,
			responsible: responsible,
			address:address_D
		}
		completeData = [...completeData,values];
		if(completeData.length===total){
			console.log(new Date());
			collectiveData.loading = false;
			collectiveData.tablename='All Data';
			dispatch({type:FETCHED_ALL,payload:collectiveData});
			dispatch({type:FETCHING_MAIN_SUCCESS,payload:completeData});
		}
	})
	.catch(error => {
		console.log(error)
	})
}