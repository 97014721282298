import { ADD_USER_START,
  ADD_USER_SUCCESS} from '../actions/actiontypes'

const INIT_STATE = {
	success : false
}

export default function(state = INIT_STATE, action) {
	switch(action.type){
		case ADD_USER_START:{
			return state;
		}
		case ADD_USER_SUCCESS:{
			return {...state, success : true };
		}
		default: {
			return state;
		}
	}
}
