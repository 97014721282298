import React, { Component } from 'react';
import Modal from 'react-responsive-modal';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';


import { fetchsalesdata } from '../actions/fetchsalesdata';
import { fetchmapping } from '../actions/fetchmapping';

import { SalesAnalyticsView } from '../components/SalesAnalyticsView';
import { PercentageGraph } from '../components/PercentageGraph';
import { Restrict } from '../components/Restrict';
import { Loader } from "../components/Loader.jsx";
import  { Filter } from '../components/Filter';

import { dateRange, percentageRange } from '../helper/range';
import { dateToMonth } from '../helper/dateChanger';
import { checkExist, checkArrayExist } from '../helper/filterData';

import { styleSheet } from '../styles/styleSheet';
import '../styles/all.css'



class SalesAnalytics extends Component {

	constructor(props) {
		super(props);

		this.state = {
			recieved:false,
			loading:true,
			data:[],
			open: false,
			state:"count",
			city:"count",
			cityMonth:"count",
			viewData:[],
			stateStageData:[],
			sizeCount:{labels:[],datasets:[]},
			stateStageColumns:[{Header:"State",accessor:"state"}],
			cityStageColumns:[{Header:"City",accessor:"city"}],
			cityMonthColumns:[{Header:"City",accessor:"city"}],
			employeeMonthColumns:[{Header:"Employee",accessor:"employeeName"}],
			buttonTypes:[{label:"Count", type:"count"},{label:"Size", type:"size"}]
		};
		this.vendor1 = ["NA","Boond1","Chemitech","SmartRoof","Aryav","Zivah","SDSL","Oakridge","ZunRoof","SunEnergy","Volks Energie","Sirius","Greenon","GeoPower","GM Trading","Alien","Adhunik"]
		this.choosenColumn = {};
	}

	componentWillMount() {
		let { fetchsalesdata, fetchmapping, user } = this.props;
		if(user && user.level>=1600) {
			fetchmapping();
			fetchsalesdata("salesAnalytics");
		}
	}

	componentWillReceiveProps({user, loading, data, stageMap, stateMap, cityMap, subsidyMap, ebissueMap, employeeIdMap}) {
		if(stageMap && stateMap && cityMap && subsidyMap && ebissueMap && employeeIdMap && stageMap.length && stateMap.length) {
			let stateStageData = []; 
			let cityStageData = [];
			let cityMonthData = [];
			let employeeMonthData = [];
			let stageObject = {};
			let monthObject = {};
			let currentMonth = dateToMonth(new Date().getTime());
			let monthColumnsList = []
			let stateStageColumnsList  = [];
			stageMap.forEach((value,index) => {
				if(index !== 11) {
					stageObject[`${index}`] = 0;
					stateStageColumnsList.push({
						Header: value,
						accessor: `${index}`,
						Cell:row => {
							return (
								<div>{row.value}</div>
							)
						}
					})
				}
			});

			for(var i=new Date().getFullYear(); i>=2016;i--) {
				for(var j=11; j>-1;j--) {
					if((i!==2016 || j > 4)&&currentMonth>=(i*100+j+1)) {
						monthObject[`${i*100+j+1}`] = 0;
						monthColumnsList.push({
							Header:`${i*100+j+1}`,
							accessor: `${i*100+j+1}`,
							Cell:row => {
								return (
									<div>{row.value}</div>
								)
							}
						});
					}
				}	
			}

			Object.keys(cityMap).forEach((value,index) => {
				cityStageData.push({city:value.trim(),...stageObject});
				cityMonthData.push({city:value.trim(),...monthObject});
			});

			Object.keys(employeeIdMap).forEach((value,index) => {
				employeeMonthData.push({employeeName:value.trim(),...stageObject});
			});

			stateMap.forEach(val => {
				stateStageData.push({state:val,...stageObject})
			});

			this.setState({
				stateStageColumns: [{Header:"State",accessor:"state"},...stateStageColumnsList],
				cityStageColumns: [{Header:"City",accessor:"city"},...stateStageColumnsList],
				cityMonthColumns: [{Header:"City",accessor:"city"},...monthColumnsList],
				employeeMonthColumns: [{Header:"Employee",accessor:"employeeName", Cell: row => (<div>{employeeIdMap[row.value]}</div>), filterMethod: (filter, row) => employeeIdMap[row[filter.id]].toLowerCase().indexOf(filter.value.toLowerCase())>-1},...monthColumnsList],
				stageObject,
				monthObject,
				stateStageData,
				cityStageData,
				cityMonthData,
				employeeMonthData,
				recieved:true,
				loading:true,
				stateList: stateMap.map(val => {return {value:val, label:val}}),
				stageList: stageMap.map((val,index) => {return {value:index, label:val}}),
				ebissuesList: ebissueMap.map((val,index) => {return {value:index, label:val}}),
				subsidiesList: subsidyMap.map((val,index) => {return {value:index, label:val}}),
				vendorList: ["NA","Boond","Chemitech","SmartRoof","Aryav","Zivah","SDSL","Oakridge","ZunRoof","SunEnergy","Volks Energie","Sirius","Greenon","GeoPower","GM Trading","Alien","Adhunik"].map(val => {return {value:val, label:val}})
			})
		}

		if(data.length > 0) {
			let { stateStageData, cityStageData, stageObject, cityMonthData, monthObject, employeeMonthData } = this.state;
			this.setState({
				viewData:data,
				cityStageData: calculateData(data,"city","count", "stage", cityStageData,stageObject),
				stateStageData: calculateData(data,"state","count", "stage", stateStageData,stageObject),
				cityMonthData: calculateData(data,"city","count", "month", cityMonthData,monthObject),
				employeeMonthData: calculateData(data,"employeeName","count", "month", employeeMonthData,monthObject),
				sizeCount: generateGraph(data),
				loading:false
			})
		}
	}

	handleOnButtonClick = (type,id) => {
		let { viewData, stateStageData, cityStageData, stageObject, cityMonthData, monthObject, employeeMonthData } = this.state;
		switch(id) {
			case "state":
				this.setState({
					stateStageData: calculateData(viewData,id,type,"stage",stateStageData,stageObject),
					state:type
				})
				break;
			case "city":
				this.setState({
					cityStageData: calculateData(viewData,id,type,"stage",cityStageData,stageObject),
					city: type
				})
				break;
			case "cityMonth":
				this.setState({
					cityMonthData: calculateData(viewData,"city",type, "month", cityMonthData,monthObject),
					cityMonth: type
				})
				break;
			case "employeeMonth":
				this.setState({
					employeeMonthData: calculateData(viewData,"employeeName",type, "month", employeeMonthData,monthObject),
					employeeMonth: type
				})
				break;
			default:
				break;
		}
	}

	handleMultiSelectChange = (selectedOption, name) => {
		switch(name) {
			case "stateChange":
				return this.setState({stateChoosen:selectedOption});
			case "subsidyChange":
				return this.setState({subsidyChoosen:selectedOption});
			case "stageChange":
				return this.setState({stageChoosen:selectedOption});
			case "v1Change":
				return this.setState({vendorChoosen:selectedOption});
			case "ebIssueChange":
				return this.setState({ebissueChoosen:selectedOption});
			default:
				return;
		}
	}

	handleDateInputChange = (e) => {
		if(e.target.name==="startDate") {
			this.setState({
				[e.target.name]: e.target.value?new Date(e.target.value):0
			})
		}else{
			this.setState({
				[e.target.name]: e.target.value?new Date(e.target.value):9999999999999
			})
		}
	}

	handleInputChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	applyFilter = () => {
		let { cityStageData, stageObject, stateStageData, city, state, cityMonthData, monthObject, cityMonth, employeeMonthData } = this.state;
		let stateChoosenArray = []
		if(this.state.stateChoosen){
			stateChoosenArray = this.state.stateChoosen.map(val => val.value)
		}


		let subsidyChoosenArray = [];
		if(this.state.subsidyChoosen){
			subsidyChoosenArray = this.state.subsidyChoosen.map(val => val.label)
		}

		let stageChoosenArray = [];
		if(this.state.stageChoosen){
			stageChoosenArray = this.state.stageChoosen.map(val => val.value)
		}

		let vendorChoosenArray = [];
		if(this.state.vendorChoosen){
			vendorChoosenArray = this.state.vendorChoosen.map(val => val.value)
		}

		let ebissueChoosenArray = [];
		if(this.state.ebissueChoosen){
			ebissueChoosenArray = this.state.ebissueChoosen.map(val => val.value)
		}

		let startPercentage = this.state.startPercentage;
		let endPercentage 	= this.state.endPercentage;

		let viewData = this.props.data.map(value => {
			if(checkExist(stateChoosenArray,value.state) && dateRange(value.salesDate,this.state.startDate,this.state.endDate) && percentageRange(value.percentage,startPercentage,endPercentage) && checkArrayExist(ebissueChoosenArray,value.ebIssueAllList) && checkExist(vendorChoosenArray,value.vendor) && checkExist(subsidyChoosenArray,value.subsidy) && (value.stage!=="11" || (value.stage==="11" && stageChoosenArray.indexOf("11")>-1))  && checkExist(stageChoosenArray,value.stage)) {
				return value;
			}
			return false;
		}).filter(val => val?true:false);
		
		this.setState({
			viewData,
			cityStageData: calculateData(viewData, "city", city, "stage", cityStageData, stageObject),
			stateStageData: calculateData(viewData, "state", state, "stage", stateStageData, stageObject),
			cityMonthData: calculateData(viewData,"city",cityMonth, "month", cityMonthData,monthObject),
			employeeMonthData: calculateData(viewData,"employeeName",cityMonth, "month", employeeMonthData,monthObject),
			sizeCount: generateGraph(viewData),
		})
	}

	onOpenModal = (name) => {
    		this.setState({ [name]: true })
	};
	 
	onCloseModal = (name) => {
		this.setState({ [name]: false });
	};

	render() {
		
		let { handleOnButtonClick, state, props, onCloseModal, onOpenModal, handleDateInputChange, handleInputChange, handleMultiSelectChange, applyFilter } = this;
		let { sizeCount, defaultValues, buttonTypes, stateStageColumns, cityStageColumns, stateStageData, cityStageData, loading, open, stateList, stageList, ebissuesList, subsidiesList, vendorList, cityMonthData, cityMonthColumns, employeeMonthColumns, employeeMonthData } = state;

		
		if(this.props.user===null){
			return <Redirect to="/login?redirect_uri=salesanalytics" />;
		}

		if(this.props.user.level<1600) {
			return ( 
				<div>
					<Restrict />
				</div>
			)
		}

		if(this.state.recieved) {
			return (
				<div>
					<Modal open={open} onClose={() => {onCloseModal("open")}} center>
						<div style={styleSheet.commentForm} className="form-group">
							Hi
						</div>
			        </Modal>
			        <div style={styleSheet.divStyle} className="container-fluid filter_deals">
						<Filter
								stateList={stateList}
								stageList={stageList}
								vendorList={vendorList}
								ebissuesList={ebissuesList}
								subsidiesList={subsidiesList}
								handleDateInputChange={handleDateInputChange}
								handleInputChange={handleInputChange}
								handleMultiSelectChange={handleMultiSelectChange}
								applyFilter={applyFilter}
						/>
					</div>
					<div className="container-fluid">
						<SalesAnalyticsView 
							loading = {loading}
							defaultValues={defaultValues}
							buttonTypes = {buttonTypes}
							stateStageColumns = {stateStageColumns}
							cityStageColumns = {cityStageColumns}
							cityMonthColumns = {cityMonthColumns}
							employeeMonthColumns = {employeeMonthColumns}
							employeeMonthData = {employeeMonthData}
							stateStageData = {stateStageData}
							cityStageData = {cityStageData}
							cityMonthData = {cityMonthData}
							handleOnButtonClick = {handleOnButtonClick}
						/>
						<div>
						<PercentageGraph dataGraph={sizeCount.datasets} labels={sizeCount.labels} XLabel = {'Size'} YLabel = {'Count'} title = {'Size Distribution'}/>
					</div>
					</div>
				</div>
			)
		}

		return (
			<div style = {styleSheet.frameOne} className="container-fluid">
				<Loader />
			</div>
		);
	}
}

function calculateData(data,id,type,columnType,tempStageData,stageObject) {
	let tempData = {};
	tempStageData.forEach(val => {
		tempData[val[id].trim()] = {...stageObject};
	});
	console.log(id);
	data.forEach(value => {
		let { employeeName, salesDate, size, stage} = value;
		if(id==="employeeName") {
			if(columnType==="month") {
				let month = dateToMonth(salesDate);
				if(value[id]) {
					value[id].split(",").forEach(val => {
						if(tempData[val]) {
							console.log(value[id])
							tempData[val][`${month}`] += type==="size"?(Math.round(size*100)/value[id].split(',').length)/100:(1/value[id].split(',').length);
						}else{
							console.log(val);
						}
					});
				}
			}
		}else{
			if(tempData[value[id]]) {
				if(columnType==="stage") {
					tempData[value[id]][`${stage}`] += type==="size"?Math.round(size*100)/100:1;
				}else if(columnType==="month") {
					let month = dateToMonth(salesDate);
					tempData[value[id]][`${month}`] += type==="size"?Math.round(size*100)/100:1;
				}
			}
		}
	});
	return Object.keys(tempData).map(key => {
		return {[id]:key,...tempData[key]}
	});
}

function generateGraph(viewData) {
	let data = {};
	viewData.map(val => {
		let size = Math.round(val.size);
		if(size<10) {
			if(data[`${size}`]){
				data[`${size}`] += 1;
			}else{
				data[`${size}`] = 1;
			}
		}else if(size<20) {
			size = '10';
			if(data[`${size}`]){
				data[`${size}`] += 1;
			}else{
				data[`${size}`] = 1;
			}
		}else{
			size = '20';
			if(data[`${size}`]){
				data[`${size}`] += 1;
			}else{
				data[`${size}`] = 1;
			}
		}
	});
	let labels = Object.keys(data);
	let datasets = [
		{
			label: 'Size',
			backgroundColor: '#30a29d',
			borderColor: '#0d5f5b',
			borderWidth: 1,
			hoverBackgroundColor: '#1f6d6a',
			hoverBorderColor: 'rgba(255,99,132,1)',
			data: Object.values(data)
		}
	]
	return {labels, datasets};
}

function mapStateToProps({ user, salesanalyticsdata, mapping }) {
	let { data, loading } = salesanalyticsdata;
	let { stageMap, stateMap, cityMap, subsidyMap, ebissueMap, employeeIdMap } = mapping;
	return {
		user : user,
		data: data,
		loading: loading,
		stageMap, stateMap, cityMap, subsidyMap, ebissueMap, employeeIdMap
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({fetchsalesdata, fetchmapping}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesAnalytics); 