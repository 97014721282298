import { FETCHING_EMAIL_ACCOUNTS_START, FETCHING_EMAIL_ACCOUNTS_SUCCESS, FETCHING_EMAIL_ANALYTICS_DATE, FETCHING_CLIENT_CALL_ANALYTICS_DATE, FETCHING_EMAIL_ANALYTICS_START, FETCHING_EMAIL_ANALYTICS_SUCCESS, FETCHING_EMAIL_STATUS_SUCCESS, FETCHING_CLIENT_CALL_ANALYTICS_SUCCESS } from '../actions/actiontypes'

import { columnsEmailAnalytics, columnsClientAnalytics,columnsEmailAccounts } from '../config/column_fields';

const INIT_STATE = {
	loading:true,
	clientLoading:true,
	tablename:'Loading...',
	columns:columnsEmailAccounts,
	data:[],
	clientColumn:columnsClientAnalytics,
	statusMap:[],
	clientData:[],
	lastDate:{}
}
	

export default function(state = INIT_STATE, action) {
	switch(action.type){
		case FETCHING_EMAIL_ACCOUNTS_START:{
		return {...state,clientLoading:true};
		};

		case FETCHING_EMAIL_ACCOUNTS_SUCCESS:{
			return {...state,data:action.payload,loading:false};
		}

		case FETCHING_EMAIL_STATUS_SUCCESS:{
			return {...state,statusMap:action.payload};
		}

		case FETCHING_EMAIL_ANALYTICS_DATE:{
			return {...state,lastDate:{...state.lastDate,...action.payload}};
		}

		case FETCHING_CLIENT_CALL_ANALYTICS_DATE:{
			return {...state,lastDate:{...state.lastDate,...action.payload}};
		}

		case FETCHING_CLIENT_CALL_ANALYTICS_SUCCESS:{
			return {...state,clientData:action.payload,clientLoading:false};
		}
	}	
	
	return state;
}