import { UPDATE_COMPLAINTS_SUCCESS, UPDATE_COMPLAINTS_START } from './actiontypes';
import { dateChanger } from '../helper/dateChanger';
import { base, db } from '../connection';

export function updateComplaint(ticketId, status, text, internalComment, updatedBy, dueDate, assignedTo, removed, responsibleTeam, obj) {
	console.log(new Date());
  	return (dispatch) => {        
    	dispatch({type:UPDATE_COMPLAINTS_START});
        // setData('/website/complaint/tickets/'+ticketId+'/status',status);
        // setData('/website/complaint/tickets/'+ticketId+'/text',text);
        // setData('/website/complaint/tickets/'+ticketId+'/internalComment',internalComment);
        // setData('/website/complaint/tickets/'+ticketId+'/updatedBy',updatedBy);
        let epoch = (new Date).getTime();
        dueDate = dueDate.split("-");
        dueDate = new Date(dueDate[1]+"-"+dueDate[2]+"-"+dueDate[0]).getTime()+86399000;
        let actionObj = {
            [epoch] : {
                status : status,
                text : text,
                updatedBy : updatedBy,
                updateTime : epoch,
                dueDate:dueDate,
                removed:removed,
                responsibleTeam:responsibleTeam,
                assignedTo:assignedTo
            }
        };
        obj.actions[epoch] = {
            status : status,
                text : text,
                updatedBy : updatedBy,
                updateTime : epoch,
                dueDate:dueDate,
                removed:removed,
                responsibleTeam:responsibleTeam,
                assignedTo:assignedTo,
                internalComment:internalComment
        }
        let complaintObj = {
            quoteId:obj.quoteId,
            phoneNumber : obj.phoneNumber,
            email: obj.email?obj.email:'',
            type:obj.type?obj.type:'',
            subType:obj.subType?obj.subType:'',
            status:status,
            text : text,
            city : obj.city?obj.city:'',
            ticketId : obj.ticketId,
            updatedBy : updatedBy,
            updateTime : epoch,
            createdBy:obj.createdBy?obj.createdBy:'',
            comment:obj.comment?obj.comment:'',
            epoch:obj.epoch?obj.epoch:'',
            actions:obj.actions,
            source:obj.source?obj.source:'',
            assignedTo:assignedTo,
            dueDate:dueDate,
            removed:removed,
            responsibleTeam:responsibleTeam,
            internalComment:internalComment
        };
        setData('website/complaint/tickets/'+ticketId, complaintObj);
        //setData('/website/complaint/tickets/'+ticketId+'/updateTime',epoch);
        fetchNameFromId(updatedBy).then(name => {
            console.log(name);
            //setData('/website/complaint/tickets/'+ticketId+'/updatedByName',name);
            // let updateObj = {
            //     status : status,
            //     text : text,
            //     updatedBy:updatedBy,
            //     updateTime:epoch,
            //     updatedByName : name,
            //     internalComment : internalComment
            // }
            setData('/website/complaint/tickets/'+ticketId+'/updatedByName',name);
            setData('/website/complaint/tickets/'+ticketId+'/actions/'+epoch+'/updatedByName',name);
        })
        .catch(e => {
            console.log(e);
            // let updateObj = {
            //     status : status,
            //     text : text,
            //     updatedBy:updatedBy,
            //     updateTime:epoch,
            //     internalComment:internalComment
            // }
            // setData('/website/complaint/tickets/'+ticketId+'/actions/'+epoch,updateObj);
        });
        console.log("Done");
        //console.log(Object.values(snapshot.val()));
        dispatch({type:UPDATE_COMPLAINTS_SUCCESS});
 	}
}

function setData(path, data){
    db.ref(path).set(data)
    .then((val) => {
        return
    })
    .catch(e => {
        return e;
    })
}

function fetchNameFromId(userId){
    return new Promise((resolve,reject) => {
		db.ref('users/'+userId+'/mUser_Contact').once('value')
        .then(snapshot => {
            let userObj = snapshot.val();
            let fullname = userObj.mContact_FirstName + " " + userObj.mContact_LastName;
            resolve(fullname);
        })
        .catch(e => {
            reject(e);
        })
	});
}
