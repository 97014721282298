import { FETCHING_COMMENTS_SUCCESS, FETCHING_START } from '../actions/actiontypes'

export default function(state = [], action) {
	switch(action.type){
		case FETCHING_START:{
			return [];
		}
		case FETCHING_COMMENTS_SUCCESS:{
			return [...state, ...action.payload];
		}
		default:
			return state;
	}
}