import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { Restrict } from '../components/Restrict';
import Modal from 'react-responsive-modal';
import ReactTable from "react-table";
import Select from 'react-select';
import { fetchAllCenters, addNewCenter, fetchCities, updateCenter, deleteCenter } from '../actions/peTasksCentersAction'


class PeTaskCenters extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            centers: ["Subsidy", "Discom", "Other"],
            openCenterFormModal: false,
            error: false,
            newCenterMap: {},
            centerTypeChosen: [],
            cityChosen: [],
            tableData: [],
            oldCenterMap: {},
            tableDataCopy: []
        }
    }

    componentDidMount() {
        if (this.props.user) {
            if (this.props.user.level >= 1600) {
                let user = this.props.user;
                this.props.fetchAllCenters(user);
                this.props.fetchCities(user);
            }
        }
        let centers = this.state.centers.map((obj) => ({ label: obj, value: obj }));
        this.setState({
            centers
        })
    }

    componentWillReceiveProps(props) {
        this.setState({
            stateToCityMap: props.stateToCityMap || {},
            tableData: props.tableData,
            tableDataCopy: props.tableData
        })
    }

    openCreaterCenterModal = (e, row) => {
        let buttonName = e.target.name;
        if (buttonName == "edit") {
            this.setState({
                newCenterMap: row,
                oldCenterMap: row,
                openCenterFormModal: true,
                edit: true
            })
        } else {
            this.setState({
                openCenterFormModal: true,
                error: false
            })
        }
    }

    closeCreateCenterModal = (e) => {
        this.setState({
            newCenterMap: {},
            openCenterFormModal: false,
            error: false,
            edit: false
        })
    }

    handleMultiSelectChange = (selectedOption, name) => {
        if (name == "stateChosen") {
            let statesArr = selectedOption.map((val) => val.value)
            let citiesInFilter = [];
            statesArr.map((state) => {
                citiesInFilter = citiesInFilter.concat(this.state.stateToCityMap[state])
            })
            this.setState({
                citiesInFilter: citiesInFilter.map((val) => ({ label: val, value: val }))
            })
        }
        this.setState({
            [name]: selectedOption
        });
    }

    selectType = (disabled, placeholder, name, options, value) => {
        return (
            <div className="react-select-payment">
                <Select
                    isSearchable
                    onChange={(e) => this.handleSelectChange(e, name)}
                    placeholder={placeholder}
                    name={name}
                    options={options}
                    value={value}
                    menuPlacement="auto"
                    menuPosition="fixed"
                    isDisabled={disabled}
                // defaultValue={(this.state.edit && this.state.allMapping[name]) ? this.state.allMapping[name].map(val => ({ label: this.state.newPaymentMap[name], value: this.state.newPaymentMap[name] })) : ""}
                />
            </div>
        )
    }

    handleSelectChange = (e, name) => {
        let { value, label } = e
        if (name == "state") {
            let cities = this.state.stateToCityMap[value];
            this.setState({
                cities: cities.map((val) => ({ label: val, value: val }))
            })
        }
        this.setState({
            newCenterMap: {
                ...this.state.newCenterMap,
                [name]: value
            }
        })
    }

    handleInputChange = (e) => {
        let { name, value } = e.target
        this.setState({
            newCenterMap: {
                ...this.state.newCenterMap,
                [name]: value,
            }
        })
    }

    inputType = (placeholder, type, name, value, disabled) => {
        return <input type={type} placeholder={placeholder} className="form-control payment-input" name={name} value={value} onChange={(e) => this.handleInputChange(e)} disabled={disabled} />
    }

    createNewCenter = (e) => {
        e.preventDefault();
        let tableData = this.props.tableData;
        let user = this.props.user.phoneNumber;
        let formData = { ...this.state.newCenterMap };
        formData = this.removingNullKeys(formData);
        if (formData.centerType == "Discom" && !formData.city) {
            alert("City not Selected");
            return;
        }
        if (this.state.edit) {
            let oldFormData = { ...this.state.oldCenterMap };
            this.props.updateCenter(user, oldFormData, formData, tableData);
            this.setState({
                newCenterMap: {},
                openCenterFormModal: false,
                edit: false
            })
        } else {
            if (formData.centerName && formData.centerType && formData.lat && formData.long && formData.state) {
                this.props.addNewCenter(formData, user, tableData);
                this.setState({
                    newCenterMap: {},
                    openCenterFormModal: false,
                    edit: false
                })
            } else {
                this.setState({
                    error: true
                })
            }
        }
    }

    removingNullKeys = (obj) => {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName].length < 1 || obj[propName] == "" || obj[propName] == {}) {
                delete obj[propName];
            }
        }
        return obj
    }

    handleDelete = (e, row) => {
        let { key } = row;
        let user = this.props.user.phoneNumber;
        let { tableDataCopy } = this.state;
        if (this.props.user.level >= 1700) {
            this.props.deleteCenter(key, user, tableDataCopy)
        } else {
            alert("You do not have access to delete a center")
        }
    }

    onApplyFilter = (e) => {
        let stateChosen = this.state.stateChosen.map(val => val.value);
        let cityChosen = this.state.cityChosen.map(val => val.value);
        let centerTypeChosen = this.state.centerTypeChosen.map(val => val.value);
        let tableData = JSON.parse(JSON.stringify(this.state.tableData));
        let filteredTable = tableData.filter(obj => {
            return ((stateChosen.length > 0 ? stateChosen.includes(obj.state) : true) && (cityChosen.length > 0 ? cityChosen.includes(obj.city) : true) && (centerTypeChosen.length > 0 ? centerTypeChosen.includes(obj.centerType) : true))
        })
        this.setState({
            tableDataCopy: filteredTable,
        })
    }

    render() {

        const tableColumns = [
            {
                Header: "State",
                accessor: "state"
            },
            {
                Header: "City",
                accessor: "city"
            },
            {
                Header: "Center Type",
                accessor: "centerType"
            },
            {
                Header: "Center Name",
                accessor: "centerName"
            },
            {
                Header: "Lat",
                accessor: "lat"
            },
            {
                Header: "Long",
                accessor: "long"
            },
            {
                Header: "Edit",
                accessor: '',
                Cell: row => {
                    return (
                        <div>
                            <button className="btn btn-warning" name="edit" onClick={(e) => { this.openCreaterCenterModal(e, row.original) }}>Edit</button>
                            <button style={{ marginLeft: '8px' }} className="btn btn-danger" onClick={(e) => { this.handleDelete(e, row.original) }}>Delete</button>
                        </div>
                    )
                }
            }
        ]

        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            },
            iconShow: {
                float: 'right',
                display: ''
            },
            iconHide: {
                float: 'right',
                display: 'none'
            },
            showError: {
                display: '',
                color: 'red',
            },
            hideError: {
                display: 'none',
            }
        };

        const modalStyles = {
            modal: {
                width: '40%',
                maxWidth: '40%',
                padding: '40px',
                background: '#f7f7f7',
                textAlign: 'center'
            }
        }

        if (this.props.user === null) {
            return <Redirect to={"/login?redirect_uri=petaskcenters"} />;
        }

        if (this.props.user && this.props.user.level < 1600) {
            return (
                <div>
                    <Restrict />
                </div>
            )
        }

        return (
            <div style={{ "marginTop": "50px", backgroundColor: "#f7f7f7" }} className="container-fluid">
                <div style={{ textAlign: "center" }}>
                    <h2 style={{ fontWeight: "bold" }}>PE Tasks Centers</h2>
                </div>
                <div className="row v2-topbar-section">
                    <div className="col-md-2">
                        <label>State</label>
                        {selectCustom(this.state.stateToCityMap ? (Object.keys(this.state.stateToCityMap).map((state) => ({ label: state, value: state }))) : [], "stateChosen", "State", this.handleMultiSelectChange)}
                    </div>
                    <div className="col-md-2">
                        <label>City </label>
                        {selectCustom(this.state.citiesInFilter, "cityChosen", "City", this.handleMultiSelectChange)}
                    </div>
                    <div className="col-md-2">
                        <label>Center Type</label>
                        {selectCustom(this.state.centers, "centerTypeChosen", "Center Type", this.handleMultiSelectChange)}
                    </div>
                    <div className="col-md-2">
                        <button className="btn btn-success v2SubmitButton" onClick={(e) => { this.onApplyFilter() }}>Apply Filter</button>
                    </div>
                </div>
                <div className="btnDiv">
                    <div>
                        <button className="btn btn-success btn-lg" onClick={this.openCreaterCenterModal}>Create New Center</button>
                    </div>
                </div>
                <div style={{ "marginTop": 20 }}>
                    <ReactTable
                        loading={this.state.loading}
                        data={this.state.tableDataCopy}
                        columns={tableColumns}
                        filterable={true}
                        showPagination={true}
                        sortable={true}
                        defaultPageSize={25}
                        style={{ textAlign: "center", backgroundColor: "#fff" }}
                        // defaultSorted={[{ id: "paymentDate", desc: true }]}
                        defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    >
                    </ReactTable>
                </div>
                <Modal open={this.state.openCenterFormModal} onClose={this.closeCreateCenterModal} styles={modalStyles}>
                    <div className="payment-create-form">
                        <form onSubmit={this.createNewCenter} className="form-control" style={{ border: '1px solid yellow', borderRadius: '5px' }}>
                            <h3 style={{ marginTop: '15px', marginBottom: '30px', fontWeight: 'bold' }}>Create New Center</h3>
                            <p style={this.state.error ? style.showError : style.hideError}>Required fields Missing</p>
                            <div className="form-group">
                                <label className="newPaymentLabel">Select Center Type</label>
                                {this.selectType(false, "Select Center Type", 'centerType', this.state.centers, { label: this.state.newCenterMap.centerType, value: this.state.newCenterMap.centerType })}
                            </div>
                            {
                                this.state.newCenterMap.centerType ?
                                    <div>
                                        <div className="form-group">
                                            <label className="newPaymentLabel">Select State</label>
                                            {this.selectType(false, "Select City", 'state', this.state.stateToCityMap ? (Object.keys(this.state.stateToCityMap).map((val) => ({ label: val, value: val }))) : [], { label: this.state.newCenterMap.state, value: this.state.newCenterMap.city })}
                                        </div>
                                        {this.state.newCenterMap.centerType == "Discom" ?
                                            <div className="form-group">
                                                <label className="newPaymentLabel">Select City</label>
                                                {this.selectType(false, "Select City", 'city', this.state.cities, { label: this.state.newCenterMap.city, value: this.state.newCenterMap.city })}
                                            </div> : ""
                                        }
                                        <div className="form-group">
                                            <label className="newPaymentLabel">Center Name</label>
                                            {this.inputType("Center Name", "text", 'centerName', this.state.newCenterMap.centerName || "", (false))}
                                        </div>
                                        <div className="form-group">
                                            <label className="newPaymentLabel">Lat</label>
                                            {this.inputType("Lat", "number", 'lat', this.state.newCenterMap.lat || "", (false))}
                                        </div>
                                        <div className="form-group">
                                            <label className="newPaymentLabel">Long</label>
                                            {this.inputType("Long", "number", 'long', this.state.newCenterMap.long || "", (false))}
                                        </div>
                                        <div className="new-payment-submit">
                                            <button className="btn btn-success" type="submit" value="Submit">Submit</button>
                                        </div>
                                    </div>
                                    : ""
                            }
                        </form>
                    </div>
                </Modal>
            </div>
        )
    }
}

const selectCustom = (options, name, placeholder, handleMultiSelectChange, defaultValue) => {
    const changeHandle = (value) => { handleMultiSelectChange(value, name) }
    return (
        <Select
            isMulti
            isSearchable
            onChange={(e) => { changeHandle(e) }}
            placeholder={placeholder}
            name={name}
            options={options}
            defaultValue={defaultValue}
        />
    )
}

function mapStateToProps(state) {
    let { stateToCityMap, tableData } = state.peCentersData;
    return {
        user: state.user,
        stateToCityMap,
        tableData
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchAllCenters, addNewCenter, fetchCities, updateCenter, deleteCenter }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PeTaskCenters);