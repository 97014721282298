import { FETCHING_INCENTIVE_START, FETCHING_INCENTIVE_SUCCESS, FETCHED_QUOTEIDLIST, INCENTIVE_UPADTE_START, INCENTIVE_UPADTE_SUCCESS, INCENTIVE_UPADTE_FAILED, FETCHING_INCENTIVE_DETAILS } from '../actions/actiontypes'

const INIT_STATE = {
	loading: true,
	data: [],
	quoteIdList:{},
	quoteIdSelected:"",
	cityFound:"",
	teamFound:"",
	updateStatus: {
		status:"",
		text:""
	}
}



export default function(state = INIT_STATE, action) {
	switch(action.type){
		case FETCHING_INCENTIVE_START: 
			return {...state, loading: true}
		case FETCHING_INCENTIVE_SUCCESS: 
			return {...state, loading: false, data:action.payload}
		case FETCHED_QUOTEIDLIST: 
			return {...state, quoteIdList: action.payload}
		case FETCHING_INCENTIVE_DETAILS: 
			return {...state, ...action.payload}
		case INCENTIVE_UPADTE_START: 
			return {...state, updateStatus:{...action.payload}}
		case INCENTIVE_UPADTE_SUCCESS: 
			return {...state, updateStatus:{...action.payload}}
		case INCENTIVE_UPADTE_FAILED: 
			return {...state, updateStatus:{...action.payload}}
		default:
			return state;
	}
}