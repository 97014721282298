import { FETCH_SALESANALYTICS_SUCCESS } from './actiontypes';
import axios from 'axios';

import { base, db } from '../connection';

export function fetchSalesAnalytics(startEpoch, endEpoch ,selectedMonth, stateList) {
    console.log("start");
    return (dispatch) => {
        fetchData(startEpoch, endEpoch, selectedMonth, stateList).then(data => {
            console.log(data);
            dispatch({ type: FETCH_SALESANALYTICS_SUCCESS, payload:{...data} });
        })
    }
}


async function fetchData(startEpoch, endEpoch, selectedMonth, stateList) {
    try{
        let formdata = new URLSearchParams();
        formdata.append('key', 'Yo3B0SJ6nFy7wRl');
        formdata.append('startEpoch', parseInt(startEpoch));
        formdata.append('endEpoch', parseInt(endEpoch));
        formdata.append('selectedMonth', parseInt(selectedMonth));
        formdata.append('stateList', stateList);
        console.log(stateList);
        let data = await axios.post("https://operations.zunroof.com/api/fetchsalesanalytics", formdata, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });
        return data?data.data:{};
    }
    catch(e){
        console.log("error", e);
    }
}


export function setTarget(month, state, target, type){
    return (dispatch) => {
        updateTarget(month, state, target, type).then(data => {
            console.log(data);
        })
    }
}

async function updateTarget(month, state, target, type){
    if(month && state && target && type){
        await db.ref(`website/salesAnalytics/monthWiseTarget/${month}/${type}/${state}`).set(target);
        console.log("Target Updated");
    }
    else{
        alert("Some error occured");
    }
}