import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

import { percentagepaymentgraph } from '../actions/graphActions/percentagepaymentgraph';
import { outstandingordersgraph } from '../actions/graphActions/outstandingordersgraph';
import { fetchmapping } from '../actions/fetchmapping';

// HELPER FUNCTION
import { barGraphPercentageStage, barGraphSalesOrder, barGraphPercentageResponsible } from '../helper/graphHelper';
import { changeToViewDate } from '../helper/dateChanger';

// COMPONENTS
import { PercentageGraph, LineGraph } from '../components/PercentageGraph';
import { FilterComponent } from '../components/FilterComponent';
import { Restrict } from '../components/Restrict';

class Graph extends Component {

	constructor(props) {
	  	super(props);
	
	  	this.state = {
		  	recieved:false,
			dataPercentageGraph:[],
			salesOrderGraph:[],
			stagesSelected:[],
			paymentHistoryGraph:{
				labels:[],
				data:[]
			},
			paymentWeekHistoryGraph:{
				labels:[],
				data:[]
			},
			outstandingorders:{
					labels:[],
					data:[]
				}
		}
		this.labelsPercentage 	= ["0-10%","10-20%","20-30%","30-40%","40-50%","50-60%","60-70%","70-80%","80-90%","90-100%","Final Price Missing"];
		this.labelsSalesDate	= ["0-45","45-90","90-135","135-180","180-225","225-270",">270"];
		//BINDING FUNCTIONS
		this.handleStateChange 	= this.handleStateChange.bind(this);
		this.applyFilter 		= this.applyFilter.bind(this);
	}

	componentDidMount() {
		console.log("DID MOUNT")
		if(this.props.user){
			if(this.props.user.level>=1900){
				this.props.fetchmapping();
				this.props.outstandingordersgraph();
				this.props.percentagepaymentgraph();
			}
		}
	}

	componentWillReceiveProps(props) {
		const { percentagedata, paymenthistory, paymentweekhistory, employeemap, outstandingorders } = props;
		console.log(outstandingorders);
		if(percentagedata) {
			let arrayNew = barGraphPercentageStage(percentagedata,this.state.stagesSelected,this.props.stages);
			let salesOrderGraph = barGraphSalesOrder(percentagedata,this.state.stagesSelected,this.props.stages)
			this.setState({
				dataPercentageGraph:[...[],...arrayNew],
				salesOrderGraph:[...[],...salesOrderGraph]
			})
		}
		if(paymenthistory) {
			let sum = 0;
			let dataGraph = {};
			for(let i=0;i<15;i++){
				sum+=paymenthistory[i];
			}
			dataGraph["0"] = Math.round(sum/15);
			for(let i=15;i<paymenthistory.length;i++) {
				sum = (sum-paymenthistory[i-15]+paymenthistory[i]);
				dataGraph[""+(i-14)] = Math.round(sum/15);
			}
			let labels = Object.keys(dataGraph).map((val,index) => changeToViewDate(new Date("06/01/2018").setDate(new Date("06/01/2018").getDate()+index)))
			// console.log(paymenthistory,dataGraph);
			this.setState({
				paymentHistoryGraph:{
					labels:labels,
					data:Object.values(dataGraph)
				}
			})
		}
		//paymentWeekHistoricalData
		if(paymentweekhistory) {
			let labels 	= Object.keys(paymentweekhistory.data).map(val=> {let fromDate= new Date("08/01/2018"); let toDate= new Date("08/01/2018"); return (changeToViewDate(fromDate.setDate(fromDate.getDate()+((val-1)*7)))+" - "+changeToViewDate(toDate.setDate(toDate.getDate()+((val))*7)))});
			let data   	= barGraphPercentageResponsible(paymentweekhistory.data,paymentweekhistory.responsibleMap,employeemap);
			// console.log(paymenthistory,dataGraph);
			this.setState({
				paymentWeekHistoryGraph:{
					labels:labels,
					data:[...[],...data]
				}
			})
		}

		//outstandingorders
		if(outstandingorders) {
			let labels = Object.keys(outstandingorders).map(val => {
				let dateString = val.toString(); 
				let year = dateString.slice(0,4); let month = dateString.slice(4,6); let date = dateString.slice(6,8);
				return changeToViewDate(new Date(""+month+"/"+date+"/"+year).getTime());
			});
			console.log("outstandingorders",outstandingorders);
			let dataPoint1 = [];
			let dataPoint2 = [];
			Object.keys(outstandingorders).forEach(key => {
				let actualCount		= 0;
				let expectedCount	= 0;
				let stageData = outstandingorders[""+key];
				Object.keys(stageData).forEach(val => {
					if(parseInt(val) < 5){
						actualCount 	+= stageData[""+val].actualCount;
						expectedCount	+= stageData[""+val].expectedCount;
					}
				});
				dataPoint1.push(actualCount);
				dataPoint2.push(Math.round(expectedCount));
			});
			let finalDataPoints = [];
			finalDataPoints.push(dataPoint1);
			finalDataPoints.push(dataPoint2);
			console.log("FINAL DATA POINTS : ",finalDataPoints);
			this.setState({
				outstandingorders:{
					labels:labels,
					data:[...[],...finalDataPoints]
				}
			})
		}
	}

	// FILTER BY STAGE
	handleStateChange = (selectedOption) => {
		this.setState({ stagesSelected:selectedOption })
	}

	applyFilter = (type) => {
		const { percentagedata } = this.props;
		if(percentagedata) {
			switch(type) {
				case 1:
					let arrayNew = barGraphPercentageStage(percentagedata,this.state.stagesSelected,this.props.stages);
					this.setState({
						dataPercentageGraph:[...[],...arrayNew]
					})
					break;
				case 2:
					let salesOrderGraph = barGraphSalesOrder(percentagedata,this.state.stagesSelected,this.props.stages)
					this.setState({
						salesOrderGraph:[...[],...salesOrderGraph]
					})
					break;
			}
		}
	}


	render() {

		if(this.props.user===null){
			return <Redirect to={"/login?redirect_uri=graphs"} />;
		}

		if(this.props.user && this.props.user.level<1900) {
			return ( 
				<div>
					<Restrict />
				</div>
			)
		}

		if(this.props.percentagedata) {
			return (
				<div style={{"marginTop":50}} className="container-fluid">
					<div>
						{/* PERCENTAGE GRAPH COUNTS */}
						<div className="row" style={{display:"flex"}}>
							<FilterComponent isMulti={true} isSearchable={false} placeholder={"Choose Stage"} value={this.state.stagesSelected} handleStateChange={this.handleStateChange} stateMulti={this.props.stages} />
							<div className="col-md-2 col-6 filter_box filter_box_button">
								<div className="filter_selector__drop_down_label1" style={{marginBottom:'6%'}}></div>
								<button className="btn btn-primary applyButton" style={{backgroundColor:'#00796b',borderColor:'#00796b'}} onClick={() => this.applyFilter(1)}>Apply Filter</button>
							</div>
						</div>
						<PercentageGraph dataGraph={this.state.dataPercentageGraph} labels={this.labelsPercentage} XLabel = {'Percentage Bucket'} YLabel = {'Quote ID\'s Count'} title = {'Payment'}/>
					</div>
					<div>
						{/* AGE OF ORDERS */}
						<div className="row" style={{display:"flex"}}>
							<FilterComponent isMulti={true} isSearchable={false} placeholder={"Choose Stage"} value={this.state.stagesSelected} handleStateChange={this.handleStateChange} stateMulti={this.props.stages} />
							<div className="col-md-2 col-6 filter_box filter_box_button">
								<div className="filter_selector__drop_down_label1" style={{marginBottom:'6%'}}></div>
								<button className="btn btn-primary applyButton" style={{backgroundColor:'#00796b',borderColor:'#00796b'}} onClick={() => this.applyFilter(2)}>Apply Filter</button>
							</div>
						</div>
						<PercentageGraph dataGraph={this.state.salesOrderGraph} labels={this.labelsSalesDate} XLabel = {'Sales Date Bucket'} YLabel = {'Quote ID\'s Count'} title = {'Age of orders'}/>
					</div>
					<div>
						{/* PAYMENT MOVING AVERAGE */}
						<LineGraph dataGraph={[this.state.paymentHistoryGraph.data]} dataLabels={["Payment Moving Average"]} labels={this.state.paymentHistoryGraph.labels} XLabel = {''} YLabel = {'Payments Average Value'} title = {'Payment Moving Average'}/>
					</div>
					<div>
						{/*  */}
						<PercentageGraph dataGraph={this.state.paymentWeekHistoryGraph.data} labels={this.state.paymentWeekHistoryGraph.labels} XLabel = {'Payment Week Bucket'} YLabel = {'Quote ID\'s Count'} title = {'PAYMENT WEEK RESPONSIBLE'}/>
					</div>
					<div>
						{/* OUTSTANDING ORDERS GRAPH */}
						<LineGraph dataGraph={this.state.outstandingorders.data} dataLabels={["Actual","Expected"]} labels={this.state.outstandingorders.labels} XLabel = {'Date'} YLabel = {'Quote ID\'s Count'} title = {'Outstanding Orders Count'}/>
					</div>
				</div>
			);
		}

		return (
			<div style={{"marginTop":100}}>
				Loading...
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		user 					: state.user,
		percentagedata 			: state.graphdata.percentageGraph,
		paymenthistory			: state.graphdata.paymentHistory,
		paymentweekhistory		: state.graphdata.paymentWeekHistory,
		outstandingorders		: state.graphdata.outstandingOrdersGraph,
		stages					: state.mapping.stageMap,
		employeemap				: state.mapping.employeeMap
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({percentagepaymentgraph, fetchmapping, outstandingordersgraph }, dispatch);
};

export default connect(mapStateToProps,mapDispatchToProps)(Graph);