import { FETCHING_PM_TASKS_START, FETCHING_PM_TASKS_SUCCESS, CREATING_PM_TASKS_SUCCESS } from './actiontypes';

import axios from 'axios';
import { db, base } from '../connection';
import { dotToEmail, emailToDot } from '../helper/helper';

export function fetchPmTasks(id) {
	return (dispatch) => {
		dispatch({type:FETCHING_PM_TASKS_START,payload:[]})
		let formdata = new URLSearchParams();
		formdata.append('key', 'g6muc4cFoPVVZvs');
		// axios.post("http://localhost:3002/api/pmTasks/getPmTasks", formdata, {
		axios.post("https://operations.zunroof.com/api/pmTasks/getPmTasks", formdata, {
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
		})
		.then((data) => {
				dispatch({type:FETCHING_PM_TASKS_SUCCESS,payload:data.data})
		})

    // Static Testing
    // let data = {
    //   "1605126426794_OCT200391" :{ quoteId: 'OCT200391',
    //   stage: 'P1',
    //   category: 'subsidy',
    //   task: 'Apply feasibility',
    //   state: 'Gujarat',
    //   dueDays: 3,
    //   active: 1,
    //   creationDate: 1605126426794,
    //   dueDate: '1605385626794' },
    //   "1605126426795_NOV200008" :{ quoteId: 'NOV200008',
    //   stage: 'P1',
    //   category: 'subsidy',
    //   task: 'Apply feasibility',
    //   state: 'Gujarat',
    //   dueDays: 3,
    //   active: 1,
    //   creationDate: 1605126426795,
    //   dueDate: '1605385626795',
    //   comments : [{
		// 		timestamp : Date.now(),
    //     commentData : "Test Comment",
    //     commentedByName : "Naman",
    //     commentedByUser : "+918076588818"
    //   },{
		// 		timestamp : Date.now(),
    //     commentData : "Test",
    //     commentedByName : "Naman",
    //     commentedByUser : "+918076588818"
    //   }]
    // }
    // }
    // dispatch({type:FETCHING_PM_TASKS_SUCCESS,payload:{data}})
	}
}

export function editTask(taskId, key, value) {
	return (dispatch) => {
		if (taskId && key) {
			let formdata = new URLSearchParams();
			formdata.append('key', 'g6muc4cFoPVVZvs');
			formdata.append('taskId', taskId);
			formdata.append('variableName', key);
			formdata.append('value', value);
			// axios.post("http://localhost:3002/api/pmTasks/task/edit", formdata, {
			axios.post("https://operations.zunroof.com/api/pmTasks/task/edit", formdata, {
					headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			})
			.then((data) => {
				console.log(data.data);
			})
		}
		else{
			alert("Some error occured");
		}
	}
}

export function addComment(taskId, comment, userId){
	return (dispatch) => {
		if(taskId && comment && userId){
			let formdata = new URLSearchParams();
			formdata.append('key', 'g6muc4cFoPVVZvs');
			formdata.append('taskId', taskId);
			formdata.append('comment', comment);
			formdata.append('userId', userId);
			// axios.post("http://localhost:3002/api/pmTasks/comment/add", formdata, {
			axios.post("https://operations.zunroof.com/api/pmTasks/comment/add", formdata, {
					headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			})
			.then((data) => {
				console.log(data.data.finalData);
			})
		}
		else{
			alert("Some error occured");
		}
	}
}

export function createTask(createTaskData,dueDates,allData){
	return (dispatch) => {
		createNewTask(createTaskData,dueDates,allData).then((res) => {
			if(res){
			dispatch({type:CREATING_PM_TASKS_SUCCESS,payload:res})
			}
		})
	}
}

async function createNewTask(createTaskData,dueDates,allData){
	let siteId = (await db.ref(`/quotestaken/${createTaskData.quoteId}/siteId`).once("value")).val();
	let zunPrime = (await db.ref(`/delivery/webPortal/sites/${siteId}/zunPrime`).once("value")).val();
	createTaskData['dueDays'] = zunPrime && dueDates?dueDates.prime:dueDates.nonPrime
	let taskSaved = await axios.post("https://operations.zunroof.com/api/pmTasks/task/add", createTaskData)
	console.log('parduman11',taskSaved)
	let date = new Date();
	createTaskData = {
		...createTaskData,
		active: 1,
		creationDate: date.getTime(),
		clientName: '-',
		dueDate: createTaskData.dueDate?new Date(date.addDays(createTaskData.dueDate)).getTime() : new Date().getTime(),
		responsibleEmail: '-',
		responsibleId: "-",
		responsibleName: "-",
		state: "-",
		closedOn: ''
	}
	allData.data['0']=createTaskData || {}
	return allData
}