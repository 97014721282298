import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';
import { changeToViewDateTime, changeToInputDate, changeToViewDate } from '../helper/dateChanger';
import { Restrict } from '../components/Restrict';
import { columnsOutbound } from '../config/column_fields';
import Modal from 'react-responsive-modal';
import { fetchOutboundCalldata } from '../actions/fetchOutboundCalldata';
import { callClient } from '../actions/callClient';
class OutboundCall extends Component {


    constructor(props) {
        super(props);
        this.reactTable = React.createRef();
        this.state = {
            showColumns: columnsOutbound,
            callData: [],
            viewData: [],
            initState: true,
            agentOptions: [],
            agentChoosen: [],
            startDate: '',
            endDate: '',
            startTime: '00:00',
            endTime: '23:59'
        };
    }

    componentDidMount() {
        console.log(this.props.user);
        if (this.props.user) {
            if (this.props.user.level >= 1600) {
                console.log("Component Did Mount");
                this.props.fetchOutboundCalldata();
            }
        }
    }


    componentWillReceiveProps(props) {
        //console.log("Component will receive props");
        console.log(props);
        if (props.outboundCallData) {
            console.log(props.outboundCallData);
            this.setState({
                callData: props.outboundCallData,
                viewData: props.outboundCallData
            });

        }
        if (this.state.initState) {
            this.setState({
                initState: false
            });
            setInterval(() => this.props.fetchOutboundCalldata(), 20000);
        }
    }

    handleMultiSelectChange = (selectedOption, name) => {
        console.log(selectedOption, name);
        this.setState({
            [name]: selectedOption
        });
    }

    handleInputChange = (e) => {
        console.log(e);
        console.log(e.target.name);
        console.log(e.target.value);
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    applyFilter = (e) => {
        console.log("APPLY FILTER");
        console.log(this.state.statusChoosen);
        console.log(this.state.startTime);
        let callData = this.state.callData;
        let startTime = this.state.startTime ? this.state.startTime : '00:00';
        let endTime = this.state.endTime ? this.state.endTime : '23:59';
        let agentArray = this.state.agentChoosen.map(val => val.value);
        let startDate = this.state.startDate ? new Date(this.state.startDate + " " + startTime + ":00").getTime() : 0;
        let endDate = this.state.endDate ? new Date(this.state.endDate + " " + endTime + ":59").getTime() : 9999999999999;
        //endDate = endDate + 86400000;
        let newData = callData.filter(obj => {
            return (new Date(obj.date).getTime() >= startDate && new Date(obj.date).getTime() <= endDate && (agentArray.length > 0 ? agentArray.includes(obj.agentName) : true));
        });
        this.setState(oldState => ({
            viewData: newData
        }))
    }

    makeCall = (number) => {
        console.log(number);
        this.props.callClient(number, this.props.user.phoneNumber);
    }


    render() {
        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            },
            iconShow: {
                float: 'right',
                display: ''
            },
            iconHide: {
                float: 'right',
                display: 'none'
            }
        };
        if (this.props.user === null) {
            return <Redirect to={"/login?redirect_uri=outbound"} />;
        }

        if (this.props.user && this.props.user.level < 1600) {
            return (
                <div>
                    <Restrict />
                </div>
            )
        }

        return (
            <div style={{ "marginTop": 50 }} className="container-fluid">
                {/* <div style={{ "marginBottom": "10px" }} className="row">
                    <div className="col-2" style={{ padding: '0px 15px' }}>
                        Start Date:
							<input
                            style={{ width: 203, borderRadius: 5, padding: '8px 8px' }}
                            type='date'
                            name='startDate'
                            onChange={this.handleInputChange}
                            value={this.state.startDate}
                        />
                    </div>
                    <div className="col-2" style={{ padding: '0px 15px' }}>
                        Start Time:
							<input
                            style={{ width: 203, borderRadius: 5, padding: '8px 8px' }}
                            type='time'
                            name='startTime'
                            onChange={this.handleInputChange}
                            value={this.state.startTime}
                        />
                    </div>
                    <div className="col-2" style={{ padding: '0px 10px' }}>
                        End Date:
							<input
                            style={{ width: 203, borderRadius: 5, padding: '8px 8px' }}
                            type='date'
                            name='endDate'
                            onChange={this.handleInputChange}
                            value={this.state.endDate}
                        />
                    </div>
                    <div className="col-2" style={{ padding: '0px 10px' }}>
                        End Time:
							<input
                            style={{ width: 203, borderRadius: 5, padding: '8px 8px' }}
                            type='time'
                            name='endTime'
                            onChange={this.handleInputChange}
                            value={this.state.endTime}
                        />
                    </div>
                    <div className="col-md-2 col-6">
                        {selectCustom(this.state.agentOptions, "agentChoosen", "Choose agent", this.handleMultiSelectChange)}
                    </div>
                    <div className="col-md-2 filter_box filter_box_button">
                        <button onClick={this.applyFilter} className="btn btn-success" style={{ "marginTop": 10 }}>Apply Filter</button>
                    </div>
                </div> */}
                <ReactTable
                    filterable={true}
                    showPagination={true}
                    sortable={true}
                    data={this.state.viewData}
                    columns={[...this.state.showColumns,{
                        Header: 'Customer No',
                        accessor: 'caller',
                        Cell : row => {
                            return (
                                <button className="btn btn-default" onClick={() => this.makeCall(row.original.caller)}>{row.original.caller}</button>
                            )
                        }
                    }]}
                    //defaultSorted={[{ id: "date", desc: true }]}
                    style={{ height: "90vh", textAlign: 'center' }}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={this.state.loading}
                />
            </div>
        );


    }
}

const selectCustom = (options, name, placeholder, handleMultiSelectChange, defaultValue) => {
    const changeHandle = (value) => { handleMultiSelectChange(value, name) }
    return (
        <div className="">
            <Select
                isMulti
                isSearchable
                onChange={(e) => { changeHandle(e) }}
                placeholder={placeholder}
                name={name}
                options={options}
                defaultValue={defaultValue}
            />
        </div>
    )
}


function mapStateToProps(state) {
    return {
        user: state.user,
        outboundCallData: state.outboundcall.data
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchOutboundCalldata, callClient }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(OutboundCall);