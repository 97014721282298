import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect, Link } from 'react-router-dom';
import ReactTable from "react-table";
import Modal from 'react-responsive-modal';
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import { fetchsalesdata } from '../actions/fetchsalesdata';
import { fetchmapping } from '../actions/fetchmapping';

import { setcitydata } from '../actions/setcitydata';
import { updateDVariable } from '../actions/setDVariable';

import { columnsSalesAdmin } from '../config/column_fields';
import { columnsSalesAdminDownload } from '../config/column_fields';
import { FilterView } from '../components/FilterView';
import { SalesViewCheckBox } from '../components/SalesViewCheckBox';
import { Restrict } from '../components/Restrict';
import {  changeToViewDate } from '../helper/dateChanger';
import { filterClone } from '../helper/filterData';

export class SalesAdmin extends Component {
	constructor(props) {
	  super(props);
		this.csvLink = React.createRef();
	  this.state = {
	  	loading:true,
	  	columnStatus:false,
	  	data:[],
	  	columns:[],
	  	stateFilter: [],
		subsidyFilter: [],
		stageFilter: [],
	  	selectCheckBox:{},
	  	singleEditStatus:{},
	  	defaultData:[],
	  	startDate: 0,
	  	endDate: 9999999999999,
	  	startPercentage: -100,
	  	choosenColumns: window.localStorage.getItem('salesAdminColumns')?JSON.parse(window.localStorage.getItem('salesAdminColumns')):[],
	  	endPercentage: 120,
	  	salesApprovedStatus_D:0,
	  	currentView:0,
	  	currentViewButton:"New",
	  	openModal:false,
		  columnCheckBoxStatus:{},
		  downloadData:[]
	  };
	  this.defaultColumn = columnsSalesAdmin.map(val => {
	  		switch(parseInt(val.type)) {
	  			case 1:
	  				return {...val, Cell: row => { return this.state.singleEditStatus[row.index]&&this.state.singleEditStatus[row.index][row.column.id]?
	  					this.inputDiv(row):this.deafultViewDiv(row) }
	  				}
	  			case 2:
	  				return {
	  					...val,
	  					Cell: row => { 
	  						return this.state.singleEditStatus[row.index]
		  						&& this.state.singleEditStatus[row.index][row.column.id]?
		  						this.selectInputDiv(row,{"-1":"Not Available"}):this.deafultViewDiv(row);
	  						}
	  				}
	  			case 4:
	  				return {...val, Cell: row => { return (<button onClick={() => {this.handleOnApprovedClick(row.original,row.value)}} className={row.value?"btn btn-warning":"btn btn-success"}>{row.value?"Disapprove":"Approve"}</button>) }
	  				}
	  			default:
	  				return {...val}
	  		}
	  });
	}

	componentWillMount() {
		let { user, fetchsalesdata, fetchmapping } = this.props;
		if(user?user.level >= 1900:false) {
			let preColumns = columnsSalesAdmin.map((val,index) => {
				switch(parseInt(val.type)) {
		  			case 1:
		  				return {...val, Cell: row => { return this.state.singleEditStatus[row.index]&&this.state.singleEditStatus[row.index][row.column.id]?
		  					this.inputDiv(row):this.deafultViewDiv(row) }
		  				}
		  			case 2:
		  				return {
		  					...val,
		  					Cell: row => { 
		  						return this.state.singleEditStatus[row.index]
			  						&& this.state.singleEditStatus[row.index][row.column.id]?
			  						this.selectInputDiv(row,{"-1":"Not Available"}):this.deafultViewDiv(row);
		  						}
		  				}
		  			case 3:
		  				return {...val,
		  					Cell: row => { return (<div>
							          <Link to = {"/quoteid/"+row.original.quoteId} onClick={(event) => {event.preventDefault(); window.open(window.location.origin+"/quoteid/"+row.original.quoteId);}}>{row.value}</Link>
							        </div>) }
		  				}
		  			case 4:
		  				return {...val, Cell: row => { return (<button onClick={() => {this.handleOnApprovedClick(row.original,row.value)}} className={row.value?"btn btn-warning":"btn btn-success"}>{row.value?"Disapprove":"Approve"}</button>) }
		  				}
		  			case 5:
		  				return {...val, Cell: row => { return this.state.singleEditStatus[row.index]&&this.state.singleEditStatus[row.index][row.column.id]?
		  					this.inputNumberDiv(row):this.deafultViewDiv(row) }
		  				}
		  			default:
						let newValue = {...val};
						if(newValue.accessor==="v1"||newValue.accessor==="v2"||newValue.accessor==="stage") {
							newValue.show = false;
						}
		  				return {...newValue}
		  		}
			});
			let columns = [...[{
				Header: row => {return(
			        <div>
			        	<input type="checkbox" onChange={(e) => {this.handleBulkSelectOnChange(e,row.data)}}/>
			        </div>
			      )},
				accessor: "select",
				width	: 50,
				sortable: false,
				filterable: false,
				showDefault:true,
				Cell: row => {
					let { selectCheckBox } = this.state;
					let { quoteId } = row.original;
					return(
				        <div>
				        	<input type="checkbox" checked={selectCheckBox[quoteId]?true:false} onChange={(e) => {this.handleSelectOnChange(quoteId)}}/>
				        </div>
			      	)
				}, 
			}],...preColumns];
			this.setState({columns, defaultColumns:columns})
			fetchsalesdata("sales");
			fetchmapping();
		}
	}

	componentWillReceiveProps({user, loading, data, employeeIdMap, stageMap, stateMap, subsidyMap, ebissueMap, financingMap, notificationMap, employeeMap, cityMap }) {
		if(stageMap&&stateMap&&subsidyMap&&ebissueMap&&financingMap&&notificationMap&&employeeMap&&cityMap&&employeeIdMap&&!this.state.columnStatus) {
			let changeColumn = this.state.columns.map((value,index) => {
				let val = {...value};
				if(this.state.choosenColumns[index]) {
					let { show } = this.state.choosenColumns[index];
					if(show===false||show===true) {
						val.show = show;
					}else{
						if(val.show!==true&&val.show!==false) {
							val.show = true;
						}
					}					
				}
				if(parseInt(val.type)===2) {
					switch(val.accessor) {
						case "state":
							return {
								...val,
								Cell: row => { 
									let stateMapObject = {};
									stateMap.forEach(val => { stateMapObject[val]=val })
	  							return this.state.singleEditStatus[row.index]
		  							&& this.state.singleEditStatus[row.index][row.column.id]?
		  							this.selectInputDiv(row,stateMapObject):this.deafultViewDiv(row);
	  							}
							}
						case "stage":
							return {
								...val,
								Cell: row => {
	  							return this.state.singleEditStatus[row.index]
		  							&& this.state.singleEditStatus[row.index][row.column.id]?
		  							this.selectInputDiv(row,stageMap):this.deafultViewDiv(row);
	  							}
							}
						case "city":
							return {
								...val,
								Cell: row => {
	  							return this.state.singleEditStatus[row.index]
		  							&& this.state.singleEditStatus[row.index][row.column.id]?
		  							this.selectInputDiv(row,cityMap):this.deafultViewDiv(row);
	  							}
							}
						case "systemType":
							return {
								...val,
								Cell: row => {
	  							return this.state.singleEditStatus[row.index]
		  							&& this.state.singleEditStatus[row.index][row.column.id]?
		  							this.selectInputDiv(row,{"Grid-tied": "Grid-tied","Hybrid": "Hybrid","Off-grid": "Off-grid"}):this.deafultViewDiv(row);
	  							}
							}
						case "employeeId":
							return {
								...val,
								Cell: row => {
	  							return this.state.singleEditStatus[row.index]
		  							&& this.state.singleEditStatus[row.index][row.column.id]?
		  							this.selectMultiInputDiv(row,employeeIdMap):this.deafultViewDiv(row);
	  							},
	  							filterMethod: (filter, row) => {
	  								let { value} = filter;
	  								let { employeeId } = row;
	  								let name = "";
	  								if(employeeId){
	  									employeeId.split(",").forEach(val => {
		  									name += employeeIdMap[val];
		  								})
	  									return name.toLowerCase().indexOf(value.toLowerCase())>-1;
		  							}else{
		  								if(value.toLowerCase()==="na") {
		  									return true;
		  								}
		  							}
		  							return false;
	  							}
							}
						case "revenueType":
							return {
								...val,
								Cell: row => {
	  							return this.state.singleEditStatus[row.index]
		  							&& this.state.singleEditStatus[row.index][row.column.id]?
		  							this.selectInputDiv(row,{R1:'R1',R2:'R2',R3:'R3',R4:'R4',R5:'R5',R6:'R6'}):this.deafultViewDiv(row);
	  							}
							}
						case "elevationType":
							return {
								...val,
								Cell: row => {
	  							return this.state.singleEditStatus[row.index]
		  							&& this.state.singleEditStatus[row.index][row.column.id]?
		  							this.selectInputDiv(row,{"Normal": "Normal","Elevated": "Elevated"}):this.deafultViewDiv(row);
	  							}
							}
						case "subsidy":
							return {
								...val,
								Cell: row => {
	  							return this.state.singleEditStatus[row.index]
		  							&& this.state.singleEditStatus[row.index][row.column.id]?
		  							this.selectInputDiv(row,{"No Subsidy":"No Subsidy","Upfront":"Upfront","Cashback":"Cashback"}):this.deafultViewDiv(row);
	  							}
							}
						default: 
							return {...val}
					}
				}
				return {...val};
			});
			this.setState({
				columns: changeColumn,
				defaultColumns: changeColumn,
				columnStatus: true
			})
		}
		this.setState({
			loading, data,defaultData:data
		},() => {this.applyFilter()})
	}

	deafultViewDiv = (row) => {
		let showValue = row.value||row.value===0?row.value:"NA";
		if(row.column.id==="employeeId") {
			showValue = row.value?row.value.split(",").map(key => this.props.employeeIdMap[key]).toString():"NA";
		}
		if(row.column.id === "stage") {
			showValue = row.value||row.value===0?this.props.stageMap[row.value]?this.props.stageMap[row.value]:"NA":"NA";
		} 
		return (<div onDoubleClick={() => {this.handleOnTableCellClick(row.index,row.column.id)}} >{showValue}</div>);
	}
	inputDiv = (row) => {
		let defaultValue = row.value;
		return <input onKeyUp={(e) => {this.handleOnKeyPress(e,row.index,row.column.id)}} className="form-control" name={`${row.index}__${row.column.id}`} defaultValue={defaultValue} />
	}

	inputNumberDiv = (row) => {
		let defaultValue = row.value?row.value:0;
		return <input type="number" onKeyUp={(e) => {this.handleOnKeyPress(e,row.index,row.column.id)}} className="form-control" name={`${row.index}__${row.column.id}`} defaultValue={defaultValue} />
	}

	selectInputDiv = (row,options) => {
		return (
			<div>
			<select
			onKeyUp={(e) => {this.handleOnKeyPress(e,row.index,row.column.id)}}
			onChange = {(e) => {this.handleOnSelectChange(e,row.index,row.column.id)}}
			className="form-control" name={`${row.index}__${row.column.id}`}
			defaultValue={row.value?row.value:""}>
				{Object.keys(options).map(key =><option key={key} value={key}>{options[key]}</option>)}
			</select>
			</div>
		)
	}

	selectMultiInputDiv = (row,options) => {
		const changeHandle = (value) => { this.handleOnMultiSelectInputChange(value,row.index,row.column.id) }
		return (
			<div>
			<Select
				isMulti
				isSearchable
				onKeyDown={(e) => {this.handleOnKeyPress(e,row.index,row.column.id)}}
			    onChange = {(e) => {changeHandle(e)}}
			    value={row.value?row.value.split(",").map(key => {return { value:key, label:options[key] }}):""}
			    className="form-control" name={`${row.index}__${row.column.id}`}
				options={Object.keys(options).map(key => {return {value:key, label:options[key]}})}
			/>
			</div>
		)
	}



	// IN CELL UPDATE AND SUBMIT
	handleOnKeyPress = (e,index,id) => {
		if (e.keyCode === 13 && !e.shiftKey) {
			let indexUpdate = this.state.singleEditStatus[index]?this.state.singleEditStatus[index]:{};
			indexUpdate[id] = false;
			let getData = [...this.state.data];
			getData[index][id] = e.target.value;
			let siteId 	= this.state.data[index].siteId;
			let value 	= e.target.value;
			// SAVE FUNCTION
			if(id==="size"){
				this.props.updateDVariable(siteId,"size_D",value);
			}

			if(id==="revenueRate"){
				this.props.updateDVariable(siteId,"revenueRate_D",value);
			}

			let defaultData = filterClone("siteId",getData[index],[...this.state.defaultData])
	        this.setState({
				singleEditStatus:{...this.state.singleEditStatus,[index]:indexUpdate},
				data:[...getData],
				defaultData
			});
	    }
	    if (e.keyCode === 27) {
	        let indexUpdate = this.state.singleEditStatus[index]?this.state.singleEditStatus[index]:{};
			indexUpdate[id] = false;
	        this.setState({
				singleEditStatus:{...this.state.singleEditStatus,[index]:indexUpdate},
			});
	    }
	}

	handleOnSelectChange = (e,index,id) => {
		let indexUpdate = this.state.singleEditStatus[index]?this.state.singleEditStatus[index]:{};
		indexUpdate[id] = false;
		let getData = [...this.state.data];
		getData[index][id] = e.target.value;
		let siteId 	= this.state.data[index].siteId;
		let value 	= e.target.value;
		let phone 	= this.state.data[index].ownerId;
		if(id==="city"){
			this.props.setcitydata(phone,value);
		}

		if(id==="state"){
			this.props.updateDVariable(siteId,"state_D",value);
		}

		if(id==="subsidy"){
			this.props.updateDVariable(siteId,"subsidy_D",value);
		}

		if(id==="elevationType"){
			this.props.updateDVariable(siteId,"elevationType_D",value);
		}

		if(id==="revenueType"){
			this.props.updateDVariable(siteId,"revenueType_D",value);
		}

		if(id==="systemType"){
			this.props.updateDVariable(siteId,"systemType_D",value);
		}
		let defaultData = filterClone("siteId",getData[index],[...this.state.defaultData]);
		this.setState({
			singleEditStatus:{...this.state.singleEditStatus,[index]:indexUpdate},
			data:[...getData],
			defaultData
		});
	}

	handleOnMultiSelectInputChange = (dataChange,index,id) => {
		let indexUpdate = this.state.singleEditStatus[index]?this.state.singleEditStatus[index]:{};
		indexUpdate[id] = false;
		let getData = [...this.state.data];
		let valueGet = dataChange.map(val => val.value).toString();
		getData[index][id] = valueGet;
		let siteId 	= this.state.data[index].siteId;
		if(id==="employeeId"){
			this.props.updateDVariable(siteId,"employeeId_D",valueGet);
		}
		let defaultData = filterClone("siteId",getData[index],[...this.state.defaultData]);
		this.setState({
			singleEditStatus:{...this.state.singleEditStatus,[index]:indexUpdate},
			data:[...getData],
			defaultData
		});
	}

	// CHANGING THE CELL TO INPUT
	handleOnTableCellClick = (index,id) => {
		let indexUpdate = this.state.singleEditStatus[index]?this.state.singleEditStatus[index]:{};
		indexUpdate[id] = true;
		this.setState({
			singleEditStatus:{...this.state.singleEditStatus,[index]:indexUpdate}
		})
	}

	// HANDLE MULTIPLE CHECK BOX CHANGE
	handleSelectOnChange = (phoneNumber) => {
		let { selectCheckBox } = this.state;
		let value = selectCheckBox[phoneNumber]?!selectCheckBox[phoneNumber]:true;
		this.setState({
			selectCheckBox: {...this.state.selectCheckBox,[phoneNumber]:value}
		});
	}

	// HANDLE BULK CLICK CHECK BOX CHANGE
	handleBulkSelectOnChange = (e,row) => {
		let { selectCheckBox }  = this.state;
		Object.keys(selectCheckBox).forEach(number => {
			selectCheckBox[number] = false;
		});
		row.forEach(data => {
			selectCheckBox[data.quoteId] = e.target.checked;
		});
		this.setState({selectCheckBox});
	}

	handleOnMultiSelectChange = (selectedValue,name) => {
		this.setState({[name]: selectedValue});
	}

	handleDateInputChange = (e) => {
		let addValue = 0;
		if(e.target.name==="startDate") {
			addValue = e.target.value?e.target.value:0;
		}else{
			addValue = e.target.value?e.target.value:9999999999999;
		}
		this.setState({
			[e.target.name]: new Date(addValue).getTime()
		})
	}

	handleInputChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	handleOnApprovedClick = (getData,value) => {
		let { siteId } = getData;
		this.props.updateDVariable(siteId,"salesApprovedStatus_D",value?0:1);
		getData["salesApprovedStatus_D"] = value?0:1;
		let defaultData = filterClone("siteId",getData,[...this.state.defaultData]);
		this.setState({
			defaultData
		},() => {this.applyFilter()});
	}

	handleViewChange = (change) => {
		let columnStatusList = [];
		if(change) {
			let { defaultColumns } = this.state;
			columnStatusList = defaultColumns.map(val => {
				let newValue = {...val};
				if(newValue.accessor==="v1"||newValue.accessor==="v2"||newValue.accessor==="stage") {
					newValue.show = true;
				}
				return newValue;
			});
		} else {
			let { defaultColumns } = this.state;
			columnStatusList = defaultColumns.map(val => {
				let newValue = {...val};
				if(newValue.accessor==="v1"||newValue.accessor==="v2"||newValue.accessor==="stage") {
					newValue.show = false;
				}
				return newValue;
			});
		}
		this.setState({
			defaultColumns:columnStatusList,
			columns:columnStatusList,
			currentView: change,
			currentViewButton: change?"Final":"New"
		},() => {this.applyFilter()})
	}

	applyFilter = () => {
		let { stateFilter, subsidyFilter, stageFilter, startDate, endDate, startPercentage, endPercentage, currentView } = this.state;
		let newData = [...this.state.defaultData];
		let filteredData = newData.filter(val => {
			if((stateFilter.length===0||stateFilter.map(value => value.label).indexOf(val.state)>-1)&&(stageFilter.length===0||stageFilter.map(key => key.value).indexOf(val.stage)>-1)&&(val.stage!==11 || (val.stage===11 && stageFilter.indexOf(11)>-1))&&(subsidyFilter.length===0||subsidyFilter.map(value => value.label).indexOf(val.subsidy)>-1)&&(startDate<=val.salesDate&&val.salesDate<=endDate)&&((!startPercentage||startPercentage<=val.percentage)&&(val.percentage<=endPercentage||!endPercentage))&&(val.salesApprovedStatus_D===currentView)){
				return true;
			}
			return false;
		});
		this.setState({
			data: filteredData
		})
	}

	onModalAction = (modalName) => {
		let status = this.state[modalName];
		this.setState({
			[modalName] : !status
		})
	}

	onColumnFilterClick = (key) => {
		let { columns } = this.state;
		let newColumn = columns.map(val => {
			if(val.accessor===key) {
				val.show = val.show===undefined?false:!val.show; // NEED TO CHANGE
			}
			return val;
		})
		window.localStorage.setItem('salesAdminColumns', JSON.stringify(newColumn));
		this.setState({columns:newColumn,salesAdminColumns:newColumn})
	}

	downloadData = () => {
		// fetch('/mydata').then(data => {
		//   this.setState({ data }, () => {
		// 	this.csvLink.current.link.click()
		//   })
		// })
		let stageMap = this.props.stageMap;
		let dataToDownload = [...this.state.data];
		dataToDownload.forEach(function(rowData) {
			//let time = new Date(dataToDownload[i].salesDate);
			rowData.salesDate = changeToViewDate(new Date(rowData.salesDate));
			rowData.stage = stageMap[rowData.stage];
		});
		this.setState({ downloadData : dataToDownload }, () => {
			console.log('Download Clicked');
			this.csvLink.current.link.click();
		})
	  }

	render() {
		let { loading, data, columns, currentView, currentViewButton, openModal } = this.state;
		let { stageMap, stateMap, subsidyMap, ebissueMap, financingMap, employeeMap } = this.props;
		//console.log("SalesData",data);
		if(this.props.user===null){
			return <Redirect to="/login?redirect_uri=sales" />;
		}

		if(this.props.user.level<1600) {
			return ( 
				<div>
					<Restrict />
				</div>
			)
		}

		if(this.props.user.level>=1900) {
			return (
				<div className="container-fluid navMargin" style={{textAlign:'center'}}>
					<div style={{textAlign:'left'}}>
					<button onClick={this.downloadData} className="btn btn-success">Download</button>
						{
							this.props.user.level>=2500?<CSVLink filename={"Sales_View_Data.csv"} className="hidden" ref={this.csvLink} data={this.state.downloadData} headers={columnsSalesAdminDownload} />:""
							// 	Download
							// </CSVLink>:""
							
							// this.props.user.level>=2500?<CSVLink filename={"Sales_View_Data.csv"} className="hidden" ref={this.csvLink} data={this.state.downloadData} headers={columns.map((val,index) => {return index>0?{label:val.Header&&{}.toString.call(val.Header) === '[object Function]'?"":val.Header,key:val.accessor}:false}).filter(val => val?true:false)} />:""
							// // Download
							// // </CSVLink>:""
						}
					</div>
					<Modal open={openModal} onClose={() => {this.onModalAction("openModal")}} center>
						<div className="row" style={{marginTop:"20px"}}>
							{
								columns.map(val=> {
									return (
										<SalesViewCheckBox 
											key={val.accessor}
											showDefault={val.showDefault}
											columnCheckBoxStatus={val.show}
											handleColumnChange={this.onColumnFilterClick}
											index={val.accessor}
											label={val.Header}
										/>
									)
								})
							}
						</div>
					</Modal>
					<FilterView 
						stageMap 	= {stageMap}
						stateMap 	= {stateMap}
						subsidyMap 	= {subsidyMap}
						ebissueMap 	= {ebissueMap}
						financingMap= {financingMap}
						employeeMap = {employeeMap}
						currentView = {currentView}
						currentViewButton = {currentViewButton}
						onChangeHandle = {this.handleOnMultiSelectChange}
						handleDateInputChange = {this.handleDateInputChange}
						handleInputChange = {this.handleInputChange}
						handleViewChange = {this.handleViewChange}
						applyFilter = {this.applyFilter}
						onClickModal = {this.onModalAction}
					/>
					<ReactTable
						loading={loading}
						filterable={true}
						showPagination={true}
						sortable={true}
						data={data}
						columns={columns}
						getTdProps = {(state, rowInfo, column) => {
							const { id } = column
							return {
								style: id === 'employeeId' ? { overflow: 'visible' } : {},
							}
						}}
						defaultSorted={[{ id: "salesDate", desc: true }]}
						defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase())>-1}/>
				</div>
			);
		}
		return (
			<div className="navMargin">
				Loading...
			</div>
		);
	}
}

function mapStateToProps({user, salesdata, mapping}) {
	let { loading, data } = salesdata;
	let { stageMap, stateMap, subsidyMap, ebissueMap, financingMap, notificationMap, employeeMap, cityMap, employeeIdMap } = mapping;
	return { user, loading, data, stageMap, stateMap, subsidyMap, ebissueMap, financingMap, notificationMap, employeeMap, cityMap, employeeIdMap }
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		fetchsalesdata, fetchmapping,
		setcitydata, updateDVariable
	}, dispatch);
};

export default connect(mapStateToProps,mapDispatchToProps)(SalesAdmin);