import { db } from '../connection.js';

export function fetch(urlRef,value) {
	return	db.ref(urlRef).child(value)
			.once('value')
			.then(snapShot => snapShot.val())
			.catch(err => err)
}

export function set(urlRef,value) {
	return	db.ref(urlRef).set(value)
			.then(() => "Successfully Set")
			.catch(err => err)
}

export function push(urlRef,value) {
	return	db.ref(urlRef).push(value)
			.once('value')
			.then(snapShot =>  snapShot.key)
			.catch(err => err)
}

export function fetchOrderByChild(urlRef,onKey,value) {
	return	db.ref(urlRef)
				.orderByChild(onKey)
				.equalTo(value).once("value")
				.then(snapshot => snapshot.val())
				.catch(err => err)
}

export function fetchOrderByKey(urlRef,onKey,value) {
	return	db.ref(urlRef)
				.orderByKey(onKey)
				.equalTo(value).once("value")
				.then(snapshot => snapshot.val())
				.catch(err => err)
}

export function fetchOrderByValue(urlRef,onKey,value) {
	return	db.ref(urlRef)
				.orderByValue(onKey)
				.equalTo(value).once("value")
				.then(snapshot => snapshot.val())
				.catch(err => err)
}