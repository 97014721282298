import React, { Component } from 'react';
import ReactTable from "react-table";
import { columnsCallData } from '../config/column_fields';
import { styleSheet } from '../styles/styleSheet';

export class ClientCallLogAll extends Component {
	constructor(props) {
	  super(props);
	
	  this.state = {
	  	data: [],
		show: true,
		emailHTML: null,
		openUpdate:false
	  };
	  this.handleOnClickComment = this.handleOnClickComment.bind(this);
	}

	componentWillMount() {
		this.setState({
			data : this.props.data,
			open : false
		})
	}

	componentWillRecieveProps({data}) {
		this.setState({
			data
		})
	}

	handleOnClickComment() {
		this.setState({
			show: !this.state.show
		})
	}

	onOpenUpdateModal = (val) => {
		this.setState({
			openUpdate:true,
			emailHTML:val
		})
	}

	onCloseUpdateModal = () =>{
		this.setState({
			openUpdate:false
		})
	}

	render() {
		return (
			<div className="row row_data">
				<div className="col-12">
					<div className="page-title">
						<div style={styleSheet.titlePage}>
							<div style={styleSheet.titlePage.quote}>
								<span style={styleSheet.dropRightArrow} onClick={this.handleOnClickComment}><i className={this.state.show?"fas fa-arrow-down open_arrow":"fas fa-arrow-right open_arrow"}></i>Call Logs</span>
							</div>
						</div>
					</div>
				</div>
				<div style={this.state.show?styleSheet.show:styleSheet.hide} className="col-12">
					<div className="page_data">
						<ReactTable 
							columns={columnsCallData}
							defaultPageSize={5}
							data = {this.state.data}
							defaultSorted={[{id: "call_date",desc: true}]}
						/>
					</div>
				</div>
			</div>
		);
	}
}
