import { FETCHING_START } from '../actions/actiontypes'
import { columnsComplete } from '../config/column_fields';
import { csvComplete } from '../config/csv_fields';

let INITIAL_STATE = {
	loading:false,
	tablename:'Loading...',
	column:columnsComplete,
	csvheader:csvComplete,
	pagesize:10,
	pagination:true
}

export default function(state = INITIAL_STATE, action) {
	switch(action.type){
		case FETCHING_START:
			return action.payload;
		default:
			return state;
	}
}