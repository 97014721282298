import { FETCH_SALESANALYTICS_SUCCESS } from '../actions/actiontypes'
const INIT_STATE = {
	
};


export default function (state = INIT_STATE, action) {
	switch (action.type) {
		case FETCH_SALESANALYTICS_SUCCESS:
			return { ...state, data: {...action.payload} };

		default:
			return state;
	}
}
