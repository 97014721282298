import { db } from '../connection';
import { GENERATE_OTP_SUCCESS, GENERATE_OTP_FAILED, VERIFY_OTP_FAILED, VERIFY_OTP_SUCCESS } from './actiontypes';

import { base } from '../connection';
import axios from 'axios';
import { getNameById} from '../helper/helper';

export function generateOtp(userId, employeeId, siteId){
    return (dispatch) => {
        console.log("GENERATE OTP CALLED");
        let otpObject = {
            timestamp:(new Date()).getTime(),
            customerid:userId,
            otpsubmit:0,
            response:0,
            takenby:employeeId
        }
        db.ref(`otpRequestvendor/${userId}`).once('value')
        .then(snapshot => {
            console.log(snapshot.val());
            //console.log(snapshot.val().length);
            let length = 0;
            if(snapshot && snapshot.val()){
                length = snapshot.val().length;
            }
            db.ref(`otpRequestvendor/${userId}/${length}`).set(otpObject)
            .then((val) => {
                console.log("Done");
                let resultObj = {
                    generate : 1
                };
                dispatch({type:GENERATE_OTP_SUCCESS,payload:resultObj});
                setTimeout(() => {
                  triggerWhatsapp(siteId, userId).then();
                }, 10000)
            })
            .catch(e => {
                console.log(e);
                let resultObj = {
                    generate : 2
                };
                dispatch({type:GENERATE_OTP_SUCCESS,payload:resultObj});
            })
        })
        .catch(e => {
            console.log(e);
            let resultObj = {
                generate : 2
            };
            dispatch({type:GENERATE_OTP_SUCCESS,payload:resultObj});
        })
    }
}

export function verifyOtp(userId, employeeId, otp, assignedVendor, siteId) {
    return (dispatch) => {
        console.log("VERIFY OTP CALLED");
        db.ref(`otpRequestvendor/${userId}`).once('value')
        .then(snapshot => {
            console.log(snapshot.val());
            //console.log(snapshot.val().length);
            let data = snapshot.val();
            let lastNode = snapshot.val().length - 1;
            db.ref(`otprecordsvendor/${userId}/${lastNode}`).once('value')
            .then(otpSnapshot => {
                let inputOtp = otpSnapshot.val();
                if(inputOtp==otp){
                    console.log("Done");
                    let epoch = (new Date()).getTime();
                    let resultObj = {
                        verify : 1,
                        epoch : epoch
                    };
                    dispatch({type:VERIFY_OTP_SUCCESS,payload:resultObj});
                    setStatus(assignedVendor,siteId,epoch);
                }
                else{
                    console.log("Wrong OTP");
                    let resultObj = {
                        verify : 2
                    };
                    dispatch({type:VERIFY_OTP_FAILED,payload:resultObj});
                }
            })
            .catch(e => {
                console.log(e);
                let resultObj = {
                    verify : 2
                };
                dispatch({type:VERIFY_OTP_FAILED,payload:resultObj});
            })
        })
        .catch(e => {
            console.log(e);
            let resultObj = {
                verify : 2
            };
            dispatch({type:VERIFY_OTP_FAILED,payload:resultObj});
        })
    }
}

function setData(path, data){
    db.ref(path).set(data)
    .then((val) => {
        return
    })
    .catch(e => {
        return e;
    })
}

function setStatus(vendorId, siteId, time){
    if(vendorId && siteId){
    let vendorAppDatePath = `delivery/vendorApp/assigned/${vendorId}/${siteId}/date`;
    setData(vendorAppDatePath, time);
    let vendorAppStatusPath = `delivery/vendorApp/assigned/${vendorId}/${siteId}/status`;
    setData(vendorAppStatusPath, 1);
    let verifiedStatusPath = `delivery/webPortal/sites/${siteId}/panelOtpVerifiedStatus`;
    setData(verifiedStatusPath, 1);
    let panelDatePath = `delivery/sites/${siteId}/orderStatus/panel`;
    let dateObj = new Date(time);
    let panelData = {
        expectedCompletionDate:{
            date:dateObj.getDate(),
            day:dateObj.getDay(),
            hours:dateObj.getHours(),
            minutes:dateObj.getMinutes(),
            month:dateObj.getMonth(),
            seconds:dateObj.getSeconds(),
            time:time,
            timezoneOffset:dateObj.getTimezoneOffset(),
            year:dateObj.getYear()
        },
        name:"Panel",
        status:1
    }
    setData(panelDatePath, panelData);
}
}

const triggerWhatsapp = async (siteId, userId) => {
	let {mSite_QuoteId : quoteId, mSite_OwnerId : customerId} = (await db.ref(`/sites/${siteId}`).once("value")).val();
  let otpArray = (await db.ref(`/otprecordsvendor/${userId}`).once("value")).val();
  let otp = otpArray.pop();
	let customerName = await getNameById(customerId);
	let requestData = {
			key : "4VhfN1xiAD",
			phoneNumber : customerId,
			trigger : "panelDeliveryOTP",
			quoteId : quoteId,
			customerName : customerName,
      otp : otp
	}
	requestData = new URLSearchParams(requestData);
	let data = await axios.post("https://zuntalks.in:3000/whatsapp/trigger", requestData, {
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
	});
}
