import React from 'react';
import Select from 'react-select';

export const Filter = ({ siteVisitOptions, stateList,cityList, stageList, vendorList, ebissuesList, subsidiesList, applyFilter, handleMultiSelectChange, handleDateInputChange, handleInputChange, stage1List = [], stage2List = [], netMeteringOptions = [] }) => {
	return (
		<div>
			<div className="filter_deals__subpart">
				<div className="filter_selector">
					<div className="row" style={{width:'100%'}}>
						<div className="col-md col-6 filter_box ">
							{selectCustom(stateList,"stateChange","Choose State",handleMultiSelectChange)}
						</div>
						<div className="col-md col-6 filter_box ">
							{selectCustom(cityList,"cityChange","Choose City",handleMultiSelectChange)}
						</div>
						<div className="col-md col-6 filter_box ">
							{selectCustom(stageList,"stageChange","Choose Stage",handleMultiSelectChange)}
						</div>
						<div className="col-md col-6 filter_box ">
							{selectCustom(ebissuesList,"ebIssueChange","Choose EbIssue",handleMultiSelectChange)}
						</div>
						<div className="col-md col-6 filter_box ">
							{selectCustom(subsidiesList,"subsidyChange","Choose Subsidy",handleMultiSelectChange)}
						</div>
						<div className="col-md col-6 filter_box ">
							{selectCustom(vendorList,"v1Change","Choose V1",handleMultiSelectChange)}
						</div>
						<div className="col-md col-6 filter_box ">
							{selectCustom(stage1List,"stage1Change","Choose stage 1",handleMultiSelectChange)}
						</div>
						<div className="col-md col-6 filter_box ">
							{selectCustom(stage2List,"stage2Change","Choose stage 2",handleMultiSelectChange)}
						</div>
					</div>
				</div>
				<div className="row" style = {{marginBottom:10}}>
					<div className="col-lg-2 col-6" style={{padding:'0px 15px'}}>
						Sales Start Date:
						<input
						  style={{width:"100%",borderRadius:5,padding:'8px 8px'}}
						  onChange={handleDateInputChange}
						  name="startDate"
						  type='date'
						/>
					</div>
					<div className="col-lg-2 col-6" style={{padding:'0px 10px'}}>
						Sales End Date:
						<input
						  style={{width:"100%",borderRadius:5,padding:'8px 8px'}}
						  onChange={handleDateInputChange}
						  name="endDate"
						  type='date'
						/>
					</div>
					<div className="col-lg-1 col-6" style={{padding:'0px 10px'}}>
						% Start Value:
						<input
						  style={{width:85,borderRadius:5,padding:'8px 8px'}}
						  name="startPercentage"
						  type='number'
						  onChange={handleInputChange}
						  placeholder='Start Value'
						/>
					</div>
					<div className="col-lg-1 col-6" style={{padding:'0px 10px'}}>
						% End Value:
						<input
						  style={{width:85,borderRadius:5,padding:'8px 8px'}}
						  name="endPercentage"
						  type='number'
						  onChange={handleInputChange}
						  placeholder='Last Value'
						/>
					</div>
					<div className="col-lg-1 col-6" style={{padding:'0px 15px'}}>
						RMS Installed
						<div className="drop_down1">
							< select className="form-control"
							    onChange = {handleInputChange}
							    name="rmsInstalled"
							>
							<option selected value="">No Filter</option>
							<option value="Yes">Yes</option>
							<option value="No">No</option>
							</select>
						</div>
					</div>
					<div className="col-lg-1 col-6" style={{padding:'0px 15px'}}>
						RMS Status
						<div className="drop_down1">
							< select className="form-control"
							    onChange = {handleInputChange}
							    name="rmsStatus"
							>
							<option selected value="">No Filter</option>
							<option value="Active">Active</option>
							<option value="Inactive">Inactive</option>
							</select>
						</div>

					</div>
					<div className="col-lg-1 col-6" style={{padding:'0px 15px'}}>
						ZunPrime
						<div className="drop_down1">
							< select className="form-control"
							    onChange = {handleInputChange}
							    name="zunPrime"
							>
							<option selected value="">No Filter</option>
							<option value="Yes">Yes</option>
							<option value="No">No</option>
							</select>
						</div>

					</div>

					<div className="col-lg-1 col-6 filter_box ">
						Site Visit
							{selectCustom(siteVisitOptions,"siteVisitChange","Site Visit",handleMultiSelectChange)}
					</div>
					<div className="col-lg-1 col-6 filter_box ">
						Net metering
							{selectCustom(netMeteringOptions,"netMeterChange","Net metering",handleMultiSelectChange)}
					</div>
					<div className="col-md-1 col-6 filter_box filter_box_button">
							<div className="filter_selector__drop_down_label1" style={{marginBottom:'25%'}}></div>
							<button className="btn btn-success applyButton" onClick={applyFilter}>Apply Filter</button>
						</div>
				</div>
			</div>
		</div>
	);
}

const selectCustom = (options, name, placeholder, handleMultiSelectChange) => {
	const changeHandle = (value) => { handleMultiSelectChange(value,name) }
	return (
		<div className="drop_down1">
			<Select
				isMulti
				isSearchable
			    onChange = {(e) => {changeHandle(e)}}
			    placeholder={placeholder}
			    name={name}
				options={options}
			/>
		</div>
	)
}
