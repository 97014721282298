import {
  FETCHING_INBOUND_CALL_START,
  FETCHING_INBOUND_CALL_SUCCESS
} from './actiontypes';

import { db } from '../connection';
import axios from 'axios';
import moment from 'moment'

export function fetchInboundCall() {
  return (dispatch) => {
    dispatch({ type: FETCHING_INBOUND_CALL_START });
    mergeData().then((finalObj) => {
      console.log("prateek final", finalObj);
      dispatch({ type: FETCHING_INBOUND_CALL_SUCCESS, payload: finalObj });
    })
  }
}

async function mergeData() {
  try {
    console.log("Prateek inside function");
    console.time('time');
    let data = await getInboundData();
    console.log("prateek data", data);
    return data;
  } catch (e) {
    console.log(e);
    return new Error(e);
  }
}

async function getInboundData() {
  try {
    let inboundData = (await db.ref("/delivery/webPortal/knowlarity/inbound").once("value")).val();
    if (inboundData) {
      inboundData = Object.values(inboundData).map(user => {
        return Object.values(user);
      })
      inboundData = [].concat(...inboundData);
      inboundData = await Promise.all(inboundData.map(async user => {
        if (user.customer_number) {
          user.caller_number = user.customer_number
        }
        let number = (user.entered_mobile_number && user.entered_mobile_number != '1#') ? "+91" + parseInt(user.entered_mobile_number) : user.caller_number;
        let sites = (await db.ref("/users/" + number).once("value")).val();
        if (sites && sites["mUser_SitesViewableIdList"]) {
          let quoteId = await Promise.all(sites["mUser_SitesViewableIdList"].map(async site => {
            let quote = (await db.ref("/sites/" + site + "/mSite_QuoteId").once("value")).val();
            return quote;
          }));
          user.quoteId = quoteId;
        }
        let agent = (await db.ref("/users/" + user.agent_number + "/mUser_Contact").once("value")).val();
        if (agent) {
          user.agent_name = agent.mContact_FirstName + " " + agent.mContact_LastName
        } else {
          user.agent_name = user.agent_number;
        }
        if (user.call_type == "outgoing") {
          user.menu_extension = "outbound";
          user.caller_duration = user.agent_call_duration;
          user.conversation_duration = user.customer_call_duration;
          user.call_status = user.customer_status;
        }
        if (user.call_date) {
          user.call_date = new Date(user.call_date + " " + (user.call_time ? user.call_time : '')).getTime();
        }
        return user
      }))
    } else {
      inboundData = [];
    }
    inboundData = inboundData.filter(call => call.call_type != "runtime")
    console.log("prateek", inboundData);
    return inboundData;
  } catch (e) {
    console.log(e);
    return new Error(e);
  }
}

export function fetchInboundCallData(startTime, endTime, limit, page, data) {
  return async (dispatch) => {
    try {
      let callsData = await fetchData(startTime, endTime, limit, page);
      console.log("calls", callsData)
      dispatch({ type: FETCHING_INBOUND_CALL_SUCCESS, payload: [...data, ...callsData] })
    }
    catch(e) {
      console.log(e);
    }
  }
} 

async function fetchData(startTime, endTime, limit, page) {
  try {
    let config = {
      method: 'get',
      url: `https://kpi.knowlarity.com/Basic/v1/account/calllog?start_time=${startTime}&end_time=${endTime}&offset=${limit*(page-1)}&limit=${limit}`,
      headers: {
        'x-api-key': 'qRwE4Ugq4C3XJfNlUWUkl3CpwZ77ryWradPalKfY',
        'Authorization': '6b8b0678-2f95-429d-9e6c-3347c3cbc00a',
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };
    let response = await axios(config);
    response = response.data || {};
    let data = response.objects || [];
    data = await Promise.all(data.map(async user => {
      if (user.customer_number) {
        user.caller_number = user.customer_number
      }
      let number = user.caller_number;
      let sites = (await db.ref("/users/" + number).once("value")).val();
      if (sites && sites["mUser_SitesViewableIdList"]) {
        let quoteId = await Promise.all(sites["mUser_SitesViewableIdList"].map(async site => {
          let quote = (await db.ref("/sites/" + site + "/mSite_QuoteId").once("value")).val();
          return quote;
        }));
        user.quoteId = quoteId;
      }
      // let agentNumber = ((user.agent_number||"").split("#"))[1] || "";
      // let agent = (await db.ref("/users/" + agentNumber + "/mUser_Contact").once("value")).val();
      // if (agent) {
      //   user.agent_name = agent.mContact_FirstName + " " + agent.mContact_LastName
      // } else {
      //   user.agent_name = user.agent_number;
      // }
      user.callDate = moment(user.start_time).format('x');
      return user
    }))
    return data
  }
  catch (e) {
    console.log("error", e);
    return [];
  }
}

// async function mergeData() {
//   try {
//     console.log("Prateek inside function");
//     console.time('time');
//     let data = await Promise.all([getInboundData(),getOutboundData()]);
//     console.log("prateek data", data);
//     data = data[0].concat(data[1]);
//     return data;
//   } catch (e) {
//     console.log(e);
//     return new Error(e);
//   }
// }
//
// async function getInboundData() {
//   try {
//     let inboundData = (await db.ref("/delivery/webPortal/knowlarity/inbound").once("value")).val();
//     if (inboundData) {
//       inboundData = Object.values(inboundData).map(user => {
//         return Object.values(user);
//       })
//       inboundData = [].concat(...inboundData);
//       inboundData = await Promise.all(inboundData.map(async user => {
//         if (user.customer_number) {
//           user.caller_number = user.customer_number
//         }
//         let number = (user.entered_mobile_number && user.entered_mobile_number != '1#' )? "+91" + user.entered_mobile_number.slice(0, user.entered_mobile_number.length-1) : user.caller_number;
//         let sites = (await db.ref("/users/" + number).once("value")).val();
//         if (sites && sites["mUser_SitesViewableIdList"]) {
//           let quoteId = await Promise.all(sites["mUser_SitesViewableIdList"].map(async site => {
//             let quote = (await db.ref("/sites/" + site + "/mSite_QuoteId").once("value")).val();
//             return quote;
//           }));
//           user.quoteId = quoteId;
//         }
//         let agent = (await db.ref("/users/" + user.agent_number + "/mUser_Contact").once("value")).val();
//         if (agent) {
//           user.agent_name = agent.mContact_FirstName + " " + agent.mContact_LastName
//         } else {
//           user.agent_name = user.agent_number;
//         }
//         return user
//       }))
//     } else {
//       inboundData = [];
//     }
//     console.log("prateek", inboundData);
//     return inboundData;
//   } catch (e) {
//     console.log(e);
//     return new Error(e);
//   }
// }
//
// async function getOutboundData() {
//   try {
//     let outboundData = (await db.ref("/delivery/webPortal/knowlarity/outbound").once("value")).val();
//     if (outboundData) {
//       outboundData = Object.values(outboundData).map(user => {
//         return Object.values(user);
//       })
//       outboundData = [].concat(...outboundData);
//       outboundData = await Promise.all(outboundData.map(async user => {
//         let sites = (await db.ref("/users/" + user.customer_number).once("value")).val();
//         if (sites && sites["mUser_SitesViewableIdList"]) {
//           let quoteId = await Promise.all(sites["mUser_SitesViewableIdList"].map(async site => {
//             let quote = (await db.ref("/sites/" + site + "/mSite_QuoteId").once("value")).val();
//             return ('<a href="/quoteId/' + quote + '" target="_blank">' + quote + '</a>');
//           }));
//           user.quoteId = quoteId;
//         }
//         let agent = (await db.ref("/users/" + user.agent_number + "/mUser_Contact").once("value")).val();
//         if (agent) {
//           user.agent_name = agent.mContact_FirstName + " " + agent.mContact_LastName
//         } else {
//           user.agent_name = user.agent_number;
//         }
//         user.menu_extension = "outbound";
//         return user
//       }))
//     } else {
//       outboundData = [];
//     }
//     console.log("prateek", outboundData);
//     return outboundData;
//   } catch (e) {
//     console.log(e);
//     return new Error(e);
//   }
// }
