import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';

import { auth, firebase } from '../connection';

// ACTIONS
import { login, logincachecheck } from '../actions/auth';

// COMPONENTS
import { LoginComponent } from '../components/LoginComponent.jsx';  
import { Loader } from '../components/Loader.jsx';  

class Login extends Component {

	constructor(props) {
	  super(props);
	
	  this.state = {
	  	phoneNumber:"",
	  	code:"",
	  	error:null,
	  	confirmationResult:null,
		getcode:null,
		requestCode:false,
		rememberMe:false,
		buttontext:"Get OTP",
		user:{}
	  };
	}

	componentWillMount() {
		if(localStorage.getItem('user_f')){
			this.props.logincachecheck();
		}
	}

	componentWillReceiveProps({user}) {
		if(user) this.setState({
			user,
			requestCode:false
		});
	}

	handleOnInputChange = (e) => {
		this.setState({
			[e.target.name] : e.target.value
		})
	}

	onSubmitForm = (e) => {
		e.preventDefault();
		let { phoneNumber, getcode, code, confirmationResult } = this.state;
		if(!getcode) {
			if(phoneNumber.length===10){
				this.setState({error:null,requestCode:true});
				var appVerifier = new firebase.auth.RecaptchaVerifier(this.recaptcha,{
				  'size': 'invisible',
				  'callback': function(response) {
				  },
				  'expired-callback': function() {
				    // Response expired. Ask user to solve reCAPTCHA again.
				    // ...
				  }
				});
				auth.signInWithPhoneNumber("+91"+phoneNumber, appVerifier)
			    .then(confirmationResult => {
			    	this.setState({
						confirmationResult:confirmationResult,
						getcode:true,
						buttontext:'Login'
					})
			    	
			    }).catch(function (error) {
			    	this.setState({error:"Couldn't able to send OTP"});
			    });
			}else{
				this.setState({error:"Enter Valid Phone Number"});
			}
		}else{
			if(code) {
				this.props.login(code,confirmationResult);
			}
		}
	}


	render() {
		let { phoneNumber, code, error, getcode, buttontext, user, requestCode  } = this.state;
		if(user.phoneNumber&&localStorage.getItem('user_f')){
			let parsed = queryString.parse(this.props.location.search);
			let to = '/deals';
			if(parsed.redirect_uri){
				to = parsed.redirect_uri;
				let qs = Object.keys(parsed);
				if (qs.length >1) {
					to += '?' + qs[1] + '=' + parsed[qs[1]];
					if (qs.length > 2) {
						qs.map(key => {
							to += '&' + key + '=' + parsed[key];
						})
					}
				}
			}
			return <Redirect to= {to} />;
		}

		if(localStorage.getItem('user_f')) {
				return <Loader />
		}

		return (
			<div className="LoginBackground">
				<div className="LoginComponent">
					{(error||user.error)?<div className="alert alert-danger" role="alert">{error?error:user.error?user.error:""}</div>:""}
					<LoginComponent 
						phoneNumber = {phoneNumber}
						code = {code}
						getcode = {getcode}
						requestCode = {requestCode}
						buttontext = {buttontext}
						handleOnInputChange = {this.handleOnInputChange}
						onSubmitForm = {this.onSubmitForm}
					/>
					<div ref={(ref)=>this.recaptcha=ref} style={{
						display:'none'
					}}></div>
				</div>
			</div>
		);
	}
}

function mapStateToProps({user}) {
	return {
		user
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ login, logincachecheck }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Login); 