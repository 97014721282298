import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { Restrict } from '../components/Restrict';
import renderHTML from 'react-render-html';
import queryString from 'query-string';
import axios from 'axios';
class OpsReport extends Component {


    constructor(props) {
        super(props);
        this.state = {
            html:'Loading...',
            states : []
        };
    }

    componentDidMount() {
        console.log(this.props.user);
        if (this.props.user) {
            if (this.props.user.level == 2300 || this.props.user.level >= 2500) {
                console.log("Component Did Mount");
                let formdata = new URLSearchParams();
                formdata.append('key', 'g6muc4cFoPVVZvs');
                formdata.append('states', queryString.parse(this.props.location.search).state)
                // axios.post("http://localhost:3002/api/getReportHtml", formdata, {
                axios.post("https://operations.zunroof.com/api/getReportHtml", formdata, {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                })
                .then((data) => {
                    this.setState({ html: data.data.html, states : data.data.states })
                })
            }
            else{
                this.setState({ html: "You don't have access to view this. Please Contact Admin." })
            }
        }
    }


    render() {

        if (this.props.user === null) {
          let qs = queryString.parse(this.props.location.search)
            return <Redirect to={"/login?redirect_uri=opsreport" + (qs.state ? "&state=" + qs.state.replace(/ /g, "%20") : "")} />;
        }

        if (this.props.user && !(this.props.user.level == 2300 || this.props.user.level >= 2500)) {
            return (
                <div>
                    <Restrict />
                </div>
            )
        }

        return (
            <div style={{ "marginTop": 50 }} className="container-fluid">
            <div class="row">
              <div class="col-md-3">
                <select className="form-control" onChange={(e)=>{window.open('/opsreport?state=' + e.target.value, '_blank')}}>
                  <option selected disabled value>Select State</option>
                  { this.state.states.map(state => <option value= {state}>{state}</option>)}
                </select>
              </div>
            </div>
                {renderHTML(this.state.html)}
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        user: state.user
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(OpsReport);
