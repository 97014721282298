import { FETCHING_BILLS_SUCCESS, FETCHING_START } from '../actions/actiontypes'

export default function(state = [], action) {
	switch(action.type){
		case FETCHING_START:{
			return [];
		}
		case FETCHING_BILLS_SUCCESS:{
			return [...state, ...action.payload];
		}
		default:
			return state;
	}
}