export const LOGIN = 'LOGIN';
export const LOGIN_CHECK = 'LOGIN_CHECK';
export const LOGOUT = 'LOGOUT';

// FETCING DATA
export const FETCHING_START = 'FETCHING_START';
export const FETCHING_MAIN_SUCCESS = 'FETCHING_MAIN_SUCCESS';
export const FETCHING_NOTIFICATION_SUCCESS = 'FETCHING_NOTIFICATION_SUCCESS';
export const FETCHING_COMMENTS_SUCCESS = 'FETCHING_COMMENTS_SUCCESS';
export const FETCHING_BILLS_SUCCESS = 'FETCHING_BILLS_SUCCESS';
export const FETCHING_PAYMENT_SUCCESS = 'FETCHING_PAYMENT_SUCCESS';
export const FETCH_VENDOR_DUE_PAYMENT_START = 'FETCH_VENDOR_DUE_PAYMENT_START';
export const FETCH_VENDOR_DUE_PAYMENT_SUCCESS = 'FETCH_VENDOR_DUE_PAYMENT_SUCCESS';
export const FETCH_INVENTORY_SUCCESS = 'FETCH_INVENTORY_SUCCESS';

//USER ROLE
export const SET_USERROLE_START = 'SET_USERROLE_START';
export const SET_USERROLE_COMPLETED = 'SET_USERROLE_COMPLETED';
export const SET_USERROLE_SUCCESS = 'SET_USERROLE_SUCCESS';
export const FETCHING_USERROLE_START = 'FETCHING_USERROLE_START';
export const FETCHING_USERROLE_COMPLETED = 'FETCHING_USERROLE_COMPLETED';
export const FETCHING_USERROLE_SUCCESS = 'FETCHING_USERROLE_SUCCESS';

//STAGE
export const FETCHING_STAGES_START = 'FETCHING_STAGES_START';
export const FETCHING_STAGES_PROGRESS = 'FETCHING_STAGES_PROGRESS';
export const FETCHING_STAGES_SUCCESS = 'FETCHING_STAGES_SUCCESS';


export const FETCHING_QUOTEID_START = 'FETCHING_QUOTEID_START';
export const FETCHING_QUOTEID_SUCCESS = 'FETCHING_QUOTEID_SUCCESS';
export const FETCHING_DEALS_START = 'FETCHING_DEALS_START';
export const FETCHING_DEALS_SUCCESS = 'FETCHING_DEALS_SUCCESS';
export const FETCHING_DEALS_COMPLETED = 'FETCHING_DEALS_COMPLETED';
export const FETCHED_ALL = 'FETCHED_ALL';

// ADD OR UPDATE DATA
export const ADD_OR_UPDATE_ELEVATION_DETAIL_START = 'ADD_OR_UPDATE_ELEVATION_DETAIL_START';
export const ADD_OR_UPDATE_ELEVATION_DETAIL_SUCCESS = 'ADD_OR_UPDATE_ELEVATION_DETAIL_SUCCESS';
export const DELIVERED_PANEL_START = 'DELIVERED_PANEL_START';
export const DELIVERED_PANEL_SUCCESS = 'DELIVERED_PANEL_SUCCESS';
export const DELIVERED_INVERTER_START = 'DELIVERED_INVERTER_START';
export const DELIVERED_INVERTER_SUCCESS = 'DELIVERED_INVERTER_SUCCESS';
export const ADDING_COMMENT_STATUS = 'ADDING_COMMENT_STATUS';
export const ADD_USER_START = 'ADD_USER_START';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_DELIVERY_TEAM_START = 'ADD_DELIVERY_TEAM_START';
export const ADD_DELIVERY_TEAM_SUCCESS = 'ADD_DELIVERY_TEAM_SUCCESS';


//FETCHING MAPPING
export const FETCHING_STATE_MAP_SUCCESS = 'FETCHING_STATE_MAP_SUCCESS';
export const FETCHING_EBISSUE_MAP_SUCCESS = 'FETCHING_EBISSUE_MAP_SUCCESS';
export const FETCHING_FINANCING_MAP_SUCCESS = 'FETCHING_FINANCING_MAP_SUCCESS';
export const FETCHING_STAGE_MAP_SUCCESS = 'FETCHING_STAGE_MAP_SUCCESS';
export const FETCHING_SUBSIDY_MAP_SUCCESS = 'FETCHING_SUBSIDY_MAP_SUCCESS';
export const FETCHING_NOTIFICATION_MAP_SUCCESS = 'FETCHING_NOTIFICATION_MAP_SUCCESS';
export const FETCHING_EMPLOYEE_MAP_SUCCESS = 'FETCHING_EMPLOYEE_MAP_SUCCESS';
export const FETCHING_TEAM_MAP_SUCCESS = 'FETCHING_TEAM_MAP_SUCCESS';
export const FETCHING_CITY_MAP_SUCCESS = 'FETCHING_CITY_MAP_SUCCESS';
export const FETCHING_STATE_CITY_MAP_SUCCESS = 'FETCHING_STATE_CITY_MAP_SUCCESS';
export const FETCHING_EMPLOYEE_ID_MAP_SUCCESS = 'FETCHING_EMPLOYEE_ID_MAP_SUCCESS';
export const FETCHING_VENDOR_ID_MAP_SUCCESS = 'FETCHING_VENDOR_ID_MAP_SUCCESS';
export const FETCHING_SUBSIDY_STATUS_MAP_SUCCESS = 'FETCHING_SUBSIDY_STATUS_MAP_SUCCESS';
export const FETCHING_NET_METERING_MAP_SUCCESS = 'FETCHING_NET_METERING_MAP_SUCCESS';
export const FETCHING_NEW_STAGE_MAPPING_SUCCESS = 'FETCHING_NEW_STAGE_MAPPING_SUCCESS';

//FETCHING GRAPH
export const PERCENTAGE_GRAPH = 'PERCENTAGE_GRAPH';
export const PAYMENT_HISTORY_GRAPH = 'PAYMENT_HISTORY_GRAPH';
export const PAYMENT_RESPONSIBLE_WEEK_GRAPH = 'PAYMENT_RESPONSIBLE_WEEK_GRAPH';
export const OUTSTANDING_ORDERS_GRAPH = 'OUTSTANDING_ORDERS_GRAPH';

// EMAIL FETCH
export const FETCHING_MAIL_START = 'FETCHING_MAIL_START';
export const FETCHING_MAIL_SUCCESS = 'FETCHING_MAIL_SUCCESS';
export const FETCHING_EMAIL_STATUS_SUCCESS = 'FETCHING_EMAIL_STATUS_SUCCESS';
export const FETCHING_EMAIL_ANALYTICS_START = 'FETCHING_EMAIL_ANALYTICS_START';
export const FETCHING_EMAIL_ANALYTICS_SUCCESS = 'FETCHING_EMAIL_ANALYTICS_SUCCESS';
export const FETCHING_CLIENT_CALL_ANALYTICS_SUCCESS = 'FETCHING_CLIENT_CALL_ANALYTICS_SUCCESS';
export const FETCHING_EMAIL_ANALYTICS_DATE = 'FETCHING_EMAIL_ANALYTICS_DATE';
export const FETCHING_CLIENT_CALL_ANALYTICS_DATE = 'FETCHING_CLIENT_CALL_ANALYTICS_DATE';
export const FETCHING_EMAIL_ACCOUNTS_START = 'FETCHING_EMAIL_ACCOUNTS_START';
export const FETCHING_EMAIL_ACCOUNTS_SUCCESS = 'FETCHING_EMAIL_ACCOUNTS_SUCCESS';


export const UPLOADING_STARTED = 'UPLOADING_STARTED';
export const UPLOADING_SUCCESS = 'UPLOADING_SUCCESS';
export const UPLOADING_ERROR = 'UPLOADING_ERROR';


export const FETCHING_FILE_START = 'FETCHING_FILE_START';
export const FETCHING_FILE_SUCCESS = 'FETCHING_FILE_SUCCESS';
export const FETCHING_FILE_ERROR = 'FETCHING_FILE_ERROR';


// INCENTIVE
export const FETCHING_INCENTIVE_START = 'FETCHING_INCENTIVE_START';
export const FETCHING_INCENTIVE_SUCCESS = 'FETCHING_INCENTIVE_SUCCESS';
export const INCENTIVE_UPADTE_START = 'INCENTIVE_UPADTE_START';
export const INCENTIVE_UPADTE_SUCCESS = 'INCENTIVE_UPADTE_SUCCESS';
export const INCENTIVE_UPADTE_FAILED = 'INCENTIVE_UPADTE_FAILED';
export const FETCHED_QUOTEIDLIST = 'FETCHED_QUOTEIDLIST';
export const FETCHING_INCENTIVE_DETAILS = 'FETCHING_INCENTIVE_DETAILS';

// SALES
export const FETCHING_SALES_START = 'FETCHING_SALES_START';
export const FETCHING_SALES_ERROR = 'FETCHING_SALES_ERROR';
export const FETCHING_SALES_SUCCESS = 'FETCHING_SALES_SUCCESS';
export const FETCHING_SALES_ANALYTICS_SUCCESS = 'FETCHING_SALES_ANALYTICS_SUCCESS';

//VENDOR
export const FETCH_QUOTEID_DATA_START = 'FETCH_QUOTEID_DATA_START';
export const FETCH_QUOTEID_DATA_ERROR = 'FETCH_QUOTEID_DATA_ERROR';
export const FETCH_QUOTEID_DATA_SUCCESS = 'FETCH_QUOTEID_DATA_SUCCESS';
export const FETCH_TICKET_QUOTEIDS_START = 'FETCH_TICKET_QUOTEIDS_START';
export const FETCH_TICKET_QUOTEIDS_ERROR = 'FETCH_TICKET_QUOTEIDS_ERROR';
export const FETCH_TICKET_QUOTEIDS_SUCCESS = 'FETCH_TICKET_QUOTEIDS_SUCCESS';
export const FETCH_REFERENCE_LIST_START = 'FETCH_REFERENCE_LIST_START';
export const FETCH_REFERENCE_LIST_SUCCESS = 'FETCH_REFERENCE_LIST_SUCCESS';
export const FETCH_REFERENCE_LIST_QUOTEID_SUCCESS = 'FETCH_REFERENCE_LIST_QUOTEID_SUCCESS';
export const FETCH_PROCESS_LIST_START = 'FETCH_PROCESS_LIST_START';
export const FETCH_PROCESS_LIST_SUCCESS = 'FETCH_PROCESS_LIST_SUCCESS';
export const FETCH_VENDOR_DUE_START = 'FETCH_VENDOR_DUE_START';
export const FETCH_VENDOR_DUE_SUCCESS = 'FETCH_VENDOR_DUE_SUCCESS';

export const SET_VENDOR_FORM_INIT_SUCCESS = 'SET_VENDOR_FORM_INIT_SUCCESS';
export const SET_VENDOR_FORM_INIT_ERROR = 'SET_VENDOR_FORM_INIT_ERROR';
export const SET_VENDOR_FORM_APPROVAL_SUCCESS = 'SET_VENDOR_FORM_APPROVAL_SUCCESS';
export const SET_VENDOR_FORM_APPROVAL_ERROR = 'SET_VENDOR_FORM_APPROVAL_ERROR';
export const SET_NEW_VENDOR_FORM_SUCCESS = 'SET_NEW_VENDOR_FORM_SUCCESS';
export const SET_NEW_VENDOR_FORM_ERROR = 'SET_NEW_VENDOR_FORM_ERROR';
export const SET_TRANSACTION_FORM_SUCCESS = 'SET_TRANSACTION_FORM_SUCCESS';
export const SET_TRANSACTION_FORM_ERROR = 'SET_TRANSACTION_FORM_ERROR';
export const FETCH_BANK_DATA_SUCCESS = 'FETCH_BANK_DATA_SUCCESS';
export const FETCH_BANK_DATA_START = 'FETCH_BANK_DATA_START';
export const FETCH_VENDOR_SUMMARY_START = 'FETCH_VENDOR_SUMMARY_START';
export const FETCH_VENDOR_SUMMARY_SUCCESS = 'FETCH_VENDOR_SUMMARY_SUCCESS';
export const FETCH_INVOICE_DATA_SUCCESS = 'FETCH_INVOICE_DATA_SUCCESS';
// Send Email On Edit
export const SEND_EMAIL_ON_EDIT = 'SEND_EMAIL_ON_EDIT';

//Complaint View
export const FETCH_COMPLAINTS_START = 'FETCH_COMPLAINTS_START';
export const FETCH_COMPLAINTS_SUCCESS = 'FETCH_COMPLAINTS_SUCCESS';
export const UPDATE_COMPLAINTS_START = 'UPDATE_COMPLAINT_START';
export const UPDATE_COMPLAINTS_SUCCESS = 'UPDATE_COMPLAINT_SUCCESS';
export const CREATE_COMPLAINT_FAILED = 'CREATE_COMPLAINT_FAILED';
export const CREATE_COMPLAINT_SUCCESS = 'CREATE_COMPLAINT_SUCCESS';

//Client Call logs

export const FETCH_CLIENT_CALL_LOG_START = 'FETCH_CLIENT_CALL_LOG_START';
export const FETCH_CLIENT_CALL_LOG_SUCCESS = 'FETCH_CLIENT_CALL_LOG_SUCCESS';

// SEND EMAIL FOR LAYOUT VERIFICATION

export const SEND_EMAIL_LAYOUT_START = 'SEND_EMAIL_LAYOUT_START';
export const SEND_EMAIL_LAYOUT_SUCCESS = 'SEND_EMAIL_LAYOUT_SUCCESS';

//upload logs
export const OPENING_LOG_START = 'OPENING_LOG_START';
export const OPENING_LOG_SUCCESS = 'OPENING_LOG_SUCCESS';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const FILEVIEWER = 'FILEVIEWER';

// Generate and verify otp success

export const GENERATE_OTP_SUCCESS = 'GENERATE_OTP_SUCCESS';
export const GENERATE_OTP_FAILED = 'GENERATE_OTP_FAILED';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILED = 'VERIFY_OTP_FAILED';

//Ticket System
export const FETCH_TICKETS_SUCCESS = 'FETCH_TICKETS_SUCCESS';
export const FETCH_TICKETS_START = 'FETCH_TICKETS_START';
export const CREATE_TICKET_FAILED = 'CREATE_TICKET_FAILED';
export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS';
export const FETCH_TICKETID_SUCCESS = 'FETCH_TICKETID_SUCCESS';
export const FETCH_TICKETID_START = 'FETCH_TICKETID_START';
export const UPDATE_TICKET_SUCCESS = 'UPDATE_TICKET_SUCCESS';
export const UPDATE_TICKET_START = 'UPDATE_TICKET_START';
export const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS';
export const UPDATE_COMMENT_START = 'UPDATE_COMMENT_START';
export const REOPEN_TICKET_START = 'REOPEN_TICKET_START';
export const REOPEN_TICKET_SUCCESS = 'REOPEN_TICKET_SUCCESS';
export const FETCH_LAST_COMMENT = 'FETCH_LAST_COMMENT';
// export const UPDATE_COMPLAINTS_START = 'UPDATE_COMPLAINT_START';
// export const UPDATE_COMPLAINTS_SUCCESS = 'UPDATE_COMPLAINT_SUCCESS';
// export const CREATE_COMPLAINT_FAILED = 'CREATE_COMPLAINT_FAILED';
// export const CREATE_COMPLAINT_SUCCESS = 'CREATE_COMPLAINT_SUCCESS';
//inbound & outbound CALLS
//export const SET_USERROLE_START = 'SET_USERROLE_START';
//export const SET_USERROLE_COMPLETED = 'SET_USERROLE_COMPLETED';
//export const SET_USERROLE_SUCCESS = 'SET_USERROLE_SUCCESS';
export const FETCHING_INBOUND_CALL_START = 'FETCHING_INBOUND_CALL_START';
export const FETCHING_INBOUND_CALL_COMPLETED = 'FETCHING_INBOUND_CALL_COMPLETED';
export const FETCHING_INBOUND_CALL_SUCCESS = 'FETCHING_INBOUND_CALL_SUCCESS';
export const FETCH_CURRENT_DATA_SUCCESS = 'FETCH_CURRENT_DATA_SUCCESS';
export const FETCH_CURRENT_DATA_START = 'FETCH_CURRENT_DATA_START';
export const FETCH_OUTBOUND_DATA_SUCCESS = 'FETCH_OUTBOUND_DATA_SUCCESS';
export const FETCH_OUTBOUND_DATA_START = 'FETCH_OUTBOUND_DATA_START';

export const FETCH_CONFIRMED_DATA_SUCCESS = 'FETCH_CONFIRMED_DATA_SUCCESS';
export const FETCH_CONFIRMED_DATA_START = 'FETCH_CONFIRMED_DATA_START';

//Escalation View
export const FETCH_ESCALATIONS_SUCCESS = 'FETCH_ESCALATIONS_SUCCESS';
export const FETCH_ESCALATIONS_START = 'FETCH_ESCALATIONS_START';

//Sales Analytics New
export const FETCH_SALESANALYTICS_SUCCESS = 'FETCH_SALESANALYTICS_SUCCESS';

//Ticketing Report
export const FETCH_TICKETREPORT_SUCCESS = 'FETCH_TICKETREPORT_SUCCESS';
export const FETCH_HISTORY_SUCCESS = 'FETCH_HISTORY_SUCCESS';

//DPP
export const FETCH_DPP_SUCCESS = 'FETCH_DPP_SUCCESS';
export const FETCH_ELIGIBILITY_DPP_SUCCESS = 'FETCH_ELIGIBILITY_DPP_SUCCESS';

//PE TASKS
export const FETCH_PETASKS_SUCCESS = 'FETCH_PETASKS_SUCCESS';
export const FETCH_QUOTEID_PE_SUCCESS = 'FETCH_QUOTEID_PE_SUCCESS';
export const FETCH_PE_MAPPING_SUCCESS = 'FETCH_PE_MAPPING_SUCCESS';
export const FETCH_NAME_MAPPING_SUCCESS = 'FETCH_NAME_MAPPING_SUCCESS';
export const FETCH_CITY_MAPPING_SUCCESS = 'FETCH_CITY_MAPPING_SUCCESS';
export const FETCH_STATE_SUBSIDY_MAPPING = 'FETCH_STATE_SUBSIDY_MAPPING';
export const FETCH_CITY_DISCOM_MAPPING = 'FETCH_CITY_DISCOM_MAPPING';
export const UPDATE_AUTO_ASSIGN_STATUS = 'UPDATE_AUTO_ASSIGN_STATUS';

//Payment Portal
export const FETCH_ALL_PAYMENTS_START = 'FETCH_ALL_PAYMENTS_START'
export const FETCH_ALL_PAYMENT_SUCCESS = 'FETCH_ALL_PAYMENT_SUCCESS';
export const FETCH_ALL_PAYMENT_ERROR = 'FETCH_ALL_PAYMENT_ERROR';
export const FETCH_PAYMENT_PORTAL_MAPPING_START = 'FETCH_PAYMENT_PORTAL_MAPPING_START';
export const FETCH_PAYMENT_PORTAL_MAPPING_SUCCESS = 'FETCH_PAYMENT_PORTAL_MAPPING_SUCCESS';
export const FETCH_PAYMENT_HISTORY_START = 'FETCH_PAYMENT_HISTORY_START';
export const FETCH_PAYMENT_HISTORY_SUCCESS = 'FETCH_PAYMENT_HISTORY_SUCCESS';
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_START = 'CREATE_PAYMENT_START';
export const CREATE_PAYMENT_ERROR = 'CREATE_PAYMENT_ERROR';

export const FETCH_V2_DATA_START = 'FETCH_V2_DATA_START';
export const FETCH_V2_DATA_SUCCESS = 'FETCH_V2_DATA_SUCCESS';
export const FETCH_V2_DATA_ERROR = 'FETCH_V2_DATA_ERROR';

export const FETCH_V2_POCS_START = 'FETCH_V2_POCS_START';
export const FETCH_V2_POCS_SUCCESS = 'FETCH_V2_POCS_SUCCESS';

export const FETCH_PAYMENT_COMMENTS_START = 'FETCH_PAYMENT_COMMENTS_START';
export const FETCH_PAYMENT_COMMENTS_SUCCESS = 'FETCH_PAYMENT_COMMENTS_SUCCESS';

// Panel Inverter Status
export const FETCH_PANEL_INVERTER_STATUS_SUCCESS = "FETCH_PANEL_INVERTER_STATUS_SUCCESS";

//Ops report
export const FETCHING_OPS_REPORT_START = 'FETCHING_OPS_REPORT_START';
export const FETCHING_OPS_REPORT_SUCCESS = 'FETCHING_OPS_REPORT_SUCCESS';

//PM Tasks
export const FETCHING_PM_TASKS_START = 'FETCHING_PM_TASKS_START';
export const FETCHING_PM_TASKS_SUCCESS = 'FETCHING_PM_TASKS_SUCCESS';
export const CREATING_PM_TASKS_SUCCESS = 'CREATING_PM_TASKS_SUCCESS';

// PE Tasks Centers 
export const CREATE_NEW_CENTER_START = `CREATE_NEW_CENTER_START`;
export const CREATE_NEW_CENTER_SUCCESS = `CREATE_NEW_CENTER_SUCCESS`;
export const FETCH_CENTERS_START = `FETCH_CENTERS_START`;
export const FETCH_CENTERS_SUCCESS = `FETCH_CENTERS_SUCCESS`;
export const FETCH_CITIES = `FETCH_CITIES`;

//SALES TARGET ACTIONS
export const FETCH_SALES_TARGET_SUCCESS = 'FETCH_SALES_TARGET_SUCCESS';
export const FETCH_TARGET_ACCESS_SUCCESS = 'FETCH_TARGET_ACCESS_SUCCESS';

// GENERATE INVOICE ACTIONS
export const GENERATE_INVOICE_STATUS = 'GENERATE_INVOICE_STATUS';
export const GENERATE_INVOICE_SUCCESS = 'GENERATE_INVOICE_SUCCESS';
export const UPDATE_INVOICE_DATA = 'UPDATE_INVOICE_DATA';