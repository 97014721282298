import { ADD_DELIVERY_TEAM_START, ADD_DELIVERY_TEAM_SUCCESS } from './actiontypes';

import { base } from '../connection';

import { fetch, set, push, fetchOrderByChild, fetchOrderByKey, fetchOrderByValue } from '../helper/firebaseFunctions';

import { db } from '../connection.js';

export function addToDelivery(teams, userid) {
	return (dispatch) => {
		dispatch({type : ADD_DELIVERY_TEAM_START});
    addEmployeeToDelivery(teams, userid)
    .then(() => {
			db.ref("/addemployeetodeliveryrequest/" + userid).on("child_changed", function(snapshot) {
			  let changedData = snapshot.val();
				console.log("Event Triggered", changedData);
				if (changedData == 1) {
					console.log("ADD_DELIVERY_TEAM_SUCCESS");
					dispatch({type : ADD_DELIVERY_TEAM_SUCCESS, payload : {}});
				}
			});
    })
	}
}

const addEmployeeToDelivery = async (teams, userid) => {
	try {
		let newTeam = {};
		Object.keys(teams).map(team => {
			switch(teams[team]){
				case 600 : newTeam[team] = "Engineer"; break;
				case 700 : newTeam[team] = "Associate"; break;
				case 800 : newTeam[team] = "Admin"; break;
			}
		})
		let addDeliveryMapping = await db.ref("/addemployeetodeliveryrequest/" + userid).set({
			employeeid : userid,
			response : 0,
			roles : Object.values(newTeam),
			team : Object.keys(newTeam)
		});
		return true;
	} catch (e) {
		console.log(e);
    return false;
	}
}

// export function addToDelivery(teams, userid) {
// 	return (dispatch) => {
// 		dispatch({type : ADD_DELIVERY_TEAM_START});
//     addDeliveryMapping(teams, userid)
//     .then(() => {
//       dispatch({type : ADD_DELIVERY_TEAM_SUCCESS, payload : {}});
//     })
// 	}
// }

const addDeliveryMapping = async (teams, userid) => {
  try {
    let final = await Promise.all(Object.keys(teams).map(async (team) => {
      let roleDescription = "";
      switch(teams[team]){
        case 600 : roleDescription = "Engineer"; break;
        case 700 : roleDescription = "Associate"; break;
        case 800 : roleDescription = "Admin"; break;
      }
      let addDeliveryMapping = await db.ref("/delivery/teamtoEmployeeMapping/" + team + "/members/" + userid).set({
        employeeId : userid,
        role : teams[team],
        roleDescription : roleDescription
      });
    }))
    return final;
  } catch (e) {
    console.log(e);
    return false;
  }
}
