import { UPDATE_TICKET_SUCCESS, UPDATE_TICKET_START, REOPEN_TICKET_START, REOPEN_TICKET_SUCCESS } from './actiontypes';
import { dateChanger } from '../helper/dateChanger';
import { base, db } from '../connection';

export function updateTicket(obj, ticketId, type, quoteId, closeTime, ticketType, oldData) {
    return (dispatch) => {
        dispatch({ type: UPDATE_TICKET_START });
        console.log("Ticket data", obj);
        console.log(type, quoteId);
        let epoch = (new Date).getTime();
        let dueDate = obj.dueDate;
        dueDate = dueDate.split("-");
        dueDate = new Date(dueDate[1] + "-" + dueDate[2] + "-" + dueDate[0]).getTime() + 86399000;
        let updateObj = {};
        if(obj.status == 'Close'){
            updateObj = {
                dueDate: dueDate,
                responsibleTeam: obj.responsibleTeam,
                assignedTo: obj.assignedTo,
                priority: obj.priority,
                status: obj.status,
                text:obj.text?obj.text:'',
                removed:obj.removed,
                issueType:obj.issueType,
                source:obj.source,
                closeTime:closeTime,
                reasonForClose : obj.reasonForClose
            };
        }
        else{
            updateObj = {
                dueDate: dueDate,
                responsibleTeam: obj.responsibleTeam,
                assignedTo: obj.assignedTo,
                priority: obj.priority,
                status: obj.status,
                text:obj.text?obj.text:'',
                removed:obj.removed,
                issueType:obj.issueType,
                source:obj.source
            };
        }
        if(ticketType == 'Procurement'){
            updateObj.deliveryStatus = obj.deliveryStatus;
        }
        let updatedFields = {};
        Object.keys(updateObj).map(field => {
            if(updateObj[field]!=oldData[field]){
                updatedFields[field] = {
                    field:field,
                    oldVal: oldData[field],
                    newVal: updateObj[field]
                }
            }
        })
        console.log("Updated Fields", updatedFields)
        if(!Object.keys(updatedFields).length) return;
        let isDueDateChanged = updatedFields.dueDate?true:false;
        if(!isDueDateChanged || (isDueDateChanged && Object.keys(updatedFields).length > 1)) {
            updateObj.ticketUpdatedOn = new Date().getTime();
        }
        updateObj.updatedOn = new Date().getTime();
        sendEmailForClosedTicket(obj, type, quoteId, ticketType, oldData) 
        
        db.ref('/website/ticketSystem/tickets/' + ticketId).update(updateObj)
            .then(val => {
                console.log(val);
                // addFieldChangeComment(updatedFields, ticketId);
                dispatch({ type: UPDATE_TICKET_SUCCESS });
                if (obj.status == 'Close') {
                    db.ref(`website/ticketSystem/runningTickets/${ticketId}`).set(0)
                        .then(val => {
                            console.log("Ticket Closed");
                        })
                        .catch(e => {
                            console.log("Ticket not closed");
                        })
                }
            })
            .catch(e => {
                console.log(e);
            })
    }
}

export function reOpenTicket(quoteId, type, ticketId) {
    return (dispatch) => {
        dispatch({ type: REOPEN_TICKET_START });
        isExist(type, quoteId)
            .then(data => {
                if (data.status) {
                    let updateObj = {
                        status: 'Open'
                    };
                    db.ref('/website/ticketSystem/tickets/' + ticketId).update(updateObj)
                        .then(val => {
                            console.log(val);
                            let message = {
                                statusChanged: 1
                            };
                            dispatch({ type: REOPEN_TICKET_SUCCESS, payload: message });
                            db.ref(`website/ticketSystem/runningTickets/${ticketId}`).set(1)
                                .then(val => {
                                    console.log("Ticket Closed");
                                })
                                .catch(e => {
                                    console.log("Ticket not closed");
                                })
                        })
                        .catch(e => {
                            console.log(e);
                        })
                }
                else {
                    let message = {
                        error: `Ticket already exists with Ticket Id - ${data.existingTicket}`
                    };
                    //alert(message.error);
                    dispatch({ type: REOPEN_TICKET_SUCCESS, payload: message });
                }
            })
    }
}

function isExist(ticketType, quoteId) {
    return new Promise((resolve, reject) => {
        let returnVal = {
            existingTicket: '',
            status: false
        }
        db.ref(`website/ticketSystem/quoteToTypeMap/${quoteId}/${ticketType}`).once('value')
            .then(snapshot => {
                let ticketId = snapshot.val();
                if (ticketId) {
                    db.ref(`website/ticketSystem/runningTickets/${ticketId}`).once('value')
                        .then(snapshot2 => {
                            let val = snapshot2.val();
                            if (val && val == 1) {
                                returnVal.existingTicket = ticketId;
                                returnVal.status = false;
                                resolve(returnVal);
                            }
                            else {
                                returnVal.existingTicket = '';
                                returnVal.status = true;
                                resolve(returnVal);
                            }
                        })
                }
                else {
                    returnVal.existingTicket = '';
                    returnVal.status = true;
                    resolve(returnVal);
                }
            })
            .catch(e => {
                console.log(e);
                reject(e);
            })
    });
}


const sendEmailForClosedTicket = async (obj, type, quoteId, ticketType, oldData) => {
    if (type == 'Welcome Call__Welcome Call' && ticketType == 'Welcome Call' && obj.status == 'Close' && obj.status != oldData.status){
        const { siteId } = (await db.ref('/quotestaken/' + quoteId).once("value")).val();
        let { mSite_paymentterms, mSite_pv_option, mSite_in_option, mSite_finalPrice } =  (await db.ref('/sites/' + siteId).once("value")).val()
        let { promisedPanel, promisedInverter } = (await db.ref('/delivery/webPortal/sites/' + siteId).once("value")).val();
        let paymentTerms = mSite_paymentterms || ''
        let panelPromisedToDeliver = promisedPanel || mSite_pv_option[0] || ''
        let inverterPromisedToDeliver = promisedInverter || mSite_in_option[0] || ''
        let finalPriceWithoutOTT = +mSite_finalPrice || ''
        let OC_report = (await db.ref(`/customerreports/${quoteId}`).once("value")).val()
        if(paymentTerms, panelPromisedToDeliver, inverterPromisedToDeliver, finalPriceWithoutOTT, OC_report.fileName, OC_report.downloadurl){
            let emailList = [oldData.email,'delivery@zunroof.com']
            const emailSubject = 'Order Summary'
            const emailMessage = `
                <!DOCTYPE html>
                    <html lang="en">
                        <head>
                            <meta charset="utf-8">
                            <meta name="viewport" content="width=device-width, initial-scale=1">
                        </head>
                        <body>
                            <div>
                                Dear Sir,
                                <br>
                                Greetings from Team Zunroof!
                                <br><br>
                                Welcome to the Zunroof family. Please find below the summary of your solar rooftop project. We have also attached a copy of the Order Confirmation report attached for your reference.
                                <br><br>
                                Order Summary
                                <br><br>
                                Project cost(excluding subsidy): Rs. ${finalPriceWithoutOTT} /-
                                <br>
                                Panel brand promised: ${panelPromisedToDeliver}
                                <br>
                                Inverter brand promised: ${inverterPromisedToDeliver}
                                <br>
                                Payment Terms: 
                                <br>
                                <pre>${paymentTerms}</pre>
                                <br>
                                Warm Regards,
                                <br>
                                ZunRoof Team
                                <br>
                                www.zunroof.com
                                <br>
                                www.facebook.com/zunroof
                                <br><br>
                                Support: +919319432702
                                <br>
                                Get your ZunRoof app: https://play.google.com/store/apps/details?id=com.zunroofprod.zunroofprod&hl=en
                            </div>
                        </body>
                    </html>
            `
            let jsonRequest = {
                "subject": emailSubject,
                "body": emailMessage,
                "emailList": emailList,
                "bccList":['delivery@zunroof.com'],
                "attachments": [{
                    "name": OC_report.fileName,
                    "url": OC_report.downloadurl
                }]
            };
            let apiUrl = 'https://www.zunroof.com/server/newSendEmail.php';
            let response = await fetch(apiUrl, {
                method: 'POST',
                body: JSON.stringify(jsonRequest),
                mode: 'cors'
            });
        }
    }
}