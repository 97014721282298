import { FETCH_PAYMENT_PORTAL_MAPPING_START, FETCH_PAYMENT_PORTAL_MAPPING_SUCCESS } from '../actions/actiontypes';

export default function (state = {}, action) {
  switch (action.type) {
    case FETCH_PAYMENT_PORTAL_MAPPING_START: {
      return {};
    }
    case FETCH_PAYMENT_PORTAL_MAPPING_SUCCESS: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}  