import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import Select from 'react-select';
import { changeToViewDateTime, changeToInputDate, changeToViewDate } from '../helper/dateChanger';
import { Restrict } from '../components/Restrict';
import { dppColumns, columnsEligibilityData } from '../config/column_fields';
import { fetchDpp, fetchEligibility } from '../actions/fetchDpp';
import { csvDPP } from '../config/csv_fields';
import { CSVLink } from 'react-csv';

class Dpp extends Component {


    constructor(props) {
        super(props);
        this.state = {
            viewData1: [],
            loading: true,
            dppColumns:dppColumns,
            selectView:"files",
            columnsEligibilityData: columnsEligibilityData,
            viewData2:[],
            mapping:{}
        };
    }

    componentDidMount() {
        if (this.props.user) {
            if (this.props.user.level >= 2500 || this.props.user.level === 1950) {
                this.props.fetchDpp();
                this.props.fetchEligibility();
            }
        }
    }


    componentWillReceiveProps(props) {
        console.log(props);
        let { dppData, eligibilityData } = props;
        let { data = [], mapping = {} } = eligibilityData||{};
        this.setState({
            viewData1: dppData,
            viewData2: data,
            mapping,
            eligData: data,
            loading:false
        },() => {
            console.log(this.state);
        })
    }

    handleOnViewChange = (value) => {
        this.setState({
            selectView: value
        })
    }

    handleSelectChangeFilters = (e, name) => {
        let value = (e||[]).map(val => val.value)
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value
            }
        })
    }

    handleInputChangeFilters = (e) => {
        let { name, value } = e.target;
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value
            }
        })
    }

    applyFilters = () => {
        console.log("af", this.state.filters)
        let { startDate, endDate, state=[], city=[], appStatus=[], bureauFile=[], salesPerson=[], otpEntered=[] } = this.state.filters;
        let { eligData } = this.state;
        let startEpoch = startDate?new Date(`${startDate} 00:00:00`).getTime():0;
        let endEpoch = endDate?new Date(`${endDate} 00:00:00`).getTime() + 86400000:9999999999999;
        let filteredData = (eligData||[]).filter(row => {
            return(
                ( state.length>0?state.includes(row.state):true ) &&
                ( city.length>0?city.includes(row.city):true ) &&
                ( appStatus.length>0?appStatus.includes(row.appStatus):true ) &&
                ( bureauFile.length>0?bureauFile.includes(row.bureauFile):true ) &&
                ( salesPerson.length>0?salesPerson.includes(row.salesPerson):true ) &&
                ( otpEntered.length>0?otpEntered.includes(row.otpEntered):true ) &&
                ( row.date>=startEpoch && row.date<=endEpoch )
            )
        })
        this.setState({
            viewData2: filteredData
        })
    }

    render() {
        if (this.props.user === null) {
            return <Redirect to={"/login?redirect_uri=dpp"} />;
        }

        if (this.props.user && (this.props.user.level < 2500 && this.props.user.level!=1950)) {
            return (
                <div>
                    <Restrict />
                </div>
            )
        }
        console.log(this.state);
        let { selectView, columnsEligibilityData, viewData2, mapping } = this.state;
        let { handleOnViewChange, handleSelectChangeFilters, handleInputChangeFilters, applyFilters } = this;
        return (
            <div className="container-fluid" style={{ marginTop: "65px" }}>
                <div className="formButtons" style={{ marginBottom: "20px" }}>
                    <button className={`btn btn-primary paymentViewButton btn-color ${selectView === "files" ? "select" : ""}`} name={"files"} onClick={(e) => handleOnViewChange("files")} value={"files"}>{"Files"}</button>
                    <button className={`btn btn-primary paymentViewButton btn-color ${selectView === "eligibility" ? "select" : ""}`} name={"eligibility"} onClick={(e) => handleOnViewChange("eligibility")} value={"eligibility"}>{"Eligibility"}</button>
				</div>
                {selectView=='files'?<div className="card shadow">
                    <div className="card-body">
                        <div className="card-title">
                            <h5>MPP</h5>
                        </div>
                        <ReactTable
                            filterable={true}
                            sortable={true}
                            showPagination={true}
                            defaultPageSize={10}
                            data={this.state.viewData1}
                            columns={this.state.dppColumns}
                            defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                            loading={this.state.loading}
                            style={{ textAlign: 'center' }}
                        />
                    </div>
                </div>:""}
                {selectView=='eligibility'?<div>
                <CSVLink filename={"DPP_Data.csv"} data={viewData2} headers={csvDPP} type="button" className="btn btn-info">
								Download
							</CSVLink>
                    <div className="row filters">
                        <div className="col-12 col-md-2" style={{ width: '100%', paddingTop: '1%' }}>
                            <div className="form-group">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">Start Date</span>
                                    </div>
                                    <input className="form-control customInput" type="date" name={"startDate"} placeholder={"Start Date"} onChange={(e) => handleInputChangeFilters(e)} />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-2" style={{ width: '100%', paddingTop: '1%' }}>
                            <div className="form-group">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">End Date</span>
                                    </div>
                                    <input className="form-control customInput" type="date" name={"endDate"} placeholder={"End Date"} onChange={(e) => handleInputChangeFilters(e)} />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-2" style={{ width: '100%', paddingTop: '1%' }}>
                            <Select
                                isMulti
                                isSearchable
                                placeholder={"Select State"}
                                name={"state"}
                                options={Object.keys(mapping.stateMap||{}).map(state => { return({label:state, value:state}) })}
                                onChange={(e) => handleSelectChangeFilters(e, "state")}
                            />
                        </div>
                        <div className="col-12 col-md-2" style={{ width: '100%', paddingTop: '1%' }}>
                            <Select
                                isMulti
                                isSearchable
                                placeholder={"Select City"}
                                name={"city"}
                                options={Object.keys(mapping.cityMap || {}).map(city => { return({label:city, value:city}) })}
                                onChange={(e) => handleSelectChangeFilters(e, "city")}
                            />
                        </div>
                        <div className="col-12 col-md-2" style={{ width: '100%', paddingTop: '1%' }}>
                            <Select
                                isMulti
                                isSearchable
                                placeholder={"Select App Status"}
                                name={"appStatus"}
                                options={[{label:'Approved', value:'Approved'}, {label:'Declined', value:'Declined'}]}
                                onChange={(e) => handleSelectChangeFilters(e, "appStatus")}
                            />
                        </div>
                        {/* <div className="col-12 col-md-2" style={{ width: '100%', paddingTop: '1%' }}>
                            <Select
                                isMulti
                                isSearchable
                                placeholder={"Select Bureau File"}
                                name={"bureauFile"}
                                options={[{label:'Approved', value:'Approved'}, {label:'Declined', value:'Declined'}]}
                                onChange={(e) => handleSelectChangeFilters(e, "bureauFile")}
                            />
                        </div> */}
                        <div className="col-12 col-md-2" style={{ width: '100%', paddingTop: '1%' }}>
                            <Select
                                isMulti
                                isSearchable
                                placeholder={"Select Sales Person"}
                                name={"salesPerson"}
                                options={Object.keys(mapping.salesPersonMap||{}).map(salesPerson => { return({label:salesPerson, value:salesPerson}) })}
                                onChange={(e) => handleSelectChangeFilters(e, "salesPerson")}
                            />
                        </div>
                        <div className="col-12 col-md-2" style={{ width: '100%', paddingTop: '1%' }}>
                            <Select
                                isMulti
                                isSearchable
                                placeholder={"Select OTP Enetered"}
                                name={"otpEntered"}
                                options={[{label:'Yes', value:'Yes'}, {label:'No', value:'No'}]}
                                onChange={(e) => handleSelectChangeFilters(e, "otpEntered")}
                            />
                        </div>
                        <div className="col-12 col-md-2" style={{ width: '100%', paddingTop: '1%' }}>
                            <button className="btn btn-success" onClick={() => applyFilters()}>Apply Filter</button>
                        </div>
                    </div>
                    <div className="card shadow mt-3">
                    <div className="card-body">
                        <div className="card-title">
                            <h5>Eligibility</h5>
                        </div>
                        <ReactTable
                            filterable={true}
                            sortable={true}
                            showPagination={true}
                            defaultPageSize={10}
                            data={viewData2}
                            columns={columnsEligibilityData}
                            defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                            loading={this.state.loading}
                            style={{ textAlign: 'center' }}
                        />
                    </div>
                </div></div>:""}
            </div>
        );


    }
}



function mapStateToProps(state) {
    console.log(state);
    return {
        user: state.user,
        dppData:state.fetchdpp?state.fetchdpp.data:[],
        eligibilityData:state.fetchdpp?state.fetchdpp.eligibilityData:[],
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchDpp, fetchEligibility }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Dpp);