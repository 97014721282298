import { ADDING_COMMENT_STATUS } from './actiontypes';

import { base } from '../connection';

import { getCommentProcessRequest } from '../helper/addComments';


export function setebissue(siteId,user,data,newEBIssue,oldEBIssue) {
	console.log(new Date());
	return (dispatch) => {
		pushissue(dispatch,siteId,user,data,newEBIssue,oldEBIssue);
	}
}


function pushissue(dispatch,siteId,user,data,newEBIssue,oldEBIssue) {
	base.post('/delivery/Issue/statusbysite/'+siteId+'/siteissue', {
	    data: data
	    ,then(err){
	      if(!err){
	      	console.log("SUCCESS");
	      	let processCheck = {
	      		siteId:siteId,
                user:user,
                nameVar:"EbIssue",
                newChangeValue:newEBIssue.toString(),
                oldChangeValue:oldEBIssue.toString(),
	      	}
	      	dispatch({type:ADDING_COMMENT_STATUS,payload:true})
	      	getCommentProcessRequest(processCheck,dispatch);
	      }else{
	      	console.log(err);
	      }
	    }
	  });
}