import { FETCH_PANEL_INVERTER_STATUS_SUCCESS } from './actiontypes';
import axios from 'axios';

export function fetchPanelInverterStatus() {
    return async (dispatch) => {
        let response = await axios.post("https://operations.zunroof.com/api/fetchorderstatus", { key: "hHnWTk8pHb2MZBh" });
        response = response.data;
        if(response.error){
            alert("Some Error Occured");
            return;
        }
        console.log("resp", response)
        dispatch({ type: FETCH_PANEL_INVERTER_STATUS_SUCCESS, payload: response || {} })
    }
}
