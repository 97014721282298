import { FETCH_CLIENT_CALL_LOG_START, FETCH_CLIENT_CALL_LOG_SUCCESS } from './actiontypes';

import { base, db } from '../connection';

import moment from 'moment';

export function fetchClientCallLog(quoteId) {
	console.log(new Date());
  	return (dispatch) => {        
    	dispatch({type:FETCH_CLIENT_CALL_LOG_START});
        db.ref('/mapstore/quoteidtocustomerid/'+quoteId).once('value')
        .then(snapshot => {
            //console.log(Object.values(snapshot.val()));
            //dispatch({type:FETCH_COMPLAINTS_SUCCESS,payload:Object.values(snapshot.val())})
            fetchCalls(dispatch,snapshot.val());
        })
        .catch(e => {
            return e;
        })
 	}
}


async function fetchCalls(dispatch, userId){
    let callData = (await db.ref(`delivery/webPortal/knowlarity/customerCallLogData/${userId}`).once('value')).val();
    callData = callData?Object.values(callData):[];

    let totalData = callData.map(data => {
        if(data.Call_Type == 0){
            data.call_type = "Incoming" 
        }
        if(data.Call_Type == 1){
            data.call_type  = "Outgoing"     
        }
        
        data.call_date = moment(data.start_time).format("x");
        console.log("100", data.call_date)
        return data;
    })
    dispatch({type:FETCH_CLIENT_CALL_LOG_SUCCESS,payload:totalData });
}

// "2020 - 07 - 27 14: 21: 56 + 05: 30"

// function fetchCalls(dispatch,userId){
//     console.log(userId);
//     let userPhone = userId.substring(3,userId.length);
//     console.log(userPhone);
//     // fetchCallDataPart1(userId).then( data => {
//     //     console.log(data);
//     // });
//     Promise.all([fetchCallData(userId),fetchCallData(userPhone)]).then(result => {
//         let totalCallData = [];
//         let callDataPart1 = [];
//         let callDataPart2 = [];
//         if(result[0]){
//             callDataPart1 = Object.values(result[0]);
//         }
//         if(result[1]){
//             callDataPart2 = Object.values(result[1]);
//         }
//         totalCallData = [...callDataPart1,...callDataPart2];
//         console.log(totalCallData);
//         console.log(result);
//         dispatch({type:FETCH_CLIENT_CALL_LOG_SUCCESS,payload:totalCallData});
//     })
// }


// function fetchCallData(phoneNumber) {
// 	return new Promise((resolve,reject) => {
// 		db.ref('delivery/webPortal/clientCallLogAll/'+phoneNumber).once('value')
//         .then(snapshot => {
//             resolve(snapshot.val());
//         })
//         .catch(e => {
//             reject(e);
//         })
// 	});
// }


// function fetchUserInfo(userId, data) {
// 	return new Promise((resolve, reject) => {
// 		fetch('users',userId)
// 		.then(val => {
// 			let { mUser_Contact } = val;
// 			let { mContact_FirstName, mContact_LastName } = mUser_Contact;
// 			let responsibleName = `${mContact_FirstName} ${mContact_LastName}`;
// 			resolve({...data, responsible: responsibleName});
// 		})
// 		.catch(error => {
// 			reject(error);
// 		})
// 	});
// }