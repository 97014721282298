import { UPDATE_TICKET_SUCCESS, UPDATE_TICKET_START } from '../actions/actiontypes'

const INIT_STATE = {
	completed:false,
}

export default function(state = INIT_STATE, action) {
	switch(action.type){
		case UPDATE_TICKET_START:{
			return {...state};
		}
		case UPDATE_TICKET_SUCCESS:{
			return {...state, completed:true};
		}
		default:
			return state;
	}
}