import React, { Component } from 'react';
import { styleSheet } from '../styles/styleSheet'
import { numberConverter, changeToViewDate, changeToInputDate } from '../helper/dateChanger';

import Select from 'react-select';
export class FrameTwo extends Component {
	constructor(props) {
	  super(props);
	  this.paymentTypes = [
		{ value: 'Billable', label: 'Billable' },
		{ value: 'Non-Billable', label: 'Non-Billable' }
	  ];
	  this.billableOptions = [
		{ value:'Extra Panel Wattage', label:'Extra Panel Wattage'},
		{ value:'Less Panel Wattage', label:'Less Panel Wattage'},
		{ value:'Inverter Upgrade', label:'Inverter Upgrade'},
		{ value: 'OC-Correction', label:'OC-Correction'},
		{ value:'System correction', label:'System correction'},
		{ value:'Adjustments', label:'Adjustments'},
		{ value:'Gift Adjustment',label:'Gift Adjustment'},
		{ value: 'Other', label:'Other' }
	  ];
	  this.nonBillableOptions = [
		{ value:'Bond', label:'Bond'},
		{ value:'SA Fees', label:'SA Fees'},
		{ value:'Net metring demand fees', label:'Net metring demand fees'},
		{ value: 'Meter processing fees', label:'Meter processing fees'},
		{ value: 'Meter testing fees', label:'Meter testing fees'},
		{ value:'Cheque Collection', label:'Cheque Collection'},
		{ value:'Load Change', label:'Load Change'},
		{ value:'Name Change', label:'Name Change'},
		{ value:'Phase Change', label:'Phase Change'},
		{ value:'Material paid by client',label:'Material paid by client'},
		{ value:'License report',label:'License report'},
		{ value:'Panel Delivery',label:'Panel Delivery'},
		{ value: 'Other', label:'Other' }
	  ]
	  //this.selectedOption = {};	
	  this.state = {
	  	totalPay: 0,
	  	totalBill:0,
	  	totalPercentage:0,
	  	edit:false,
	  	finalAmount:0,
	  	finalBeforeOtt:0,
	  	ottArray:[],
	  	newOttArray:[],
	  	save:false,
	  	add:true,
	  	payments:[],
		paymentEdit: false,
		selectedOption:{},
		paymentOptions:[],
		selectedPaymentOption:{}
	  };
	  this.handleEdit 			= this.handleEdit.bind(this);
	  this.handleAdd 			= this.handleAdd.bind(this);
	  this.handleSaveClick 		= this.handleSaveClick.bind(this);
	  this.handleLabelChange	= this.handleLabelChange.bind(this);
	  this.handleAmountChange	= this.handleAmountChange.bind(this);
	  this.handleClickDelete	= this.handleClickDelete.bind(this);
	  this.handleClickCancel	= this.handleClickCancel.bind(this);
	  this.handlePaymentTypeChange = this.handlePaymentTypeChange.bind(this);
	  this.handlePaymentSubTypeChange = this.handlePaymentSubTypeChange.bind(this);
	}

	componentDidMount() {
		console.log("DID MOUNT");
		let totalPay = 0;
		let totalPercentage = 0;
		let ottArray = [];
		let finalAmount = 0;

		let { bill, ottPayments, payments } = this.props;

		payments.map(pay => {
			console.log(pay)
			totalPay += parseFloat(pay.amount);
		});

		finalAmount += bill.length>0?parseFloat(bill[bill.length-1].amount):0;


		Object.keys(ottPayments).map((key,index) => {
			finalAmount 	+= ottPayments[key].price?parseInt(ottPayments[key].price):0;
			let options = [];
			let subType = [];
			let viewLabelInput = 'flex';
			if(ottPayments[key].type == 'Billable'){
				options = this.billableOptions;
				subType = {
					label:ottPayments[key].line,
					value:ottPayments[key].line
				}
				for (let i=0; i<options.length; i++) {
					if (options[i].value == ottPayments[key].line ) {
						viewLabelInput = 'none';
					 }
			   }
			}
			else if(ottPayments[key].type == 'Non-Billable'){
				options = this.nonBillableOptions;
				subType = {
					label:ottPayments[key].line,
					value:ottPayments[key].line
				}
				for (let i=0; i<options.length; i++) {
					if (options[i].value == ottPayments[key].line ) {
						viewLabelInput = 'none';
					 }
			   }
			}
			console.log(viewLabelInput);
			ottArray.push({
				id:index,
				label:ottPayments[key].line,
				price:ottPayments[key].price,
				type:ottPayments[key].type,
				subTypeOptions:options,
				subTypeSelected:subType,
				viewLabel:viewLabelInput
			})
		})
		console.log(ottArray);
		totalPercentage = Math.round((totalPay/finalAmount)*1000)/10;

		this.setState({
			totalPay,
			totalPercentage,
			finalAmount,
			ottArray,
			payments:[...[],...payments]
		},() => {
			console.log(this.state,this.props.bill,'121212')
		})
	}

	// HANDLE EDIT OLD OTT

	handleEdit() {
		this.setState({
			save:true,
			edit:true,
			add:false
		})
	}

	// HANDLE NEW ADD OTT

	handleAdd() {
		this.setState({
			newOttArray: [
				...this.state.newOttArray,
				{
					id:this.state.newOttArray.length,
					price:null,
					label:"",
					type:"",
					subTypeOptions:[],
					subTypeSelected:{},
					viewLabel:'none'
				}
			],
			save:true,
			add:true,
			edit:false
		})
	}

	// HANDLE CLICK SAVE

	handleSaveClick() {
		let finalAmount 	= this.props.bill.length>0?parseFloat(this.props.bill[this.props.bill.length-1].amount):0;
		let ottArrayClean 	= [...[],...this.state.newOttArray];
		let ottArrayList 	= [];
		let changeOttArray 	= [];
		let formatOttArray = [];

		// Cleaning the new data
		let ottArray = ottArrayClean.filter(val => {
			if(val.price && val.label && val.price!="" && val.label!=""){
				return true;
			}else{
				return false;
			}
		});

		// MERGING WITH THE OLD ARRAY
		ottArray = [...this.state.ottArray,...ottArray];	

		console.log("ottArray",ottArray);

		// CONVERTING IT INTO THE OTT FORMAT
		ottArray.map((val,index) => {
			let key = 'item'+ (index+1);
			let pType='';
			if(val.type){
				pType = val.type;
				// if(pType == 'Billable'){
				// 	val.label = val.label + "(B)";
				// }
				// else if(pType == 'Non-Billable'){
				// 	val.label = val.label + "(NB)";
				// }
				// else{
				// 	val.label = val.label + "(NA)";
				// }
			}
			else{
				pType = 'NA';
				//val.label = val.label + "(NA)";
			}
			ottArrayList[key] = {
				id:key,
				line:val.label,
				price:val.price,
				type:pType
			}
			changeOttArray[index] = {
				id:index,
				label:val.label,
				price:val.price,
				type:pType
			}
			finalAmount += parseFloat(val.price);
		});

		console.log("ottArrayList",ottArrayList);//////Here

		Object.keys(ottArrayList).map((key,index) => {
			//finalAmount 	+= ottArrayList[key].price?parseInt(ottArrayList[key].price):0;
			let options = [];
			let subType = [];
			let viewLabelInput = 'flex';
			if(ottArrayList[key].type == 'Billable'){
				options = this.billableOptions;
				subType = {
					label:ottArrayList[key].line,
					value:ottArrayList[key].line
				}
				for (let i=0; i<options.length; i++) {
					if (options[i].value == ottArrayList[key].line ) {
						viewLabelInput = 'none';
					 }
			   }
			}
			else if(ottArrayList[key].type == 'Non-Billable'){
				options = this.nonBillableOptions;
				subType = {
					label:ottArrayList[key].line,
					value:ottArrayList[key].line
				}
				for (let i=0; i<options.length; i++) {
					if (options[i].value == ottArrayList[key].line ) {
						viewLabelInput = 'none';
					 }
			   }
			}
			console.log(viewLabelInput);
			formatOttArray.push({
				id:index,
				label:ottArrayList[key].line,
				price:ottArrayList[key].price,
				type:ottArrayList[key].type,
				subTypeOptions:options,
				subTypeSelected:subType,
				viewLabel:viewLabelInput
			})
		})

		let totalPercentage = Math.round((parseFloat(this.state.totalPay)/parseFloat(finalAmount))*1000)/10;
		this.props.setottdata(this.props.siteid,this.props.user.phoneNumber,ottArrayList,this.props.ottPayments,Math.round(totalPercentage));
		console.log(this.state.ottArray);
		console.log(this.state.newOttArray);
		this.setState({
			newOttArray:[],
			finalAmount,
			ottArray:[...[],...formatOttArray],
			totalPercentage,
			edit:false,
			save:false,
			add:true
		})
		console.log(this.state.ottArray)
		console.log(ottArray)
		console.log(changeOttArray)
		if(this.props.user.level == 1900){
		const subject = String(this.props.data.quoteid) + " ott edited by " + String(this.props.user.phoneNumber)
		this.props.sendEmailOnEdit(changeOttArray, subject)
		}
	}


	// PAYMENT SAVE
	handlePaymentSaveClick = () => {
		let entryFault = false;
		let finalPayment = {};
		let oldData = this.state.payments;
		let index = 0;
		for (let i = 0; i < oldData.length; i++) {
			console.log(!oldData[i].amount,(new Date().getTime() - new Date(oldData[i].date).getTime()));
			if(!oldData[i].amount||(new Date().getTime() - new Date(oldData[i].date).getTime() < 0)) {
				entryFault = true;
			}
		}
		if(entryFault) {
			console.log("Got Some Error");
		}else{
			console.log("No Error Found",finalPayment);
			let totalPay = 0;
			let { totalPercentage, ottArray, payments } = this.state;
			let finalAmount 	= parseFloat(this.props.bill[this.props.bill.length-1].amount);
			ottArray.forEach(val => {
				finalAmount += parseInt(val.price)?parseInt(val.price):0;
			});

			payments.forEach(pay => {
				totalPay += parseInt(pay.amount)?parseInt(pay.amount):0;
			});

			totalPercentage = Math.round((parseInt(totalPay)/parseInt(finalAmount))*1000)/10;
			console.log(totalPercentage);
			console.log(this.props.siteid,this.props.user.phoneNumber,this.state.payments,this.props.payments,Math.round(totalPercentage),this.props.system.teamId);
			this.props.setPayments(this.props.siteid,this.props.data.quoteid,this.props.user.phoneNumber,this.props.payments,this.state.payments,Math.round(totalPercentage),this.props.system.teamId,(this.props.system.state_D||this.props.data.state),this.props.system.inverterdate, this.props.data.city);
			this.setState({
				totalPercentage,
				paymentEdit:false,
				totalPay
			})
			console.log(this.state.payments) //////Here
			if(this.props.user.level == 1900){
			const subject = String(this.props.data.quoteid) + " payment edited by " + String(this.props.user.phoneNumber)
			this.props.sendEmailOnEdit(this.state.payments, subject)
			}
		}
	}

	// ALLOW PAYMENT CHANGE
	handlePaymentChange = () => {
		this.setState({
			paymentEdit:!this.state.paymentEdit
		})
	}

	handlePaymentRemoveClick = (e) => {
		let oldData = this.state.payments;
		let newData = [];
		let index = e.target.getAttribute("data-key");
		for (let i = 0; i < oldData.length; i++) {
			if(index!=i) {
				console.log(oldData[i])
				newData.push({
					name:"Payment "+(newData.length+1),
					date:oldData[i].date,
					amount: oldData[i].amount
				});
			} 
		}
		this.setState({
			payments:[...[],...newData]
		})
	}

	// NEW PAYMENT INPUT ADD
	handlePaymentAdd = () => {
		let oldData = this.state.payments;
		let index = 0;
		for (let i = 0; i < oldData.length; i++) {
			if(oldData[i].name != "Payment "+(i+1)) {
				index = i+1;
				break;
			}
		}
		console.log(index,"  :  ",oldData.length);
		oldData.splice(index?index-1:oldData.length,0,{
			name:"Payment "+(index?index:oldData.length+1),
			date:new Date().getTime(),
			amount: 0
		});
		this.setState({
			payments: [...[],...oldData],
			paymentEdit:true
		})
	}

	// HANDLE LABEL CHANGE

	handleLabelChange(e,index,i,type) {
		let ottDummyArray = [];
		if(type==1) {
			ottDummyArray = [...[],...this.state.ottArray];
		} else {
			console.log(i,type)
			ottDummyArray = [...[],...this.state.newOttArray];	
		}
		if(ottDummyArray[index]) {
			ottDummyArray[index] = {
				id:i,
				label:e.target.value,
				price:ottDummyArray[index].price,
				type:ottDummyArray[index].type,
				subTypeOptions:ottDummyArray[index].subTypeOptions,
				subTypeSelected:ottDummyArray[index].subTypeSelected,
				viewLabel : ottDummyArray[index].viewLabel
			}
		}else{
			ottDummyArray.push({
				id:i,
				label:e.target.value,
				price:0,
				type:"",
				subTypeOptions:[],
				subTypeSelected:{},
				viewLabel : 'none'
			})
		}
		if(type===1){
			this.setState({
				ottArray:[...[],...ottDummyArray]
			});
		} else {
			this.setState({
				newOttArray:[...[],...ottDummyArray]
			});
		}
	}

	handlePaymentTypeChange(e, index, i, type){
		let ottDummyArray = [];
		if(type==1) {
			ottDummyArray = [...[],...this.state.ottArray];
		} else {
			console.log(i,type)
			ottDummyArray = [...[],...this.state.newOttArray];	
		}
		let subOptions = [];
		if(e.value=='Billable'){
			subOptions = this.billableOptions;
		}
		else{
			subOptions = this.nonBillableOptions;
		}
		if(ottDummyArray[index]) {
			ottDummyArray[index] = {
				id:i,
				label:ottDummyArray[index].label,
				price:ottDummyArray[index].price,
				type:e.value,
				subTypeOptions:subOptions,
				subTypeSelected:{},
				viewLabel : ottDummyArray[index].viewLabel
			}
		}else{
			ottDummyArray.push({
				id:i,
				label:"",
				price:0,
				type:e.value,
				subTypeOptions:subOptions,
				subTypeSelected:{},
				viewLabel : 'none'
			})
		}
		if(type===1){
			this.setState({
				ottArray:[...[],...ottDummyArray]
			});
		} else {
			this.setState({
				newOttArray:[...[],...ottDummyArray]
			});
		}
	}


	handlePaymentSubTypeChange(e, index, i, type){
		let ottDummyArray = [];
		if(type==1) {
			ottDummyArray = [...[],...this.state.ottArray];
		} else {
			console.log(i,type)
			ottDummyArray = [...[],...this.state.newOttArray];	
		}
		// let subOptions = [];
		// if(e.value=='billable'){
		// 	subOptions = this.billableOptions;
		// }
		// else{
		// 	subOptions = this.nonBillableOptions;
		// }
		let view='none';
		let labelInput = '';
		if(e.value=='Other'){
			view = 'flex'
			labelInput = ''
		}
		else{
			view = 'none'
			labelInput = e.value
		}
		if(ottDummyArray[index]) {
			ottDummyArray[index] = {
				id:i,
				label:labelInput,
				price:ottDummyArray[index].price,
				type:ottDummyArray[index].type,
				subTypeOptions:ottDummyArray[index].subTypeOptions,
				subTypeSelected:e,
				viewLabel : view
			}
		}else{
			ottDummyArray.push({
				id:i,
				label:labelInput,
				price:0,
				type:"",
				subTypeOptions:{},
				subTypeSelected:e,
				viewLabel : view
			})
		}
		if(type===1){
			this.setState({
				ottArray:[...[],...ottDummyArray]
			});
		} else {
			this.setState({
				newOttArray:[...[],...ottDummyArray]
			});
		}
	}
	// HANDLE AMOUNT CHANGE

	handleAmountChange(e,index,i,type) {
		let ottDummyArray = [];
		if(type==1) {
			ottDummyArray = [...[],...this.state.ottArray];
		} else {
			ottDummyArray = [...[],...this.state.newOttArray];	
		}

		if(parseInt(e.target.value)<0) {
			if(this.props.user.level==1800 || this.props.user.level==2600 || this.props.user.level==1900 || this.props.user.level==2300) {
				if(ottDummyArray[index]) {
					ottDummyArray[index] = {
						id:i,
						label:ottDummyArray[index].label,
						price:e.target.value,
						type:ottDummyArray[index].type,
						subTypeOptions:ottDummyArray[index].subTypeOptions,
						subTypeSelected:ottDummyArray[index].subTypeSelected,
						viewLabel : ottDummyArray[index].viewLabel
					}
				}else{
					ottDummyArray.push({
						id:i,
						label:"",
						price:e.target.value,
						type:"",
						subTypeOptions:[],
						subTypeSelected:{},
						viewLabel : 'none'
					})
				}
			}
		}else{
			if(ottDummyArray[index]) {
				ottDummyArray[index] = {
					id:i,
					label:ottDummyArray[index].label,
					price:e.target.value,
					type:ottDummyArray[index].type,
					subTypeOptions:ottDummyArray[index].subTypeOptions,
					subTypeSelected:ottDummyArray[index].subTypeSelected,
					viewLabel : ottDummyArray[index].viewLabel
				}
			}else{
				ottDummyArray.push({
					id:i,
					label:null,
					price:e.target.value,
					type:"",
					subTypeOptions:[],
					subTypeSelected:{},
					viewLabel : 'none'
				})
			}
		}

		if(type===1) {
			this.setState({
				ottArray:[...[],...ottDummyArray],
			});
		} else {
			this.setState({
				newOttArray:[...[],...ottDummyArray],
			});
		}
	}

	// REMOVE OTT
	handleClickDelete(index) {
		let ottDummyArray = [...[],...this.state.ottArray];
		let ottArray = ottDummyArray.filter(val => {
			if(val.id!=index){
				return true;
			}
			return false;
		});
		this.setState({
			ottArray:[...[],...ottArray]
		});
	}

	// CANCEL OTT UPDATE
	handleClickCancel(index) {
		let ottDummyArray = [...[],...this.state.newOttArray];
		let ottArray = ottDummyArray.filter(val => {
			if(val.id!==index){
				return true;
			}
			return false;
		});
		this.setState({
			newOttArray:[...[],...ottArray],
			save:this.state.edit||ottArray.length>0?true:false,
			edit:false,
			add:true
		})
	}

	// HANDLE PAYMENT INPUT
	handlePaymentInputChange = (e) => {
		let oldData = this.state.payments;
		let value = e.target.value;
		let name  = e.target.name;
		let index = e.target.getAttribute("data-key");
		console.log(index, value, name);
		oldData[index][name] = value;
		console.log(oldData);
		this.setState({
			payments: oldData
		})
	}

	handleSelectChange = (e) => {
		console.log(e);
		if(e.value=='Billable'){
		this.setState({
			selectedOption:e,
			paymentOptions:this.billableOptions
		});
		}
		else{
			this.setState({
				selectedOption:e,
				paymentOptions:this.nonBillableOptions
			});
		}
	}


	render() {
		const style = {
			hide : {
				display:'none'
			},
			show : {
				display:''
			}
		}

		console.log(this.state.ottArray);

		return (
			<div className="row row_data">
				<div className="col-12">
					<div className="page-title">
						<div style={styleSheet.titlePage}>
							{
								<div style={styleSheet.titlePage.quote}>
								Payments {
									// <span className="alert alert-warning" style = {{fontSize:'12px'}} role="alert"> this.state.updateStatus.text </span>
								}
								</div>
						}
							<div style={styleSheet.titlePage.actions}>
							  	{this.state.add?<i onClick={this.handleAdd} className="fas fa-plus-circle circle_create"></i>:""}
								{(this.props.user.level==1800||this.props.user.level==1900||this.props.user.level==2300||this.props.user.level==2600)&&!this.state.save?<i onClick={this.handleEdit} className="fas fa-pen" style={styleSheet.titlePage.actions.edit}></i>:""}
								{this.state.save?<div className="input-group-append col-2">
							    	<button  className="btn btn-sm btn-success"  onClick = {this.handleSaveClick}>Save</button>
							  	</div>:""}
							</div>
						</div>
					</div>
				</div>
				<div className="col-12">
					<div className="page_data page_data__background">
						<div className="row row_data">
							<div className="col-md-4 page_data__1">
								<div className="row_head">
									<div className="row row_data">System Cost</div>
								</div>
								{
									this.props.bill.map(pay => {
										return (
											<div className="row row_data"  key ={pay.name+"."+pay.amount}>
												<span className="row_data__key col-6">{pay.name}</span>
												<span className="row_data__value col-6">{numberConverter(pay.amount)}</span>
											</div>
										)
									})
								}
								{
									this.state.ottArray.map(({id,label,price,type,subTypeOptions,subTypeSelected,viewLabel},index) => {
										let labelToShow;
										if(type == 'Billable'){
											labelToShow = label + "(B)";
										}
										else if(type == 'Non-Billable'){
											labelToShow = label + "(NB)";
										}
										else{
											labelToShow = label + "(NA)";
										}
										let paymentType = {
											label:type,
											value:type
										}
										return (
											<div>
												<div className="row row_data" style={this.state.edit?style.hide:style.show} key = {id}>
													<span className="row_data__key col-6" >{labelToShow}</span>
													<span className="row_data__value col-6" >{numberConverter(price)}</span>
												</div>
												<div style={this.state.edit?style.show:style.hide}>
												<div className="row row_data">
													<div className="row_data__key col-6 input-group" style={{display:'block'}}>
													<Select
														key = {id}
														value={paymentType}
														onChange={e => this.handlePaymentTypeChange(e,index,id,1)}
														options={this.paymentTypes}
													/>
													</div>
													<div className="row_data__key col-6 input-group" style={{display:'block'}}>
													<Select
														key = {id}
														value={subTypeSelected}
														onChange={e=>this.handlePaymentSubTypeChange(e,index,id,1)}
														options={subTypeOptions}
													/>
													</div>
												</div>
												<div className="row row_data">
													<span className="row_data__key col-5 input-group">
														<input key = {id} className="form-control" placeholder = {"label #"+(id+1)} onChange={e => this.handleLabelChange(e,index,id,1)} type="text" value={label} style={{display:viewLabel}}/>
													</span>
													<span className="row_data__key col-5 input-group">
														<input key = {id} className="form-control" placeholder = {"amount #"+(id+1)} value={price} onChange={e => this.handleAmountChange(e,index,id,1)} type="number" />
													</span>
													{this.props.user.level>=1900?<span className="row_data__key col-2 input-group" style={{padding:'3px 0'}}>
														<button  className="btn btn-sm btn-danger" style={{paddingTop: 3}} onClick = {() => this.handleClickDelete(id)}>Remove</button>
													</span>:""}
												</div>
												</div>
											</div>
										)
									})
								}
								{
									this.state.newOttArray.map(({id,label,price,type,subTypeOptions,subTypeSelected, viewLabel},index) => {
										const paymentType = {
											label:type,
											value:type
										}
										return (
											<div>
												<div className="row row_data">
													<div className="row_data__key col-6 input-group" style={{display:'block'}}>
													<Select
														key = {id}
														value={paymentType}
														onChange={e => this.handlePaymentTypeChange(e,index,id,2)}
														options={this.paymentTypes}
													/>
													</div>
													<div className="row_data__key col-6 input-group" style={{display:'block'}}>
													<Select
														key = {id}
														value={subTypeSelected}
														onChange={e=>this.handlePaymentSubTypeChange(e,index,id,2)}
														options={subTypeOptions}
													/>
													</div>
												</div>
												<div className = "row row_data">
													<span className="row_data__key col-5 input-group" style={{display:viewLabel}}>
														<input key = {id} className="form-control" placeholder = {"label #"+(id+1)} onChange={e => this.handleLabelChange(e,index,id,2)} type="text" value={label}/>
													</span>
													<span className="row_data__key col-5 input-group">
														<input key = {id} className="form-control" placeholder = {"amount #"+(id+1)} value={price} onChange={e => this.handleAmountChange(e,index,id,2)} type="number" />
													</span>
													<span className="row_data__key col-2 input-group"  style={{padding:'3px 0'}}>
														<button  className="btn btn-sm btn-danger" style={{paddingTop: 3}} onClick = {() => this.handleClickCancel(id)}>Cancel</button>
													</span>
												</div>
											</div>
										)
									})
								}
								<div className="row row_data">
									<span className="row_data__key col-6">Final Price</span>
									<span className="row_data__value col-6">{numberConverter(this.state.finalAmount)}</span>
								</div>
							</div>
							<div className="col-md-6 page_data__2">
								<div className="row_head" style={{"display":"flex","justifyContent": "space-between"}}>
									<div className="row row_data" style={{paddingLeft:"50%"}}>Payments</div>
									<div style={styleSheet.titlePage.actions}>
										{	
									  		(this.props.user.level==1800 || this.props.user.level==1900 || this.props.user.level==2300 || this.props.user.level==2600)?<i onClick={this.handlePaymentAdd} className="fas fa-plus-circle circle_create"></i>:""
									  	}
									  	{
									  		(this.props.user.level==1800 || this.props.user.level==1900 || this.props.user.level==2300 || this.props.user.level==2600)?<i onClick={this.handlePaymentChange} className="fas fa-pen" style={{padding: '4px'}}></i>:""
									  	}
									  	{
									  		this.state.paymentEdit?<button  className="btn btn-sm btn-success"  onClick = {() => {this.handlePaymentSaveClick()}}>Save</button>:""
									  	}
									</div>
								</div>
								{
									this.state.paymentEdit?"":this.state.payments.map((pay,index) => {
										return (
											<div key = {pay.date + "" +pay.name} className="row row_data">
												<span className="row_data__key col-4">{pay.name}</span>
												<span className="row_data__key col-4">{numberConverter(pay.amount)}</span>
												<span className="row_data__value col-4">{changeToViewDate(pay.date)}</span>
											</div>
										)
									})
								}
								{
									this.state.paymentEdit?this.state.payments.map((pay,index) => {
										return (
											<div key = {index} className="row row_data">
												<span className="row_data__value col-2 input-group" style={{padding:"2px 8px"}}>
													<input key={index} data-key={index} name="name" type="text" className="form-control" value = {pay.name}  onChange={(event,index) => {this.handlePaymentInputChange(event,index)}} readOnly />
												</span>
												<span className="row_data__value col-3 input-group" style={{padding:"2px 8px"}}>
													<input key={index} data-key={index} name="amount" type="number" className="form-control" value = {(pay.amount) } onChange={(event,index) => {this.handlePaymentInputChange(event,index)}}/>
												</span>
												<span className="row_data__value col-4 input-group" style={{padding:"2px 8px"}}>
													<input key={index} data-key={index} name="date" type="date" className="form-control" value = { changeToInputDate(pay.date) } onChange={(event,index) => {this.handlePaymentInputChange(event,index)}}/>
												</span>
												<span className="row_data__value col-3" style={{padding:"2px 8px"}}><button  className="btn btn-sm btn-danger" data-key={index} onClick = {(e) => {this.handlePaymentRemoveClick(e)}}>Remove</button></span>

											</div>
										)
									}):""
								}
								<div className="row row_data">
									<span className="row_data__key col-4">Total</span>
									<span className="row_data__key col-4">{numberConverter(this.state.totalPay)}</span>
									<span className="row_data__value col-4">{Math.floor(this.state.totalPercentage*100)/100}%</span>
								</div>
							</div>
							<div className="col-md-2 page_data__3">
								<div className="row_head">
									<div className="row row_data">Paid Percentage</div>
								</div>
								{
									this.state.finalAmount>0?this.state.payments.length>0?
										this.state.payments.map(pay => {
											return (
												<div className="row row_data" key ={numberConverter(pay.amount)}>
													<span className="row_data__key">{numberConverter(Math.floor((Math.floor(pay.amount)/this.state.finalAmount)*10000)/100)}%</span>
												</div>
											)
										}):"No Payment Yet":"Final Payment not Generated"
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}