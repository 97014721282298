import { INCENTIVE_UPADTE_START, INCENTIVE_UPADTE_SUCCESS, INCENTIVE_UPADTE_FAILED } from './actiontypes';

import { base, db } from '../connection';

import { fetch, set } from '../helper/firebaseFunctions';

import { getCommentProcessRequest } from '../helper/addComments';


export function setincentiveupdate(user,updateData) {
	return (dispatch) => {
		dispatch({type:INCENTIVE_UPADTE_START,payload:{status:"alert alert-primary",text:"Please wait..."}});
		let { date, value, team, quoteId, subtype, type, responsible  } = updateData;
		if(user.level>=1900) {
			console.log(date&&value!=-1&&team&&quoteId&&subtype!=-1&&type!=-1&&responsible)
			console.log(date,value!=-1,team,quoteId,subtype!=-1,type!=-1,responsible)
			console.log(date,value,team,quoteId,subtype,type,responsible)
			if(date&&value!=-2&&team&&quoteId&&subtype!=-1&&type!=-1&&responsible) {
				let timeData = {
					date:new Date(date).getTime(),
					value:value,
					team:team,
					responsible:responsible,
					quoteId:quoteId,
					subtype:subtype,
					type:type,
					changedBy: user.phoneNumber,
					changedOn: new Date().getTime()
				}
				let manualRecordData = {};
				if(type !== "Adjustment") {
					manualRecordData = {
							changedBy: user.phoneNumber,
							date: new Date().getTime()
						}
				}
				let manualURL 	= `delivery/webPortal/operationsIncentives/adjustment/manual/${quoteId}/${changeSubType(subtype)==="expired"?"netMetering":changeType(type)}/${changeSubType(subtype)}`;
				let timeWiseURL = `delivery/webPortal/operationsIncentives/timeWiseHistory/${new Date().getTime()+""+(Math.floor(Math.random()*90000) + 10000)}`;
				Promise.all([set(manualURL,manualRecordData),set(timeWiseURL,timeData)])
				.then(values => {
					console.log(values);
					dispatch({type:INCENTIVE_UPADTE_SUCCESS,payload:{status:"",text:""}});
				}).catch(error => {
					dispatch({type:INCENTIVE_UPADTE_FAILED,payload:{status:"alert alert-warning",text:"Something went wrong."}});
				})
			}else{
				dispatch({type:INCENTIVE_UPADTE_FAILED,payload:{status:"alert alert-warning",text:"Please fill all the details"}});
			}
		}else{
			dispatch({type:INCENTIVE_UPADTE_FAILED,payload:{status:"alert alert-warning",text:"You don't have permission"}});
		}
	}
}


function changeType(type) {
	switch(type) {
		case 'Payments':
			return 'payments';
		case 'Net Metering':
			return 'netMetering';
		default:
			return type;

	}
}

function changeSubType(type) {
	switch(type) {
		case 'P2':
			return 'p2';
		case 'P3':
			return 'p3';
		case 'Achieved':
			return 'achived';
		case 'P4 Late':
			return 'expired';
		default:
			return type;

	}
}