import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';
import { changeToViewDateTime, changeToInputDate, changeToViewDate } from '../helper/dateChanger';
import { Restrict } from '../components/Restrict';
import { columnsTicket, previousTicketColumns } from '../config/column_fields';
import Modal from 'react-responsive-modal';
import { fetchTickets, fetchLastComment } from '../actions/fetchTickets';
import { createTicket, sendBulkNotification } from '../actions/createTicket';
import { bulkAssignTickets } from '../actions/bulkAssignTickets';
import { csvTickets } from '../config/csv_fields';
import { CSVLink } from 'react-csv';
import moment from 'moment';

class TicketSystem extends Component {


	constructor(props) {
		super(props);
		this.reactTable = React.createRef();
		this.state = {
			showColumns: columnsTicket,
			previousColumns: previousTicketColumns,
			viewData: [],
			openCreateModal: false,
			createTicketForm: {
				quoteId: '',
				type: '',
				subType: '',
				description: '',
				assignedTo: '',
				source: 'Internal',
				responsibleTeam: '',
				dueDate: changeToInputDate(new Date().getTime() + 172800000),
				priority: 'Normal',
				selectedQuoteIdState: '',
				panelType: '',
				panelBrand: '',
				brandConfirm: '',
				inverterPhase: '',
				inverterBrand: '',
				gridTied: '',
				amount: ''
			},
			subTypeOptions: [],
			ticketsData: [],
			employeeList: {},
			members: [],
			teamEmployeeMap: {},
			stateEmployeeMap: {},
			responsibleOptions: [],
			quoteList: [],
			initState: true,
			createTicket: false,
			statusChoosen: [{ label: 'Open', value: 'Open' }, { label: 'In Progress', value: 'In Progress' }],
			sourceChoosen: [],
			assignedChoosen: [],
			typeChoosen: [],
			teamChoosen: [],
			subTypeChoosen: [],
			ticketTypeChoosen: [],
			viewData: [],
			stageChoosen: [],
			stateChoosen: [],
			notificationMap: [],
			selectedRows: {},
			showAssignedTo: false,
			bulkAssign: {
				responsibleTeam: '',
				assignedTo: ''
			},
			assignResponsibleOptions: [],
			selectAll: false,
			totalCount: 0,
			loading: true,
			minDueDate: changeToInputDate(new Date().getTime()),
			maxDueDate: moment().add(7, 'days').format("YYYY-MM-DD"),
			lastComments: {},
			startDate: 0,
			endDate: 0,
			salesStartDate: 0,
			salesEndDate: 0,
			previousTickets: [],
			zunPrimeChoosen: [],
			reasonForCloseChosen :[]
		};


		this.typeOptions = {
			"Project update": ["Layout Approval", "Structure Delivery", "Panel Inverter Delivery", "Commissioning", "Net-metering", "EB Issues", "Call Back", "Documents", "Others"],
			"Payment": ["P1", "P2", "p3", "p4", "Net-metering"],
			"Procurement": ["Panel", "Inverter"],
			"RMS": ["RMS", "Not Installed", "Not Working"],
			"Welcome Call": ["Welcome Call"],
			"Maintenance": ["AMC", "Correction Visit", "damage", "Bill Issues"],
			"Project Delivery": ["Project Delivery"],
			"Invoice": ["Invoice"],
			"Complaint": ["Site Assessment","Material Delivery","Installation","Net-metering","RMS","Documentation","Maintenance", "Sales commitments"]
		};
		this.subTypes = ["Layout Approval", "Structure Delivery", "Panel Inverter Delivery", "Commissioning", "EB Issues","Call Back", "Documents", "P1", "P2", "p3", "p4", "Net-metering", "Panel", "Inverter", "damage", "Maintenance", "AMC", "Welcome Call", "Correction Visit", "RMS", "Project Delivery", "Invoice", "Site Assessment","Material Delivery","Installation", "Documentation","Sales commitments", "Bill Issues", "Not Installed", "Not Working", "Others"];
		this.subTypeOptions = (this.subTypes ? this.subTypes : []).map(val => {
			return (
				{
					value: val,
					label: val
				}
			)
		});
		// this.sourceOptions = ["Internal", "System", "Facebook", "Twitter", "Youtube", "Google", "Call", "Email", "website", "Other"];
		this.sourceOptions = ["Email", "Call", "Whatsapp", "Social Media", "website"];
		this.ticketTypes = ['Complaints', 'Tickets'];
		this.ticketOptions = (this.ticketTypes ? this.ticketTypes : []).map(val => {
			return (
				{
					value: val,
					label: val
				}
			)
		});
		this.zunPrimeOptions = [
			{label: "Yes", value: "Yes"},
			{label: "No", value: "No"}
		];
		this.reasonForCloseOptions = ["Irrelevant ticket","Ticket Resolved","Client not responding","Wrong type/sub type selected"];
		this.stateEmployeeMap = {
			"CHANDIGARH": ["+918448389958", "+918448389952"],
			"DELHI": ["+918448389958", "+918448389952", "+917827941242"],
			"HARYANA": ["+918448389958", "+918448389952", "+917827941242"],
			"PUNJAB": ["+918448389958", "+918448389952", "+917827941242"],
			"UTTARAKHAND": ["+918448389958", "+918448389952"],
			"GUJARAT": ["+918448389958", "+917827038133", "+918287929840", "+918882922788", "+918448380949"],
			"RAJASTHAN": ["+918448389958", "+918448389952"],
			"KERALA": ["+917827944128", "+919205893367", "+919318345697"],
			"TAMIL NADU": ["+917827944128", "+919205893367", "+919318345697"],
			"ANDHRA PRADESH": ["+917827944128"],
			"KARNATAKA": ["+917827944128", "+919205893367", "+919318345697"],
			"UTTAR PRADESH": ["+919205695692", "+918448380949", "+918287102187"],
			"TELANGANA": ["+917827944128", "+918287929833", "+918287102178"],
			"ALL": ["+918448389958", "+918448389952", "+917827941242", "+917827038133", "+918287929840", "+918882922788", "+918448380949", "+917827944128", "+919205893367", "+919318345697", "+919205695692", "+918287102187", "+918287929833", "+918287102178"]
		};

		this.panelTypeOptions = ["DCR", "Non DCR"];
		this.panelBrandOptions = ["Adani", "Vikram", "RenewSys", "MNRE Tier I"];
		this.phaseOptions = ["Single Phase", "Three Phase"];
		this.inverterBrandOptions = ["EVVO", "Growatt", "Delta", "Sungrow", "UTL", "Statcon", "ABB", "Solax", "Luminous", "MNRE Approved"];
		this.brandConfirmationOptions = ["Brand is preferred", "Brand is compulsory"];
		this.gridTiedOptions = ["Off-grid", "Hybrid", "Grid-tied"];
	}

	componentDidMount() {
		if (this.props.user) {
			if (this.props.user.level >= 1600) {
				this.props.fetchTickets();
			}
		}
	}


	componentWillReceiveProps(props) {
		console.log(props);
		let quoteList = props.tickets.quoteList;
		let lastComments = { ...this.state.lastComments };
		let receivedComment = props.lastComment;
		if (receivedComment) {
			lastComments[receivedComment.ticketId] = {
				time: receivedComment.time,
				comment: receivedComment.comment
			}
		}
		let quoteOptions = [];
		quoteOptions = (quoteList ? quoteList : []).map(val => {
			return (
				{
					label: val.quoteId + " " + val.name,
					value: val.quoteId,
					state: val.state,
					gridTied: val.gridTied,
					stage: val.stage,
					phoneNumber: val.userId
				}
			)
		});
		let viewData = props.tickets.complaintData.filter(val => {
			if (val.status == 'Open' || val.status == 'In Progress') {
				return true;
			}
			else {
				return false;
			}
		})
		this.setState({
			ticketsData: props.tickets.complaintData,
			viewData: viewData,
			employeeList: props.tickets.employeeList,
			members: props.tickets.members,
			teamEmployeeMap: props.tickets.teamEmployeeMap,
			quoteList: quoteOptions,
			totalCount: viewData.length,
			loading: false,
			lastComments: lastComments,
			stateEmployeeMap: props.tickets.stateEmployeeMap
		}, () => {
			this.applyFilter();
		});
		this.options = [
			{ value: 'Open', label: 'Open' },
			{ value: 'In Progress', label: 'In Progress' },
			{ value: 'Close', label: 'Close' },
			{ value: 'Hold', label: 'Hold' }
		];
		this.sourceSelectOptions = this.sourceOptions.map(val => {
			return (
				{
					value: val,
					label: val
				}
			)
		});
		this.assignedToOptions = (props.tickets.members ? props.tickets.members : []).map(val => {
			return (
				{
					value: val,
					label: props.tickets.employeeList["" + val]
				}
			)
		})
		this.setState({
			ticketsData: props.tickets.complaintData,
			viewData: viewData,
			employeeList: props.tickets.employeeList,
			members: props.tickets.members,
			teamEmployeeMap: props.tickets.teamEmployeeMap,
			quoteList: quoteOptions,
			totalCount: viewData.length,
			loading: false,
			lastComments: lastComments,
			stateEmployeeMap: props.tickets.stateEmployeeMap,
			notificationMap: props.tickets.notificationMap
		}, () => {
			this.applyFilter();
		});
		this.options = [
			{ value: 'Open', label: 'Open' },
			{ value: 'In Progress', label: 'In Progress' },
			{ value: 'Close', label: 'Close' },
			{ value: 'Hold', label: 'Hold' }
		];
		this.sourceSelectOptions = this.sourceOptions.map(val => {
			return (
				{
					value: val,
					label: val
				}
			)
		});
		this.assignedToOptions = (props.tickets.members ? props.tickets.members : []).map(val => {
			return (
				{
					value: val,
					label: props.tickets.employeeList["" + val]
				}
			)
		});
		this.typeOptionsFilter = Object.keys(this.typeOptions ? this.typeOptions : {}).map(val => {
			return (
				{
					value: val,
					label: val
				}
			)
		});
		this.teamOptions = Object.keys(props.tickets.teamEmployeeMap ? props.tickets.teamEmployeeMap : {}).map(val => {
			return (
				{
					value: val,
					label: val
				}
			)
		});
		this.stageOptions = (props.tickets.stageList ? props.tickets.stageList : []).map(val => {
			return (
				{
					value: val,
					label: val
				}
			)
		});
		this.stateOptions = (props.tickets.stateList ? props.tickets.stateList : []).map(val => {
			return (
				{
					value: val,
					label: val
				}
			)
		});
	}


	createComplaint = (e) => {
		this.setState({
			openCreateModal: true
		});
	}

	onCloseCreateModal = (e) => {
		this.setState({
			openCreateModal: false
		});
	}

	handleCreateFormChange = (e) => {
		let name = e.target.name;
		let value = e.target.value;
		if (e.target.name == 'type') {
			if (value != -1) {
				this.setState({
					subTypeOptions: this.typeOptions[e.target.value]
				})
			}
			else {
				this.setState({
					subTypeOptions: []
				})
			}
			if(value == 'Complaint') {
				console.log("Complaint type");
				this.setState({
					maxDueDate: moment().add(3, 'days').format('YYYY-MM-DD')
				})
			}
			else {
				this.setState({
					maxDueDate: moment().add(7, 'days').format('YYYY-MM-DD')
				})
			}
		}
		if (e.target.name == 'responsibleTeam') {
			if (value != -1) {
				if (value == 'PM') {
					if (this.state.createTicketForm.selectedQuoteIdState && this.state.stateEmployeeMap[this.state.createTicketForm.selectedQuoteIdState.toUpperCase()]) {
						this.setState({
							responsibleOptions: this.state.stateEmployeeMap[this.state.createTicketForm.selectedQuoteIdState.toUpperCase()]
						})
					} else {
						this.setState({
							responsibleOptions: this.state.stateEmployeeMap["ALL"]
						})
					}
				} else {
					this.setState({
						responsibleOptions: this.state.teamEmployeeMap[value]
					})
				}
			}
			else {
				this.setState({
					responsibleOptions: []
				})
			}
		}
		this.setState(prevState => ({
			createTicketForm: {
				...prevState.createTicketForm,
				[name]: value
			}
		}))
	}

	handleAssignChange = (e) => {
		let name = e.target.name;
		let value = e.target.value;
		if (e.target.name == 'responsibleTeam') {
			if (value != -1) {
				if (value == 'PM') {
					this.setState({
						assignResponsibleOptions: this.state.stateEmployeeMap["ALL"]
					})
				}
				else {
					this.setState({
						assignResponsibleOptions: this.state.teamEmployeeMap[value]
					})
				}
			}
			else {
				this.setState({
					assignResponsibleOptions: []
				})
			}
		}
		this.setState(prevState => ({
			bulkAssign: {
				...prevState.bulkAssign,
				[name]: value
			}
		}))
	}

	handleQuoteIdChange = (e) => {
		if (e) {
			let quoteId = e.value;
			let allData = [...this.state.ticketsData];
			let phoneNumber = e.phoneNumber ? e.phoneNumber : ""
			let previousTickets = allData.filter(val => {
				if (val.phoneNumber == phoneNumber) {
					return true;
				}
				else {
					return false;
				}
			})
			this.setState(prevState => ({
				createTicketForm: {
					...prevState.createTicketForm,
					quoteId: e.value,
					selectedQuoteIdState: e.state,
					stage: (this.stageOptions[e.stage]||{}).label,
					gridTied: e.gridTied
				},
				previousTickets: previousTickets
			}))
		}
	}
	applyFilter = (e) => {
		let ticketsData = this.state.ticketsData;
		let statusArray = this.state.statusChoosen.map(val => val.value);
		let sourceArray = this.state.sourceChoosen.map(val => val.value);
		let assignedArray = this.state.assignedChoosen.map(val => val.value);
		let typeArray = this.state.typeChoosen.map(val => val.value);
		let teamArray = this.state.teamChoosen.map(val => val.value);
		let subTypeArray = this.state.subTypeChoosen.map(val => val.value);
		let ticketTypeChoosenArray = this.state.ticketTypeChoosen.map(val => val.value);
		let stateArray = this.state.stateChoosen.map(val => val.value);
		let stageArray = this.state.stageChoosen.map(val => val.value);
		let startDate = this.state.startDate ? new Date(`${this.state.startDate} 00:00:00`).getTime() : 0;
		let endDate = this.state.endDate ? new Date(`${this.state.endDate} 00:00:00`).getTime() + 86400000 : 9999999999999;
		let salesStartDate = this.state.salesStartDate ? new Date(`${this.state.salesStartDate} 00:00:00`).getTime() : 0;
		let salesEndDate = this.state.salesEndDate ? new Date(`${this.state.salesEndDate} 00:00:00`).getTime() : 9999999999999;
		let dueDateStart = this.state.dueDateStart ? new Date(`${this.state.dueDateStart} 00:00:00`).getTime() : 0;
		let dueDateEnd = this.state.dueDateEnd ? new Date(`${this.state.dueDateEnd} 00:00:00`).getTime() + 86400000 : 9999999999999;
		let lastActionDateStart = this.state.lastActionDateStart ? new Date(`${this.state.lastActionDateStart} 00:00:00`).getTime() : 0;
		let lastActionDateEnd = this.state.lastActionDateEnd ? new Date(`${this.state.lastActionDateEnd} 00:00:00`).getTime() + 86400000 : 9999999999999;
		let reasonForClose = this.state.reasonForCloseChosen.map(val=>val.value);
		let zunPrimeArray = this.state.zunPrimeChoosen.map(val => val.value);
		console.log(lastActionDateStart, lastActionDateEnd)
		let newData = ticketsData.filter(obj => {
			let isComplaint;
			if (obj.isComplaint) {
				isComplaint = 'Complaints';
			}
			else {
				isComplaint = 'Tickets';
			}
			let { updatedOn = 0, dueDate = 0 } = obj;
			let quoteIdSalesDate = obj.salesDate ? obj.salesDate : 0;
			return ((reasonForClose.length > 0 ? reasonForClose.includes(obj.reasonForClose) : true) && (statusArray.length > 0 ? statusArray.includes(obj.status) : true) && (sourceArray.length > 0 ? sourceArray.includes(obj.source) : true) && (assignedArray.length > 0 ? assignedArray.includes(obj.assignedTo) : true) && (typeArray.length > 0 ? typeArray.includes(obj.type) : true) && (teamArray.length > 0 ? teamArray.includes(obj.responsibleTeam) : true) && (subTypeArray.length > 0 ? subTypeArray.includes(obj.subType) : true) && (ticketTypeChoosenArray.length > 0 ? ticketTypeChoosenArray.includes(isComplaint) : true) && (obj.epoch >= startDate && obj.epoch <= endDate) && (quoteIdSalesDate >= salesStartDate && quoteIdSalesDate <= salesEndDate) && (stageArray.length > 0 ? stageArray.includes(obj.stage) : true) && (stateArray.length > 0 ? stateArray.includes(obj.state) : true) && (dueDate >= dueDateStart && dueDate <= dueDateEnd) && (updatedOn >= lastActionDateStart && updatedOn <= lastActionDateEnd) && (zunPrimeArray.length > 0 ? zunPrimeArray.includes(obj.zunPrime) : true));
		});
		this.setState({
			viewData: newData,
			totalCount: newData.length
		})
	}
	handleMultiSelectChange = (selectedOption, name) => {
		this.setState({
			[name]: selectedOption
		});
	}

	handleSubmitCreateTicket = (e) => {
		e.preventDefault();
		if (this.state.createTicketForm.quoteId && this.state.createTicketForm.quoteId != -1 && this.state.createTicketForm.type && this.state.createTicketForm.type != -1 && this.state.createTicketForm.subType && this.state.createTicketForm.subType != -1 && this.state.createTicketForm.description && this.state.createTicketForm.assignedTo && this.state.createTicketForm.assignedTo != -1 && this.state.createTicketForm.source && this.state.createTicketForm.source != -1 && this.state.createTicketForm.responsibleTeam && this.state.createTicketForm.responsibleTeam != -1 && this.state.createTicketForm.dueDate && this.state.createTicketForm.priority && this.state.createTicketForm.priority != -1) {
			if (this.state.createTicketForm.type == 'Procurement') {
				if (this.state.createTicketForm.subType == 'Panel' && !(this.state.createTicketForm.panelType && this.state.createTicketForm.panelType != -1 && this.state.createTicketForm.panelBrand && this.state.createTicketForm.panelBrand != -1 && this.state.createTicketForm.brandConfirm && this.state.createTicketForm.brandConfirm != -1)) {
					alert('Please fill all the details!!!!');
					return;
				}
				if (this.state.createTicketForm.subType == 'Inverter' && !(this.state.createTicketForm.inverterPhase && this.state.createTicketForm.inverterPhase != -1 && this.state.createTicketForm.inverterBrand && this.state.createTicketForm.inverterBrand != -1 && this.state.createTicketForm.brandConfirm && this.state.createTicketForm.brandConfirm != -1 && this.state.createTicketForm.gridTied && this.state.createTicketForm.gridTied != -1)) {
					alert('Please fill all the details!!!!');
					return;
				}
			}
			if(this.state.createTicketForm.type=='Payment' && !this.state.createTicketForm.amount){
				alert('Please fill all the details!!!!');
				return;
			}
			this.props.createTicket(this.state.createTicketForm, this.props.user.phoneNumber, this.props.tickets);
			this.setState({
				openCreateModal: false,
				createTicket: true
			});
		}
		else {
			alert('Please fill all the details!!!!');
		}
	}


	changeSelectStatus = (ticketId) => {
		let rowsSelected = { ...this.state.selectedRows, ...{} };
		if (rowsSelected[ticketId]) {
			delete rowsSelected[ticketId];
		}
		else {
			rowsSelected[ticketId] = true;
		}
		let show = false;
		if (Object.keys(rowsSelected).length > 0) {
			show = true;
		}
		let allSelected;
		if (Object.keys(rowsSelected).length == this.state.viewData.length) {
			allSelected = true;
		}
		else {
			allSelected = false;
		}
		this.setState({
			selectedRows: rowsSelected,
			showAssignedTo: show,
			selectAll: allSelected
		})
	}

	bulkAssign = (e) => {
		if (this.state.bulkAssign.responsibleTeam && this.state.bulkAssign.responsibleTeam != -1) {
			this.props.bulkAssignTickets(Object.keys(this.state.selectedRows), this.state.bulkAssign.responsibleTeam, this.state.bulkAssign.assignedTo);
			let allData = this.state.ticketsData.map(row => {
				if (Object.keys(this.state.selectedRows).includes(row.ticketId)) {
					row.responsibleTeam = this.state.bulkAssign.responsibleTeam;
					row.assignedTo = this.state.bulkAssign.assignedTo;
					row.assignedToName = this.state.employeeList[this.state.bulkAssign.assignedTo + ""]
				}
				return row;
			})
			this.setState({
				ticketsData: allData,
				viewData: allData,
				selectedRows: {},
				showAssignedTo: false
			}, () => {
				this.applyFilter();
			});
		}
		else {
			alert("Choose Responsible!!!");
		}

	}

	selectAll = (e) => {
		if (this.reactTable.current) {
			if (this.reactTable.current.getResolvedState().sortedData.length == this.state.viewData.length) {
				if (this.state.selectAll) {
					let rowsSelected = {};
					this.setState({
						selectedRows: rowsSelected,
						showAssignedTo: false,
						selectAll: false
					})
				}
				else {
					let rowsSelected = {};
					this.state.viewData.forEach(ticket => {
						if (ticket.ticketId) {
							rowsSelected[ticket.ticketId] = true;
						}
					})
					this.setState({
						selectedRows: rowsSelected,
						showAssignedTo: true,
						selectAll: true
					})
				}
			}
			else {
				alert("Please remove search before selecting all!!");
			}
		}
	}

	onChangeFilter = (e) => {
		let rowsSelected = {};
		let count = 0;
		if (this.reactTable.current) {
			count = this.reactTable.current.getResolvedState().sortedData.length;
		}
		this.setState({
			selectedRows: rowsSelected,
			showAssignedTo: false,
			selectAll: false,
			totalCount: count
		})
	}

	fetchLastComment = (ticketId) => {
		if (!this.state.lastComments[ticketId]) {
			this.props.fetchLastComment(ticketId);
		}
	}

	handleDateChange = (e) => {
		console.log(e.target.name, e.target.value);
		let { name, value } = e.target;
		this.setState({
			[name]: value
		})
	}

	onSendNotificationClick = () => {
		this.setState({
			openNotificationModal: true
		})
	}

	onCloseNotificationModal = () => {
		this.setState({
			openNotificationModal: false
		})
	}

	handleSelectNotifitionChange = (e) => {
		this.setState({
			notificationTitle: e.target.value,
			notificationText: this.state.notificationText ? this.state.notificationText : (this.state.notificationMap["" + e.target.value] || {}).text,
			notificationTitleText: (this.state.notificationMap["" + e.target.value] || {}).label
		})
	}

	handleNotificationChange = (e) => {
		this.setState({
			notificationText: e.target.value
		})
	}

	handleNotificationTitleCustomChange = (e) => {
		this.setState({
			notificationTitleText: e.target.value
		})
	}

	handleNotificationSubmit = (e) => {
		let text = this.state.notificationText;
		let title = this.state.notificationTitleText;
		let requestedBy = this.props.user.phoneNumber;
		let data = [...this.state.viewData];
		if (text && title) {
			this.props.sendBulkNotification(data, text, title, requestedBy);
		}
		else {
			alert("Add Text and title");
		}
		this.setState({
			openNotificationModal: false
		})
	}

	render() {
		const style = {
			hide: {
				display: 'none'
			},
			show: {
				display: ''
			},
			iconShow: {
				float: 'right',
				display: ''
			},
			iconHide: {
				float: 'right',
				display: 'none'
			}
		};

		if (this.props.user === null) {
			return <Redirect to={"/login?redirect_uri=ticketSystem"} />;
		}

		if (this.props.user && this.props.user.level < 1600) {
			return (
				<div>
					<Restrict />
				</div>
			)
		}

		const modalStyles = {
			modal: {
				width: '80%',
				maxWidth: '80%',
				padding: '40px'
			}
		}


		return (

			<div style={{ "marginTop": 50 }} className="container-fluid">
				<div style={this.state.showAssignedTo ? style.show : style.hide}>
					<div className="row">
						<div className="form-group col-4">
							<label>Team</label>
							<select className="form-control" name="responsibleTeam" onChange={this.handleAssignChange} value={this.state.bulkAssign.responsibleTeam}>
								<option key="-1" value="-1">---Select Team---</option>
								{
									Object.keys(this.state.teamEmployeeMap ? this.state.teamEmployeeMap : {}).map((v, i) => {
										return (<option key={i} value={v}>{v}</option>)
									})
								}
							</select>
						</div>
						<div className="form-group col-4">
							<label>Employee</label>
							<select className="form-control" name="assignedTo" onChange={this.handleAssignChange} value={this.state.bulkAssign.assignedTo}>
								<option key="-1" value="-1">--Select Responsible---</option>
								{
									(this.state.assignResponsibleOptions).map((v, i) => {
										return (<option key={i} value={v}>{this.state.employeeList[v + ""]}</option>)
									})
								}
							</select>
						</div>
						<div className="form-group col-4">
							<button className="btn btn-success" onClick={this.bulkAssign} >Assign</button>
						</div>
					</div>
				</div>
				<div className="row container-fluid" style={this.state.showAssignedTo ? style.hide : style.show}>
					<div className="col-md-11">
						<button onClick={this.createComplaint} className="btn btn-success" style={{ "marginBottom": 10 }}>Create Ticket</button>
						{this.props.user.level >= 2300 ? <button onClick={this.onSendNotificationClick} className="btn btn-success" style={{ "marginBottom": 10, "marginLeft": 10 }}>Send Notification</button> : ""}
						{(this.props.user.level==1900 || this.props.user.level>=2300)?<CSVLink data={this.state.viewData||[]} filename={"Tickets_Data.csv"} className="btn btn-success" headers={csvTickets} style={{ "marginBottom": 10, "marginLeft": 10 }}>Download</CSVLink>:""}
					</div>
					<div className="col-md-1">
						<button onClick={this.applyFilter} className="btn btn-success">Apply Filter</button>
					</div>
				</div>
				<div style={this.state.showAssignedTo ? style.hide : style.show} className="row container-fluid">
					<div className="col-md-2 col-6 mb-3">
						{selectCustom(this.options, "statusChoosen", "Choose Status", this.handleMultiSelectChange, [{ label: 'Open', value: 'Open' }, { label: 'In Progress', value: 'In Progress' }])}
					</div>
					<div className="col-md-2 col-6 mb-3">
						{selectCustom(this.teamOptions, "teamChoosen", "Choose team", this.handleMultiSelectChange)}
					</div>
					<div className="col-md-2 col-6 mb-3">
						{selectCustom(this.assignedToOptions, "assignedChoosen", "Choose Responsible", this.handleMultiSelectChange)}
					</div>
					<div className="col-md-2 col-6 mb-3">
						{selectCustom(this.typeOptionsFilter, "typeChoosen", "Choose type", this.handleMultiSelectChange)}
					</div>
					<div className="col-md-2 col-6 mb-3">
						{selectCustom(this.subTypeOptions, "subTypeChoosen", "Choose sub type", this.handleMultiSelectChange)}
					</div>
					<div className="col-md-2 col-6 mb-3">
						{selectCustom(this.sourceSelectOptions, "sourceChoosen", "Choose Source", this.handleMultiSelectChange)}
					</div>
					<div className="col-md-2 col-6 mb-3">
						{selectCustom(this.ticketOptions, "ticketTypeChoosen", "Choose Ticket Type", this.handleMultiSelectChange)}
					</div>
					<div className="col-md-2 col-6 mb-3">
						<div className="input-group">
							<div className="input-group-prepend">
								<span className="input-group-text" id="basic-addon1">{"Ticket Start Date"}</span>
							</div>
							<input className="form-control customInput" type='date' name='startDate' onChange={(e) => this.handleDateChange(e)} />
						</div>
					</div>
					<div className="col-md-2 col-6 mb-3">
						<div className="input-group">
							<div className="input-group-prepend">
								<span className="input-group-text" id="basic-addon1">{"Ticket End Date"}</span>
							</div>
							<input className="form-control customInput" type='date' name='endDate' onChange={(e) => this.handleDateChange(e)} />
						</div>
					</div>
					<div className="col-md-2 col-6 mb-3">
						<div className="input-group">
							<div className="input-group-prepend">
								<span className="input-group-text" id="basic-addon1">{"Sales Start Date"}</span>
							</div>
							<input className="form-control customInput" type='date' name='salesStartDate' onChange={(e) => this.handleDateChange(e)} />
						</div>
					</div>
					<div className="col-md-2 col-6 mb-3">
						<div className="input-group">
							<div className="input-group-prepend">
								<span className="input-group-text" id="basic-addon1">{"Sales End Date"}</span>
							</div>
							<input className="form-control customInput" type='date' name='salesEndDate' onChange={(e) => this.handleDateChange(e)} />
						</div>
					</div>
					<div className="col-md-2 col-6 mb-3">
						{selectCustom(this.stageOptions, "stageChoosen", "Choose Stage", this.handleMultiSelectChange)}
					</div>
					<div className="col-md-2 col-6 mb-3">
						{selectCustom(this.stateOptions, "stateChoosen", "Choose State", this.handleMultiSelectChange)}
					</div>
					<div className="col-md-2 col-6 mb-3">
						<div className="input-group">
							<div className="input-group-prepend">
								<span className="input-group-text" id="basic-addon1">{"Due Date Start"}</span>
							</div>
							<input className="form-control customInput" type='date' name='dueDateStart' onChange={(e) => this.handleDateChange(e)} />
						</div>
					</div>
					<div className="col-md-2 col-6 mb-3">
						<div className="input-group">
							<div className="input-group-prepend">
								<span className="input-group-text" id="basic-addon1">{"Due Date End"}</span>
							</div>
							<input className="form-control customInput" type='date' name='dueDateEnd' onChange={(e) => this.handleDateChange(e)} />
						</div>
					</div>
					<div className="col-md-2 col-6 mb-3">
						<div className="input-group">
							<div className="input-group-prepend">
								<span className="input-group-text" id="basic-addon1">{"Last Action Date"}</span>
							</div>
							<input className="form-control customInput" type='date' name='lastActionDateStart' onChange={(e) => this.handleDateChange(e)} />
						</div>
					</div>
					<div className="col-md-2 col-6 mb-3">
						<div className="input-group">
							<div className="input-group-prepend">
								<span className="input-group-text" id="basic-addon1">{"Last Action Date"}</span>
							</div>
							<input className="form-control customInput" type='date' name='lastActionDateEnd' onChange={(e) => this.handleDateChange(e)} />
						</div>
					</div>
					<div className="col-md-2 col-6 mb-3">
						{selectCustom(this.zunPrimeOptions, "zunPrimeChoosen", "Choose ZunPrime", this.handleMultiSelectChange)}
					</div>
					<div className="col-md-2 col-6 mb-3">
						{selectCustom(this.reasonForCloseOptions.map((obj)=>({label : obj,value : obj})), "reasonForCloseChosen", "Choose Reason For Close", this.handleMultiSelectChange)}
					</div>
					{/* <div className="col-md-2 col-6 mb-3">
						<button onClick={this.applyFilter} className="btn btn-success">Apply Filter</button>
					</div> */}
				</div>
				<ReactTable
					filterable={true}
					showPagination={true}
					sortable={true}
					data={this.state.viewData}
					ref={this.reactTable}
					onFilteredChange={() => this.onChangeFilter()}
					columns={[{
						Header: <div>{""}<input type="checkbox" onChange={() => this.selectAll()} checked={this.state.selectAll} /></div>,
						accessor: 'selected',
						sortable: false,
						Cell: row => (
							<div><input type="checkbox" onChange={() => this.changeSelectStatus(row.original.ticketId)} checked={this.state.selectedRows[row.original.ticketId]} /></div>
						)
					}, ...this.state.showColumns, {
						Header: 'Last Comment',
						style: { 'white-space': 'unset' },
						Cell: row => (this.state.lastComments && this.state.lastComments[row.original.ticketId] ? this.state.lastComments[row.original.ticketId].comment : <button onClick={() => this.fetchLastComment(row.original.ticketId)} className="btn btn-success">Show</button>)
					}]}
					defaultSorted={[{ id: "epoch", desc: true }]}
					style={{ height: "90vh", textAlign: 'center' }}
					defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
					loading={this.state.loading}
					getTrProps={(state, rowInfo, column, instance) => {
						if (rowInfo && this.state.selectedRows[rowInfo.original.ticketId]) {
							return {
								style: {
									backgroundColor: '#DCDCDC'
								}
							}
						}
						else if (rowInfo && rowInfo.original.dueDate < new Date().getTime() && (rowInfo.original.status == 'Open' || rowInfo.original.status == 'In Progress')) {
							return {
								style: {
									backgroundColor: 'rgb(249, 146, 141, 0.8)'
								}
							}
						}
						else if (rowInfo && rowInfo.original.dueDate - new Date().getTime() < 86400000 && (rowInfo.original.status == 'Open' || rowInfo.original.status == 'In Progress')) {
							return {
								style: {
									backgroundColor: 'rgb(243, 189, 155, 0.8)'
								}
							}
						}
						else {
							return {
								style: {
									backgroundColor: '#FFFFFF'
								}
							}
						}
					}
					}
					getTdProps={() => ({
						style: {
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center'
						}
					})
					}
				/>
				<Modal open={this.state.openNotificationModal} onClose={this.onCloseNotificationModal} center>
					<div className="form-group">
						<label htmlFor="notificationTitle">Notification:</label>
						<br />
						<select className="custom-select" value={this.state.notificationTitle ? this.state.notificationTitle : ""} onChange={this.handleSelectNotifitionChange}>
							<option value={-1}>Select Notification Title</option>
							{
								this.state.notificationMap ? this.state.notificationMap.map((data, index) => {
									return (
										<option key={index} value={index}>{data.label}</option>
									)
								}) : ""
							}
						</select>
						<br />
						<label htmlFor="notificationTitle"></label>
						<input value={this.state.notificationTitleText} onChange={this.handleNotificationTitleCustomChange} className="form-control" rows="5" />
						<label htmlFor="notificationText"></label>
						<textarea value={this.state.notificationText} onChange={this.handleNotificationChange} className="form-control" rows="5" id="comment"></textarea>
						<div><button className="btn btn-success" onClick={() => this.handleNotificationSubmit()}>Submit</button></div>
					</div>
				</Modal>
				<Modal styles={modalStyles} open={this.state.openCreateModal} onClose={this.onCloseCreateModal}>
					<form onSubmit={this.handleSubmitCreateTicket} className="form-control" style={{ border: 'none' }}>
						<div className="row">
							<div className="form-group col-4">
								<label>Quote Id</label>
								<Select
									isSearchable
									onChange={(e) => this.handleQuoteIdChange(e)}
									placeholder="Select Quote Id"
									name="quoteId"
									options={this.state.quoteList}
								/>

							</div>
							<div className="form-group col-4">
								<label>Type</label>
								<select className="form-control" name="type" onChange={this.handleCreateFormChange} value={this.state.createTicketForm.type}>
									<option key="-1" value="-1">--Select Type---</option>
									{
										Object.keys(this.typeOptions).map((v, i) => {
											return (<option key={i} value={v}>{v}</option>)
										})
									}
								</select>
							</div>
							<div className="form-group col-4">
								<label>Sub Type</label>
								<select className="form-control" name="subType" onChange={this.handleCreateFormChange} value={this.state.createTicketForm.subType}>
									<option key="-1" value="-1">--Select Type---</option>
									{
										(this.state.subTypeOptions).map((v, i) => {
											return (<option key={i} value={v}>{v}</option>)
										})
									}
								</select>
							</div>
						</div>
						{(this.state.createTicketForm.type == 'Procurement' && this.state.createTicketForm.subType == 'Panel') ? <div className="row">
							<div className="form-group col-4">
								<label>Type Of Panels</label>
								<select className="form-control" name="panelType" onChange={this.handleCreateFormChange} value={this.state.createTicketForm.panelType}>
									<option key="-1" value="-1">---Select Panel---</option>
									{
										this.panelTypeOptions.map((v, i) => {
											return (<option key={i} value={v}>{v}</option>)
										})
									}
								</select>
							</div>
							<div className="form-group col-4">
								<label>Brand Of Panels</label>
								<select className="form-control" name="panelBrand" onChange={this.handleCreateFormChange} value={this.state.createTicketForm.panelBrand}>
									<option key="-1" value="-1">---Select Brand---</option>
									{
										this.panelBrandOptions.map((v, i) => {
											return (<option key={i} value={v}>{v}</option>)
										})
									}
								</select>
							</div>
							<div className="form-group col-4">
								<label>Brand Confirmation</label>
								<select className="form-control" name="brandConfirm" onChange={this.handleCreateFormChange} value={this.state.createTicketForm.brandConfirm}>
									<option key="-1" value="-1">---Select---</option>
									{
										this.brandConfirmationOptions.map((v, i) => {
											return (<option key={i} value={v}>{v}</option>)
										})
									}
								</select>
							</div>

						</div> : ""}
						{(this.state.createTicketForm.type == 'Procurement' && this.state.createTicketForm.subType == 'Inverter') ? <div className="row">
							<div className="form-group col-3">
								<label>Inverter Phase</label>
								<select className="form-control" name="inverterPhase" onChange={this.handleCreateFormChange} value={this.state.createTicketForm.inverterPhase}>
									<option key="-1" value="-1">---Select Phase---</option>
									{
										this.phaseOptions.map((v, i) => {
											return (<option key={i} value={v}>{v}</option>)
										})
									}
								</select>
							</div>
							<div className="form-group col-3">
								<label>Brand Of Inverter</label>
								<select className="form-control" name="inverterBrand" onChange={this.handleCreateFormChange} value={this.state.createTicketForm.inverterBrand}>
									<option key="-1" value="-1">---Select Brand---</option>
									{
										this.inverterBrandOptions.map((v, i) => {
											return (<option key={i} value={v}>{v}</option>)
										})
									}
								</select>
							</div>
							<div className="form-group col-3">
								<label>Brand Confirmation</label>
								<select className="form-control" name="brandConfirm" onChange={this.handleCreateFormChange} value={this.state.createTicketForm.brandConfirm}>
									<option key="-1" value="-1">---Select---</option>
									{
										this.brandConfirmationOptions.map((v, i) => {
											return (<option key={i} value={v}>{v}</option>)
										})
									}
								</select>
							</div>
							<div className="form-group col-3">
								<label>Grid Tied</label>
								<select className="form-control" name="gridTied" onChange={this.handleCreateFormChange} value={this.state.createTicketForm.gridTied}>
									<option key="-1" value="-1">---Select---</option>
									{
										this.gridTiedOptions.map((v, i) => {
											return (<option key={i} value={v}>{v}</option>)
										})
									}
								</select>
							</div>
						</div> : ""}
						<div className="row">
							<div className="form-group col-8">
								<label>Previous Tickets</label>
								<ReactTable
									columns={this.state.previousColumns}
									data={this.state.previousTickets}
									filterable={false}
									showPagination={true}
									sortable={true}
									defaultPageSize={5}
									style={{ textAlign: "center" }}
									defaultSorted={[
										{
											id: 'epoch',
											desc: true
										}
									]}
								/>
							</div>
							<div className="form-group col-4">
								<label>Description</label>
								<textarea rows="12" className="form-control" type="text" name="description" onChange={this.handleCreateFormChange} value={this.state.createTicketForm.description} required />
							</div>
						</div>
						<div className="row">
							<div className="form-group col-4">
								<label>Team</label>
								<select className="form-control" name="responsibleTeam" onChange={this.handleCreateFormChange} value={this.state.createTicketForm.responsibleTeam}>
									<option key="-1" value="-1">---Select Team---</option>
									{
										Object.keys(this.state.teamEmployeeMap ? this.state.teamEmployeeMap : {}).map((v, i) => {
											return (<option key={i} value={v}>{v}</option>)
										})
									}
								</select>
							</div>
							<div className="form-group col-4">
								<label>Employee</label>
								<select className="form-control" name="assignedTo" onChange={this.handleCreateFormChange} value={this.state.createTicketForm.assignedTo}>
									<option key="-1" value="-1">--Select Responsible---</option>
									{
										(this.state.responsibleOptions).map((v, i) => {
											return (<option key={i} value={v}>{this.state.employeeList[v + ""]}</option>)
										})
									}
								</select>
							</div>
							<div className="form-group col-4">
								<label>Source</label>
								<select className="form-control" name="source" onChange={this.handleCreateFormChange} value={this.state.createTicketForm.source}>
									<option key="-1" value="-1">--Select source---</option>
									{
										(this.sourceOptions).map((v, i) => {
											return (<option key={i} value={v}>{v}</option>)
										})
									}
								</select>
							</div>
						</div>
						<div className="row">
							<div className="form-group col-2">
								<label>Due Date</label>
								<div className="date">
									<input className="form-control" type="date" name="dueDate" onChange={this.handleCreateFormChange} value={this.state.createTicketForm.dueDate} min={this.state.minDueDate} max={this.state.maxDueDate}/>
								</div>
							</div>
							{this.state.createTicketForm.type=='Payment'?<div className="form-group col-2">
								<label>Amount</label>
								<div className="date">
									<input className="form-control" type="number" name="amount" onChange={this.handleCreateFormChange} value={this.state.createTicketForm.amount}/>
								</div>
							</div>:<div className="form-group col-2"></div>}
							<div className="form-group col-4">
								<label>Priority</label>
								<select className="form-control" name="priority" onChange={this.handleCreateFormChange} value={this.state.createTicketForm.priority}>
									<option key="1" value="Normal">Normal</option>
									<option key="2" value="High">High</option>
									<option key="3" value="Low">Low</option>
								</select>
							</div>
							<div className="form-group col-4" style={{ "marginTop": "22px", "textAlign": "center" }}>
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</div>

					</form>

				</Modal>
			</div>
		);


	}
}

const selectCustom = (options, name, placeholder, handleMultiSelectChange, defaultValue) => {
	const changeHandle = (value) => { handleMultiSelectChange(value, name) }
	return (
		<div>
			<Select
				isMulti
				isSearchable
				onChange={(e) => { changeHandle(e) }}
				placeholder={placeholder}
				name={name}
				options={options}
				defaultValue={defaultValue}
			/>
		</div>
	)
}


function mapStateToProps(state) {
	return {
		user: state.user,
		tickets: state.tickets.data,
		newticket: state.createnewticket.data,
		lastComment: state.tickets.lastComment
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ fetchTickets, createTicket, bulkAssignTickets, fetchLastComment, sendBulkNotification }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketSystem);