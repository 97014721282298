import { db } from '../connection';
import { OPENING_LOG_START,
    OPENING_LOG_SUCCESS } from './actiontypes'
import { object } from 'prop-types';


export function openlog(quoteid) {
    return (dispatch) => {
        dispatch({type : OPENING_LOG_START});
        getlog(quoteid).then((finalObj) => {
        dispatch({type : OPENING_LOG_SUCCESS, payload : finalObj });
        })
    }
}

async function getlog(quoteid){
    let snapshot = await db.ref(`/delivery/webPortal/fileManagement/delivery/viewableFiles/${quoteid}`).once("value");
    let employeedetails = await db.ref(`/delivery/webPortal/employeeDetails`).once("value");
    employeedetails=employeedetails.val();
    let logs = snapshot.val();
    if(logs==null)
    return null
    return processlog(logs,employeedetails);

}
function processlog(logs,employeedetails){
    let keys=Object.keys(logs);
    let clientlog=[];
    keys.reverse();
    keys.forEach((key)=>{

        if(logs[key].folderType==='Client Info')
        {
            let typename = logs[key].type;
            let uploadtime = logs[key].metaData.createdOn;let uploadedby =logs[key].metaData.createdBy;
            if(Object.keys(employeedetails).includes(uploadedby))
            uploadedby=employeedetails[uploadedby].name;
            let verifiedat ="" ; let verifiedby="";
            let comments ="";
            var uploaddate = new Date(parseInt(uploadtime));
            uploadtime = uploaddate.getDate()+ '/' + (uploaddate.getMonth()+1) + '/' + uploaddate.getFullYear()+"\n"+uploaddate.getHours()+":"+uploaddate.getMinutes()+":"+uploaddate.getSeconds();
            if(logs[key].Verifiedby!==undefined){
             verifiedby =logs[key].Verifiedby.userid;
             if(Object.keys(employeedetails).includes(verifiedby))
             verifiedby=employeedetails[verifiedby].name;
            verifiedat =logs[key]["Verifiedat"]["epochtime"];
            var verifieddate = new Date(parseInt(verifiedat));
            verifiedat = verifieddate.getDate()+ '/' + (verifieddate.getMonth()+1) + '/' + verifieddate.getFullYear()+"\n"+verifieddate.getHours()+":"+verifieddate.getMinutes()+":"+verifieddate.getSeconds();
            if(logs[key]["Comments"])
            comments=logs[key]["Comments"]["comments"];
            else comments="....";
            }
            else{
                verifiedat ="....."
                verifiedby="....."
            }
            let rejected=0;
            if(logs[key]["Rejected"]!=undefined)
            rejected=1;
            clientlog.push({typename,uploadtime,uploadedby,verifiedat,verifiedby,comments,rejected})
        }
    })
    // viewtodocs()
    // .then(()=>{console.log("docs uploaded")})
    // .catch((err)=>{(console.log(err))});
    return clientlog;
}

async function viewtodocs(){ 
	let view= `/delivery/webPortal/fileManagement/delivery/viewableFiles/`;
	let docsmap=await db.ref(`/delivery/webPortal/fileManagement/delivery/config/typesList/Client Info`).once("value");docsmap=docsmap.val();
	let quotes= await db.ref(view).once("value");quotes=quotes.val();
    let quoteids =Object.keys(quotes);let docs={};
	quoteids.forEach((quoteid)=>{
		let quote =quotes[quoteid];
		let keys =Object.keys(quote);
        let q={};

		keys.forEach((key)=>{
			let ep = quote[key];
			if(ep.folderType==="Client Info"){
                let type =ep.type;

                let docname = docsmap[type].name;
                let e= {status:1,key:key};
				q[docname]=e;
			}
        })
        console.log(q,"Pranav");
		docs[quoteid]=q;
	})
	await db.ref(`/delivery/webPortal/fileManagement/delivery/docs/`).set(docs);
}
