import React from 'react';
import Select from 'react-select';

export const FilterComponent = ({isMulti,isSearchable,placeholder,value,handleStateChange,stateMulti}) => {
	return (
		<div className="col-md-2 col-6 filter_box ">
			<div className="drop_down1">
			<Select
				isMulti={isMulti}
				isSearchable={isSearchable}
				placeholder={placeholder}
				value={value}
				onChange={handleStateChange}
				options={Object.keys(stateMulti).map(val => {return {value:val,label:stateMulti[val]}})}
			/>
			</div>
		</div>
	)
}