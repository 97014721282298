import React from 'react';
import Select from 'react-select';

import { styleSheet } from '../styles/styleSheet';

export const PaymentView = (props) => {
	let buttons = ["Confirm","Approval","Request"]  
	return (
		<div style={{marginTop:40}}>
			<div className="page-title properties-flex">
				<div className="flex-child-1">
					<div className="flex-child">
						<span style={{fontSize:16}}>Request</span>
					</div>
				</div>
				<div className="flex-child-2">
					{
						buttons.map((val,index) => <button className = "btn btn-primary flex-child" style = {{marginRight:5}} key={index} value={index} >{buttons[index]}</button>)
					}
				</div>
			</div>
			<div className="page_data">
				<div className="container" style={{border:'1px solid',borderRadius:'10px',padding:'10px 20px'}}>
					<RequestForm />
				</div>
				<div>
					FORM
				</div>	
			</div>
		</div>
	);
}

const RequestForm = (props) => {
	return (
		<form>
			<div class="form-row">
				<div class="form-group col-4">
					<label for="inputState">Quote Id</label>
					<select id="inputState" class="form-control">
						<option selected>Choose...</option>
						<option>...</option>
					</select>
				</div>
				<div class="form-group col-4">
					<label for="inputPassword4">Vender 1</label>
					<input type="text" class="form-control" id="inputPassword4" placeholder="Vender 1" value="Vender 1" readOnly/>
				</div>
				<div class="form-group col-4">
					<label for="inputPassword4">Vender 1</label>
					<input type="text" class="form-control" id="inputPassword4" placeholder="Vender 1" value="Vender 1" readOnly/>
				</div>
			</div>
			<div class="form-row">
				<div class="form-group col-4">
					<label for="inputPassword4">Name</label>
					<input type="text" class="form-control" id="inputPassword4" placeholder="Name" value="Name" readOnly/>
				</div>
				<div class="form-group col-4">
					<label for="inputPassword4">Size</label>
					<input type="text" class="form-control" id="inputPassword4" placeholder="Size" value="Size" readOnly/>
				</div>
				<div class="form-group col-4">
					<label for="inputCity">City</label>
					<input type="text" class="form-control" id="inputCity" placeholder="Size" value="Size" readOnly/>
				</div>
			</div>
			<div class="form-row">
				<div class="form-group col-4">
					<label for="inputPassword4">Total Paid</label>
					<input type="text" class="form-control" id="inputPassword4" placeholder="Paid" value="Paid" readOnly/>
				</div>
				<div class="form-group col-4">
					<label for="inputPassword4">Total Payment</label>
					<input type="text" class="form-control" id="inputPassword4" placeholder="Payment" value="Payment" readOnly/>
				</div>
				<div class="form-group col-4">
					<label for="inputCity">Percentage Paid</label>
					<input type="text" class="form-control" id="inputCity" readOnly/>
				</div>
			</div>
			<button type="submit" class="btn btn-primary">Sign in</button>
		</form>
	)
}