import { OPENING_LOG_START,
    OPENING_LOG_SUCCESS, VERIFY_SUCCESS } from '../actions/actiontypes';

const INITIAL_STATE ={
    log:[]
}
export default function(state=INITIAL_STATE, action){

    switch (action.type){
        case OPENING_LOG_SUCCESS:
            return{...state, log:action.payload};
        default:
        return state;
    }

}