import { base } from '../connection';

export function setnotification(siteId,fromUserId,notification_id,text,title,toUserId,index) {
	console.log(new Date());
	return (dispatch) => {
		let dataNotification = {
			fromUserId:""+fromUserId,
			notification_id:""+notification_id,
			site_id:""+siteId,
			sendDate:createDateObj(),
			text:""+text,
			title:""+title,
			toUserId:""+toUserId
		}
		pushNotification(dataNotification);
		if(index){
			pushSiteNotification(siteId,notification_id,index);
		}else{
			pushSiteNotification(siteId,notification_id,"0");
		}
	}
}

function pushNotification(data1) {
	var immediatelyAvailableReference  = base.post('/notifications/'+(data1.notification_id), {
	    data: data1
	    ,then(err){
	      if(!err){
	      	console.log("SUCCESS");
	      }else{
	      	console.log(err);
	      }
	    }
	  });
}


function pushSiteNotification(siteId,id,index) {
	var immediatelyAvailableReference  = base.post('/sites/'+siteId+'/mSite_notificationIds/'+index, {
	    data: ""+id
	    ,then(err){
	      if(!err){
	      	console.log("SUCCESS");
	      }else{
	      	console.log(err);
	      }
	    }
	  });
}

function createDateObj() {
	let dateVal = new Date();
	let dateObj = {
		date:dateVal .getDate(),
		day:dateVal.getDay(),
		hours:dateVal.getHours(),
		minutes:dateVal.getMinutes(),
		month:dateVal.getMonth()+1,
		seconds:dateVal.getSeconds(),
		time:dateVal.getTime(),
		timezoneOffset:dateVal.getTimezoneOffset(),
		year:dateVal.getFullYear()-1900
	}
	return dateObj;
}