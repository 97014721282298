import { db } from '../connection';

export function setInvoiceVerificationStatus({ quoteId, requestId, status, userId }) {
    return async (dispatch) => {
        if(!quoteId || !requestId || !userId){
            return;
        }
        let updateData = {
            verified: status,
            actionTime: new Date().getTime(),
            actionBy: userId
        }
        console.log("setInvoiceVerificationStatus", quoteId, requestId, status, userId, updateData)
        await db.ref(`delivery/webPortal/fileManagement/delivery/viewableFiles/${quoteId}/${requestId}`).update(updateData);
    }
}
