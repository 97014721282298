import React, { Component } from 'react';
import ReactTable from "react-table";
import Modal from 'react-responsive-modal';
import { columnsPerComments } from '../config/column_fields';
import { styleSheet } from '../styles/styleSheet'

export class FrameFour extends Component {
	constructor(props) {
	  super(props);
	
	  this.state = {
	  	hover:false,
	  	data:[],
	  	text:"",
	  	user:"",
	  	show:true
	  };
	  	this.hoverOn			= this.hoverOn.bind(this);
		this.hoverOff			= this.hoverOff.bind(this);
		this.onOpenModal		= this.onOpenModal.bind(this);
		this.onCloseModal		= this.onCloseModal.bind(this);
		this.handleCommentSubmit= this.handleCommentSubmit.bind(this);
		this.handleCommentChange= this.handleCommentChange.bind(this);
		this.handleOnClickComment= this.handleOnClickComment.bind(this);
	}

	componentWillMount() {
		this.setState({
			data : this.props.data,
			user : this.props.user,
			open : false
		})
	}

	handleCommentChange(e) {
		this.setState({
			text:e.target.value
		})
		console.log(this.state.text);
	}

	handleOnClickComment() {
		this.setState({
			show: !this.state.show
		})
	}

	hoverOn(){
      this.setState({ hover: true });
    }
    hoverOff(){ 
      this.setState({ hover: false });    
    }

    onOpenModal(){
		this.setState({ open: true });
	};
	 
	onCloseModal(){
		this.setState({ open: false });
	};

	handleCommentSubmit() {
		//Perform comment send
		var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
		let date = new Date();
		let commentDate = date.toLocaleDateString("en-US", options)
		this.setState({
			open: false,
			data : [...this.props.data , {text:this.state.text,date:commentDate,responsible:this.state.user.phoneNumber}]
		});
		console.log("BEFORE POSTING : ",this.props.siteid,this.state.text,this.state.user.phoneNumber)
		this.props.setquotedata(this.props.siteid,this.state.text,this.state.user.phoneNumber);
	}

	render() {
		return (
			<div className="row row_data">
				<div className="col-12">
					<div className="page-title">
						<Modal open={this.state.open} onClose={this.onCloseModal} center>
							<div style={styleSheet.commentForm} className="form-group">
							  <label for="comment">Comment:</label>
							  <textarea value={this.state.text} onChange={this.handleCommentChange} className="form-control" rows="5" id="comment"></textarea>
							  <div style={styleSheet.commentFormButton}><button style={styleSheet.commentFormButton} className="btn btn-success" onClick={this.handleCommentSubmit}>Submit</button></div>
							</div>
				        </Modal>
						<div style={styleSheet.titlePage}>
							<div style={styleSheet.titlePage.quote}>
								<span style={styleSheet.dropRightArrow} onClick={this.handleOnClickComment}><i className={this.state.show?"fas fa-arrow-down open_arrow":"fas fa-arrow-right open_arrow"}></i> Comments</span>
							</div>
							<div style={styleSheet.addComment}  
								 onMouseEnter={this.hoverOn} 
	            				 onMouseLeave={this.hoverOff}>
								<i style={this.state.hover?styleSheet.plusCircleOnHover:styleSheet.plusCircle} onClick={this.onOpenModal} className="fas fa-plus-circle"></i>
							</div>
						</div>
					</div>
				</div>
				<div style={this.state.show?styleSheet.show:styleSheet.hide} className="col-12">
					<div className="page_data">
						<ReactTable 
							columns={columnsPerComments}
							defaultPageSize={this.state.data.length>5?5:this.state.data.length}
							data = {this.state.data}
							defaultSorted={[{id: "time",desc: true}]}
						/>
					</div>
				</div>
			</div>
		);
	}
}
