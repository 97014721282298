import { FETCHING_EMAIL_ANALYTICS_START, FETCHING_EMAIL_ANALYTICS_DATE, FETCHING_CLIENT_CALL_ANALYTICS_DATE, FETCHING_EMAIL_ANALYTICS_SUCCESS, FETCHING_EMAIL_STATUS_SUCCESS, FETCHING_CLIENT_CALL_ANALYTICS_SUCCESS } from './actiontypes';
import { dotToEmail, emailToDot } from '../helper/helper';
import { fetch, fetchOrderByChild } from '../helper/firebaseFunctions';
import { base, db } from '../connection';

let total = 0;
let teamMapping = {};
let foundEmail = {};
let emailLastFetchDate = '';
export function fetchEmailsAnalytics() {
	console.log(new Date());
  	return (dispatch) => {
  		dispatch({type:FETCHING_EMAIL_ANALYTICS_START});
    	let teamURL = `/delivery/teamtoEmployeeMapping`;
    	teamMapping = {};
    	foundEmail = {};
		base.fetch(teamURL,{})
		.then(dataGet => {
			Object.keys(dataGet).forEach(val => {
				teamMapping[""+val] = dataGet[""+val].teamName;
			});
			fetchStatus(dispatch);
		})
		.catch(error => {
			console.log(error);
		})
 	}
}

function fetchLastFetchDateEmail(dispatch) {
	return new Promise((resolve,reject) => {
		db.ref('delivery/webPortal/email/timeData')
		.orderByChild('date')
		.limitToLast(1)
		.once("value")
		.then(snapshot => {
			console.log(snapshot.val(),Object.values(snapshot.val())[0].date);
			resolve({type:FETCHING_EMAIL_ANALYTICS_DATE, payload:{Email: Object.values(snapshot.val())[0].date}})
		}).catch(err => {reject(err)})
	})
}

function fetchStatus(dispatch) {
	let statusFetchURL = `delivery/webPortal/email/statusFlag`;
	base.fetch(statusFetchURL,{})
	.then(dataGet => {
		dispatch({type:FETCHING_EMAIL_STATUS_SUCCESS, payload:dataGet});
		fecthSiteIds(dispatch,dataGet);
	})
	.catch(error => {
		console.log(error);
	})
}

function fecthSiteIds(dispatch,statusMap) {
	let quoteIdURL = `/delivery/webPortal/deliverySiteMapping/`
	base.fetch(quoteIdURL,{})
	.then(dataGet => {
		total = Object.keys(dataGet).length;
		let collectiveData = [];
		Object.keys(dataGet).forEach(key => {
			fetchDeliverySites(dispatch,collectiveData,key,statusMap)
		})
	})
	.catch(error => {
		console.log(error);
	})
}

function fetchDeliverySites(dispatch,collectiveData,siteId,statusMap) {
	let deliverySiteURL = `/delivery/sites/${siteId}/`
	base.fetch(deliverySiteURL,{})
	.then(dataGet => {
		if(dataGet){
			let dataCreated = {
				quoteId: dataGet.quoteId,
				teamId: teamMapping[""+dataGet.teamId],
				siteOwnerId:dataGet.siteOwnerId
			}
			fetchEmail(dispatch,collectiveData,dataCreated.siteOwnerId,dataCreated,statusMap)
		}else{
			total=total-1;
			if(collectiveData.length===total) {
				dispatch({type:FETCHING_EMAIL_ANALYTICS_SUCCESS,payload:collectiveData})
			}
		}
	})
	.catch(error => {
		console.log(error);
	})
}

function fetchEmail(dispatch,collectiveData,userId,dataCreated,statusMap) {
	let deliverySiteURL = `/users/${userId}/mUser_Contact/`
	base.fetch(deliverySiteURL,{})
	.then(dataGet => {
		if(dataGet){
			let dataToFetch = {
				email: dataGet.mContact_Email,
				name: `${dataGet.mContact_FirstName} ${dataGet.mContact_LastName}`
			}
			dataToFetch = {...dataToFetch,...dataCreated};
			lastEmail(dispatch,collectiveData,dataToFetch.email,dataToFetch,statusMap)
		}else{
			total=total-1;
			if(collectiveData.length===total) {
				dispatch({type:FETCHING_EMAIL_ANALYTICS_SUCCESS,payload:collectiveData})
			}
		}
	})
	.catch(error => {
		console.log(error);
	})
}

function lastEmail(dispatch,collectiveData,email,dataToFetch,statusMap) {
	let emailURL = `/delivery/webPortal/email/emailIdList/${dotToEmail(email).trim()}`
	base.fetch(emailURL,{
		queries: {
			limitToLast: 1
		}
	})
	.then(dataGet => {
		if(dataGet) {
			fetchEmailData(dispatch,collectiveData,email,Object.keys(dataGet)[0],dataToFetch,statusMap)
		}else{
			total=total-1;
			if(collectiveData.length===total) {
				dispatch({type:FETCHING_EMAIL_ANALYTICS_SUCCESS,payload:collectiveData})
			}
		}
	})
	.catch(error => {
		console.log(error);
	})
}

function fetchEmailData(dispatch,collectiveData,emailGet,id,dataToFetch,statusMap) {
	let emailIdURL = `/delivery/webPortal/email/timeData/${id}`
	base.fetch(emailIdURL,{})
	.then(dataGet => {
		if(dataGet) {
			if(dotToEmail(emailGet)===dataGet.fromName&&!foundEmail[id]) {
				foundEmail[""+id] = 1;
				let emailData = {
					cc: dataGet.cc,
					date: dataGet.date,
					name: `${dataToFetch.name}__${id}`,
					fromName: emailToDot(dataGet.fromName),
					mailBody: dataGet.mailBody,
					mailText: dataGet.mailText,
					subject: dataGet.subject,
					to: emailToDot(dataGet.to),
					quoteId: dataToFetch.quoteId,
					teamId: dataToFetch.teamId,
					status: dataGet.status?`${id}___${dataGet.status}`:`${id}___${1}`,
					id:id
				}
				collectiveData.push(emailData);
				if(!emailLastFetchDate || emailLastFetchDate<dataGet.date){
					emailLastFetchDate = dataGet.date;
				}
				if(collectiveData.length===total) {
					dispatch({type:FETCHING_EMAIL_ANALYTICS_SUCCESS,payload:collectiveData});
					dispatch({type:FETCHING_EMAIL_ANALYTICS_DATE, payload:{Email: emailLastFetchDate}});
				}
			}else{
				total=total-1;
				if(collectiveData.length===total) {
					dispatch({type:FETCHING_EMAIL_ANALYTICS_SUCCESS,payload:collectiveData})
					dispatch({type:FETCHING_EMAIL_ANALYTICS_DATE, payload:{Email: emailLastFetchDate}});

				}
			}
		}else{
			total=total-1;
			if(collectiveData.length===total) {
				dispatch({type:FETCHING_EMAIL_ANALYTICS_SUCCESS,payload:collectiveData})
				dispatch({type:FETCHING_EMAIL_ANALYTICS_DATE, payload:{Email: emailLastFetchDate}});

			}
		}
	})
	.catch(error => {
		console.log(error);
	})
}

// CLIENT MANAGEMENT CALL RECORD
export function fetchClientAnalytics() {
	console.log(new Date());
  	return (dispatch) => {
		// Promise.all([fetchLastFetchDateEmail(dispatch),fetchLastFetchDateCall(dispatch)])
		Promise.all([fetchLastFetchDateCall(dispatch)])
    	.then(values => {
    		dispatch(values[0]);
    		// dispatch(values[1]);
    	})
    	fetchClientData(dispatch);
 	}
}

function fetchLastFetchDateCall(dispatch) {
	return new Promise((resolve,reject) => {
		db.ref('utilities/webConfig/clientManagementLastFetch')
		.once("value")
		.then(snapshot => {
			resolve({type:FETCHING_CLIENT_CALL_ANALYTICS_DATE, payload:snapshot.val()})
		}).catch(err => {reject(err)})
	})
}

function fetchClientData(dispatch) {
	let clientCallRecordIdURL = `/delivery/webPortal/clientCallLog`;
	return new Promise((resolve,reject) => {
		db.ref('delivery/webPortal').child('clientCallLog').once('value')
		.then(snapshot => {
			let data = snapshot.val();
			let promiseList = Object.values(data).map(val => {
				if(val.type === "Missed") {
					return new Promise((resolve,reject) => {
						let userId = val.mobileNumber;
						fetchNameCallRecord(userId)
						.then(value => {
							resolve({
								date:val.epoch,
								mobile:val.mobileNumber,
								on:val.phoneNumber,
								duration:0,
								type:"Missed",
								...value
							})
						}).catch(err => {
							reject(err);
						})
					})
				}else{
					return new Promise((resolve,reject) => {
						resolve(false);
					})
				}
			});
			Promise.all(promiseList)
			.then(values => {
				dispatch({type:FETCHING_CLIENT_CALL_ANALYTICS_SUCCESS,payload:[...[],...values.filter(val => val)]});
			})
		})
	})
}


function fetchNameCallRecord(userId) {
	return new Promise((resolve,reject) => {
		let clientCallRecordIdURL = `/users/${userId}`;
		base.fetch(clientCallRecordIdURL,{})
		.then(dataGet => {
			let name = "";
			let quoteId = "";
			let notFoundSiteID = true;
			if(dataGet) {
				if(dataGet&&dataGet.mUser_Contact){
					name = `${dataGet.mUser_Contact.mContact_FirstName} ${dataGet.mUser_Contact.mContact_LastName}`;
					let siteId = dataGet.mUser_SitesViewableIdList?dataGet.mUser_SitesViewableIdList[0]:null;
					if(siteId) {
						notFoundSiteID = false;
						getQuoteId(siteId)
						.then(val => {
							resolve({
								name,
								quoteId:val
							});
							return 1;
						})
					}
				}
			}
			if(notFoundSiteID) {
				resolve({
					name,
					quoteId:""
				});
				return 1;
			}
		})
		.catch(error => {
			console.log(error);
		})
	})
}



function getQuoteId(siteId) {
	return new Promise((resolve,reject) => {
		db.ref(`sites/${siteId}`).child('mSite_QuoteId').once('value')
		.then(snapshot => {
			resolve(snapshot.val());
			return 1;
		}).catch(err => {
			reject(err);
			return 1;
		})
	})
}

function dispatcher(dispatch,callRecordDataTable,count,total) {
	count.status = count.status+1;
	console.log(count.status,total)
	if(count.status===total) {
		dispatch({type:FETCHING_CLIENT_CALL_ANALYTICS_SUCCESS,payload:[...[],...Object.values(callRecordDataTable).filter(val => val)]});
	}
}


// SEARCH DATA
export function searchData(status,value) {
	return (dispatch) => {
		switch(status) {
			case 0: // QUOTE ID
				dispatch({type:FETCHING_EMAIL_ANALYTICS_START})
				getQuoteIdNumber(value)
				.then(values =>{
					let data = destructuring(values);
					console.log(data);
					dispatch({type:FETCHING_CLIENT_CALL_ANALYTICS_SUCCESS,payload:data});
				}).catch(error => {
					console.log(error);
				});
				return;
			case 1: //PHONE NUMBER
				dispatch({type:FETCHING_EMAIL_ANALYTICS_START})
				fetchMasterCallLog(value)
				.then(values =>{
					let data = destructuring(values);
					console.log(data);
					dispatch({type:FETCHING_CLIENT_CALL_ANALYTICS_SUCCESS,payload:data});
				}).catch(error => {
					console.log(error);
				});
				return;
			default: // RETURN ERROR
				return
		}
 	}
}

function destructuring(values) {
	console.log(values);
	let data1=values[0]?Object.values(values[0]):[];
	let data2=values[1]?Object.values(values[1]):[];
	let data3=values[2]?Object.values(values[2]):[];
	let data = [...data1,...data2,...data3].map(val => {
		return {
			date: dateToEpoch(val.date),
			quoteId: values[3].quoteId,
			name: values[3].name,
			mobile: val.mobileNumber.substring(val.mobileNumber.length-10,val.mobileNumber.length),
			on: val.phoneNumber,
			duration: val.duration,
			type: val.type
		}
	});
	return data;
}

function getQuoteIdNumber(quoteId) {
	return new Promise((resolve,reject) => {
		console.log(quoteId);
		fetch(`quotestaken`,quoteId)
		.then(siteIdObject => {
			let { siteId } = siteIdObject?siteIdObject:{};
			if(siteId) {
				fetch(`sites/${siteId}`,'mSite_OwnerId')
				.then(value => {
					fetchMasterCallLog(value)
					.then(values => {
						resolve(values);
					});
				}).catch(error => {
					reject(error);
				})
			}
		})
	})
}

function fetchMasterCallLog(number) {
	let phoneNumber  = number.substring(number.length-10,number.length);
	let phoneNumber1 = "+91"+number.substring(number.length-10,number.length);
	let phoneNumber2 = "0"+number.substring(number.length-10,number.length);
	return new Promise((resolve,reject) => {
		Promise.all([fetchOrderByChild(`utilities/CallRecords/2`,'mobileNumber',phoneNumber),fetchOrderByChild(`utilities/CallRecords/2`,'mobileNumber',phoneNumber1),fetchOrderByChild(`utilities/CallRecords/2`,'mobileNumber',phoneNumber2),fetchNameCallRecord(phoneNumber1)])
		.then(values => {
			resolve(values);
		}).catch(error => {
			reject(error);
		})
	})
}

function dateToEpoch(value) {
	return new Date(`${value.substring(3,5)}-${value.substring(0,2)}-${value.substring(6,8)} ${value.split(" ")[1]}`).getTime();
}