import { base, db, storage } from '../connection';
import axios from 'axios';

export function sendUploadedFileEmail(quoteId, requestId, type, email, fileName, responsibleDeliveryNumber, name) {
    console.log(new Date());
    return (dispatch) => {
        sendEmail(quoteId, requestId, type, email, fileName, responsibleDeliveryNumber, name).then(data => {
            console.log(data);
            alert(data);
        })
    }
}


async function sendEmail(quoteId, requestId, type, email, fileName, responsibleDeliveryNumber, name) {
    try {
        let emailData = getMailBody(quoteId, name, type);
        if (emailData.body && emailData.subject) {
            let responsibleEmail = '';
            if (responsibleDeliveryNumber) {
                responsibleEmail = (await db.ref(`users/${responsibleDeliveryNumber}/mUser_Contact/mContact_Email`).once('value')).val();
            }
            let formData = new URLSearchParams();
            //email = 'Shubham.arora@zunroof.com';
            let emailList = [email];
            if (responsibleEmail) {
                console.log(responsibleEmail);
                //responsibleEmail = 'shubharora16@gmail.com';
                emailList.push(responsibleEmail);
            }
            formData.append("key", "jma3tyngdfue8ge");
            formData.append("quoteId", quoteId);
            formData.append("requestId", requestId);
            formData.append("email", JSON.stringify(emailList));
            formData.append("fileName", fileName);
            formData.append("body", emailData.body);
            formData.append("subject", emailData.subject);
            let data = await axios.post("https://operations.zunroof.com/api/sendUploadedFile", formData, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            });
            console.log(data ? data.data : {});
            if (data && data.data && data.data.data) {
                await db.ref(`delivery/webPortal/fileManagement/delivery/viewableFiles/${quoteId}/${requestId}/verified`).set(1);
                console.log("Done");
            }
            else {
                return 'Some Error Occurred While Sending Email';
            }
            return 'Email Sent.'
        }
        else {
            return 'Some Error Occurred While Sending Email';
        }
    }
    catch (e) {
        return 'Some Error Occurred While Sending Email';
    }
}


function getMailBody(quoteId, name, type) {
    try {
        let body = '';
        let subject = '';
        switch (type) {
            case 'type1':
                body = `Hi ${name},<br/>
                Please find attached your Invoice.<br/>
                Please reach out to us for any assistance.<br/>
                <br/>
                Warm Regards,<br/>
                Team ZunRoof<br/>
                +919319432702`;
                subject = `${quoteId}_Invoice`;
                break;
            case 'type6':
                body = `Hi ${name},<br/>
                Please find attached your Warranty Certificate.<br/>
                Please reach out to us for any assistance.<br/>
                <br/>
                Warm Regards,<br/>
                Team ZunRoof<br/>
                +919319432702`;
                subject = `${quoteId}_Warranty Certificate`;
                break;
        }
        return { body, subject };
    }
    catch (e) {
        return {};
    }
}