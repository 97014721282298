   import { FETCHING_START, FETCHING_STAGES_START, FETCHING_STAGES_PROGRESS, FETCHING_STAGES_SUCCESS } from './actiontypes';

import { fetch } from '../helper/firebaseFunctions';
import { db } from '../connection';

//COLUMN
import { columnsstagestatus } from '../config/column_fields';

//HELPER FUNCTION
import { calculateDateDiff, dotToEmail } from '../helper/helper';
import { dateChanger } from '../helper/dateChanger';



export function fetchstages() {
	console.log(new Date(),0);
	return (dispatch) => {
		// GET ALL SITE IDs
		dispatch({type:FETCHING_STAGES_START});
		fetchMapping()
		.then(map => {
			let url = `delivery/webPortal/`;
			fetch(url,'deliverySiteMapping')
			.then(siteIds => {
				console.log(new Date(),1)
				let siteIdList = Object.keys(siteIds);
				dispatch({type:FETCHING_STAGES_PROGRESS,payload:siteIdList.length});
				let promiseList = siteIdList.map((key,index) => {
					return new Promise((resolve, reject) => {
						Promise.all([
							fetch('delivery/sites/',key),	
							fetchSites(key),
							fetch('delivery/webPortal/sites',key),
							fetch('delivery/Issue/statusbysite',key)
						]).then(values => {
							resolve(values);
							return;
						}).catch(error => {
							reject(error);
							return;
						})
					});
				});
				Promise.all(promiseList)
				.then(values => {
					values.forEach(value => {
						let dataJSON = {
							delivery: value[0],
							...value[1],
							web: value[2],
							ebIssue: value[3]
						}
						let { siteObject, delivery, web, ebIssue, email, call, notification, user } = dataJSON;
						let { stage, quoteId, extraPriceLineItem, paymentHistory, vendor, orderStatus } = delivery;
						let { mSite_selectedSize, mSite_finalPrice, mSite_State } = siteObject;
						let { size_D, deliveredPanel, deliveredInverter, subsidy_D, state_D } = web?web:{};
						let { mUser_Contact } = user;
						let nd = notification, ed=email, cd= call;
						let ottPrice   = extraPriceLineItem?addPayment(extraPriceLineItem,"price"):0;
						let totalPaid   = paymentHistory?addPayment(paymentHistory,"amountPaid"):0;
						let pd = paymentHistory?Object.values(paymentHistory)[Object.values(paymentHistory).length-1].paidDate.time:0;
						let totalPaidPercentage = totalPaid/(+mSite_finalPrice+ottPrice);
						let lastStage = stage?stage[stage.length-1].current_State:-1;
						let salesDate = stage?stage[stage.length-1].sales_Date&&stage[stage.length-1].sales_Date!=="NA"?dateChanger(stage[stage.length-1].sales_Date):0:0;
						let refDate = stage?stage[stage.length-1].ref_Date&&stage[stage.length-1].ref_Date!=="NA"?dateChanger(stage[stage.length-1].ref_Date):0:0;
						let structureDate 	= orderStatus?orderStatus.structure?orderStatus.structure.expectedCompletionDate?orderStatus.structure.expectedCompletionDate.time:0:0:0;
						let panelDel 		= deliveredPanel;
						let inverterDel 	= deliveredInverter;
						let subsidy 		= subsidy_D;

						// console.log(stage,totalPaidPercentage,pd,nd,cd,ed)
						dispatch({type:FETCHING_STAGES_SUCCESS,payload:{
							'quoteid': delivery.quoteId,
							'name': mUser_Contact?`${mUser_Contact.mContact_FirstName} ${mUser_Contact.mContact_LastName}`:"",
							'state': state_D?state_D:mSite_State?mSite_State:"",
							'paidPercentage': totalPaidPercentage,
							'vendor': vendor,
							'stage': lastStage,
							'salesDate':salesDate,
							'subsidy': subsidy,
							'size': size_D?parseFloat(size_D):mSite_selectedSize?parseFloat(mSite_selectedSize):0,
							'notificationC': notificationCheck(lastStage,nd,cd,ed),
							'commissioningC': commissioning(lastStage,totalPaidPercentage,pd,nd,cd,ed),
							'payment0': paymentCheck(lastStage,0,0,totalPaidPercentage,0,0),
							'payment1': paymentCheck(lastStage,0,0,totalPaidPercentage,0,15),
							'payment2': paymentCheck(lastStage,1,2,totalPaidPercentage,0,65),
							'payment3': paymentCheck(lastStage,2,5,totalPaidPercentage,0,93),
							'payment4': paymentCheck(lastStage,6,6,totalPaidPercentage,0,99),
							'p1recieved': progressCheck(quoteId,lastStage,0,0,totalPaidPercentage,15,100,salesDate,10,7,structureDate) < progressCheck(quoteId,lastStage,0,0,totalPaidPercentage,0,65,salesDate,30,25,structureDate?false:true)?progressCheck(quoteId,lastStage,0,0,totalPaidPercentage,15,100,salesDate,10,7,structureDate):progressCheck(quoteId,lastStage,0,0,totalPaidPercentage,0,65,salesDate,30,25,structureDate?false:true),
							'panels': progressCheck(quoteId,lastStage,0,1,totalPaidPercentage,65,100,refDate,10,7,panelDel),
							'inverter': progressCheck(quoteId,lastStage,1,3,totalPaidPercentage,92,100,refDate,25,20,inverterDel),
							'commissioningP': progressCheck(quoteId,lastStage,3,4,totalPaidPercentage,92,100,refDate,25,20,inverterDel?false:true),
						}})
					})
				}).catch(error => {
					console.log(error);
				})
			})
		});
	}
}


/// MAPPING FETCH //
function fetchMapping() {
	return new Promise((resolve,reject) => {
		Promise.all([
			fetch('delivery','stagelist')
		]).then(values => {
			resolve({
				stageMap: values[0]
			});
		}).catch(error => {
			reject(error);
		})
	})
}


// DATA FETCH //

function fetchSites(key) {
	return new Promise((resolve,reject) => {
		fetch('sites/',key)
		.then(val => {
			let { mSite_OwnerId, mSite_notificationIds } = val;
			let lastNotifications = mSite_notificationIds?mSite_notificationIds[mSite_notificationIds.length-1]:"";
			Promise.all([getUserEmail(mSite_OwnerId),fetch('delivery/webPortal/clientCallLastConversation',mSite_OwnerId),fetchNotification('notifications',lastNotifications)])
			.then(data => {
				var addedData = {
					siteObject:val,
					email:data[0].email?Object.values(data[0].email)[0].date:0,
					call: data[1]?data[1].epoch:0,
					notification: data[2]?data[2].sendDate.time:0,
					user: data[0].user
				}
				resolve(addedData);
			}).catch(err => {reject(err);});
		})
	})
}

function getUserEmail(userId) {
	return new Promise((resolve,reject) => {
		fetch('users/',userId)
		.then(val => {
			let { mUser_Contact	} = val;
			if(mUser_Contact) {
				let { mContact_Email } = mUser_Contact;
				var dotToEmailConvert = dotToEmail(mContact_Email);
				db.ref('delivery/webPortal/email/timeData')
				.orderByChild('to')
				.equalTo(dotToEmailConvert)
				.limitToLast(1)
				.once("value")
				.then(snapshot => {
					resolve({email:snapshot.val(),user:val});
					return;
				}).catch(error => {console.log(error);reject(error); return;})
			}else{
				resolve(null);
				return;
			}
		}).catch(err => {reject(err); return;})
	})	
}

function fetchNotification(url,key) {
	return new Promise((resolve,reject) => {
		if(key) {
			fetch(url,key).then(val => {resolve(val); return;}).catch(err => {reject(err); return;});	
		}else{
			resolve(null);
			return;
		}
	})
}

// HELPER FUNCTION //

function checkAge(epoch,num) {
	let age = (new Date().getTime() - epoch)/(1000*60*60*24)
	return age>num;
}

// NOTIFICATION
function notificationCheck(stage,nd,cd,ed) {
	if(stage>5) {
		return 4;
	}else{
		if(checkAge(nd,10)&&checkAge(cd,10)&&checkAge(ed,10)) {
			return 1;
		}else if(checkAge(nd,7)&&checkAge(cd,7)&&checkAge(ed,7)) {
			return 2;
		}else {
			return 3;
		}
	}
}

function commissioning(stage,payment,pd,nd,cd,ed) {
	if(stage>4 || stage<1) {
		return 4;
	}else{
		if(checkAge(pd,10)&&checkAge(nd,7)&&checkAge(cd,7)&&checkAge(ed,7)&&payment>0.65) {
			return 1;
		}else {
			return 3;
		}
	}
}

/* Zero Payment	P1 Payment	P2 Payment	P3 Payment	P4 remaining */

function paymentCheck(stage,minRange,maxRange,payment,minPayment,maxPayment) {
	if(minRange<=stage&&stage<=maxRange) {
		if(minPayment<=payment&&payment<=maxPayment) {
			return 1;
		}else{
			return 3;
		}
	}else{
		return 4;
	}
}



function progressCheck(quoteId,stage,minRange,maxRange,payment,minPayment,maxPayment,date,maxAge1,maxAge2,variable) {
	if(quoteId=="FEB18011") {
		console.log(minPayment<=payment,payment<=maxPayment,!variable,checkAge(date,maxAge1));
	}
	if(minRange<=stage&&stage<=maxRange) {
		if(minPayment<=payment&&payment<=maxPayment&&!variable) {
			if(checkAge(date,maxAge1)) {
				return 1;
			}else if(checkAge(date,maxAge2)){
				return 2;
			}else {
				return 3;
			}
		}else{
			return 3;
		}
	}else{
		return 4;
	}
}

function addPayment(payment,value) {
	let sum = 0;
	Object.values(payment).forEach(val => {
		if(!isNaN(parseInt(val[value]))) {
			sum += parseInt(val[value]);
		}
	});
	return sum*100;
}