import { FETCH_SALES_TARGET_SUCCESS, FETCH_TARGET_ACCESS_SUCCESS } from '../actions/actiontypes'
const INIT_STATE = {
	loading: true
}


export default function (state = INIT_STATE, action) {
	switch (action.type) {
		case FETCH_SALES_TARGET_SUCCESS:
			return { ...state, data: action.payload, received: true };
		case FETCH_TARGET_ACCESS_SUCCESS:
			return { ...state, accessLevel: action.payload };
        default:
			return state;
	}
}
