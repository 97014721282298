export function dateRange(date,start,end){
	if(!start&&!end){
		return true;
	}
	if(date){
		let getDate 	= new Date(date).getTime();
		let startDate 	= start?new Date(start).setHours(0,0,0,0):0;
		let endDate		= end?new Date(end).setHours(23,59,59,59):9999999999999;
		if(getDate>=startDate&&getDate<=endDate){
			return true;
		}	
	}else{
		return false;
	}
}

export function percentageRange(value,startVal,endVal){
	if(value||value===0) {
		let start 	= startVal||startVal===0?startVal:-100;
		let end		= endVal?endVal:1000;
		if(parseInt(value)>=start&&parseInt(value)<=end){
			return true;
		}
	}else{
		return false;
	}
}
