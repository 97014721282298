import { SET_VENDOR_FORM_INIT_SUCCESS, SET_VENDOR_FORM_INIT_ERROR, SET_VENDOR_FORM_APPROVAL_SUCCESS, SET_VENDOR_FORM_APPROVAL_ERROR, SET_NEW_VENDOR_FORM_SUCCESS, SET_NEW_VENDOR_FORM_ERROR, SET_TRANSACTION_FORM_SUCCESS, SET_TRANSACTION_FORM_ERROR } from './actiontypes';
import { push, set } from '../helper/firebaseFunctions';
import { base, storage, db } from '../connection';
import axios from 'axios';
const compulsaryValue = {
	name:true, address:true, pancard:true, gst:true, ifsc:true, accountNumber:true, bank:true, director:true, pocname:true, pocphone1:true, pocphone2:true, companyType:true, v1Permission:false, v2Permission:false, panelPermission:false, inverterPermission:false, structurePermission:false
}

export function addNewVendor({ user, formObject }) {
	// uploadFiles(user, formObject);
	// return (dispatch) => {
	// 	console.log("abc")
	// }
	return (dispatch) => {
		console.log("error");
		let { name, email, address, pancard, gst, ifsc, accountNumber, bank, director, pocname, pocphone1, pocphone2, companyType, v1Permission, v2Permission, panelPermission, inverterPermission, structurePermission, files, cashfreeId, msme } = formObject;
		let { value, label } = companyType?companyType:{};

		let vendorObj = {
			name : name,
			email: email?email:"",
			address : address,
			pancard:pancard,
			gst:gst,
			ifsc:ifsc,
			accountNumber:accountNumber,
			bank:bank,
			director:director,
			pocname:pocname,
			pocphone1:pocphone1,
			pocphone2:pocphone2,
			companyType:companyType,
			v1Permission:v1Permission,
			v2Permission:v2Permission,
			panelPermission:panelPermission,
			inverterPermission:inverterPermission,
			structurePermission:structurePermission,
			time:new Date().getTime(),
			type1:files[0]?files[0].name:0,
			type2:files[1]?files[1].name:0,
			type3:files[2]?files[2].name:0,
			type4:files[3]?files[3].name:0,
			cashfreeId:cashfreeId?cashfreeId:'',
			msme:msme?msme:''
		}
		if(checkForm(vendorObj)) {
			let id = getRandomDate();
			console.log("vendorId", id);
			let finalData = {...vendorObj, id:id, msme: msme?msme.value:'', companyType:companyType.value, pocphone1:`+91${pocphone1.substring(pocphone1.length-10,pocphone1.length)}`,pocphone2:`+91${pocphone2.substring(pocphone2.length-10,pocphone2.length)}`}
			set(`delivery/webPortal/vendorMapping/${id}`,finalData)
			.then(val => {
				dispatch({type:SET_VENDOR_FORM_INIT_SUCCESS, payload:"SuccessFully Submitted"});
				uploadFiles(user, formObject, id);
				setTimeout(function(){
					dispatch({type:SET_VENDOR_FORM_INIT_SUCCESS, payload:null})
				},2000)
			})
			.catch(error => {
				dispatch({type:SET_VENDOR_FORM_INIT_ERROR, payload:"Oops! Something went wrong"});
				setTimeout(function(){
					dispatch({type:SET_VENDOR_FORM_INIT_ERROR, payload:null})
				},2000)
			});
		}else{
			dispatch({type:SET_VENDOR_FORM_INIT_ERROR, payload:"Please check all the fields"});
			setTimeout(function(){
					dispatch({type:SET_VENDOR_FORM_INIT_ERROR, payload:null})
				},2000)
		}
	}
}

export function updateVendor({ user, id, columnId, value }) {
	return (dispatch) => {
		set(`delivery/webPortal/vendorMapping/${id}/${columnId}`, value)
		.then(val => {
			console.log("Updated");
		}).catch(error => {
			console.log(error);
		})
	}
}

function checkForm(formObject) {
	let { name, address, pancard, gst, ifsc, accountNumber, bank, director, pocname, pocphone1, pocphone2, companyType, v1Permission, v2Permission, panelPermission, inverterPermission, structurePermission } = formObject;
	let status = true;
	Object.keys(compulsaryValue).forEach(key => {
		if(compulsaryValue[key] && formObject[key] === undefined) {
			status = false;
		}
	});
	return status;
}

function getRandomDate() {
	let currentEpoch = new Date().getTime();
	let randomDigit = Math.floor(100000 + Math.random() * 900000);
	return `${currentEpoch}${randomDigit}`;
}


async function uploadFiles(user, formObject, id){
	let blobs = formObject.blobs;
	let files = formObject.files;
	let types = ['type1', 'type2', 'type3', 'type4'];
	await Promise.all(blobs.map(async (blob, i) => {
		if(blob){
			console.log(blob, i);
			let uploadObj = {
				userId:user.phoneNumber,
				quoteId:id,
				name:files[i]?files[i].name:'',
				version:'1',
				type:types[i],
				file:blob,
				folderType:'Vendor',
				requestId:types[i]
			}
			fileUpload(uploadObj);
		}
	}));
}

export function uploadFilesEdit(user, file, blob, type, vendorId){
	return(dispatch) => {
		if(vendorId && type){
			let name = file?file.name:'';
			db.ref(`delivery/webPortal/vendorMapping/${vendorId}/${type}`).set(name);
			let uploadObj = {
				userId:user.phoneNumber,
				quoteId:vendorId,
				name:file?file.name:'',
				version:'1',
				type:type,
				file:blob,
				folderType:'Vendor',
				requestId:type
			};
			fileUpload(uploadObj);
		}
	}
}

function fileUpload(uploadObj) {
	console.log(new Date());
		let { userId, quoteId, name, version, type, file, folderType, requestId } = uploadObj;
		let folderTypeMap={};
		console.log(userId, quoteId, name, version, type, file);
		let storageRefURL = `delivery/tempUpload/${userId}/${quoteId}/${requestId}`;
		console.log(storageRefURL);
		let storageRef = storage.ref().child(storageRefURL);
		let metadata = {
			customMetadata: {
				createdBy:userId,
				version,
				name,
				type,
				folderType,
				ownerUserId:"ownerUserId_123789",
				responsibleUserId:"responsibleUserId_123789"
			}
		}
		storageRef.put(file,metadata)
		.then(function(snapshot) {
		  	console.log(snapshot);
			base.post(`delivery/webPortal/fileManagement/delivery/uploadRequestVendor/${userId}/${quoteId}/${requestId}`, {
		  		data: {status:1,url:""},
		  		then(err){
					if(!err){
						console.log("SUCCESS");
					  uploadObj["requestId"] = requestId;
					  uploadURLListener(uploadObj);
					}else{
						console.log("POST : ",err);
					}
			    }
		  	});
		})
		.catch(err => {
			console.log("STORAGE : ",err);
		});
}

function uploadURLListener(uploadObj) {
	console.log("ATTCHING LISTENER");
	let { userId, quoteId, requestId, name } = uploadObj;
	base.listenTo(`delivery/webPortal/fileManagement/delivery/uploadRequestVendor/${userId}/${quoteId}/${requestId}/status`, {
		context: {},
		asArray: false,
		then(caseVal){
			switch(caseVal) {
				case 2: 
					console.log("SUCCESSFULLY UPLOADED AND SECURED")
					break;
				default: 
					break;
			}
		}
	})
}

export function fileDownloadVendor(downloadObj) {
	console.log(new Date());
	return (dispatch) => {
		let { userId, quoteId, requestId } = downloadObj;
		base.post(`delivery/webPortal/fileManagement/delivery/downloadRequestVendor/${userId}/${quoteId}/${requestId}/`,{
				data:{url:"",status:1},
				then(err){
		      	if(!err){
		      		downloadURLListener(downloadObj);
		      	}else{
		      		console.log(err);
		      	}
		    }
		});
	}
}

function downloadURLListener(downloadObj) {
	console.log("ATTCHING LISTENER");
	let { userId, quoteId, requestId, fileName } = downloadObj;
	let listenerCreated = base.listenTo(`delivery/webPortal/fileManagement/delivery/downloadRequestVendor/${userId}/${quoteId}/${requestId}/url`, {
		context: {},
		asArray: false,
		then(caseVal){
			switch(caseVal) {
				case 1: 
					let userFolder = ""+parseInt(userId);
					console.log("Found : ",`delivery/tempDownload/${userId}/${quoteId}/${requestId}`);
					storage.ref(`delivery/tempDownload/${userFolder}/${quoteId}/${requestId}`).getDownloadURL().then(function (url) {
						console.log(url);
				        axios({
						  url,
						  method: 'GET',
						  responseType: 'blob',
						}).then((response) => {
							console.log(response);
							base.post(`delivery/webPortal/fileManagement/delivery/downloadRequestVendor/${userId}/${quoteId}/${requestId}`, {
								data:{url:"REMOVED",status:2},
								then(err){
							      	if(!err){
							      		console.log("REMOVED")
							      	}else{
							      		console.log(err);
							      	}
							    }
							});
							const url = window.URL.createObjectURL(new Blob([response.data]));
							const link = document.createElement('a');
							link.href = url;
							
							link.setAttribute('download', fileName);
							document.body.appendChild(link);
							link.click();
						});
				        
				    }).catch(error => {console.log(error)});
					console.log("REMOVING LISTENER");
					base.removeBinding(listenerCreated);
					break;
				case 2: 
					// FORBIDDEN
					break;
				case 3: 
					// NO FILE PRESENT
					break;
				default:
					break;
			}
		}
	})
}


export function deleteVendor(vendorId) {
	return (dispatch) => {
		if(vendorId){
			set(`delivery/webPortal/vendorMapping/${vendorId}`, null)
			.then(val => {
				alert("vendor deleted");
				console.log("Updated");
			}).catch(error => {
				alert("some error occured");
				console.log(error);
			})
		}
	}
}