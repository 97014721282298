import React from 'react';

import '../styles/loader.css';

export const Loader = () => {
	return (
		<div className="container">
			<div className="row">
		        <div className="animationload">
		            <div className="loading"></div>
		        </div>
			</div>
		</div>
	);
}