import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

import { styleSheet } from '../styles/styleSheet';

import { fetchstages } from '../actions/fetchstages';
import { fetchmapping } from '../actions/fetchmapping';

import { StageView } from '../components/StageView'
import { Restrict } from '../components/Restrict'

import { columnsstagestatus } from '../config/column_fields';

class Stages extends Component {
	constructor(props) {
	  super(props);
	
	  this.state = {
	  	recieved:false
	  };
	  this.vendor1 = ["NA","Boond","Chemitech","SmartRoof","Aryav","Zivah","SDSL","Oakridge","ZunRoof","SunEnergy","Volks Energie","Sirius","Greenon","GeoPower","GM Trading","Alien","Adhunik"];
	}

	componentWillMount() {
		if(this.props.user){
			if(this.props.user.level>=1900){
				this.props.fetchstages();
				this.props.fetchmapping();
			}
		}
	}

	componentWillReceiveProps(props) {
		const { data, states, stages, subsidies, ebissues, count } = props;
		if(count && data.length === count) {
			console.log(states, stages, subsidies, ebissues);
			this.setState({
				recieved: true 
			})
		}
	}

	render() {
		if(this.props.user===null){
			return <Redirect to="/login?redirect_uri=stages" />;
		}

		if(this.props.user.level<1900) {
			return ( 
				<div>
					<Restrict />
				</div>
			)
		}

		if(this.state.recieved) {
			return (
				<div>
					<div>
						<StageView 	
							columns 		= {columnsstagestatus}
							data 		= {this.props.data}
							user 		= {this.props.user}
							states 		= {this.props.states}
							stages 		= {this.props.stages}
							subsidies 	= {this.props.subsidies}
							ebissues 	= {this.props.ebissues}/>
				  	</div>
				</div>
			)
		}
		return (
			<div style = {styleSheet.frameOne} className="container-fluid">
				Loading...
			</div>
		);
	}
}


function mapStateToProps(state) {
	return {
		data: 			state.stagesdata.data,
		count: 			state.stagesdata.count,
		user : 			state.user,
		states: 		state.mapping.stateMap,
		stages: 		state.mapping.stageMap,
		subsidies: 		state.mapping.subsidyMap,
		ebissues: 		state.mapping.ebissueMap
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({fetchstages, fetchmapping}, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Stages); 
