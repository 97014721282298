import { base, db } from '../connection';


export function callClient(number, agent) {
    return async (dispatch) => {
        makeCall(number, agent).then(data => {
            console.log(data);
        })
    }
}



async function makeCall(number, agent) {
    console.log("CLICK 2 CALL", agent);
    let formData = new FormData();
    formData.append("number", number);
    formData.append("key", 'qderfba');
    formData.append("agent", agent);
    let response = await fetch('https://www.zunroof.com/server/makeCall.php', {
        method: 'POST',
        body: formData
    });
    response = (await response.json());
    if(response.success){
        response = response.success;
        alert(response.message);
    }
    else if(response.error){
        response = response.error;
        alert(response.message);
    }
}