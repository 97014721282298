import { FETCHING_PM_TASKS_START, FETCHING_PM_TASKS_SUCCESS, CREATING_PM_TASKS_SUCCESS } from '../actions/actiontypes';

export default function (state = {}, action) {
  switch (action.type) {
    case FETCHING_PM_TASKS_START: {
      return {};
    }
    case FETCHING_PM_TASKS_SUCCESS: {
      return { ...state, data: action.payload };
    }
    case CREATING_PM_TASKS_SUCCESS: {
      return { ...state, data: action.payload };
    }
    default:
      return state;
  }
}
