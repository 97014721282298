import { UPLOADING_STARTED, UPLOADING_SUCCESS, UPLOADING_ERROR } from '../actiontypes';
import { base, storage, db } from '../../connection';
import axios from 'axios';


export function fileUpload(uploadObj) {
	console.log(new Date());
	return (dispatch) => {
		let { userId, quoteId, name, version, type, file, folderType } = uploadObj;
		dispatch({type:UPLOADING_STARTED,payload:name});
		let folderTypeMap={};
		console.log(userId, quoteId, name, version, type, file);
		let requestId = `${new Date().getTime()}`;
		let storageRefURL = `delivery/tempUpload/${userId}/${quoteId}/${requestId}`;
		console.log(storageRefURL);
		let storageRef = storage.ref().child(storageRefURL);
		let metadata = {
			customMetadata: {
				createdBy:userId,
				version,
				name,
				type,
				folderType,
				ownerUserId:"ownerUserId_123789",
				responsibleUserId:"responsibleUserId_123789",
			}
		}
		storageRef.put(file,metadata)
		.then(function(snapshot) {
		  	console.log(snapshot);
			base.post(`delivery/webPortal/fileManagement/delivery/uploadRequest/${userId}/${quoteId}/${requestId}`, {
		  		data: {status:1,url:""},
		  		then(err){
					if(!err){
						console.log("SUCCESS");
					  uploadObj["requestId"] = requestId;
					  if(folderType==="Client Info"){
						  base.fetch('/delivery/webPortal/fileManagement/delivery/config/typesList/Client Info/',{})
						  .then(data=>{
							  folderTypeMap = data;
							  let filename=folderTypeMap[type].name;
							  base.post(`delivery/webPortal/fileManagement/delivery/docs/${quoteId}/${filename}`, {
								  data:{key:parseInt(requestId),status:1,}
							  ,then(err){
								  if(!err)
								  console.log("success upload")
							  }
							  })
						  })
					  }
					  if(folderType == "Subsidy Status" && type == "type1") {
						updateSubsidyApprovalDate(quoteId);
					  }
					  uploadURLListener(dispatch,uploadObj, folderType, type);
					}else{
						dispatch({type:UPLOADING_ERROR,payload:name});
						console.log("POST : ",err);
					}
			    }
		  	});
		})
		.catch(err => {
			dispatch({type:UPLOADING_ERROR,payload:name});
			console.log("STORAGE : ",err);
		});
	}
}


// FUNCTION UPLOAD LISTENER
function uploadURLListener(dispatch,uploadObj, folderType, type) {
	console.log("ATTCHING LISTENER");
	let { userId, quoteId, requestId, name } = uploadObj;
	base.listenTo(`delivery/webPortal/fileManagement/delivery/uploadRequest/${userId}/${quoteId}/${requestId}/status`, {
		context: {},
		asArray: false,
		then(caseVal){
			switch(caseVal) {
				case 2:
					console.log("SUCCESSFULLY UPLOADED AND SECURED", folderType, type)
					dispatch({type:UPLOADING_SUCCESS,payload:name});
					if (folderType == "Design" && type == "type1") {
						let formData = new URLSearchParams();
            formData.append("key", "jma3tyngdfue8ge");
            formData.append("quoteId", quoteId);
            formData.append("requestId", requestId);
            axios.post("https://operations.zunroof.com/api/whatsappLayout", formData, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            }).then();
					}
					break;
				default:
					// NO FILE PRESENT
					dispatch({type:UPLOADING_ERROR,payload:name});
					break;
			}
		}
	})
}





export function fileDownload(downloadObj) {
	console.log(new Date());
	return (dispatch) => {
		let { userId, quoteId, requestId } = downloadObj;
		base.post(`delivery/webPortal/fileManagement/delivery/downloadRequest/${userId}/${quoteId}/${requestId}/`,{
				data:{url:"",status:1},
				then(err){
		      	if(!err){
		      		// DOWNLOAD CALL INITIATED
		      		downloadURLListener(downloadObj);
		      	}else{
		      		console.log(err);
		      	}
		    }
		});
	}
}

function downloadURLListener(downloadObj) {
	console.log("ATTCHING LISTENER");
	let { userId, quoteId, requestId, fileName } = downloadObj;
	let listenerCreated = base.listenTo(`delivery/webPortal/fileManagement/delivery/downloadRequest/${userId}/${quoteId}/${requestId}/url`, {
		context: {},
		asArray: false,
		then(caseVal){
			switch(caseVal) {
				case 1:
					// ALL DONWLOAD
					let userFolder = ""+parseInt(userId);
					console.log("Found : ",`delivery/tempDownload/${userId}/${quoteId}/${requestId}`);
					storage.ref(`delivery/tempDownload/${userFolder}/${quoteId}/${requestId}`).getDownloadURL().then(function (url) {
						console.log(url);
				        axios({
						  url,
						  method: 'GET',
						  responseType: 'blob', // important
						}).then((response) => {
							console.log(response);
							base.post(`delivery/webPortal/fileManagement/delivery/downloadRequest/${userId}/${quoteId}/${requestId}`, {
								data:{url:"REMOVED",status:2},
								then(err){
							      	if(!err){
							      		// REMOVE CALL INITIATED
							      		console.log("REMOVED")
							      	}else{
							      		console.log(err);
							      	}
							    }
							});
							const url = window.URL.createObjectURL(new Blob([response.data]));
							const link = document.createElement('a');
							link.href = url;

							link.setAttribute('download', fileName);
							document.body.appendChild(link);
							link.click();
						});

				    }).catch(error => {console.log(error)});
					console.log("REMOVING LISTENER");
					base.removeBinding(listenerCreated);
					break;
				case 2:
					// FORBIDDEN
					break;
				case 3:
					// NO FILE PRESENT
					break;
				default:
					break;
			}
		}
	})
}

async function updateSubsidyApprovalDate(quoteId) {
	try {
		if(!quoteId) return;
		let siteId = (await db.ref(`/quotestaken/${quoteId}/siteId`).once('value')).val() || "";
		if(!siteId) return;
		let subsidyApprovalDate = (await db.ref(`delivery/webPortal/sites/${siteId}/subsidyApprovalDate`).once('value')).val();
        if(subsidyApprovalDate) return;
		await db.ref(`delivery/webPortal/sites/${siteId}/subsidyApprovalDate`).set(new Date().getTime());
		return;
	}
	catch(error) {
		console.log("updateSubsidyApprovalDate error", error);
	}
}