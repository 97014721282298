//import { UPDATE_TICKET_SUCCESS, UPDATE_TICKET_START } from './actiontypes';
import { dateChanger } from '../helper/dateChanger';
import { base, db } from '../connection';

export function bulkAssignTickets(tickets, team, assignedTo) {
    return (dispatch) => {
        //dispatch({ type: UPDATE_TICKET_START });
        //console.log("Ticket data", obj);
        //console.log(type, quoteId);
        if(assignedTo == -1){
            assignedTo = '';
        }
        tickets.forEach(ticketId => {
            let updateObj = {
                responsibleTeam:team,
                assignedTo:assignedTo
            };
            db.ref('/website/ticketSystem/tickets/' + ticketId).update(updateObj)
            .then(val => {
                console.log("DONE BULK");
                //dispatch({ type: UPDATE_TICKET_SUCCESS });
            })
            .catch(e => {
                console.log(e);
            })
        })
        // let epoch = (new Date).getTime();
        // let dueDate = obj.dueDate;
        // dueDate = dueDate.split("-");
        // dueDate = new Date(dueDate[1] + "-" + dueDate[2] + "-" + dueDate[0]).getTime() + 86399000;
        // let updateObj = {
        //     dueDate: dueDate,
        //     responsibleTeam: obj.responsibleTeam,
        //     assignedTo: obj.assignedTo,
        //     priority: obj.priority,
        //     status: obj.status
        // };
        // db.ref('/website/ticketSystem/tickets/' + ticketId).update(updateObj)
        //     .then(val => {
        //         console.log(val);
        //         dispatch({ type: UPDATE_TICKET_SUCCESS });
        //     })
        //     .catch(e => {
        //         console.log(e);
        //     })
    }
}
