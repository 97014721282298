import { FETCH_ESCALATIONS_SUCCESS, FETCH_ESCALATIONS_START } from './actiontypes';

import { base, db } from '../connection';

export function fetchEscalations() {
    return async (dispatch) => {
        dispatch({ type: FETCH_ESCALATIONS_START });
        console.log('fetch start');
        fetchEscalationList().then(data => {
            dispatch({ type: FETCH_ESCALATIONS_SUCCESS, payload:data });
            console.log("ESCALATION DATA", data);
        })
    }
}


async function fetchEscalationList(){
    try{
        let escalationList = (await db.ref(`delivery/webPortal/escalations`).once('value')).val();
        let allData = [];
        if(escalationList){
            await Promise.all(Object.values(escalationList).map(async type => {
                await Promise.all(Object.values(type).map(async data => {
                    let responsibleId = data.responsible;
                    let userObj = (await db.ref(`users/${responsibleId}/mUser_Contact`).once('value')).val();
                    if(userObj){
                        data.responsible = (userObj.mContact_FirstName || '') + " " + (userObj.mContact_LastName || '');
                    }
                    
                    if(data.quoteId){
                        //console.log(data.quoteId.toUpperCase());
                        allData.push(data);
                    }
                }))
            }))
        }
        return allData;
    }
    catch(e){
        return e;
    }
}