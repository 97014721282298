import { FETCH_DPP_SUCCESS, FETCH_ELIGIBILITY_DPP_SUCCESS } from '../actions/actiontypes'
const INIT_STATE = {
	
};


export default function (state = INIT_STATE, action) {
	switch (action.type) {
		case FETCH_DPP_SUCCESS:
			return { ...state, data: [...action.payload] };
		case FETCH_ELIGIBILITY_DPP_SUCCESS:
			return { ...state, eligibilityData: {...action.payload} };
		default:
			return state;
	}
}
