import React, { Component } from 'react';
import ReactTable from "react-table";
import Modal from 'react-responsive-modal';
import { columnsPerNotification } from '../config/column_fields';
import { styleSheet } from '../styles/styleSheet';
import { validateEmail } from '../helper/validate';
import { sortKeyData } from '../helper/helper';

export class FrameThree extends Component {
	
	constructor(props) {
	  super(props);
	
	  this.state = {
	  	show:true,
	  	data:[],
	  	text:"",
	  	user:"",
	  	show:true,
	  	open:false,
	  	openNotificationModal:false,
	  	notificationTitle:""
	  };

	  	this.emailInvalidList = ["na@na.com,Na@na.com,A@b.com,a@B.com"];

		this.hoverOn						= this.hoverOn.bind(this);
		this.hoverOff						= this.hoverOff.bind(this);
		this.onOpenModal					= this.onOpenModal.bind(this);
		this.onCloseModal					= this.onCloseModal.bind(this);
		this.onOpenNotificationModal		= this.onOpenNotificationModal.bind(this);
		this.onCloseNotificationModal		= this.onCloseNotificationModal.bind(this);
		this.handleNotificationSubmit		= this.handleNotificationSubmit.bind(this);
		this.handleOnClickNotifications 	= this.handleOnClickNotifications.bind(this);
		this.handleSelectNotifitionChange	= this.handleSelectNotifitionChange.bind(this);
		this.handleNotificationChange		= this.handleNotificationChange.bind(this);
	}

	componentDidMount() {
		this.props.data.sort(sortKeyData("date"));
		this.setState({
			data: this.props.data.reverse(),
			open : false
		})
	}

	handleNotificationChange(e) {
		this.setState({
			text:e.target.value
		})
	}

	componentWillReceiveProps(props) {
		// console.log(props)
	}

	handleNotificationSubmit() {
		if(this.state.notificationTitle&&this.state.text) {
			console.log(this.props.user.phoneNumber);
			console.log(this.props.quoteDetails.phone);
			console.log(this.props.siteid);
			console.log(""+new Date().getTime()+""+Math.round(Math.random()*1000000));
			console.log(this.state.text);
			console.log(this.props.notificationMap[this.state.notificationTitle].label);
			console.log(this.props.data.length);
			let siteId 			= this.props.siteid;
			let fromUserId 		= this.props.user.phoneNumber;
			let notification_id = ""+new Date().getTime()+""+Math.round(Math.random()*1000000);
			let text 			= this.state.text;
			let label 			= this.props.notificationMap[this.state.notificationTitle].label;
			let toUserId 		= this.props.quoteDetails.phone;
			let index 			= this.props.data.length;
			this.props.setnotification(siteId,fromUserId,notification_id,text,label,toUserId,index);
			this.setState({
				openNotificationModal: false
			});
		}
	}

	handleSelectNotifitionChange(e) {
		if(e.target.value >-1) {
			this.setState({
				notificationTitle:e.target.value,
				text:this.state.text?this.state.text:this.props.notificationMap[""+e.target.value].text
			})
		} else {
			this.setState({
				text:""
			})
		}
	}

	handleOnClickNotifications() {
		this.setState({
			show: !this.state.show
		})
	}

	hoverOn(){
      this.setState({ hover: true });
    }
    hoverOff(){ 
      this.setState({ hover: false });    
    }

    onOpenModal(){
    	if(validateEmail(this.props.quoteDetails.email)&&this.emailInvalidList.indexOf(this.props.quoteDetails.email)<0){
			this.setState({ openNotificationModal: true });
    	}else{
    		this.setState({ open: true });
    	}
	};
	 
	onCloseModal(){
		this.setState({ open: false });
	};


	onOpenNotificationModal(){
		this.setState({ openNotificationModal: true, open: false });
	};
	 
	onCloseNotificationModal(){
		this.setState({ openNotificationModal: false });
	};

	render() {
		return (
			<div className="row row_data" style={{marginBottom:2}}>
				<div className="col-12">
					<div className="page-title">
						<Modal open={this.state.open} onClose={this.onCloseModal} center>
							<div style={styleSheet.commentForm} className="form-group">
								<div>Email is not correct.<br/>Do you still wanna continue?<br/><br/>If already changed, then please reload the page.</div>
								<div style={styleSheet.commentFormButton}><button style={styleSheet.commentFormButton} className="btn btn-success" onClick={this.onOpenNotificationModal}>Yes</button></div>
							</div>
				        </Modal>
						<Modal open={this.state.openNotificationModal} onClose={this.onCloseNotificationModal} center>
							<div style={styleSheet.commentForm} className="form-group">
							<label htmlFor="notificationTitle">Notification:</label>]
								<br />
							  	<select className="custom-select"  value ={this.state.city} onChange={this.handleSelectNotifitionChange}>
									<option value={-1}>Select Notification Title</option>
									{
										this.props.notificationMap.map((data,index) => {
											return (
												<option key={index} value={index}>{data.label}</option>
											)
										})
									}
								</select>
								<br />
								<label htmlFor="notificationText"></label>
								<textarea value={this.state.text} onChange={this.handleNotificationChange} className="form-control" rows="5" id="comment"></textarea>
								<div style={styleSheet.commentFormButton}><button style={styleSheet.commentFormButton} className="btn btn-success" onClick={this.handleNotificationSubmit}>Submit</button></div>
								</div>
				        </Modal>
						<div style={styleSheet.titlePage}>
							<div style={styleSheet.titlePage.quote}>
								<span style={styleSheet.dropRightArrow} onClick={this.handleOnClickNotifications}><i className={this.state.show?"fas fa-arrow-down open_arrow":"fas fa-arrow-right open_arrow"}></i> Notifications</span>
							</div>
							<div style={styleSheet.titlePage.name}>
								
							</div>
							<div style={styleSheet.addComment}  
								 onMouseEnter={this.hoverOn} 
	            				 onMouseLeave={this.hoverOff}>
								<i style={this.state.hover?styleSheet.plusCircleOnHover:styleSheet.plusCircle} onClick={this.onOpenModal} className="fas fa-plus-circle"></i>
							</div>
						</div>
					</div>
				</div>
				<div style={this.state.show?styleSheet.show:styleSheet.hide} className="col-12">
					<div className="page_data">
						<ReactTable 
							columns={columnsPerNotification}
							defaultPageSize={this.props.data.length>5?5:this.props.data.length}
							data = {this.state.data}
						/>
					</div>
				</div>
			</div>
		);
	}
}
