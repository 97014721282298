import { FETCHING_DEALS_SUCCESS, FETCHING_DEALS_COMPLETED, FETCHING_DEALS_START } from '../actions/actiontypes'

const INIT_STATE = {
	data:{},
	lastUpdatedTime : "",
	completed:false,
}

export default function(state = INIT_STATE, action) {
	switch(action.type){
		case FETCHING_DEALS_START:{
			return state;
		}

		case FETCHING_DEALS_COMPLETED:{
			return {...state,completed:true};
		}
		
		case FETCHING_DEALS_SUCCESS:{
			return {...state, data:{...state.data,...action.payload.data}, lastUpdatedTime : action.payload.lastUpdatedTime};
		}
		default: {
			return state;
		}
	}
}